import {
  ADD_BOQ_ITEM,
  GET_BOQ_ITEM,
  GET_BOQ_ITEMS,
  UPDATE_BOQ_ITEM,
  DELETE_BOQ_ITEM,
  GET_BOQ_NAV_ITEMS,
  BOQ_API_SUCCESS,
  BOQ_API_ERROR,
  BOQ_API_PENDING,
  BOQ_UPLOAD_ERROR,
  BOQ_UPLOAD_SUCCESS,
  BOQ_PKG_ID_SELECTED,
  TOTAL_BOQ_COUNT,
  BOQ_COUNT_PENDING,
  BOQ_COUNT_RESET,
  BOQ_PROMISES_PENDING,
  BOQ_PROMISES,
  SHOW_FINANCE_FORM,
  GET_PO_IN_BOQ,
  CLEAR_BOQS,
} from './Boq.type';

const initialState = {
  pending: false,
  boqItems: [],
  poWithBoqItem: [],
  error: null,
  navItems: [],
  uploadError: [],
  boqUploaded: false,
  initialPkgId: '',
  count: 0,
  countPending: true,
  taskToBoqCount: 0,
  selectedBoq: null,
  addedBoq: null,
  boqPromisesPending: false,
  boqPromises: null,
  showFinanceForm: false,
};

export default function boqReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case ADD_BOQ_ITEM:
      return {
        ...state,
        boqItems: [...state.boqItems, payload.data],
        addedBoq: payload.data,
      };
    case GET_BOQ_ITEM:
      return { ...state, selectedBoq: payload };
    case GET_BOQ_ITEMS:
      return { ...state, boqItems: payload.data };
    case GET_PO_IN_BOQ:
      return { ...state, poWithBoqItem: payload.data };
    case UPDATE_BOQ_ITEM:
      return state;
    case DELETE_BOQ_ITEM:
      return state.boqItems.filter(elem => elem.id !== payload.id);
    case BOQ_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case BOQ_API_PENDING:
      return {
        ...state,
        pending: true,
        docUploaded: false,
        addedBoq: null,
      };
    case BOQ_API_ERROR:
      return {
        ...state,
        pending: false,
        error: error,
      };
    case GET_BOQ_NAV_ITEMS:
      return {
        ...state,
        navItems: payload,
      };
    case BOQ_UPLOAD_ERROR:
      return {
        ...state,
        uploadError: payload,
      };
    case BOQ_UPLOAD_SUCCESS:
      return {
        ...state,
        docUploaded: true,
      };
    case BOQ_PKG_ID_SELECTED:
      return {
        ...state,
        initialPkgId: payload,
      };
    case BOQ_PROMISES_PENDING:
      return {
        ...state,
        boqPromisesPending: payload,
      };
    case BOQ_PROMISES:
      return {
        ...state,
        boqPromises: payload.data,
      };
    case TOTAL_BOQ_COUNT:
      return {
        ...state,
        count: payload,
        countPending: false,
      };
    case BOQ_COUNT_PENDING:
      return { ...state, countPending: true };
    case BOQ_COUNT_RESET:
      return { ...state, count: 0 };
    case SHOW_FINANCE_FORM:
      return { ...state, showFinanceForm: payload };
    case CLEAR_BOQS:
      return initialState;
    default:
      return state;
  }
}
