export const GET_ALL_INDENT_SEQ = 'GET_ALL_INDENT_SEQ';
export const GET_ACTIVE_INDENT_SEQ = 'GET_ACTIVE_INDENT_SEQ';
export const GET_ARCHIVED_INDENT_SEQ = 'GET_ARCHIVED_INDENT_SEQ';
export const GET_INDENT_SEQ = 'GET_INDENT_SEQ';
export const GET_INDENT_SEQ_PROJECT = 'GET_INDENT_SEQ_PROJECT';

export const CREATE_INDENT_SEQ = 'CREATE_INDENT_SEQ';
export const UPDATE_INDENT_SEQ = 'UPDATE_INDENT_SEQ';
export const UPDATE_ALL_INDENT_SEQ = 'UPDATE_ALL_INDENT_SEQ';
export const UPDATE_ACTIVE_INDENT_SEQ = 'UPDATE_ACTIVE_INDENT_SEQ';
export const UPDATE_ARCHIVED_INDENT_SEQ = 'UPDATE_ARCHIVED_INDENT_SEQ';

export const ARCHIVE_INDENT_SEQ = 'ARCHIVE_INDENT_SEQ';
export const RESTORE_INDENT_SEQ = 'RESTORE_INDENT_SEQ';
export const INDENT_SEQ_API_ERROR = 'INDENT_SEQ_API_ERROR';

export const ARCHIVE_INDENT_PENDING = 'ARCHIVE_INDENT_PENDING';
export const INDENT_SEQ_PENDING = 'INDENT_SEQ_PENDING';
