import ListItem from '@mui/material/ListItem';
import './layoutStyles.scss';

// eslint-disable-next-line react/prop-types
export const CustomListItem = ({ children, ...props }) => {
  return (
    <ListItem className="customListItemStyles" {...props}>
      {children}
    </ListItem>
  );
};
