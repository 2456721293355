import {
  GET_TAG,
  GET_ALL_TAGS_TASK,
  CREATE_TAG,
  DELETE_TAG,
  TAG_API_ERROR,
  GET_TAGS_PENDING,
  GET_TAG_PENDING,
  GET_ALL_TAGS_BOQ,
  GET_ALL_TAGS_DRIVE,
  GET_ALL_TAGS_PROJECT,
  GET_ALL_TAGS_MI,
  GET_ALL_TAGS_MATERIAL,
  CREATE_TAG_PENDING,
  GET_ALL_TAGS_MATERIAL_LOGS,
} from './Tag.type';

const pendingInitialStae = {
  createTagPending: false,
  deleteTagPending: false,
  getTagPending: false,
  getTagsPending: false,
};

const initialState = {
  ...pendingInitialStae,
  tags: {
    task: [],
    boq: [],
    drive: [],
    project: [],
    mi: [],
    material: [],
    material_logs: [],
  },
  error: null,
  addedTag: null,
  selectedTask: null,
};

export default function tagReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case CREATE_TAG:
      return { ...state, addedTag: payload };
    case GET_TAG:
      return { ...state, selectedTask: payload };
    case GET_ALL_TAGS_TASK:
      return { ...state, tags: { ...state.tags, task: payload } };
    case GET_ALL_TAGS_BOQ:
      return { ...state, tags: { ...state.tags, boq: payload } };
    case GET_ALL_TAGS_DRIVE:
      return { ...state, tags: { ...state.tags, drive: payload } };
    case GET_ALL_TAGS_PROJECT:
      return { ...state, tags: { ...state.tags, project: payload } };
    case GET_ALL_TAGS_MI:
      return { ...state, tags: { ...state.tags, mi: payload } };
    case GET_ALL_TAGS_MATERIAL:
      return { ...state, tags: { ...state.tags, material: payload } };
    case GET_ALL_TAGS_MATERIAL_LOGS:
      return { ...state, tags: { ...state.tags, material_logs: payload } };
    case GET_TAG_PENDING:
      return { ...state, getTagPending: payload };
    case GET_TAGS_PENDING:
      return { ...state, getTagsPending: payload };
    case TAG_API_ERROR:
      return { ...state, error: error };
    case DELETE_TAG:
    case CREATE_TAG_PENDING:
      return { ...state, createTagPending: payload };
    default:
      return state;
  }
}
