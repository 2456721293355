import {
  USER_ACTIVITY_API_PENDING,
  DAILY_ACTIVITY_API_PENDING,
  GET_DAILY_ACTIVITY,
  GET_USER_ACTIVITY,
} from './Activity.type';
import { ReducerProps } from '../../../../interfaces/Base';

const initialState = {
  dailyActivityPending: false,
  userActivityPending: false,
  userActivity: [],
  dailyActivity: [],
};

export default function attendanceReducer(
  state = initialState,
  { type, payload }: ReducerProps
) {
  switch (type) {
    case USER_ACTIVITY_API_PENDING:
      return { ...state, userActivityPending: payload };
    case DAILY_ACTIVITY_API_PENDING:
      return { ...state, dailyActivityPending: payload };
    case GET_DAILY_ACTIVITY:
      return { ...state, dailyActivity: payload };
    case GET_USER_ACTIVITY:
      return { ...state, userActivity: payload };
    default:
      return state;
  }
}
