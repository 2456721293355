export const GET_TASK = 'GET_TASK';
export const GET_TASK_PENDING = 'GET_TASK_PENDING';
export const GET_TASK_LOGS = 'GET_TASK_LOGS';
export const GET_TASK_WITH_LOGS = 'GET_TASK_WITH_LOGS';
export const GET_TASK_LOGS_PENDING = 'GET_TASK_LOGS_PENDING';
export const GET_ALL_TASKS = 'GET_ALL_TASKS';
export const SET_TASK_DEPENDENCIES = 'SET_TASK_DEPENDENCIES';
export const GET_ALL_TASKS_PENDING = 'GET_ALL_TASKS_PENDING';
export const CREATE_TASK = 'CREATE_TASK';
export const CREATE_TASK_PENDING = 'CREATE_TASK_PENDING';
export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_PENDING = 'UPDATE_TASK_PENDING';
export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASK_PENDING = 'DELETE_TASK_PENDING';
export const TASK_USAGES = 'TASK_USAGES';
export const TASK_USAGES_PENDING = 'TASK_USAGES_PENDING';
export const TASK_COUNT_PENDING = 'TASK_COUNT_PENDING';
export const TASK_API_ERROR = 'TASK_API_ERROR';
export const CLEAR_TASKS = 'CLEAR_TASKS';
export const DELETE_TASK_LOG_PENDING = 'DELETE_TASK_LOG_PENDING';
export const ADD_TASK_LOG_PENDING = 'ADD_TASK_LOG_PENDING';
export const UPDATE_TASK_LOG_PENDING = 'UPDATE_TASK_LOG_PENDING';
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES';
export const SET_IF_FILTER = 'SET_IF_FILTER';
export const SET_FILTERED_TASKS = 'SET_FILTERED_TASKS';
export const SET_SELECTED_TASK = 'SET_SELECTED_TASK';
export const TOTAL_TASK_COUNT = 'TOTAL_TASK_COUNT';
export const SET_TASK_COMMENTS = 'SET_TASK_COMMENTS';
export const SET_TASK_DEPENDENCY_DATA = 'SET_TASK_DEPENDENCY_DATA';
export const SET_BASELINES = 'SET_BASELINES';
export const GET_TASK_TREE = 'GET_TASK_TREE';
export const TASK_TREE_PENDING = 'TASK_TREE_PENDING';
export const SET_SELECTED_TASK_ID = 'SET_SELECTED_TASK_ID';
export const LAST_ADDED_TASK = 'LAST_ADDED_TASK';
export const SET_TASK_ISSUES = 'SET_TASK_ISSUES';
export const SET_TASK_MATERIALS = 'SET_TASK_MATERIALS';
export const SET_MEASUREMENT_UNITS = 'SET_MEASUREMENT_UNITS';
export const SET_SELECTED_BASELINE = 'SET_SELECTED_BASELINE';
export const SET_OUTLINE_LEVEL = 'SET_OUTLINE_LEVEL';
export const SET_COPIED_TASK_DETAILS = 'SET_COPIED_TASK_DETAILS';
export const SET_BULK_COPIED_TASK_DETAILS = 'SET_BULK_COPIED_TASK_DETAILS';

export const SET_COLLAPSED_TASKS = 'SET_COLLAPSED_TASKS';
export const SET_TOTAL_TASK_COUNT = 'SET_TOTAL_TASK_COUNT';
export const RESET_COLLAPSED_TASKS = 'RESET_COLLAPSED_TASKS';
export const CLEAR_TASK_DETAILS = 'CLEAR_TASK_DETAILS';

export const GET_BROAD_PLAN_MAIN_TASK = 'GET_BROAD_PLAN_MAIN_TASK';
export const GET_BROAD_PLAN_TASK_DETAILS = 'GET_BROAD_PLAN_TASK_DETAILS';
export const GET_BROAD_PLAN_SITE_UPDATE = 'GET_BROAD_PLAN_SITE_UPDATE';
export const GET_BROAD_PLAN_TASK_PREV = 'GET_BROAD_PLAN_TASK_PREV';
export const GET_BROAD_PLAN_TASK_CURR = 'GET_BROAD_PLAN_TASK_CURR';
export const GET_BROAD_PLAN_TASK_NEXT = 'GET_BROAD_PLAN_TASK_NEXT';
export const GET_BROAD_PLAN_WEEK_DETAIL = 'GET_BROAD_PLAN_WEEK_DETAIL';
export const GET_BROAD_PLAN_TASK_DETAILS_STATE =
  'GET_BROAD_PLAN_TASK_DETAILS_STATE';
export const GET_BROAD_PLAN_WEEK_TASK_STATE = 'GET_BROAD_PLAN_WEEK_TASK_STATE';
export const GET_BROAD_PLAN_WEEK_TASK = 'GET_BROAD_PLAN_WEEK_TASK';
export const GET_BROAD_PLAN_TASK_PENDING = 'GET_BROAD_PLAN_TASK_PENDING';
export const GET_BROAD_SITE_UPDATE_PENDING = 'GET_BROAD_SITE_UPDATE_PENDING';
export const GET_BROAD_PLAN_ISSUE = 'GET_BROAD_PLAN_ISSUE';
export const GET_BROAD_PLAN_PHOTOS = 'GET_BROAD_PLAN_PHOTOS';
export const SYNC_MAIN_TASK_VIEW = 'SYNC_MAIN_TASK_VIEW';
export const SET_BROAD_PLAN_TASK_COUNT = 'SET_BROAD_PLAN_TASK_COUNT';
export const SET_BROAD_PLAN_TASK_PROFILE = 'SET_BROAD_PLAN_TASK_PROFILE';
export const GET_MAP_WEEKLY_TASKS = 'GET_MAP_WEEKLY_TASKS';

export const SET_TASK_WORK_ORDER = 'SET_TASK_WORK_ORDER';
export const SET_WEEK_TASK_PROFILE_IDS = 'SET_WEEK_TASK_PROFILE_IDS';
export const SET_MAIN_TASK_PROFILE_IDS = 'SET_MAIN_TASK_PROFILE_IDS';

export const GET_SITE_UPDATES = 'GET_SITE_UPDATES';
export const SET_TASK_RESOURCE_USED = 'SET_TASK_RESOURCE_USED';
export const SET_TASK_CFS = 'SET_TASK_CFS';
