import { RootState } from '../../Reducers';
import { USE_SEGMENT } from '../../../constant/segment_constant';
import { CS_TEAM_MEMBERS, ONE_DAY, USE_SMARTLOOK } from '../../../constants';
import { jwtDecode } from 'jwt-decode';
import { LocalStorageTokenKeyName } from '../../../apiClient';
import { LocalStorageUtil, generateCanvasFingerprint } from '../../../utils';
import { getCookie } from '../../../utils/Cookies.util';

let kamEmail, kamUser, deviceId;

const getDeviceId = () => {
  const deviceIdKey = 'device_id';
  let userDeviceId: any = LocalStorageUtil.getLocalStorageValue(
    deviceIdKey,
    false
  );
  if (!userDeviceId) {
    userDeviceId = `${
      Math.random().toString(36).substring(2) + Date.now().toString(36)
    }_${generateCanvasFingerprint()}`;
    LocalStorageUtil.setLocalStorageValue(deviceIdKey, userDeviceId, false);
  }
  deviceId = userDeviceId;
  return userDeviceId;
};

export const identify = (data: any) => {
  if (USE_SEGMENT) {
    if (data._id) {
      data.user_id = data._id;
    }

    const trait: any = {
      name: data.displayName,
      email: data.email,
      org_name: data.org_name,
      ord_id: data.org_id,
      team_name: data.team_name,
      team_id: data.team_id,
      mobile_number: data.mobile_number,
      user_type: data.user_type,
    };

    const token = LocalStorageUtil.getLocalStorageValue(
      LocalStorageTokenKeyName,
      false
    );
    const decoded: any = jwtDecode(token);
    if (decoded) {
      const userId = decoded?._id;
      kamEmail = decoded.kam_email;
      kamUser = decoded.kam_user;
      deviceId = getDeviceId();
      const loginTime = Number(decoded?.iat) * 1000;

      if (CS_TEAM_MEMBERS?.includes(userId)) {
        const expiredLogin = loginTime + Number(ONE_DAY * 7) < Date.now();
        if (!kamEmail) {
          kamEmail = getCookie('kam_email');
          kamUser = getCookie('kam_user');
        }

        if (expiredLogin) {
          alert('Login Expired');
          LocalStorageUtil.clearLocalStorageValues();
          window.location.reload();
        }
      }

      if (kamEmail) {
        trait.kam_email = kamEmail;
      }
      if (kamUser) {
        trait.kam_user = kamUser;
      }
    }

    if ((window as any).analytics) {
      (window as any).analytics.identify(data.user_id, trait);
    }

    if (USE_SMARTLOOK && (window as any).smartlook) {
      (window as any).smartlook('identify', data._id, {
        _id: data._id,
        name: data.displayName,
        email: data.email,
        org_id: data.org_id,
        org_name: data.org_name,
        mobile_number: data.mobile_number,
        country_code: data.country_code,
        user: data.user_type,
      });
    }
  }
};

export const track = (name: string, data: any = {}) => {
  return (_, getState: () => RootState) => {
    if (USE_SEGMENT) {
      const projectName = getState()?.projectreducer?.selectedProj?.name;
      const projectId = getState()?.projectreducer?.selectedProj?.id;
      const userId = getState()?.userreducer?._id;
      const mobileNumber = getState()?.userreducer?.mobile_number;
      const displayName = getState()?.userreducer?.displayName;
      const orgId = getState()?.organisationReducer?.orgProfile?._id;
      const orgName = getState()?.organisationReducer?.orgProfile?.org_name;
      const userRole = getState()?.userreducer.user_type;

      let eventData = {
        ...data,
        user_id: userId || '',
        phone_number: mobileNumber || '',
        username: displayName,
        organisation_id: orgId,
        organisation_name: orgName,
        user_role: userRole,
        platform: 'web',
      };
      if (kamEmail) {
        eventData.kam_email = kamEmail;
      }
      if (kamUser) {
        eventData.kam_user = kamUser;
      }

      if (!deviceId) {
        deviceId = getDeviceId();
      }

      if (projectId) {
        eventData = {
          ...eventData,
          device_id: deviceId,
          project_name: projectName,
          project_id: projectId,
        };
      }

      if ((window as any).analytics) {
        (window as any).analytics.track(name, eventData);
      }
    }
  };
};
