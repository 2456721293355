import { BROAD_PLAN_TASK_STATUS } from '../components/new-site-plan/BroadPlanConstant';

export const TASK_TYPE = {
  CHILD: 0,
  PARENT: 1,
  P_PARENT: 2,
  P_CHILD: 3,
} as const;

export const TASK_STATUS = {
  NOT_STARTED: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
};

export const TEMPLATE_CHANGE_TYPE = {
  TOWER: 1,
  FLOOR: 2,
  FLAT: 3,
  CIVIL: 4,
  ARCHITECTURAL: 5,
  RCC: 6,
  MEP: 7,
};

export const TASK_PLAN_STATUS = {
  UPCOMING: 0,
  CURRENT: 1,
  PAST: 2,
} as const;

export const PLANNED_STATUS = {
  UPCOMING: 0,
  CURRENT_AND_NOT_STARTED: 1,
  CURRENT_AND_IN_PROGRESS: 2,
  CURRENT_AND_DELAYED: 3,
  PAST: 4,
};

export const BROAD_PLAN_TYPE = {
  WEEKLY_TASK: 0,
  MAIN_TASK: 1,
};

export const WEEK_TYPE = {
  PREV_WEEK: 0,
  CURR_WEEK: 1,
  NEXT_WEEK: 2,
};

export const MAPPED_TASK_TYPE = {
  WEEKLY_TASK: 3,
};

export const PLAN_TYPE = {
  WEEKLY_PLAN: 'weekly_plan',
  MASTER_PLAN: 'master_plan',
};

export const BROAD_PLAN_STATUS_DETAILS = {
  [BROAD_PLAN_TASK_STATUS.NOT_STARTED]: {
    status: 'Not Started',
    col: 'blue-700',
    brCol: 'primary',
  },
  [BROAD_PLAN_TASK_STATUS.INPROGRESS]: {
    status: 'In Progress',
    col: 'yellow-600',
    brCol: 'warning',
  },
  [BROAD_PLAN_TASK_STATUS.COMPLETED]: {
    status: 'Completed',
    col: 'green-800',
    brCol: 'success',
  },
};

export const TASK_CELL_OPTIONS = {
  MARK_AS_COMPLETE: 'mark_as_complete',
  ADD_BELOW: 'add_below',
  ADD_ABOVE: 'add_above',
  MOVE_UP: 'move_up',
  MOVE_DOWN: 'move_down',
  DELETE: 'delete',
  COPY_TASKS: 'copy_tasks',
  PASTE_TASK: 'paste_task',
  ADD_TASK_REMINDER: 'add_task_reminder',
};

export const UPLOAD_FILE_TYPE = {
  EXCEL: 'NESTED/EXCEL',
  MPP: 'MPP/XML',
};

export const TASK_PROFILE_TABS = {
  details: {
    key: 0,
    supressForParent: false,
    label: 'Details',
  },
  timeline: {
    key: 1,
    label: 'Timeline',
    supressForParent: true,
  },
  photos: {
    key: 2,
    label: 'Attachments',
    supressForParent: true,
  },
  issues: {
    key: 3,
    label: 'Issues',
    supressForParent: false,
  },
  checklist: {
    key: 8,
    label: 'Checklist',
    supressForParent: true,
  },
  material_usage: {
    key: 6,
    label: 'Material Used',
    supressForParent: true,
  },
  materials: {
    key: 5,
    label: 'Material Issued',
    supressForParent: true,
  },
  comments: {
    key: 4,
    supressForParent: false,
    label: 'Comments',
  },
  reminders: {
    key: 7,
    supressForParent: true,
    label: 'Reminder',
  },
  resources: {
    key: 9,
    supressForParent: true,
    label: 'Resources',
  },
};

export const sampleFileLink =
  'https://docs.google.com/spreadsheets/d/e/2PACX-1vSkH47qLArM4NS0RjfENYILBUl4jCDAaYlWDWBzRDTxnT1KHbrFwzX6lFIf_SIucWhrOPxzpCNICEka/pubhtml?gid=1047381591&amp;single=true&amp;widget=true&amp;headers=false';

export const templateFileLink =
  'https://docs.google.com/spreadsheets/d/e/2PACX-1vS6Neu8EeySX4dy1enGKFanS65EJCJkYX6_AT-ecCGy-Cbu4xlHKa2CXmXNJh9qRc9vA2mtJRjBa3Bu/pubhtml?gid=532430200&amp;single=true&amp;widget=true&amp;headers=false';

export const TASKLOG_VIDEO_THUMBNAIL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/video-player-placeholder.png';

export const NO_RESULT_FOUND =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/no-result-found.png';

export const LOCAL_STORAGE_SEARCH_STRING = 'searchString';

export const TASK_FILTER_QUERY = 'taskFilterQuery';

export const TASK_FILTER = 'taskFilter';

export const SEARCH_FILTER_ACCESS_TYPE = 2;
