import {
  DELETE_ST_ITEM,
  GET_ST_ITEM,
  ST_API_ERROR,
  ST_API_PENDING,
  UPDATE_ST_ITEM,
} from './SiteTransfer.type';

const pendingInitialStae = {
  pending: false,
};

const initialState = {
  ...pendingInitialStae,
  stItemData: {},
  updateData: {},
  error: null,
};

export default function stReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case GET_ST_ITEM:
      return { ...state, stItemData: payload };
    case UPDATE_ST_ITEM:
      return { ...state, updateData: payload };
    case DELETE_ST_ITEM:
      return { ...state, updateData: payload };
    case ST_API_PENDING:
      return { ...state, pending: payload };
    case ST_API_ERROR:
      return { ...state, error: error };
    default:
      return state;
  }
}
