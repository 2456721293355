import { legacy_createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import checkTokenExpirationMiddleWare from './middleware/CheckTokenExpiration.middleware';
import rootReducer from './Reducers';
import rootSaga from '../sagas';

const composeEnhancers =
  ((window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) ||
  compose;

const saga = createSagaMiddleware();

const store = legacy_createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(saga, thunk, checkTokenExpirationMiddleWare))
);

saga.run(rootSaga);

export type AppDispatch = typeof store.dispatch;

export default store;
