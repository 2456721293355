// @ts-nocheck

import { toNumber } from 'lodash';
import { INR_SYMBOL } from '../constants';
import { divide } from './Math.util';

export const formatAmount = (amount = 0, INR = INR_SYMBOL) => {
  amount = toNumber(amount);

  let formattedStr = amount;
  const value = Math.abs(amount);

  if (value >= 10000000) {
    formattedStr = `${divide(amount, 10000000)} Cr`;
  } else if (value >= 100000) {
    formattedStr = `${divide(amount, 100000)} Lac`;
  } else if (value >= 1000) {
    formattedStr = `${divide(amount, 1000)} K`;
  }

  formattedStr = INR ? `${INR} ${formattedStr}` : formattedStr;

  return formattedStr;
};

export function formatFullAmount(amount = 0, options = {}) {
  amount = toNumber(amount);

  const opts = {
    locales: 'hi-IN',
    currency: 'INR',
    style: 'currency',
    ...options,
  };

  const formatter = Intl.NumberFormat(opts.locales, opts);

  return formatter.format(amount);
}

export function formatFullAmountWithoutCurrency(amount = 0, options = {}) {
  amount = toNumber(amount);

  const opts = {
    locales: 'hi-IN',
    currency: 'INR',
    style: 'currency',
    currencyDisplay: 'code',
    ...options,
  };

  const formatter = Intl.NumberFormat(opts.locales, opts);

  return (
    (formatter?.format(amount) || '')?.replaceAll(opts.currency, '')?.trim() ||
    '0.00'
  );
}
