import {
  ADD_ONE,
  GET_ONE,
  GET_LIST,
  DELETE_ONE,
  UPDATE_ONE,
} from './Base.type';

export default function baseReducer(state = [], { type, payload }) {
  switch (type) {
    case ADD_ONE:
      return { ...state, payload };
    case GET_ONE:
      return { ...state, payload };
    case GET_LIST:
      return { ...state, payload };
    case UPDATE_ONE:
      return { ...state, payload };
    case DELETE_ONE:
      return state.filter(elem => elem.id !== payload.id);
    default:
      return state;
  }
}
