import { createSelector } from 'reselect';

import { MODULES } from '../../constant/module_constants';
import { useAppSelector } from '../../redux/Hooks';

const isAccessAllowed = (userPermissions, columnName) => {
  let isAllowed = false;

  if (userPermissions) {
    const module = userPermissions.find(
      elem => elem.moduleName === MODULES.BUDGET.path
    );

    if (module) {
      isAllowed = module.visibleColumns.some(
        elem => elem.column_name === columnName && !elem.is_visible
      );
    }
  }

  return isAllowed;
};

const permissionSelector = createSelector(
  state => state.userreducer.user.access,
  webItems =>
    // @ts-ignore
    webItems?.map(webItem => ({
      moduleName: webItem.resource_path,
      visibleColumns: webItem.visible_columns,
    }))
);

const useHidden = columnName => {
  const userPermissions = useAppSelector(permissionSelector);
  const permitted = isAccessAllowed(userPermissions, columnName);

  return permitted;
};

export default useHidden;
