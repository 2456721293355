import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Commercial } from '../../../../common/Icons';
import { fromNow } from '../../../../../utils/Date.util';
import { NOTIF_TYPE } from '../../../../../constant/notification_constant';
import { NotificationProps } from '../../../../../interfaces/Notifications';
import { useAppSelector } from '../../../../../redux/Hooks';

type Props = {
  className: string;
  notifObj: NotificationProps;
  handleClick: any;
};

function CommercialNotif({ className, notifObj, handleClick }: Props) {
  const { notification, is_clicked, created_date, data } = notifObj;
  const projectId = useAppSelector(
    state => state.projectreducer.selectedProj.id
  );
  const classes = !is_clicked
    ? clsx(className, 'bg-light-blue')
    : clsx(className);

  const icon_classes = !is_clicked
    ? clsx(className, 'svg-blue')
    : clsx(className, 'svg-grey');
  const link =
    data.type === NOTIF_TYPE.INDENT
      ? `/project/${projectId}/commercial?tab=indent&indId=${data._id}`
      : `/project/${projectId}/commercial/purchase-order/${data._id}`;

  return (
    <div
      className={`d-flex notif-item align-items-center border-bottom py-3 ${classes}`}
    >
      <div className="px-3">
        <Commercial className={`${icon_classes}`} fillOpacity={1} />
      </div>
      <div
        className="fs-s d-flex flex-column flex-grow-1"
        onClick={handleClick(notifObj)}
      >
        <Link to={link} className="fs-s d-flex flex-column no-text-dec">
          <span className="fs-m d-inline-block text-truncate notif-width text-grey-900">
            {notification.title}
          </span>
          <span className="d-inline-block text-truncate notif-width text-dark-grey mt-xl-1">
            {notification.body}
          </span>
        </Link>
      </div>
      <div className="flex-shrink-1 px-2 fs-s light">
        {fromNow(created_date, false)}
      </div>
    </div>
  );
}

export default CommercialNotif;
