import { SET_NAVBAR_TITLE } from './Layout.type';
import { NAVIGATION_SEGMENT } from '../../../../constant/segment_constant';
import { track } from '../Segment.action';

export const setNavbarTitle = data => {
  return dispatch => {
    dispatch(track(NAVIGATION_SEGMENT.MODULE_ACCESSED, { module: data }));
    dispatch({ type: SET_NAVBAR_TITLE, payload: data });
  };
};
