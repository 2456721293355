import { SET_NAVBAR_TITLE } from './Layout.type';

const initialState = {
  navbar_title: 'Project Dashboard',
};

export default function layoutReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_NAVBAR_TITLE:
      return Object.assign({}, state, {
        navbar_title: payload,
      });
    default:
      return state;
  }
}
