/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Modal, Nav } from 'react-bootstrap';

import { UserMenu } from './user';
import RequestSupport from '../../../support/RequestSupport';
import RefreshButton from '../../../common/RefreshButton';
import { setRefreshView } from '../../../../redux/features/common/refresh/Refresh.action';
import {
  SYNC_DASHBOARD_VIEW,
  SYNC_COMMERCIAL_VIEW,
  SYNC_WORK_CATEGORIES_VIEW,
  SYNC_BOM_VIEW,
  SYNC_VENDOR_VIEW,
  SYNC_ORGANIZATION_PROFILE_VIEW,
  SYNC_TEAM_VIEW,
  SYNC_MASTER_VENDOR_VIEW,
  SYNC_MASTER_BOM_VIEW,
  SYNC_INVITE_VIEW,
  SYNC_PACKAGES_VIEW,
  SYNC_DOCUMENTS_VIEW,
  SYNC_INVOICES_VIEW,
  SYNC_REPORTS_VIEW,
  SYNC_PROJECT_PROFILE_VIEW,
  SYNC_THREADS_VIEW,
  SYNC_ACTIVITY_VIEW,
  SYNC_BUDGET_VIEW,
  SYNC_GRN_LIST_VIEW,
  SYNC_ATTENDANCE_VIEW,
  SYNC_SITE_PLAN_VIEW,
} from '../../../../redux/features/common/refresh/Refresh.type';
import { NotificationMenu } from './notifications';
import CustomTooltip from '../../../common/customTooltip/CustomTooltip';
import { WhatsNew } from '../../../common';
import { openInNewTab } from '../../../../utils';
import { NEW_UPDATE_URL } from '../../../../constants';
import { track } from '../../../../redux/features/common/Segment.action';
import { BUTTON_EVENTS, UI_EVENTS } from '../../../../constant';
import TrialPeriod from './TrialPeriod';
import { useAppDispatch, useAppSelector } from '../../../../redux/Hooks';

const NavbarNav = ({ orgView }) => {
  const projectid = useAppSelector(
    state => state.projectreducer.selectedProj.id
  );
  const pageName = useAppSelector(state => state.layoutreducer.navbar_title); // TODO use path name from history to change sync
  const [showModal, setShowModal] = useState(false);
  const [lastSyncTime, setLastSyncTime] = useState(Date.now());

  const projName = useAppSelector(
    state => state.projectreducer.selectedProj.name
  );

  const dispatch = useAppDispatch();

  const onHide = () => {
    setShowModal(false);
  };

  const handleRefresh = () => {
    switch (pageName) {
      case 'Project Dashboard':
        dispatch(setRefreshView(SYNC_DASHBOARD_VIEW, true));
        break;
      case 'Work Categories':
        dispatch(setRefreshView(SYNC_WORK_CATEGORIES_VIEW, true));
        break;
      case 'Tasks':
        dispatch(setRefreshView(SYNC_SITE_PLAN_VIEW, true));
        break;
      case 'Commercial':
        dispatch(setRefreshView(SYNC_COMMERCIAL_VIEW, true));
        break;
      case 'Bill Of Materials':
        dispatch(setRefreshView(SYNC_BOM_VIEW, true));
        break;
      case 'Vendor':
        dispatch(setRefreshView(SYNC_VENDOR_VIEW, true));
        break;
      case 'Organization Profile':
        dispatch(setRefreshView(SYNC_ORGANIZATION_PROFILE_VIEW, true));
        break;
      case 'Roles':
        dispatch(setRefreshView(SYNC_TEAM_VIEW, true));
        break;
      case 'Vendors':
        dispatch(setRefreshView(SYNC_MASTER_VENDOR_VIEW, true));
        break;
      case 'Master Bill Of Materials':
        dispatch(setRefreshView(SYNC_MASTER_BOM_VIEW, true));
        break;
      case 'Issues':
        dispatch(setRefreshView(SYNC_THREADS_VIEW, true));
        break;
      case 'Activity':
        dispatch(setRefreshView(SYNC_ACTIVITY_VIEW, true));
        break;
      case 'Budget & Billing':
        dispatch(setRefreshView(SYNC_BUDGET_VIEW, true));
        break;
      case 'Invite':
        dispatch(setRefreshView(SYNC_INVITE_VIEW, true));
        break;
      case 'Packages':
        dispatch(setRefreshView(SYNC_PACKAGES_VIEW, true));
        break;
      case 'Documents':
        dispatch(setRefreshView(SYNC_DOCUMENTS_VIEW, true));
        break;
      case 'Invoices':
        dispatch(setRefreshView(SYNC_INVOICES_VIEW, true));
        break;
      case 'Reports':
        dispatch(setRefreshView(SYNC_REPORTS_VIEW, true));
        break;
      case 'Project Profile':
        dispatch(setRefreshView(SYNC_PROJECT_PROFILE_VIEW, true));
        break;
      case 'Finance':
        dispatch(setRefreshView(SYNC_GRN_LIST_VIEW, true));
        break;
      case 'Attendance':
        dispatch(setRefreshView(SYNC_ATTENDANCE_VIEW, true));
        break;
      case 'Site Plan': {
        dispatch(setRefreshView(SYNC_SITE_PLAN_VIEW, true));
        break;
      }
    }
    setLastSyncTime(Date.now());
  };

  const handleWhatsNewClick = () => {
    dispatch(track(BUTTON_EVENTS.WHATS_NEW_CLICKED));
    try {
      openInNewTab(NEW_UPDATE_URL);
      dispatch(track(UI_EVENTS.DASHBOARD.WHATS_NEW_LOAD_SUCCESS));
    } catch (err) {
      dispatch(track(UI_EVENTS.DASHBOARD.WHATS_NEW_LOAD_FAILURE));
    }
  };

  return (
    <Nav
      navbar
      className="nav-container h-100 w-100 flex-row justify-content-end flex-right"
    >
      {orgView || !projectid
        ? ''
        : projectid && (
            <div className="d-flex align-items-center mr-auto">
              <CustomTooltip title={projName}>
                <div className="navbar-proj-name text-truncate pr-2">
                  {projName}
                </div>
              </CustomTooltip>
              <RefreshButton
                noLabel
                reloadTime={lastSyncTime}
                onClick={handleRefresh}
              />
            </div>
          )}
      <div className="d-flex flex-row align-items-center">
        <TrialPeriod />

        {NEW_UPDATE_URL && (
          <Nav.Item
            className="whats-new-div mx-2 hand-pointer"
            onClick={handleWhatsNewClick}
          >
            <WhatsNew className="fes-xxl mr-1" />
            Whats New!
          </Nav.Item>
        )}

        {projectid && (
          <Nav.Item>
            <NotificationMenu />
          </Nav.Item>
        )}
        <UserMenu />
      </div>
      <Modal centered className="br-4" show={showModal} onHide={onHide}>
        <Modal.Body className="p-0 p-2">
          <RequestSupport onHide={onHide} />
        </Modal.Body>
      </Modal>
    </Nav>
  );
};

export default NavbarNav;
