// @ts-nocheck

import { createSelector } from 'reselect';

import { MODULE_ID_MAP } from '../../constant/module_constants';
import { useAppSelector } from '../../redux/Hooks';

const isLabelAllowed = (userPermissions, labelName) => {
  let isAllowed = true;

  if (userPermissions) {
    isAllowed = userPermissions.some(
      elem => elem.moduleId === MODULE_ID_MAP[labelName]
    );
  }

  return isAllowed;
};

const permissionSelector = createSelector(
  state => state.userreducer.user.viewPermissions,
  webItems =>
    webItems?.map(webItem => ({
      moduleName: webItem.resource_name,
      permission: webItem.permission_type,
      moduleId: webItem.resource_id,
    }))
);

const useSubMenu = labelName => {
  const userPermissions = useAppSelector(permissionSelector);
  const permitted = isLabelAllowed(userPermissions, labelName);

  return permitted;
};

export default useSubMenu;
