import {
  GET_GRN,
  GET_ALL_GRNS,
  GET_GRN_PENDING,
  GET_GRNS_PENDING,
  GRN_API_ERROR,
  SET_GRN_FILTER_VALUES,
  SET_IF_GRN_FILTER,
  GET_ALL_GRN_PAYMENTS,
  GET_GRN_PAYMENT,
  GRN_PAYMENTS_PENDING,
  GRN_PAYMENT_PENDING,
  SET_GRN_PAYMENT_FILTER_VALUES,
  SET_GRN_PAYMENT_IF_FILTER,
  SET_SELECTED_GRN_PAYMENTS,
  GET_GRN_PAYMENT_SUMMARY,
  GET_GRN_BY_ORG_ID,
  GET_GRN_ORG_ID_COUNT,
  GET_GRN_TOTAL_COST,
  SET_GRN_PAYMENT_DATA,
  GET_ITEM_PAYMENT_DETAILS,
  GET_GRN_BY_ORG_PENDING,
  CREATE_GRN_PENDING,
} from './Grn.type';
const initialPendingState = {
  getAllGrnsPending: false,
  getGrnPending: false,
  getGrnPaymentPending: false,
  getAllGrnPaymentPending: false,
  getGrnByOrgPending: false,
  createGrnPending: false,
};

const initialState = {
  ...initialPendingState,
  grn: {},
  grns: [],
  error: null,
  selectedGRN: null,
  isFilter: false,
  filterValues: {},
  grnPayment: {},
  grnPayments: [],
  selectedGrnPayments: [],
  isGrnPaymentFilter: false,
  grnPaymentFilterValues: {},
  grnPaymentSummary: {},
  grnByOrgId: {
    docs: [],
    limit: 0,
    page: 0,
    pages: 0,
    total: 0,
  },
  totalGrnCost: 0,
  grnPaymentData: {},
  itemPaymentDetails: [],
};
export default function grnReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case GET_GRN:
      return { ...state, grn: payload };
    case GET_GRNS_PENDING:
      return { ...state, getAllGrnsPending: payload };
    case GET_GRN_PENDING:
      return { ...state, getGrnPending: payload };
    case GET_ALL_GRNS:
      return { ...state, grns: payload };
    case GRN_API_ERROR:
      return { ...state, error: error };
    case SET_GRN_FILTER_VALUES:
      return { ...state, filterValues: payload };
    case SET_IF_GRN_FILTER:
      return { ...state, isFilter: payload };
    case GET_GRN_PAYMENT:
      return { ...state, grnPayment: payload };
    case GET_ALL_GRN_PAYMENTS:
      return { ...state, grnPayments: payload };
    case GRN_PAYMENTS_PENDING:
      return { ...state, getAllGrnPaymentPending: payload };
    case GRN_PAYMENT_PENDING:
      return { ...state, getGrnPaymentPending: payload };
    case SET_GRN_PAYMENT_FILTER_VALUES:
      return { ...state, grnPaymentFilterValues: payload };
    case SET_GRN_PAYMENT_IF_FILTER:
      return { ...state, isGrnPaymentFilter: payload };
    case SET_SELECTED_GRN_PAYMENTS:
      return { ...state, selectedGrnPayments: payload };
    case GET_GRN_PAYMENT_SUMMARY:
      return { ...state, grnPaymentSummary: payload };
    case GET_GRN_BY_ORG_ID: {
      const obj = { ...state.grnByOrgId, ...payload };
      obj.pages = Math.ceil(obj.total / obj.limit);
      return { ...state, grnByOrgId: obj };
    }
    case GET_GRN_BY_ORG_PENDING: {
      return { ...state, getGrnByOrgPending: payload };
    }
    case GET_GRN_ORG_ID_COUNT: {
      const obj = { ...state.grnByOrgId, total: payload };
      obj.pages = Math.ceil(obj.total / obj.limit);
      return { ...state, grnByOrgId: obj };
    }
    case GET_GRN_TOTAL_COST: {
      return { ...state, totalGrnCost: payload };
    }
    case SET_GRN_PAYMENT_DATA: {
      return { ...state, grnPaymentData: payload };
    }
    case GET_ITEM_PAYMENT_DETAILS: {
      return { ...state, itemPaymentDetails: payload };
    }
    case CREATE_GRN_PENDING: {
      return { ...state, createGrnPending: payload };
    }
    default:
      return state;
  }
}
