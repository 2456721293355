import { useState } from 'react';
import {
  Box,
  Checkbox,
  Container,
  Divider,
  Fade,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material';

import { OnboardingStepProps } from './types';
import {
  CustomButton,
  CustomDialogActions,
  CustomDialogContent,
} from '../common';
import { ONBOARDING_STEPS } from '../../views/Onboarding/OnboardingDialog';
import { ONBOARDING_MODULES } from './onboarding_constants';
import { track } from '../../redux/features/common/Segment.action';
import { useAppDispatch } from '../../redux/Hooks';

const SelectModules = (props: OnboardingStepProps) => {
  const dispatch = useAppDispatch();
  const { selectedModule, setSelectedModule, setStep, setOnboardingQuestions } =
    props;

  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);

  const handleClick = mod => () => {
    setSelectedQuestions([]);
    setSelectedModule(mod.id);
    dispatch(track('onboarding_module_selected', { module: mod.id }));
  };

  const mod = ONBOARDING_MODULES.find(mod => mod.id === selectedModule);

  const handleChange = (e, checked) => {
    const val = e.target.value;
    const s = new Set(selectedQuestions);

    if (checked) {
      s.add(val);
    } else {
      s.delete(val);
    }

    setSelectedQuestions(Array.from(s));
  };

  const handleNext = () => {
    const s = new Set(selectedQuestions);
    const questions = mod.questions.filter(q => s.has(q.title));
    setStep(ONBOARDING_STEPS.INVITING_MEMBERS);

    setOnboardingQuestions(questions);
    dispatch(
      track('onboarding_module_selected', { module: mod.id, value: questions })
    );
  };

  return (
    <>
      <CustomDialogContent>
        <Fade in timeout={500}>
          <Container maxWidth="md" className="mt-3 h-100 onboarding-dialog">
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="stretch"
              spacing={10}
            >
              {ONBOARDING_MODULES.map(mod => (
                <Grid
                  item
                  xs={4}
                  key={mod.id}
                  className="text-center hand-pointer"
                >
                  <Box
                    paddingX={2}
                    paddingY={2}
                    className={`h-100 module-card ${
                      mod.id === selectedModule ? 'selected' : ''
                    }`}
                    borderRadius="8px"
                    onClick={handleClick(mod)}
                  >
                    <div>{<mod.icon style={{ fontSize: 80 }} />}</div>
                    <div className="mt-1">{mod.name}</div>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <div className="text-default mt-4 fw-bold">{mod.heading}</div>
            <FormGroup>
              <Fade in timeout={500}>
                <div>
                  {mod.questions.map((q, idx) => (
                    <div key={q.title}>
                      <FormControlLabel
                        style={{ alignItems: 'flex-start' }}
                        className="py-3"
                        control={
                          <Checkbox
                            color="primary"
                            size="small"
                            value={q.title}
                            onChange={handleChange}
                            style={{ marginTop: -1 }}
                          />
                        }
                        label={
                          <>
                            <div className="fes-m mt-2 fw-bold">{q.title}</div>
                            <div className="fes-s mt-1 light">{q.desc}</div>
                          </>
                        }
                      />
                      {idx < mod.questions.length - 1 && <Divider />}
                    </div>
                  ))}
                </div>
              </Fade>
            </FormGroup>
          </Container>
        </Fade>
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton
          label={'Start inviting members'}
          disabled={Boolean(!selectedQuestions.length)}
          onClick={handleNext}
        />
      </CustomDialogActions>
    </>
  );
};

export default SelectModules;
