export const SET_AUTO_DPR_DATA = 'SET_AUTO_DPR_DATA';

export const SET_AUTO_DPR_MEMBERS = 'SET_AUTO_DPR_MEMBERS';

export const SET_AUTO_DPR_API_SUCCESS = 'SET_AUTO_DPR_API_SUCCESS';

export const SET_AUTO_DPR_API_PENDING = 'SET_AUTO_DPR_API_PENDING';

export const SET_AUTO_DPR_API_ERROR = 'SET_AUTO_DPR_API_ERROR';

export const SET_AUTO_DPR_LOADING = 'SET_AUTO_DPR_LOADING';
