import {
  AttendanceIcon,
  MaterialVendorIcon,
  ProjectTaskPlanIcon,
} from '../common';

export const ACTION_TYPES = {
  UPDATE_INVENTORY: 0,
  RECIEVE_THE_MATERIAL: 1,
  RAISE_MATERIAL_REQUEST: 2,
  WILL_APPROVE_MATERIAL_REQUEST: 3,
  ORDER_THE_REQUESTED_MATERIAL: 4,
  RECORD_THE_RECEIVED_MATERIAL_AT_SITE: 5,
  RECORD_MATERIAL_CONSUMPTION: 6,
  TRACK_INVENTORY: 7,
  RECORD_PETTY_EXPENSES: 8,
  TRACK_PETTY_EXPENSES: 9,
  CREATE_TASK: 10,
  UPDATE_TASK_PROGRESS: 11,
  DOWNLOAD_TASK_PROGRESS_REPORT: 12,
  RAISE_A_ISSUE: 13,
  RESOLVE_A_ISSUE: 14,
  TRACK_AN_ISSUE: 15,
  MARK_ATTENDANCE: 16,
  DOWNLOAD_ATTENDANCE_PAYABLE_REPORT: 17,
  NEW_UPDATE_INVENTORY: 100,
  NEW_RAISE_MATERIAL_REQUEST: 101,
  GENERATE_MATERIAL_STOCK_REPORT: 102,
  ORDER_MATERIAL: 103,
  APPROVE_MATERIAL_REQUEST: 104,
  RECEIVE_MATERIAL: 105,
  GENERATE_MATERIAL_REPORT: 106,
  NEW_RECEIVE_MATERIAL: 107,
  MARK_MATERIAL_USED: 108,
  ISSUE_MATERIAL: 109,
  CREATE_PURCHASE_ORDER: 110,
  APPROVE_PURCHASE_ORDER: 111,
  GENEREATE_PURCHASE_ORDER_REPORT: 112,
  CREATE_WORK_PLAN: 114,
  UPDATE_WORK_PROGRESS: 115,
  GENERATE_PROGRESS_REPORT: 116,
  CREATE_ISSUES: 117,
  REMOVE_BLOCKERS: 118,
  NEW_MARK_ATTENDANCE: 119,
  GENERATE_PAYABLES: 120,
  UPDATE_ON_SC_WORK: 121,
  GENERATE_WORK_PROGRESS_REPORT: 122,
  DownloadProgressReport: 123,
  DownloadProgressReportForClient: 124,
  DownloadProgressReportForSelfAndClient: 125,
  DownloadDailyProgressReport: 126,
  DownloadMonthlyProgressReport: 127,
  DownloadWeeklyProgressReport: 128,
  CreateInventory: 129,
  CreateSimpleOrder: 130,
  MakePaymentToMaterialVendor: 131,
  TrackMaterialRequested: 132,
  TrackMaterialFromOneSiteToAnother: 133,
  TrackMaterialConsumption: 134,
  IssueMaterialOnWorkTask: 135,
  ApproveIssuedMaterial: 136,
  IssueMaterialToSubContractor: 137,
  IssueMaterialOnProject: 138,
  CreateVendorList: 139,
  RecordVendorAttendance: 140,
  CreateSelfLabourList: 141,
  RecordSelfLabourAttendance: 142,
  MakeLabourPayment: 143,
  MakeAdvancePayment: 144,
  TrackMaterialUsageOnTaskProjectSC: 145,
  IssueMaterialForTaskProjectSc: 146,
  ApproveSiteTransfer: 147,
};

export const ACTION_TYPES_TEXT = {
  [ACTION_TYPES.UPDATE_INVENTORY]: 'Update Inventory',
  [ACTION_TYPES.RECIEVE_THE_MATERIAL]: 'Recieve the material',
  [ACTION_TYPES.RAISE_MATERIAL_REQUEST]: 'Raise material request',
  [ACTION_TYPES.WILL_APPROVE_MATERIAL_REQUEST]: 'Approve material request',
  [ACTION_TYPES.ORDER_THE_REQUESTED_MATERIAL]: 'Order requested Material',
  [ACTION_TYPES.RECORD_THE_RECEIVED_MATERIAL_AT_SITE]:
    'Record recieved material at site',
  [ACTION_TYPES.RECORD_MATERIAL_CONSUMPTION]: 'Record material consumption',
  [ACTION_TYPES.TRACK_INVENTORY]: 'Track Inventory',
  [ACTION_TYPES.RECORD_PETTY_EXPENSES]: 'Record petty expenses',
  [ACTION_TYPES.TRACK_PETTY_EXPENSES]: 'Track petty expenses',
  [ACTION_TYPES.CREATE_TASK]: 'Create task',
  [ACTION_TYPES.UPDATE_TASK_PROGRESS]: 'Update task progress',
  [ACTION_TYPES.DOWNLOAD_TASK_PROGRESS_REPORT]: 'Download task report',
  [ACTION_TYPES.RAISE_A_ISSUE]: 'Raise issues',
  [ACTION_TYPES.RESOLVE_A_ISSUE]: 'Resolve issues',
  [ACTION_TYPES.TRACK_AN_ISSUE]: 'Track issues',
  [ACTION_TYPES.MARK_ATTENDANCE]: 'Mark attendances',
  [ACTION_TYPES.DOWNLOAD_ATTENDANCE_PAYABLE_REPORT]:
    'Download attendance payable report',
  [ACTION_TYPES.NEW_UPDATE_INVENTORY]: 'Update Inventory',
  [ACTION_TYPES.NEW_RAISE_MATERIAL_REQUEST]: 'Request Material',
  [ACTION_TYPES.GENERATE_MATERIAL_STOCK_REPORT]:
    'Generate Material Stock Report',
  [ACTION_TYPES.ORDER_MATERIAL]: 'Order Material',
  [ACTION_TYPES.APPROVE_MATERIAL_REQUEST]: 'Approve Material Request',
  [ACTION_TYPES.RECEIVE_MATERIAL]: 'Receive Material',
  [ACTION_TYPES.GENERATE_MATERIAL_REPORT]: 'Generate Material Report',
  [ACTION_TYPES.NEW_RECEIVE_MATERIAL]: 'Recieve Material',
  [ACTION_TYPES.MARK_MATERIAL_USED]: 'Mark Material Used',
  [ACTION_TYPES.ISSUE_MATERIAL]: 'Issue Material',
  [ACTION_TYPES.CREATE_PURCHASE_ORDER]: 'Create Purchase Order',
  [ACTION_TYPES.APPROVE_PURCHASE_ORDER]: 'Approve Purchase Order',
  [ACTION_TYPES.GENEREATE_PURCHASE_ORDER_REPORT]:
    'Generate Purchase Order Report',
  [ACTION_TYPES.CREATE_WORK_PLAN]: 'Create Work Plan',
  [ACTION_TYPES.UPDATE_WORK_PROGRESS]: 'Give Work Progress Update',
  [ACTION_TYPES.GENERATE_PROGRESS_REPORT]: 'Generate Progress Report',
  [ACTION_TYPES.CREATE_ISSUES]: 'Raise on-site Issues',
  [ACTION_TYPES.REMOVE_BLOCKERS]: 'Remove Site Blockers',
  [ACTION_TYPES.NEW_MARK_ATTENDANCE]: 'Mark Attendance',
  [ACTION_TYPES.GENERATE_PAYABLES]: 'Generate Payables',
  [ACTION_TYPES.UPDATE_ON_SC_WORK]: 'Update On Sc Work',
  [ACTION_TYPES.GENERATE_WORK_PROGRESS_REPORT]: 'Generate Work Progress Report',
  [ACTION_TYPES.DownloadProgressReport]: 'Download progress report',
  [ACTION_TYPES.DownloadProgressReportForClient]:
    'Download progress report for client',
  [ACTION_TYPES.DownloadProgressReportForSelfAndClient]:
    'Download progress report for self & client',
  [ACTION_TYPES.DownloadDailyProgressReport]: 'Download daily progress report',
  [ACTION_TYPES.DownloadWeeklyProgressReport]:
    'Download weekly progress report',
  [ACTION_TYPES.DownloadMonthlyProgressReport]:
    'Download monthly progress report',
  [ACTION_TYPES.CreateInventory]: 'Create Inventory',
  [ACTION_TYPES.CreateSimpleOrder]: 'Create simple order',
  [ACTION_TYPES.MakePaymentToMaterialVendor]:
    'Make payment to material vendors',
  [ACTION_TYPES.TrackMaterialRequested]: 'Track requested material',
  [ACTION_TYPES.TrackMaterialFromOneSiteToAnother]:
    'Track material sent from one site to another',
  [ACTION_TYPES.TrackMaterialConsumption]: 'Track material consumption',
  [ACTION_TYPES.IssueMaterialOnWorkTask]: 'Issue material on work/task',
  [ACTION_TYPES.ApproveIssuedMaterial]: 'Approve issued material',
  [ACTION_TYPES.IssueMaterialToSubContractor]:
    'Issue material to sub-contractor',
  [ACTION_TYPES.IssueMaterialOnProject]: 'Issue material on a project',
  [ACTION_TYPES.CreateVendorList]: 'Create vendor list',
  [ACTION_TYPES.RecordVendorAttendance]: 'Record vendor attendance',
  [ACTION_TYPES.CreateSelfLabourList]: 'Create self labour list',
  [ACTION_TYPES.RecordSelfLabourAttendance]: 'Record self labour attendance',
  [ACTION_TYPES.MakeLabourPayment]: 'Make labour payments',
  [ACTION_TYPES.MakeAdvancePayment]: 'Make advance payment',
  [ACTION_TYPES.TrackMaterialUsageOnTaskProjectSC]:
    'Track material usage on a specific task / Project / Subcontractor',
  [ACTION_TYPES.IssueMaterialForTaskProjectSc]:
    'Issue material for any task / Project / Subcontractor',
  [ACTION_TYPES.ApproveSiteTransfer]: 'Approve site transfer',
};

export const ACTION_TYPES_TUTORIALS = {
  [ACTION_TYPES.UPDATE_INVENTORY]:
    'https://drive.google.com/file/d/1i9qEPaFiclS46EQqPXP4iKF2gyP33TMu/view',
  [ACTION_TYPES.RECIEVE_THE_MATERIAL]:
    'https://drive.google.com/file/d/1j-IsNK9fopEn7y0r17TgvhbZS7SeQIN5/view?usp=sharing',
  [ACTION_TYPES.RAISE_MATERIAL_REQUEST]:
    'https://drive.google.com/file/d/1j-IsNK9fopEn7y0r17TgvhbZS7SeQIN5/view?usp=sharing',
  [ACTION_TYPES.WILL_APPROVE_MATERIAL_REQUEST]:
    'https://drive.google.com/file/d/19S1S4fGgTPkgC_ISiyICaCOpEc21-hAL/view?usp=sharing',
  [ACTION_TYPES.ORDER_THE_REQUESTED_MATERIAL]:
    'https://drive.google.com/file/d/1j-IsNK9fopEn7y0r17TgvhbZS7SeQIN5/view?usp=sharing',
  [ACTION_TYPES.RECORD_THE_RECEIVED_MATERIAL_AT_SITE]:
    'https://drive.google.com/file/d/1j-IsNK9fopEn7y0r17TgvhbZS7SeQIN5/view?usp=sharing',
  [ACTION_TYPES.RECORD_MATERIAL_CONSUMPTION]:
    'https://drive.google.com/file/d/1PBnTOfl8B48_jS_iy3r-twsHQRwRBHdT/view?usp=sharing',
  [ACTION_TYPES.TRACK_INVENTORY]:
    'https://drive.google.com/file/d/1i9qEPaFiclS46EQqPXP4iKF2gyP33TMu/view?usp=sharing',
  [ACTION_TYPES.RECORD_PETTY_EXPENSES]: null,
  [ACTION_TYPES.TRACK_PETTY_EXPENSES]: null,
  [ACTION_TYPES.CREATE_TASK]: null,
  [ACTION_TYPES.UPDATE_TASK_PROGRESS]:
    'https://drive.google.com/file/d/1DUM5CqZepT2UTrp_p8vEINkUqCUCE4rj/view?usp=sharing',
  [ACTION_TYPES.DOWNLOAD_TASK_PROGRESS_REPORT]:
    'https://drive.google.com/file/d/1Dj03r3n7rrrkkGNWLT7svVhebNyJFTz4/view?usp=sharing',
  [ACTION_TYPES.RAISE_A_ISSUE]:
    'https://drive.google.com/file/d/11rj3HX10ZFvBM5dx7GLuWgDjUCnLppTl/view?usp=share_link',
  [ACTION_TYPES.RESOLVE_A_ISSUE]: null,
  [ACTION_TYPES.TRACK_AN_ISSUE]: null,
  [ACTION_TYPES.MARK_ATTENDANCE]:
    'https://drive.google.com/file/d/1WQjsuhN3mHpJtJAn9cxcS_7KH_0cZ34L/view?usp=sharing',
  [ACTION_TYPES.DOWNLOAD_ATTENDANCE_PAYABLE_REPORT]:
    'https://drive.google.com/file/d/1I1BzI_8a8bzVkTLEaSA_22ADzZIVAKG5/view?usp=sharing',
  [ACTION_TYPES.NEW_UPDATE_INVENTORY]:
    'https://drive.google.com/file/d/1i9qEPaFiclS46EQqPXP4iKF2gyP33TMu/view?usp=sharing',
  [ACTION_TYPES.NEW_RAISE_MATERIAL_REQUEST]:
    'https://drive.google.com/file/d/15forYoibba7yhn9oUvYmtP2aYct0I4yO/view?usp=sharing',
  [ACTION_TYPES.GENERATE_MATERIAL_STOCK_REPORT]:
    'https://drive.google.com/file/d/1Dj03r3n7rrrkkGNWLT7svVhebNyJFTz4/view?usp=sharing',
  [ACTION_TYPES.ORDER_MATERIAL]:
    'https://drive.google.com/file/d/15forYoibba7yhn9oUvYmtP2aYct0I4yO/view?usp=sharing',
  [ACTION_TYPES.APPROVE_MATERIAL_REQUEST]:
    'https://drive.google.com/file/d/19S1S4fGgTPkgC_ISiyICaCOpEc21-hAL/view?usp=sharing',
  [ACTION_TYPES.NEW_RECEIVE_MATERIAL]:
    'https://drive.google.com/file/d/1j-IsNK9fopEn7y0r17TgvhbZS7SeQIN5/view?usp=sharing',
  [ACTION_TYPES.RECEIVE_MATERIAL]:
    'https://drive.google.com/file/d/1j-IsNK9fopEn7y0r17TgvhbZS7SeQIN5/view?usp=sharing',
  [ACTION_TYPES.GENERATE_MATERIAL_REPORT]:
    'https://drive.google.com/file/d/1j-IsNK9fopEn7y0r17TgvhbZS7SeQIN5/view?usp=sharing',
  [ACTION_TYPES.RECEIVE_MATERIAL]:
    'https://drive.google.com/file/d/1j-IsNK9fopEn7y0r17TgvhbZS7SeQIN5/view?usp=sharing',
  [ACTION_TYPES.MARK_MATERIAL_USED]:
    'https://drive.google.com/file/d/1PBnTOfl8B48_jS_iy3r-twsHQRwRBHdT/view?usp=sharing',
  [ACTION_TYPES.ISSUE_MATERIAL]:
    'https://drive.google.com/file/d/1PBnTOfl8B48_jS_iy3r-twsHQRwRBHdT/view?usp=sharing',
  [ACTION_TYPES.CREATE_PURCHASE_ORDER]:
    'https://drive.google.com/file/d/1j-IsNK9fopEn7y0r17TgvhbZS7SeQIN5/view?usp=sharing',
  [ACTION_TYPES.APPROVE_PURCHASE_ORDER]:
    'https://drive.google.com/file/d/19S1S4fGgTPkgC_ISiyICaCOpEc21-hAL/view?usp=sharing',
  [ACTION_TYPES.GENEREATE_PURCHASE_ORDER_REPORT]:
    'https://drive.google.com/file/d/1j-IsNK9fopEn7y0r17TgvhbZS7SeQIN5/view?usp=sharing',
};

export const ONBOARDING_MODULES = [
  {
    id: 'task',
    name: 'Task Progress',
    icon: ProjectTaskPlanIcon,
    heading: 'Track work Progress & Generate report',
    questions: [
      {
        title: 'Visibility on task progress',
        desc: 'Get visibility on Task progress and generate progress report.',
        actions: [
          {
            key: ACTION_TYPES.CREATE_TASK,
            text: 'Who will create task?',
          },
          {
            key: ACTION_TYPES.UPDATE_TASK_PROGRESS,
            text: 'Who will update task?',
          },
          {
            key: ACTION_TYPES.DOWNLOAD_TASK_PROGRESS_REPORT,
            text: 'Who will track the progress/download report?',
          },
        ],
      },
      {
        title: 'Track Blockers in Work Progress',
        desc: 'Raise the issues on the site to get it resolved and remove the blockers on the site.',
        actions: [
          {
            key: ACTION_TYPES.RAISE_A_ISSUE,
            text: 'Who will create issue?',
          },
          {
            key: ACTION_TYPES.RESOLVE_A_ISSUE,
            text: 'Who will take action on that assigned issue?',
          },
          {
            key: ACTION_TYPES.TRACK_AN_ISSUE,
            text: 'Who will track the overall issue progress?',
          },
        ],
      },
      {
        title: 'Track sub-contractor efficiency',
        desc: 'Get visibility of committed work and delivered work by sub contractor in certain period of time.',
        actions: [
          {
            key: ACTION_TYPES.UPDATE_TASK_PROGRESS,
            text: 'Who will update the subcontracotor updates?',
          },
          {
            key: ACTION_TYPES.DOWNLOAD_TASK_PROGRESS_REPORT,
            text: 'Who will track the effieciency/work progress of the subcontracotor?',
          },
        ],
      },
    ],
  },
  {
    id: 'material',
    name: 'Material Management',
    icon: MaterialVendorIcon,
    heading: 'What you want to Manage in Material?',
    questions: [
      {
        title: 'Available Stock',
        desc: 'See all your available materials at one place and get rid of stock registers.',
        actions: [
          {
            key: ACTION_TYPES.UPDATE_INVENTORY,
            text: 'Who will update the inventory?',
          },
          {
            key: ACTION_TYPES.RECIEVE_THE_MATERIAL,
            text: 'Who will recieve the material at site?',
          },
        ],
      },
      {
        title: 'Track indent, Approved & Received',
        desc: 'Easy Approval of requested material and visibility over received material. No more delay in ordering Material.',
        actions: [
          {
            key: ACTION_TYPES.RAISE_MATERIAL_REQUEST,
            text: 'Who will raise indent?',
          },
          {
            key: ACTION_TYPES.WILL_APPROVE_MATERIAL_REQUEST,
            text: 'Who will approve request?',
          },
          {
            key: ACTION_TYPES.ORDER_THE_REQUESTED_MATERIAL,
            text: 'Who will order the material?',
          },
          {
            key: ACTION_TYPES.RECORD_THE_RECEIVED_MATERIAL_AT_SITE,
            text: 'Who will recieve the material at site?',
          },
        ],
      },
      {
        title: 'Material Usage',
        desc: 'Get visibility on where material is getting used and stop the over use of material.',
        actions: [
          {
            key: ACTION_TYPES.RECORD_THE_RECEIVED_MATERIAL_AT_SITE,
            text: 'Who will mark where material is being used?',
          },
          {
            key: ACTION_TYPES.TRACK_INVENTORY,
            text: 'Who will track inventory stock?',
          },
          {
            key: ACTION_TYPES.RECORD_MATERIAL_CONSUMPTION,
            text: 'Who will issue material on task?',
          },
          {
            key: ACTION_TYPES.RECIEVE_THE_MATERIAL,
            text: 'Who will recieve the material at site?',
          },
        ],
      },
      {
        title: 'Track employee expenses',
        desc: 'Get visibility on how much amount given and expense made by Site Engineer or project manager.',
        actions: [
          {
            key: ACTION_TYPES.RECORD_PETTY_EXPENSES,
            text: 'Who will make expenses?',
          },
          {
            key: ACTION_TYPES.TRACK_PETTY_EXPENSES,
            text: 'Who will track expenses?',
          },
        ],
      },
    ],
  },
  {
    id: 'attendance',
    name: 'Manage Attendance',
    icon: AttendanceIcon,
    heading: 'Track Attendance and Payables',
    questions: [
      {
        title: 'Track Attendance of labours and make payables',
        desc: 'Record attendance and get rid of the register and make paybles',
        actions: [
          {
            key: ACTION_TYPES.MARK_ATTENDANCE,
            text: 'Who will mark the attendance?',
          },
          {
            key: ACTION_TYPES.DOWNLOAD_ATTENDANCE_PAYABLE_REPORT,
            text: 'Who will calculate the payable?',
          },
        ],
      },
      {
        title: 'Supervise the subcontractors labour',
        desc: 'Have visibility on count of vendor labours attendance.',
        actions: [
          {
            key: ACTION_TYPES.MARK_ATTENDANCE,
            text: 'Who will mark the vendor attendance?',
          },
          {
            key: ACTION_TYPES.DOWNLOAD_ATTENDANCE_PAYABLE_REPORT,
            text: 'Who will calculate the payable?',
          },
        ],
      },
    ],
  },
];

// New onboarding flow constants
export const NEW_ONBOARDING_QUESTIONS_TYPE = {
  SINGLE_SELECT: 0,
  MULTI_SELECT_DESC: 1,
  MULTI_SELECT_NO_DESC: 2,
  DROPDOWN_SINGLE_SELECT: 3,
  TEXT: 4,
  MISC: 5,
  MODULE_SELECT: 6,
  DROPDOWN_MULTI_SELECT: 7,
};

export const NEW_ONBOARDING_MODULES = {
  NONE: 0,
  TASK: 1,
  ATTENDANCE: 2,
  MATERIAL: 3,
};

export const NEW_ONBOARDING_QUESTIONS_ACTIONS = {
  UpdateInventory: 0,
  RaiseMaterialRequest: 1,
  GenerateMaterialStockReport: 2,
  OrderMaterial: 3,
  ApprovemMaterialRequest: 4,
  ReceiveMaterial: 5,
  GenerateMaterialReport: 6,
  RecieveMaterial: 7,
  MarkMaterialUsed: 8,
  IssueMaterial: 9,
  CreatePurchaseOrder: 10,
  ApprovePurchaseOrder: 11,
  GeneratePurchaseOrderReport: 12,
  CreateWorkPlan: 14,
  UpdateWorkProgress: 15,
  GenerateProgressReport: 16,
  CreateIssue: 17,
  RemoveBlockers: 18,
  MarkAttendance: 19,
  GeneratePayables: 20,
  UpdateOnScWork: 21,
  GenerateWorkProgressReport: 22,
};

export const ONBOARDING_MODULE_TYPES = {
  1: 'Task',
  2: 'Attendance',
  3: 'Material',
};

export const ONBOARDING_STEPS = {
  MODULE_SELECT_STEP: 0,
  MODULE_QUESTIONS_STEP: 1,
  TASK_AND_MATERIAL_CREATE_STEP: 2,
  INVITE_MEMBERS_STEP: 3,
};

export const INTERNAL_ONBOARDING_STEP = {
  MODULE_SELECT_STEP: 0,
  MODULE_QUESTIONS_FIRST_STEP: 1,
  MODULE_QUESTIONS_SECOND_STEP: 2,
  SELECT_PROJECT_STEP: 3,
  CREATE_OR_UPLOAD_PLAN_STEP: 4,
  CREATE_TASK_PLAN_STEP: 5,
  UPLOAD_PLAN_SUCCESS: 6,
  INVITE_MEMBERS_STEP: 7,
};

export const ADD_ACTIONS_OPTIONS = [
  { label: 'Update Inventory', value: 100 },
  { label: 'Request Material', value: 101 },
  { label: 'Generate Material Stock Report', value: 102 },
  { label: 'Order Material', value: 103 },
  { label: 'Approve Material Request', value: 104 },
  { label: 'Receive Material', value: 105 },
  { label: 'Generate Material Report', value: 106 },
  { label: 'Recieve Material', value: 107 },
  { label: 'Mark Material Used', value: 108 },
  { label: 'Issue Material', value: 109 },
  { label: 'Create Purchase Order', value: 110 },
  { label: 'Approve Purchase Order', value: 111 },
  { label: 'Generate Purchase Order Report', value: 112 },
  { label: 'Create Work Plan', value: 114 },
  { label: 'Give Work Progress Update', value: 115 },
  { label: 'Generate Progress Report', value: 116 },
  { label: 'Raise on-site Issues', value: 117 },
  { label: 'Remove site Blockers', value: 118 },
  { label: 'Mark Attendance', value: 119 },
  { label: 'Generate Payables', value: 120 },
  { label: 'Update On Sc Work', value: 121 },
  { label: 'Generate Work Progress Report', value: 122 },
  { label: 'Download progress report', value: 123 },
  { label: 'Download progress report for client', value: 124 },
  { label: 'Download progress report for self & client', value: 125 },
  { label: 'Download daily progress report', value: 126 },
  { label: 'Download weekly progress report', value: 127 },
  { label: 'Download monthly progress report', value: 128 },
  { label: 'Create Inventory', value: 129 },
  { label: 'Create simple order', value: 130 },
  { label: 'Make payment to material vendors', value: 131 },
  { label: 'Track requested material', value: 132 },
  { label: 'Track material sent from one site to another', value: 133 },
  { label: 'Track material consumption', value: 134 },
  { label: 'Issue material on work/task', value: 135 },
  { label: 'Approve issued material', value: 136 },
  { label: 'Issue material to sub-contractor', value: 137 },
  { label: 'Issue material on a project', value: 138 },
  { label: 'Create vendor list', value: 139 },
  { label: 'Record vendor attendance', value: 140 },
  { label: 'Create self labour list', value: 141 },
  { label: 'Record self labour attendance', value: 142 },
  { label: 'Make labour payments', value: 143 },
  { label: 'Make advance payment', value: 144 },
  {
    label: 'Track material usage on a specific task / Project / Subcontractor',
    value: 144,
  },
  {
    label: 'Issue material for any task / Project / Subcontractor',
    value: 145,
  },
  {
    label: 'Approve Site Transfer',
    value: 147,
  },
];

export const TRAINING_STATUS = Object.freeze({
  NOT_DONE: 0,
  DONE: 1,
});

export const PRIORITIES = Object.freeze({
  PRIORITY_1: '1st Priority',
  PRIORITY_2: '2nd Priority',
  PRIORITY_3: '3rd Priority',
});

export const IDX_TO_MODULE = Object.freeze({
  0: NEW_ONBOARDING_MODULES.TASK,
  1: NEW_ONBOARDING_MODULES.MATERIAL,
  2: NEW_ONBOARDING_MODULES.ATTENDANCE,
});

export const MODULE_TO_IDX = Object.freeze({
  [NEW_ONBOARDING_MODULES.TASK]: 0,
  [NEW_ONBOARDING_MODULES.MATERIAL]: 1,
  [NEW_ONBOARDING_MODULES.ATTENDANCE]: 2,
});

export const MODULE_TO_IMAGE = Object.freeze({
  [NEW_ONBOARDING_MODULES.TASK]: 'WorkModule.svg',
  [NEW_ONBOARDING_MODULES.MATERIAL]: 'MaterialModule.svg',
  [NEW_ONBOARDING_MODULES.ATTENDANCE]: 'Labour.svg',
});

export const ONBOARDING_QUESTIONS_TYPE_V3 = Object.freeze({
  ICP: 0,
  CURRENT_PROCESS: 1,
});

export const ONBOARDING_STEPS_V2 = Object.freeze({
  DEFAULT: -1,
  ICP: 0,
  MODULE_SELECTION: 1,
  CURRENT_PROCESS: 2,
  SET_UP: 3,
  TIME_ALLOCATION: 4,
});

export const DEFAULT_STEP = 0;

export const MODULE_STATUS = Object.freeze({
  NOT_STARTED: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
});

export const MODULE_STATUS_MAP = Object.freeze({
  0: 'Not Started',
  1: 'In Progress',
  2: 'Completed',
});

export const ONBOARDING_INTERNAL_STEPS = Object.freeze({
  [NEW_ONBOARDING_MODULES.TASK]: ['Work Planning', 'Reporting'],
  [NEW_ONBOARDING_MODULES.MATERIAL]: [
    'Ordering',
    'Receive',
    'Consume',
    'Request',
    'Site Transfer',
    'Vendor Paybles',
  ],
  [NEW_ONBOARDING_MODULES.ATTENDANCE]: ['Labour Management', 'Payables'],
});

export const MODULES_INTERNAL_STEPS = Object.freeze({
  TASK: {
    WORK_PLANNING: 0,
    REPORTING: 1,
  },
  MATERIAL: {
    ORDERING: 0,
    RECEIVE: 1,
    CONSUME: 2,
    REQUEST: 3,
    SITE_TRANSFER: 4,
    VENDOR_PAYABLE: 5,
  },
  ATTENDANCE: {
    LABOUR_MANAGEMENT: 0,
    PAYABLES: 1,
  },
});

export const ATTENDANCE_SVG = '/attendance.svg';
export const MATRRIAL_SVG = '/material.svg';
export const TASK_SVG = '/task.svg';
export const NO_PROJECT_SVG = '/no-projects-onboarding.svg';
export const CURVE_ARROW_SVG = '/curve-arrow.svg';

export const MATERIAL_LIST = [
  {
    isSelected: false,
    name: 'Tower bolt',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Tiles',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Socket',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Screw',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Sanitary',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Red Bricks',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'PVC',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Putty',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Hoses',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Highland Sheet',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Flush Door with Both Sides Myka',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Electric Wire',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Electric Switch',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Electric Box',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Crush Sand',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'CPVC Socket',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'CPVC Reducer',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'CPVC Coupling',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'CPVC Clamp Metal',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'CPVC Brass MTA Fixed',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'CPVC Brass Elbow',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'CPVC Ball Valve',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Copper Wire Black',
    spec: '',
    category: 'Base build',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Screw',
    spec: '',
    category: 'Turnkey',
    uom: '',
  },
  {
    isSelected: false,
    name: 'CPVC Clamp',
    spec: '',
    category: 'Turnkey',
    uom: '',
  },
  {
    isSelected: false,
    name: 'CPVC Elbow',
    spec: '',
    category: 'Turnkey',
    uom: '',
  },
  {
    isSelected: false,
    name: 'CPVC Pipe',
    spec: '',
    category: 'Turnkey',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Wire',
    spec: '',
    category: 'Turnkey',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Bush',
    spec: '',
    category: 'Turnkey',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Clamp',
    spec: '',
    category: 'Turnkey',
    uom: '',
  },
  {
    isSelected: false,
    name: 'PVC Elbow',
    spec: '',
    category: 'Turnkey',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Bricks',
    spec: '',
    category: 'Turnkey',
    uom: '',
  },
  {
    isSelected: false,
    name: 'AHU units',
    spec: '',
    category: 'Turnkey',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Cement',
    spec: '',
    category: 'Turnkey',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Wood Polish',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Wire, Cable',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'White Sand',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Weight Weighing',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Water Tanker',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Vetra',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Transparent Paper (Black)',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Toilet Pot',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'TMT Steel Bar',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Tiles',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Tile Spacer',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Telephone Wire',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Tarpoline',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Sump Tiles',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Steel Wire',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Steel Binding Wire',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'Standard Brick Bat',
    spec: '',
    category: 'Interior',
    uom: '',
  },
  {
    isSelected: false,
    name: 'SS Baluster 3 Hole',
    spec: '',
    category: 'Interior',
    uom: '',
  },
];
