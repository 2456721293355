import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import image from '../Image/no-internet.png';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

function AlertDialog() {
  const [online, setOnline] = useState(navigator.onLine);

  const handleClosed = () => {
    setOnline(true);
  };

  useEffect(() => {
    function handleOnline() {
      setOnline(true);
    }

    function handleOffline() {
      setOnline(false);
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  function handleReload() {
    location.reload();
  }

  return (
    <Dialog
      maxWidth="xs"
      open={!online}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="error-dialog">
        <DialogContent className="text-center cont">
          <IconButton
            className="float-right"
            onClick={handleClosed}
            size="large"
          >
            <Close />
          </IconButton>
          <img src={image} alt="not found" />
          <div className="fs-xxxxxl fw-bold">{'Oops!'}</div>
          <div id="fw-bold">
            Slow or no internet connection. Please check your internet settings.
          </div>
          <Button
            onClick={handleReload}
            className="reload-button"
            variant="outlined"
          >
            {' '}
            Try Reloading
          </Button>
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default AlertDialog;
