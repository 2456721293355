import queryString from 'query-string';
const { stringify, parse } = queryString;

export const customStringify = params => {
  return stringify(params);
};

export const customParse = params => {
  return parse(params);
};
