import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { IconProps } from './Icons';

export const ActiveProjectStateIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path d="M9.16667 12.5007H5L10.8333 0.833984V7.50065H15L9.16667 19.1673V12.5007Z" />
  </SvgIcon>
);

export const CompletedProjectStateIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 020 206" fill="none" {...props}>
    <path d="M7.49922 17.0164L2.32422 11.8414L4.68255 9.48307L7.49922 12.3081L15.7326 4.06641L18.0909 6.42474L7.49922 17.0164Z" />
  </SvgIcon>
);

export const CloseIcon = (props: SvgIconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.8333 5.34175L14.6583 4.16675L9.99996 8.82508L5.34163 4.16675L4.16663 5.34175L8.82496 10.0001L4.16663 14.6584L5.34163 15.8334L9.99996 11.1751L14.6583 15.8334L15.8333 14.6584L11.175 10.0001L15.8333 5.34175Z"
      fill="#979797"
    />
  </svg>
);

export const EditIcon = (props: SvgIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 11.5V14H4.5L11.8733 6.62662L9.37333 4.12662L2 11.5ZM13.8067 4.69329C14.0667 4.43329 14.0667 4.01329 13.8067 3.75329L12.2467 2.19329C11.9867 1.93329 11.5667 1.93329 11.3067 2.19329L10.0867 3.41329L12.5867 5.91329L13.8067 4.69329Z"
      fill="#979797"
    />
  </svg>
);

export const DeleteIcon = (props: IconProps & SvgIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.00016 12.6667C4.00016 13.4 4.60016 14 5.3335 14H10.6668C11.4002 14 12.0002 13.4 12.0002 12.6667V4.66667H4.00016V12.6667ZM5.3335 6H10.6668V12.6667H5.3335V6ZM10.3335 2.66667L9.66683 2H6.3335L5.66683 2.66667H3.3335V4H12.6668V2.66667H10.3335Z"
      fill={props.primaryColor || '#979797'}
    />
  </svg>
);

export const FilledAddIcon = (props: SvgIconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="20" height="20" rx="2" fill="#F0F0F0" />
    <path
      d="M15.8334 10.8334H10.8334V15.8334H9.16675V10.8334H4.16675V9.16675H9.16675V4.16675H10.8334V9.16675H15.8334V10.8334Z"
      fill="#979797"
    />
  </svg>
);

export const GoToDateIcon = (props: SvgIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.486 11.984H3C2.73478 11.984 2.48043 11.8786 2.29289 11.6911C2.10536 11.5036 2 11.2492 2 10.984V2.99866C2 2.73344 2.10536 2.47909 2.29289 2.29155C2.48043 2.10401 2.73478 1.99866 3 1.99866H12C12.2652 1.99866 12.5196 2.10401 12.7071 2.29155C12.8946 2.47909 13 2.73344 13 2.99866V5.48799"
      stroke="#979797"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.00464 4.99805H13.0046"
      stroke="#979797"
      strokeLinejoin="round"
    />
    <path
      d="M5 2.99867V1"
      stroke="#979797"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 2.99867V1"
      stroke="#979797"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2 11.55L15 9.74995L13.2 7.94995"
      stroke="#979797"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 9.75H11.4C10.7635 9.75 10.153 10.0029 9.70294 10.4529C9.25286 10.903 9 11.5135 9 12.15V14.55"
      stroke="#979797"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ManageColumnIcon = (props: IconProps & SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.40002 3.1999C2.40002 2.98773 2.48431 2.78425 2.63434 2.63422C2.78437 2.48419 2.98785 2.3999 3.20002 2.3999H4.80002C5.0122 2.3999 5.21568 2.48419 5.36571 2.63422C5.51574 2.78425 5.60002 2.98773 5.60002 3.1999V12.7999C5.60002 13.0121 5.51574 13.2156 5.36571 13.3656C5.21568 13.5156 5.0122 13.5999 4.80002 13.5999H3.20002C2.98785 13.5999 2.78437 13.5156 2.63434 13.3656C2.48431 13.2156 2.40002 13.0121 2.40002 12.7999V3.1999ZM4.80002 3.1999H3.20002V12.7999H4.80002V3.1999ZM6.40002 3.1999C6.40002 2.98773 6.48431 2.78425 6.63434 2.63422C6.78437 2.48419 6.98785 2.3999 7.20002 2.3999H8.80002C9.0122 2.3999 9.21568 2.48419 9.36571 2.63422C9.51574 2.78425 9.60002 2.98773 9.60002 3.1999V10.3543L8.80002 11.1543V3.1999H7.20002V12.7999H7.58002C7.56751 12.8409 7.55604 12.8823 7.54562 12.9239L7.37602 13.5999H7.20002C6.98785 13.5999 6.78437 13.5156 6.63434 13.3656C6.48431 13.2156 6.40002 13.0121 6.40002 12.7999V3.1999ZM13.6 3.1999V7.2023C13.3272 7.2151 13.056 7.2759 12.8 7.3855V3.1999H11.2V8.7543L10.4 9.5543V3.1999C10.4 2.98773 10.4843 2.78425 10.6343 2.63422C10.7844 2.48419 10.9879 2.3999 11.2 2.3999H12.8C13.0122 2.3999 13.2157 2.48419 13.3657 2.63422C13.5157 2.78425 13.6 2.98773 13.6 3.1999ZM8.78402 12.3015L12.648 8.4375C12.9287 8.15701 13.3094 7.99951 13.7062 7.99966C14.103 7.99981 14.4835 8.1576 14.764 8.4383C15.0445 8.71901 15.202 9.09964 15.2019 9.49647C15.2017 9.8933 15.0439 10.2738 14.7632 10.5543L10.8992 14.4175C10.6741 14.6427 10.3921 14.8025 10.0832 14.8799L8.88482 15.1791C8.76566 15.2088 8.64085 15.2072 8.5225 15.1744C8.40415 15.1416 8.2963 15.0787 8.20943 14.9919C8.12255 14.9051 8.0596 14.7973 8.02669 14.679C7.99378 14.5607 7.99204 14.4359 8.02162 14.3167L8.32162 13.1183C8.39842 12.8095 8.55842 12.5263 8.78402 12.3023V12.3015Z"
      fill={props.primaryColor || '#3D90ED'}
    />
  </SvgIcon>
);
