export const TASKS_KEYS = {
  TASKS: 'tasks',
  GANTT: 'gantt',
  UPCOMING: 'upcoming-tasks',
  CURRENT: 'current-tasks',
  PAST: 'past-tasks',
} as const;

export const TASKS_TAB_KEYS = {
  [TASKS_KEYS.TASKS]: 0,
  [TASKS_KEYS.GANTT]: 1,
  [TASKS_KEYS.UPCOMING]: 5,
  [TASKS_KEYS.CURRENT]: 6,
  [TASKS_KEYS.PAST]: 7,
};

export const BUDGET_KEYS = {
  BOQ: 'boq',
  CUSTOMER: 'customer',
} as const;

export const FINANCE_KEYS = {
  GRN_APPROVAL: 'grnApproval',
  GRN_BILLING: 'grnBilling',
  EXPENESE: 'expense',
} as const;

export const COMMERCIAL_KEYS = {
  INDENT: 'indent',
  PURCHASE_ORDER: 'po',
  INVENTORY: 'inv',
  GRN: 'grn',
  MATERIAL_VENDOR_PAYABLE: 'mvp',
  SITE_TRANSFERS: 'siteTransfer',
  MATERIAL_ISSUE: 'materialIssue',
  EXPENSES: 'expenses',
  VENDOR_ATTENDANCE: 'vendor-attendance',
  CONSUMPTIONS: 'consuptions',
} as const;

export const ATTENDANCE_KEYS = {
  LABOUR: 'labour',
  VENDOR: 'vendor',
  WORKDONE: 'work-done',
};

export const MEMBERS_KEYS = {
  ALL_MEMBERS: 'all',
  MEMBERS_BY_PROJECT: 'members-by-project',
};

export const TASKS_PLAN_TAB_KEYS = {
  MASTER_PLAN: 'master-plan',
  WEEKLY_PLAN: 'week-plan',
};

export const TASKS_PLAN_TYPE_KEYS = {
  [TASKS_PLAN_TAB_KEYS.MASTER_PLAN]: 0,
  [TASKS_PLAN_TAB_KEYS.WEEKLY_PLAN]: 1,
};
