import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import clsx from 'clsx';
import './customTooltip.scss';

const CustomTooltip = ({
  children,
  title = '',
  placement = 'bottom-start',
  classes,
  width,
  ...rest
}: TooltipProps & { width?: number }) => {
  classes = {
    ...classes,
    tooltip: clsx('typography-base', classes?.tooltip),
    arrow: clsx(classes?.arrow),
  };

  return (
    <Tooltip
      title={title}
      placement={placement}
      classes={classes}
      arrow
      PopperProps={{ style: { zIndex: 10000000000 } }}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
