export const ADD_BOQ_ITEM = 'ADD_BOQ_ITEM';
export const GET_BOQ_ITEM = 'GET_BOQ_ITEM';
export const GET_BOQ_ITEMS = 'GET_BOQ_ITEMS';
export const UPDATE_BOQ_ITEM = 'UPDATE_BOQ_ITEM';
export const DELETE_BOQ_ITEM = 'DELETE_BOQ_ITEM';
export const GET_BOQ_NAV_ITEMS = 'GET_BOQ_NAV_ITEMS';
export const BOQ_API_SUCCESS = 'BOQ_API_SUCCESS';
export const BOQ_API_ERROR = 'BOQ_API_ERROR';
export const BOQ_API_PENDING = 'BOQ_API_PENDING';
export const BOQ_UPLOAD = 'BOQ_UPLOAD';
export const BOQ_UPLOAD_ERROR = 'BOQ_UPLOAD_ERROR';
export const BOQ_UPLOAD_SUCCESS = 'BOQ_UPLOAD_SUCCESS';
export const BOQ_PKG_ID_SELECTED = 'BOQ_PKG_ID_SELECTED';
export const TOTAL_BOQ_COUNT = 'TOTAL_BOQ_COUNT';
export const BOQ_COUNT_PENDING = 'BOQ_COUNT_PENDING';
export const BOQ_COUNT_RESET = 'BOQ_COUNT_RESET';
export const BOQ_PROMISES_PENDING = 'BOQ_PROMISES_PENDING';
export const BOQ_PROMISES = 'BOQ_PROMISES';
export const CLEAR_BOQS = 'CLEAR_BOQS';
export const SHOW_FINANCE_FORM = 'SHOW_FINANCE_FORM';
export const GET_PO_IN_BOQ = 'GET_PO_IN_BOQ ';
