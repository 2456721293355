import { UPDATE_APP_VERSION } from './ServiceWorker.type';
import { API_VERSIONS, APP_VERSION_APIS } from '../../../../routes.constant';
import { customStringify } from '../../../../utils/Url.util';
import { getRequest } from '../../../../apiClient';
import { eventDispatch } from '../../../base';
import { POWERPLAY_WEB_VERSION } from '../../../../constants';
import { parseString } from '../../../../utils';

export const getLatestVersion = () => {
  const params = { release_platform: 'react-web-app' };

  const apiUrl = parseString(
    APP_VERSION_APIS.getLatestVersion,
    customStringify(params)
  );

  return async dispatch => {
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V2);
      dispatch(eventDispatch(UPDATE_APP_VERSION, data));
    } catch {
      dispatch(eventDispatch(UPDATE_APP_VERSION, POWERPLAY_WEB_VERSION));
    }
  };
};
