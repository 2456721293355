import TextField, { TextFieldProps } from '@mui/material/TextField';
import clsx from 'clsx';
import './customTextField.scss';

interface Props {
  inputMode?: 'none' | 'numeric';
  customClass?: string;
}

export type CustomTextFieldProps = TextFieldProps & Props;

const CustomTextField = ({
  children,
  type,
  variant = 'outlined',
  InputProps,
  inputMode,
  customClass,
  ...rest
}: CustomTextFieldProps) => {
  const handleWheel = event => {
    if (inputMode === 'numeric' || type === 'number') {
      event.target.blur();
    }
  };

  let onKeyDown: any | undefined = undefined;

  if (inputMode === 'numeric') {
    onKeyDown = e => ['e', 'E', '-'].includes(e.key) && e.preventDefault();
    type = 'number';
  }

  const customInputProps = {
    ...InputProps,
    classes: {
      ...InputProps?.classes,
      root: clsx(
        InputProps?.classes?.root,
        'customTextField' || '',
        'typography-base'
      ),
      input: clsx(InputProps?.classes?.input, 'customTextInput' || ''),
      multiline: 'customTextMultiline',
    },
    margin: 'none',
  } as const;

  return (
    <TextField
      className={`customTextInputlabel ${customClass}`}
      onKeyDown={onKeyDown}
      type={type}
      variant={variant}
      InputProps={{ ...customInputProps }}
      FormHelperTextProps={{ style: { margin: 0 } }}
      inputMode={inputMode}
      onWheel={handleWheel}
      {...rest}
    >
      {children}
    </TextField>
  );
};

export default CustomTextField;
