/* eslint-disable no-case-declarations */
import {
  DASHBOARD_API_ERROR,
  DASHBOARD_API_PENDING,
  DASHBOARD_API_SUCCESS,
  GET_TODAYS_MILESTONE,
  GET_PACKAGES_PROGRESS,
  CLEAR_DASHBOARD_DATA,
  GET_TASK_COUNT_NEXT_SEVEN_DAYS,
  GET_ONTIME_TASK_COUNT,
  MILESTONE_PENDING,
  ON_TIME_TASK_PENDING,
  UPCOMING_TASK_PENDING,
  ITEMS_COUNT_PENDING,
  ITEMS_COUNT,
  GET_30_DAYS_ACTIVITIES,
  GET_UPCOMING_TASKS,
  GET_UPCOMING_TASKS_PENDING,
  GET_RECENTLY_UPDATED_TASKS,
  GET_RECENTLY_UPDATED_TASKS_PENDING,
  GET_DEADLINE_TASKS,
  GET_DEADLINE_TASKS_PENDING,
  GET_DASHBOARD_TASK_SUMMARY,
  GET_ORG_UPCOMING_TASKS,
  GET_ORG_UPCOMING_TASKS_PENDING,
  GET_ORG_DEADLINE_TASKS_PENDING,
  GET_ORG_DEADLINE_TASKS,
  GET_ORG_UPDATED_TASKS,
  GET_ORG_UPDATED_TASKS_PENDING,
  GET_PACKAGE_WISE_TASK_SUMMARY,
  GET_TAG_WISE_TASK_SUMMARY,
  GET_USER_WISE_TASK_SUMMARY,
  GET_S_CURVE_DATA_SUCCESS,
  GET_TASK_COUNT_DETAILS,
  GET_FILTERED_TASK_PROGRESS,
  GET_BROAD_PLAN_PROJECT_DASHBOARD_STATUS,
  GET_BROAD_PLAN_TOP_ACTIVE_USERS,
} from './Dashboard.type';
import { capitalizeString } from '../../../utils';

const intialPendingState = {
  dashboardApiPending: false,
  milestonePending: false,
  onTimeTaskPending: false,
  upcomingTaskPending: false,
  itemsCountPending: false,
};

const initialState = {
  ...intialPendingState,
  isLoading: true,
  taskCountNextSevenDays: { total: 0, assigned: 0 },
  getOntimeTaskCount: {
    running: 0,
    completed: 0,
    total: 0,
  },
  yesterday_milestone: {
    actualInstallStart: 0,
    actualInstallComplete: 0,
    actualWorkers: 0,
    allActivities: 0,
    allTasksPrgoressSinceYesterday: [],
  },
  itemsCount: {
    task: 0,
    boq: 0,
    task_to_boq: 0,
  },
  packageProgress: [],
  packageTasksDetails: [],
  fetchedPackageDetails: [],
  upcomingTasks: null,
  upcomingTasksPending: false,
  recentlyUpdatedTasks: null,
  recentlyUpdatedTasksPending: false,
  deadlineTasks: null,
  deadlineTasksPending: false,
  taskSummary: {
    completed: 0,
    in_progress: 0,
    late: 0,
  },
  last30DaysActivities: [],
  upcomingOrgTasks: null,
  upcomingOrgTasksPending: false,
  deadlineOrgTasks: null,
  deadlineOrgTasksPending: false,
  recentlyUpdatedOrgTasks: null,
  recentlyUpdatedOrgTasksPending: false,
  packageWiseTaskSummary: [],
  tagWiseTaskSummary: [],
  userWiseTaskSummary: [],
  sCurveData: [],
  taskCount: [],
  taskProgress: 0,
  broadPlanProjectStatus: {
    actual_end_date: 0,
    actual_start_date: 0,
    end_date: 0,
    start_date: 0,
    tasks: {
      completed: {
        total: 0,
        delayed: 0,
      },
      in_progress: {
        total: 0,
        delayed: 0,
      },
      not_started: {
        total: 0,
        delayed: 0,
      },
      tasks_count: 0,
    },
    weeklyTasks: {
      completed: {
        total: 0,
        delayed: 0,
      },
      in_progress: {
        total: 0,
        delayed: 0,
      },
      not_started: {
        total: 0,
        delayed: 0,
      },
      tasks_count: 0,
    },
  },
  project_status: {},
  broadPlanTopActiveUsers: [],
};

export default function dashboardReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case GET_TODAYS_MILESTONE:
      return {
        ...state,
        yesterday_milestone: payload.data,
      };
    case MILESTONE_PENDING:
      return { ...state, milestonePending: payload };
    case GET_PACKAGES_PROGRESS:
      return { ...state, packageProgress: payload.data };
    case GET_TASK_COUNT_NEXT_SEVEN_DAYS:
      return { ...state, taskCountNextSevenDays: payload.data };
    case ON_TIME_TASK_PENDING:
      return { ...state, onTimeTaskPending: payload };
    case GET_ONTIME_TASK_COUNT:
      return { ...state, getOntimeTaskCount: payload.data };
    case UPCOMING_TASK_PENDING:
      return { ...state, upcomingTaskPending: payload };
    case ITEMS_COUNT:
      return { ...state, itemsCount: payload.data };
    case ITEMS_COUNT_PENDING:
      return { ...state, itemsCountPending: payload };
    case DASHBOARD_API_SUCCESS:
      return {
        ...state,
        dashboardApiPending: false,
        isLoading: false,
      };
    case DASHBOARD_API_PENDING:
      return {
        ...state,
        dashboardApiPending: true,
        isLoading: true,
      };
    case DASHBOARD_API_ERROR:
      return {
        ...state,
        dashboardApiPending: false,
        error: error,
      };
    case CLEAR_DASHBOARD_DATA:
      return initialState;
    case GET_30_DAYS_ACTIVITIES:
      return { ...state, last30DaysActivities: payload };
    case GET_UPCOMING_TASKS:
      return { ...state, upcomingTasks: payload };
    case GET_UPCOMING_TASKS_PENDING:
      return { ...state, upcomingTasksPending: payload };
    case GET_RECENTLY_UPDATED_TASKS:
      return { ...state, recentlyUpdatedTasks: payload };
    case GET_RECENTLY_UPDATED_TASKS_PENDING:
      return { ...state, recentlyUpdatedTasksPending: payload };
    case GET_DEADLINE_TASKS:
      return { ...state, deadlineTasks: payload };
    case GET_DEADLINE_TASKS_PENDING:
      return { ...state, deadlineTasksPending: payload };
    case GET_DASHBOARD_TASK_SUMMARY:
      return { ...state, taskSummary: payload };
    case GET_ORG_UPCOMING_TASKS:
      return { ...state, upcomingOrgTasks: payload };
    case GET_ORG_UPCOMING_TASKS_PENDING:
      return { ...state, upcomingOrgTasksPending: payload };
    case GET_ORG_DEADLINE_TASKS:
      return { ...state, deadlineOrgTasks: payload };
    case GET_ORG_DEADLINE_TASKS_PENDING:
      return { ...state, deadlineOrgTasksPending: payload };
    case GET_ORG_UPDATED_TASKS:
      return { ...state, recentlyUpdatedOrgTasks: payload };
    case GET_ORG_UPDATED_TASKS_PENDING:
      return { ...state, recentlyUpdatedOrgTasksPending: payload };
    case GET_PACKAGE_WISE_TASK_SUMMARY:
      payload.map(obj => {
        obj.name = capitalizeString(obj.name);
      });
      return { ...state, packageWiseTaskSummary: payload };
    case GET_TAG_WISE_TASK_SUMMARY:
      return { ...state, tagWiseTaskSummary: payload };
    case GET_USER_WISE_TASK_SUMMARY:
      return { ...state, userWiseTaskSummary: payload };
    case GET_S_CURVE_DATA_SUCCESS:
      return { ...state, sCurveData: payload };
    case GET_TASK_COUNT_DETAILS:
      return { ...state, taskCount: payload };
    case GET_FILTERED_TASK_PROGRESS:
      return {
        ...state,
        taskProgress: payload.progress,
        project_status: payload,
      };
    case GET_BROAD_PLAN_PROJECT_DASHBOARD_STATUS:
      return { ...state, broadPlanProjectStatus: payload };
    case GET_BROAD_PLAN_TOP_ACTIVE_USERS:
      return { ...state, broadPlanTopActiveUsers: payload };
    default:
      return state;
  }
}
