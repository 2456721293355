import { SvgIcon, SvgIconProps } from '@mui/material';

export const ReportImageSelectionEmptyStateIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_608_891)">
      <path
        d="M64.0001 11.3333V41.9999C64.0001 45.3066 61.3068 47.9999 58.0001 47.9999H16.6668C16.4001 47.9999 16.1334 47.9733 15.8934 47.9466C13.0934 47.5999 10.9067 45.3066 10.6934 42.4799L10.6667 41.9999V11.3333C10.6667 8.02659 13.3601 5.33325 16.6668 5.33325H58.0001C61.3068 5.33325 64.0001 8.02659 64.0001 11.3333Z"
        fill="#ECEFF1"
      />
      <path
        d="M37.6668 5.33325H16.6668C13.3601 5.33325 10.6667 8.02659 10.6667 11.3333V41.9999L10.6934 42.4799C10.9067 45.3066 13.0934 47.5999 15.8934 47.9466C16.1334 47.9733 16.4001 47.9999 16.6668 47.9999H37.6668V5.33325Z"
        fill="#CDD0D2"
      />
      <path
        d="M64 34.5068V42.0001C64 45.3068 61.3067 48.0001 58 48.0001H16.6666C16.4 48.0001 16.1333 47.9734 15.8933 47.9468L43.36 20.4801C45.1733 18.6668 48.16 18.6668 49.9733 20.4801L64 34.5068Z"
        fill="#388E3C"
      />
      <path
        d="M46.44 19.1252C45.3173 19.1786 44.2106 19.6319 43.36 20.4799L15.8933 47.9466C16.1333 47.9732 16.4 47.9999 16.6666 47.9999H46.44V19.1252Z"
        fill="#317C34"
      />
      <path
        d="M48.16 48H16.6667C16.4 48 16.1334 47.9734 15.8934 47.9467C13.0934 47.6 10.9067 45.3067 10.6934 42.48L23.36 29.8134C25.1734 28 28.16 28 29.9734 29.8134L48.16 48Z"
        fill="#4CAF50"
      />
      <path
        d="M26.6667 28.4534C25.4667 28.4534 24.2667 28.9067 23.36 29.8134L10.6934 42.48C10.9067 45.3067 13.0934 47.6 15.8934 47.9467C16.1334 47.9734 16.4 48 16.6667 48H26.8054V28.456C26.76 28.4534 26.712 28.4534 26.6667 28.4534Z"
        fill="#429846"
      />
      <path
        d="M24.0001 13.3333C21.0588 13.3333 18.6667 15.7252 18.6667 18.6666C18.6667 21.6079 21.0588 23.9999 24.0001 23.9999C26.9414 23.9999 29.3334 21.6079 29.3334 18.6666C29.3334 15.7252 26.9414 13.3333 24.0001 13.3333Z"
        fill="#FFC107"
      />
      <path
        d="M24.0001 13.3333C21.0588 13.3333 18.6667 15.7252 18.6667 18.6666C18.6667 21.6079 21.0588 23.9999 24.0001 23.9999C24.0561 23.9999 24.1121 23.9999 24.1654 23.9972V13.3359C24.1121 13.3333 24.0561 13.3333 24.0001 13.3333Z"
        fill="#DEA806"
      />
      <path
        d="M16.6666 52C12.3972 52 8.5999 49.2666 7.22123 45.1973L7.1279 44.8906C6.80256 43.8133 6.66656 42.9066 6.66656 42V23.8213L0.197229 45.4133C-0.634772 48.5893 1.26123 51.8826 4.44256 52.76L45.6772 63.8026C46.1919 63.936 46.7066 64 47.2132 64C49.8692 64 52.2959 62.2373 52.9759 59.64L55.3759 52H16.6666Z"
        fill="#ECEFF1"
      />
      <path
        d="M6.66656 23.8213L0.197228 45.4133C-0.634771 48.5893 1.26123 51.8826 4.44256 52.7599L24.6692 58.1759L26.7119 51.9999H16.6666C12.3972 51.9999 8.59989 49.2666 7.22123 45.1973L7.12789 44.8906C6.80256 43.8133 6.66656 42.9066 6.66656 41.9999V23.8213Z"
        fill="#CDD0D2"
      />
    </g>
    <defs>
      <clipPath id="clip0_608_891">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
