export const GET_GRN = 'GET_GRN';
export const GET_ALL_GRNS = 'GET_ALL_GRNS';
export const GET_GRN_PENDING = 'GET_GRN_PENDING';
export const GET_GRNS_PENDING = 'GET_GRNS_PENDING';
export const GRN_API_SUCCESS = 'GRN_API_SUCCESS';
export const GRN_API_ERROR = 'GRN_API_ERROR';
export const SET_GRN_FILTER_VALUES = 'SET_GRN_FILTER_VALUES';
export const SET_IF_GRN_FILTER = 'SET_IF_GRN_FILTER';

export const GET_ALL_GRN_PAYMENTS = 'GET_ALL_GRN_PAYMENTS';
export const GET_GRN_PAYMENT = 'GET_GRN_PAYMENT';
export const GRN_PAYMENTS_PENDING = 'GRN_PAYMENTS_PENDING';
export const GRN_PAYMENT_PENDING = 'GRN_PAYMENT_PENDING';
export const SET_GRN_PAYMENT_FILTER_VALUES = 'SET_GRN_PAYMENT_FILTER_VALUES';
export const SET_GRN_PAYMENT_IF_FILTER = 'SET_GRN_PAYMENT_IF_FILTER';
export const SET_SELECTED_GRN_PAYMENTS = 'SET_SELECTED_GRN_PAYMENTS';
export const GET_GRN_PAYMENT_SUMMARY = 'GET_GRN_PAYMENT_SUMMARY';

export const GET_GRN_BY_ORG_ID = 'GET_GRN_BY_ORG_ID';
export const GET_GRN_ORG_ID_COUNT = 'GET_GRN_ORG_ID_COUNT';

export const GET_GRN_TOTAL_COST = 'GET_GRN_TOTAL_COST';
export const SET_GRN_PAYMENT_DATA = 'SET_GRN_PAYMENT_DATA';
export const GET_ITEM_PAYMENT_DETAILS = 'GET_ITEM_PAYMENT_DETAILS';
export const GET_GRN_BY_ORG_PENDING = 'GET_GRN_BY_ORG_PENDING';

export const CREATE_GRN_PENDING = 'CREATE_GRN_PENDING';
