export const ADD_VENDOR_ITEM = 'ADD_VENDOR_ITEM';
export const GET_VENDOR_ITEM = 'GET_VENDOR_ITEM';
export const GET_VENDOR_ITEMS = 'GET_VENDOR_ITEMS';
export const GET_VENDOR_ITEMS_PENDING = 'GET_VENDOR_ITEMS_PENDING';
export const GET_VENDOR_ITEM_PENDING = 'GET_VENDOR_ITEM_PENDING';
export const UPDATE_VENDOR_ITEM = 'UPDATE_VENDOR_ITEM';
export const DELETE_VENDOR_ITEM = 'DELETE_VENDOR_ITEM';
export const GET_VENDOR_NAV_ITEMS = 'GET_VENDOR_NAV_ITEMS';
export const VENDOR_ITEM_ADDED = 'VENDOR_ITEM_ADDED';
export const VENDOR_API_SUCCESS = 'VENDOR_API_SUCCES';
export const VENDOR_API_ERROR = 'VENDOR_API_ERROR';
export const VENDOR_API_PENDING = 'VENDOR_API_PENDING';
export const SET_VENDOR_ITEMS = 'SET_VENDOR_ITEMS';
export const SET_VENDOR_ITEMS_PAGINATION_DATA =
  'SET_VENDOR_ITEMS_PAGINATION_DATA';
export const ALL_PAYABLE_DATA = 'ALL_PAYABLE_DATA';

export const VENDOR_DELETE_API_SUCCESS = 'VENDOR_DELETE_API_SUCCESS';
export const VENDOR_DELETE_API_PENDING = 'VENDOR_DELETE_API_PENDING';
export const VENDOR_DELETE_API_ERROR = 'VENDOR_DELETE_API_ERROR';

export const VENDOR_PROJECTS_API_ERROR = 'VENDOR_PROJECTS_API_ERROR';
export const VENDOR_PROJECTS_API_SUCCESS = 'VENDOR_PROJECTS_API_SUCCESS';
export const VENDOR_PROJECTS_API_PENDING = 'VENDOR_PROJECTS_API_PENDING';
export const SET_VENDOR_PROJECTS = 'SET_VENDOR_PROJECTS';
export const SET_VENDOR_PROJECTS_COUNT = 'SET_VENDOR_PROJECTS_COUNT';

export const SET_ORG_VENDOR_SELECTION_LIST = 'SET_ORG_VENDOR_SELECTION_LIST';
export const SET_ORG_VENDOR_SELECTION_LIST_COUNT =
  'SET_ORG_VENDOR_SELECTION_LIST_COUNT';

export const SET_VENDOR_PRICING_HISTORY = 'SET_VENDOR_PRICING_HISTORY';
export const SET_VENDOR_PRICING_HISTORY_COUNT =
  'SET_VENDOR_PRICING_HISTORY_COUNT';

export const SET_VENDOR_WITH_SAME_NAME_EXISTS_FLAG =
  'SET_VENDOR_WITH_SAME_NAME_EXISTS_FLAG';

export const VENDOR_CREATE_API_PENDING = 'VENDOR_CREATE_API_PENDING';
export const VENDOR_CREATE_API_SUCCESS = 'VENDOR_CREATE_API_SUCCESS';
export const VENDOR_CREATE_API_ERROR = 'VENDOR_CREATE_API_ERROR';

export const VENDOR_EDIT_API_PENDING = 'VENDOR_EDIT_API_PENDING';
export const VENDOR_EDIT_API_SUCCESS = 'VENDOR_EDIT_API_SUCCESS';
export const VENDOR_EDIT_API_ERROR = 'VENDOR_EDIT_API_ERROR';
