export const NEW_PROJECT_BTN_CLICK = 'new_project_clicked';
export const CREATE_PROJECT_BTN_CLICK = 'create_new_project_clicked';
export const UPLOAD_MSP_BTN_CLICK = 'upload_msp_clicked';
export const CRITICAL_PATH_ON_CLICK = 'crtitical_path_on_clicked';
export const CRITICAL_PATH_OFF_CLICK = 'crtitical_path_off_clicked';
export const BASELINE_BTN_CLICKED = 'baseline_clicked';
export const CREATE_NEW_BASELINE_CLICKED = 'baseline_popup_create_clicked';
export const CRAETE_BASELINE_CTA = 'baseline_popup_create_new_clicked';
export const CHOOSE_NEW_BASELINE = 'baseline_popup_choose_clicked';
export const CLEAR_BASELINE = 'baseline_popup_clear_clicked';
export const GO_TO_TODA_CLICKED = 'go_to_today_clicked';
export const TASK_CLICK = 'task_highlight_clicked';
export const TASK_LINK_CLICK = 'gantt_view_dep_clicked';
export const TASK_LINK_DELETE_CONFIRM = 'delete_task_dep_clicked';
export const DELETE_TASK_BTN_CLICK = 'delete_task_clicked';
export const EDIT_TASK_BTN_CLICKED = 'edit_task_clicked';
export const DELETE_TASK_PERMANENTLY_BTN_CLICK =
  'delete_task_popup_delete_permanently_clicked';
export const MORE_CLICKED = 'more_clicked';
export const DOWNLOAD_DPR_CLICKED = 'download_dpr_clicked';
export const DOWNLOAD_REPORT_CLICKED = 'download_report_clicked';
export const DOWNLOAD_REPORT_POPOUT_CLICKED = 'download_report_popout_clicked';
export const DOWNLOAD_DPR_POPOUT_CLICKED = 'download_dpr_popout_submit_clicked';
export const DOWNLOAD_WPR_CLICKED = 'download_wpr_clicked';
export const DOWNLOAD_WPR_SUBMIT_CLICKED = 'download_wpr_popout_submit_clicked';
export const DOWNLOAD_WPR_DOWNLOAD_CLICKED =
  'download_wpr_popout_submit_download_popout';
export const HOVER_ADD_TASK_CLICKED = 'hover_add_task_clicked';
export const HOVER_TASK_PROFILE_CLICKED = 'hover_task_profile_clicked';
export const HOVER_DELETE_TASK_CLICKED = 'hover_delete_task_clicked';
export const HOVER_TASK_MORE_CLICKED = 'hover_task_more_clicked';
export const BULK_NO_PROGRESS_CLICKED =
  'site_plan_long_press_mark_as_no_progress_click';
export const BULK_NO_PROGRESS_SUCCESS =
  'site_plan_long_press_no_progress_success';
export const BULK_MARK_COMPLETE_CLICKED =
  'site_plan_long_press_mark_as_completed_click';
export const BULK_MARK_COMPLETE_SUCCESS =
  'site_plan_long_press_mark_as_completed_success';
export const BULK_DELETE_CLICKED = 'site_plan_long_press_delete_click';
export const BULK_DELETE_SUCCESS = 'site_plan_long_press_delete_success';
export const TASK_POPUP_SYNC_BTN_CLICK = 'task_profile_popup_sync_clicked';
export const TASK_POUP_UPLOAD_IMAGE_BTN = 'add_files_and_images_clicked';
export const ADD_FILE_IMAGE_POPUP_CLICK =
  'add_files_and_images_popup_update_clicked';
export const TASK_LOGS_CLICKED = 'task_logs_clicked';
export const ADD_TASK_LOGS_CLICKED = 'add_task_logs_clicked';
export const ADD_TASK_CLICKED = 'add_task_clicked';
export const BASELINE_TOGGLE_ON = 'baseline_popup_toggle_on';
export const BASELINE_TOGGLE_OFF = 'baseline_popup_toggle_off';
export const DELETE_BASELINE_BTN_CLICKED =
  'baseline_popup_baseline_delete_clicked';
export const EDIT_BASELINE_BTN_CLICK = 'baseline_popup_baseline_edit_clicked';
export const EDIT_BASELINE_SAVE_BTN_CLICK =
  'baseline_popup_baseline_edit_save_clicked';
export const TASK_PROFILE_POPUP_EDIT_CLICKED =
  'task_profile_popup_task_logs_add_task_logs_edit_clicked';
export const DELETE_TASK_LOGS_CLICKED = 'delete_task_logs_clicked';
export const DELETE_TASKLOGS_PERMANENTLY =
  'delete_task_logs_popup_delete_permanently_clicked';
export const COMMENT_CLICKED = 'comment_clicked';
export const COMMENT_POST_CLICKED = 'comment_post_clicked';
export const ISSUES_AND_BUDGETS_CLICKED = 'issues_and_budgets_clicked';
export const ADD_ISSUES_CLICKED = 'issues_and_budgets_add_issues_clicked';
export const NEW_ISSUE_CREATE_CLICKED = 'new_issue_create_clicked';
export const MANAGE_COLUMN_BTN_CLICKED = 'manage_column_clicked';
export const MINIMIZED_CLICK = 'minimize_clicked';
export const MAXIMIZE_CLICKED = 'maximize_clicked';
export const REPLACE_PLAN_BTN_CLICK = 'replace_plan_clicked';
export const ADD_PLAN_BTN_CLICK = 'add_existing_plan_clicked';
export const WHATS_NEW_CLICKED = 'whats_new_clicked';
export const BULK_SELECT_CLICKED = 'bulk_select_clicked';
export const EDIT_INLINE_CLICKED = 'edit_inline_clicked';
export const EDIT_TASK_POPUP_CLICKED = 'edit_task_popup_edit_details_clicked';
export const MORE_CLICKED_BTN_CLICK = 'more_clicked';
export const TASK_LOGS_SAVE_CLICKED = 'add_task_logs_save_clicked';

export const DEPEND_ON_CLICKED = 'depedency_popup_depends_clicked';
export const DEPENDENT_CLICKED = 'depedency_popup_dependent_clicked';
export const REMOVE_DEPENDENCY_CLICK = 'dependency_remove_clicked';
export const CHANGE_DEPENDENCY_CLICK = 'dependency_type_change_clicked';
export const LEAD_LAG_TYPE_CLICKED = 'lead_lag_type_clicked';

export const LEAD_ADD_CLICKED = 'lead_add_clicked';
export const LAG_ADD_CLICKED = 'lag_add_clicked';

export const PDF_EXPORT_CLICKED = 'pdf_export_clicked';

export const CREATE_BASELINE_LATER =
  'replace_plan_baseline_popup_later_clicked';
export const CREATE_BASELINE_POPUP_CLICK =
  'replace_plan_baseline_popup_create_baseline_clicked';
export const CREATE_NOW_BASELINE_POPUP_CLICK =
  'replace_plan_baseline_popup_create_now_clicked';

export const ORG_DASHBOARD_FILTER_APPLIED = 'org_dashboard_filter_applied';

export const PERMISSIONS_CHECKBOX_CLICK = 'permission_checkbox_click';
export const EXPORT_MATERIAL_USAGE = 'export_material_use_clicked';
export const MATERIAL_EXCEL_EXPORT = 'export_material_plan_success';

export const MATERIAL_USED_CLICKED_WEB = 'material_used_clicked_web';

export const TAB_SWIPE = 'tab_swipe';
export const FILTER_CLICKED = 'filter_clicked';
export const FILTER_APPLIED = 'filter_applied';
export const FILTER_CLEARED = 'filter_cleared';
export const MARK_AS_COMPLETE_POPUP =
  'site_plan_long_press_mark_as_completed_popup';
export const BULK_NO_PROGRESS_POPUP = 'site_plan_long_press_no_progress_popup';
export const BULK_DELETE_POPUP = 'site_plan_delete_popup';

export const BULK_ASSIGN_CLICK = 'site_plan_long_press_assign_to_click';
export const BULK_ASSIGN_SEARCH = 'site_plan_long_press_assign_search';
export const BULK_ASSIGN_SELECT = 'site_plan_long_press_assign_select';
export const BULK_ASSIGN_SUCCESS = 'site_plan_assign_success';

export const BULK_TAGS_CLICK = 'site_plan_long_press_add_tags_click';
export const BULK_TAGS_SEARCH = 'site_plan_long_press_add_tags_search';
export const BULK_TAGS_SELECT = 'site_plan_long_press_add_tags_select';
export const BULK_TAGS_SUCCESS = 'site_plan_long_press_add_tags_success';

export const BULK_CATEGORY_CLICK = 'site_plan_long_press_add_category_click';
export const BULK_CATEGORY_SEARCH = 'site_plan_long_press_add_category_search';
export const BULK_CATEGORY_SELECT = 'site_plan_long_press_add_category_select';
export const BULK_CATEGORY_SUCCESS =
  'site_plan_long_press_add_category_success';
