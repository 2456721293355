import {
  WORK_CATEGORIES_TAB_KEY,
  WORK_TABLE_SHOW,
  PACKAGE_ID_SELECTED,
  UTILS_API_ERROR,
  UTILS_API_PENDING,
  UTILS_API_SUCCESS,
  VERIFY_GST_NUMBER,
  VENDOR_ID_SELECTED,
  BOM_ID_SELECTED,
  INDENT_DETAILS_SHOW,
  INDENT_ID_SELECTED,
  PO_DETAILS_SHOW,
  PO_ID_SELECTED,
  CLEAR_UTILS_DATA,
  TOGGLE_SIDEBAR_MENU,
  REGISTER_ZOHO,
  SYNC_PO_STATUS,
  SELECTED_KEY,
  MX_LOOKUP_SUCCESS,
  MX_LOOKUP_FAILURE,
} from './Utils.type';

const initialState = {
  pending: false,
  wkShow: true,
  wcTabKey: 'timeline',
  packageIdSelected: 'PKG123456789012',
  vendorIdSelected: 'VDR123456789012',
  bomIdSelected: 'BOM1234567890',
  poIdSelected: '',
  indentIdSelected: 'IN123456789012',
  poDetailsShow: false,
  indentDetailsShow: false,
  gstNumberDetails: {
    id: '',
    verified: false,
    state: '',
    pan: '',
  },
  initialKey: '',
  sideBarOpen: true,
  showItemForm: false,
  zohoToken: false,
  syncPOStatus: false,
  mxLookupData: {},
};

export default function utilReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case WORK_CATEGORIES_TAB_KEY:
      return { ...state, wcTabKey: payload };
    case WORK_TABLE_SHOW:
      return { ...state, wkShow: payload };
    case PO_DETAILS_SHOW:
      return {
        ...state,
        poDetailsShow: payload,
      };
    case INDENT_DETAILS_SHOW:
      return {
        ...state,
        indentDetailsShow: payload,
      };
    case PACKAGE_ID_SELECTED:
      return { ...state, packageIdSelected: payload };
    case VENDOR_ID_SELECTED:
      return { ...state, vendorIdSelected: payload };
    case BOM_ID_SELECTED:
      return { ...state, bomIdSelected: payload };
    case PO_ID_SELECTED:
      return { ...state, poIdSelected: payload };
    case INDENT_ID_SELECTED:
      return { ...state, indentIdSelected: payload };
    case VERIFY_GST_NUMBER:
      return {
        ...state,
        gstNumberDetails: payload,
      };
    case UTILS_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case UTILS_API_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UTILS_API_ERROR:
      return {
        ...state,
        pending: false,
        error: error.response.data.message,
      };
    case CLEAR_UTILS_DATA:
      return initialState;
    case TOGGLE_SIDEBAR_MENU:
      return { ...state, sideBarOpen: payload };
    case REGISTER_ZOHO:
      return { ...state, zohoToken: payload };
    case SYNC_PO_STATUS:
      return { ...state, syncPOStatus: payload };
    case SELECTED_KEY: {
      return { ...state, initialKey: payload || state.initialKey };
    }
    case MX_LOOKUP_SUCCESS:
      return { ...state, mxLookupData: payload };
    case MX_LOOKUP_FAILURE:
      return { ...state, mxLookupData: {} };
    default:
      return state;
  }
}
