import {
  SET_SHOW_MILESTONE_DETAILS,
  GET_ALL_MILESTONES,
  GET_ALL_MILESTONES_PENDING,
  CREATE_MILESTONE_PENDING,
  CREATE_MILESTONE,
  DELETE_MILESTONE_PENDING,
  DELETE_MILESTONE,
  RAISE_BILL,
  GET_MILESTONE,
  GET_MILESTONE_PENDING,
  MILESTONE_ID_SELECTED,
  UPDATE_MILESTONE_PENDING,
  LINK_TASK_TO_MILESTONE_PENDING,
  LINK_BUDGET_TO_MILESTONE_PENDING,
  GET_MILESTONE_SUMMARY,
  GET_MILESTONE_SUMMARY_PENDING,
} from './Milestone.type';

const initialPendingState = {
  getAllMilestonePending: false,
  createMilestonePending: false,
  deleteMilestonePending: false,
  getMilestonePending: false,
  updateMilestonePending: false,
  linkTaskToMilestonePending: false,
  linkBoqToMilestonePending: false,
  getMilestoneSummaryPending: false,
};
const initialState = {
  ...initialPendingState,
  showMilestoneDetails: false,
  milestonesList: [],
  addedMilestone: null,
  selectedMilestone: {},
  milestoneIdSelected: null,
  milestoneSummary: {},
};

export default function milestoneReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_SHOW_MILESTONE_DETAILS:
      return { ...state, showMilestoneDetails: payload };
    case GET_ALL_MILESTONES:
      return { ...state, milestonesList: payload };
    case GET_ALL_MILESTONES_PENDING:
      return { ...state, getAllMilestonePending: payload };
    case CREATE_MILESTONE:
      return { ...state, addedMilestone: payload };
    case CREATE_MILESTONE_PENDING:
      return { ...state, createMilestonePending: payload };
    case GET_MILESTONE:
      return { ...state, selectedMilestone: payload };
    case GET_MILESTONE_PENDING:
      return { ...state, getAllMilestonePending: payload };
    case DELETE_MILESTONE:
      return { ...state, createMilestonePending: payload };
    case DELETE_MILESTONE_PENDING:
      return { ...state, deleteMilestonePending: payload };
    case RAISE_BILL:
      return state;
    case MILESTONE_ID_SELECTED:
      return { ...state, milestoneIdSelected: payload };
    case UPDATE_MILESTONE_PENDING:
      return { ...state, updateMilestonePending: payload };
    case LINK_TASK_TO_MILESTONE_PENDING:
      return { ...state, linkTaskToMilestonePending: payload };
    case LINK_BUDGET_TO_MILESTONE_PENDING:
      return { ...state, linkBudgetToMilestonePending: payload };
    case GET_MILESTONE_SUMMARY:
      return { ...state, milestoneSummary: payload };
    case GET_MILESTONE_SUMMARY_PENDING:
      return {
        ...state,
        milestoneSummaryPending: payload,
        milestoneSummary: { billed_value: 0, total_value: 0 },
      };
    default:
      return state;
  }
}
