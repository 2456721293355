export const ADD_BOM_ITEM = 'ADD_BOM_ITEM';
export const GET_BOM_ITEMS = 'GET_BOM_ITEMS';
export const UPDATE_BOM_ITEM = 'UPDATE_BOM_ITEM';
export const DELETE_BOM_ITEM = 'DELETE_BOM_ITEM';
export const GET_BOM_NAV_ITEMS = 'GET_BOM_NAV_ITEMS';
export const BOM_API_SUCCESS = 'BOM_API_SUCCES';
export const BOM_API_ERROR = 'BOM_API_ERROR';
export const BOM_API_PENDING = 'BOM_API_PENDING';
export const BOM_ITEM_ADDED = 'BOM_ITEM_ADDED';
export const RESET_BOM_ITEM_ADDED = 'RESET_BOM_ITEM_ADDED';
export const BOM_COUNT_FETCHED = 'BOM_COUNT_FETCHED';
export const GET_BOM_CHILD = 'GET_BOM_CHILD';
export const GET_BOM_CHILD_PENDING = 'GET_BOM_CHILD_PENDING';
export const GET_BOM_SEARCH_ITEM = 'GET_BOM_SEARCH_ITEM';
export const GET_BOM_SEARCH_ITEM_ORG = 'GET_BOM_SEARCH_ITEM_ORG';
export const BOM_SEARCH_CHECK = 'BOM_SEARCH_CHECK';
export const GET_INDENT_BOM_SEARCH_ITEM = 'GET_INDENT_BOM_SEARCH_ITEM';
export const GET_BOMS_WITH_INVENTORY = 'GET_BOMS_WITH_INVENTORY';
export const GET_BOMS_WITH_INVENTORY_COUNT = 'GET_BOMS_WITH_INVENTORY_COUNT';
export const GET_MATERIALS = 'GET_MATERIALS';
export const ADD_BULK_MATERIALS = 'ADD_BULK_MATERIALS';
export const ADVANCE_SEARCH = 'ADVANCE_SEARCH';
export const ADVANCE_SEARCH_COUNT = 'ADVANCE_SEARCH_COUNT';
export const GET_BOM_INFO = 'GET_BOM_INFO';
export const SET_BOM_INFO_DEFAULT = 'SET_BOM_INFO_DEFAULT';
export const ADVANCE_SEARCH_INVENTORY = 'ADVANCE_SEARCH_INVENTORY';
export const GET_ORG_MATERIALS_SEARCH = 'GET_ORG_MATERIALS_SEARCH';
export const SHOULD_UPDATE_BOM_ITEM = 'SHOULD_UPDATE_BOM_ITEM';
