import {
  SET_SELECTED_PROJECT,
  GET_PROJECT_ITEMS,
  CREATE_PROJECT,
  PROJECT_API_SUCCESS,
  PROJECT_API_ERROR,
  PROJECT_API_PENDING,
  GET_PROJECT_PROFILE,
  UPDATE_PROJECT_PROFILE,
  CREATE_PROJECT_PROFILE,
  SHOW_PROFILE_FORM,
  GET_ORG_PROJECT_LIST,
  SET_SELECTED_PROJECT_ID,
  PROJECT_DELETE_SUCCESS,
  GET_PROJECT_USERS,
  GET_ASSIGNED_USERS,
  UPDATE_FINANCE_SETTINGS,
  GET_PROJECT_DETAIL,
  UPDATE_PURCHASE_SETTINGS,
  REFRESH_PROJECT_ITEMS,
  SET_INITIAL_STATE,
  RESET_PROJECTS,
  SET_PROJECT_STAGES,
  SET_CUSTOM_TASKLOG_FIELD,
  SET_CONFIG_TASK_SETTING,
  UPDATE_CONFIG_TASK_SETTING,
  SET_RESOURCE_TYPES,
  SET_RESOURCES,
  SET_RESOURCE_UTILISATION,
  UPDATE_DRIVE_SETTINGS,
  SET_HOLIDAYS,
  SET_SELECTED_PROJECT_VIEW_ID,
  SET_SELECTED_TAB_VIEW_ID,
  GET_COUNT_ASYNC_REPORT,
  GET_CF_DROPDOWN_FILTER,
} from './Project.types';
import { ReducerProps } from '../../../../interfaces/Base';
import { ProjectConfigProps } from '../../../../interfaces/Project';
import { MATERIAL_VIEW_TAB } from '../../../../constants';

const configSettings: ProjectConfigProps = {
  config_settings: {
    task: {
      disable_phone_gallery: false,
    },
  },
};

const initialState = {
  selectedProj: {
    id: '',
    name: '',
    role: '',
    web_access: [],
    mobile_access: [],
    org_id: '',
    org_name: '',
  },
  projectItems: {
    projects: [],
    invited: [],
  },
  refreshProjectItems: false,
  orgProjList: [],
  showMutationActions: true,
  projectItem: {},
  acceptSuccess: false,
  deleteSuccess: false,
  projectProfile: {
    _id: '',
    project_name: '',
    project_address: '',
    project_state: '',
    tc: '',
    report_pdf_url: '',
    task_start_date: 0,
    finance_settings: {
      project_dates: {
        start_date: '',
        end_date: '',
      },
      retention_amount: {
        amount: '',
        time: '',
      },
      payment_terms: {
        before_billing: '',
        at_billing: '',
        after_billing: [{ amount: '', time: '' }],
      },
      contract_files: [],
      boq_checklist: [],
      boq_approvers: [{ users: [] }],
    },
    purchase_settings: {
      po_approvers: [{ users: [] }],
      grn_approvers: [{ users: [] }],
      indent_approvers: [{ users: [] }],
      mi_approvers: [{ users: [] }],
      mi_st_approvers: [{ users: [] }],
      po_checklist: [],
      grn_checklist: [],
      site_poc_details: { name: '', mobile: '' },
      project_poc_details: { name: '', mobile: '' },
      is_project_site_poc_same: false,
    },
    drive_settings: [{ users: [] }],
  },
  showForm: false,
  projectUsers: [],
  assignedUsers: [],
  countAsyncReport: null,
  contractFiles: [],
  uploadFilesPending: false,
  pending: false,
  customTaskFields: {},
  projectStages: [],
  holidays: [],
  cfs: {
    TASK: [],
    TASK_LOG: [],
    GRN: [],
  },
  resources: {
    page: 0,
    limit: 20,
    data: [],
    total_count: 0,
  },
  resource_types: [],
  resource_utilisation: {
    page: 0,
    limit: 20,
    data: [],
    total_count: 0,
  },
  selectedProject: '',
  selectedMaterialTab: MATERIAL_VIEW_TAB.PROJECT,
  ...configSettings,
};

export default function projectReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
) {
  switch (type) {
    case SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProj: payload.project,
        projectProfile: payload.profile,

        deleteSuccess: false,
      };
    case SET_SELECTED_PROJECT_ID:
      return {
        ...state,
        selectedProj: payload,
      };
    case GET_PROJECT_ITEMS:
      return { ...state, projectItems: payload };
    case CREATE_PROJECT:
      return {
        ...state,
        projectItem: payload,
      };
    case PROJECT_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case PROJECT_API_PENDING:
      return {
        ...state,
        pending: true,
        showForm: false,
      };
    case PROJECT_DELETE_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        selectedProj: initialState.selectedProj,
        projectProfile: initialState.projectProfile,
      };
    case PROJECT_API_ERROR:
      return {
        ...state,
        pending: false,
        error: error,
      };
    case GET_COUNT_ASYNC_REPORT:
      return {
        ...state,
        countAsyncReport: payload,
      };
    case GET_PROJECT_PROFILE: {
      const data = payload;

      if (Object.keys(data).length) {
        const selProj = {
          ...state.selectedProj,
          name: payload.project_name,
        };
        const projectProf = { ...state.projectProfile, ...data };
        return {
          ...state,
          selectedProj: selProj,
          projectProfile: projectProf,
        };
      } else {
        return state;
      }
    }
    case CREATE_PROJECT_PROFILE:
      return state;
    case UPDATE_PROJECT_PROFILE:
      return {
        ...state,
        contractFiles: [],
      };
    case SHOW_PROFILE_FORM:
      return {
        ...state,
        showForm: payload,
      };
    case GET_ORG_PROJECT_LIST:
      return {
        ...state,
        orgProjList: payload,
      };
    case GET_PROJECT_USERS:
      return { ...state, projectUsers: payload };
    case GET_ASSIGNED_USERS:
      return { ...state, assignedUsers: payload };
    case GET_CF_DROPDOWN_FILTER:
      return { ...state, dropdownCF: payload };
    case UPDATE_FINANCE_SETTINGS:
      return {
        ...state,
        projectProfile: {
          ...state.projectProfile,
          finance_settings: payload,
        },
        contractFiles: [],
      };
    case UPDATE_PURCHASE_SETTINGS: {
      const purSettings = payload;
      return {
        ...state,
        projectProfile: {
          ...state.projectProfile,
          purchase_settings: purSettings,
        },
      };
    }
    case UPDATE_DRIVE_SETTINGS: {
      const driveSettings = payload;
      return {
        ...state,
        projectProfile: {
          ...state.projectProfile,
          drive_settings: driveSettings,
        },
      };
    }
    case REFRESH_PROJECT_ITEMS:
      return { ...state, refreshProjectItems: payload };
    case GET_PROJECT_DETAIL: {
      const data = payload;

      if (Object.keys(data).length) {
        const selProj = {
          ...state.selectedProj,
          id: data._id,
          name: data.name,
          web_access: data.web_access,
          mobile_access: data.mobile_access,
          org_id: data.org_id,
          org_name: data.org_name,
        };
        const projectProf = { ...state.projectProfile, ...data };

        return {
          ...state,
          selectedProj: selProj,
          projectProfile: projectProf,
        };
      } else {
        return state;
      }
    }
    case SET_INITIAL_STATE:
      return initialState;
    case RESET_PROJECTS:
      return {
        ...state,
        projectItems: {
          projects: [],
          invited: [],
        },
      };
    case SET_CUSTOM_TASKLOG_FIELD:
      return { ...state, cfs: payload || [] };
    case SET_PROJECT_STAGES:
      return { ...state, projectStages: payload };
    case SET_CONFIG_TASK_SETTING: {
      return {
        ...state,
        config_settings: payload.config_settings,
      };
    }
    case UPDATE_CONFIG_TASK_SETTING: {
      return {
        ...state,
        config_settings: {
          ...state.config_settings,
          ...payload,
        },
      };
    }

    case SET_RESOURCE_TYPES: {
      return {
        ...state,
        resource_types: payload || [],
      };
    }

    case SET_RESOURCES: {
      return {
        ...state,
        resources: payload || [],
      };
    }

    case SET_RESOURCE_UTILISATION: {
      return {
        ...state,
        resource_utilisation: payload || [],
      };
    }

    case SET_HOLIDAYS: {
      return {
        ...state,
        holidays: payload,
      };
    }
    case SET_SELECTED_PROJECT_VIEW_ID: {
      return {
        ...state,
        selectedProject: payload,
      };
    }
    case SET_SELECTED_TAB_VIEW_ID: {
      return {
        ...state,
        selectedMaterialTab: payload,
      };
    }

    default:
      return state;
  }
}
