import { EXT_TO_MIME_MAP } from '../constant/ext_to_mime';
import { MIME_TO_EXT_MAP } from '../constant/mime_to_extension';

export function getMimeTypeFromExt(ext) {
  return EXT_TO_MIME_MAP[ext] || 'application/octet-stream';
}

export function getExtensionFromMimeType(mime) {
  return MIME_TO_EXT_MAP[mime] || 'file';
}

export function getFileExtension(filename) {
  return filename?.indexOf('.') !== -1
    ? filename?.split('.').pop().toLowerCase()
    : '';
}

export function getMimeTypeFromFilename(filename) {
  const ext = getFileExtension(filename);
  return getMimeTypeFromExt(ext) || 'application/octet-stream';
}
