import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Container } from 'react-bootstrap';

import { requestSupportCall } from '../../redux/features/common/user';
import { toastSuccessMessage } from '../../utils/Toast.util';
import CustomSpinner from '../../components/common/CustomSpinner';
import { useAppDispatch, useAppSelector } from '../../redux/Hooks';

const RequestSupport = ({ onHide }) => {
  const [name, setName] = useState(
    useAppSelector(state => state.userreducer.displayName)
  );
  const [mobNo, setMobNo] = useState('');
  const [message, setMessage] = useState('');
  const isPending = useAppSelector(state => state.userreducer.pending);
  const callRequestSuccess = useAppSelector(
    state => state.userreducer.callRequestSuccess
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (callRequestSuccess) {
      toastSuccessMessage(
        'Support request successfully created, we will contact you soon'
      );
      onHide();
    }
  }, [callRequestSuccess]);

  const handleSubmit = e => {
    e.preventDefault();
    // Dispatch request
    dispatch(requestSupportCall({ name, message, contact_number: mobNo }));
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Row className="my-3 text-black fw-bold ">
          Have a doubt? Request a call back
        </Form.Row>
        <Form.Row className="my-3">
          <Col>
            <Form.Control
              placeholder="Name"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            ></Form.Control>
          </Col>
          <Col>
            <Form.Control
              placeholder="Mobile No."
              value={mobNo}
              type="tel"
              pattern="^\d{10}$"
              onChange={e => setMobNo(e.target.value)}
              required
            ></Form.Control>
          </Col>
        </Form.Row>
        <Form.Row className="my-3">
          <Col>
            <Form.Control
              placeholder="Message"
              as="textarea"
              value={message}
              onChange={e => setMessage(e.target.value)}
              rows="4"
              required
            ></Form.Control>
          </Col>
        </Form.Row>
        <Form.Row className="my-3">
          <Col>
            {isPending ? (
              <CustomSpinner className="text-primary" />
            ) : (
              <Form.Control
                className="w-25 ml-auto primary fw-bold "
                type="submit"
                value="Request Call"
              ></Form.Control>
            )}
          </Col>
        </Form.Row>
      </Form>
    </Container>
  );
};

RequestSupport.propTypes = {
  onHide: PropTypes.func,
};

export default RequestSupport;
