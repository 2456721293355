import React, { useState, useEffect, useCallback } from 'react';
import InputBase from '@mui/material/InputBase';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/CancelRounded';
import SendIcon from '@mui/icons-material/Send';
import BackspaceIcon from '@mui/icons-material/Backspace';
import {
  Avatar,
  ClickAwayListener,
  Collapse,
  Grid,
  IconButton,
} from '@mui/material';

import { addComment, updateComment } from '../../redux/features/common/comment';
import { ACCEPTED_FILE_FORMAT } from '../../constants';
import { getAvatarBackgroundColor, getInitials } from './ProfileImage';
import { RenderFile } from './FilePreviewer';
import { handleS3Upload } from '../../utils/Upload.util';
import '../thread/textboxStyles.scss';
import { FileProps, CommentProps } from '../../interfaces/Base';
import { COMMENT_TYPES } from '../../constant/comment_constants';
import { BUTTON_EVENTS } from '../../constant';
import { track } from '../../redux/features/common/Segment.action';
import { useDropzone } from 'react-dropzone';
import { CustomTooltip } from '.';
import { KEY_CODES } from '../../constant/key_codes';
import { useAppDispatch, useAppSelector } from '../../redux/Hooks';

interface AddCommentFormProps {
  item_id: string;
  isUpdateComment?: boolean;
  comment?: CommentProps;
  onCancel?: () => void;
  type: (typeof COMMENT_TYPES)[keyof typeof COMMENT_TYPES];
  showCancel?: boolean;
  pageSource?: string;
  project_id?: string;
}

function AddCommentForm({
  item_id,
  isUpdateComment = false,
  comment,
  onCancel,
  type,
  showCancel = true,
  pageSource = '',
  project_id = '',
}: AddCommentFormProps) {
  const dispatch = useAppDispatch();

  const projectId =
    useAppSelector(state => state.projectreducer.selectedProj.id) || project_id;
  const orgId = useAppSelector(
    state => state.organisationReducer.orgProfile._id
  );
  const userName = useAppSelector(state => state.userreducer.displayName);

  const [attachedFiles, setAttachedFiles] = useState([]);
  const [inputFocus, setInputFocus] = useState(false);

  const initialCommentObj = {
    message: '',
    item_id: item_id,
    attached_files: [],
    project_id: projectId,
    org_id: orgId,
    type,
  };

  const [formObj, setFormObj] = useState<CommentProps>(
    Object.assign({}, initialCommentObj, comment)
  );

  const handleChange =
    (key: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormObj({
        ...formObj,
        [key]: event.target.value,
      });
    };

  const handleAddComment = () => {
    dispatch(track(BUTTON_EVENTS.COMMENT_POST_CLICKED));
    const tempFormObj = { ...formObj };
    tempFormObj.org_id = orgId;
    tempFormObj.item_id = item_id;
    tempFormObj.project_id = projectId;
    if (tempFormObj.message) {
      dispatch(addComment(tempFormObj, pageSource));
    } else if (tempFormObj?.attached_files?.length) {
      tempFormObj.message = 'Attached File(s)';
      dispatch(addComment(tempFormObj, pageSource));
    } else {
      return;
    }
    setFormObj(Object.assign({}, initialCommentObj, comment));
    onCancel?.();
  };

  const handleUpdateComment = () => {
    const tempFormObj = { ...formObj };
    tempFormObj.org_id = orgId;
    tempFormObj.project_id = projectId;
    if (tempFormObj.message) {
      dispatch(updateComment(tempFormObj));
    } else if (tempFormObj?.attached_files?.length) {
      tempFormObj.message = 'Attached File(s)';
      dispatch(updateComment(tempFormObj));
    } else {
      return;
    }
    setFormObj(Object.assign({}, initialCommentObj, comment));
    onCancel?.();
  };

  useEffect(() => {
    if (attachedFiles.length > 0) {
      const tempFormObj = Object.assign({}, formObj);
      tempFormObj.attached_files = [
        ...(formObj.attached_files as FileProps[]),
        ...attachedFiles,
      ];
      setFormObj(tempFormObj);
    }
  }, [attachedFiles]);

  const handleUpload = async (files: any) => {
    handleS3Upload(files, setAttachedFiles, undefined, projectId, 'comment');
  };

  const onDrop = useCallback(acceptedFiles => {
    const files = acceptedFiles.map(file => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });
    handleUpload(files);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    accept: ACCEPTED_FILE_FORMAT,
    multiple: true,
    onDrop,
  });

  const handleRemoveUploadedImage = (file: FileProps) => () => {
    const tempObj = Object.assign({}, formObj);
    const index = tempObj.attached_files?.indexOf(file);
    const newFiles = tempObj.attached_files?.slice(0);
    newFiles?.splice(index as number, 1);
    URL.revokeObjectURL(file as any);
    tempObj.attached_files = newFiles;
    setFormObj(tempObj);
  };

  const handleKeyDown = (e: any) => {
    if ((e.ctrlKey || e.metaKey) && e.key === KEY_CODES.ENTER) {
      isUpdateComment ? handleUpdateComment() : handleAddComment();
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <Avatar
            className={'square-medium fes-l'}
            style={{
              background: getAvatarBackgroundColor(userName),
            }}
          >
            {getInitials(userName || 'P P')}
          </Avatar>
        </Grid>
        <ClickAwayListener onClickAway={() => setInputFocus(false)}>
          <Grid item className="flex-fill">
            <InputBase
              multiline
              fullWidth
              autoFocus={isUpdateComment}
              maxRows={7}
              onKeyDown={handleKeyDown}
              onFocus={() => setInputFocus(true)}
              className={
                inputFocus ? `inputBaseFocusedStyles` : `inputBaseStyles`
              }
              placeholder={'Add a comment...'}
              value={formObj.message}
              onChange={handleChange('message')}
            />
            <div
              className={`${
                inputFocus
                  ? 'focusedTextboxImagesSection'
                  : 'textboxImagesSection'
              } w-100 px-2`}
            >
              {formObj.attached_files?.length ? (
                <div className="file-previewer mx-n2">
                  {formObj.attached_files?.map((file, idx) => (
                    <div className="uploadable-text-box" key={idx}>
                      <RenderFile file={file} size="small" />
                      <CancelIcon
                        onClick={handleRemoveUploadedImage(file)}
                        className="hand-pointer remove-img-sm"
                      />
                    </div>
                  ))}
                </div>
              ) : null}

              <Collapse in={inputFocus}>
                {showCancel ? (
                  <CustomTooltip title="Cancel" placement="top">
                    <IconButton
                      disabled={!formObj?.message}
                      className="p-1"
                      onClick={onCancel}
                      size="large"
                    >
                      <BackspaceIcon className={'light fes-s'} />
                    </IconButton>
                  </CustomTooltip>
                ) : null}

                <span {...getRootProps()}>
                  <CustomTooltip title="Attach File(s)" placement="top">
                    <IconButton className="p-0 mr-1" size="large">
                      <AttachFileIcon
                        className={'light fes-m'}
                        style={{ transform: 'rotate(-135deg)' }}
                      />
                      <input {...getInputProps()} />
                    </IconButton>
                  </CustomTooltip>
                </span>

                <CustomTooltip title="Post Comment" placement="top">
                  <IconButton
                    disabled={!formObj?.message && !attachedFiles.length}
                    classes={{
                      root: formObj.message ? 'bg-primary' : 'bg-light-default',
                    }}
                    className="p-1"
                    onClick={
                      isUpdateComment ? handleUpdateComment : handleAddComment
                    }
                    size="large"
                  >
                    <SendIcon className={'text-white fes-xs'} />
                  </IconButton>
                </CustomTooltip>
              </Collapse>
            </div>
          </Grid>
        </ClickAwayListener>
      </Grid>
    </>
  );
}

export default AddCommentForm;
