import {
  GET_EXPENSE,
  GET_ALL_EXPENSES,
  GET_EXPENSE_PENDING,
  GET_EXPENSES_PENDING,
  CREATE_EXPENSE_PENDING,
  UPDATE_EXPENSE_PENDING,
  EXPENSE_API_ERROR,
  UPLOAD_EXPENSE_FILES_PENDING,
  UPLOAD_EXPENSE_FILES,
} from './Expense.type';
const initialPendingState = {
  getAllExpensesPending: false,
  getExpensePending: false,
  createExpensePending: false,
  updateExpensePending: false,
  uploadFilesPending: false,
};

const initialState = {
  ...initialPendingState,
  expense: {},
  expenses: [],
  error: null,
  selectedExpense: null,
  isFilter: false,
  filterValues: {},
  proof_files: {},
};
export default function expenseReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case GET_EXPENSE:
      return { ...state, expense: payload };
    case GET_EXPENSES_PENDING:
      return { ...state, getAllExpensesPending: payload };
    case GET_EXPENSE_PENDING:
      return { ...state, getExpensePending: payload };
    case GET_ALL_EXPENSES:
      return { ...state, expenses: payload };
    case EXPENSE_API_ERROR:
      return { ...state, error: error };
    case CREATE_EXPENSE_PENDING:
      return { ...state, createExpensePending: payload };
    case UPDATE_EXPENSE_PENDING:
      return { ...state, updateExpensePending: payload };
    case UPLOAD_EXPENSE_FILES_PENDING:
      return { ...state, uploadFilesPending: payload };
    case UPLOAD_EXPENSE_FILES:
      return { ...state, proof_files: payload };
    default:
      return state;
  }
}
