import { TASK_STATUS } from '../../constant';
import { TaskProps } from '../../interfaces/Task';

interface Props {
  task: TaskProps;
  className?: string;
}

export function TaskStatusTag({ task, className }: Props) {
  if (!task) {
    return <></>;
  }

  let text = task?.meta_data?.statusText || '';
  if (!text) {
    switch (task.task_status) {
      case TASK_STATUS.COMPLETED:
        text = 'Completed';
        break;
      case TASK_STATUS.IN_PROGRESS:
        text = 'In Progress';
        break;
      case TASK_STATUS.NOT_STARTED:
        text = 'Not Started';
        break;
    }
  }

  return (
    <span
      className={`task-status-tag-${task?.meta_data?.statusClass} ${
        className || ''
      }`}
    >
      {text}
    </span>
  );
}

export function TaskTag({ text }: { text: string }) {
  return <span className="task-tag">{text}</span>;
}
