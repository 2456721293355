export const WORK_CATEGORIES_TAB_KEY = 'WORK_CATEGORIES_TAB_KEY';
export const VERIFY_GST_NUMBER = 'VERIFY_GST_NUMBER';
export const WORK_TABLE_SHOW = 'WORK_TABLE_SHOW';
export const PACKAGE_ID_SELECTED = 'PACKAGE_ID_SELECTED';
export const VENDOR_ID_SELECTED = 'VENDOR_ID_SELECTED';
export const BOM_ID_SELECTED = 'BOM_ID_SELECTED';
export const UTILS_API_PENDING = 'UTILS_API_PENDING';
export const UTILS_API_ERROR = 'UTILS_API_ERROR';
export const UTILS_API_SUCCESS = 'UTILS_API_SUCCESS';
export const PO_DETAILS_SHOW = 'PO_DETAILS_SHOW';
export const INDENT_DETAILS_SHOW = 'INDENT_DETAILS_SHOW';
export const PO_ID_SELECTED = 'PO_ID_SELECTED';
export const INDENT_ID_SELECTED = 'INDENT_ID_SELECTED';
export const CLEAR_UTILS_DATA = 'CLEAR_UTILS_DATA';
export const TOGGLE_SIDEBAR_MENU = 'TOGGLE_SIDEBAR_MENU';
export const REGISTER_ZOHO = 'REGISTER_ZOHO';
export const SYNC_PO_STATUS = 'SYNC_PO_STATUS';
export const SELECTED_KEY = 'SELECTED_KEY';
export const MX_LOOKUP_SUCCESS = 'MX_LOOKUP_SUCCESS';
export const MX_LOOKUP_FAILURE = 'MX_LOOKUP_FAILURE';
