export const GANTT_VIEW_TASK_DRAG = 'gantt_view_drag';
export const GANTT_TASK_SELECTED = 'task_clicked';
export const SIDE_NAV_SITE_PLAN_LOAD = 'side_nav_site_plan_load';
export const TASK_VIEW_CLICKED = 'task_view_clicked';
export const TASK_VIEW_LOAD_SUCCESS = 'task_view_load_success';
export const TASK_MODULE_LOAD_FAILURE = 'task_module_load_failure';
export const GANTT_VIEW_CLICKED = 'gantt_view_clicked';
export const GANTT_VIEW_LOAD_SUCCESS = 'gantt_view_load_success';
export const RESIZE_GANTT = 'resize_gantt_view';
export const RESIZE_GANTT_COLUMN = 'resize_grid_column';
export const TASK_VIEW_FILTER_BTN_CLICK = 'task_view_filter_btn_click';

//Apartment template_plan events
export const TEMP_PLAN_MENU_CLICKED = 'template_plan_menu_clicked';
export const TEMP_PLAN_TOWER_SELECTED = 'template_plan_tower_selected';
export const TEMP_PLAN_FLOOR_SELECTED = 'template_plan_floor_selected';
export const TEMP_PLAN_FLAT_SELECTED = 'template_plan_flat_selected';
export const TEMP_PLAN_CIVIL_SELECTED = 'template_plan_civil_selected';
export const TEMP_PLAN_MEP_SELECTED = 'template_plan_mep_selected';
export const TEMP_PLAN_CREATE_PLAN_CLICKED =
  'template_plan_create_plan_clicked';
export const TEMP_PLAN_TASK_LOAD = 'template_plan_task_load';
export const TEMP_PLAN_NEXT_STEP_CLICKED = 'template_plan_next_step_clicked';
export const TEMP_PLAN_PREV_STEP_CLICKED = 'template_plan_prev_step_clicked';
export const TEMP_PLAN_CROSS_BUTTON_CLICKED =
  'template_plan_cross_button_clicked';
export const TEMP_PLAN_CROSS_BUTTON_RESPONSE =
  'template_plan_cross_button_response';
export const TEMP_PLAN_BODY_SCROLL = 'template_plan_body_scroll';
export const TEMP_PLAN_TASK_EXPAND = 'template_plan_task_expand';

export const TASK_PLAN_STATUS_CHANGED = 'task_plan_status_changed';
export const BULK_TASK_PLAN_STATUS_CHANGED = 'bulk_task_plan_status_changed';
