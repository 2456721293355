export const CREATE_THREAD_PENDING = 'CREATE_THREAD_PENDING';
export const GET_THREAD_PENDING = 'GET_THREAD_PENDING';
export const GET_THREAD = 'GET_THREAD';
export const GET_THREADS_LIST_PENDING = 'GET_THREADS_LIST_PENDING';
export const GET_THREADS_LIST = 'GET_THREADS_LIST';
export const UPDATE_THREAD_PENDING = 'UPDATE_THREAD_PENDING';
export const DELETE_THREAD_PENDING = 'DELETE_THREAD_PENDING';
export const CLOSE_THREAD_PENDING = 'CLOSE_THREAD_PENDING';
export const THREAD_API_ERROR = 'THREAD_API_ERROR';
export const CREATE_THREAD_WITH_ITEM_PENDING =
  'CREATE_THREAD_WITH_ITEM_PENDING';
export const GET_THREADS_WITH_ITEM_PENDING = 'GET_THREADS_WITH_ITEM_PENDING';
export const GET_THREADS_WITH_ITEM_LIST = 'GET_THREADS_WITH_ITEM_LIST';
export const SET_THREADS_PER_PAGE = 'SET_THREADS_PER_PAGE';
export const CLEAR_THREAD_DETAILS = 'CLEAR_THREAD_DETAILS';
