import {
  DELETE_MI_ITEM,
  GET_MI_ITEM,
  MI_API_ERROR,
  MI_API_PENDING,
  UPDATE_MI_ITEM,
  FORM_SUBMIT_PENDING,
  FORM_SUBMIT_ERROR,
  FORM_SUBMIT_SUCCESS,
} from './MaterialIssue.type';

const pendingInitialStae = {
  pending: false,
};

const initialState = {
  ...pendingInitialStae,
  miItemData: {},
  updateData: {},
  error: null,
};

export default function miReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case GET_MI_ITEM:
      return { ...state, miItemData: payload };
    case UPDATE_MI_ITEM:
      return { ...state, updateData: payload };
    case DELETE_MI_ITEM:
      return { ...state, updateData: payload };
    case MI_API_PENDING:
      return { ...state, pending: payload };
    case MI_API_ERROR:
      return { ...state, error: error };
    case FORM_SUBMIT_PENDING:
      return { ...state, pending: true };
    case FORM_SUBMIT_SUCCESS:
    case FORM_SUBMIT_ERROR:
      return {
        ...state,
        pending: false,
      };

    default:
      return state;
  }
}
