import {
  GET_ALL_TC,
  GET_TC,
  CREATE_TC,
  UPDATE_TC,
  DELETE_TC,
  TC_API_ERROR,
  CREATE_TC_PENDING,
  GET_TCS_PENDING,
  GET_TC_PENDING,
  UPDATE_TC_PENDING,
  DELETE_TC_PENDING,
} from './TermsAndConditions.type';

const pendingInitialStae = {
  createTCPending: false,
  deleteTCPending: false,
  getTCPending: false,
  getTCSPending: false,
  updateTCPending: false,
};

const initialState = {
  ...pendingInitialStae,
  terms_and_conditions: [],
  selected_tc: null,
  added_tc: null,
  delete_tc: null,
  update_tc: null,
  error: null,
};

export default function termsAndConditionsReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case CREATE_TC_PENDING:
      return { ...state, createTCPending: payload };
    case CREATE_TC:
      return { ...state, added_tc: payload, selected_tc: payload };
    case GET_TCS_PENDING:
      return { ...state, getTCSPending: payload };
    case GET_ALL_TC:
      return { ...state, terms_and_conditions: payload };
    case GET_TC_PENDING:
      return { ...state, getTCSPending: payload };
    case GET_TC:
      return { ...state, selected_tc: payload };
    case UPDATE_TC_PENDING:
      return { ...state, updateTCPending: payload };
    case UPDATE_TC:
      return { ...state, update_tc: payload };
    case DELETE_TC_PENDING:
      return { ...state, deleteTCPending: payload };
    case DELETE_TC:
      return { ...state, delete_tc: payload };
    case TC_API_ERROR:
      return { ...state, error: error };
    default:
      return state;
  }
}
