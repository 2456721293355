export const WHATS_NEW_LOAD_SUCCESS = 'whats_new_load_success';
export const WHATS_NEW_LOAD_FAILURE = 'whats_new_load_failure';
export const ORG_DASHBOARD_OPEN = 'org_dashboard_open';
export const ORG_DASHBOARD_TAB_CHANGE = 'org_dashboard_tab_change';

export const PIN_PROJECT_CLICK = 'pin_project_clicked';
export const UNPIN_PROJECT_CLICK = 'unpin_project_clicked';
export const PIN_PROJECT_LIMIT_EXCEED_ERROR = 'pin_project_limit_exceed_error';

export const PIN_PROJECT_ERROR = 'pin_project_error';
export const UNPIN_PROJECT_ERROR = 'unpin_project_error';
export const TASK_DASHBOARD_FILTER_CLICK = 'task_dashboard_filter_click';
export const FILTER_OPTION_SELECT = 'filter_option_select';
export const FILTER_SEARCH_BAR_CLICK = 'filter_search_bar_click';
export const APPLY_FILTER_BUTTON_CLICK = 'apply_filter_button_click';
export const APPLY_FILTER_CLICK_SUCCESS = 'apply_filter_click_success';
