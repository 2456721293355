/* eslint-disable */

interface IUseSessionStorage {
  isSessionStorageAvailable: () => boolean;
  getSessionStorageValue: (key?: string, shouldParse?: boolean) => any;
  setSessionStorageValue: (
    value: any,
    key?: string,
    shouldStringify?: boolean
  ) => void;
  removeSessionStorageValue: (key?: string) => void;
  clearSessionStorageValues: () => void;
  checkSessionStorageValue: (key?: string) => boolean;
}

const useSessionStorage = (initialkey?: string): IUseSessionStorage => {
  const isSessionStorageAvailable = (): boolean => {
    try {
      const testKey = '__storage_test__';
      window.sessionStorage.setItem(testKey, 'test');
      window.sessionStorage.removeItem(testKey);
      return true;
    } catch (error) {
      console.warn('SessionStorage is not available:', error);
      return false;
    }
  };

  const getSessionStorageValue = (
    key: string = initialkey,
    shouldParse: boolean = true
  ) => {
    if (!key || !isSessionStorageAvailable()) return null;

    try {
      const item = window.sessionStorage.getItem(key);
      return shouldParse && item ? JSON.parse(item) : item || null;
    } catch (error) {
      console.error('Error reading sessionStorage key:', key, error);
      return null;
    }
  };

  const setSessionStorageValue = (
    key: string = initialkey,
    value: any,
    shouldStringify: boolean = true
  ) => {
    if (!key || !isSessionStorageAvailable()) return;

    try {
      window.sessionStorage.setItem(
        key,
        shouldStringify ? JSON.stringify(value) : value
      );
    } catch (error) {
      console.error('Error setting sessionStorage key:', key, error);
    }
  };

  const removeSessionStorageValue = (key: string = initialkey) => {
    if (!key || !isSessionStorageAvailable()) return;

    try {
      window.sessionStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing sessionStorage key:', key, error);
    }
  };

  const clearSessionStorageValues = () => {
    if (!isSessionStorageAvailable()) return;

    try {
      window.sessionStorage.clear();
    } catch (error) {
      console.error(
        'Error removing all sessionStorage key pair values:',
        error
      );
    }
  };

  const checkSessionStorageValue = (key: string = initialkey): boolean => {
    if (!key || !isSessionStorageAvailable()) return false;

    try {
      return window.sessionStorage.getItem(key) !== null;
    } catch (error) {
      console.error('Error checking sessionStorage key:', key, error);
      return false;
    }
  };

  return {
    isSessionStorageAvailable,
    getSessionStorageValue,
    setSessionStorageValue,
    removeSessionStorageValue,
    clearSessionStorageValues,
    checkSessionStorageValue,
  };
};

export default useSessionStorage;
