import React from 'react';

const SmallScreenError = () => {
  return (
    <div className="small-screen-error-container">
      <div className="mb-3">
        We are currently extending our domain to mobile.
      </div>
      <div className="mb-3">Switch to desktop for a nice experience.</div>
      <div className="mb-3">
        We would <span className="text-primary">love</span> to see you there :)
      </div>
    </div>
  );
};

export default SmallScreenError;
