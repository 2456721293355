import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import StarIcon from '@mui/icons-material/Star';

import {
  CustomMenuItem as MenuItem,
  CustomMenu as Menu,
} from '../../common/CustomMenu';
import CustomTooltip from '../../common/customTooltip/CustomTooltip';
import { updateUserDetails } from '../../../redux/features/common/user';
import { NAVBAR_EVENTS } from '../../../constant';
import { track } from '../../../redux/features/common/Segment.action';
import { CustomButton } from '../../common';
import CreateOrgDialog from './NavbarNav/CreateOrgDialog';
import { useAppDispatch, useAppSelector } from '../../../redux/Hooks';
import useSessionStorage from '../../../utils/hooks/useSessionStorage.hook';

const OrgDropdown = () => {
  const dispatch = useAppDispatch();
  const { orgId } = useParams();
  const history = useNavigate();
  const { getSessionStorageValue } = useSessionStorage();

  const [open, setOpen] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});

  const userOrgs = useAppSelector(state => state.userreducer.userOrgs);
  const user = useAppSelector(state => state.userreducer.user);
  const lastOrg = useAppSelector(state => state.organisationReducer.orgProfile);

  userOrgs.sort((org1, org2) => org2.is_paid - org1.is_paid);

  const handleOrgChange = val => {
    setDefaultValue({
      value: val.value,
      label: val.label,
    });
    dispatch(
      track(NAVBAR_EVENTS.CHANGE_ORG, {
        org_id_selected: val.value,
        number_of_orgs: userOrgs.length,
      })
    );
    dispatch(
      updateUserDetails(
        { ...user, org_id: val.value, org_name: val.label },
        true,
        () => history('/projects')
      )
    );
  };

  useEffect(() => {
    if (Object.keys(user).length) {
      const idx = userOrgs.findIndex(e => e.value === user.org_id);
      const userOrg = userOrgs[idx];
      if (
        idx > 0 &&
        userOrgs.length > 1 &&
        !userOrg?.is_paid &&
        userOrg?.is_expired &&
        getSessionStorageValue('first_visit', false) === 'true'
      ) {
        handleOrgChange({
          value: userOrgs[0]?.value,
          label: userOrgs[0]?.label,
        });
      } else {
        setDefaultValue({
          value: user.org_id,
          label: userOrg?.label,
        });
      }
    }
  }, [userOrgs, user]);

  useEffect(() => {
    if (orgId && userOrgs.length) {
      const org = userOrgs.find(org => org.value === orgId);
      if (org) {
        handleOrgChange(org);
      }
    }
  }, [orgId, userOrgs.length]);

  return (
    <>
      {userOrgs.length ? (
        <div className="dropdown-container">
          <Menu
            onOpenEvent={NAVBAR_EVENTS.ORG_DROPDOWN_OPEN}
            element={
              <CustomTooltip title={lastOrg.org_name || defaultValue.label}>
                <div className={`organization-dropdown`}>
                  <span className="text-truncate">
                    {lastOrg.org_name || defaultValue.label}
                  </span>
                  {userOrgs?.length > 0 && <ExpandMore className={`ico`} />}
                </div>
              </CustomTooltip>
            }
            PaperProps={{
              style: {
                maxHeight: '55vh',
              },
            }}
          >
            {userOrgs.map(item => {
              return (
                <MenuItem
                  key={item.value}
                  selected={item.value === lastOrg._id}
                  classes={{ selected: 'bg-blue-200' }}
                  onClick={() => handleOrgChange(item)}
                >
                  <span className={'mr-3'}>{item.label}</span>
                  {item.is_paid ? (
                    <StarIcon className="rounded-circle text-white bg-primary p-1" />
                  ) : null}
                </MenuItem>
              );
            })}
            <MenuItem
              alignItems="center"
              onClick={() => setOpen(true)}
              disableGutters
            >
              <CustomButton
                fullWidth
                startIcon={<AddIcon />}
                label="Add Organisation"
                className="fw-bold fes-l"
                disableElevation
                disableRipple
                disableTouchRipple
                variant="text"
              />
            </MenuItem>
          </Menu>
        </div>
      ) : null}

      <CreateOrgDialog open={open} setOpen={setOpen} />
    </>
  );
};

export default OrgDropdown;
