import { Dispatch } from 'redux';

import apiClient from '../../../../utils/ApiClient.util';
import {
  NOTIFICATION_API_SUCCESS,
  NOTIFICATION_API_ERROR,
  NOTIFICATION_API_PENDING,
  GET_UNSEEN_COUNT,
  UPDATE_LAST_SEEN,
  GET_NOTIFICATIONS,
} from './Notification.type';
import { parseString } from '../../../../utils/Common.util';
import { NOTIFICATION_APIS } from '../../../../routes.constant';
import {
  GetAllNotificationsParamsProps,
  GetUnseenCountParamsProps,
} from '../../../../interfaces/Notifications';
import { eventDispatch, errorDispatch } from '../../../base';
import { toastErrorMessage } from '../../../../utils/Toast.util';
import { customStringify } from '../../../../utils/Url.util';

export function getAllNotifications(params: GetAllNotificationsParamsProps) {
  const apiUrl = parseString(
    NOTIFICATION_APIS.getAllNotifications,
    customStringify(params)
  );
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(NOTIFICATION_API_PENDING));
    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(NOTIFICATION_API_SUCCESS));
      dispatch(eventDispatch(GET_NOTIFICATIONS, data));
    } catch (error) {
      dispatch(errorDispatch(NOTIFICATION_API_ERROR, error));
      toastErrorMessage('Error in fetching records');
    }
  };
}

export function getUnseenCount(params: GetUnseenCountParamsProps) {
  const apiUrl = parseString(
    NOTIFICATION_APIS.getUnseenCount,
    customStringify(params)
  );
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(NOTIFICATION_API_PENDING));
    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(NOTIFICATION_API_SUCCESS));
      dispatch(eventDispatch(GET_UNSEEN_COUNT, data));
    } catch (error) {
      dispatch(errorDispatch(NOTIFICATION_API_ERROR, error));
      toastErrorMessage('Error in fetching records');
    }
  };
}

export const updateLastSeen = (params: GetAllNotificationsParamsProps) => {
  const apiUrl = parseString(
    NOTIFICATION_APIS.updateLastSeen,
    customStringify(params)
  );
  return async (dispatch: Dispatch<any>, getState: any) => {
    const project_id = getState().projectreducer.selectedProj.id;
    dispatch(eventDispatch(NOTIFICATION_API_PENDING));
    try {
      const { data } = await apiClient.put(apiUrl, {});
      dispatch(eventDispatch(NOTIFICATION_API_SUCCESS));
      dispatch(eventDispatch(UPDATE_LAST_SEEN, data));
      dispatch(getUnseenCount({ project_id }));
    } catch (error) {
      dispatch(errorDispatch(NOTIFICATION_API_ERROR, error));
      toastErrorMessage('Error in fetching records');
    }
  };
};

export const markAllRead = (params: GetAllNotificationsParamsProps) => {
  return async (dispatch: Dispatch<any>, getState: any) => {
    const project_id = getState().projectreducer.selectedProj.id;
    dispatch(eventDispatch(NOTIFICATION_API_PENDING));
    try {
      await apiClient.post(NOTIFICATION_APIS.markAllRead, params);
      dispatch(eventDispatch(NOTIFICATION_API_SUCCESS));
      dispatch(
        getAllNotifications({
          project_id: project_id,
          sort_option: '-createdAt',
        })
      );
    } catch (error) {
      dispatch(errorDispatch(NOTIFICATION_API_ERROR, error));
      toastErrorMessage('Error in fetching records');
    }
  };
};

export const markClicked = (notifId: string) => {
  const apiUrl = parseString(NOTIFICATION_APIS.markClicked, notifId);
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(NOTIFICATION_API_PENDING));
    try {
      await apiClient.put(apiUrl, {});
      dispatch(eventDispatch(NOTIFICATION_API_SUCCESS));
    } catch (error) {
      dispatch(eventDispatch(NOTIFICATION_API_ERROR, error));
      toastErrorMessage('Error in fetching records');
    }
  };
};
