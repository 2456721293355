import {
  GET_RUNNING_BOQ_ITEMS,
  GET_RUNNING_BOM_ITEMS,
  FINANCE_API_ERROR,
  FINANCE_API_PENDING,
  FINANCE_API_SUCCESS,
  GET_MASTER_BOQ_ITEMS,
  ADD_RUNNING_BOQ_ITEMS,
} from './Finance.type';

const initialState = {
  pending: false,
  runningBoqs: [],
  runningBoms: [],
  masterBoqs: [],
  error: null,
  itemAdded: false,
};

export default function financeReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case GET_RUNNING_BOQ_ITEMS:
      return { ...state, runningBoqs: payload };
    case ADD_RUNNING_BOQ_ITEMS:
      return { ...state, itemAdded: true };
    case GET_RUNNING_BOM_ITEMS:
      return { ...state, runningBoms: payload };
    case GET_MASTER_BOQ_ITEMS:
      return { ...state, masterBoqs: payload };
    case FINANCE_API_PENDING:
      return {
        ...state,
        pending: true,
        itemAdded: false,
      };
    case FINANCE_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case FINANCE_API_ERROR:
      return {
        ...state,
        error: error,
        pending: false,
      };
    default:
      return state;
  }
}
