import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';

import {
  CustomButton,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
  CustomTextField,
  SideScreenDialog,
} from '../../../common';
import OrgProfileProps from '../../../../interfaces/Organisation';
import { validateForm } from '../../../../utils';
import { createOrg } from '../../../../redux/features/miscellaneous/onboarding';
import { useAppDispatch } from '../../../../redux/Hooks';

type formDataType = {
  id: keyof OrgProfileProps;
  label: string;
  required?: boolean;
  errorText?: string;
};

const formData: formDataType[] = [
  { id: 'org_name', label: 'Name', required: true, errorText: 'required' },
  { id: 'pan', label: 'PAN' },
  { id: 'tan', label: 'TAN' },
];

const schema = yup.object().shape({
  org_name: yup.string().required(),
});

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function CreateOrgDialog({ open, setOpen }: Props) {
  const dispatch = useAppDispatch();
  const [formObj, setFormObj] = useState<OrgProfileProps>({ org_name: '' });
  const [errorList, setErrorList] = useState<string[]>([]);

  const handleClose = () => {
    setOpen(false);
    setFormObj({ org_name: '' });
    setErrorList([]);
  };

  const handleChange = (id: keyof OrgProfileProps) => (e: any) => {
    const val = e.target.value;
    setFormObj(prev => ({ ...prev, [id]: val }));
  };

  const handleSubmit = async () => {
    const { isError, valErrors } = await validateForm(schema, formObj);
    if (!isError) {
      dispatch(createOrg(formObj));
      handleClose();
    } else {
      setErrorList(valErrors);
    }
  };

  return (
    <>
      <SideScreenDialog
        open={Boolean(open)}
        width={35}
        className="typography-base"
      >
        <CustomDialogTitle onClose={handleClose}>
          {'Create Organisation'}
        </CustomDialogTitle>
        <CustomDialogContent>
          <Grid container spacing={1}>
            {formData.map(item => (
              <React.Fragment key={item.id}>
                <Grid item xs={2} className="mt-2">
                  {item.label}
                </Grid>
                <Grid item xs={10}>
                  <CustomTextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    required={item.required}
                    rows={3}
                    error={errorList.includes(item.id)}
                    helperText={
                      errorList.includes(item.id) ? item.errorText : ' '
                    }
                    maxRows={7}
                    InputProps={{
                      classes: { root: 'typography-base' },
                    }}
                    value={formObj?.[item.id]}
                    onChange={handleChange(item.id)}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </CustomDialogContent>
        <CustomDialogActions leftAlign>
          <CustomButton
            uppercase
            label={'Create Organisation'}
            handleSubmit={handleSubmit}
          />
        </CustomDialogActions>
      </SideScreenDialog>
    </>
  );
}

export default CreateOrgDialog;
