export const REPORT_PROGRESS_DEEPLINK_CLICKED =
  'REPORT_PROGRESS_DEEPLINK_CLICKED';
export const REPORT_PHOTOS_DEEPLINK_CLICKED = 'REPORT_PHOTOS_DEEPLINK_CLICKED';
export const REPORT_MATERIALS_DEEPLINK_CLICKED =
  'REPORT_MATERIALS_DEEPLINK_CLICKED';
export const REPORT_ATTENDANCE_DEEPLINK_CLICKED =
  'REPORT_ATTENDANCE_DEEPLINK_CLICKED';
export const REPORT_ISSUES_DEEPLINK_CLICKED = 'REPORT_ISSUES_DEEPLINK_CLICKED';
export const BROWSER_VIEW_OPEN_IN_APP_CLICKED =
  'BROWSER_VIEW_OPEN_IN_APP_CLICKED';
export const WEB_REPORT_OPENED = 'WEB_REPORT_OPENED';

export const PLAN_EXTRACT_DOWNLOAD_SUCCESS = 'PLAN_EXTRACT_DOWNLOAD_SUCCESS';
export const PLAN_EXTRACT_DOWNLOAD_FAILURE = 'PLAN_EXTRACT_DOWNLOAD_FAILURE';
export const PLAN_EXTRACT_BTN_CLICK = 'PLAN_EXTRACT_BTN_CLICK';

export const CLIENT_REPORT_SELECTED = 'client_report_selected';
export const INTERNAL_TEAM_REPORT_SELECTED = 'internal_team_report_selected';
export const REPORT_GENERATE_PAGE_SCROLL = 'report_generate_page_scroll';
export const GENERATE_REPORT_SUCCESS = 'generate_report_success';
export const REPORT_IMAGE_SELECTED = 'report_image_selected';
export const REPORT_IMAGE_SCROLL = 'report_image_scrolled';
