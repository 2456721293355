import React, { useState, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';

import { BellIcon } from '../../../../common/Icons';
import Notifications from './Notifications';
import {
  getUnseenCount,
  updateLastSeen,
} from '../../../../../redux/features/miscellaneous/notification';
import { ONE_MIN } from '../../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../../redux/Hooks';
import './NotificationMenuStyles.scss';

// eslint-disable-next-line react/prop-types
const StyledBadge = ({ children, ...props }) => {
  return <Badge {...props}>{children}</Badge>;
};

const NotificationMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const projectId = useAppSelector(
    state => state.projectreducer.selectedProj.id
  );
  const unseenCount = useAppSelector(
    state => state.notificationReducer.unseenCount
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (projectId) {
      if (!unseenCount) {
        dispatch(getUnseenCount({ project_id: projectId }));
      }

      const timer = setInterval(() => {
        dispatch(getUnseenCount({ project_id: projectId }));
      }, 5 * ONE_MIN);

      return () => clearTimeout(timer);
    }
  }, []);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (unseenCount) {
      dispatch(updateLastSeen({ project_id: projectId }));
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledBadge
        className="hand-pointer"
        aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
        badgeContent={unseenCount}
        aria-haspopup="true"
        color="primary"
        onClick={handlePopoverOpen}
      >
        <BellIcon />
      </StyledBadge>

      <Popover
        id="mouse-over-popover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className="notificationMenuPopover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      >
        <Notifications handlePopoverClose={handlePopoverClose} />
      </Popover>
    </>
  );
};

export default NotificationMenu;
