import {
  SET_EMBED_URL,
  SET_ANALYTICS_DASHBOARD_API_PENDING,
  SET_ANALYTICS_DASHBOARD_LOADING,
  SET_ANALYTICS_DASHBOARD_API_ERROR,
  SET_ANALYTICS_DASHBOARD_API_SUCCESS,
  SET_VIEWS,
} from './index';
import { ReducerProps } from '../../../../interfaces/Base';

const initialState = {
  status: '',
  summary: '',
  embedURL: '',
  pending: false,
  error: false,
  loading: false,
  orgViews: [],
};

export default function analyticsDashboardReducer(
  state = initialState,
  { type, payload }: ReducerProps
) {
  switch (type) {
    case SET_EMBED_URL:
      return {
        ...state,
        status: payload?.status,
        summary: payload?.summary,
        embedURL: payload?.url,
      };

    case SET_VIEWS:
      return {
        ...state,
        orgViews: payload,
      };

    case SET_ANALYTICS_DASHBOARD_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case SET_ANALYTICS_DASHBOARD_API_PENDING:
      return {
        ...state,
        pending: payload,
      };
    case SET_ANALYTICS_DASHBOARD_API_ERROR:
      return {
        ...state,
        pending: false,
        error: payload,
      };
    case SET_ANALYTICS_DASHBOARD_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
}
