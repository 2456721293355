export const GET_TEAM_TEMPLATES = 'GET_TEAM_TEMPLATES';
export const ORG_API_SUCCESS = 'ORG_API_SUCCESS';
export const ORG_API_ERROR = 'ORG_API_ERROR';
export const ORG_API_PENDING = 'ORG_API_PENDING';
export const GET_ORG_MEMBERS = 'GET_ORG_MEMBERS';
export const GET_ORG_MEMBERS_PAGINATED = 'GET_ORG_MEMBERS_PAGINATED';
export const GET_ORG_TEAMS = 'GET_ORG_TEAMS';
export const GET_ORG_MEMBERS_PENDING = 'GET_ORG_MEMBERS_PENDING';
export const GET_ORG_TEAMS_PENDING = 'GET_ORG_TEAMS_PENDING';
export const GET_TEAM_MEMBERS_PENDING = 'GET_TEAM_MEMBERS_PENDING';
export const ADD_ORG_TEAM = 'ADD_ORG_TEAM';
export const UPDATE_ORG_TEAM = 'UPDATE_ORG_TEAM';
export const DELETE_ORG_TEAM = 'DELETE_ORG_TEAM';
export const GET_ORG_PROFILE = 'GET_ORG_PROFILE';
export const CREATE_ORG_PROFILE = 'CREATE_ORG_PROFILE';
export const UPDATE_ORG_PROFILE = 'UPDATE_ORG_PROFILE';
export const SET_SELECTED_TEAM = 'SET_SELECTED_TEAM';
export const UPDATE_TEAM_USER = 'UPDATE_TEAM_USER';
export const DELETE_TEAM_USER = 'DELETE_TEAM_USER';
export const DELETE_ORG_USER = 'DELETE_ORG_USER';
export const GET_ORG_PKG_LIST = 'GET_ORG_PKG_LIST';
export const GET_ORG_VENDOR_LIST = 'GET_ORG_VENDOR_LIST';
export const UPDATING_SETTINGS = 'UPDATING_SETTINGS';
export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE';
export const SET_QUESTIONNAIRE = 'SET_QUESTIONNAIRE';
export const SET_ORG_INFO_PENDING = 'SET_ORG_INFO_PENDING';
export const SET_TEAM_MEMBERS = 'SET_TEAM_MEMBERS';
export const GET_PURCHASE_ORDER_SEQUENCE = 'GET_PURCHASE_ORDER_SEQUENCE';
export const SET_ORG_EXPERIMENTS = 'SET_ORG_EXPERIMENTS';
export const UPDATE_EXP_METADATA = 'UPDATE_EXP_METADATA';
export const GET_INVENTORIES_BY_ORG_ID = 'GET_INVENTORIES_BY_ORG_ID';
export const GET_ORG_ADMINS = 'GET_ORG_ADMINS';
export const SET_MEMBERS_PAGE = 'SET_MEMBERS_PAGE';
export const SET_MEMBERS_LIMIT = 'SET_MEMBERS_LIMIT';
export const SET_MEMBERS_FILTERS = 'SET_MEMBERS_FILTERS';

export const DELETE_ORG_WORKER_PENDING = 'DELETE_ORG_WORKER_PENDING';
