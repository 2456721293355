import {
  useContext,
  createContext,
  useState,
  ReactNode,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { ConfirmDialog, CustomTextField } from '../../components/common';

export const NOTE_STATE = {
  NOT_NEEDED: 0,
  OPTIONAL: 1,
  REQUIRED: 2,
} as const;

export type NoteState = (typeof NOTE_STATE)[keyof typeof NOTE_STATE];
interface Props {
  show?: boolean;
  headerIconType?: 'warning' | 'alert' | 'none' | 'accept';
  headerLabel?: string;
  content?: string | ReactNode;
  confirmStartIcon?: ReactNode;
  cancelStartIcon?: ReactNode;
  confirmButtonLabel?: string;
  confirmButtonType?: 'contained' | 'text' | 'outlined';
  confirmButtonColor?: 'default' | 'primary';
  confirmButtonClassName?: string;
  cancelButtonLabel?: string;
  cancelButtonType?: 'contained' | 'text' | 'outlined';
  cancelButtonColor?: 'default' | 'primary' | 'inherit' | 'secondary';
  cancelButtonClassName?: string;
  buttonPosition?: 'straight' | 'reverse';
  closeable?: boolean;
  /**
   * 0 - note not needed
   * 1 - note is optional
   * 2 - note is required
   */
  noteState?: NoteState;
  notePlaceholder?: string;
  footerContent?: string | ReactNode;
  noteHelperText?: string;
  styles?: string;
  onPopupLoad?: () => void;
}

type returnType = Promise<{ choice: boolean; note?: string }>;

type noteState = {
  text: string;
  error: boolean;
  state: NoteState;
  placeholder: string;
  helperText: string;
};

const noteInitialState: noteState = {
  text: '',
  error: false,
  state: NOTE_STATE.NOT_NEEDED,
  placeholder: 'Note: Optional',
  helperText: 'Note is required',
};

const ConfirmDialogContext = createContext<(data: Props) => returnType>(() =>
  Promise.resolve({ choice: false })
);

export function ConfirmDialogProvider({ children }: { children?: ReactNode }) {
  const fn = useRef<any>();
  const [state, setState] = useState<Props>({ show: false });
  const [note, setNote] = useState({ ...noteInitialState });

  const confirm = useCallback(
    (data: Props): returnType => {
      return new Promise(resolve => {
        setState({ ...data, show: true });
        setNote(prev => ({
          ...prev,
          state: data.noteState || NOTE_STATE.NOT_NEEDED,
          placeholder: data.notePlaceholder || prev.placeholder,
          helperText: data?.noteHelperText || '',
        }));

        fn.current = (choice: boolean, text: string) => {
          resolve({ choice, note: text });
          setState(prev => ({ ...prev, show: false }));
          setNote(noteInitialState);
        };
      });
    },
    [setState]
  );

  useEffect(() => {
    if (state.onPopupLoad && typeof state.onPopupLoad === 'function') {
      state.onPopupLoad();
    }
  }, [state.onPopupLoad]);

  const handleAction = (action: boolean) => () => {
    if (action && note.state === 2 && !note.text.trim()) {
      return setNote(prev => ({
        ...prev,
        error: true,
      }));
    }

    fn.current(action, note.text);
  };

  const handleNoteChange = useCallback(
    e => {
      const val = String(e.target.value).trim();
      setNote(prev => ({ ...prev, error: false, text: val }));
    },
    [setNote]
  );

  return (
    <ConfirmDialogContext.Provider value={confirm}>
      <ConfirmDialog
        {...state}
        onHide={handleAction(false)}
        onSubmit={handleAction(true)}
        closeOnSubmit={false}
      >
        {state.noteState ? (
          <CustomTextField
            className="mt-2"
            placeholder={note.placeholder}
            multiline
            fullWidth
            onChange={handleNoteChange}
            rows={3}
            helperText={note.error ? note.helperText : ''}
            error={note.error}
            inputProps={{ className: 'typography-base fes-m' }}
          />
        ) : null}
      </ConfirmDialog>
      {children}
    </ConfirmDialogContext.Provider>
  );
}

const useConfirm = () => useContext(ConfirmDialogContext);

export default useConfirm;
