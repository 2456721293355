export const GET_ALL_MI_SEQ = 'GET_ALL_MI_SEQ';
export const GET_ACTIVE_MI_SEQ = 'GET_ACTIVE_MI_SEQ';
export const GET_ARCHIVED_MI_SEQ = 'GET_ARCHIVED_MI_SEQ';
export const GET_MI_SEQ = 'GET_MI_SEQ';
export const GET_MI_SEQ_PROJECT = 'GET_MI_SEQ_PROJECT';

export const CREATE_MI_SEQ = 'CREATE_MI_SEQ';
export const UPDATE_MI_SEQ = 'UPDATE_MI_SEQ';
export const UPDATE_ALL_MI_SEQ = 'UPDATE_ALL_MI_SEQ';
export const UPDATE_ACTIVE_MI_SEQ = 'UPDATE_ACTIVE_MI_SEQ';
export const UPDATE_ARCHIVED_MI_SEQ = 'UPDATE_ARCHIVED_MI_SEQ';

export const ARCHIVE_MI_SEQ = 'ARCHIVE_MI_SEQ';
export const RESTORE_MI_SEQ = 'RESTORE_MI_SEQ';
export const MI_SEQ_API_ERROR = 'MI_SEQ_API_ERROR';

export const ARCHIVE_MI_PENDING = 'ARCHIVE_MI_PENDING';
export const MI_SEQ_PENDING = 'MI_SEQ_PENDING';
