/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateZoomImage from './RotatingImage';
import GetAppIcon from '@mui/icons-material/GetApp';
import SwipeableViews from 'react-swipeable-views';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';

import {
  Box,
  Button,
  Dialog,
  IconButton,
  MobileStepper,
  Typography,
} from '@mui/material';
import { CustomDialogContent, CustomDialogTitle } from '../CustomDialog';
import { KEY_CODES } from '../../../constant/key_codes';

const MultipleImagesModal = ({
  show,
  onHide,
  images,
  index,
  handleSetIndex,
  caption = '',
  onClick = () => {},
  clickableIndexes = [],
}) => {
  const initialArray = images?.map(() => 0);
  const [rotateArray, setRotateArray] = useState(initialArray);
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images?.length;

  useEffect(() => {
    setActiveStep(index);
  }, [index, images]);

  useEffect(() => {
    setRotateArray(images?.map(() => 0));
  }, [images]);

  const onRotate = () => {
    const newArr = rotateArray.slice();
    newArr[activeStep] = rotateArray[activeStep] + 1;
    setRotateArray(newArr);
  };

  const handleNext = () => {
    if (activeStep === maxSteps - 1) return;

    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep == 0) return;
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
    handleSetIndex(step);
  };

  const handleKeyDown = event => {
    switch (event?.code) {
      case KEY_CODES.ARROW_RIGHT:
        handleNext();
        break;
      case KEY_CODES.ARROW_LEFT:
        handleBack();
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleDownload = activeStep => {
    window.open(images[activeStep], '_blank');
  };

  return (
    <>
      <Dialog open={show} onClose={onHide} width="50vw">
        <CustomDialogTitle onClose={onHide}>
          <IconButton onClick={onRotate} size="large">
            <RotateRightIcon />
          </IconButton>
          <IconButton
            onClick={handleDownload.bind(null, activeStep)}
            size="large"
          >
            <GetAppIcon className="hand-pointer fs-xxl" />
          </IconButton>
        </CustomDialogTitle>
        <CustomDialogContent>
          <Box sx={{ maxWidth: 700, flexGrow: 1, width: 700 }}>
            <SwipeableViews
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {images?.map((image, index) => (
                <div key={image}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box className="text-center">
                      <RotateZoomImage
                        src={image}
                        rotate={rotateArray[index]}
                        index={index}
                        onClick={onClick}
                        zoomable={!clickableIndexes?.includes(index)}
                      />
                    </Box>
                  ) : null}
                </div>
              ))}
            </SwipeableViews>
            {caption && (
              <Typography className="text-center">{caption}</Typography>
            )}
          </Box>
        </CustomDialogContent>
        {images && images?.length > 1 && (
          <Box>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  <KeyboardArrowRightIcon />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <KeyboardArrowLeftIcon />
                  Previous
                </Button>
              }
            />
          </Box>
        )}
      </Dialog>
    </>
  );
};

MultipleImagesModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  images: PropTypes.array,
  index: PropTypes.number,
  caption: PropTypes.string,
};

export default MultipleImagesModal;
