import { useEffect, Suspense } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { getProject } from './redux/features/common/project';
import useResourceAccess from './utils/hooks/useResourceAccess.hook';
import HasAccess from './components/common/HasAccess';
import { CustomSpinner } from './components/common';
import { useAppDispatch } from './redux/Hooks';

interface ProtectedRouteProps {
  component: any;
  isAuthenticated: boolean;
  id?: string | string[];
}

function Protect({
  isAuthenticated,
  id,
  component: Component,
}: ProtectedRouteProps) {
  const orgHasAccess = useResourceAccess(id);

  const isAuth = isAuthenticated === true;

  const { projId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (projId && projId !== '') {
      dispatch(getProject(projId));
    }
  }, [projId]);

  if (isAuth && orgHasAccess) {
    return (
      <Suspense fallback={<CustomSpinner />}>
        <HasAccess resources={id}>
          <Component />
        </HasAccess>
      </Suspense>
    );
  }

  if (isAuth && !orgHasAccess) {
    return <Navigate to="/projects" />;
  }

  return <Navigate to="/login" />;
}

export default Protect;
