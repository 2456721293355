import React from 'react';
import Popover from 'material-ui-popup-state/HoverPopover';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import {
  usePopupState,
  bindHover,
  bindPopover,
} from 'material-ui-popup-state/hooks';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { setSelectedKey } from '../redux/features/common/utils/Utils.action';
import { setNavbarTitle } from '../redux/features/common/layout/Layout.action';
import { CustomListItem } from './LayoutStyles';
import { MenuButtonProps } from '../interfaces/Layout';
import { POWERPLAY_COLORS } from '../components/common/Theme';
import { useAppDispatch, useAppSelector } from '../redux/Hooks';
import './layoutStyles.scss';
import { track } from '../redux/features/common/Segment.action';
import { MATERIAL_SEGMENT_CONST } from '../constant/segment_constant';

function MenuButton({
  iconType,
  title,
  labels,
  path,
  keys,
  to,
  newTabs,
}: MenuButtonProps) {
  const dispatch = useAppDispatch();

  const [hover, setHover] = React.useState(false);

  const navbarTitle = useAppSelector(state => state.layoutreducer.navbar_title);
  const isSelected = navbarTitle === title;

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });

  const key = useAppSelector(state => state.utilsReducer.initialKey);

  const handleTabChange = (idx: number) => {
    if (path && keys) {
      if (keys[idx] === 'inv') {
        dispatch(track(MATERIAL_SEGMENT_CONST.COMMERCIAL_MATERIAL_LIST_CLICK));
      }
      dispatch(setSelectedKey(keys[idx]));
    }
  };

  const setNavTitle = (title: string) => () => {
    dispatch(setNavbarTitle(title));
  };

  const getStyles = () => {
    const styleOnSelection = isSelected
      ? {
          color: POWERPLAY_COLORS.primary,
          backgroundColor: POWERPLAY_COLORS.white,
        }
      : {};

    return hover && !isSelected
      ? {
          backgroundColor: `${POWERPLAY_COLORS.blue400}40`,
          color: POWERPLAY_COLORS.white,
        }
      : styleOnSelection;
  };

  const getVerticalIconHoverStyle = () => {
    return hover ? `${POWERPLAY_COLORS.white}d1` : POWERPLAY_COLORS.white;
  };

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => {
        setHover(false);
      }}
      className="side-navbar"
    >
      <div {...bindHover(popupState)}>
        <div>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-around"
            style={{
              marginTop: '0.4375rem',
            }}
            spacing={0}
          >
            <Grid item xs={2} />
            <Grid item xs={8} className="icon" style={getStyles()}>
              {iconType}
            </Grid>
            <Grid item xs={2}>
              <MoreVertIcon
                style={{
                  color:
                    labels.length > 0
                      ? getVerticalIconHoverStyle()
                      : 'transparent',
                  marginLeft: -4,
                  fontSize: 18,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          disableRestoreFocus
          slotProps={{
            paper: {
              style: {
                width: '15.625rem',
                marginLeft: '3rem',
                backgroundColor: 'rgba(0,0,0,0)',
                boxShadow: 'none',
              },
            },
          }}
        >
          <div className="layoutMenuList">
            <List>
              <CustomListItem button={false as true}>
                <ListItemText
                  primary={title}
                  primaryTypographyProps={{
                    style: {
                      fontWeight: 400,
                      letterSpacing: 1.2,
                      color: `${POWERPLAY_COLORS.white}d1`,
                      marginLeft: '1rem',
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                  }}
                  style={
                    labels?.length > 0
                      ? { textTransform: 'uppercase' }
                      : { textTransform: 'capitalize' }
                  }
                />
              </CustomListItem>
              {labels?.length > 0 && <Divider className="layoutMenuDivider" />}
              <div onClick={setNavTitle(title)}>
                {labels.map((item, idx) => (
                  <Link
                    key={idx}
                    to={newTabs ? `${to}/${item.key}` : to}
                    style={{ textDecoration: 'none' }}
                  >
                    <CustomListItem
                      button={false as true}
                      onClick={() => handleTabChange(idx)}
                    >
                      <div
                        className="layoutMenuLabels"
                        style={
                          key === item.key
                            ? { backgroundColor: POWERPLAY_COLORS.white }
                            : {}
                        }
                      >
                        <span
                          style={
                            key === item.key
                              ? {
                                  color: POWERPLAY_COLORS.primary,
                                  fontWeight: 600,
                                }
                              : {}
                          }
                        >
                          <span className="layoutMenuIco">{item.ico}</span>
                          <span className="layoutMenuLabel">{item.label}</span>
                        </span>
                      </div>
                    </CustomListItem>
                  </Link>
                ))}
              </div>
            </List>
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default MenuButton;
