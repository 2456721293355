import {
  REPORT_API_SUCCESS,
  REPORT_API_PENDING,
  REPORT_API_ERROR,
  GET_REPORT_IMAGE_SELECTION_LIST,
  STORE_HTML_CONTENT,
  CLEAR_REPORT_STATE,
} from './Report.type';

const initialState = {
  reportPending: false,
  error: null,
  imageSelectionList: [],
  htmlContent: '',
};

export default function baseReducer(
  state = initialState,
  { type, error, payload }
) {
  switch (type) {
    case REPORT_API_SUCCESS:
      return {
        ...state,
        reportPending: false,
      };
    case REPORT_API_PENDING:
      return {
        ...state,
        reportPending: true,
      };
    case REPORT_API_ERROR:
      return {
        ...state,
        reportPending: false,
        error: error?.response?.data?.message || '',
      };
    case GET_REPORT_IMAGE_SELECTION_LIST:
      return {
        ...state,
        imageSelectionList: payload,
      };
    case STORE_HTML_CONTENT:
      return {
        ...state,
        htmlContent: payload.htmlContent,
      };
    case CLEAR_REPORT_STATE:
      return initialState;
    default:
      return state;
  }
}
