// @ts-nocheck

import imageCompression from 'browser-image-compression';
import * as Sentry from '@sentry/react';

import {
  diffInDays,
  addDays,
  resetHours,
  formatDate,
  getTodayGMT,
} from './Date.util';
import {
  MIN_WIDTH_BREAKPOINT_TASKBAR,
  MIN_RATIO_BREAKPOINT_TASKBAR,
  OFFSET_TASKBAR,
  BAR_RADIUS,
  MIN_SEPERATION_TIMELINE,
  MIN_SEPERATION_BUDGET,
  BUDGET_STATUS,
  MIN_WIDTH_BREAKPOINT_BUDGET,
  MIN_RATIO_BREAKPOINT_BUDGET,
  ONE_DAY,
  MIN_FILE_SIZE_FOR_COMPRESSION,
  DAY_IN_MS,
} from '../constants';
import { minus, toNumber } from './Math.util';
import { formatAmount } from './Amount.util';
import dayjs from 'dayjs';

export const findById = (array, id, rev = false) => {
  const start = rev ? array.length - 1 : 0;
  const end = rev ? 0 : array.length;
  const step = rev ? -1 : 1;

  for (let index = start; rev ? index >= end : index < end; index += step) {
    const element = array[index];
    if (element._id === id || element.packageId === id) {
      return { found: true, index, foundPackage: element };
    }
  }
  return { found: false, index: -1, foundPackage: {} };
};

const findGSTIN = (gstinDetails, gstin) => {
  for (const gstinElement of gstinDetails) {
    if (gstinElement._id === gstin) {
      return true;
    }
  }
  return false;
};

export const findVendorByIdAndGSTIN = (array, id, gstin = '') => {
  for (const element of array) {
    if (element._id === id) {
      if (gstin === '' || findGSTIN(element.gstin_details, gstin)) {
        return {
          found: true,
          index: array.indexOf(element),
          foundVendor: element,
        };
      }
    }
  }
  return { found: false, index: -1, foundVendor: {} };
};

export const openInNewTab = url => {
  const a = document.createElement('a');
  a.href = url;
  document.body.append(a);
  a.rel = 'noopener noreferrer';
  a.target = '_blank';
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

export const downloadFile = async (url, name) => {
  if (url) {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      const fileURL = window.URL.createObjectURL(blob);
      const alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = name || true;
      alink.click();
      window.URL.revokeObjectURL(fileURL);
    } catch (error) {
      openInNewTab(url);
    }
  } else {
    window?.alert('Unable to download');
  }
};

export const getLabelWidth = (labelText = '', fontSize = 12, offest = 0) => {
  const test = document.createElement('span');
  document.body.appendChild(test);
  test.innerHTML = labelText;
  test.style.fontSize = `${fontSize}px`;
  test.style.height = 'auto';
  test.style.width = 'auto';
  test.style.position = 'absolute';
  test.style.whiteSpace = 'no-wrap';
  test.style.fontSize = fontSize;
  const width = test.clientWidth;
  document.body.removeChild(test);
  return width + offest;
};

export const getLabelHeight = (labelText = '', fontSize = 12, offest = 0) => {
  const test = document.createElement('span');
  document.body.appendChild(test);
  test.innerHTML = labelText;
  test.style.fontSize = `${fontSize}px`;
  test.style.height = 'auto';
  test.style.width = 'auto';
  test.style.position = 'absolute';
  test.style.whiteSpace = 'no-wrap';
  test.style.fontSize = fontSize;
  const height = test.clientHeight;
  document.body.removeChild(test);
  return height + offest;
};

export const createDataObject = array => {
  return array.reduce(
    (obj, item) => Object.assign(obj, { [item.dataKey]: item.value }),
    {}
  );
};

export const getBarRadius = (array, index) => {
  if (index === -1) {
    return BAR_RADIUS.fullRadius;
  } else if (index === 0) {
    return BAR_RADIUS.firstRadius;
  } else if (index === array.length - 1) {
    return BAR_RADIUS.lastRadius;
  } else {
    return BAR_RADIUS.noRadius;
  }
};

export const getBarRadiusArray = array => {
  const radiusArray = new Array(array.length).fill(BAR_RADIUS.noRadius);
  const valueArray = [...array].map(element => element.value);

  for (let i = 0; i < valueArray.length; i++) {
    const value = valueArray[i];
    if (value > 0) {
      radiusArray[i] = BAR_RADIUS.firstRadius;
      break;
    }
  }

  for (let i = valueArray.length - 1; i >= 0; i--) {
    const value = valueArray[i];
    if (value > 0) {
      if (radiusArray[i] === BAR_RADIUS.firstRadius) {
        radiusArray[i] = BAR_RADIUS.fullRadius;
      } else {
        radiusArray[i] = BAR_RADIUS.lastRadius;
      }
      break;
    }
  }
  return radiusArray;
};

export const taskbarDataGenator = data => {
  let {
    start_date = 1589122057000,
    end_date = 1589813257000,
    actual_start_date = 1589554057000,
    actual_end_date = 1590850057000,
    percentage_work_done,
    status,
  } = data;

  const today = getTodayGMT();
  if (actual_start_date === 0) {
    actual_start_date = today;
  }
  const initialPlannedDuration = diffInDays(start_date, end_date, true);

  const initialActualDuration = diffInDays(
    actual_start_date,
    actual_end_date,
    true
  );

  const minDate = Math.min(start_date, actual_start_date, today);
  const maxDate = Math.max(end_date, actual_end_date, today);

  const minWidth = Math.min(initialPlannedDuration, initialActualDuration);
  const maxWidth = diffInDays(minDate, maxDate, true);

  const initalaReferenceLineTodayOffset = diffInDays(
    today,
    actual_start_date,
    false
  );

  const initialTaskDone =
    percentage_work_done <= 100
      ? (percentage_work_done * initialActualDuration) / 100
      : initialActualDuration;
  const initalTaskLeft = initialActualDuration - initialTaskDone;

  let plannedOffetStart = 0,
    plannedDuration = 0,
    plannedOffetEnd = 0,
    actualOffsetStart = 0,
    actualDuration = 0,
    actualOffsetEnd = 0,
    referenceLineData = 0,
    referenceLineOffset = 0,
    referenceLineTodayOffset = 0,
    referenceLineMax = 0,
    taskDone = 0,
    taskLeft = 0;

  const diffActualStartPlannedStart = diffInDays(
    actual_start_date,
    start_date,
    false
  );
  const diffActualEndPlannedEnd = diffInDays(actual_end_date, end_date, false);
  const diffTodayPlannedStart = diffInDays(today, start_date, true);

  if (actual_start_date > start_date && start_date <= today) {
    actualOffsetStart = diffActualStartPlannedStart;
  } else if (actual_start_date >= start_date && start_date > today) {
    plannedOffetStart = OFFSET_TASKBAR * maxWidth;
    actualOffsetStart = OFFSET_TASKBAR * maxWidth;
  } else {
    plannedOffetStart = diffActualStartPlannedStart;
  }

  if (actual_end_date > end_date && actual_end_date >= today) {
    plannedOffetEnd = diffActualEndPlannedEnd;
  } else if (actual_end_date >= end_date && actual_end_date < today) {
    plannedOffetEnd = OFFSET_TASKBAR * maxWidth;
    actualOffsetEnd = OFFSET_TASKBAR * maxWidth;
  } else {
    actualOffsetEnd = diffActualEndPlannedEnd;
  }

  plannedDuration = initialPlannedDuration;

  actualDuration = initialActualDuration;
  taskDone = initialTaskDone;
  taskLeft = initalTaskLeft;

  referenceLineTodayOffset = initalaReferenceLineTodayOffset;
  referenceLineOffset = actualOffsetStart + referenceLineTodayOffset;

  if (initialActualDuration * MIN_WIDTH_BREAKPOINT_TASKBAR <= maxWidth) {
    actualDuration = MIN_RATIO_BREAKPOINT_TASKBAR * maxWidth;
    taskDone = (initialTaskDone * actualDuration) / initialActualDuration;
    taskLeft = (initalTaskLeft * actualDuration) / initialActualDuration;
  }

  if (initialPlannedDuration * MIN_WIDTH_BREAKPOINT_TASKBAR < maxWidth) {
    plannedDuration = MIN_RATIO_BREAKPOINT_TASKBAR * maxWidth;
  }

  if (minWidth * MIN_WIDTH_BREAKPOINT_TASKBAR <= maxWidth) {
    if (actual_end_date > end_date && actual_start_date > start_date) {
      plannedOffetEnd = (1 - MIN_RATIO_BREAKPOINT_TASKBAR) * maxWidth;

      actualOffsetStart = (1 - MIN_RATIO_BREAKPOINT_TASKBAR) * maxWidth;

      referenceLineTodayOffset =
        (initalaReferenceLineTodayOffset * actualDuration) /
        initialActualDuration;
      referenceLineOffset = actualOffsetStart + referenceLineTodayOffset;
    } else if (start_date > actual_start_date && end_date > actual_end_date) {
      plannedOffetStart = (1 - MIN_RATIO_BREAKPOINT_TASKBAR) * maxWidth;

      actualOffsetEnd = (1 - MIN_RATIO_BREAKPOINT_TASKBAR) * maxWidth;

      referenceLineOffset =
        plannedOffetStart +
        (diffTodayPlannedStart * plannedDuration) / initialPlannedDuration;
    } else if (start_date > actual_start_date && end_date < actual_end_date) {
      plannedOffetStart =
        (diffActualStartPlannedStart * initialPlannedDuration) /
        plannedDuration;

      plannedOffetEnd =
        MIN_RATIO_BREAKPOINT_TASKBAR * maxWidth - plannedOffetStart;

      referenceLineOffset = referenceLineTodayOffset;
    } else if (start_date < actual_start_date && end_date > actual_end_date) {
      actualOffsetStart =
        (diffActualStartPlannedStart * initialActualDuration) / actualDuration;

      referenceLineOffset =
        (initalaReferenceLineTodayOffset * actualDuration) /
          initialActualDuration +
        actualOffsetStart;
    }
  }

  const actualTimelineWidth =
    actualOffsetStart + actualDuration + actualOffsetEnd;

  referenceLineMax = Math.max(maxWidth, actualTimelineWidth);
  referenceLineData = referenceLineOffset / referenceLineMax;

  let delayToDisplay = diffInDays(actual_end_date, end_date, false);
  if (actual_end_date < end_date) {
    delayToDisplay = `-${delayToDisplay}`;
  } else {
    delayToDisplay = `+${delayToDisplay}`;
  }

  const percentageWorkDone =
    percentage_work_done <= 100 ? percentage_work_done : 100;

  if (start_date === actual_start_date && actual_end_date === end_date) {
    if (today <= start_date) {
      referenceLineData = 0;
    }
    if (today >= end_date) {
      referenceLineData = 1;
    }
  }

  if (status.toLowerCase() === 'upcoming') {
    plannedOffetStart = diffInDays(today, start_date, false);
    actualOffsetStart = plannedOffetStart;
    actualOffsetEnd = 0;
    referenceLineData = 0;
    taskDone = 0;
  } else if (status.toLowerCase() === 'completed') {
    referenceLineData = 1;
  }

  const plannedData = {
    plannedStartDate: start_date,
    plannedEndDate: end_date,
    plannedDuration,
    plannedOffetStart,
    plannedOffetEnd,
  };

  const actualData = {
    actualStartDate: actual_start_date,
    actualEndDate: actual_end_date,
    taskDone,
    taskLeft,
    actualOffsetStart,
    actualDuration,
    actualOffsetEnd,
  };

  return {
    delayToDisplay,
    percentageWorkDone,
    status,
    plannedData,
    actualData,
    referenceLineData,
  };
};

export const handleTaskbarDates = elem => {
  const { status } = elem;
  const today = getTodayGMT();
  elem.start_date = resetHours(elem.start_date);
  elem.end_date = resetHours(elem.end_date);
  elem.actual_start_date = resetHours(elem.actual_start_date);
  elem.actual_end_date = resetHours(elem.actual_end_date);

  const diffActualStartPlannedStart = diffInDays(
    elem.actual_start_date,
    elem.start_date,
    true
  );
  if (diffActualStartPlannedStart === 0) {
    elem.actual_start_date = elem.start_date;
  }

  switch (status.toLowerCase()) {
    case 'completed':
      break;
    case 'upcoming':
      elem.actual_start_date = elem.start_date;
      elem.actual_end_date = elem.end_date;
      break;
    case 'overdue': {
      const initialPlannedDuration = diffInDays(
        elem.start_date,
        elem.end_date,
        false
      );
      elem.actual_start_date = today;
      elem.actual_end_date = addDays(
        elem.actual_start_date,
        initialPlannedDuration
      );
      break;
    }
    case 'delayed':
    case 'ongoing': {
      if (elem.actual_start_date <= 0) {
        elem.actual_start_date = today;
      }
      const initialPlannedDuration = diffInDays(
        elem.start_date,
        elem.end_date,
        false
      );
      elem.actual_end_date = addDays(
        today,
        initialPlannedDuration * ((100 - elem.percentage_work_done) / 100)
      );
      break;
    }
    default:
      break;
  }
  return elem;
};

export const timelineDataGenerator = async projectPlanSummary => {
  const expectedTotal =
    projectPlanSummary.Ongoing +
    projectPlanSummary.Delayed +
    projectPlanSummary.Overdue;

  const maxWidth = projectPlanSummary.Total;

  const today = getTodayGMT();
  let crossedExpectedDate = false,
    data = {},
    remainingValue = projectPlanSummary.Total - expectedTotal;

  if (today > projectPlanSummary.plannedEndDate) {
    crossedExpectedDate = true;
    remainingValue =
      projectPlanSummary.Total -
      expectedTotal +
      MIN_SEPERATION_TIMELINE * maxWidth;
  }

  data = {
    dateLabelData: {
      leftLabel: formatDate(
        projectPlanSummary.plannedStartDate,
        'MMM DD, YYYY'
      ),
      rightLabel: formatDate(projectPlanSummary.plannedEndDate, 'MMM DD, YYYY'),
    },
    crossedExpectedDate,
    dateData: [
      {
        dataKey: 'till_today',
        value: expectedTotal,
        barFillColor: '#002253',
      },
      {
        dataKey: 'remaining',
        value: remainingValue,
      },
    ],
    taskData: [
      {
        dataKey: 'Completed',
        value: projectPlanSummary.Completed,
        barFillColor: '#419CEB',
      },
      {
        dataKey: 'Ongoing',
        value: projectPlanSummary.Ongoing,
        barFillColor: '#68D083',
      },
      {
        dataKey: 'Delayed',
        value: projectPlanSummary.Delayed,
        barFillColor: '#EBB840',
      },
      {
        dataKey: 'Overdue',
        value: projectPlanSummary.Overdue,
        barFillColor: '#E6642E',
      },
      {
        dataKey: 'remainingTask',
        value: projectPlanSummary.Total - expectedTotal,
        noLabel: false,
        barFillColor: '#DEE2E6',
        labelFillColor: '#90A0B7',
      },
    ],
    refernceLineData: { label: 'Today', fillColor: '#419CEB' },
    referenceLineX: expectedTotal / projectPlanSummary.Total,
  };

  return data;
};

export const lastIndexOf = (array, dataKey) => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (array[i].dataKey === dataKey) return i;
  }
  return -1;
};

export const getUnique = array => {
  const uniqueArray = [];

  for (const item of array) {
    if (uniqueArray.indexOf(item) === -1) {
      uniqueArray.push(item);
    }
  }

  return uniqueArray;
};

export const budgetDataGenerator = async (
  initialPlannedCost,
  initialActualCost
) => {
  const initalDiffBudget = Math.abs(
    minus(initialPlannedCost, initialActualCost)
  );
  const minWidth = Math.min(initialPlannedCost, initialActualCost);
  const maxWidth = Math.max(initialPlannedCost, initialActualCost);

  let plannedCost = 0,
    plannedOffsetEnd = 0,
    actualCost = 0,
    actualOffsetEnd = 0,
    diffBudget = initalDiffBudget,
    referenceLineData = 0,
    referenceLineOffset = 0,
    referenceLineMax = 0,
    diffToDisplay = '',
    fillColor;
  const minOffsetWidth = MIN_SEPERATION_BUDGET * maxWidth;

  if (diffBudget < minOffsetWidth) {
    diffBudget = minOffsetWidth;
  }
  if (initialPlannedCost < initialActualCost) {
    fillColor = BUDGET_STATUS.overshoot.fillColor;
    diffToDisplay = `( +${formatAmount(initalDiffBudget)} )`;
  } else {
    fillColor = BUDGET_STATUS.undershoot.fillColor;
    diffToDisplay = `( -${formatAmount(initalDiffBudget)} )`;
  }

  plannedCost = initialPlannedCost;
  actualCost = initialActualCost;

  if (initialActualCost * MIN_WIDTH_BREAKPOINT_BUDGET <= maxWidth) {
    actualCost = MIN_RATIO_BREAKPOINT_BUDGET * maxWidth;
  }
  if (initialPlannedCost * MIN_WIDTH_BREAKPOINT_BUDGET <= maxWidth) {
    plannedCost = MIN_RATIO_BREAKPOINT_BUDGET * maxWidth;
  }

  if (minWidth === initialPlannedCost) {
    actualOffsetEnd = minOffsetWidth;
    plannedOffsetEnd = maxWidth + minOffsetWidth - plannedCost;
    referenceLineOffset = actualCost;
  } else {
    actualOffsetEnd = diffBudget;
    referenceLineOffset = actualCost;
  }
  referenceLineMax = actualCost + actualOffsetEnd;
  referenceLineData = referenceLineOffset / referenceLineMax;

  const plannedData = {
    plannedCost,
    plannedOffsetEnd,
  };

  const actualData = {
    actualCost,
    actualOffsetEnd,
  };

  const plannedLabelData = [formatAmount(initialPlannedCost), ''];
  const actualLabelData = [
    [formatAmount(initialActualCost), diffToDisplay],
    '',
  ];

  return {
    plannedData,
    actualData,
    referenceLineData,
    plannedLabelData,
    actualLabelData,
    fillColor,
  };
};

export const blobToFile = async blobFile => {
  //A Blob() is almost a File() - it's just missing  properties below which we will add
  blobFile.lastModifiedDate = new Date();
  blobFile.webkitRelativePath = '';
  const file = new File([blobFile], blobFile.name, {
    type: blobFile.type,
    lastModified: Date.now(),
  });
  file.path = blobFile.name;
  return file;
};

const imageUploadOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 600,
  useWebWorker: true,
};

export const compressImage = async imageFile => {
  try {
    if (imageFile.size <= MIN_FILE_SIZE_FOR_COMPRESSION) return imageFile;

    const compressedBlob = await imageCompression(
      imageFile,
      imageUploadOptions
    );
    const compressedFile = await blobToFile(compressedBlob);
    return compressedFile;
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const handleScroll = query => {
  const slider = document.querySelector(query);
  let isDown = false;
  let startX;
  let scrollLeft;

  if (slider) {
    slider.addEventListener('mousedown', e => {
      isDown = true;
      slider.classList.add('active');
      slider.classList.add('hand-grab');
      slider.classList.add('unselectable');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
      slider.classList.remove('hand-grab');
      slider.classList.remove('unselectable');
    });

    slider.addEventListener('dblclick', () => {
      slider.classList.remove('active');
      slider.classList.remove('hand-grab');
      slider.classList.remove('unselectable');
    });

    slider.addEventListener('mousemove', e => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = x - startX; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }
};
export const setPrecision = (num = 0) => {
  const validated = String(num).match(/^(\d*\.?\d{0,2})$/);

  if (validated) {
    return num;
  } else return;
};

export const getScreenDimensions = () => {
  return {
    screenWidth: document.body.clientWidth,
    screenHeight: document.body.clientHeight,
  };
};

export const getPPTimelineData = (initialTimelineData, viewType = 'Day') => {
  const { screenWidth } = getScreenDimensions();
  let zoom;
  const timelineData = Object.assign({}, initialTimelineData);

  if (viewType === 'Week') {
    zoom = 12 * 7 * ONE_DAY;
    timelineData.timeSteps = { week: 1 };
    timelineData.width = '8.57%';
    timelineData.unit = 'week';
    timelineData.dateFormat = 'W';
  } else if (viewType === 'Month') {
    zoom = 12 * ONE_DAY * 30;
    timelineData.timeSteps = { month: 1 };
    timelineData.mainDateFormat = 'YYYY';
    timelineData.mainUnit = 'year';
    timelineData.unit = 'month';
    timelineData.width = '8.57%';
    timelineData.dateFormat = 'MMM';
  } else {
    zoom = 23 * ONE_DAY;
    timelineData.timeSteps = { day: 1 };
    timelineData.unit = 'day';
    timelineData.width = '4.285%';
    timelineData.mainUnit = 'month';
    timelineData.mainDateFormat = 'MMM YYYY';
    timelineData.dateFormat = 'DD';
    if (screenWidth < 1024) {
      zoom = 11 * ONE_DAY;
      timelineData.width = '8.57%';
      timelineData.timeStart = dayjs(timelineData.timeStart)
        .startOf('day')
        .add(6, 'days')
        .toDate();
      timelineData.timeEnd = dayjs(timelineData.timeEnd)
        .startOf('day')
        .subtract(6, 'days')
        .toDate();
    }
  }
  timelineData.maxZoom = zoom;
  timelineData.minZoom = zoom;
  return timelineData;
};

export const getPODisplayId = (poId = '') => {
  const index = poId.indexOf('-');
  let poStr = poId;

  if (index !== -1) {
    poStr = poId.substring(index + 1);
  }

  return poStr;
};

//trim the string to the given length
export const trimString = (name, length = 15) => {
  if (name?.length > length) {
    return `${name.substring(0, length)} ...`;
  }
  return name;
};

export const getParsedJson = string => {
  try {
    return JSON.parse(string || '{}');
  } catch (e) {
    return {};
  }
};

export const replaceAllPolyfill = (str, search, replacement) => {
  if (search instanceof RegExp) {
    return str.replace(search, replacement);
  }

  search = search.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

  const regex = new RegExp(search, 'g');
  return str.replace(regex, replacement);
};

export const updateTaskGridViewCount = (
  collapsedTaskMap,
  gridApi,
  currRowCount,
  knownIndex = undefined
) => {
  let totalCollapsedCount = 0;
  for (const val of collapsedTaskMap.values()) {
    if (!val?.ancestors?.some(id => collapsedTaskMap.has(id))) {
      totalCollapsedCount += val.allChildrenCount;
    }
  }

  gridApi.setRowCount(currRowCount - totalCollapsedCount, knownIndex);
};

export function addMinutesTo12HourTime(time12Hour, period, minutesToAdd) {
  const [hours12, minutes] = time12Hour.split(':').map(Number);
  let hours24 = hours12;
  if (period === 'PM' && hours12 !== 12) {
    hours24 += 12;
  }
  const totalMinutes = hours24 * 60 + minutes + minutesToAdd;
  const newHours24 = Math.floor(totalMinutes / 60) % 24;
  const newMinutes = totalMinutes % 60;
  return [newHours24, newMinutes];
}

export function addMinutesTo24HourTime(time24Hour, minutesToAdd) {
  const [hours, minutes] = time24Hour;
  const totalMinutes = hours * 60 + minutes + minutesToAdd;
  const newHours = Math.floor(totalMinutes / 60) % 24;
  const newMinutes = totalMinutes % 60;
  return [newHours, newMinutes];
}

export function timeDifferenceInMinutes(time1, time2) {
  const [hours1, minutes1] = time1;
  const [hours2, minutes2] = time2;
  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;
  const differenceInMinutes = totalMinutes1 - totalMinutes2;
  return differenceInMinutes;
}

export function convertTo12HourFormat(date) {
  let [hours, minutes] = date;
  hours = Math.min(23, Math.max(0, hours));
  minutes = Math.min(59, Math.max(0, minutes));
  let formattedTime;
  const period = hours < 12 ? 'AM' : 'PM';
  if (hours === 0 || hours === 12) {
    formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
    return { formattedTime, period };
  }
  hours = hours % 12;
  formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;

  return { formattedTime, period };
}

export function convertTo24HourFormat(time12Hour, period) {
  let [hours, minutes] = time12Hour.split(':').map(Number);
  if (period === 'PM' && hours < 12) {
    hours += 12;
  } else if (period === 'AM' && hours === 12) {
    hours = 0;
  }
  return [hours, minutes];
}

export function isTimeGreaterThan(time1, time2) {
  const [hours1, minutes1] = time1;
  const [hours2, minutes2] = time2;

  return hours1 > hours2 || (hours1 === hours2 && minutes1 > minutes2);
}

function detectDeviceType() {
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  if (screenWidth > 1500) {
    return 'Laptop';
  } else if (screenWidth > 600) {
    return 'Tablet';
  } else {
    return 'Mobile';
  }
}

export function generateDateStrings(startDate, endDate) {
  const dateStrings = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const day = String(currentDate.getDate());
    const month = String(currentDate.getMonth() + 1);
    dateStrings.push(`${day}/${month}`);
    currentDate = new Date(currentDate.getTime() + DAY_IN_MS);
  }

  return dateStrings;
}

export const isTablet = () => {
  return detectDeviceType() === 'Tablet';
};

export const trimText = (text = '', length = 50) => {
  if (text?.length <= length) return text;

  return `${text?.slice(0, length)}.....`;
};

export const generateCanvasFingerprint = () => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Set canvas dimensions
  canvas.width = 200;
  canvas.height = 50;

  // Draw some random shapes
  ctx.fillStyle = '#f60';
  ctx.fillRect(25, 10, 50, 30);
  ctx.fillStyle = '#069';
  ctx.fillRect(75, 10, 50, 30);
  ctx.fillStyle = '#09f';
  ctx.fillRect(125, 10, 50, 30);

  // Draw some text
  ctx.fillStyle = '#000';
  ctx.font = '14px Arial';
  ctx.fillText('Canvas Fingerprint', 10, 25);

  // Get the image data from the canvas
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  // Use a hashing algorithm to generate a fingerprint
  let hash = 0;

  for (const value of imageData.data) {
    hash = (hash << 5) - hash + value;
    hash |= 0; // Convert to 32bit integer
  }

  // Clean up
  canvas.remove();

  return Math.abs(Number(hash.toString()));
};

// This function is used to generate a random number in a safe way, compliant for security-sensitivity
export const generateSafeInt32RandomNumber = () => {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array?.[0];
};

export const safeJSONStringify = obj => {
  try {
    return obj ? JSON.stringify(obj) : '{}';
  } catch (e) {
    return '';
  }
};

export const areObjectsEqual = (obj1, obj2) => {
  // Check for reference equality
  if (obj1 === obj2) {
    return true;
  }

  // Early exit for primitives
  if (Object(obj1) !== obj1 || Object(obj2) !== obj2) {
    return obj1 === obj2;
  }

  // Check if both are objects
  if (!(obj1 instanceof Object) || !(obj2 instanceof Object)) {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate through the keys and compare their values recursively
  for (const key of keys1) {
    if (!keys2.includes(key) || !areObjectsEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  // If all checks pass, the objects are deep equal
  return true;
};

/**
 * Comparator function for case-insensitive string comparison.
 * @param {string} valueA - First string to compare.
 * @param {string} valueB - Second string to compare.
 * @returns {number} - Comparison result: -1 if valueA < valueB, 1 if valueA > valueB, 0 if they are equal.
 */
export function caseInsensitiveComparator(valueA, valueB) {
  valueA = valueA?.toLowerCase();
  valueB = valueB?.toLowerCase();
  if (valueA === valueB) return 0;
  return valueA > valueB ? 1 : -1;
}

export const timeStringToMinutes = timeString => {
  const hoursRegex = /(\d+)h/;
  const minutesRegex = /(\d+)m/;
  const hoursMatch = timeString.match(hoursRegex);
  const minutesMatch = timeString.match(minutesRegex);

  const hours = hoursMatch ? toNumber(hoursMatch[1], 10) : 0;
  const minutes = minutesMatch ? toNumber(minutesMatch[1], 10) : 0;

  return hours * 60 + minutes;
};
