export const SEND_OTP_PAGE_MOUNT = 'login_page_load';
export const SEND_OTP_BTN_CLICK = 'login_continue_clicked';
export const OTP_PAGE_MOUNT = 'otp_page_load';
export const SEND_OTP_SUCCESS = 'otp_mobile_sent_success';
export const SEND_OTP_FAILURE = 'otp_mobile_sent_failure';
export const VERIF_OTP_BTN_CLICK = 'verify_otp_clicked';
export const CHANGE_NUMBER_BTN_CLICK = 'change_number_clicked';
export const RESEND_OTP_BTN_CLICK = 'resend_otp_btn_clicked';
export const LOGOUT_BUTTON_CLICK = 'logout_clicked';
export const LOGOUT_SUCCESS = 'logout_success';
