export const DRIVE_FILES = 'DRIVE_FILES';
export const DRIVE_FOLDERS = 'DRIVE_FOLDERS';
export const DRIVE_PARENT_ID = 'DRIVE_PARENT_ID';
export const DRIVE_OPEN_FOLDER_DETAIL = 'DRIVE_OPEN_FOLDER_DETAIL';
export const DRIVE_FOLDER_API_PENDING = 'DRIVE_FOLDER_API_PENDING';
export const DRIVE_FOLDER_API_SUCCESS = 'DRIVE_FOLDER_API_SUCCESS';
export const DRIVE_FOLDER_API_ERROR = 'DRIVE_FOLDER_API_ERROR';

//create folder
export const CREATE_FOLDER_API_PENDING = 'CREATE_FOLDER_API_PENDING';
export const CREATE_FOLDER_API_SUCCESS = 'CREATE_FOLDER_API_SUCCESS';
export const CREATE_FOLDER_API_ERROR = 'CREATE_FOLDER_API_ERROR';

//create file
export const CREATE_FILE_API_PENDING = 'CREATE_FILE_API_PENDING';
export const CREATE_FILE_API_SUCCESS = 'CREATE_FILE_API_SUCCESS';
export const CREATE_FILE_API_ERROR = 'CREATE_FILE_API_ERROR';
export const DRIVE_UPLOAD_FILE_RESET = 'DRIVE_UPLOAD_FILE_RESET';

//share file
export const DRIVE_FILE_SHARABLE_LINK = 'DRIVE_FILE_SHARABLE_LINK';
export const DRIVE_FILE_SHARE_ERROR = 'DRIVE_FILE_SHARE_ERROR';
export const DRIVE_FILE_SHARE_PENDING = 'DRIVE_FILE_SHARE_PENDING';
export const DRIVE_FILE_SHARE_RESET = 'DRIVE_FILE_SHARE_RESET';

//folder info
export const DRIVE_FOLDER_INFO_PENDING = 'DRIVE_FOLDER_INFO_PENDING';
export const DRIVE_FOLDER_INFO_SUCCESS = 'DRIVE_FOLDER_INFO_SUCCESS';
export const DRIVE_FOLDER_INFO_ERROR = 'DRIVE_FOLDER_INFO_ERROR';
export const DRIVE_FOLDER_INFO_DETAIL = 'DRIVE_FOLDER_INFO_DETAIL';

//file info
export const DRIVE_FILE_INFO_PENDING = 'DRIVE_FILE_INFO_PENDING';
export const DRIVE_FILE_INFO_SUCCESS = 'DRIVE_FILE_INFO_SUCCESS';
export const DRIVE_FILE_INFO_ERROR = 'DRIVE_FILE_INFO_ERROR';
export const SELECTED_FILE_INFO = 'SELECTED_FILE_INFO';
export const SELECTED_FILE_VERSION = 'SELECTED_FILE_VERSION';
export const DRIVE_SHARE_INFO_RESET = 'DRIVE_SHARE_INFO_RESET';
export const SET_FILE_COMMENTS = 'SET_FILE_COMMENTS';
