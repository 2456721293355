import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  matchPath,
  useLocation,
} from 'react-router-dom';
import store from './redux/Store';
import * as Sentry from '@sentry/react';
import {
  ThemeProvider,
  StyledEngineProvider,
  useMediaQuery,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';

import App from './App';
import ErrorBoundary from './views/error/ErrorBoundary';
import SmallScreenError from './views/error/SmallScreenError';
import CustomSpinner from './components/common/CustomSpinner';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  USE_SEENTRY,
  POWERPLAY_WEB_VERSION,
  USE_SMARTLOOK,
  SMART_LOOK_TOKEN,
} from './constants';
import { theme, variables } from './components/common/Theme';
import { SEGMENT_TOKEN } from './constant/segment_constant';
import UserProps from './interfaces/User';
import { SHOW_UPDATE_ALERT } from './redux/features/miscellaneous/service-worker/ServiceWorker.type';
import { ConfirmDialogProvider } from './utils/hooks/useConfirm.hook';
import { apolloClient } from './apiClient';
import { UNPROTECTED_ROUTES } from './routes';
import { useAppSelector } from './redux/Hooks';

import './assets/main.scss';

if (USE_SEENTRY) {
  Sentry.init({
    dsn: 'https://701ece8d07724e4fa39dbf6d54db2c8c@o313445.ingest.sentry.io/5252527',
    release: `powerplay@ver_${POWERPLAY_WEB_VERSION}`,
  });
}

const Index = () => {
  const location = useLocation();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const smallScreens = Boolean(
    UNPROTECTED_ROUTES.filter(v => v.allowSmallScreen)
      .map(v => v.path)
      .map(it => matchPath({ path: it }, location.pathname))
      .filter(Boolean).length
  );

  const user: UserProps = useAppSelector(state => state.userreducer);

  async function updateFcUser() {
    await (window as any)?.fcWidget?.user?.setProperties({
      firstName: user.displayName,
      email: user.email,
      phone: user.mobile_number,
      phoneCountry: user.country_code,
      externalId: user._id,
    });
  }

  if (user?._id && (window as any)?.fcWidget) {
    updateFcUser();
  }

  if (matches && !smallScreens) {
    return <SmallScreenError />;
  } else {
    return (
      <div className="h-100">
        <Helmet>
          <meta name="author" content="Powerplay" />
          <meta
            name="description"
            content="Powerplay - Manage construction & interior projects. India's 1st construction and architecture project management app"
          />
          <meta
            name="keywords"
            content="power, play, powerplay, construction, projects, civil, works, organisation, project management,labour"
          />
          <meta name="robots" content="follow, index" />

          <meta name="og:title" content="Powerplay" />
          <meta name="og:type" content="website" />
          <meta
            name="og:description"
            content="Powerplay - Manage construction & interior projects. India's 1st construction and architecture project management app"
          />
          <meta property="og:url" content="https://portal.getpowerplay.in" />

          <meta name="twitter:title" content="Powerplay" />
          <meta name="twitter:type" content="website" />
          <meta
            name="twitter:card"
            content="Powerplay - Manage construction & interior projects. India's 1st construction and architecture project management app"
          />
          <meta
            name="twitter:description"
            content="Powerplay - Manage construction & interior projects. India's 1st construction and architecture project management app"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"
            rel="stylesheet"
          />
          <link rel="canonical" href="https://portal.getpowerplay.in/" />

          <script>
            {`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
            analytics.load("${SEGMENT_TOKEN}");
            }}();`}
          </script>

          <script id="_webengage_script_tag" type="text/javascript">
            {`var webengage;
    ! function(w, e, b, n, g) {
        function o(e, t) {
            e[t[t.length - 1]] = function() {
                r.__queue.push([t.join("."), arguments])
            }
        }
        var i, s, r = w[b],
            z = " ",
            l = "init options track screen onReady".split(z),
            a = "feedback survey notification".split(z),
            c = "options render clear abort".split(z),
            p = "Open Close Submit Complete View Click".split(z),
            u = "identify login logout setAttribute".split(z);
        if (!r || !r.__v) {
            for (w[b] = r = {
                    __queue: [],
                    is_spa: 1, //Change this to 0 if you do not wish to use default SPA behaviour of WebEngage SDK
                    __v: "6.0",
                    user: {}
                }, i = 0; i < l.length; i++) o(r, [l[i]]);
            for (i = 0; i < a.length; i++) {
                for (r[a[i]] = {}, s = 0; s < c.length; s++) o(r[a[i]], [a[i], c[s]]);
                for (s = 0; s < p.length; s++) o(r[a[i]], [a[i], "on" + p[s]])
            }
            for (i = 0; i < u.length; i++) o(r.user, ["user", u[i]]);
            setTimeout(function() {
                var f = e.createElement("script"),
                    d = e.getElementById("_webengage_script_tag");
                f.type = "text/javascript", f.async = !0, f.src = ("https:" == e.location.protocol ? "https://ssl.widgets.webengage.com" : "http://cdn.widgets.webengage.com") + "/js/webengage-min-v-6.0.js", d.parentNode.insertBefore(f, d)
            })
        }
    }(window, document, "webengage");
    webengage.init('~99198a20');`}
          </script>

          <script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            `}
          </script>

          <script>
            {USE_SMARTLOOK
              ? `window.smartlook||(function(d) {
            var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
            var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
            c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
            })(document);
            smartlook('init', '${SMART_LOOK_TOKEN}', { region: 'eu' });`
              : ''}
          </script>
        </Helmet>
        <Toaster
          position="bottom-center"
          toastOptions={{
            className: 'toast',
            error: {
              style: { backgroundColor: variables.red },
              iconTheme: {
                primary: variables.white,
                secondary: variables.red,
              },
              duration: 6000,
            },
            success: {
              style: { backgroundColor: variables.green600 },
              iconTheme: {
                primary: variables.white,
                secondary: variables.green600,
              },
              duration: 6000,
            },
            loading: {
              style: { backgroundColor: variables.blue600 },
              iconTheme: {
                primary: variables.white,
                secondary: variables.blue600,
              },
            },
          }}
        />
        <Suspense fallback={<CustomSpinner />}>
          <Sentry.ErrorBoundary fallback={ErrorBoundary} showDialog>
            <App />
          </Sentry.ErrorBoundary>
        </Suspense>
      </div>
    );
  }
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <Router>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ConfirmDialogProvider>
              <Index />
            </ConfirmDialogProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Router>
    </ApolloProvider>
  </Provider>
);

serviceWorkerRegistration.register({
  onUpdate: (registration: any) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          store.dispatch({ type: SHOW_UPDATE_ALERT, payload: true });
        }
      });

      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

reportWebVitals();
