import { Link } from 'react-router-dom';

import MenuButton from './LayoutMenu';
import useResourceAccess from '../utils/hooks/useResourceAccess.hook';
import { setNavbarTitle } from '../redux/features/common/layout/Layout.action';
import { setSelectedKey } from '../redux/features/common/utils/Utils.action';
import { ModuleProps } from '../interfaces/Layout';
import { replaceAllPolyfill } from '../utils';
import { useAppDispatch, useAppSelector } from '../redux/Hooks';
import { LOCAL_STORAGE_SEARCH_STRING, TASK_FILTER } from '../constant';
import useLocalStorage from '../utils/hooks/useLocalStorage.hook';

interface Props {
  item: ModuleProps;
  index: number;
  isProjectRoute?: boolean;
}

const MenuDropdown = ({ item, index, isProjectRoute }: Props) => {
  const dispatch = useAppDispatch();

  const experiments = useAppSelector(
    state => state.organisationReducer.experiments
  );
  const projectId = useAppSelector(
    state => state.projectreducer.selectedProj.id
  );

  const { removeLocalStorageValue } = useLocalStorage();

  const labels = [
    ...(item?.labels?.filter(label => {
      if (label.shouldShowWithExp) {
        return label.shouldShowWithExp(experiments);
      }

      const hasAccess = useResourceAccess(label.id);

      if (hasAccess) {
        return true;
      }

      return false;
    }) || []),
  ];

  const newPath = replaceAllPolyfill(
    String(isProjectRoute ? `/project/${projectId}${item.to}` : item.to),
    '//',
    '/'
  );

  const setNavTitle = (title: string) => () => {
    dispatch(setNavbarTitle(title));
    dispatch(setSelectedKey(null));
    removeLocalStorageValue(LOCAL_STORAGE_SEARCH_STRING);
    removeLocalStorageValue(TASK_FILTER);
  };

  if (item?.experimentalLabels?.length) {
    for (const expLabel of item.experimentalLabels) {
      const allowed = experiments?.get(expLabel.expName);

      if (allowed) {
        labels.push(expLabel.label as any);
      }
    }
  }

  const navBtn = (
    <MenuButton
      key={index}
      iconType={item.htmlBefore}
      title={item.title}
      labels={labels}
      path={item.to}
      keys={item.keys}
      to={newPath}
      newTabs={item.newTabs}
    />
  );

  if (!item.keys) {
    return (
      <div key={index} onClick={setNavTitle(item.title)}>
        <Link to={newPath}>{navBtn}</Link>
      </div>
    );
  } else {
    return navBtn;
  }
};

export default MenuDropdown;
