export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';

export const GET_PROJECT_ITEMS = 'GET_PROJECT_ITEMS';

export const GET_PROJECT_USERS = 'GET_PROJECT_USERS';
export const GET_ASSIGNED_USERS = 'GET_ASSIGNED_USERS';

export const CREATE_PROJECT = 'CREATE_PROJECT';

export const PROJECT_API_SUCCESS = 'PROJECT_API_SUCCESS';
export const PROJECT_API_ERROR = 'PROJECT_API_ERROR';
export const PROJECT_API_PENDING = 'PROJECT_API_PENDING';

export const GET_PROJECT_PROFILE = 'GET_PROJECT_PROFILE';
export const CREATE_PROJECT_PROFILE = 'CREATE_PROJECT_PROFILE';
export const UPDATE_PROJECT_PROFILE = 'UPDATE_PROJECT_PROFILE';

export const UPDATE_PROJECT_USERS = 'UPDATE_PROJECT_USERS';
export const DELETE_PROJECT_USERS = 'DELETE_PROJECT_USERS';

export const SHOW_PROFILE_FORM = 'SHOW_PROFILE_FORM';

export const GET_ORG_PROJECT_LIST = 'GET_ORG_PROJECT_LIST';

export const SET_SELECTED_PROJECT_ID = 'SET_SELECTED_PROJECT_ID';

export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS';
export const GET_PROJECT_DETAIL = 'GET_PROJECT_DETAIL';

export const UPDATE_FINANCE_SETTINGS = 'UPDATE_FINANCE_SETTINGS';

export const UPDATE_PURCHASE_SETTINGS = 'UPDATE_PURCHASE_SETTINGS';

export const UPDATE_DRIVE_SETTINGS = 'UPDATE_DRIVE_SETTINGS';

export const REFRESH_PROJECT_ITEMS = 'REFRESH_PROJECT_ITEMS';

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

export const RESET_PROJECTS = 'RESET_PROJECTS';

export const SET_CUSTOM_TASKLOG_FIELD = 'SET_CUSTOM_TASKLOG_FIELD';

export const SET_PROJECT_STAGES = 'SET_PROJECT_STAGES';

export const SET_CONFIG_TASK_SETTING = 'SET_CONFIG_TASK_SETTING';

export const UPDATE_CONFIG_TASK_SETTING = 'UPDATE_CONFIG_TASK_SETTING';

export const SET_RESOURCE_TYPES = 'SET_RESOURCE_TYPES';
export const SET_RESOURCES = 'SET_RESOURCES';
export const SET_RESOURCE_UTILISATION = 'SET_RESOURCE_UTILISATION';
export const SET_HOLIDAYS = 'SET_HOLIDAYS';

export const SET_SELECTED_PROJECT_VIEW_ID = 'SET_SELECTED_PROJECT_VIEW_ID';
export const SET_SELECTED_TAB_VIEW_ID = 'SET_SELECTED_TAB_VIEW_ID';

export const GET_COUNT_ASYNC_REPORT = 'GET_COUNT_ASYNC_REPORT';

export const GET_CF_DROPDOWN_FILTER = 'GET_CF_DROPDOWN_FILTER';
