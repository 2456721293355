import Dialog from '@mui/material/Dialog';

import { sendInterestedPayingOrgDetails } from '../../../../redux/features/common/organisation';
import { diffInDays } from '../../../../utils/Date.util';
import OrgProfileProps from '../../../../interfaces/Organisation';
import {
  CustomButton,
  CustomDialogActions,
  CustomDialogContent,
} from '../../../common';
import { CS_TEAM_MEMBERS } from '../../../../constants';
import OrgDropdown from '../OrgDropdown';
import { useAppDispatch, useAppSelector } from '../../../../redux/Hooks';

const PaymentDialog = () => {
  const dispatch = useAppDispatch();

  const org: OrgProfileProps = useAppSelector(
    state => state.organisationReducer.orgProfile
  );

  const handleClick = () => {
    if (org._id) dispatch(sendInterestedPayingOrgDetails(org._id));
  };

  return (
    <Dialog open maxWidth="xs">
      <CustomDialogContent dividers={false}>
        <div className="fes-xxxl fw-bold mb-2">
          {'Your 30 Day Trial has ended.'}
        </div>
        <div>
          {
            'Don’t worry all of your data is safe with us and you can continue using features like project planning, task scheduling and more.'
          }
          <br /> <br />
          {
            'If you want to continue using our web application, please click on the button below and one of our team members will contact you with steps on how to proceed.'
          }
        </div>

        <div className="text-default fw-bold fes-xl mt-4">
          {'Change Organisation:'}
        </div>
        <OrgDropdown />
      </CustomDialogContent>
      <CustomDialogActions leftAlign>
        <CustomButton label="Contact Us" uppercase onClick={handleClick} />
      </CustomDialogActions>
    </Dialog>
  );
};

const TrialPeriod = () => {
  const org: OrgProfileProps = useAppSelector(
    state => state.organisationReducer.orgProfile
  );
  const userId: string = useAppSelector(state => state.userreducer._id);

  const showTrialPeriod =
    !org.is_paid &&
    (org.free_trial_date || 0) > Date.now() &&
    org.questionnaire;

  const showPaymentDialog = !showTrialPeriod && !org.is_paid;

  if (CS_TEAM_MEMBERS?.includes(userId)) {
    return null;
  }

  return showTrialPeriod ? (
    <div className="trial-period">
      <div className="text-black">
        {`Trial Left: ${diffInDays(
          org.free_trial_date,
          Date.now(),
          true
        )} days`}
      </div>
    </div>
  ) : (
    showPaymentDialog && <PaymentDialog />
  );
};

export default TrialPeriod;
