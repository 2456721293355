import clsx from 'clsx';
import { Navbar } from 'react-bootstrap';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import LaunchIcon from '@mui/icons-material/Launch';

import NavbarNav from './NavbarNav/NavbarNav';
import OrgDropdown from './OrgDropdown';
import { sendEmailVerification } from '../../../redux/features/common/user';
import { useAppDispatch, useAppSelector } from '../../../redux/Hooks';

interface Props {
  stickyTop?: boolean;
  noOrg?: boolean;
  orgView?: boolean;
  dividers?: boolean;
}

function MainNavbar({ stickyTop = true, noOrg, orgView, dividers }: Props) {
  const dispatch = useAppDispatch();
  const classes = clsx(
    'main-navbar-container',
    stickyTop && 'sticky-top',
    dividers && 'border-bottom'
  );

  const emailVerified = useAppSelector(
    state => state.userreducer.is_email_verified
  );
  const email = useAppSelector(state => state.userreducer.email);
  const userId = useAppSelector(state => state.userreducer._id);

  const sendVerificationEmail = () => {
    dispatch(sendEmailVerification(userId, { email }));
  };

  return (
    <>
      {!emailVerified && (
        <span className={`sticky-top verify-email-bar`}>
          <WarningIcon className={`ico`} />
          {'Verify your Email ID using the link sent to your account.'}

          <span onClick={sendVerificationEmail}>
            <span className="email-verificaton-btn">{'Resend Email'}</span>
            <LaunchIcon className={'ico-resend'} />
          </span>
        </span>
      )}
      <div className={classes}>
        {!noOrg && <OrgDropdown />}

        <Navbar
          variant="light"
          className="align-items-right flex-md-nowrap p-0 w-100"
        >
          <NavbarNav orgView={orgView} />
        </Navbar>
      </div>
    </>
  );
}

export default MainNavbar;
