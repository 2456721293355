import { SET_LOCALSTORAGE_PARAMETERS } from '../../constants';
import LocalStorageUtil from '../../utils/LocalStorage.util';

export function eventDispatch(actionType: string, data?: any) {
  return {
    type: actionType,
    payload: data,
  };
}

export function setLocalStorage(data: any) {
  for (const dataItem in data) {
    if (SET_LOCALSTORAGE_PARAMETERS.includes(dataItem)) {
      LocalStorageUtil.setLocalStorageValue(dataItem, data[dataItem], false);
    }
  }
}

export function errorDispatch(actionType: string, error?: any) {
  return {
    type: actionType,
    error,
  };
}

export function makeEventData(type: string, item_id?: string, context = 'web') {
  return {
    type,
    item_id,
    platform: 'web',
    context,
  };
}
