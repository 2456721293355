import {
  parseString,
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../../utils';
import { eventDispatch } from '../../../base';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../../../apiClient';
import { API_VERSIONS, INVENTORY_APIS } from '../../../../routes.constant';
import {
  GET_GROUPED_MATERIALS,
  GET_GROUPED_MATERIAL,
  GET_INVENTORY_DETAILS,
  INVENTORY_API_PENDING,
  INVENTORY_API_SUCCESS,
  INVENTORY_API_ERROR,
  GET_EDIT_MATERIAL_INFO,
  SET_PAGINATED_DATA_TOTAL_COUNT,
  SET_SELECTED_INVENTORY_TAB_VIEW_ID,
  GET_INVENTORY_INDENT_DETAILS,
  ADD_MATERIAL_INVENTORY,
} from './Inventory.type';
import {
  GroupMaterialFilterProps,
  InventoryProfileFilterProps,
} from '../../../../interfaces/Inventory';
import { customStringify } from '../../../../utils/Url.util';
import {
  SYNC_GROUP_MATERIAL_VIEW,
  SYNC_INVENTORY_HISTORY,
  SYNC_INVENTORY_VIEW,
} from '../../common/refresh/Refresh.type';
import { NewMaterialPayload } from '../../../../interfaces/Materials';
import { GET_BOM_INFO } from '../bom/Bom.type';
import { getAllGroupedMaterialProp } from '../../../../interfaces/GroupedMaterial';

export const getInventoryHistory = ({
  id,
  orgId,
  filters,
}: {
  id: string;
  orgId: string;
  filters?: InventoryProfileFilterProps;
}) => {
  const filter = JSON.stringify(filters);
  const apiUrl = parseString(
    INVENTORY_APIS.getInventoryHistory,
    id,
    customStringify({ org_id: orgId, filters: filter })
  );

  return async dispatch => {
    dispatch(eventDispatch(INVENTORY_API_PENDING, false));
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V2);
      dispatch(eventDispatch(GET_INVENTORY_DETAILS, data));
      dispatch(eventDispatch(INVENTORY_API_SUCCESS));
    } catch (err) {
      dispatch(eventDispatch(INVENTORY_API_PENDING, true));
      toastErrorMessage('Error in gettting Inventory Details');
    }
  };
};

export const addMaterialToInventory =
  (body?: NewMaterialPayload) => async dispatch => {
    try {
      const apiUrl = INVENTORY_APIS.addToInventory;
      const { data } = await postRequest(apiUrl, body);
      dispatch(eventDispatch(ADD_MATERIAL_INVENTORY, data));
      dispatch(eventDispatch(SYNC_INVENTORY_VIEW, true));
    } catch (err) {
      toastErrorMessage(`Error: ${err?.errorMsg}`);
    }
  };

export const getGroupedMaterial = (
  groupedMaterialId: string,
  orgId: string,
  filters: GroupMaterialFilterProps
) => {
  const dFilter = JSON.stringify(filters || {});
  const apiUrl = parseString(
    INVENTORY_APIS.getGroupedMaterialInfo,
    groupedMaterialId,
    customStringify({ dFilter, org_id: orgId })
  );

  return async dispatch => {
    try {
      const { data } = await getRequest(apiUrl);

      dispatch(eventDispatch(GET_GROUPED_MATERIAL, data));
    } catch (err) {
      toastErrorMessage('Error while fetching Group material Info');
    }
  };
};

export const getBomInfo = (
  bomId: string,
  orgId: string,
  filters: GroupMaterialFilterProps
) => {
  const dFilter = JSON.stringify(filters || {});
  const apiUrl = parseString(
    INVENTORY_APIS.getGroupedMaterialBomInfo,
    bomId,
    customStringify({ dFilter, org_id: orgId })
  );

  return async dispatch => {
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_BOM_INFO, data));
    } catch (err) {
      toastErrorMessage('Error while fetching bom info');
    }
  };
};

export const deleteInventory = (invId: string) => async dispatch => {
  try {
    const apiUrl = parseString(INVENTORY_APIS.deleteInventory, invId);
    await deleteRequest(apiUrl);
    dispatch(eventDispatch(SYNC_INVENTORY_VIEW, true));
    toastSuccessMessage('Material archived successfully');
  } catch (err) {
    toastErrorMessage(`Error: ${err}`);
  }
};

export const getGroupedMaterials =
  (body: getAllGroupedMaterialProp) => async dispatch => {
    const dFilter = JSON.stringify(body?.dFilter || {});
    const apiUrl = parseString(
      INVENTORY_APIS.getGroupedMaterials,
      customStringify({ ...body, dFilter })
    );
    try {
      dispatch(eventDispatch(INVENTORY_API_PENDING));
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(INVENTORY_API_SUCCESS));

      dispatch(eventDispatch(GET_GROUPED_MATERIALS, data));
    } catch (err) {
      toastErrorMessage(err?.errorMsg || 'Error in getting Group Materials');
      dispatch(eventDispatch(INVENTORY_API_ERROR, err));
    }
  };

export const getEditMaterialInfo =
  (id: string, query: { org_id: string }) => async dispatch => {
    const apiUrl = parseString(
      INVENTORY_APIS.getEditMterialInfo,
      id,
      customStringify(query)
    );
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_EDIT_MATERIAL_INFO, data));
    } catch (err) {
      toastErrorMessage(
        err?.errorMsg || 'Error in getting edit group material info'
      );
    }
  };

export const postGroupMaterialForm = (body, handleError) => async dispatch => {
  const apiUrl = INVENTORY_APIS.postGroupMaterialForm;
  try {
    const { data } = await postRequest(apiUrl, body);

    handleError(
      data?.error_msgs?.map(msg => msg.msg),
      data?.group
    );
    if (data?.group?._id) {
      toastSuccessMessage('Form Submitted Successfully');
    }
  } catch (err) {
    toastErrorMessage(err?.errorMsg || 'Error in submitting form');
    dispatch(eventDispatch(INVENTORY_API_ERROR, err));
  }
};

export const postDraftedGroupMaterialForm =
  (id, body, handleError) => async dispatch => {
    const apiUrl = parseString(INVENTORY_APIS.postDraftedGroupMaterialForm, id);
    try {
      const { data } = await putRequest(apiUrl, body);

      handleError(
        data?.error_msgs?.map(msg => msg.msg),
        data?.group
      );
      if (data?.group?._id) {
        toastSuccessMessage('Draft group material saved successfully');
      }
    } catch (err) {
      toastErrorMessage(
        err?.errorMsg || 'Error in saving draft group material'
      );
      dispatch(eventDispatch(INVENTORY_API_ERROR, err));
    }
  };

export const deleteGroupedMaterial =
  (id: string, handleRedirect: Function) => async dispatch => {
    const apiUrl = parseString(INVENTORY_APIS.deleteGroupedMaterial, id);
    try {
      await deleteRequest(apiUrl);
      toastSuccessMessage('Successfully deleted group material');
      dispatch(eventDispatch(SYNC_GROUP_MATERIAL_VIEW, true));
      handleRedirect();
    } catch (err) {
      toastErrorMessage(err?.errorMsg || 'Error in deleting Group Material');
      dispatch(eventDispatch(INVENTORY_API_ERROR, err));
    }
  };

export const getGroupMaterialTotalCount =
  (body: getAllGroupedMaterialProp) => async dispatch => {
    const dFilter = JSON.stringify(body?.dFilter || {});
    const apiUrl = parseString(
      INVENTORY_APIS.getGroupMaterialsCount,
      customStringify({ ...body, dFilter })
    );
    try {
      const { data } = await getRequest(apiUrl);

      dispatch(eventDispatch(SET_PAGINATED_DATA_TOTAL_COUNT, data?.count));
    } catch (err) {
      toastErrorMessage(
        err?.errorMsg || 'Error in getting group material count'
      );
      dispatch(eventDispatch(INVENTORY_API_ERROR, err));
    }
  };

export const getInventoryIndentHistory = ({
  bomId,
  orgId,
  filters,
}: {
  bomId: string;
  orgId: string;
  filters?: any;
}) => {
  const dFilter = JSON.stringify({
    ...filters,
  });
  const apiUrl = parseString(
    INVENTORY_APIS.getInventoryIndentHistory,
    bomId,
    customStringify({ dFilter, org_id: orgId })
  );

  return async dispatch => {
    dispatch(eventDispatch(INVENTORY_API_PENDING, false));
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(
        eventDispatch(GET_INVENTORY_INDENT_DETAILS, data?.indents || [])
      );
      dispatch(eventDispatch(INVENTORY_API_SUCCESS));
    } catch (err) {
      dispatch(eventDispatch(INVENTORY_API_ERROR));
      toastErrorMessage('Error in gettting approval details');
    }
  };
};

export const setSelectedInventoryTab = (tabId: number) => {
  return dispatch => {
    dispatch(eventDispatch(SET_SELECTED_INVENTORY_TAB_VIEW_ID, tabId));
  };
};

export const syncInventoryHistory = (invId: string) => async dispatch => {
  try {
    const apiUrl = parseString(INVENTORY_APIS.syncInventoryHistory, invId);
    await postRequest(apiUrl);
    dispatch(eventDispatch(SYNC_INVENTORY_HISTORY, true));
    toastSuccessMessage('Inventory History Synced Successfully');
  } catch (err) {
    toastErrorMessage(`Error: ${err}`);
  }
};
