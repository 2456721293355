export const GET_EXPENSE = 'GET_EXPENSE';
export const GET_ALL_EXPENSES = 'GET_ALL_EXPENSES';
export const GET_EXPENSE_PENDING = 'GET_EXPENSE_PENDING';
export const GET_EXPENSES_PENDING = 'GET_EXPENSES_PENDING';
export const EXPENSE_API_SUCCESS = 'EXPENSE_API_SUCCESS';
export const EXPENSE_API_ERROR = 'EXPENSE_API_ERROR';
export const CREATE_EXPENSE_PENDING = 'CREATE_EXPENSE_PENDING';
export const UPDATE_EXPENSE_PENDING = 'UPDATE_EXPENSE_PENDING';
export const UPLOAD_EXPENSE_FILES_PENDING = 'UPLOAD_EXPENSE_FILES_PENDING';
export const UPLOAD_EXPENSE_FILES = 'UPLOAD_EXPENSE_FILES';
