import React from 'react';
import Box from '@mui/material/Box';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { variables } from '../Theme';
import { useResourceMapper } from '../../../utils/hooks/useResourceAccess.hook';

import './customTabs.scss';

export const CustomTabs = (props: TabsProps) => {
  const resourceMapper = useResourceMapper();

  const children = React.Children.toArray(props.children)?.filter?.(
    (item: React.ReactElement) => resourceMapper(item.props.resource_id)
  );

  return (
    <Tabs
      {...props}
      TabIndicatorProps={{
        style: {
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: variables.blue500,
        },
      }}
      className="customTabsStyles"
    >
      {children}
    </Tabs>
  );
};

export const CustomTab = (props: any) => {
  return <Tab disableRipple {...props} className="customTabStyles" />;
};

export function a11yProps(index: number | string) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    value: index,
  };
}

interface CustomTabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
  fullWidth?: boolean;
  [other: string]: any;
}

export function CustomTabPanel({
  children,
  value,
  index,
  fullWidth = false,
  ...other
}: CustomTabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={!fullWidth ? 2 : 0} mb={12}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const VerticalTabs = (props: any) => {
  return (
    <Tabs {...props} className="verticalTabStyles">
      {props?.children}
    </Tabs>
  );
};

export const VerticalTab = (props: any) => {
  return (
    <Tab disableRipple {...props} className="verticalTabStyles border-bottom" />
  );
};
