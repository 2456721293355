import React, { useEffect } from 'react';
import { Chip, ChipProps } from '@mui/material';

interface IOptionProp {
  _id: string | number;
  name: string;
}
type Props = {
  options?: IOptionProp[];
  defaultValue?: IOptionProp[];
  onChange: (val: IOptionProp[]) => void;
  chipProps?: ChipProps;
};

function MultipleChipSelect({
  options = [],
  chipProps,
  onChange,
  defaultValue = [],
}: Props) {
  const optionMap = React.useRef(
    new Map(
      options.map(opt => [
        opt._id,
        {
          ...opt,
          selected: defaultValue?.find(optVal => optVal._id === opt._id)
            ? true
            : false,
        },
      ])
    )
  );

  useEffect(() => {
    const newMap = new Map(optionMap.current);

    options.forEach(opt => {
      newMap.set(opt._id, {
        ...opt,
        selected: defaultValue?.find(optVal => optVal._id === opt._id)
          ? true
          : false,
      });
    });

    optionMap.current = newMap;
  }, [options]);

  const handleClick = (key: string | number) => () => {
    const option = optionMap.current.get(key);
    if (option) {
      option.selected = !option.selected;
      optionMap.current.set(key, option);

      const result = Array.from(optionMap.current.values())
        .filter(opt => opt.selected)
        .map(opt => ({ _id: opt._id, name: opt.name }));
      onChange(result);
    }
  };

  return (
    <>
      {options.length ? null : (
        <div className="light mx-2">{'No Items Found'}</div>
      )}
      {options.map(opt => (
        <Chip
          key={opt._id}
          label={opt.name}
          clickable
          onClick={handleClick(opt._id)}
          variant={
            optionMap.current.get(opt._id)?.selected ? 'filled' : 'outlined'
          }
          color={
            optionMap.current.get(opt._id)?.selected ? 'primary' : 'default'
          }
          {...chipProps}
        />
      ))}
    </>
  );
}

export default MultipleChipSelect;
