export const GET_ALL_ST_SEQ = 'GET_ALL_ST_SEQ';
export const GET_ACTIVE_ST_SEQ = 'GET_ACTIVE_ST_SEQ';
export const GET_ARCHIVED_ST_SEQ = 'GET_ARCHIVED_ST_SEQ';
export const GET_ST_SEQ = 'GET_ST_SEQ';
export const GET_ST_SEQ_PROJECT = 'GET_ST_SEQ_PROJECT';

export const CREATE_ST_SEQ = 'CREATE_ST_SEQ';
export const UPDATE_ST_SEQ = 'UPDATE_ST_SEQ';
export const UPDATE_ALL_ST_SEQ = 'UPDATE_ALL_ST_SEQ';
export const UPDATE_ACTIVE_ST_SEQ = 'UPDATE_ACTIVE_ST_SEQ';
export const UPDATE_ARCHIVED_ST_SEQ = 'UPDATE_ARCHIVED_ST_SEQ';

export const ARCHIVE_ST_SEQ = 'ARCHIVE_ST_SEQ';
export const RESTORE_ST_SEQ = 'RESTORE_ST_SEQ';
export const ST_SEQ_API_ERROR = 'ST_SEQ_API_ERROR';

export const ARCHIVE_ST_PENDING = 'ARCHIVE_ST_PENDING';
export const ST_SEQ_PENDING = 'ST_SEQ_PENDING';
