export const GET_ALL_DOCS = 'GET_ALL_DOCS';
export const DOC_API_SUCCESS = 'DOC_API_SUCCESS';
export const DOC_API_ERROR = 'DOC_API_ERROR';
export const DOC_API_PENDING = 'DOC_API_PENDING';
export const DOC_UPLOAD = 'DOC_UPLOAD';
export const DOC_UPLOAD_SUCCESS = 'DOC_UPLOAD_SUCCESS';
export const EXCEL_UPLOAD_ERROR = 'EXCEL_UPLOAD_ERROR';
export const DOC_CLEAR_STATE = 'DOC_CLEAR_STATE';
export const EXCEL_UPLOAD_WARNINGS = 'EXCEL_UPLOAD_WARNINGS';
export const EXCEL_UPLOAD_MESSAGE = 'EXCEL_UPLOAD_MESSAGES';
export const RESET_EXCEL_ERROR_LIST = 'RESET_EXCEL_ERROR_LIST';
