import {
  SYNC_DASHBOARD_VIEW,
  SYNC_WORK_CATEGORIES_VIEW,
  SYNC_PROJECT_PLAN_VIEW,
  SYNC_COMMERCIAL_VIEW,
  SYNC_BOM_VIEW,
  SYNC_VENDOR_VIEW,
  SYNC_ORGANIZATION_PROFILE_VIEW,
  SYNC_TEAM_VIEW,
  SYNC_MASTER_VENDOR_VIEW,
  SYNC_MASTER_BOM_VIEW,
  SYNC_INVITE_VIEW,
  SYNC_PACKAGES_VIEW,
  SYNC_DOCUMENTS_VIEW,
  SYNC_INVOICES_VIEW,
  SYNC_REPORTS_VIEW,
  SYNC_PROJECT_PROFILE_VIEW,
  SYNC_THREADS_VIEW,
  SYNC_TASK_PROFILE,
  SYNC_EXPENSE_PROFILE,
  SYNC_ACTIVITY_VIEW,
  SYNC_BUDGET_VIEW,
  SYNC_GRN_VIEW,
  SYNC_GRN_LIST_VIEW,
  SYNC_ATTENDANCE_VIEW,
  SYNC_SITE_PLAN_VIEW,
  SYNC_PROJECT_LIST_VIEW,
  SYNC_MEMBERS_VIEW,
  SYNC_INVENTORY_VIEW,
  SYNC_VIEW,
  SYNC_ATTENDANCE_SUMMARY_VIEW,
  SYNC_ORG_ATTENDANCE_SUMMARY_VIEW,
  SYNC_ATTENDANCE_PAYABLES_VIEW,
  SYNC_ATTENDANCE_PROFILE_VIEW,
  SYNC_ATTENDANCE_ORG_PAYBALS_VIEW,
  SYNC_ISSUE,
  SYNC_MVP_CHILDREN,
  SYNC_MVP_VIEW,
  REFRESH_MVP_CHILDRENS_FOR_ID,
  SYNC_BROAD_PLAN_TASK_VIEW,
  SYNC_BROAD_PLAN_TASK_PROFILE,
  SYNC_BROAD_PLAN_WEEK_VIEW,
  SYNC_BROAD_PLAN_WEEK_TASK_PROFILE,
  SYNC_ATTENDANCE_PROJECT_PROFILE_VIEW,
  SYNC_VENDOR_ORG_LIST_VIEW,
  SYNC_WORK_ORDER_VIEW,
  SYNC_WORK_ORDER_PROFILE_VIEW,
  SYNC_WORK_ORDER_TRANSACTION_VIEW,
  SYNC_VENDOR_PROFILE_VIEW,
  SYNC_VENDOR_TO_PAY_VIEW,
  SYNC_LABOUR_TO_PAY_VIEW,
  SYNC_GROUP_MATERIAL_VIEW,
  SYNC_BOM_PROFILE_VIEW,
  SYNC_INVENTORY_HISTORY,
  SYNC_PRICING_HISTORY,
  SYNC_ASYNC_REPORTS,
  INDENT_SEQ_SYNC_VIEW,
  SYNC_ORG_ST_VIEW,
  SYNC_DIALOG_VIEW,
  SYNC_ORG_MI_VIEW,
  SYNC_VENDOR_PROJECTS_VIEW,
  SYNC_ORG_VENDOR_SELECTION_VIEW,
} from './Refresh.type';

const initialState = {
  sync_dashboard_view: false,
  sync_work_categories_view: false,
  sync_project_plan_view: false,
  sync_commercial_view: false,
  sync_bom_view: false,
  sync_vendor_view: false,
  sync_organization_profile_view: false,
  sync_team_view: false,
  sync_master_vendor_view: false,
  sync_master_bom_view: false,
  sync_invite_view: false,
  sync_packages_view: false,
  sync_documents_view: false,
  sync_invoices_view: false,
  sync_reports_view: false,
  sync_project_profile_view: false,
  sync_threads_view: false,
  sync_task_profile: false,
  sync_expense_profile: false,
  sync_activity_view: false,
  sync_budget_view: false,
  sync_grn_view: false,
  sync_grn_list_view: false,
  sync_attendance_view: false,
  sync_site_plan_view: false,
  sync_org_st_view: false,
  sync_project_list_view: false,
  sync_members_view: false,
  sync_inventory_view: false,
  sync_view: false,
  sync_dialog_view: false,
  sync_org_mi_view: false,
  sync_attendance_summary_view: false,
  sync_org_attendance_summary_view: false,
  sync_org_attendance_paybals_view: false,
  sync_attendance_payables_view: false,
  sync_attendance_profile_view: false,
  sync_attendance_project_profile_view: false,
  sync_issue: false,
  sync_mvp_children: false,
  sync_mvp_view: false,
  sync_broad_plan_task_view: false,
  sync_broad_plan_week_task_view: false,
  sync_broad_plan_task_profile: false,
  sync_broad_plan_week_task_profile: false,
  sync_vendor_org_list_view: false,
  sync_work_order_view: false,
  sync_work_order_profile_view: false,
  sync_work_order_transaction_view: false,
  sync_vendor_profile_view: false,
  sync_vendor_org_to_pay: false,
  sync_labour_org_to_pay: false,
  sync_group_material_view: false,
  sync_bom_profile_view: false,
  refresh_po_mvp: '',
  sync_inventory_history: false,
  sync_pricing_history_view: false,
  sync_async_report: false,
  indent_seq_sync_view: false,
  sync_vendor_project_view: false,
  sync_org_vendor_selection_view: false,
};

export default function refreshReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SYNC_DASHBOARD_VIEW:
      return {
        ...state,
        sync_dashboard_view: payload,
      };
    case SYNC_WORK_CATEGORIES_VIEW:
      return {
        ...state,
        sync_work_categories_view: payload,
      };
    case SYNC_BROAD_PLAN_TASK_PROFILE:
      return {
        ...state,
        sync_broad_plan_task_profile: payload,
      };
    case SYNC_BROAD_PLAN_WEEK_VIEW:
      return {
        ...state,
        sync_broad_plan_week_task_view: payload,
      };
    case SYNC_PROJECT_PLAN_VIEW:
      return {
        ...state,
        sync_project_plan_view: payload,
      };
    case SYNC_COMMERCIAL_VIEW:
      return {
        ...state,
        sync_commercial_view: payload,
      };
    case SYNC_THREADS_VIEW:
      return {
        ...state,
        sync_threads_view: payload,
      };
    case SYNC_BOM_VIEW:
      return { ...state, sync_bom_view: payload };
    case SYNC_VENDOR_VIEW:
      return { ...state, sync_vendor_view: payload };
    case SYNC_ORGANIZATION_PROFILE_VIEW:
      return { ...state, sync_organization_profile_view: payload };
    case SYNC_TEAM_VIEW:
      return { ...state, sync_team_view: payload };
    case SYNC_MASTER_VENDOR_VIEW:
      return { ...state, sync_master_vendor_view: payload };
    case SYNC_MASTER_BOM_VIEW:
      return { ...state, sync_master_bom_view: payload };
    case SYNC_INVITE_VIEW:
      return { ...state, sync_invite_view: payload };
    case SYNC_PACKAGES_VIEW:
      return { ...state, sync_packages_view: payload };
    case SYNC_DOCUMENTS_VIEW:
      return { ...state, sync_documents_view: payload };
    case SYNC_INVOICES_VIEW:
      return { ...state, sync_invoices_view: payload };
    case SYNC_REPORTS_VIEW:
      return { ...state, sync_reports_view: payload };
    case SYNC_PROJECT_PROFILE_VIEW:
      return { ...state, sync_project_profile_view: payload };
    case SYNC_TASK_PROFILE:
      return { ...state, sync_task_profile: payload };
    case SYNC_BROAD_PLAN_TASK_VIEW:
      return { ...state, sync_broad_plan_task_view: payload };
    case SYNC_BROAD_PLAN_WEEK_TASK_PROFILE:
      return { ...state, sync_broad_plan_week_task_profile: payload };
    case SYNC_EXPENSE_PROFILE:
      return { ...state, sync_expense_profile: payload };
    case SYNC_ACTIVITY_VIEW:
      return { ...state, sync_activity_view: payload };
    case SYNC_BUDGET_VIEW:
      return { ...state, sync_budget_view: payload };
    case SYNC_GRN_VIEW:
      return { ...state, sync_grn_view: payload };
    case SYNC_ATTENDANCE_PROJECT_PROFILE_VIEW:
      return { ...state, sync_attendance_project_profile_view: payload };
    case SYNC_GRN_LIST_VIEW:
      return { ...state, sync_grn_list_view: payload };
    case SYNC_ATTENDANCE_VIEW:
      return { ...state, sync_attendance_view: payload };
    case SYNC_ATTENDANCE_ORG_PAYBALS_VIEW:
      return { ...state, sync_org_attendance_paybals_view: payload };
    case SYNC_SITE_PLAN_VIEW:
      return { ...state, sync_site_plan_view: payload };
    case SYNC_PROJECT_LIST_VIEW:
      return { ...state, sync_project_list_view: payload };
    case SYNC_MEMBERS_VIEW:
      return { ...state, sync_members_view: payload };
    case SYNC_INVENTORY_VIEW:
      return { ...state, sync_inventory_view: payload };
    case SYNC_VIEW:
      return { ...state, sync_view: payload };
    case SYNC_DIALOG_VIEW:
      return { ...state, sync_dialog_view: payload };
    case SYNC_ORG_MI_VIEW:
      return { ...state, sync_org_mi_view: payload };
    case SYNC_ORG_ST_VIEW:
      return { ...state, sync_org_st_view: payload };
    case SYNC_VENDOR_ORG_LIST_VIEW:
      return { ...state, sync_vendor_org_list_view: payload };
    case SYNC_WORK_ORDER_VIEW:
      return { ...state, sync_work_order_view: payload };
    case SYNC_WORK_ORDER_PROFILE_VIEW:
      return { ...state, sync_work_order_profile_view: payload };
    case SYNC_VENDOR_PROFILE_VIEW:
      return { ...state, sync_vendor_profile_view: payload };
    case SYNC_WORK_ORDER_TRANSACTION_VIEW:
      return { ...state, sync_work_order_transaction_view: payload };
    case SYNC_ATTENDANCE_SUMMARY_VIEW:
      return {
        ...state,
        sync_attendance_summary_view: payload,
      };
    case SYNC_LABOUR_TO_PAY_VIEW:
      return {
        ...state,
        sync_labour_org_to_pay: payload,
      };
    case SYNC_VENDOR_TO_PAY_VIEW:
      return { ...state, sync_vendor_org_to_pay: payload };
    case SYNC_ORG_ATTENDANCE_SUMMARY_VIEW:
      return {
        ...state,
        sync_org_attendance_summary_view: payload,
      };
    case SYNC_ATTENDANCE_PAYABLES_VIEW:
      return {
        ...state,
        sync_attendance_payables_view: payload,
      };
    case SYNC_ATTENDANCE_PROFILE_VIEW:
      return {
        ...state,
        sync_attendance_profile_view: payload,
      };
    case SYNC_ASYNC_REPORTS:
      return {
        ...state,
        sync_async_report: payload,
      };
    case SYNC_ISSUE:
      return { ...state, sync_issue: payload };
    case SYNC_MVP_CHILDREN:
      return { ...state, sync_mvp_children: payload };
    case SYNC_MVP_VIEW:
      return { ...state, sync_mvp_view: payload };
    case SYNC_GROUP_MATERIAL_VIEW:
      return { ...state, sync_group_material_view: payload };
    case SYNC_INVENTORY_HISTORY:
      return { ...state, sync_inventory_history: payload };
    case REFRESH_MVP_CHILDRENS_FOR_ID:
      return { ...state, refresh_po_mvp: payload };
    case SYNC_BOM_PROFILE_VIEW: {
      return {
        ...state,
        sync_bom_profile_view: payload,
      };
    }
    case INDENT_SEQ_SYNC_VIEW:
      return { ...state, indent_seq_sync_view: payload };
    case SYNC_PRICING_HISTORY:
      return {
        ...state,
        sync_pricing_history_view: payload,
      };
    case SYNC_VENDOR_PROJECTS_VIEW:
      return {
        ...state,
        sync_vendor_project_view: payload,
      };
    case SYNC_ORG_VENDOR_SELECTION_VIEW:
      return {
        ...state,
        sync_org_vendor_selection_view: payload,
      };
    default:
      return state;
  }
}
