import React, { useState } from 'react';
import Popover from '@mui/material/Popover';

import UserProfile from './UserProfile';
import ProfileImage from '../../../../common/ProfileImage';
import { track } from '../../../../../redux/features/common/Segment.action';
import { NAVBAR_EVENTS } from '../../../../../constant';
import { useAppDispatch, useAppSelector } from '../../../../../redux/Hooks';
import './userMenuStyles.scss';

function UserMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();

  const user_name = useAppSelector(state => state.userreducer.displayName);
  const photo_url = useAppSelector(state => state.userreducer.photo_url);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    dispatch(track(NAVBAR_EVENTS.USER_AVATAR_CLICK));
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="ml-3">
      <div className="hand-pointer" onClick={handlePopoverOpen}>
        <ProfileImage user={{ photo_url, user_name }} size="medium" />
      </div>
      <Popover
        id="mouse-over-popover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className="userMenuPopover userMenuPopoverPaper"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      >
        <UserProfile handlePopoverClose={handlePopoverClose} />
      </Popover>
    </div>
  );
}

export default UserMenu;
