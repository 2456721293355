import dayjs from 'dayjs';
import {
  GET_PROJECT_PLAN_ITEMS,
  GET_PROJECT_PLAN_SUMMARY,
  PROJECT_PLAN_API_ERROR,
  PROJECT_PLAN_API_PENDING,
  PROJECT_PLAN_API_SUCCESS,
  PROJECT_PLAN_UPLOAD_ERROR,
  GET_GANTT_CHART_DATA,
  GET_GANTT_CHART_DATA_PENDING,
  GET_IMAGE_LIST,
  GET_IMAGE_LIST_PENDING,
  SET_SHOW_WRP_REF_DIALOG,
  SET_APARTMENT_TEMPLATE,
  SET_APARTMENT_TEMPLATE_PENDING,
} from './ProjectPlan.type';

const defaultProjectPlanSummary = {
  Completed: 10,
  Ongoing: 10,
  Overdue: 10,
  Upcoming: 10,
  Delayed: 10,
  plannedStartDate: 0,
  plannedEndDate: 0,
  total: 0,
  completed: 0,
  inprogress: 0,
  notstarted: 0,
  lateCount: 0,
};

const defaultProjectPlanItems = {
  groups: [],
  items: [],
};

const initialState = {
  pending: false,
  projectPlanItems: defaultProjectPlanItems,
  projectPlanSummary: defaultProjectPlanSummary,
  ganttChartDataPending: false,
  ganttChartData: {
    tasks: [],
    links: [],
  },
  imageList: [],
  imageListPending: false,
  error: null,
  showWprRefDialog: false,
  refImages: [],
  apartmentTemplate: {},
  apartmentTemplatePending: false,
};

export default function baseReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case GET_PROJECT_PLAN_SUMMARY: {
      return { ...state, projectPlanSummary: payload.data };
    }

    case GET_PROJECT_PLAN_ITEMS: {
      const { groups, items } = payload.data;
      for (const item of items) {
        item.start_date = dayjs(item.start_date)
          .subtract(330, 'minutes')
          .valueOf();
        item.end_date = dayjs(item.end_date).subtract(330, 'minutes').valueOf();
      }

      return {
        ...state,
        projectPlanItems: {
          groups: groups,
          items: items,
        },
      };
    }

    case GET_GANTT_CHART_DATA: {
      const filteredTasks = payload.tasks.filter(
        task => task.start_date && task.end_date
      );
      return { ...state, ganttChartData: { ...payload, tasks: filteredTasks } };
    }

    case GET_GANTT_CHART_DATA_PENDING:
      return { ...state, ganttChartDataPending: payload };

    case PROJECT_PLAN_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };

    case PROJECT_PLAN_API_PENDING:
      return {
        ...state,
        pending: true,
      };

    case PROJECT_PLAN_API_ERROR:
      return {
        ...state,
        pending: false,
        error: error.response.data.message,
      };

    case GET_IMAGE_LIST_PENDING:
      return {
        ...state,
        imageListPending: payload,
      };

    case GET_IMAGE_LIST:
      return {
        ...state,
        imageListPending: false,
        imageList: payload.data.proofFiles,
        refImages: payload.data.refImages,
      };

    case PROJECT_PLAN_UPLOAD_ERROR:
      return {
        ...state,
        uploadError: payload,
      };

    case SET_SHOW_WRP_REF_DIALOG:
      return { ...state, showWprRefDialog: payload };

    case SET_APARTMENT_TEMPLATE:
      return { ...state, apartmentTemplate: payload };

    case SET_APARTMENT_TEMPLATE_PENDING:
      return { ...state, apartmentTemplatePending: payload };

    default:
      return state;
  }
}
