export const GET_INDENT_ITEMS = 'GET_INDENT_ITEMS';
export const INDENT_API_PENDING = 'INDENT_API_PENDING';
export const INDENT_API_ERROR = 'INDENT_API_ERROR';
export const INDENT_API_SUCCESS = 'INDENT_API_SUCCESS';
export const ADD_INDENT_ITEM = 'ADD_INDENT_ITEM';
export const INDENT_ITEM_SELECTED = 'INDENT_ITEM_SELECTED';
export const INDENT_FORM_SHOW = 'INDENT_FORM_SHOW';
export const FORM_MODE = 'FORM_MODE';

export const GET_INDENT_DETAILS = 'GET_INDENT_DETAILS';
export const DELETE_INDENT = 'DELETE_INDENT';
export const GET_INDENT_DETAILS_PENDING = 'GET_INDENT_DETAILS_PENDING';

export const GET_INDENT_BY_ORG_ID = 'GET_INDENT_BY_ORG_ID';
export const GET_SIGNATURE = 'GET_SIGNATURE';

export const GET_INDENT_SEQ_PENDING = 'GET_INDENT_SEQ_PENDING';
