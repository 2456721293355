import { Dispatch } from 'redux';

import {
  GET_ALL_TASKS_PENDING,
  GET_TASK,
  GET_TASK_PENDING,
  GET_TASK_LOGS,
  GET_TASK_WITH_LOGS,
  GET_TASK_LOGS_PENDING,
  CREATE_TASK_PENDING,
  UPDATE_TASK_PENDING,
  DELETE_TASK_PENDING,
  TASK_COUNT_PENDING,
  TASK_API_ERROR,
  TOTAL_TASK_COUNT,
  CREATE_TASK,
  CLEAR_TASKS,
  DELETE_TASK_LOG_PENDING,
  ADD_TASK_LOG_PENDING,
  UPDATE_TASK_LOG_PENDING,
  SET_FILTER_VALUES,
  SET_IF_FILTER,
  SET_FILTERED_TASKS,
  SET_SELECTED_TASK,
  SET_TASK_DEPENDENCY_DATA,
  SET_TASK_COMMENTS,
  SET_BASELINES,
  GET_TASK_TREE,
  TASK_TREE_PENDING,
  SET_TASK_DEPENDENCIES,
  SET_SELECTED_TASK_ID,
  LAST_ADDED_TASK,
  SET_TASK_ISSUES,
  SET_MEASUREMENT_UNITS,
  SET_SELECTED_BASELINE,
  SET_COPIED_TASK_DETAILS,
  SET_TASK_MATERIALS,
  SET_TOTAL_TASK_COUNT,
  RESET_COLLAPSED_TASKS,
  GET_BROAD_PLAN_MAIN_TASK,
  GET_BROAD_PLAN_TASK_DETAILS,
  GET_BROAD_PLAN_SITE_UPDATE,
  GET_BROAD_PLAN_TASK_PREV,
  GET_BROAD_PLAN_TASK_CURR,
  GET_BROAD_PLAN_TASK_NEXT,
  GET_BROAD_PLAN_TASK_DETAILS_STATE,
  GET_BROAD_PLAN_WEEK_TASK,
  GET_BROAD_PLAN_TASK_PENDING,
  GET_BROAD_SITE_UPDATE_PENDING,
  GET_BROAD_PLAN_ISSUE,
  GET_BROAD_PLAN_PHOTOS,
  SET_BROAD_PLAN_TASK_COUNT,
  GET_BROAD_PLAN_WEEK_DETAIL,
  GET_BROAD_PLAN_WEEK_TASK_STATE,
  GET_MAP_WEEKLY_TASKS,
  SET_OUTLINE_LEVEL,
  TASK_USAGES,
  TASK_USAGES_PENDING,
  SET_TASK_WORK_ORDER,
  GET_SITE_UPDATES,
  SET_TASK_RESOURCE_USED,
  SET_TASK_CFS,
  SET_BULK_COPIED_TASK_DETAILS,
} from './Task.type';
import {
  SYNC_BROAD_PLAN_TASK_PROFILE,
  SYNC_BROAD_PLAN_TASK_VIEW,
  SYNC_BROAD_PLAN_WEEK_TASK_PROFILE,
  SYNC_BROAD_PLAN_WEEK_VIEW,
  SYNC_SITE_PLAN_VIEW,
  SYNC_TASK_PROFILE,
  SYNC_VENDOR_PROFILE_VIEW,
  SYNC_WORK_ORDER_PROFILE_VIEW,
} from '../../common/refresh/Refresh.type';
import { setRefreshView } from '../../common/refresh/Refresh.action';
import {
  toastSuccessMessage,
  toastErrorMessage,
  parseString,
  toastLoadingMessage,
  safeJSONStringify,
} from '../../../../utils';
import {
  API_VERSIONS,
  TASK_APIS,
  THREAD_APIS,
} from '../../../../routes.constant';
import {
  TASK_SEGMENT,
  TASK_LOG_SEGMENT,
  MANAGE_TASK_SEGMENT,
  SC_PAYABALS_SEGMENT,
} from '../../../../constant/segment_constant';
import { track } from '../../common/Segment.action';
import {
  AssignUserToTaskProps,
  CreateTaskProps,
  GetTaskLogsProps,
  TaskProps,
  LinkBoqToTaskProps,
  AssignUserToTasksProps,
  LinkBoqToTasksProps,
  CreateTaskLogProps,
  TaskLogProps,
  GetTaskDependencyProps,
  BulkUpdateTaskPriceProps,
  TaskViewFilterProps,
  TaskViewQueryFilterProps,
  GetTaskCountProps,
  MeasurementUnitProps,
  SetTaskReminderProps,
  CreateMainTaskProps,
  BroadPlanTaskProps,
  BroadPlanTaskDetailsProps,
  type TaskLogEditProps,
} from '../../../../interfaces/Task';
import { eventDispatch, errorDispatch, makeEventData } from '../../../base';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../../../apiClient';
import { DependencyProps } from '../../../../providers/dhtmlx/gantt/types';
import { TASK_ACTIONS } from '../../../../constant/events/actions';
import {
  ACTION_EVENTS,
  BROAD_PLAN_TYPE,
  MAPPED_TASK_TYPE,
  SEARCH_FILTER_ACCESS_TYPE,
  WEEK_TYPE,
} from '../../../../constant';
import { RootState } from '../../../Reducers';
import { GridApi } from 'ag-grid-community';
import { MEASUREMENT_UNIT_TYPES } from '../../../../constants';
import { customStringify } from '../../../../utils/Url.util';
import { add } from '../../../../utils/Math.util';

export const setFilteredTasks = (tasks: any) => {
  return {
    type: SET_FILTERED_TASKS,
    payload: tasks,
  };
};

export const setSelectedTask = (payload: any) => {
  return {
    type: SET_SELECTED_TASK,
    payload,
  };
};

export function getTaskTree(
  projectId: string,
  query: TaskViewFilterProps = {},
  showLoading: boolean = false
) {
  const filter: TaskViewQueryFilterProps = {};
  filter.packages = query?.packages?.map(pkg => pkg._id);
  filter.start_date_start = query.start_date_start;
  filter.start_date_end = query.start_date_end;

  filter.end_date_start = query.end_date_start;
  filter.end_date_end = query.end_date_end;
  filter.tags = query?.tags?.map(tag => tag._id);
  filter.assigned_users = query?.assigned_users?.map(user => user._id);
  if (query?.task_status) filter.task_status = query?.task_status._id;
  if (query?.is_late) filter.is_late = true;

  const parsedFilter = customStringify(filter);
  const apiUrl = parseString(TASK_APIS.getTaskTree, projectId, parsedFilter);

  return async (dispatch: Dispatch) => {
    try {
      if (showLoading) {
        dispatch(eventDispatch(TASK_TREE_PENDING, true));
      }
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_TASK_TREE, data));
    } catch {
      toastErrorMessage('Error in getting data');
    } finally {
      if (showLoading) {
        dispatch(eventDispatch(TASK_TREE_PENDING, false));
      }
    }
  };
}

export function getTaskDependencyData(params: GetTaskDependencyProps) {
  params.sort_option = 'index_number';
  const apiUrl = parseString(
    TASK_APIS.getTaskDependency,
    customStringify(params)
  );
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(GET_ALL_TASKS_PENDING, true));
    try {
      const { data } = await getRequest<any>(apiUrl, API_VERSIONS.V1);
      dispatch(
        track(TASK_ACTIONS.FETCH_TASK_DATA_SUCCESS, {
          number_of_tasks: data?.tasks?.length || 0,
        })
      );
      dispatch(eventDispatch(SET_TASK_DEPENDENCY_DATA, data));
    } catch {
      dispatch(track(TASK_ACTIONS.FETCH_TASK_DATA_FAILURE));
      toastErrorMessage('Error in fetching records');
    } finally {
      dispatch(eventDispatch(GET_ALL_TASKS_PENDING, false));
    }
  };
}

export function createTask(
  reqBody: CreateTaskProps,
  cb?: (task: TaskProps) => void
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(CREATE_TASK_PENDING, true));
    try {
      const { data } = await postRequest<TaskProps>(
        TASK_APIS.createTask,
        reqBody,
        API_VERSIONS.V1
      );
      dispatch(
        track(
          TASK_SEGMENT.TASK_CREATE_SUCCESS,
          makeEventData(TASK_SEGMENT.TYPE, data._id)
        )
      );
      dispatch(track(TASK_SEGMENT.TASK_CREATE_SUCCESS));
      dispatch(eventDispatch(CREATE_TASK_PENDING, false));
      dispatch(eventDispatch(CREATE_TASK, data));

      cb?.(data);
    } catch (error) {
      dispatch(
        track(TASK_SEGMENT.TASK_CREATE_ERROR, makeEventData(TASK_SEGMENT.TYPE))
      );
      dispatch(track(TASK_SEGMENT.TASK_CREATE_ERROR));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in Creating Task');
    }
  };
}

export const getTask = (taskId: string) => {
  const apiUrl = parseString(TASK_APIS.getTasK, taskId);
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_TASK_PENDING, true));
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(GET_TASK_PENDING, false));
      dispatch(eventDispatch(GET_TASK, data));
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in getting task');
    }
  };
};

export function getTaskLogsForTask(taskId: string) {
  const apiUrl = parseString(TASK_APIS.getTaskLogsForTask, taskId);
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_TASK_LOGS_PENDING, true));
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V2);
      dispatch(eventDispatch(GET_TASK_LOGS_PENDING, false));
      dispatch(eventDispatch(GET_TASK_LOGS, data));
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in getting task logs');
    }
  };
}

export function getTaskLogs(params: GetTaskLogsProps) {
  const apiUrl = parseString(TASK_APIS.taskLogs, customStringify(params));
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_TASK_LOGS_PENDING, true));
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_TASK_LOGS_PENDING, false));
      dispatch(eventDispatch(GET_TASK_LOGS, data));
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in getting task logs');
    }
  };
}

export function getTaskWithLogs(params: GetTaskLogsProps) {
  const apiUrl = parseString(TASK_APIS.taskWithLogs, customStringify(params));
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_TASK_LOGS_PENDING, true));
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_TASK_LOGS_PENDING, false));
      dispatch(eventDispatch(GET_TASK_WITH_LOGS, data));
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in getting task logs');
      dispatch(eventDispatch(GET_TASK_LOGS_PENDING, false));
    }
  };
}

export function sendFollowUp(taskId: string) {
  const apiUrl = parseString(TASK_APIS.sendFollowUp, taskId);
  return async (dispatch: Dispatch) => {
    try {
      await postRequest(apiUrl);
      toastSuccessMessage('Follow Up sent successfully');
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in sending notification for follow up');
    }
  };
}

export function getTaskComments(taskId: string) {
  const apiUrl = `task/${taskId}/comments`;
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(SET_TASK_COMMENTS, data));
    } catch (err) {
      toastErrorMessage('Error in getting task comments');
    }
  };
}

export const getTaskIssues = (taskId: string) => async (dispatch: Dispatch) => {
  const params = { item_id: taskId };
  const apiUrl = parseString(
    THREAD_APIS.getThreadsWithItem,
    customStringify(params)
  );
  try {
    const { data } = await getRequest(apiUrl);
    dispatch(eventDispatch(SET_TASK_ISSUES, data));
  } catch {
    toastErrorMessage('Cannot get task issues');
  }
};

export const getTaskMaterials =
  (taskId: string) => async (dispatch: Dispatch) => {
    const apiUrl = parseString(TASK_APIS.getTaskMaterials, taskId);
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(SET_TASK_MATERIALS, data));
    } catch {
      toastErrorMessage('Cannot get task materials');
    }
  };

export const getTaskCFs = (taskId: string) => async (dispatch: Dispatch) => {
  const apiUrl = parseString(TASK_APIS.getTaskCFs, taskId);
  try {
    const { data } = await getRequest(apiUrl);
    dispatch(eventDispatch(SET_TASK_CFS, data));
  } catch {
    toastErrorMessage('Cannot get task materials');
  }
};

export const getTaskProfile =
  (taskId: string) => async (dispatch: Dispatch<any>) => {
    dispatch(getTask(taskId));
    dispatch(getTaskIssues(taskId));
    dispatch(getTaskLogsForTask(taskId));
    dispatch(getTaskComments(taskId));
    dispatch(getTaskMaterials(taskId));
    dispatch(getTaskCFs(taskId));
  };

export const silentUpdateTask = (
  taskId: string,
  reqBody: TaskProps,
  cb?: (data: TaskProps) => void
) => {
  const apiUrl = parseString(TASK_APIS.updateTask, taskId);

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(UPDATE_TASK_PENDING, true));
    try {
      const { data } = await putRequest(apiUrl, reqBody);
      cb?.(data);
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
    } finally {
      dispatch(eventDispatch(UPDATE_TASK_PENDING, false));
    }
  };
};

export function updateTask(
  taskId: string,
  reqBody: TaskProps,
  showToast?: boolean,
  refreshData: boolean = true
) {
  delete reqBody._id;
  const apiUrl = parseString(TASK_APIS.updateTask, taskId);
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(UPDATE_TASK_PENDING, true));
    let toastId;
    if (showToast) toastId = toastLoadingMessage(`Updating ${reqBody.name}`);
    try {
      await putRequest(apiUrl, reqBody);
      dispatch(eventDispatch(UPDATE_TASK_PENDING, false));
      dispatch(
        track(TASK_SEGMENT.TASK_EDIT_SUCCESS, {
          page_source: 'task_profile_popup',
          task_id: taskId,
        })
      );
      if (showToast) {
        toastSuccessMessage(`${reqBody.name} updated successfully`, toastId);
      }
      dispatch(getTask(taskId));
      if (refreshData) dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      dispatch(
        track(TASK_SEGMENT.TASK_EDIT_ERROR, {
          page_source: 'task_profile_popup',
          task_id: taskId,
        })
      );
      if (showToast)
        toastErrorMessage(`Cannot update ${reqBody.name}`, toastId);
    }
  };
}

export function addTaskAttachments(taskId: string, reqBody: TaskProps) {
  const apiUrl = parseString(TASK_APIS.addTaskAttachments, taskId);

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(UPDATE_TASK_PENDING, true));
    const toastId = toastLoadingMessage('Please wait...');
    try {
      await putRequest(apiUrl, reqBody);
      dispatch(eventDispatch(UPDATE_TASK_PENDING, false));
      dispatch(getTask(taskId));
      toastSuccessMessage('Files attached successfully', toastId);
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage(`Could not attach file`, toastId);
    }
  };
}

export function deleteTask(taskId: string, cb?: Function) {
  const apiUrl = parseString(TASK_APIS.deleteTask, taskId);
  const toastId = toastLoadingMessage('Please wait...');
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(eventDispatch(DELETE_TASK_PENDING, true));
    try {
      const { data } = await deleteRequest<TaskProps>(apiUrl);
      dispatch(
        track(
          TASK_SEGMENT.TASK_DELETE_SUCCESS,
          makeEventData(TASK_SEGMENT.TYPE, data._id)
        )
      );

      const totalTasksDeleted = data.allChildrenCount + 1;

      dispatch(
        eventDispatch(
          SET_TOTAL_TASK_COUNT,
          getState().taskreducer.totalTaskCount - totalTasksDeleted
        )
      );

      dispatch(track(TASK_SEGMENT.TASK_DELETE_SUCCESS, { taskId }));
      dispatch(eventDispatch(DELETE_TASK_PENDING, false));

      cb?.(data);

      toastSuccessMessage('Successfully deleted Task Item', toastId);
    } catch (error) {
      dispatch(
        track(TASK_SEGMENT.TASK_DELETE_ERROR, makeEventData(TASK_SEGMENT.TYPE))
      );
      dispatch(track(TASK_SEGMENT.TASK_DELETE_ERROR, { taskId }));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in deleting task');
    } finally {
      setTimeout(() => {
        dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
      }, 200);
    }
  };
}

export function getTaskCount(
  params: GetTaskCountProps,
  cb?: (count: number) => void
) {
  const apiUrl = parseString(TASK_APIS.taskCount, customStringify(params));
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(TASK_COUNT_PENDING, true));
    try {
      const {
        data: { count },
      } = await getRequest(apiUrl);
      dispatch(eventDispatch(TASK_COUNT_PENDING, false));
      dispatch(eventDispatch(TOTAL_TASK_COUNT, count));
      cb?.(count);
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in getting task count');
    }
  };
}

export function bulkDeleteData(
  reqBody: { taskIdArr: string[] },
  cb?: Function
) {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(eventDispatch(DELETE_TASK_PENDING, true));
    let toastId;
    try {
      toastId = toastLoadingMessage('Deleting task...');
      await putRequest(TASK_APIS.deleteTasks, reqBody);
      dispatch(eventDispatch(SYNC_BROAD_PLAN_TASK_VIEW, true));
      dispatch(
        track(
          reqBody.taskIdArr.length > 1
            ? MANAGE_TASK_SEGMENT.MANAGE_TASK_BULK_DELETE_SUCCESS
            : MANAGE_TASK_SEGMENT.HOVER_TASK_DELETE_SUCCESS,
          makeEventData(TASK_SEGMENT.TYPE)
        )
      );
      dispatch(
        track(
          reqBody.taskIdArr.length > 1
            ? MANAGE_TASK_SEGMENT.MANAGE_TASK_BULK_DELETE_SUCCESS
            : MANAGE_TASK_SEGMENT.HOVER_TASK_DELETE_SUCCESS,
          { taskId: reqBody.taskIdArr }
        )
      );

      dispatch(eventDispatch(DELETE_TASK_PENDING, false));
      const collapsedTasks = getState().taskreducer.collapsedTasks;
      for (const taskId of reqBody.taskIdArr) {
        collapsedTasks.delete(taskId);
      }

      dispatch(
        eventDispatch(RESET_COLLAPSED_TASKS, {
          collapsedTasks,
          projectId: getState().projectreducer.projectProfile._id,
        })
      );

      cb?.(collapsedTasks);
      toastSuccessMessage('Successfully deleted Tasks', toastId);
    } catch (error) {
      dispatch(
        track(
          reqBody.taskIdArr.length > 1
            ? MANAGE_TASK_SEGMENT.MANAGE_TASK_BULK_DELETE_ERROR
            : MANAGE_TASK_SEGMENT.HOVER_TASK_DELETE_ERROR,
          { taskId: reqBody.taskIdArr }
        )
      );
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in deleting task', toastId);
    }
  };
}

export function checkTaskUsages(reqBody: {
  taskIdArr: string[];
  project_id: string;
}) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(TASK_USAGES_PENDING, true));
    try {
      const { data }: any = await postRequest(
        TASK_APIS.checkTaskUsages,
        reqBody
      );
      dispatch(eventDispatch(TASK_USAGES, data));
      dispatch(eventDispatch(TASK_USAGES_PENDING, false));
    } catch (error) {
      dispatch(eventDispatch(TASK_USAGES_PENDING, false));
      toastErrorMessage('Error in deleting task');
    }
  };
}

export function deleteAllTask(projectId: string) {
  const toastId = toastLoadingMessage('Please wait');
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(DELETE_TASK_PENDING, true));
    try {
      const apiUrl = parseString(TASK_APIS.deleteAllTasks, projectId);
      await deleteRequest(apiUrl);
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
      dispatch(eventDispatch(DELETE_TASK_PENDING, false));
      toastSuccessMessage('Successfully deleted Tasks', toastId);
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in deleting task', toastId);
    }
  };
}

export function bulkAddTasks(
  reqBody: {
    project_id: string;
    org_id: string;
    tasks: TaskProps[];
  },
  successCallback = () => {}
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(CREATE_TASK_PENDING, true));
    try {
      await postRequest(TASK_APIS.addTasks, reqBody, API_VERSIONS.V3);
      successCallback();
      dispatch(track(MANAGE_TASK_SEGMENT.MANAGE_TASK_ADD_SUCCESS));
      dispatch(eventDispatch(CREATE_TASK_PENDING, false));
      toastSuccessMessage('Successfully added tasks');
    } catch (error) {
      dispatch(track(MANAGE_TASK_SEGMENT.MANAGE_TASK_ADD_ERROR));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in adding tasks');
    }
  };
}

export function assignUserToTask(
  taskId: string,
  reqBody: AssignUserToTaskProps,
  cb?: Function
) {
  const apiUrl = parseString(TASK_APIS.assignUser, taskId);
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(CREATE_TASK_PENDING, true));
    try {
      await putRequest(apiUrl, reqBody);
      dispatch(track(ACTION_EVENTS.TASK_ACTIONS.EDIT_INLINE_SUCCESSFUL));
      dispatch(eventDispatch(CREATE_TASK_PENDING, false));
      dispatch(getTask(taskId));
      cb?.();
    } catch (error) {
      dispatch(track(ACTION_EVENTS.TASK_ACTIONS.EDIT_INLINE_ERROR));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in assigning users to task');
    }
  };
}

export function linkBoqToTask(reqBody: LinkBoqToTaskProps, taskId: string) {
  const apiUrl = parseString(TASK_APIS.linkBoq, taskId);
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(CREATE_TASK_PENDING, true));
    try {
      await putRequest(apiUrl, reqBody);
      dispatch(track(MANAGE_TASK_SEGMENT.MANAGE_TASK_LINK_BOQ_SUCCESS));
      dispatch(eventDispatch(CREATE_TASK_PENDING, false));
      toastSuccessMessage('Successfully linked boqs to task');
    } catch (error) {
      dispatch(track(MANAGE_TASK_SEGMENT.MANAGE_TASK_LINK_BOQ_ERROR));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in linking boqs to task');
    }
  };
}

export function assignUserToTasks(
  reqBody: AssignUserToTasksProps,
  type?: number
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(CREATE_TASK_PENDING, true));
    try {
      await postRequest(TASK_APIS.assignUsers, reqBody);
      dispatch(track(MANAGE_TASK_SEGMENT.MANAGE_TASK_BULK_ASSIGN_USER_SUCCESS));
      if (type === BROAD_PLAN_TYPE.MAIN_TASK)
        dispatch(eventDispatch(SYNC_BROAD_PLAN_TASK_VIEW, true));
      else dispatch(eventDispatch(SYNC_BROAD_PLAN_WEEK_VIEW, true));
      dispatch(eventDispatch(SYNC_BROAD_PLAN_TASK_VIEW, true));
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
      dispatch(eventDispatch(CREATE_TASK_PENDING, false));
      toastSuccessMessage('Successfully assigned user to tasks');
    } catch (error) {
      dispatch(track(MANAGE_TASK_SEGMENT.MANAGE_TASK_BULK_ASSIGN_USER_ERROR));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in assigning users to tasks');
    }
  };
}

export function linkBoqToTasks(reqBody: LinkBoqToTasksProps) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(CREATE_TASK_PENDING, true));
    try {
      await postRequest(TASK_APIS.linkBoqs, reqBody);
      dispatch(track(MANAGE_TASK_SEGMENT.MANAGE_TASK_BULK_LINK_BOQ_SUCCESS));
      dispatch(eventDispatch(CREATE_TASK_PENDING, false));
      toastSuccessMessage('Successfully linked boqs to tasks');
    } catch (error) {
      dispatch(track(MANAGE_TASK_SEGMENT.MANAGE_TASK_BULK_LINK_BOQ_ERROR));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in linking boqs to tasks');
    }
  };
}

export function bulkUpdateTasksForAutoSchedule(
  reqBody: { taskArr: TaskProps[] },
  refreshData: boolean = true
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(UPDATE_TASK_PENDING, true));
    const toastId = toastLoadingMessage('Please wait...');
    try {
      await postRequest(TASK_APIS.updateTasks, reqBody);
      dispatch(eventDispatch(UPDATE_TASK_PENDING, false));
      dispatch(eventDispatch(LAST_ADDED_TASK, ''));
      toastSuccessMessage('Tasks updated successfully', toastId);
    } catch (error) {
      dispatch(track(MANAGE_TASK_SEGMENT.MANAGE_TASK_UPDATE_ERROR));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in updating tasks', toastId);
    } finally {
      if (refreshData) dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
    }
  };
}

export function bulkUpdateTasks(
  reqBody: { taskArr: TaskProps[] },
  refreshData: boolean = true
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(UPDATE_TASK_PENDING, true));
    try {
      await postRequest(TASK_APIS.updateTasks, reqBody);
      dispatch(eventDispatch(UPDATE_TASK_PENDING, false));
      dispatch(eventDispatch(LAST_ADDED_TASK, ''));
      toastSuccessMessage('Tasks updated successfully');
    } catch (error) {
      dispatch(track(MANAGE_TASK_SEGMENT.MANAGE_TASK_UPDATE_ERROR));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in updating tasks');
    } finally {
      if (refreshData) dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
    }
  };
}

export function addTaskLog(reqBody: CreateTaskLogProps) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(ADD_TASK_LOG_PENDING, true));
    try {
      await postRequest(TASK_APIS.createTaskLog, reqBody, API_VERSIONS.V2);
      if (reqBody?.vendors?.length > 0) {
        dispatch(
          track(SC_PAYABALS_SEGMENT.TASK_LOG_SAVE_UPDATE_CLICK, {
            task_id: reqBody.task_id,
            vendor_id: reqBody.vendors[0].vendor_id,
            wo_id: reqBody.vendors[0].work_order_id,
          })
        );
      }
      dispatch(
        track(TASK_LOG_SEGMENT.TASK_LOG_CREATE_SUCCESS, {
          task_id: reqBody.task_id,
          ...(reqBody?.vendors?.length > 0
            ? {
                wo_id: reqBody.vendors[0].work_order_id,
                vendor_id: reqBody.vendors[0].vendor_id,
              }
            : {}),
        })
      );

      dispatch(eventDispatch(ADD_TASK_LOG_PENDING, false));
      toastSuccessMessage('Successfully created Task Log');
      dispatch(eventDispatch(SYNC_TASK_PROFILE, true));
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
    } catch (error) {
      dispatch(
        track(TASK_LOG_SEGMENT.TASK_LOG_CREATE_ERROR, {
          task_id: reqBody.task_id,
        })
      );
      dispatch(eventDispatch(ADD_TASK_LOG_PENDING, false));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in Creating Task log');
    }
  };
}

export function addTaskLogV2(reqBody: any) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(ADD_TASK_LOG_PENDING, true));
    try {
      await postRequest(TASK_APIS.createTaskLog, reqBody);
      toastSuccessMessage('Successfully updated!!');
      dispatch(
        track(TASK_LOG_SEGMENT.TASK_LOG_CREATE_SUCCESS, {
          task_id: reqBody.task_id,
        })
      );
      dispatch(eventDispatch(SYNC_WORK_ORDER_PROFILE_VIEW, true));
      dispatch(eventDispatch(SYNC_VENDOR_PROFILE_VIEW, true));
      dispatch(eventDispatch(ADD_TASK_LOG_PENDING, false));
    } catch (error) {
      dispatch(
        track(TASK_LOG_SEGMENT.TASK_LOG_CREATE_ERROR, {
          task_id: reqBody.task_id,
        })
      );
      dispatch(eventDispatch(ADD_TASK_LOG_PENDING, false));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in Creating Task log');
    }
  };
}

export function deleteTaskLog(taskLogId: string) {
  const apiUrl = parseString(TASK_APIS.deleteTaskLog, taskLogId);
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(eventDispatch(DELETE_TASK_LOG_PENDING, true));
    const taskId = getState().taskreducer.selectedTask._id;
    try {
      await deleteRequest<TaskProps>(apiUrl);
      dispatch(
        track(
          TASK_LOG_SEGMENT.TASK_LOG_DELETE_SUCCESS,
          makeEventData(TASK_LOG_SEGMENT.TYPE)
        )
      );
      dispatch(
        track(TASK_LOG_SEGMENT.TASK_LOG_DELETE_SUCCESS, {
          taskId,
          page_source: 'task_profile_popup',
        })
      );
      dispatch(eventDispatch(DELETE_TASK_LOG_PENDING, false));
      toastSuccessMessage('Successfully deleted Task Log');
      dispatch(setRefreshView(SYNC_TASK_PROFILE, true));
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
    } catch (error) {
      dispatch(
        track(TASK_LOG_SEGMENT.TASK_LOG_DELETE_ERROR, {
          taskId,
          page_source: 'task_profile_popup',
        })
      );
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in deleting Task log');
    }
  };
}

export function updateTaskLog(id, reqBody: TaskLogEditProps) {
  const apiUrl = parseString(TASK_APIS.updateTaskLog, id);
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(UPDATE_TASK_LOG_PENDING, true));
    try {
      await putRequest(apiUrl, reqBody, API_VERSIONS.V2);
      dispatch(track(TASK_LOG_SEGMENT.TASK_LOG_EDIT_SUCCESS));
      dispatch(eventDispatch(UPDATE_TASK_LOG_PENDING, false));
      toastSuccessMessage('Successfully Updated Task Log');
      dispatch(setRefreshView(SYNC_TASK_PROFILE, true));
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
    } catch (error) {
      dispatch(track(TASK_LOG_SEGMENT.TASK_LOG_EDIT_ERROR));
      dispatch(eventDispatch(UPDATE_TASK_LOG_PENDING, false));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in Updating Task log');
    }
  };
}

export function updateTaskLogV2(reqBody: TaskLogProps) {
  const apiUrl = parseString(TASK_APIS.updateTaskLog, reqBody._id);
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(UPDATE_TASK_LOG_PENDING, true));
    try {
      await putRequest(apiUrl, reqBody, API_VERSIONS.V2);
      dispatch(track(TASK_LOG_SEGMENT.TASK_LOG_EDIT_SUCCESS));
      dispatch(eventDispatch(UPDATE_TASK_LOG_PENDING, false));
      toastSuccessMessage('Successfully Updated Task Log');
      dispatch(setRefreshView(SYNC_TASK_PROFILE, true));
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
    } catch (error) {
      dispatch(track(TASK_LOG_SEGMENT.TASK_LOG_EDIT_ERROR));
      dispatch(eventDispatch(UPDATE_TASK_LOG_PENDING, false));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in Updating Task log');
    }
  };
}

export function clearTasks() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_TASKS,
    });
  };
}

export function setFilterValues(filterValues: any) {
  return (dispatch: any) => {
    try {
      dispatch(eventDispatch(SET_FILTER_VALUES, filterValues));
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
    }
  };
}

export function setIfFilter(ifFilter: any) {
  return (dispatch: Dispatch) => {
    try {
      dispatch(eventDispatch(SET_IF_FILTER, ifFilter));
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
    }
  };
}

export function bulkCreateTaskDependencies(
  dependencies: DependencyProps[],
  cb?: Function
) {
  return async () => {
    const toastId = toastLoadingMessage('Creating Dependency');
    try {
      await postRequest(TASK_APIS.bulkDependencyCreate, {
        dependencies,
      });
      cb?.();
      toastSuccessMessage('Successfully created dependencies', toastId);
    } catch {
      toastErrorMessage('Failed to create dependencies', toastId);
    }
  };
}

export function createTaskDependency(
  projectId: string,
  reqBody: DependencyProps,
  cb?: Function
) {
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage('Creating Dependency');
    const eventData = {
      src: reqBody.source,
      target: reqBody.target,
      type: reqBody.type,
    };
    try {
      await postRequest(
        TASK_APIS.createtaskDependency,
        reqBody,
        API_VERSIONS.V1
      );
      cb?.();
      dispatch(
        track(ACTION_EVENTS.TASK_ACTIONS.ON_TASK_LINK_CREATE_SUCCESS, eventData)
      );
      toastSuccessMessage('Successfully created dependency', toastId);
    } catch {
      toastErrorMessage('Cannot creat dependency', toastId);
      dispatch(
        track(ACTION_EVENTS.TASK_ACTIONS.ON_TASK_LINK_CREATE_FAILURE, eventData)
      );
    }
  };
}

export function deleteTaskDependency(
  projectId: string,
  dependencyId: string,
  cb?: Function
) {
  const apiUrl = parseString(TASK_APIS.deleteTaskDependency, dependencyId);
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage('Deleting Dependency...');
    try {
      await deleteRequest(apiUrl, API_VERSIONS.V1);
      dispatch(
        track(ACTION_EVENTS.TASK_ACTIONS.LINK_DELETE_SUCCESS, {
          link_id: dependencyId,
        })
      );
      setTimeout(() => {
        dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
      }, 500);
      cb?.();
      toastSuccessMessage('Successfully deleted dependency', toastId);
    } catch {
      dispatch(
        track(ACTION_EVENTS.TASK_ACTIONS.LINK_DELETE_FAILURE, {
          link_id: dependencyId,
        })
      );
      toastErrorMessage('Cannot delete dependency', toastId);
    } finally {
      dispatch(
        getTaskDependencyData({
          project_id: projectId,
        })
      );
    }
  };
}

export function bulkUpdateDependency(dependencies: any) {
  const reqBody = { dependencies };
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage('Updating Dependency...');
    try {
      await postRequest(
        TASK_APIS.bulkDependencyUpdate,
        reqBody,
        API_VERSIONS.V1
      );
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));

      toastSuccessMessage('Successfully updated dependency', toastId);
      dependencies.map((single: any) => {
        if (single.type)
          dispatch(
            track(ACTION_EVENTS.TASK_ACTIONS.LEADLAG_TYPE_CHANGE_SUCCESS, {
              link_id: single._id,
            })
          );
        if (single.lag) {
          dispatch(
            track(ACTION_EVENTS.TASK_ACTIONS.LEADLAG_TYPE_CHANGE_SUCCESS, {
              link_id: single._id,
            })
          );
          if (parseInt(single.lag) < 0) {
            dispatch(
              track(ACTION_EVENTS.TASK_ACTIONS.LEAD_ADD_SUCCESSFUL, {
                link_id: single._id,
              })
            );
          } else {
            dispatch(
              track(ACTION_EVENTS.TASK_ACTIONS.LAG_ADD_SUCCESSFUL, {
                link_id: single._id,
              })
            );
          }
        }
      });
    } catch (err) {
      dependencies.map((single: any) => {
        if (single.type)
          dispatch(
            track(ACTION_EVENTS.TASK_ACTIONS.LEADLAG_CHANGE_FAILURE, {
              link_id: single._id,
            })
          );
        if (single.lag) {
          if (parseInt(single.lag) < 0) {
            dispatch(
              track(ACTION_EVENTS.TASK_ACTIONS.LEAD_ADD_FAILURE, {
                link_id: single._id,
              })
            );
          } else {
            dispatch(
              track(ACTION_EVENTS.TASK_ACTIONS.LAG_ADD_FAILURE, {
                link_id: single._id,
              })
            );
          }
        }
        if (single.lag) {
          dispatch(
            track(ACTION_EVENTS.TASK_ACTIONS.LEADLAG_CHANGE_FAILURE, {
              link_id: single._id,
            })
          );
        }
      });
      toastErrorMessage('Cannot update dependency', toastId);
    }
  };
}

export function fetchTaskDependency(taskId: string) {
  const apiUrl = parseString(TASK_APIS.fetchDependency, taskId);
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(SET_TASK_DEPENDENCIES, data));
    } catch {
      toastErrorMessage('Cannot fetch dependency');
    }
  };
}

export function getProjectBaselines(projectId: string) {
  const apiUrl = parseString(
    TASK_APIS.getBaselines,
    customStringify({ project_id: projectId })
  );
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(SET_BASELINES, data));
    } catch {
      toastErrorMessage('error while fetching baselines');
    }
  };
}

export function createProjectBaseline(name: string, projectId: string) {
  const reqBody = { project_id: projectId, name };
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const selectedTab = getState().utilsReducer.initialKey;
    const toastId = toastLoadingMessage(`Creating ${name}`);
    try {
      await postRequest(TASK_APIS.createBaseline, reqBody, API_VERSIONS.V1);
      toastSuccessMessage(`${name} created successfully`, toastId);
      dispatch(
        track(ACTION_EVENTS.TASK_ACTIONS.CREATE_BASELINE_SUCCESS, {
          page_view: `${selectedTab}_view`,
        })
      );
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
      dispatch(getProjectBaselines(projectId));
    } catch {
      dispatch(
        track(ACTION_EVENTS.TASK_ACTIONS.CREATE_BASELINE_FAILURE, {
          page_view: `${selectedTab}_view`,
        })
      );
      toastErrorMessage(`Cannot create ${name}`, toastId);
    }
  };
}

export function updateBulkTaskProgress(
  task_ids: string[],
  project_id: string,
  progress: number,
  cb?: Function
) {
  const reqBody = { task_ids, project_id, progress };
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage(`Updating tasks' progress...`);
    try {
      await postRequest(
        TASK_APIS.updateTasksProgress,
        reqBody,
        API_VERSIONS.V1
      );
      if (progress === 0)
        dispatch(
          track(ACTION_EVENTS.TASK_ACTIONS.BULK_NO_PROGRESS_SUCCESS, {
            task_ids,
          })
        );
      if (progress === 100)
        dispatch(
          track(MANAGE_TASK_SEGMENT.BULK_MARK_COMPLETE_SUCCESS, {
            task_ids,
          })
        );

      toastSuccessMessage(`Updated successfully`, toastId);
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
      cb?.();
    } catch {
      if (progress === 0)
        dispatch(
          track(ACTION_EVENTS.TASK_ACTIONS.BULK_NO_PROGRESS_FAILURE, {
            task_ids,
          })
        );
      if (progress === 100)
        dispatch(
          track(MANAGE_TASK_SEGMENT.BULK_MARK_COMPLETE_FAILURE, {
            task_ids,
          })
        );
      toastErrorMessage(`Cannot update tasks' progress`, toastId);
    }
  };
}

export function deleteProjectBaseline(baselineId: string, projectId: string) {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const selectedTab = getState().utilsReducer.initialKey;
    const toastId = toastLoadingMessage(`Deleting Baseline `);
    try {
      const apiUrl = parseString(TASK_APIS.deleteBaseline, baselineId);
      await deleteRequest(apiUrl, API_VERSIONS.V1);
      toastSuccessMessage(`Baseline successfully Deleted`, toastId);
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
      dispatch(getProjectBaselines(projectId));
      dispatch(
        track(ACTION_EVENTS.TASK_ACTIONS.DELETE_BASELINE_SUCCESS, {
          baselineId,
          page_view: `${selectedTab}_view`,
        })
      );
    } catch (err) {
      dispatch(
        track(ACTION_EVENTS.TASK_ACTIONS.DELETE_BASELINE_FAILURE, {
          baselineId,
          page_view: `${selectedTab}_view`,
        })
      );
      toastErrorMessage(`Cannot Delete Baseline`, toastId);
    }
  };
}

export function updateBaseline(
  baselineId: string,
  name: string,
  projectId: string
) {
  const reqBody = { name };
  const apiUrl = parseString(TASK_APIS.updateBaseLine, baselineId);
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const selectedTab = getState().utilsReducer.initialKey;
    const toastId = toastLoadingMessage(`Updating Baseline`);
    try {
      await putRequest(apiUrl, reqBody, API_VERSIONS.V1);
      toastSuccessMessage(`Baseline Updated`, toastId);
      dispatch(
        track(ACTION_EVENTS.TASK_ACTIONS.UPDATE_BASELINE_SUCCESS, {
          page_view: `${selectedTab}_view`,
        })
      );
      dispatch(getProjectBaselines(projectId));
    } catch {
      toastErrorMessage(`Cannot Update Baseline`, toastId);
      dispatch(
        track(ACTION_EVENTS.TASK_ACTIONS.UPDATE_BASELINE_FAILURE, {
          page_view: `${selectedTab}_view`,
        })
      );
    }
  };
}

export const setSelectedTaskId = (taskId: string) => (dispatch: Dispatch) => {
  dispatch(eventDispatch(SET_SELECTED_TASK_ID, taskId));
};

export const setSelectedBaselineId =
  (baselineId: string) => (dispatch: Dispatch) => {
    dispatch(eventDispatch(SET_SELECTED_BASELINE, baselineId));
  };

export const setSelectedOutlineLevel =
  (outlineLevel: number) => (dispatch: Dispatch) => {
    dispatch(eventDispatch(SET_OUTLINE_LEVEL, outlineLevel));
  };

export const getMeasurementUnits =
  (orgId: string) => async (dispatch: Dispatch) => {
    const params = { org_id: orgId };
    const apiUrl = parseString(
      TASK_APIS.getMeasurementUnits,
      customStringify(params)
    );
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(SET_MEASUREMENT_UNITS, data));
    } catch {
      toastErrorMessage('Cannot get measurement units');
    }
  };

export const getUoqs =
  (orgId: string, key: string) => async (dispatch: Dispatch) => {
    const params = { org_id: orgId, key };
    const apiUrl = parseString(TASK_APIS.getUoqs, customStringify(params));
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V2);
      dispatch(
        eventDispatch(SET_MEASUREMENT_UNITS, [
          ...(data?.defaultUoqs || []),
          ...(data?.userUoqs || []),
        ])
      );
    } catch {
      toastErrorMessage('Cannot get measurement units');
    }
  };

export const updateTaskQuantity =
  (taskId: string, reqBody: TaskProps) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(TASK_APIS.updateQuantityInfo, taskId);
    try {
      await putRequest(apiUrl, reqBody);
      dispatch(getTask(taskId));
    } catch {
      toastErrorMessage('Cannot update Task');
    }
  };

export const bulkUpdateTaskPrice =
  (reqBody: BulkUpdateTaskPriceProps) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(TASK_APIS.updatePrice);
    try {
      await putRequest(apiUrl, reqBody);
      const taskCount = reqBody.taskArr.length;
      if (taskCount > 1) {
        dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
      } else if (taskCount === 1) {
        dispatch(getTask(reqBody.taskArr[0]._id));
      }
    } catch {
      toastErrorMessage('Cannot update Price');
    }
  };

export const copyAndPasteTaskTree =
  (copiedTaskId: string, parentTaskId: string, gridApi: GridApi) =>
  async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const apiUrl = TASK_APIS.copyAndPasteTask;
    const reqBody = {
      parent_task_id: parentTaskId,
      copied_task_id: copiedTaskId,
    };
    const toastId = toastLoadingMessage(`Please Wait...`);
    const totalTaskCount = getState().taskreducer.totalTaskCount;

    try {
      const {
        data: { insertedItemCount },
      } = await postRequest(apiUrl, reqBody, API_VERSIONS.V1);
      toastSuccessMessage('Copied task pasted successfully', toastId);

      const currCount = totalTaskCount + insertedItemCount;
      gridApi.setRowCount(currCount, true);

      dispatch(eventDispatch(SET_TOTAL_TASK_COUNT, currCount));
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
      dispatch(eventDispatch(SET_COPIED_TASK_DETAILS, null));
    } catch (err) {
      toastErrorMessage('Cannot copy tasks', toastId);
    }
  };

export const bulkCopyTask =
  (copiedTaskId: string[], parentTaskId: string, gridApi: GridApi) =>
  async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const apiUrl = TASK_APIS.bulkCopyAndPasteTask;
    const reqBody = {
      source_ids: copiedTaskId,
      target_id: parentTaskId,
    };
    const toastId = toastLoadingMessage(`Please Wait...`);
    const totalTaskCount = getState().taskreducer.totalTaskCount;

    try {
      const {
        data: { insertedItemCount },
      } = await postRequest(apiUrl, reqBody, API_VERSIONS.V1);
      toastSuccessMessage('Copied task pasted successfully', toastId);
      dispatch(
        track(MANAGE_TASK_SEGMENT.SITE_PLAN_PASTE_AS_SUBTASK_SUCCESS, {
          item_count: copiedTaskId.length,
          task_id: parentTaskId,
        })
      );

      const currCount = Number(add(totalTaskCount, insertedItemCount));
      gridApi.setRowCount(currCount, true);

      dispatch(eventDispatch(SET_TOTAL_TASK_COUNT, currCount));
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
      dispatch(eventDispatch(SET_COPIED_TASK_DETAILS, null));
    } catch (err) {
      if (err.errorMsg) {
        toastErrorMessage(err.errorMsg, toastId);
      } else {
        toastErrorMessage('Cannot copy tasks', toastId);
      }
    }
  };

export const copyTaskDetails =
  (task: TaskProps | null) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(eventDispatch(SET_COPIED_TASK_DETAILS, task));
      if (task) {
        toastSuccessMessage(`Copied ${task.name}`);
      }
    } catch (err) {
      toastErrorMessage('Cannot copy tasks');
    }
  };

export const bulkCopyTaskDetails =
  (task: string[] | null) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(eventDispatch(SET_BULK_COPIED_TASK_DETAILS, task));
      if (task) {
        toastSuccessMessage(`${task.length} tasks copied successfully`);
      }
    } catch (err) {
      toastErrorMessage('Cannot copy tasks');
    }
  };

export const indentTasks =
  (tasksIds: any, projectId: string) => async (dispatch: Dispatch<any>) => {
    const apiUrl = TASK_APIS.indentTasks;
    const reqBody = {
      task_ids: tasksIds,
      project_id: projectId,
    };
    const toastId = toastLoadingMessage(`Please Wait...`);

    try {
      await postRequest(apiUrl, reqBody, API_VERSIONS.V1);
      toastSuccessMessage('Tasks indented successfully', toastId);
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
    } catch (err) {
      toastErrorMessage(err.errorMsg || 'Cannot Indent tasks', toastId);
    }
  };

export const outdentTasks =
  (tasksIds: any, projectId: string) => async (dispatch: Dispatch<any>) => {
    const apiUrl = TASK_APIS.outdentTasks;
    const reqBody = {
      task_ids: tasksIds,
      project_id: projectId,
    };
    const toastId = toastLoadingMessage(`Please Wait...`);

    try {
      await postRequest(apiUrl, reqBody, API_VERSIONS.V1);
      toastSuccessMessage('Tasks outdented successfully', toastId);
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
    } catch (err) {
      toastErrorMessage(err.errorMsg || 'Cannot outdent tasks', toastId);
    }
  };

export function moveTask(
  taskId: string,
  action: 'move_up' | 'move_down',
  cb?: Function
) {
  const apiUrl = parseString(TASK_APIS.moveTasks, taskId);

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(UPDATE_TASK_PENDING, true));

    try {
      await putRequest(apiUrl, { action });
      dispatch(eventDispatch(UPDATE_TASK_PENDING, false));
      dispatch(
        track(TASK_SEGMENT.TASK_EDIT_SUCCESS, {
          page_source: 'task_profile_popup',
          task_id: taskId,
        })
      );

      cb?.();
    } catch (error) {
      toastErrorMessage('Action not allowed');
      dispatch(errorDispatch(TASK_API_ERROR, error));
      dispatch(
        track(TASK_SEGMENT.TASK_EDIT_ERROR, {
          page_source: 'task_profile_popup',
          task_id: taskId,
        })
      );
    }
  };
}

export function agGridTaskCreate(
  reqBody: TaskProps,
  api: any,
  isWorkOrder: boolean = false
) {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(eventDispatch(CREATE_TASK_PENDING, true));
    const collapsedTasksMap = getState().taskreducer.collapsedTasks;
    const totaltaskCount = getState().taskreducer.totalTaskCount;
    const selectedTaskWO = getState().taskreducer.task_work_order;

    try {
      const { data: task } = await postRequest<TaskProps>(
        TASK_APIS.createTask,
        reqBody,
        API_VERSIONS.V1
      );
      dispatch(track(TASK_SEGMENT.TASK_CREATE_SUCCESS));
      dispatch(eventDispatch(CREATE_TASK, task));
      dispatch(eventDispatch(SET_TOTAL_TASK_COUNT, totaltaskCount + 1));

      const itemsToRemove = [];
      let rowIndex = task.index_number - 1;

      for (const [id, val] of collapsedTasksMap.entries()) {
        if (!id || !val) continue;

        if ((task as any).ancestors?.some(ancestorId => ancestorId === id)) {
          itemsToRemove.push(id);
          continue;
        }

        if (val.index_number > task.index_number) {
          rowIndex -= val?.allChildrenCount;
        }
      }

      itemsToRemove.forEach(id => collapsedTasksMap?.delete(id));
      setTimeout(() => {
        api?.setRowCount(api?.getInfiniteRowCount() + 1, true);
      }, 0);
      setTimeout(() => {
        api?.ensureIndexVisible(rowIndex);
      }, 0);
      api?.asyncRefreshCache?.();
      api.__lastAddedTaskId = task._id;

      if (isWorkOrder) {
        dispatch(eventDispatch(SET_TASK_WORK_ORDER, [...selectedTaskWO, task]));
      }

      dispatch(eventDispatch(CREATE_TASK_PENDING, false));
    } catch (error) {
      dispatch(
        track(TASK_SEGMENT.TASK_CREATE_ERROR, makeEventData(TASK_SEGMENT.TYPE))
      );
      dispatch(track(TASK_SEGMENT.TASK_CREATE_ERROR));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in Creating Task');
    }
  };
}

export const getTotalTaskCount = (
  projectId: string,
  cb?: (count: number) => void
) => {
  const apiUrl = parseString(TASK_APIS.getTaskSequence, projectId);

  return async (dispatch: Dispatch<any>) => {
    try {
      const { data } = await getRequest(apiUrl);
      const totalTaskCount = data.seq_value;
      cb?.(totalTaskCount);
      dispatch(eventDispatch(SET_TOTAL_TASK_COUNT, totalTaskCount));
    } catch (error) {
      toastErrorMessage('Something went wrong');
    }
  };
};

export const fetchProjectPlan =
  (
    projectId: string,
    page: number,
    limit: number,
    filter = {},
    cb?: (tasks: TaskProps[]) => void
  ) =>
  async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const apiUrl = parseString(TASK_APIS.getProjectPlan, projectId);

    try {
      const collapsedTasksMap = getState().taskreducer.collapsedTasks;

      const reqBody = {
        page,
        limit,
        collapsed: Array.from(collapsedTasksMap.keys()).join(','),
        ...filter,
      };

      const { data } = await postRequest<TaskProps[]>(apiUrl, reqBody);
      cb?.(data);
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Something went wrong');
    }
  };

export function fetchLevelWiseTaskForOutline(
  projectId: string,
  level: number,
  cb?: (map: Map<any, any>) => void
) {
  const apiUrl = parseString(
    TASK_APIS.fetchLevelWiseTaskForOutline,
    projectId,
    customStringify({ level })
  );

  return async (dispatch: Dispatch) => {
    if (level <= 0) {
      return;
    }

    try {
      const { data } = await getRequest<
        {
          _id: string;
          index_number: number;
          allChildrenCount: number;
          ancestors: number;
        }[]
      >(apiUrl);
      const collapsedTasks = data.reduce((map, item) => {
        map.set(item._id, { ...item });
        return map;
      }, new Map());

      dispatch(
        eventDispatch(RESET_COLLAPSED_TASKS, {
          collapsedTasks,
          projectId,
        })
      );
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
      cb?.(collapsedTasks);
    } catch (err) {
      dispatch(errorDispatch(TASK_API_ERROR, err));
    }
  };
}

export function fetchCollapsedTaskData(
  projectId: string,
  taskIds: string[],
  cb?: (map: Map<any, any>) => void
) {
  const apiUrl = parseString(TASK_APIS.fetchCollapsedTaskData, projectId);

  return async (dispatch: Dispatch) => {
    try {
      const { data } = await postRequest<
        {
          _id: string;
          index_number: number;
          allChildrenCount: number;
          ancestors: number;
        }[]
      >(apiUrl, { taskIds });

      const collapsedTasks = data.reduce((map, item) => {
        map.set(item._id, { ...item });
        return map;
      }, new Map());

      dispatch(
        eventDispatch(RESET_COLLAPSED_TASKS, {
          collapsedTasks,
          projectId,
        })
      );
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
      cb?.(collapsedTasks);
    } catch (err) {
      dispatch(errorDispatch(TASK_API_ERROR, err));
    }
  };
}

export function reconcileParentTasks(taskIds: string[]) {
  return async (dispatch: Dispatch) => {
    try {
      await putRequest(TASK_APIS.reconcileParentTasks, {
        taskIds,
      });
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
    } catch (err) {
      dispatch(errorDispatch(TASK_API_ERROR, err));
    }
  };
}

export function searchTask(
  projectId: string,
  search: string,
  page: number,
  limit: number,
  filters: any,
  cb?: (tasks: TaskProps[]) => void
) {
  const queryParams = customStringify({
    text: search,
    projectId: projectId,
    limit: limit,
    page: page,
    access_type: SEARCH_FILTER_ACCESS_TYPE,
  });

  const apiUrl = filters
    ? `${TASK_APIS.searchTaskV3}?${queryParams}&dFilter=${safeJSONStringify(
        filters
      )}`
    : `${TASK_APIS.searchTaskV3}?${queryParams}`;

  return async (dispatch: Dispatch) => {
    try {
      const { data } = await getRequest<TaskProps[]>(apiUrl, API_VERSIONS.V3);
      cb?.(data);
    } catch (err) {
      dispatch(errorDispatch(TASK_API_ERROR, err));
    }
  };
}

export function createMeasurementUnit(
  measurementUnit: MeasurementUnitProps,
  getUrl: string = '',
  uomType: number = MEASUREMENT_UNIT_TYPES.TASK
) {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      const reqBody = {
        org_id: getState().organisationReducer.orgProfile._id,
        key: measurementUnit.unit,
        abbr: measurementUnit.unit,
        name: measurementUnit.name,
        uom_type: uomType,
      };

      await postRequest<MeasurementUnitProps>(
        TASK_APIS.createMeasurementUnit,
        reqBody
      );

      if (getUrl === TASK_APIS.getUoqs) {
        dispatch(getUoqs(reqBody.org_id, 'MATERIAL'));
      } else {
        dispatch(getMeasurementUnits(reqBody.org_id));
      }
    } catch (err) {
      toastErrorMessage(err?.response?.data?.message);
      dispatch(errorDispatch(TASK_API_ERROR, err));
    }
  };
}

export const fetchPlannedTasks =
  (
    projectId: string,
    page: number,
    limit: number,
    filter = {},
    cb?: (tasks: TaskProps[]) => void
  ) =>
  async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(TASK_APIS.fetchPlannedTasks, projectId);

    try {
      const reqBody = {
        page,
        limit,
        ...filter,
      };

      const { data } = await postRequest<TaskProps[]>(apiUrl, reqBody);

      cb?.(data);
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Something went wrong');
    }
  };

export const fetchPlannedTasksV2 =
  (
    projectId: string,
    page: number,
    limit: number,
    filter = {},
    cb?: (tasks: TaskProps[]) => void
  ) =>
  async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(TASK_APIS.fetchPlannedTasksV2, projectId);

    try {
      const reqBody = {
        page,
        limit,
        dFilter: filter,
      };

      const { data } = await postRequest<TaskProps[]>(apiUrl, reqBody);

      cb?.(data);
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Something went wrong');
    }
  };

export const updateTaskPlanStatus =
  (taskArr: string[], updatedStatus: number) => async (dispatch: Dispatch) => {
    const apiUrl = TASK_APIS.updateTaskPlanStatus;

    try {
      const reqBody = {
        taskArr,
        task_plan_status: updatedStatus,
      };

      await putRequest(apiUrl, reqBody);

      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
    } catch (err) {
      dispatch(errorDispatch(TASK_API_ERROR, err));
    }
  };

export const getTaskUpdates =
  (taskIds: string[], note: string) => async (dispatch: Dispatch) => {
    const apiUrl = TASK_APIS.getTaskUpdates;

    try {
      await postRequest(apiUrl, {
        task_ids: taskIds,
        message: note,
      });
    } catch (err) {
      dispatch(errorDispatch(TASK_API_ERROR, err));
    }
  };

export const setTaskReminder =
  (reqBody: SetTaskReminderProps) => async (dispatch: Dispatch) => {
    const apiUrl = TASK_APIS.setTaskReminder;

    try {
      await postRequest(apiUrl, reqBody);
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, true));
    } catch (err) {
      dispatch(errorDispatch(TASK_API_ERROR, err));
    }
  };

export const updateTaskReminder =
  (reminderId: string, note: string) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(TASK_APIS.updateTaskReminder, reminderId);

    try {
      const { data } = await putRequest(apiUrl, {
        message: note,
      });

      dispatch(getTask(data.task_id));
    } catch (err) {
      dispatch(errorDispatch(TASK_API_ERROR, err));
    }
  };

export function createMainTask(
  reqBody: CreateMainTaskProps,
  cb?: (task: TaskProps) => void
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(CREATE_TASK_PENDING, true));
    try {
      const { data } = await postRequest<TaskProps>(
        TASK_APIS.createBroadPlanMainTask,
        reqBody,
        API_VERSIONS.V1
      );

      if (reqBody?.type === BROAD_PLAN_TYPE.WEEKLY_TASK) {
        dispatch(eventDispatch(SYNC_BROAD_PLAN_WEEK_VIEW, true));
      } else {
        dispatch(eventDispatch(SYNC_BROAD_PLAN_TASK_VIEW, true));
      }

      cb?.(data);
    } catch (error) {
      dispatch(
        track(TASK_SEGMENT.TASK_CREATE_ERROR, makeEventData(TASK_SEGMENT.TYPE))
      );
      dispatch(track(TASK_SEGMENT.TASK_CREATE_ERROR));
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in Creating Task');
    } finally {
      dispatch(eventDispatch(CREATE_TASK_PENDING, false));
    }
  };
}

export const fetchBroadPlan =
  (reqBody: BroadPlanTaskProps, weekType?: number) =>
  async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(
      TASK_APIS.getBroadPlanTask,
      customStringify(reqBody)
    );
    try {
      dispatch(eventDispatch(GET_BROAD_PLAN_TASK_PENDING, true));
      const { data } = await getRequest<TaskProps[]>(apiUrl);

      switch (weekType) {
        case WEEK_TYPE.PREV_WEEK:
          dispatch(eventDispatch(GET_BROAD_PLAN_TASK_PREV, data));
          break;

        case WEEK_TYPE.CURR_WEEK:
          dispatch(eventDispatch(GET_BROAD_PLAN_TASK_CURR, data));
          break;

        case WEEK_TYPE.NEXT_WEEK:
          dispatch(eventDispatch(GET_BROAD_PLAN_TASK_NEXT, data));
          break;

        case MAPPED_TASK_TYPE.WEEKLY_TASK:
          dispatch(eventDispatch(GET_MAP_WEEKLY_TASKS, data));
          break;

        default:
          if (reqBody?.type === BROAD_PLAN_TYPE.MAIN_TASK) {
            dispatch(eventDispatch(GET_BROAD_PLAN_MAIN_TASK, data));
          } else if (reqBody?.type === BROAD_PLAN_TYPE.WEEKLY_TASK) {
            dispatch(eventDispatch(GET_BROAD_PLAN_WEEK_TASK, data));
          }
          break;
      }

      dispatch(eventDispatch(GET_BROAD_PLAN_TASK_PENDING, false));
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      dispatch(eventDispatch(GET_BROAD_PLAN_TASK_PENDING, false));
      toastErrorMessage('Something went wrong');
    }
  };

export const getTaskDetailsBroadPlan =
  (taskId: string, reqBody: BroadPlanTaskDetailsProps) =>
  async (dispatch: Dispatch<any>) => {
    const { type, ...query } = reqBody;
    const apiUrl = parseString(
      TASK_APIS.getBroadPlanTaskDetails,
      taskId,
      customStringify(query)
    );
    try {
      if (type === BROAD_PLAN_TYPE.WEEKLY_TASK) {
        dispatch(eventDispatch(GET_BROAD_PLAN_WEEK_TASK_STATE, true));
      } else {
        dispatch(eventDispatch(GET_BROAD_PLAN_TASK_DETAILS_STATE, true));
      }
      const { data } = await getRequest<TaskProps[]>(apiUrl, API_VERSIONS.V2);

      if (type === BROAD_PLAN_TYPE.WEEKLY_TASK) {
        dispatch(eventDispatch(GET_BROAD_PLAN_WEEK_TASK_STATE, false));
        dispatch(eventDispatch(GET_BROAD_PLAN_WEEK_DETAIL, data));
      } else {
        dispatch(eventDispatch(GET_BROAD_PLAN_TASK_DETAILS, data));
        dispatch(eventDispatch(GET_BROAD_PLAN_TASK_DETAILS_STATE, false));
      }
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Something went wrong');
    }
  };

export const updateTaskBroadPlan =
  (reqBody: any, type: number) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(TASK_APIS.updateBroadPlanTask);
    try {
      const { data } = await postRequest(apiUrl, reqBody);
      if (type === BROAD_PLAN_TYPE.WEEKLY_TASK) {
        dispatch(eventDispatch(GET_BROAD_PLAN_TASK_DETAILS, data));
        dispatch(eventDispatch(SYNC_BROAD_PLAN_WEEK_VIEW, true));
        dispatch(eventDispatch(SYNC_BROAD_PLAN_WEEK_TASK_PROFILE, true));
      } else {
        dispatch(eventDispatch(GET_BROAD_PLAN_TASK_DETAILS, data));
        dispatch(eventDispatch(SYNC_BROAD_PLAN_TASK_VIEW, true));
        dispatch(eventDispatch(SYNC_BROAD_PLAN_TASK_PROFILE, true));
      }

      toastSuccessMessage(`Task updated successfully`);
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Something went wrong');
    }
  };

export const getSiteUpdate =
  (reqBody: any) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(
      TASK_APIS.getBroadPlanUpdate,
      customStringify(reqBody)
    );
    try {
      dispatch(eventDispatch(GET_BROAD_SITE_UPDATE_PENDING, true));
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_BROAD_PLAN_SITE_UPDATE, data));
      dispatch(eventDispatch(GET_BROAD_SITE_UPDATE_PENDING, false));
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Something went wrong');
    }
  };

export const updateBroadPlanTask =
  (taskId: string, reqBody: any) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(TASK_APIS.editBroadPlanTask, taskId);
    try {
      await putRequest(apiUrl, reqBody);

      if (reqBody?.type === BROAD_PLAN_TYPE.WEEKLY_TASK) {
        dispatch(eventDispatch(SYNC_BROAD_PLAN_WEEK_VIEW, true));
        dispatch(eventDispatch(SYNC_BROAD_PLAN_WEEK_TASK_PROFILE, true));
      } else {
        dispatch(eventDispatch(SYNC_BROAD_PLAN_TASK_VIEW, true));
        dispatch(eventDispatch(SYNC_BROAD_PLAN_TASK_PROFILE, true));
      }
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Something went wrong');
    }
  };

export const updateBroadPlanStatus =
  (type: number, reqBody: any) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(TASK_APIS.bulkStatusUpdate);
    try {
      await putRequest(apiUrl, reqBody);
      if (type === BROAD_PLAN_TYPE.WEEKLY_TASK) {
        dispatch(eventDispatch(SYNC_BROAD_PLAN_WEEK_VIEW, true));
      } else {
        dispatch(eventDispatch(SYNC_BROAD_PLAN_TASK_VIEW, true));
      }
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Something went wrong');
    }
  };

export const getBroadPlanIssue =
  (reqBody: any) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(
      TASK_APIS.broadPlanIssue,
      customStringify(reqBody)
    );
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_BROAD_PLAN_ISSUE, data));
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Something went wrong');
    }
  };

export const getBroadPlanPhotos =
  (reqBody: any) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(
      TASK_APIS.broadPlanPhotos,
      customStringify(reqBody)
    );
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_BROAD_PLAN_PHOTOS, data));
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Something went wrong');
    }
  };

export const getBroadPlanTaskCount =
  (reqBody: any) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(
      TASK_APIS.broadPlanTaskCount,
      customStringify(reqBody)
    );
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(SET_BROAD_PLAN_TASK_COUNT, data));
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Something went wrong');
    }
  };

export const deleteBroadPlanTask =
  (taskId: string, reqBody: any) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(
      TASK_APIS.broadPlanDeleteTask,
      taskId,
      customStringify(reqBody)
    );
    try {
      await deleteRequest(apiUrl);
      if (reqBody?.type === BROAD_PLAN_TYPE.WEEKLY_TASK) {
        dispatch(eventDispatch(SYNC_BROAD_PLAN_WEEK_VIEW, true));
      } else {
        dispatch(eventDispatch(SYNC_BROAD_PLAN_TASK_VIEW, true));
      }
      toastSuccessMessage('Successfully delete Tasks');
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Something went wrong');
    }
  };

export const broadPlanBulkDelete =
  (reqBody: any) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(
      TASK_APIS.broadPlanBulkDelete,
      customStringify(reqBody)
    );
    try {
      await deleteRequest(apiUrl);
      if (reqBody?.type === BROAD_PLAN_TYPE.WEEKLY_TASK) {
        dispatch(eventDispatch(SYNC_BROAD_PLAN_WEEK_VIEW, true));
      } else {
        dispatch(eventDispatch(SYNC_BROAD_PLAN_TASK_VIEW, true));
      }
      toastSuccessMessage('Successfully delete Tasks');
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Something went wrong');
    }
  };

export function getSiteUpdates(params: GetTaskLogsProps) {
  const apiUrl = parseString(TASK_APIS.taskLogs, customStringify(params));
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_TASK_LOGS_PENDING, true));
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_TASK_LOGS_PENDING, false));
      dispatch(eventDispatch(GET_SITE_UPDATES, data));
    } catch (error) {
      dispatch(errorDispatch(TASK_API_ERROR, error));
      toastErrorMessage('Error in getting task logs');
    }
  };
}

export function getTaskResourceUsage(params) {
  const apiUrl = parseString(
    TASK_APIS.getTaskResourceUsage,
    customStringify(params)
  );

  return async (dispatch: Dispatch) => {
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(SET_TASK_RESOURCE_USED, data));
    } catch (error) {
      toastErrorMessage('Error in getting resource usage');
    }
  };
}
