import PropTypes from 'prop-types';
import { AccessDeniedGraphic } from './Icons';
import { useEffect } from 'react';
import {
  getOrgAdmins,
  sendAccessRequestEmail,
} from '../../redux/features/common/organisation';
import { Box, Grid } from '@mui/material';
import CustomButton from './customButton/CustomButton';
import useViewAccess from '../../utils/hooks/useViewAccess.hook';
import { useAppDispatch, useAppSelector } from '../../redux/Hooks';
import { track } from '../../redux/features/common/Segment.action';
import { SEGMENTS_USER_PERMISSION } from '../../constant/segment_constant';
import { MODULE_NAME_MAP } from '../../constant/module_constants';

const HasAccess = ({ children, resources }) => {
  const dispatch = useAppDispatch();

  const orgAdmins = useAppSelector(
    state => state.organisationReducer.orgAdmins
  );
  const orgId = useAppSelector(
    state => state.organisationReducer.orgProfile._id
  );

  const teamName = useAppSelector(state => state.userreducer.user.team_name);

  const isAccess = useViewAccess(resources);

  useEffect(() => {
    if (!orgAdmins?.length && orgId) {
      dispatch(getOrgAdmins(orgId));
    }
  }, [orgId]);

  useEffect(() => {
    if (!isAccess && resources && orgId) {
      const eventProps = {
        module_name:
          MODULE_NAME_MAP[Array.isArray(resources) ? resources[0] : resources],
        module_id: resources,
        denied_access_type: 'view',
      };

      dispatch(
        track(SEGMENTS_USER_PERMISSION.ACCESS_DENIED_SCREEN, eventProps)
      );
    }
  }, [resources]);

  const handleClick = receiverId => () => {
    dispatch(
      sendAccessRequestEmail(
        receiverId,
        'view',
        Array.isArray(resources) ? resources[0] : resources
      )
    );

    const eventProps = {
      admin_user_id: receiverId,
      denied_access_type: 'view',
      module_id: Array.isArray(resources) ? resources[0] : resources,
      team_name: teamName,
    };

    dispatch(
      track(
        SEGMENTS_USER_PERMISSION.ACCESS_DENIED_RAISE_REQUEST_CLICK,
        eventProps
      )
    );
  };

  const renderNoPermission = (
    <div className="error-container text-center w-100">
      <AccessDeniedGraphic className="error-boundary-icon" />
      <div className="fw-bolder fs-xxxxxl">{'Access Denied!'}</div>
      <div className="fes-l fw-lighter">
        {'You currently do not have permission to access this page.'}
      </div>

      <div className="mt-4 fw-bold fs-xl">
        {'Request access from site admin'}
      </div>

      <Box
        maxHeight={380}
        overflow={'scroll'}
        className="hide-scrollbar mt-2"
        mx={40}
      >
        <Grid container spacing={4}>
          {orgAdmins
            .filter(usr => Boolean(usr.email))
            .map(user => (
              <Grid item xs={6} key={user._id}>
                <div className="text-left d-flex align-items-center justify-content-between p-2 py-3 rounded border-grey-300 cbg-blue-grey-100">
                  <div>
                    <div className="fw-bold fes-l">{user.displayName}</div>
                    <div>{`${user.country_code} ${user.mobile_number}`}</div>
                  </div>
                  <CustomButton
                    label={'Raise Request'}
                    variant="text"
                    className="fw-bold"
                    buttonSize="small"
                    size="small"
                    onClick={handleClick(user._id)}
                  />
                </div>
              </Grid>
            ))}
        </Grid>
      </Box>
    </div>
  );

  return isAccess ? children : renderNoPermission;
};

export default HasAccess;

HasAccess.propTypes = {
  children: PropTypes.any,
  resources: PropTypes.string || PropTypes.arrayOf(PropTypes.string),
};
