import { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import {
  getAllNotifications,
  markAllRead,
} from '../../../../../redux/features/miscellaneous/notification';
import CustomButton from '../../../../common/customButton/CustomButton';
import Notification from './Notification';
import { NotificationProps } from '../../../../../interfaces/Notifications';
import { useAppDispatch, useAppSelector } from '../../../../../redux/Hooks';

const ITEMS_PER_SCROLL = 8;

interface Props {
  handlePopoverClose: () => void;
}

const Notifications = ({ handlePopoverClose }: Props) => {
  const notifications = useAppSelector(
    state => state.notificationReducer.notifications
  );
  const projectId = useAppSelector(
    state => state.projectreducer.selectedProj.id
  );

  const [ref, setRef] = useState(null);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const [items, setItems] = useState<any[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (projectId) {
      dispatch(
        getAllNotifications({
          project_id: projectId,
          sort_option: '-createdAt',
        })
      );
    }
    return () => {
      setItems([]);
    };
  }, []);

  useEffect(() => {
    if (notifications.length) {
      setHasMoreItems(true);
    }
  }, [notifications]);

  const loadItems = (page: number) => {
    const tempArr = notifications
      .slice((page - 1) * ITEMS_PER_SCROLL, page * ITEMS_PER_SCROLL)
      .map((elem: NotificationProps) => (
        <Notification key={elem._id} className="hand-pointer" notifObj={elem} />
      ));

    setItems([...items, ...tempArr]);

    if (page * ITEMS_PER_SCROLL >= notifications.length) {
      setHasMoreItems(false);
    }
  };

  const markAllAsRead = () => {
    if (projectId) {
      dispatch(markAllRead({ project_id: projectId }));
      handlePopoverClose();
    }
  };

  return (
    <div
      ref={(ref: any) => {
        setRef(ref);
      }}
      className="notification-container"
    >
      <div className="title d-flex align-items-center justify-content-between bg-grey-200">
        <div className="p-3 text-black fw-bold fs-l">{`Notifications`}</div>
        <CustomButton
          label={'Mark all as read'}
          variant="text"
          buttonSize="small"
          className="text-primary fs-xs"
          disableRipple
          handleSubmit={markAllAsRead}
        />
      </div>
      <InfiniteScroll
        pageStart={0}
        initialLoad={true}
        loadMore={loadItems}
        hasMore={hasMoreItems}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
        useWindow={false}
        getScrollParent={() => ref}
      >
        {items}
      </InfiniteScroll>
    </div>
  );
};

export default Notifications;
