import {
  ONBOARDING_API_ERROR,
  ONBOARDING_CREATE_ORG_AND_PROJECT_PENDING,
  SET_ANSWER,
  SET_INTERNAL_STEP,
  SET_INTERNAL_SUBSTEP,
  SET_IS_PROJECT_SELECTION,
  SET_MODULE,
  SET_NEW_ONBOARDING_QUESTIONS,
  SET_NEW_ONBOARDING_STEP,
  SET_NO_QUESTION_LEVEL,
  SET_ONBOARDING_STEP,
  SET_PROJECT,
  SET_QUESTION_LOADING,
  SET_STEP_AND_SUBSTEP,
} from './Onboarding.type';
import {
  DEFAULT_STEP,
  NEW_ONBOARDING_MODULES,
  ONBOARDING_STEPS_V2,
} from '../../../../components/onboarding/onboarding_constants';
import { ReducerProps } from '../../../../interfaces/Base';

const initialPendingState = {
  createOrgAndProjectPending: false,
};

const initialState = {
  ...initialPendingState,
  onboardingStep: ONBOARDING_STEPS_V2.MODULE_SELECTION,
  newOnboardingStep: ONBOARDING_STEPS_V2.DEFAULT,
  internalStep: DEFAULT_STEP,
  internalSubStep: DEFAULT_STEP,
  questions: [],
  answers: new Map(),
  module: NEW_ONBOARDING_MODULES.MATERIAL,
  selectedProject: { project_id: '', project_name: '' },
  loadingQuestion: false,
  isProjectSelection: false,
  noQuestionLevel: false,
};

function OnboardingReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
) {
  switch (type) {
    case SET_ONBOARDING_STEP:
      return {
        ...state,
        onboardingStep: payload,
      };

    case ONBOARDING_CREATE_ORG_AND_PROJECT_PENDING:
      return {
        ...state,
        createOrgAndProjectPending: payload,
      };

    case ONBOARDING_API_ERROR:
      return {
        ...state,
        error: error,
      };

    case SET_NEW_ONBOARDING_STEP:
      return {
        ...state,
        newOnboardingStep: payload,
      };

    case SET_INTERNAL_STEP:
      return {
        ...state,
        internalStep: payload,
      };

    case SET_INTERNAL_SUBSTEP:
      return {
        ...state,
        internalSubStep: payload,
      };

    case SET_NEW_ONBOARDING_QUESTIONS:
      return {
        ...state,
        questions: payload,
      };

    case SET_ANSWER:
      return {
        ...state,
        answers: payload,
      };

    case SET_MODULE:
      return {
        ...state,
        module: payload,
      };

    case SET_PROJECT:
      return {
        ...state,
        selectedProject: payload,
      };

    case SET_QUESTION_LOADING:
      return {
        ...state,
        loadingQuestion: payload,
      };

    case SET_IS_PROJECT_SELECTION:
      return {
        ...state,
        isProjectSelection: payload,
      };
    case SET_NO_QUESTION_LEVEL:
      return {
        ...state,
        noQuestionLevel: payload,
      };

    case SET_STEP_AND_SUBSTEP:
      return {
        ...state,
        internalStep: payload.internalStep,
        internalSubStep: payload.internalSubStep,
      };

    default:
      return state;
  }
}

export default OnboardingReducer;
