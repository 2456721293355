import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const RotateZoomImage = ({
  src,
  className,
  rotate,
  index,
  zoomable,
  onClick,
}) => {
  const classes = clsx(className);
  const [isZoom, setZoom] = useState(false);

  let cursorStyle;

  if (zoomable) {
    cursorStyle = isZoom ? 'zoom-out' : 'zoom-in';
  } else {
    cursorStyle = 'pointer';
  }

  return (
    <div className={'zoom-rotate-image'}>
      <img
        src={src}
        onClick={() => {
          if (zoomable) {
            setZoom(!isZoom);
          } else {
            onClick(index);
          }
        }}
        style={{
          transform: `rotate(${rotate * 90}deg)`,
          transitionDuration: '300ms',
          transitionProperty: 'transform,height',
          cursor: cursorStyle,
          height: isZoom ? '180vh' : '60vh',
          maxWidth: '100%',
          objectFit: !isZoom ? 'contain' : '',
          overflow: 'hidden',
          width: '100%',
          maxHeight: '60vh',
        }}
        className={classes}
      />
    </div>
  );
};

RotateZoomImage.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  rotate: PropTypes.number,
  index: PropTypes.number,
  zoomable: PropTypes.any,
  onClick: PropTypes.func,
  style: PropTypes.any,
};

export default RotateZoomImage;
