export const CREATE_NEW_PROJECT_SUCCESS = 'create_new_project_success';
export const CREATE_NEW_PROJECT_FAILURE = 'create_new_project_failure';
export const STATUS_CHANGE_CLICKED = 'status_change_click';
export const CHANGE_STATUS_SUCCESS = 'status_change_success';
export const CHANGE_STATUS_FAILURE = 'status_change_failure';
export const PROJECT_CARD_CLICKED = 'project_card_clicked';
export const DASHBOARD_LOAD_FAILURE = 'dashboard_load_failure';
export const DASHBOARD_LOAD_SUCCESS = 'dashboard_load_success';
export const DUPLICATE_PROJECT_BUTTON_CLICK = 'duplicate_project_button_click';
export const PROJECT_NAME_TYPE = 'project_name_type';
export const DUPLICATE_PROJECT_SAVE_BUTTON_CLICK =
  'duplicate_project_save_button_click';
export const DUPLICATE_PROJECT_CANCEL_BUTTON_CLICK =
  'duplicate_project_cancel_button_click';
export const CHANGE_MATERIAL_APPROVER_MODE = 'change_material_approver_mode';
export const PROJECT_CONTACT_DETAILS_WHATSAPP_ICON_CLICKED =
  'project_contact_details_whatsapp_icon_clicked';
export const PROJECT_CONTACT_ICON_CLICKED = 'project_contact_icon_clicked';
export const PROJECT_CONTACT_PHONE_NUMBER_COPY =
  'project_contact_phone_number_copy';
export const MANAGE_COLUMNS_BUTTON_CLICK = 'manage_columns_button_click';
export const MANAGE_COLUMNS_SEARCH_BAR_CLICK =
  'manage_columns_search_bar_click';
export const MANAGE_COLUMNS_SEARCH_BAR_TYPE = 'manage_columns_search_bar_type';
export const MANAGE_COLUMNS_SCROLL = 'manage_columns_scroll';
export const MANAGE_COLUMNS_OPTION_CLICK = 'manage_columns_option_click';
