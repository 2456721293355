/**
 * Filename: dashboardSaga
 * Description:
 */
import { take, fork, call, put } from 'redux-saga/effects';

import apiClient from '../utils/ApiClient.util';
import {
  GET_PACKAGES_PROGRESS,
  GET_TASK_COUNT_NEXT_SEVEN_DAYS,
  GET_ONTIME_TASK_COUNT,
  UPCOMING_TASK_PENDING,
  ON_TIME_TASK_PENDING,
  GET_DASHBOARD_DATA,
  DASHBOARD_API_ERROR,
  DASHBOARD_API_SUCCESS,
  DASHBOARD_API_PENDING,
} from '../redux/features/dashboard/Dashboard.type';
import { DASHBOARD_APIS } from '../routes.constant';
import { toastErrorMessage } from '../utils/Toast.util';
import { customStringify } from '../utils/Url.util';

function* getPackageProgress(project_id) {
  const apiUrl = `${DASHBOARD_APIS.packageProgress}/?${customStringify({
    project_id,
  })}`;

  try {
    yield put({ type: DASHBOARD_API_PENDING });
    const payload = yield call(apiClient.get, apiUrl);
    yield put({ type: DASHBOARD_API_SUCCESS });
    yield put({ type: GET_PACKAGES_PROGRESS, payload });
  } catch (error) {
    yield put({ type: DASHBOARD_API_ERROR, error });
  }
}

function* getTaskCountNext7Days(project_id) {
  const apiUrl = `${DASHBOARD_APIS.uncompletedTasks}/${project_id}`;
  try {
    yield put({ type: UPCOMING_TASK_PENDING, payload: true });
    const payload = yield call(apiClient.get, apiUrl);
    yield put({ type: UPCOMING_TASK_PENDING, payload: false });
    yield put({ type: GET_TASK_COUNT_NEXT_SEVEN_DAYS, payload });
  } catch (error) {
    yield put({ type: UPCOMING_TASK_PENDING, payload: false });
    yield put({ type: DASHBOARD_API_ERROR, error });
  }
}

function* getOntimeTaskCount(project_id) {
  const apiUrl = `${DASHBOARD_APIS.taskCountOnTime}/${project_id}`;
  try {
    yield put({ type: ON_TIME_TASK_PENDING, payload: true });
    const payload = yield call(apiClient.get, apiUrl);
    yield put({ type: ON_TIME_TASK_PENDING, payload: false });
    yield put({ type: GET_ONTIME_TASK_COUNT, payload });
  } catch (error) {
    yield put({ type: ON_TIME_TASK_PENDING, payload: false });
    yield put({ type: DASHBOARD_API_ERROR, error });
  }
}

/**
 * listens for dashboard actions, and handles async calls
 * appropriately
 */
export function* dashboardSaga() {
  while (true) {
    try {
      const { payload: project_id } = yield take(GET_DASHBOARD_DATA);
      yield fork(getTaskCountNext7Days, project_id);
      yield fork(getOntimeTaskCount, project_id);
      yield fork(getPackageProgress, project_id);
    } catch (e) {
      toastErrorMessage(e);
    }
  }
}
