import {
  ARCHIVE_ST_PENDING,
  ARCHIVE_ST_SEQ,
  ST_SEQ_PENDING,
  CREATE_ST_SEQ,
  GET_ACTIVE_ST_SEQ,
  GET_ALL_ST_SEQ,
  GET_ARCHIVED_ST_SEQ,
  GET_ST_SEQ_PROJECT,
  RESTORE_ST_SEQ,
  UPDATE_ACTIVE_ST_SEQ,
  UPDATE_ALL_ST_SEQ,
  UPDATE_ARCHIVED_ST_SEQ,
  UPDATE_ST_SEQ,
  GET_ST_SEQ,
  ST_SEQ_API_ERROR,
} from './SiteTransferSequence.type';

const pendingInitialStae = {
  stSeqPending: false,
  archiveSTSeqPending: false,
};

const initialState = {
  ...pendingInitialStae,
  allSTSeq: [],
  archivedSTSeq: [],
  activeSTSeq: [],
  projectSTSeq: [],
  createSTSeq: null,
  selectedSTSeq: null,
  addedSTSeq: null,
  stSeq: null,
  archiveSTSeqResp: null,
  restoreSTSeq: null,
  updateSTSeq: null,
  updateSTAllSeq: null,
  updateSTArchivedSeq: null,
  updateSTActiveSeq: null,
  error: null,
};

export default function stSequenceReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case ST_SEQ_PENDING:
      return { ...state, stSeqPending: payload };
    case CREATE_ST_SEQ:
      return { ...state, createSTSeq: payload };
    case GET_ALL_ST_SEQ:
      return { ...state, allSTSeq: payload };
    case GET_ACTIVE_ST_SEQ:
      return { ...state, activeSTSeq: payload };
    case GET_ARCHIVED_ST_SEQ:
      return { ...state, archivedSTSeq: payload };
    case GET_ST_SEQ_PROJECT:
      return { ...state, projectSTSeq: payload };
    case GET_ST_SEQ:
      return { ...state, stSeq: payload };

    case UPDATE_ST_SEQ:
      return { ...state, updateSTSeq: payload };
    case UPDATE_ALL_ST_SEQ:
      return { ...state, updateAllSTSeq: payload };
    case UPDATE_ARCHIVED_ST_SEQ:
      return { ...state, updateArchivedSTSeq: payload };
    case UPDATE_ACTIVE_ST_SEQ:
      return { ...state, updateActiveSTSeq: payload };
    case ARCHIVE_ST_PENDING:
      return { ...state, archiveSTSeqPending: payload };
    case ARCHIVE_ST_SEQ:
      return { ...state, archiveSTSeqResp: payload };
    case RESTORE_ST_SEQ:
      return { ...state, restoreSTSeq: payload };

    case ST_SEQ_API_ERROR:
      return { ...state, error: error };
    default:
      return state;
  }
}
