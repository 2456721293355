export const GET_TAG = 'GET_TAG';
export const GET_ALL_TAGS_TASK = 'GET_ALL_TAGS_TASK';
export const GET_ALL_TAGS_BOQ = 'GET_ALL_TAGS_BOQ';
export const GET_ALL_TAGS_DRIVE = 'GET_ALL_TAGS_DRIVE';
export const GET_ALL_TAGS_PROJECT = 'GET_ALL_TAGS_PROJECT';
export const GET_ALL_TAGS_MI = 'GET_ALL_TAGS_MI';
export const GET_ALL_TAGS_MATERIAL = 'GET_ALL_TAGS_MATERIAL';
export const GET_ALL_TAGS_MATERIAL_LOGS = 'GET_ALL_TAGS_MATERIAL_LOGS';
export const CREATE_TAG = 'CREATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const UPDATE_TAG = 'UPDATE_TAG';
export const TAG_API_ERROR = 'TAG_API_ERROR';
export const GET_TAG_PENDING = 'GET_TAG_PENDING';
export const GET_TAGS_PENDING = 'GET_TAGS_PENDING';
export const CREATE_TAG_PENDING = 'CREATE_TAG_PENDING';
export const UPDATE_TAG_PENDING = 'UPDATE_TAG_PENDING';
export const DELETE_TAG_PENDING = 'DELETE_TAG_PENDING';
