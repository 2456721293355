import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/HighlightOff';

import {
  getProjUsers,
  updateDriveSettings,
  updateFinanceSettings,
  updatePurchaseSettings,
} from '../../redux/features/common/project';
import ProjectProps, {
  ApproverUserProps,
  DriveSettingsProps,
  FinanceSettingsProps,
  PurchaseSettingsProps,
} from '../../interfaces/Project';
import UserProps from '../../interfaces/User';
import { Box } from '@mui/material';
import { variables } from './Theme';
import {
  Action,
  CustomTextField,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
  ProfileImage,
  CustomButton,
} from './';
import { TEAM_MODULES } from '../../constant/module_constants';
import { ACTION_NAME } from '../../constant/action_constants';
import { useAppDispatch, useAppSelector } from '../../redux/Hooks';
import { PROJECT_APPROVER_CHOICES } from '../../constants';
import { track } from '../../redux/features/common/Segment.action';
import { APPROVER_SETTINGS } from '../../constant/segment_constant';

interface Props {
  choice: (typeof PROJECT_APPROVER_CHOICES)[keyof typeof PROJECT_APPROVER_CHOICES];
  idx?: number;
  show: boolean;
  onHide: () => void;
}
function AddApproverDialog({ choice, idx = 0, show, onHide }: Props) {
  const dispatch = useAppDispatch();
  const [searchVal, setSearchVal] = useState('');
  const members: (UserProps & ApproverUserProps)[] = useAppSelector(
    state => state.projectreducer.projectUsers
  );
  const projectProfile: ProjectProps = useAppSelector(
    state => state.projectreducer.projectProfile
  );
  const projectId: string = useAppSelector(
    state => state.projectreducer.selectedProj.id
  );

  function setUserProps(user: ApproverUserProps & UserProps) {
    return {
      value: user.user_id,
      label: user.user_name,
      user_profile_url: user?.user_profile_url || user.photo_url || '',
    };
  }
  dispatch(
    track(APPROVER_SETTINGS.APPROVAL_SETTINGS_ADD_APPROVER_POP_UP, {
      module_name: choice,
    })
  );

  function setUserChoice() {
    switch (choice) {
      case PROJECT_APPROVER_CHOICES.PO:
        return projectProfile?.purchase_settings?.po_approvers?.[
          idx
        ]?.users?.map(setUserProps);
      case PROJECT_APPROVER_CHOICES.GRN:
        return projectProfile?.purchase_settings?.grn_approvers?.[
          idx
        ]?.users.map(setUserProps);
      case PROJECT_APPROVER_CHOICES.INDENT:
        return projectProfile?.purchase_settings?.indent_approvers?.[
          idx
        ]?.users.map(setUserProps);
      case PROJECT_APPROVER_CHOICES.BOQ:
        return projectProfile?.finance_settings?.boq_approvers?.[
          idx
        ]?.users?.map(setUserProps);
      case PROJECT_APPROVER_CHOICES.MI_SC:
        return projectProfile?.purchase_settings?.mi_approvers?.[
          idx
        ]?.users?.map(setUserProps);
      case PROJECT_APPROVER_CHOICES.MI_ST:
        return projectProfile?.purchase_settings?.mi_st_approvers?.[
          idx
        ]?.users?.map(setUserProps);
      case PROJECT_APPROVER_CHOICES.DRIVE:
        return projectProfile?.drive_settings?.drive_approvers?.[
          idx
        ]?.users?.map(setUserProps);
    }
  }
  const [users, setUsers] = useState<
    | {
        value?: string;
        label?: string;
        user_profile_url?: string;
        state?: number;
      }[]
    | undefined
  >(() => setUserChoice());

  const memberOptions = members
    .map(setUserProps)
    .filter(v => !users?.some(v2 => v2.value === v.value));

  useEffect(() => {
    if (projectProfile && show) {
      setUsers(() => setUserChoice());
      dispatch(getProjUsers({ project_id: projectId }));
    }
  }, [show, choice, projectProfile]);

  const handleUserAdd = (_: unknown, val: any) => {
    const tmp = [...(users || [])];
    tmp.push(val);
    setUsers(tmp);
    setSearchVal('');
  };
  const handleRemove = (idx: number) => () => {
    const tmp = [...(users || [])];
    if (tmp?.[idx]) {
      tmp.splice(idx, 1);
      setUsers(tmp);
    }
  };

  const handleSubmit = () => {
    function setUserSettings() {
      return users?.length
        ? users?.map(user => ({
            user_id: user.value,
            user_name: user.label,
            user_profile_url: user.user_profile_url || '',
            state: 0,
          }))
        : [];
    }
    let tmpObj: PurchaseSettingsProps &
      FinanceSettingsProps &
      DriveSettingsProps = {};
    let tempList = [];
    dispatch(
      track(APPROVER_SETTINGS.APPROVAL_SETTINGS_SAVE_APPROVER_BTN_CLICK, {
        module_name: choice,
      })
    );
    switch (choice) {
      case PROJECT_APPROVER_CHOICES.PO:
        tempList = projectProfile?.purchase_settings?.po_approvers || [];
        if (!tempList || !tempList.length) {
          tempList = [{ users: [] }];
        }
        tempList[0].users = setUserSettings();
        tmpObj = {
          ...(projectProfile?.purchase_settings || {}),
          po_approvers: tempList,
          is_po_approver_dialog_shown: true,
        };
        break;
      case PROJECT_APPROVER_CHOICES.GRN:
        tempList = projectProfile?.purchase_settings?.grn_approvers || [];
        if (!tempList || !tempList.length) {
          tempList = [{ users: [] }];
        }
        tempList[0].users = setUserSettings();
        tmpObj = {
          ...(projectProfile?.purchase_settings || {}),
          grn_approvers: tempList,
        };
        break;
      case PROJECT_APPROVER_CHOICES.INDENT:
        tempList = projectProfile?.purchase_settings?.indent_approvers || [];
        if (!tempList || !tempList.length) {
          tempList = [{ users: [] }];
        }
        tempList[0].users = setUserSettings();
        tmpObj = {
          ...(projectProfile?.purchase_settings || {}),
          indent_approvers: tempList,
        };
        break;
      case PROJECT_APPROVER_CHOICES.MI_SC:
        tempList = projectProfile?.purchase_settings?.mi_approvers || [];
        if (!tempList || !tempList.length) {
          tempList = [{ users: [] }];
        }
        tempList[0].users = setUserSettings();
        tmpObj = {
          ...(projectProfile?.purchase_settings || {}),
          mi_approvers: tempList,
        };
        break;
      case PROJECT_APPROVER_CHOICES.MI_ST:
        tempList = projectProfile?.purchase_settings?.mi_st_approvers || [];
        if (!tempList || !tempList.length) {
          tempList = [{ users: [] }];
        }
        tempList[0].users = setUserSettings();
        tmpObj = {
          ...(projectProfile?.purchase_settings || {}),
          mi_st_approvers: tempList,
        };
        break;
      case 'boq':
        tempList = projectProfile?.finance_settings?.boq_approvers || [];
        if (!tempList || !tempList.length) {
          tempList = [{ users: [] }];
        }
        tempList[0].users = setUserSettings();
        tmpObj = {
          ...(projectProfile?.finance_settings || {}),
          boq_approvers: tempList,
        };
        break;
      case PROJECT_APPROVER_CHOICES.DRIVE:
        tempList = projectProfile?.drive_settings?.drive_approvers || [];
        if (!tempList || !tempList.length) {
          tempList = [{ users: [] }];
        }
        tempList[0].users = setUserSettings();
        tmpObj = {
          drive_approvers: tempList,
        };
        break;
    }
    if (choice === 'boq') {
      dispatch(updateFinanceSettings(tmpObj));
    } else if (choice === PROJECT_APPROVER_CHOICES.DRIVE) {
      dispatch(updateDriveSettings(tmpObj));
    } else {
      dispatch(updatePurchaseSettings(tmpObj));
    }
    onHide();
  };

  const title = (choice: string) => {
    switch (choice) {
      case PROJECT_APPROVER_CHOICES.PO:
        return 'Purchase Order';
      case PROJECT_APPROVER_CHOICES.GRN:
        return 'GRN Inwarding';
      case PROJECT_APPROVER_CHOICES.INDENT:
        return PROJECT_APPROVER_CHOICES.INDENT;
      case PROJECT_APPROVER_CHOICES.BOQ:
        return 'BOQ';
      case PROJECT_APPROVER_CHOICES.MI_SC:
        return 'Material Issue Subcontractor';
      case PROJECT_APPROVER_CHOICES.MI_ST:
        return 'Material Issue Site Transfer';
      case PROJECT_APPROVER_CHOICES.DRIVE:
        return 'Drive Module';
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={show} onClose={onHide}>
      <CustomDialogTitle onClose={onHide}>{`Add Approver`}</CustomDialogTitle>
      <CustomDialogContent>
        <>
          <Box className="fw-bold text-black text-capitalize" mb={3}>
            {title(choice)}
          </Box>
          <Autocomplete
            fullWidth
            options={memberOptions || []}
            getOptionLabel={op => op?.label || ''}
            onChange={handleUserAdd}
            value={null}
            size="small"
            popupIcon={null}
            inputValue={searchVal}
            onInputChange={(e: any) => setSearchVal(e?.target?.value || '')}
            onOpen={() => dispatch(getProjUsers({ project_id: projectId }))}
            renderInput={(params: any) => (
              <CustomTextField
                {...params}
                fullWidth
                placeholder={'Search Members'}
                variant="outlined"
              />
            )}
          />
          <div className="d-flex flex-wrap mt-2 mx-n2">
            {users?.map((u, idx) => (
              <Box
                display="flex"
                key={u.value}
                p={'0.375em 0.75em'}
                bgcolor={variables.blueGrey100}
                borderRadius="0.3empx"
                alignItems="center"
                className="m-2"
              >
                <ProfileImage
                  user={{ ...u, displayName: u.label }}
                  size="small"
                  className="fes-m"
                />
                <div className="fes-m mr-1">{u.label}</div>
                <Action
                  resource_id={TEAM_MODULES.PROJECT_PROFILE.resource_id}
                  action={ACTION_NAME.EDIT}
                >
                  <CloseIcon
                    className="delete-red hand-pointer"
                    fontSize="small"
                    onClick={handleRemove(idx)}
                  />
                </Action>
              </Box>
            ))}
          </div>
        </>
      </CustomDialogContent>
      <CustomDialogActions leftAlign>
        <CustomButton
          onClick={handleSubmit}
          uppercase
          buttonSize="small"
          className="px-2"
          classes={{ root: 'typography-base fw-bold text-white' }}
        />
      </CustomDialogActions>
    </Dialog>
  );
}
export default AddApproverDialog;
