import { Dispatch } from 'redux';

import {
  ORG_API_ERROR,
  ORG_API_PENDING,
  ORG_API_SUCCESS,
  GET_ORG_MEMBERS_PENDING,
  GET_ORG_MEMBERS,
  GET_ORG_TEAMS,
  GET_ORG_TEAMS_PENDING,
  GET_ORG_PROFILE,
  CREATE_ORG_PROFILE,
  UPDATE_ORG_PROFILE,
  ADD_ORG_TEAM,
  SET_SELECTED_TEAM,
  UPDATE_ORG_TEAM,
  GET_TEAM_TEMPLATES,
  UPDATE_TEAM_USER,
  DELETE_TEAM_USER,
  GET_ORG_PKG_LIST,
  DELETE_ORG_USER,
  GET_ORG_VENDOR_LIST,
  SET_QUESTIONNAIRE,
  SET_ORG_INFO_PENDING,
  SET_TEAM_MEMBERS,
  GET_PURCHASE_ORDER_SEQUENCE,
  SET_ORG_EXPERIMENTS,
  GET_INVENTORIES_BY_ORG_ID,
  GET_ORG_ADMINS,
  GET_ORG_MEMBERS_PAGINATED,
  DELETE_ORG_WORKER_PENDING,
} from './Organisation.type';
import {
  toastSuccessMessage,
  toastErrorMessage,
  toastLoadingMessage,
} from '../../../../utils/Toast.util';
import { getProjUsers } from '../project';
import {
  INVITE_CONSTANT,
  ORG_SEGMENT,
  SEGMENTS_USER_PERMISSION,
  SEGMENT_TEAM_ACTIONS,
} from '../../../../constant/segment_constant';
import { track } from '../Segment.action';
import { getSelfPermission, getUserOrgs } from '../user';
import { METHOD_NOT_ALLOWED } from '../../../../constant/status_constants';
import {
  TEAM_APIS,
  ORG_APIS,
  API_VERSIONS,
  CHECKLIST_APIS,
  INVENTORY_APIS,
} from '../../../../routes.constant';
import TeamProps, {
  ChangeTeamProps,
  GetMembersParamsProps,
  GetMembersWithPaginationParamsProps,
  getOrgPackagesListParamsProps,
  GetOrgVendorListParamsProps,
  GetTeamsParamsProps,
  InviteOrgTeamParams,
  InviteTeamProps,
  ResendInviteProps,
  UpdateTeamUsersProps,
} from '../../../../interfaces/Team';
import OrgProfileProps, {
  OrgExpenseSettingsProps,
  GetExperimentProps,
  GstinProps,
  NewChecklistItemProps,
  DeleteOrgWorkerProps,
} from '../../../../interfaces/Organisation';
import { CallbackConfigProps } from '../../../../interfaces/GenericReducerAction';
import { parseString } from '../../../../utils/Common.util';
import { GetUserOrgParamsProps } from '../../../../interfaces/User';
import { errorDispatch, eventDispatch } from '../../../base';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../../../apiClient';
import { PurchaseOrderSequenceProps } from '../../../../interfaces/PurchaseOrder';
import {
  SYNC_BOM_PROFILE_VIEW,
  SYNC_GROUP_MATERIAL_VIEW,
  SYNC_INVENTORY_VIEW,
  SYNC_MEMBERS_VIEW,
  SYNC_ORGANIZATION_PROFILE_VIEW,
} from '../refresh/Refresh.type';
import { getInventoryHistory } from '../../material/inventory/Inventory.action';
import { customStringify } from '../../../../utils/Url.util';
import { RecordAccess, WORKER_ITEM_TYPE } from '../../../../constants';
import { RESOURCE_ID_MAP } from '../../../../constant/module_constants';
import LocalStorageUtil from '../../../../utils/LocalStorage.util';

export function setSelectedTeam(team: TeamProps | null) {
  return (dispatch: Dispatch) => {
    dispatch(eventDispatch(SET_SELECTED_TEAM, team));
  };
}

export function setOrgPackagesList(data: any) {
  return (dispatch: Dispatch) => {
    dispatch(eventDispatch(ORG_API_PENDING));
    dispatch(eventDispatch(GET_ORG_PKG_LIST, data));
  };
}

export function getMembers(params: GetMembersParamsProps) {
  const apiUrl = parseString(TEAM_APIS.getMembers, customStringify(params));

  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_ORG_MEMBERS_PENDING));

    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_ORG_MEMBERS, data));
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
    }
  };
}

export function getMembersWithPagination(
  params: GetMembersWithPaginationParamsProps
) {
  const apiUrl = parseString(
    TEAM_APIS.getMembersWithPagination,
    customStringify(params)
  );

  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_ORG_MEMBERS_PENDING));

    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_ORG_MEMBERS_PAGINATED, data));
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
    }
  };
}

export function getTeamMembers(params: GetMembersParamsProps) {
  const apiUrl = parseString(TEAM_APIS.getMembers, customStringify(params));

  return async (dispatch: Dispatch) => {
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(SET_TEAM_MEMBERS, data));
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
    }
  };
}

export function getTeams(params: GetTeamsParamsProps) {
  const apiUrl = parseString(TEAM_APIS.getTeams, customStringify(params));

  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_ORG_TEAMS_PENDING));
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_ORG_TEAMS, data));
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
    }
  };
}

export function getTeamById(teamId: string) {
  const apiUrl = parseString(TEAM_APIS.getTeamById, teamId);
  return async (dispatch: Dispatch<any>) => {
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(SET_SELECTED_TEAM, data));
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
    }
  };
}

export function createTeam(
  reqBody: TeamProps,
  callback: (data: any) => void = () => {}
) {
  const apiUrl = TEAM_APIS.createTeam;

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(ORG_API_PENDING));

    try {
      const webAccess = reqBody.web_access;

      webAccess.forEach(team => {
        const value = team.resource_id !== RESOURCE_ID_MAP.ORG_PROFILE;

        team.access_type = value
          ? RecordAccess.ALL_ACCESS.value
          : RecordAccess.NO_ACCESS.value;

        team.permission_type.forEach(it => {
          it.permission_value = value;
        });
      });

      const { data } = await postRequest(apiUrl, reqBody);
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(eventDispatch(ADD_ORG_TEAM, data));
      dispatch(
        track(SEGMENTS_USER_PERMISSION.USER_PERMISSION_NEW_ROLE_SUCCESS, {
          role_created: reqBody.team_name,
          role_desc: reqBody.description,
        })
      );
      callback(data);
      toastSuccessMessage('Successfully created Role');
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
      toastErrorMessage('Error in creating Role');
    }
  };
}

export function deleteTeam(teamId: string, cb?: Function) {
  const apiUrl = parseString(TEAM_APIS.deleteTeam, teamId);

  return async (dispatch: Dispatch<any>, getState: any) => {
    const orgId = getState().organisationReducer.orgProfile._id;
    dispatch(eventDispatch(ORG_API_PENDING));

    try {
      await deleteRequest(apiUrl);
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(getTeams({ org_id: orgId }));
      toastSuccessMessage('Successfully deleted role');
      cb?.(null);
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
      toastErrorMessage('Error in deleting Role');
      cb?.(err);
    }
  };
}

export function getExperiments({
  org_id = '',
  project_id = '',
  user_id = '',
}: GetExperimentProps) {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userId = user_id || getState().userreducer.user_id || '';
      const projectId =
        project_id || getState().projectreducer.selectedProj.id || '';
      const orgId =
        org_id || getState().organisationReducer.orgProfile._id || '';

      const apiUrl = parseString(
        ORG_APIS.getConfigAndExperiments,
        customStringify({
          id: `${orgId}, ${projectId}, ${userId}`,
          org_id: orgId,
        })
      );

      const { data } = await getRequest(apiUrl, API_VERSIONS.V2);
      const { experiments, is_paid } = data;

      dispatch(eventDispatch(SET_ORG_EXPERIMENTS, experiments));

      LocalStorageUtil.setLocalStorageValue('org_id', orgId, false);
      LocalStorageUtil.setLocalStorageValue('is_paid', is_paid, false);
    } catch (err: any) {
      toastErrorMessage(err?.response?.data?.message || 'Something went wrong');
    }
  };
}

export function getOrgProfile(orgId: string, noToast = false) {
  const apiUrl = parseString(ORG_APIS.getOrgProfile, orgId);

  return async (dispatch: Dispatch<any>) => {
    if (!noToast) {
      dispatch(eventDispatch(ORG_API_PENDING));
    }

    try {
      const { data } = await getRequest(apiUrl);

      dispatch(getExperiments({ org_id: orgId }));

      if (!noToast) {
        dispatch(eventDispatch(ORG_API_SUCCESS));
      }

      dispatch(eventDispatch(GET_ORG_PROFILE, data));
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
    }
  };
}

export function createOrgProfile(reqBody: OrgProfileProps, userId?: string) {
  const apiUrl = ORG_APIS.createOrgProfile;

  return async (dispatch: Dispatch<any>, getState: any) => {
    const orgId = getState().organisationReducer.orgProfile._id;
    dispatch(eventDispatch(ORG_API_PENDING));

    try {
      const { data } = await postRequest(apiUrl, reqBody);
      dispatch(eventDispatch(CREATE_ORG_PROFILE, data));
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(getOrgProfile(orgId));
      toastSuccessMessage('Successfully created organisation profile');
      dispatch(getUserOrgs({ user_id: userId } as GetUserOrgParamsProps));
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
      toastErrorMessage('Some error while creating organisation profile');
    }
  };
}

export function updateOrgProfile(
  orgId: string,
  reqBody: OrgProfileProps,
  userId?: string
) {
  const apiUrl = parseString(ORG_APIS.updateOrg, orgId);
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(ORG_API_PENDING));
    const toastId = toastLoadingMessage(`Updating ${reqBody.org_name}`);
    try {
      const { data } = await putRequest(apiUrl, reqBody);
      dispatch(eventDispatch(UPDATE_ORG_PROFILE, data));
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(getOrgProfile(orgId));
      toastSuccessMessage(`${reqBody.org_name} successfully updated`, toastId);
      if (userId) {
        dispatch(getUserOrgs({ user_id: userId }));
      }
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
      toastErrorMessage(`Cannot update ${reqBody.org_name}`, toastId);
    }
  };
}

export function updateOrgExpenseSettings(
  orgId: string,
  reqBody: OrgExpenseSettingsProps,
  userId?: string
) {
  const apiUrl = parseString(ORG_APIS.updateOrgExpenseSettings, orgId);
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(ORG_API_PENDING));
    const toastId = toastLoadingMessage(`Updating ${orgId}`);
    try {
      const { data } = await putRequest(apiUrl, reqBody);
      dispatch(eventDispatch(UPDATE_ORG_PROFILE, data));
      dispatch(eventDispatch(ORG_API_SUCCESS));
      toastSuccessMessage(`${data.org_name} successfully updated`, toastId);
      if (userId) {
        dispatch(getUserOrgs({ user_id: userId }));
      }
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
      toastErrorMessage(`Cannot update`, toastId);
    }
  };
}
export function updateTeam(teamId: string, reqBody: TeamProps) {
  const apiUrl = parseString(TEAM_APIS.updateTeam, teamId);

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(ORG_API_PENDING));

    try {
      const { data } = await putRequest(apiUrl, reqBody);
      dispatch(eventDispatch(UPDATE_ORG_TEAM, data));
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(getTeams({ org_id: reqBody.org_id }));
      dispatch(getSelfPermission());
      toastSuccessMessage('Successfully updated Role');
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
      toastErrorMessage('Some error while updating Role');
    }
  };
}

export function inviteTeam(
  reqBody: InviteTeamProps,
  singleTeam: boolean = true
) {
  const apiUrl = TEAM_APIS.inviteTeam;

  return async (dispatch: Dispatch<any>, getState: any) => {
    const orgId = getState().organisationReducer.orgProfile._id;
    const projectId = getState().projectreducer.selectedProj.id;

    dispatch(eventDispatch(ORG_API_PENDING));

    try {
      const { data } = await putRequest(apiUrl, reqBody);

      dispatch(track(ORG_SEGMENT.ORG_INVITE_SUCCESS));
      dispatch(
        track(
          SEGMENTS_USER_PERMISSION.USER_PERMISSION_ROLE_PERMISSION_ASSIGN_MEMBER_SUCCESS,
          { data }
        )
      );
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(getTeams({ org_id: orgId }));
      if (singleTeam) {
        const teamId = getState().organisationReducer.selectedTeam._id;
        dispatch(getTeamMembers({ team_id: teamId }));
      } else {
        dispatch(getMembers({ org_id: orgId }));
      }
      if (projectId) {
        dispatch(getProjUsers({ project_id: projectId }));
      }

      dispatch(
        track(SEGMENT_TEAM_ACTIONS.INVITE_TEAM, {
          ...reqBody,
          singleTeam,
        })
      );

      if (data.invitesError.length === 0) {
        toastSuccessMessage('Invite sent successfully');
      } else {
        toastErrorMessage('No invites were sent becuase of duplicate emails!');
      }
    } catch (err) {
      dispatch(track(ORG_SEGMENT.ORG_INVITE_ERROR));
      dispatch(eventDispatch(ORG_API_ERROR, err));
      toastErrorMessage(
        err?.response?.data?.message || 'Error in inviting Members'
      );
    }
  };
}

export function getTemplates() {
  const apiUrl = TEAM_APIS.getTemplates;

  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(ORG_API_PENDING));

    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(eventDispatch(GET_TEAM_TEMPLATES, data));
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
    }
  };
}

export function updateTeamUser(reqBody: UpdateTeamUsersProps) {
  const apiUrl = TEAM_APIS.updateTeamUser;

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(ORG_API_PENDING));

    try {
      const { data } = await putRequest(apiUrl, reqBody);
      dispatch(eventDispatch(UPDATE_TEAM_USER, data));
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(eventDispatch(SYNC_MEMBERS_VIEW, true));
      toastSuccessMessage('Successfully updated User');
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
      toastErrorMessage('Some error while updating User');
    }
  };
}

export function deleteTeamUser(teamId: string, userId: string) {
  const apiUrl = parseString(TEAM_APIS.deleteTeamUser, teamId, userId);

  return async (dispatch: Dispatch<any>, getState: any) => {
    const team = getState().organisationReducer.selectedTeam;
    dispatch(eventDispatch(ORG_API_PENDING));

    try {
      const res = await deleteRequest(apiUrl);
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(eventDispatch(DELETE_TEAM_USER, res));
      dispatch(getTeamMembers({ team_id: team._id }));
      toastSuccessMessage('Successfully revoked role accesses of the user');
    } catch (err: any) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
      if (err.response.status === METHOD_NOT_ALLOWED) {
        toastErrorMessage(
          err?.response?.data?.message || 'Something went wrong'
        );
      } else {
        toastErrorMessage('Error in revoking role accesses of the user');
      }
    }
  };
}

export function getOrgPackagesList(params: getOrgPackagesListParamsProps) {
  const apiUrl = parseString(ORG_APIS.packagesList, customStringify(params));

  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(ORG_API_PENDING));

    try {
      const res = await getRequest(apiUrl);
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(eventDispatch(GET_ORG_PKG_LIST, res));
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
    }
  };
}

export function getOrgVendorList(params: GetOrgVendorListParamsProps) {
  const apiUrl = parseString(ORG_APIS.vendorList, customStringify(params));

  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(ORG_API_PENDING));
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(eventDispatch(GET_ORG_VENDOR_LIST, data));
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
    }
  };
}

export function deleteOrgUser(orgId: string, userId: string, cb?: Function) {
  const apiUrl = parseString(ORG_APIS.deleteOrgUser, orgId, userId);

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(ORG_API_PENDING));
    try {
      const res = await deleteRequest(apiUrl);
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(eventDispatch(DELETE_ORG_USER, res));
      dispatch(getMembers({ org_id: orgId }));
      cb?.();

      dispatch(
        track(
          SEGMENTS_USER_PERMISSION.USER_PERMISSION_ASSIGNED_USER_REMOVE_SUCCESS,
          { removed_assigned_user: userId }
        )
      );
      toastSuccessMessage('Successfully Removed User from Organisation');
    } catch (err: any) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
      if (err.response.status === METHOD_NOT_ALLOWED) {
        toastErrorMessage(
          err?.response?.data?.message || 'Something went wrong'
        );
      } else {
        toastErrorMessage('Error in removing User from Organisation');
      }
    }
  };
}

export function resendInvite(reqBody: ResendInviteProps) {
  const apiUrl = TEAM_APIS.resendInvite;

  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(ORG_API_PENDING));
    const toastId = toastLoadingMessage('Sending invite');

    try {
      await postRequest(apiUrl, reqBody);
      dispatch(eventDispatch(ORG_API_SUCCESS));
      toastSuccessMessage('Invite sent successfully', toastId);
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
      toastErrorMessage('Error in resending Invite', toastId);
    }
  };
}

export function changeTeam(reqBody: ChangeTeamProps, curTeamId: string) {
  const apiUrl = ORG_APIS.changeTeam;

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(ORG_API_PENDING));
    try {
      await putRequest(apiUrl, reqBody);

      dispatch(
        track(SEGMENT_TEAM_ACTIONS.CHANGE_USER_TEAM, {
          ...reqBody,
          currTeam: curTeamId,
        })
      );

      dispatch(eventDispatch(SYNC_MEMBERS_VIEW, true));
      dispatch(getSelfPermission());
      dispatch(eventDispatch(ORG_API_SUCCESS));
      toastSuccessMessage('Team Changed Successfully');
    } catch (err: any) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
      if (err.response.status === METHOD_NOT_ALLOWED) {
        toastErrorMessage(
          err?.response?.data?.message || 'Something went wrong'
        );
      } else {
        toastErrorMessage('Error in Changing Team');
      }
    }
  };
}

export function changeBulkTeam(reqBody: any) {
  const apiUrl = ORG_APIS.changeBulkTeam;
  return async dispatch => {
    try {
      await putRequest(apiUrl, reqBody, API_VERSIONS.V2);
      dispatch(eventDispatch(SYNC_MEMBERS_VIEW, true));
      dispatch(getSelfPermission());
      dispatch(eventDispatch(SET_TEAM_MEMBERS, []));
      toastSuccessMessage('Team Changed Successfully');
    } catch (err) {
      toastErrorMessage(
        err?.response?.data?.message || 'Error in Changing Team'
      );
    }
  };
}

export function updateOrgGST(
  orgId: string,
  reqBody: { gstinArr: GstinProps[] }
) {
  const apiUrl = parseString(ORG_APIS.updateOrgGST, orgId);

  return async (dispatch: Dispatch<any>) => {
    try {
      const { data } = await putRequest(apiUrl, reqBody);
      toastSuccessMessage('Successfully updated organisation gst details');
      dispatch(eventDispatch(GET_ORG_PROFILE, data));
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
      toastErrorMessage('Some error while updating organisation gst details');
    }
  };
}

export function getQuestionnaire() {
  const apiUrl = ORG_APIS.getQuestionnaire;

  return async (dispatch: Dispatch) => {
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(SET_QUESTIONNAIRE, data));
    } catch (err) {
      toastErrorMessage(`Something bad Happend.`);
    }
  };
}

export function updateQuestionnaireInfo(orgId: string, questionnaireInfo: any) {
  const apiUrl = parseString(ORG_APIS.updateQuestionnaireInfo, orgId);

  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(eventDispatch(SET_ORG_INFO_PENDING, true));
      const { data } = await putRequest(apiUrl, questionnaireInfo);

      if (data) {
        toastSuccessMessage(`Free Trial Activated`);
        dispatch(getOrgProfile(orgId));
        dispatch(eventDispatch(SET_ORG_INFO_PENDING, false));
      }
    } catch (err: any) {
      toastErrorMessage(err?.response?.data?.message || 'Something went wrong');
    }
  };
}

export function getPOSequenceNumber(orgId: string) {
  const apiUrl = parseString(ORG_APIS.getPOSequence, orgId);
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(GET_PURCHASE_ORDER_SEQUENCE, data));
    } catch {
      toastErrorMessage(
        'Some Error happend while fetching Purchase Order Sequence'
      );
    }
  };
}

export function updatePOSequenceNumber(
  orgId: string,
  reqBody: PurchaseOrderSequenceProps
) {
  const apiUrl = parseString(ORG_APIS.getPOSequence, orgId);
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage('Updating PO Sequence Number');
    try {
      await postRequest(apiUrl, reqBody, API_VERSIONS.V1);
      dispatch(getPOSequenceNumber(orgId));
      toastSuccessMessage('PO Sequence Number updated successfully', toastId);
    } catch (error: any) {
      toastErrorMessage(
        error.response.data.message || 'Cannot update PO Sequence Number',
        toastId
      );
    }
  };
}

export function sendOrgTeamInvites(
  reqBody: InviteOrgTeamParams,
  orgId: string
) {
  const apiUrl = parseString(ORG_APIS.orgTeamInvites, orgId);
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage('Inviting Members');
    try {
      await postRequest(apiUrl, reqBody, API_VERSIONS.V1);
      dispatch(eventDispatch(SYNC_MEMBERS_VIEW, true));
      dispatch(
        track(INVITE_CONSTANT.ORG_INVITE_MEMBER_SUCCESS, {
          org_id: orgId,
          ...reqBody,
        })
      );
      toastSuccessMessage('Invite Sent Successfully', toastId);
    } catch {
      toastErrorMessage('Cannot send Invites', toastId);
    }
  };
}

export function sendInterestedPayingOrgDetails(orgId: string) {
  const apiUrl = parseString(ORG_APIS.sendInterestedPayingOrgDetails, orgId);

  return async (dispatch: Dispatch<any>) => {
    try {
      await postRequest(apiUrl);
      dispatch(getOrgProfile(orgId));
      toastSuccessMessage('We will contact you shortly');
    } catch {
      toastErrorMessage('Something went wrong');
    }
  };
}

export const getInventoriesByOrgId =
  (orgId: string, page: number, limit: number, filters = {}) =>
  async (dispatch: Dispatch) => {
    dispatch(eventDispatch(ORG_API_PENDING));
    try {
      const apiUrl = parseString(
        ORG_APIS.getInventoriesByOrgId,
        orgId,
        customStringify({ page, limit, dFilter: JSON.stringify(filters) })
      );

      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_INVENTORIES_BY_ORG_ID, data));
      dispatch(eventDispatch(ORG_API_SUCCESS));
    } catch {
      dispatch(eventDispatch(ORG_API_ERROR));
      toastErrorMessage('Error!');
    }
  };

export const updateInventory =
  (invId: string, reqBody: any) => async dispatch => {
    if (!invId) {
      return;
    }

    const { org_id: orgId } = reqBody;
    try {
      const apiUrl = parseString(INVENTORY_APIS.updateInventory, invId);
      await putRequest(apiUrl, reqBody);
      dispatch(getInventoryHistory({ id: invId, orgId }));
      dispatch(eventDispatch(SYNC_INVENTORY_VIEW, true));
      dispatch(eventDispatch(SYNC_BOM_PROFILE_VIEW, true));
      dispatch(eventDispatch(SYNC_GROUP_MATERIAL_VIEW, true));
    } catch (err) {
      toastErrorMessage(`Error!: ${err.errorMsg}`);
    }
  };

export const updateOrgProjectStages =
  (orgId: string, stages: OrgProfileProps['project_stages']) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const apiUrl = parseString(ORG_APIS.updateOrgProjectStages, orgId);

      await putRequest(apiUrl, stages);
      toastSuccessMessage('Stages updated successfully');
      dispatch(getOrgProfile(orgId, true));
    } catch {
      toastErrorMessage('Error!');
    }
  };

export const createNewChecklistItem =
  (reqBody: NewChecklistItemProps, cb?: Function) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(ORG_API_PENDING));
    try {
      await postRequest(CHECKLIST_APIS.newChecklistItem, reqBody);
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(eventDispatch(SYNC_ORGANIZATION_PROFILE_VIEW, true));
      cb?.();
    } catch (err) {
      dispatch(
        track('checklist_create_failure', {
          err,
          message: err?.response?.data?.message,
        })
      );
      dispatch(
        errorDispatch(ORG_API_ERROR, err?.response?.data?.message || '')
      );
    }
  };

export const updateChecklistItem =
  (id: string, label: string, cb?: Function) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(ORG_API_PENDING));
    const apiUrl = parseString(CHECKLIST_APIS.updateChecklistItem, id);

    try {
      await putRequest(apiUrl, { label });
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(eventDispatch(SYNC_ORGANIZATION_PROFILE_VIEW, true));
      cb?.();
    } catch (err) {
      dispatch(
        track('checklist_update_failure', {
          err,
          message: err?.response?.data?.message,
        })
      );
      dispatch(
        errorDispatch(ORG_API_ERROR, err?.response?.data?.message || '')
      );
    }
  };

export const deleteChecklistItem =
  (id: string, cb?: Function) => async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(ORG_API_PENDING));
    const apiUrl = parseString(CHECKLIST_APIS.deleteItem, id);
    try {
      await deleteRequest(apiUrl);
      dispatch(eventDispatch(ORG_API_SUCCESS));
      dispatch(eventDispatch(SYNC_ORGANIZATION_PROFILE_VIEW, true));
      cb?.();
    } catch (err) {
      dispatch(
        track('checklist_delete_failure', {
          err,
          message: err?.response?.data?.message,
        })
      );
      dispatch(
        errorDispatch(ORG_API_ERROR, err?.response?.data?.message || '')
      );
    }
  };

export function getOrgAdmins(orgId: string) {
  const apiUrl = parseString(TEAM_APIS.getOrgAdmins, orgId);

  return async (dispatch: Dispatch<any>) => {
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_ORG_ADMINS, data));
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
    }
  };
}

export const sendAccessRequestEmail = (receiverId, action, resourceId) => {
  return async (dispatch: Dispatch<any>) => {
    const tId = toastLoadingMessage('Sending request...');
    try {
      await postRequest(TEAM_APIS.sendAccessRequestEmail, {
        receiver_id: receiverId,
        resource_id: resourceId,
        action: action,
      });

      toastSuccessMessage('Request sent', tId);
    } catch (err) {
      dispatch(eventDispatch(ORG_API_ERROR, err));
      toastErrorMessage(err.message, tId);
    }
  };
};

export const updateExpMetadata = (expName, updateBody) => async () => {
  const { item_id, meta_data } = updateBody;
  const url = parseString(ORG_APIS.updateExpMetadata, expName);

  await putRequest(url, {
    item_id,
    meta_data,
  });
};

export function deleteOrgWorker(
  params: DeleteOrgWorkerProps,
  cbs?: CallbackConfigProps
) {
  const apiUrl = parseString(ORG_APIS.deleteWorkers, customStringify(params));
  const workerTypeTxt =
    params?.worker_type === WORKER_ITEM_TYPE.LABOUR ? 'labour' : 'vendor';

  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage(`Deleting ${workerTypeTxt}...`);
    dispatch(eventDispatch(DELETE_ORG_WORKER_PENDING, true));
    try {
      await deleteRequest(apiUrl);
      toastSuccessMessage(`Successfully deleted ${workerTypeTxt}`, toastId);
      cbs?.on_success_cb?.();
    } catch (err) {
      toastErrorMessage(`Error in deleting ${workerTypeTxt}`, toastId);
      cbs?.on_error_cb?.();
    } finally {
      dispatch(eventDispatch(DELETE_ORG_WORKER_PENDING, false));
      cbs?.after_api_end_cb?.();
    }
  };
}
