import {
  SW_INIT,
  UPDATE_APP_VERSION,
  SHOW_UPDATE_ALERT,
} from './ServiceWorker.type';

const initialState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  webAppVersion: 0,
  showUpdateAlerts: false,
};

export default function serviceWorkerReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case UPDATE_APP_VERSION:
      return {
        ...state,
        webAppVersion: payload.version_code,
      };
    case SW_INIT:
      return { ...state, serviceWorkerInitialized: true };
    case SHOW_UPDATE_ALERT:
      return { ...state, showUpdateAlerts: payload };
    default:
      return state;
  }
}
