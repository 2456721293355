import { useCallback, useState } from 'react';

import { Menu, MenuItem, MenuProps } from '@mui/material';
import { track } from '../../redux/features/common/Segment.action';
import { useAppDispatch } from '../../redux/Hooks';
import { useDebounceFunc } from '../../utils/Debounce.util';

interface Props extends Omit<MenuProps, 'open'> {
  onOpenEvent?: any;
  element: any;
  children?: any;
  closeMenuOnSelect?: boolean;
}

const CustomRootMenu = ({ onScroll = () => {}, ...props }: MenuProps) => {
  const debouncedOnScroll = useCallback(useDebounceFunc(onScroll, 500), [
    onScroll,
  ]);

  return (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          borderWidth: 0,
          borderRadius: 4,
          boxShadow:
            '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
          marginTop: 6,
        },
        onScroll: debouncedOnScroll,
      }}
      MenuListProps={{
        style: {
          padding: 0,
          maxHeight: 300,
        },
      }}
      {...props}
    />
  );
};

export const CustomMenuItem = (props: Props) => {
  const [isHover, setHover] = useState<boolean>(false);

  const handleMouseOver = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };
  return (
    <MenuItem
      onMouseOver={handleMouseOver as any}
      onMouseOut={handleMouseOut as any}
      style={{ backgroundColor: isHover ? '#DEEDF7' : '' }}
      {...(props as any)}
    >
      {props.children}
    </MenuItem>
  );
};

export const CustomMenu = ({
  children,
  element,
  onOpenEvent,
  closeMenuOnSelect,
  ...props
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    if (onOpenEvent) dispatch(track(onOpenEvent));
  };

  const handleClose = () => {
    (props as any)?.onClose?.();
    setAnchorEl(null);
  };

  return (
    <>
      <div
        onClick={handleClick}
        onTouchEnd={handleClick}
        aria-controls="customized-menu"
        aria-haspopup="true"
        className="hand-pointer"
      >
        {element}
      </div>
      <CustomRootMenu
        {...props}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={() => {
          if (closeMenuOnSelect) setAnchorEl(null);
        }}
      >
        {children}
      </CustomRootMenu>
    </>
  );
};
