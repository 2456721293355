import {
  ADD_VENDOR_ITEM,
  GET_VENDOR_ITEM,
  GET_VENDOR_ITEMS,
  UPDATE_VENDOR_ITEM,
  DELETE_VENDOR_ITEM,
  VENDOR_API_SUCCESS,
  VENDOR_API_ERROR,
  VENDOR_API_PENDING,
  VENDOR_ITEM_ADDED,
  GET_VENDOR_ITEMS_PENDING,
  GET_VENDOR_ITEM_PENDING,
  SET_VENDOR_ITEMS,
  SET_VENDOR_ITEMS_PAGINATION_DATA,
  ALL_PAYABLE_DATA,
  VENDOR_DELETE_API_PENDING,
  VENDOR_DELETE_API_SUCCESS,
  VENDOR_DELETE_API_ERROR,
  VENDOR_PROJECTS_API_ERROR,
  VENDOR_PROJECTS_API_PENDING,
  VENDOR_PROJECTS_API_SUCCESS,
  SET_VENDOR_PROJECTS,
  SET_VENDOR_PROJECTS_COUNT,
  SET_ORG_VENDOR_SELECTION_LIST,
  SET_ORG_VENDOR_SELECTION_LIST_COUNT,
  SET_VENDOR_PRICING_HISTORY_COUNT,
  SET_VENDOR_PRICING_HISTORY,
  SET_VENDOR_WITH_SAME_NAME_EXISTS_FLAG,
  VENDOR_CREATE_API_ERROR,
  VENDOR_CREATE_API_PENDING,
  VENDOR_CREATE_API_SUCCESS,
  VENDOR_EDIT_API_ERROR,
  VENDOR_EDIT_API_PENDING,
  VENDOR_EDIT_API_SUCCESS,
} from './Vendor.type';
import { ReducerProps } from '../../../interfaces/Base';
import { IVendorProjects } from '../../../modules/vendor/types/interface/Vendor.interface';
import { IVendorPricingHistory } from '../../../modules/vendor/types/models/PricingHistory.model';

const initialState = {
  deletePending: false as boolean,
  pending: false as boolean,
  vendorCreatePending: false as boolean,
  vendorEditPending: false as boolean,
  vendorProjectsPending: false as boolean,
  vendorProjectsCount: 0 as number,
  vendorProjects: [] as IVendorProjects[],
  vendorItems: [],
  error: null,
  vendorItemAdded: false,
  selectedVendor: null,
  addedItem: {},
  vendorItemsPending: false,
  vendorItemPending: false,
  vendorPaginationData: {
    docs: [],
    total: 0,
    pages: 0,
  },
  allPayableData: {},
  vendorPricingHistory: null as IVendorPricingHistory,
  vendorPricingHistoryCount: 0 as number,
  orgVendorSelectionList: null,
  orgVendorSelectionListCount: 0 as number,
  vendorSelectionList: [],
  vendorAlreadyExistsFlag: false as boolean,
};

function VendorReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
): any {
  switch (type) {
    case ADD_VENDOR_ITEM:
      return {
        ...state,
        vendorItems: [payload, ...state.vendorItems],
        addedItem: payload,
      };
    case GET_VENDOR_ITEMS_PENDING:
      return { ...state, vendorItemsPending: payload };
    case GET_VENDOR_ITEM_PENDING:
      return { ...state, vendorItemPending: payload };
    case GET_VENDOR_ITEM:
      return { ...state, selectedVendor: payload };
    case GET_VENDOR_ITEMS:
      return { ...state, vendorItems: payload };
    case UPDATE_VENDOR_ITEM:
      return { ...state, selectedVendor: payload };
    case DELETE_VENDOR_ITEM: {
      const newVendorItems = state.vendorItems.filter(
        (elem: any) => elem._id !== payload.id
      );
      return {
        ...state,
        vendorItems: newVendorItems,
      };
    }
    case VENDOR_ITEM_ADDED:
      return {
        ...state,
        vendorItemAdded: payload,
      };
    case VENDOR_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case VENDOR_API_PENDING:
      return {
        ...state,
        pending: true,
        vendorItemAdded: false,
        addedItem: {},
      };
    case VENDOR_DELETE_API_ERROR:
    case VENDOR_DELETE_API_SUCCESS:
      return {
        ...state,
        deletePending: false,
      };
    case VENDOR_DELETE_API_PENDING:
      return {
        ...state,
        deletePending: true,
      };

    case VENDOR_PROJECTS_API_ERROR:
    case VENDOR_PROJECTS_API_SUCCESS:
      return {
        ...state,
        vendorProjectsPending: false,
      };
    case VENDOR_PROJECTS_API_PENDING:
      return {
        ...state,
        vendorProjectsPending: true,
      };
    case VENDOR_CREATE_API_ERROR:
    case VENDOR_CREATE_API_SUCCESS:
      return {
        ...state,
        vendorCreatePending: false,
      };
    case VENDOR_CREATE_API_PENDING:
      return {
        ...state,
        vendorCreatePending: true,
      };
    case VENDOR_EDIT_API_ERROR:
    case VENDOR_EDIT_API_SUCCESS:
      return {
        ...state,
        vendorEditPending: false,
      };
    case VENDOR_EDIT_API_PENDING:
      return {
        ...state,
        vendorEditPending: true,
      };
    case SET_VENDOR_PROJECTS: {
      return {
        ...state,
        vendorProjects: payload,
      };
    }
    case SET_VENDOR_PROJECTS_COUNT: {
      return {
        ...state,
        vendorProjectsCount: payload.count,
      };
    }
    case SET_ORG_VENDOR_SELECTION_LIST: {
      return {
        ...state,
        orgVendorSelectionList: payload,
      };
    }
    case SET_ORG_VENDOR_SELECTION_LIST_COUNT: {
      return {
        ...state,
        orgVendorSelectionListCount: payload.total_count,
      };
    }
    case SET_VENDOR_PRICING_HISTORY: {
      return {
        ...state,
        vendorPricingHistory: payload,
      };
    }
    case SET_VENDOR_PRICING_HISTORY_COUNT: {
      return {
        ...state,
        vendorPricingHistoryCount: payload,
      };
    }
    case SET_VENDOR_WITH_SAME_NAME_EXISTS_FLAG: {
      return { ...state, vendorAlreadyExistsFlag: payload };
    }
    case VENDOR_API_ERROR:
      return {
        ...state,
        pending: false,
        error: error.response.data.message,
      };
    case SET_VENDOR_ITEMS:
      return {
        ...state,
        vendorPaginationData: { ...state.vendorPaginationData, docs: payload },
      };
    case SET_VENDOR_ITEMS_PAGINATION_DATA:
      return {
        ...state,
        vendorPaginationData: { ...state.vendorPaginationData, ...payload },
      };
    case ALL_PAYABLE_DATA:
      return {
        ...state,
        allPayableData: { ...state.allPayableData, ...payload },
      };
    default:
      return state;
  }
}

export default VendorReducer;
