import {
  GROUPED_MATERIAL_STATUS,
  GROUPED_MATERIAL_TYPE,
  MATERIAL_INVENTORY_DETAILS,
} from '../../../../constants';
import { BomProps } from '../../../../interfaces/Bom';
import {
  GET_GROUPED_MATERIALS,
  GET_INVENTORY_DETAILS,
  INVENTORY_API_ERROR,
  INVENTORY_API_PENDING,
  INVENTORY_API_SUCCESS,
  GET_GROUPED_MATERIAL,
  GET_EDIT_MATERIAL_INFO,
  SET_GROUP_MATERIAL_FORM,
  SET_GROUP_MATERIAL_FORM_ITEMS,
  SET_GROUP_MATERIAL_FORM_ERROR,
  SET_GROUP_MATERIAL_DESCRIPTION_ERROR,
  SET_GROUP_MATERIAL_FORM_DEFAULT,
  SET_PAGINATED_DATA_TOTAL_COUNT,
  SET_SELECTED_INVENTORY_TAB_VIEW_ID,
  GET_INVENTORY_INDENT_DETAILS,
  ADD_MATERIAL_INVENTORY,
} from './Inventory.type';

const initialPendingState = {
  getInventoryPending: false,
};
const defaultForm = {
  org_id: '',
  group_status: GROUPED_MATERIAL_STATUS.DONE,
  project_id: '',
  group_type: GROUPED_MATERIAL_TYPE.SAME_NAME,
  boms: [],
  items: [{}],
  name: '',
  tags: [],
  uom: '',
};

const initialState = {
  ...initialPendingState,
  pending: false,
  error: null,
  selectedInventoryItem: {},
  groupedMaterials: [],
  selectedGroupedItem: {},
  editMaterialInfo: {},
  groupMaterialForm: defaultForm,
  groupMaterialFormError: [],
  groupMaterialDescriptionError: [],
  paginatedGroupedMaterialTotalCount: 0,
  selectedInventoryTab: MATERIAL_INVENTORY_DETAILS.STOCK_LOGS,
  approvedInventoryIndent: [],
  newMaterialData: <BomProps>{},
};

export default function inventoryReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case GET_INVENTORY_DETAILS:
      return {
        ...state,
        selectedInventoryItem: payload,
      };
    case GET_GROUPED_MATERIALS:
      return {
        ...state,
        groupedMaterials: payload,
      };
    case GET_GROUPED_MATERIAL:
      return {
        ...state,
        selectedGroupedItem: payload,
      };
    case INVENTORY_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case GET_EDIT_MATERIAL_INFO:
      return {
        ...state,
        editMaterialInfo: payload,
      };
    case ADD_MATERIAL_INVENTORY:
      return {
        ...state,
        newMaterialData: payload || {},
      };
    case SET_GROUP_MATERIAL_FORM: {
      return {
        ...state,
        groupMaterialForm: payload,
      };
    }
    case SET_GROUP_MATERIAL_FORM_ITEMS: {
      return {
        ...state,
        groupMaterialForm: {
          ...state.groupMaterialForm,
          items: payload,
        },
      };
    }
    case SET_GROUP_MATERIAL_FORM_ERROR: {
      return {
        ...state,
        groupMaterialFormError: payload,
      };
    }
    case SET_GROUP_MATERIAL_FORM_DEFAULT: {
      return {
        ...state,
        groupMaterialForm: { ...defaultForm, items: [{}], boms: [] },
        editMaterialInfo: {},
      };
    }
    case SET_GROUP_MATERIAL_DESCRIPTION_ERROR: {
      return {
        ...state,
        groupMaterialDescriptionError: payload,
      };
    }
    case SET_PAGINATED_DATA_TOTAL_COUNT: {
      return {
        ...state,
        paginatedGroupedMaterialTotalCount: payload,
      };
    }
    case INVENTORY_API_PENDING:
      return {
        ...state,
        pending: true,
      };
    case INVENTORY_API_ERROR:
      return {
        ...state,
        pending: false,
        error: error?.response?.data?.message,
      };
    case SET_SELECTED_INVENTORY_TAB_VIEW_ID: {
      return {
        ...state,
        selectedInventoryTab: payload,
      };
    }
    case GET_INVENTORY_INDENT_DETAILS:
      return {
        ...state,
        approvedInventoryIndent: payload,
      };
    default:
      return state;
  }
}
