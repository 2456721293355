/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** DFilter (JSON Object) */
  DFilterObject: { input: any; output: any };
  /** Date is a custom scalar type that represents a date Object. */
  Date: { input: any; output: any };
  /** Epoch in milliseconds */
  Epoch: { input: any; output: any };
  /** Long custom scalar type */
  Long: { input: any; output: any };
  /** Number */
  Number: { input: any; output: any };
  limit_Int_NotNull_min_1: { input: any; output: any };
  limit_Int_NotNull_min_10: { input: any; output: any };
  limit_Int_NotNull_min_20_max_100: { input: any; output: any };
  page_Int_NotNull_min_0: { input: any; output: any };
};

export type AdditionalPocDetailProps = {
  __typename?: 'AdditionalPOCDetailProps';
  /** mobile number of the POC */
  mobile: Scalars['String']['output'];
  /** name of the POC */
  name: Scalars['String']['output'];
  /** title of the POC eg: "Site POC", "Project POC", etc */
  title: Scalars['String']['output'];
};

export type ApproverLevelSchemaProps = {
  __typename?: 'ApproverLevelSchemaProps';
  level: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  users: Array<ApproverSchemaProps>;
};

export type ApproverSchemaProps = {
  __typename?: 'ApproverSchemaProps';
  createdAt?: Maybe<Scalars['Epoch']['output']>;
  dateMS?: Maybe<Scalars['Epoch']['output']>;
  /** date when the approver approved/rejected the request */
  dateMs?: Maybe<Scalars['Long']['output']>;
  /** note given by the approver */
  note?: Maybe<Scalars['String']['output']>;
  /**
   * INITIAL   : 0
   *
   * APPROVED  : 1
   *
   * REJECTED  : 2
   *
   * CANCELLED : 3
   */
  state?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Epoch']['output']>;
  /** user id of the approver */
  user_id?: Maybe<Scalars['String']['output']>;
  /** user name of the approver */
  user_name?: Maybe<Scalars['String']['output']>;
  /** user profile url of the approver */
  user_profile_url?: Maybe<Scalars['String']['output']>;
};

export type CfTaskProps = {
  __typename?: 'CFTaskProps';
  _id: Scalars['String']['output'];
  ancestors?: Maybe<Array<Maybe<TaskAncestorsProps>>>;
  index_number?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

export type ChecklistItemProps = {
  __typename?: 'ChecklistItemProps';
  _id: Scalars['String']['output'];
  checklist_type: Scalars['Int']['output'];
  createdAt: Scalars['Epoch']['output'];
  creator_id: Scalars['String']['output'];
  creator_name: Scalars['String']['output'];
  entity_id: Scalars['String']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  parent_id?: Maybe<Scalars['String']['output']>;
  state: Scalars['Int']['output'];
  updatedAt: Scalars['Epoch']['output'];
  updater_id?: Maybe<Scalars['String']['output']>;
  updater_name?: Maybe<Scalars['String']['output']>;
};

export type ChecklistSchemaProps = {
  __typename?: 'ChecklistSchemaProps';
  /** checklist item */
  item: Scalars['String']['output'];
  /**
   * 0: unchecked
   *
   * 1: checked
   *
   * 2: rejected
   */
  state: Scalars['Int']['output'];
};

export type ConsumptionMiIssueItemProps = {
  __typename?: 'ConsumptionMIIssueItemProps';
  bom_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  inventory_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  task_list?: Maybe<Array<Maybe<MiTaskListProps>>>;
  uom?: Maybe<Scalars['String']['output']>;
  user_custom_task_list?: Maybe<Array<Maybe<MiCustomTaskListProps>>>;
};

export type ConsumptionMiProps = {
  __typename?: 'ConsumptionMIProps';
  ancestors?: Maybe<Scalars['String']['output']>;
  approvers?: Maybe<Array<Maybe<ApproverLevelSchemaProps>>>;
  checker_name?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<Maybe<FileProps>>>;
  issued_items?: Maybe<Array<Maybe<ConsumptionMiIssueItemProps>>>;
  task_name?: Maybe<Scalars['String']['output']>;
  vendor_id?: Maybe<Scalars['String']['output']>;
  vendor_name?: Maybe<Scalars['String']['output']>;
};

export type ConsumptionMaterialProps = {
  __typename?: 'ConsumptionMaterialProps';
  name: Scalars['String']['output'];
  quantity_str?: Maybe<Scalars['String']['output']>;
  specs?: Maybe<Scalars['String']['output']>;
  uoq?: Maybe<Scalars['String']['output']>;
};

export type ConsumptionProps = {
  __typename?: 'ConsumptionProps';
  _id?: Maybe<Scalars['String']['output']>;
  dateMS: Scalars['Epoch']['output'];
  files?: Maybe<Scalars['String']['output']>;
  item_display_name?: Maybe<Scalars['String']['output']>;
  item_type?: Maybe<Scalars['Int']['output']>;
  material?: Maybe<ConsumptionMaterialProps>;
  material_name?: Maybe<Scalars['String']['output']>;
  material_tags?: Maybe<Array<Maybe<Tag>>>;
  mi?: Maybe<ConsumptionMiProps>;
  project_id: Scalars['String']['output'];
  project_name: Scalars['String']['output'];
  remarks?: Maybe<Scalars['String']['output']>;
  specs?: Maybe<Scalars['String']['output']>;
  user_id: Scalars['String']['output'];
  user_name: Scalars['String']['output'];
};

export type DashboardRemarkMetaDataProps = {
  __typename?: 'DashboardRemarkMetaDataProps';
  edited_on?: Maybe<Scalars['String']['output']>;
  footer?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type DashboardRemarkProps = {
  __typename?: 'DashboardRemarkProps';
  _id: Scalars['String']['output'];
  createdAt: Scalars['Long']['output'];
  creator_id: Scalars['String']['output'];
  creator_name: Scalars['String']['output'];
  end_date?: Maybe<Scalars['Long']['output']>;
  files?: Maybe<Array<Maybe<FileProps>>>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  meta_data?: Maybe<DashboardRemarkMetaDataProps>;
  org_id: Scalars['String']['output'];
  project_id: Scalars['String']['output'];
  project_name?: Maybe<Scalars['String']['output']>;
  remark: Scalars['String']['output'];
  remark_type: Scalars['Int']['output'];
  start_date?: Maybe<Scalars['Long']['output']>;
  updatedAt: Scalars['Long']['output'];
  version?: Maybe<Scalars['Int']['output']>;
};

export type DateInputProps = {
  end?: InputMaybe<Scalars['Epoch']['input']>;
  start?: InputMaybe<Scalars['Epoch']['input']>;
};

export type FileProps = {
  __typename?: 'FileProps';
  /** mime type of the file */
  mime_type: Scalars['String']['output'];
  /** name of the file */
  name: Scalars['String']['output'];
  /** url of the file */
  url: Scalars['String']['output'];
};

export type FinanceSettingsPaymentProps = {
  __typename?: 'FinanceSettingsPaymentProps';
  /** amount of the payment */
  amount?: Maybe<Scalars['String']['output']>;
  /** time of the payment */
  time?: Maybe<Scalars['String']['output']>;
};

export type FinanceSettingsPaymentTermProps = {
  __typename?: 'FinanceSettingsPaymentTermProps';
  /** Finance Setting payment after billing */
  after_billing?: Maybe<Array<Maybe<FinanceSettingsPaymentProps>>>;
  /** payment at billing */
  at_billing?: Maybe<Scalars['Float']['output']>;
  /** payment before billing */
  before_billing?: Maybe<Scalars['Float']['output']>;
};

export type FinanceSettingsProjectDateProps = {
  __typename?: 'FinanceSettingsProjectDateProps';
  /** end date of the project in milliseconds */
  end_date?: Maybe<Scalars['Long']['output']>;
  /** start date of the project in milliseconds */
  start_date?: Maybe<Scalars['Long']['output']>;
};

export type FinanceSettingsProps = {
  __typename?: 'FinanceSettingsProps';
  /** boq approvers */
  boq_approvers?: Maybe<Array<Maybe<ApproverLevelSchemaProps>>>;
  /** contract_files */
  contract_files?: Maybe<Array<Maybe<FileProps>>>;
  /** payment terms */
  payment_terms?: Maybe<FinanceSettingsPaymentTermProps>;
  /** project dates */
  project_dates?: Maybe<FinanceSettingsProjectDateProps>;
  /** retention amount */
  retention_amount?: Maybe<FinanceSettingsPaymentProps>;
};

export type GeoLocationProps = {
  __typename?: 'GeoLocationProps';
  /** coordinates of the geo location: [lat, long] */
  coordinates: Array<Scalars['Float']['output']>;
  /**
   * POINT      : 'Point'
   *
   * LINESTRING : 'LineString'
   *
   * POLYGON    : 'Polygon'
   */
  type: Scalars['String']['output'];
};

export type GstInProps = {
  __typename?: 'GstInProps';
  /** address ex - 1498, 19th Main Road */
  address?: Maybe<Scalars['String']['output']>;
  /** GSTIN number ex - 27AABCC1234P1Z5 */
  id: Scalars['String']['output'];
  /** state ex- 'Maharashtra' */
  state?: Maybe<Scalars['String']['output']>;
};

export type IssuesItems = {
  __typename?: 'IssuesItems';
  bom_id: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  inventory_id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  quantity?: Maybe<Scalars['Float']['output']>;
  task_list?: Maybe<MiTask>;
  uom: Scalars['String']['output'];
};

export type LabourContactDetailsProps = {
  __typename?: 'LabourContactDetailsProps';
  contact_person_mobile_number?: Maybe<Scalars['String']['output']>;
  contact_person_name?: Maybe<Scalars['String']['output']>;
};

export type LabourProps = {
  __typename?: 'LabourProps';
  _id: Scalars['String']['output'];
  contact_detail?: Maybe<LabourContactDetailsProps>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  created_date: Scalars['Float']['output'];
  creator_id: Scalars['String']['output'];
  creator_name?: Maybe<Scalars['String']['output']>;
  detail_type?: Maybe<Scalars['Int']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
  hash_value: Scalars['String']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  labour_type?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  org_id: Scalars['String']['output'];
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  working_minutes?: Maybe<Scalars['Int']['output']>;
};

export type MiCustomTaskListProps = {
  __typename?: 'MICustomTaskListProps';
  quantity?: Maybe<Scalars['Number']['output']>;
  task_name: Scalars['String']['output'];
};

export type MiTask = {
  __typename?: 'MITask';
  quantity: Scalars['Float']['output'];
  task_id?: Maybe<Scalars['String']['output']>;
  task_name?: Maybe<Scalars['String']['output']>;
};

export type MiTaskListProps = {
  __typename?: 'MITaskListProps';
  quantity?: Maybe<Scalars['Number']['output']>;
  task_id: Scalars['String']['output'];
  task_name?: Maybe<Scalars['String']['output']>;
};

export type MvpProps = {
  __typename?: 'MVPProps';
  _id: Scalars['String']['output'];
  canBePaid?: Maybe<Scalars['Boolean']['output']>;
  delivery_date?: Maybe<Scalars['Epoch']['output']>;
  display_id?: Maybe<Scalars['String']['output']>;
  grn_adjustment?: Maybe<Scalars['Number']['output']>;
  grn_amount?: Maybe<Scalars['Number']['output']>;
  grn_files?: Maybe<Array<Maybe<FileProps>>>;
  grn_total?: Maybe<Scalars['Number']['output']>;
  hasInvoice?: Maybe<Scalars['Boolean']['output']>;
  invoice_adjustment?: Maybe<Scalars['Number']['output']>;
  invoice_amount?: Maybe<Scalars['Number']['output']>;
  invoice_files?: Maybe<Array<Maybe<FileProps>>>;
  invoice_number?: Maybe<Scalars['String']['output']>;
  invoice_total?: Maybe<Scalars['Number']['output']>;
  paid_amount?: Maybe<Scalars['Number']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  parent_type?: Maybe<Scalars['Int']['output']>;
  payment_remarks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  payment_status?: Maybe<Scalars['Int']['output']>;
  pending_amount?: Maybe<Scalars['Number']['output']>;
  po_amount?: Maybe<Scalars['Number']['output']>;
  po_amounts?: Maybe<MvpPoAmountProps>;
  project_id: Scalars['String']['output'];
  project_name?: Maybe<Scalars['String']['output']>;
  total_amount?: Maybe<Scalars['Number']['output']>;
  type: Scalars['Int']['output'];
};

export type MvpVendorAmtDetails = {
  __typename?: 'MVPVendorAmtDetails';
  amount_paid?: Maybe<Scalars['Number']['output']>;
  amount_to_pay?: Maybe<Scalars['Number']['output']>;
  grn_amount?: Maybe<Scalars['Number']['output']>;
  invoice_amount?: Maybe<Scalars['Number']['output']>;
  non_invoice_grn_amount?: Maybe<Scalars['Number']['output']>;
};

export type MaterialIssuePaginatedCount = {
  __typename?: 'MaterialIssuePaginatedCount';
  totalCount: Scalars['Int']['output'];
};

export type MaterialIssueWebProps = {
  __typename?: 'MaterialIssueWebProps';
  _id: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  creator_id: Scalars['String']['output'];
  creator_name: Scalars['String']['output'];
  is_active: Scalars['Boolean']['output'];
  issued_dateMS: Scalars['Float']['output'];
  issued_items: IssuesItems;
  issuer_id?: Maybe<Scalars['String']['output']>;
  issuer_name?: Maybe<Scalars['String']['output']>;
  last_updateMS?: Maybe<Scalars['String']['output']>;
  material_tag_ids?: Maybe<Array<Maybe<Tag>>>;
  material_tags?: Maybe<Array<Maybe<Tag>>>;
  mi_display_id?: Maybe<Scalars['String']['output']>;
  mi_status?: Maybe<Scalars['Int']['output']>;
  mi_type: Scalars['Int']['output'];
  project_id: Scalars['String']['output'];
  project_name?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  updater_id?: Maybe<Scalars['String']['output']>;
  updater_name?: Maybe<Scalars['String']['output']>;
  vendor_id?: Maybe<Scalars['String']['output']>;
  vendor_name?: Maybe<Scalars['String']['output']>;
};

export type MvpPoAmountProps = {
  __typename?: 'MvpPoAmountProps';
  grn_adjustment?: Maybe<Scalars['Number']['output']>;
  grn_amount?: Maybe<Scalars['Number']['output']>;
  grn_total?: Maybe<Scalars['Number']['output']>;
  invoice_adjustment?: Maybe<Scalars['Number']['output']>;
  invoice_amount?: Maybe<Scalars['Number']['output']>;
  invoice_total?: Maybe<Scalars['Number']['output']>;
};

export type OrgPackageProps = {
  __typename?: 'OrgPackageProps';
  _id: Scalars['String']['output'];
  hash_value?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  org_id: Scalars['String']['output'];
  org_package_type?: Maybe<Scalars['Int']['output']>;
  sort_option?: Maybe<Scalars['String']['output']>;
};

export type OrgSettingsProps = {
  __typename?: 'OrgSettingsProps';
  /** should we show task filters in the task list */
  show_task_filters?: Maybe<Scalars['Boolean']['output']>;
};

export type OrgTaskProps = {
  __typename?: 'OrgTaskProps';
  _id: Scalars['String']['output'];
  ancestors?: Maybe<Array<Maybe<TaskAncestorsProps>>>;
  createdAt: Scalars['Long']['output'];
  index_number?: Maybe<Scalars['Int']['output']>;
  meta_data?: Maybe<TaskMetaDataProps>;
  name: Scalars['String']['output'];
  percentage_work_done?: Maybe<Scalars['Float']['output']>;
  plan_status?: Maybe<Scalars['Int']['output']>;
  project_id: Scalars['String']['output'];
  project_name: Scalars['String']['output'];
  updatedAt: Scalars['Long']['output'];
};

export type OrganisationProps = {
  __typename?: 'OrganisationProps';
  /** unique id of the organisation */
  _id: Scalars['String']['output'];
  /** created at */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** id of the creator of the organisation */
  creator_id: Scalars['String']['output'];
  /** external id of the organisation */
  external_id?: Maybe<Scalars['String']['output']>;
  /** epoch time of the first payment */
  first_payment_dateMS?: Maybe<Scalars['Long']['output']>;
  /** end epoch time of the free trial */
  free_trail_date?: Maybe<Scalars['Long']['output']>;
  /** GSTIN details of the organisation */
  gstinArr?: Maybe<Array<Maybe<GstInProps>>>;
  /** is the organisation paid or not */
  is_paid?: Maybe<Scalars['Boolean']['output']>;
  /** url of the organisation logo */
  logo_url?: Maybe<Scalars['String']['output']>;
  /** name of the organisation */
  org_name: Scalars['String']['output'];
  /** org settings */
  org_settings?: Maybe<OrgSettingsProps>;
  /** PAN number of the organisation */
  pan?: Maybe<Scalars['String']['output']>;
  /** has the organisation filled the questionnaire or not */
  questionnaire?: Maybe<Scalars['Boolean']['output']>;
  /** selected GSTIN details of the organisation */
  selected_gst?: Maybe<GstInProps>;
  /** TAN number of the organisation */
  tan?: Maybe<Scalars['String']['output']>;
  /** updated at */
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** zoho org id */
  zoho_org_id?: Maybe<Scalars['String']['output']>;
};

export type PocDetailProps = {
  __typename?: 'POCDetailProps';
  /** mobile number of the POC */
  mobile: Scalars['String']['output'];
  /** name of the POC */
  name: Scalars['String']['output'];
};

export type PaymentModeProps = {
  __typename?: 'PaymentModeProps';
  _id: Scalars['String']['output'];
  payment_method?: Maybe<Scalars['String']['output']>;
};

export type PaymentModelProps = {
  __typename?: 'PaymentModelProps';
  _id: Scalars['String']['output'];
  createdAt: Scalars['Epoch']['output'];
  org_id: Scalars['String']['output'];
  payment_method?: Maybe<Scalars['String']['output']>;
  payment_type?: Maybe<Scalars['String']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Epoch']['output'];
};

export type PettyCashProps = {
  __typename?: 'PettyCashProps';
  _id: Scalars['String']['output'];
  amount: Scalars['Float']['output'];
  approvers?: Maybe<Array<Maybe<ApproverLevelSchemaProps>>>;
  createdAt: Scalars['Epoch']['output'];
  creator_id: Scalars['String']['output'];
  creator_name: Scalars['String']['output'];
  files?: Maybe<Array<Maybe<FileProps>>>;
  grn_display_id?: Maybe<Scalars['String']['output']>;
  labour_details?: Maybe<LabourProps>;
  last_updateMS?: Maybe<Scalars['String']['output']>;
  org: OrganisationProps;
  org_id: Scalars['String']['output'];
  org_package_id?: Maybe<Scalars['String']['output']>;
  org_package_name?: Maybe<Scalars['String']['output']>;
  payer_details: UserProps;
  payer_id?: Maybe<Scalars['String']['output']>;
  payment_date_MS?: Maybe<Scalars['Long']['output']>;
  payment_mode?: Maybe<PaymentModeProps>;
  payment_mode_id?: Maybe<Scalars['String']['output']>;
  pc_display_id: Scalars['String']['output'];
  pc_status?: Maybe<Scalars['Int']['output']>;
  project: ProjectProps;
  project_id: Scalars['String']['output'];
  receiver_details?: Maybe<UserProps>;
  receiver_id?: Maybe<Scalars['String']['output']>;
  ref_item_id?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  transaction_type?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Epoch']['output'];
  updater_id?: Maybe<Scalars['String']['output']>;
  updater_name?: Maybe<Scalars['String']['output']>;
  vendor_details?: Maybe<VendorProps>;
  vendor_id: Scalars['String']['output'];
};

export type PremiumOnboardingProps = {
  __typename?: 'PremiumOnboardingProps';
  _id?: Maybe<Scalars['String']['output']>;
  creator_name?: Maybe<Scalars['String']['output']>;
  expired?: Maybe<Scalars['Boolean']['output']>;
  ref_org_id?: Maybe<Scalars['String']['output']>;
  valid_tillMS: Scalars['Long']['output'];
};

export type ProjectCurrencyProps = {
  __typename?: 'ProjectCurrencyProps';
  /** currency code eg: INR, USD, GBP, etc */
  currency_code?: Maybe<Scalars['String']['output']>;
  /** currency name eg: rupees, dollars, pounds, etc */
  currency_name?: Maybe<Scalars['String']['output']>;
  /** currency plural eg: rupees, dollars, pounds, etc */
  currency_plural?: Maybe<Scalars['String']['output']>;
  /** currency singular eg: rupee, dollar, pound, etc */
  currency_singular?: Maybe<Scalars['String']['output']>;
  /** currency symbol eg: ₹, $, £, etc */
  currency_symbol?: Maybe<Scalars['String']['output']>;
};

export type ProjectInviteNoteProps = {
  __typename?: 'ProjectInviteNoteProps';
  /** note given by the user while inviting */
  note?: Maybe<Scalars['String']['output']>;
  /** date when the user invited the user */
  updated_at?: Maybe<Scalars['Long']['output']>;
  /** user who invited the user */
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type ProjectProps = {
  __typename?: 'ProjectProps';
  /** unique id of the project */
  _id: Scalars['String']['output'];
  /** actual end date of the project */
  actual_end_date?: Maybe<Scalars['Long']['output']>;
  /** actual start date of the project */
  actual_start_date?: Maybe<Scalars['Long']['output']>;
  /** additional poc details */
  additional_poc?: Maybe<Array<Maybe<AdditionalPocDetailProps>>>;
  /** attached project plans */
  attached_project_plans?: Maybe<Array<Maybe<FileProps>>>;
  /** created at */
  createdAt: Scalars['Date']['output'];
  /** creator id of the project */
  creator_id: Scalars['String']['output'];
  /** creator name of the project */
  creator_name: Scalars['String']['output'];
  /** description of the project */
  description?: Maybe<Scalars['String']['output']>;
  /** end date of the project */
  end_date?: Maybe<Scalars['Long']['output']>;
  /** what is the expected cost of the project */
  expected_cost?: Maybe<Scalars['Float']['output']>;
  /** what is the expected handover date of the project */
  expected_handover_date?: Maybe<Scalars['Long']['output']>;
  /** is the feed unlocked */
  feed_unlocked: Scalars['Boolean']['output'];
  /** finance settings of the project */
  finance_settings?: Maybe<FinanceSettingsProps>;
  /** project invite note */
  invite_note?: Maybe<ProjectInviteNoteProps>;
  /** is the project active */
  is_active?: Maybe<Scalars['Boolean']['output']>;
  /** is the project a demo project */
  is_default: Scalars['Boolean']['output'];
  /** is the project explorable */
  is_explorable: Scalars['Boolean']['output'];
  /** when was the status updated last time */
  last_status_update_date?: Maybe<Scalars['Long']['output']>;
  /** name of the project */
  name: Scalars['String']['output'];
  /** organisation details */
  org?: Maybe<OrganisationProps>;
  /** id of the organisation to which the project belongs to */
  org_id: Scalars['String']['output'];
  /** name of the organisation to which the project belongs to */
  org_name?: Maybe<Scalars['String']['output']>;
  /** photo url */
  photo_url?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  plan_creation_status: Scalars['Int']['output'];
  /** planned end date of the project */
  planned_end_date?: Maybe<Scalars['Long']['output']>;
  /** planned start date of the project */
  planned_start_date?: Maybe<Scalars['Long']['output']>;
  /** project address */
  project_address?: Maybe<Scalars['String']['output']>;
  /** project currency */
  project_currency?: Maybe<ProjectCurrencyProps>;
  /** project geo address */
  project_geo_address?: Maybe<Scalars['String']['output']>;
  /** project geo location */
  project_geo_location?: Maybe<GeoLocationProps>;
  /**
   * deprected fields
   * @deprecated was used for experiment which has been discontinued
   */
  project_plan_report_pdf_url?: Maybe<Scalars['String']['output']>;
  /** project plan type of the project (broad plan or detailed plan) */
  project_plan_type?: Maybe<Scalars['Long']['output']>;
  /**
   * PLANS_TO_BE_UPLOADED : 0
   *
   * PLANS_UPLOADED       : 1
   *
   * PLANS_IMPLEMENTED    : 2
   *
   * PLANS_SEEN           : 3
   */
  project_plan_upload_status: Scalars['Int']['output'];
  /** project progress, between 0 and 100 */
  project_progress?: Maybe<Scalars['Float']['output']>;
  /** project settings */
  project_settings?: Maybe<ProjectSettingsProps>;
  /** project stages */
  project_stages?: Maybe<Array<Maybe<ProjectStageProps>>>;
  /** project state, ex - Karnataka */
  project_state?: Maybe<Scalars['String']['output']>;
  /**
   * NOT_STARTED : 0
   *
   * IN_PROGRESS : 1
   *
   * COMPLETED   : 2
   *
   * ON_HOLD     : 3
   */
  project_status?: Maybe<Scalars['Int']['output']>;
  /**
   * RESIDENTIAL         : 0
   *
   * COMMERCIAL          : 1
   *
   * ROAD_CONSTRUCTION   : 2
   *
   * OTHER               : 3
   *
   * INFRASTRUCTURE      : 4
   *
   * INTERIOR            : 5
   *
   * GOVERNEMNT_PROJECTS : 6
   */
  project_type: Scalars['Int']['output'];
  /** promised cost of the project */
  promised_cost?: Maybe<Scalars['Float']['output']>;
  /** promised handover date of the project */
  promised_handover_date?: Maybe<Scalars['Long']['output']>;
  /** purchase settings of the project */
  purchase_settings?: Maybe<PurchaseSettingsProps>;
  /** start date of the project */
  start_date?: Maybe<Scalars['Long']['output']>;
  /** terms and conditions */
  tc?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  total_price: Scalars['Float']['output'];
  /** @deprecated No longer supported */
  total_price_work_done: Scalars['Float']['output'];
  /** @deprecated No longer supported */
  total_tasks: Scalars['Int']['output'];
  /** total number of template tasks */
  total_template_tasks: Scalars['Int']['output'];
  /** the last time any of the project details/entitites were updated */
  update_dateMS: Scalars['Long']['output'];
  /** updated at */
  updatedAt: Scalars['Date']['output'];
  /** project users */
  user_team_details?: Maybe<ProjectUserTeamDetailsProps>;
};

export type ProjectSettingsProps = {
  __typename?: 'ProjectSettingsProps';
  /**
   * Normal Site Scheduling : 0
   * BOQ                    : 1
   * DRAWINGS               : 2
   */
  site_plan_type?: Maybe<Scalars['Int']['output']>;
  /** tells whether or not show empty labour attendance state */
  suppress_empty_labour_att_state?: Maybe<Scalars['Boolean']['output']>;
  /** tells whether or not show empty vendor attendance state */
  suppress_empty_vendor_att_state?: Maybe<Scalars['Boolean']['output']>;
  /** tells whether or not to show the invite button */
  suppress_invite_button?: Maybe<Scalars['Boolean']['output']>;
};

export type ProjectStageProps = {
  __typename?: 'ProjectStageProps';
  _id: Scalars['String']['output'];
  project_id: Scalars['String']['output'];
  stage_id: Scalars['String']['output'];
  task_id?: Maybe<Scalars['String']['output']>;
  task_status?: Maybe<Scalars['Int']['output']>;
};

export type ProjectUserTeamDetailsProps = {
  __typename?: 'ProjectUserTeamDetailsProps';
  /** is the user a team admin */
  is_org_admin?: Maybe<Scalars['Boolean']['output']>;
  /** team id of the user */
  team_id: Scalars['String']['output'];
  /** team name of the user */
  team_name: Scalars['String']['output'];
};

export type PurchaseSettingsProps = {
  __typename?: 'PurchaseSettingsProps';
  /** grn approvers */
  grn_approvers?: Maybe<Array<Maybe<ApproverLevelSchemaProps>>>;
  /** grn checklist */
  grn_checklist?: Maybe<Array<Maybe<ChecklistSchemaProps>>>;
  /** indent approvers */
  indent_approvers?: Maybe<Array<Maybe<ApproverLevelSchemaProps>>>;
  /** tells if approver dialog is shown or not */
  is_po_approver_dialog_shown?: Maybe<Scalars['Boolean']['output']>;
  /** additional poc details */
  is_project_site_poc_same?: Maybe<Scalars['Boolean']['output']>;
  /** po approvers */
  po_approvers?: Maybe<Array<Maybe<ApproverLevelSchemaProps>>>;
  /** po checklist */
  po_checklist?: Maybe<Array<Maybe<ChecklistSchemaProps>>>;
  /** project poc details */
  project_poc_details?: Maybe<PocDetailProps>;
  /** site poc details */
  site_poc_details?: Maybe<PocDetailProps>;
};

export type Query = {
  __typename?: 'Query';
  checklistitems: Checklistitems;
  dashboardRemarks: DashboardRemarks;
  inventoryHistories: InventoryHistories;
  materialIssues: MaterialIssues;
  materialVendorPayables: MaterialVendorPayables;
  orgPackages: OrgPackages;
  organisations: Organisations;
  pettyCashes: PettyCashes;
  premiumOnboarding: PremiumOnboarding;
  projectPlans: ProjectPlans;
  projects: Projects;
  siteTransfers: SiteTransfers;
  tasks: Tasks;
  users: Users;
};

export type StBomProps = {
  __typename?: 'STBomProps';
  _id: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  received_by_id?: Maybe<Scalars['String']['output']>;
  received_by_name?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  total_received_quantity?: Maybe<Scalars['Float']['output']>;
  uom?: Maybe<Scalars['String']['output']>;
};

export type SiteTranferFlatListProps = {
  __typename?: 'SiteTranferFlatListProps';
  _id: Scalars['String']['output'];
  boms?: Maybe<StBomProps>;
  createdAt: Scalars['Long']['output'];
  created_at?: Maybe<Scalars['Epoch']['output']>;
  creator_id: Scalars['String']['output'];
  creator_name: Scalars['String']['output'];
  is_active: Scalars['Boolean']['output'];
  latest_delivery_date?: Maybe<Scalars['Epoch']['output']>;
  org_id: Scalars['String']['output'];
  project: ProjectProps;
  project_id: Scalars['String']['output'];
  reference_item_id?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  source_id?: Maybe<Scalars['String']['output']>;
  source_name?: Maybe<Scalars['String']['output']>;
  st_display_id?: Maybe<Scalars['String']['output']>;
  st_status: Scalars['Int']['output'];
  updatedAt: Scalars['Long']['output'];
  updated_at?: Maybe<Scalars['Epoch']['output']>;
};

export type SortOptionProps = {
  key: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type Tag = {
  __typename?: 'Tag';
  _id?: Maybe<Scalars['String']['output']>;
  tag_name?: Maybe<Scalars['String']['output']>;
};

export type TaskAncestorsProps = {
  __typename?: 'TaskAncestorsProps';
  _id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TaskCountSummaryProps = {
  __typename?: 'TaskCountSummaryProps';
  _id: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
};

export type TaskMetaDataProps = {
  __typename?: 'TaskMetaDataProps';
  statusClass?: Maybe<Scalars['String']['output']>;
  statusText?: Maybe<Scalars['String']['output']>;
};

export type UserProps = {
  __typename?: 'UserProps';
  _id: Scalars['String']['output'];
  country_code: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstVerifiedMS?: Maybe<Scalars['Int']['output']>;
  isVerified: Scalars['Boolean']['output'];
  is_email_verified?: Maybe<Scalars['Boolean']['output']>;
  latest_app_ver?: Maybe<Scalars['String']['output']>;
  mobile_number: Scalars['String']['output'];
  org_id: Scalars['String']['output'];
  org_name?: Maybe<Scalars['String']['output']>;
  photo_url?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  user_type?: Maybe<Scalars['String']['output']>;
};

export type UserPropsWithProjectInfos = {
  __typename?: 'UserPropsWithProjectInfos';
  _id: Scalars['String']['output'];
  country_code: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstVerifiedMS?: Maybe<Scalars['Int']['output']>;
  isVerified: Scalars['Boolean']['output'];
  is_email_verified?: Maybe<Scalars['Boolean']['output']>;
  latest_app_ver?: Maybe<Scalars['String']['output']>;
  mobile_number: Scalars['String']['output'];
  org_id: Scalars['String']['output'];
  org_name?: Maybe<Scalars['String']['output']>;
  photo_url?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  team_name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  user_type?: Maybe<Scalars['String']['output']>;
  usersProject: UsersProjectProps;
};

export type UsersProjectProps = {
  __typename?: 'UsersProjectProps';
  _id: Scalars['String']['output'];
  actions?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  createdAt: Scalars['Date']['output'];
  project_id: Scalars['String']['output'];
  training_status?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Date']['output'];
  user_id: Scalars['String']['output'];
};

export type VendorContactDetailsProps = {
  __typename?: 'VendorContactDetailsProps';
  contact_person_mobile_number?: Maybe<Scalars['String']['output']>;
  contact_person_name?: Maybe<Scalars['String']['output']>;
};

export type VendorProps = {
  __typename?: 'VendorProps';
  _id: Scalars['String']['output'];
  completion_state?: Maybe<Scalars['String']['output']>;
  contact_details?: Maybe<Array<Maybe<VendorContactDetailsProps>>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  creator_id?: Maybe<Scalars['String']['output']>;
  creator_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  org_id: Scalars['String']['output'];
  org_name?: Maybe<Scalars['String']['output']>;
  project_id: Scalars['String']['output'];
  shift?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vendor_credits?: Maybe<Scalars['Float']['output']>;
  vendor_type?: Maybe<Scalars['Int']['output']>;
  working_hours?: Maybe<Scalars['Int']['output']>;
};

export type Checklistitems = {
  __typename?: 'checklistitems';
  getAllForEntity: Array<Maybe<ChecklistItemProps>>;
};

export type ChecklistitemsGetAllForEntityArgs = {
  entity_id: Scalars['String']['input'];
};

export type DashboardRemarks = {
  __typename?: 'dashboardRemarks';
  getAll: Array<DashboardRemarkProps>;
  getAllByOrgId: Array<DashboardRemarkProps>;
  getAllVersionsForId: Array<DashboardRemarkProps>;
  getRemarksForDate: Array<DashboardRemarkProps>;
  getRemarksForDateByOrgId: Array<DashboardRemarkProps>;
};

export type DashboardRemarksGetAllArgs = {
  creators?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dFilter?: InputMaybe<Scalars['DFilterObject']['input']>;
  end_date?: InputMaybe<Scalars['Long']['input']>;
  limit: Scalars['limit_Int_NotNull_min_10']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
  project_id: Scalars['String']['input'];
  remark_types?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOption?: InputMaybe<Array<InputMaybe<SortOptionProps>>>;
  start_date?: InputMaybe<Scalars['Long']['input']>;
};

export type DashboardRemarksGetAllByOrgIdArgs = {
  creators?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  end_date?: InputMaybe<Scalars['Long']['input']>;
  limit: Scalars['limit_Int_NotNull_min_10']['input'];
  org_id: Scalars['String']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
  project_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  remark_types?: InputMaybe<Scalars['String']['input']>;
  sortOption?: InputMaybe<Array<InputMaybe<SortOptionProps>>>;
  sortOptions?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['Long']['input']>;
};

export type DashboardRemarksGetAllVersionsForIdArgs = {
  id: Scalars['String']['input'];
  limit: Scalars['limit_Int_NotNull_min_10']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
};

export type DashboardRemarksGetRemarksForDateArgs = {
  date: Scalars['Long']['input'];
  project_id: Scalars['String']['input'];
};

export type DashboardRemarksGetRemarksForDateByOrgIdArgs = {
  date: Scalars['Long']['input'];
  org_id: Scalars['String']['input'];
  project_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Filters = {
  assigned_users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  end_date_end?: InputMaybe<Scalars['Date']['input']>;
  end_date_start?: InputMaybe<Scalars['Date']['input']>;
  packages?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  start_date_end?: InputMaybe<Scalars['Date']['input']>;
  start_date_start?: InputMaybe<Scalars['Date']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type InventoryHistories = {
  __typename?: 'inventoryHistories';
  getConsumptionById: ConsumptionProps;
  getConsumptionList?: Maybe<Array<Maybe<ConsumptionProps>>>;
  getConsumptionListCount?: Maybe<Scalars['Int']['output']>;
};

export type InventoryHistoriesGetConsumptionByIdArgs = {
  id: Scalars['String']['input'];
};

export type InventoryHistoriesGetConsumptionListArgs = {
  checkers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdAt?: InputMaybe<DateInputProps>;
  creators?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dFilter?: InputMaybe<Scalars['DFilterObject']['input']>;
  limit: Scalars['limit_Int_NotNull_min_10']['input'];
  material_tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  materials?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  members?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  org_id: Scalars['String']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
  projects?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sortOption?: InputMaybe<Array<InputMaybe<SortOptionProps>>>;
  tasks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  types?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type InventoryHistoriesGetConsumptionListCountArgs = {
  checkers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdAt?: InputMaybe<DateInputProps>;
  creators?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  material_tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  materials?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  members?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  org_id: Scalars['String']['input'];
  projects?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tasks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  types?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MaterialIssues = {
  __typename?: 'materialIssues';
  getByOrgId: Array<Maybe<MaterialIssueWebProps>>;
  getCount: MaterialIssuePaginatedCount;
};

export type MaterialIssuesGetByOrgIdArgs = {
  bom_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  end_date?: InputMaybe<Scalars['Long']['input']>;
  limit: Scalars['limit_Int_NotNull_min_20_max_100']['input'];
  material_tag_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  member_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mi_display_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mi_status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  mi_type?: InputMaybe<Scalars['Int']['input']>;
  org_id: Scalars['String']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
  show_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  start_date?: InputMaybe<Scalars['Long']['input']>;
  task_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vendor_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MaterialIssuesGetCountArgs = {
  bom_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  end_date?: InputMaybe<Scalars['Long']['input']>;
  material_tag_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  member_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mi_display_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mi_status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  org_id: Scalars['String']['input'];
  show_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  start_date?: InputMaybe<Scalars['Long']['input']>;
  task_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vendor_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MaterialVendorPayables = {
  __typename?: 'materialVendorPayables';
  getInvoicePaymentDeletionData?: Maybe<
    Array<Maybe<Scalars['String']['output']>>
  >;
  getPayablesForInvoice?: Maybe<Array<MvpProps>>;
  getPayablesForPO?: Maybe<Array<MvpProps>>;
  getPayblesForVendor?: Maybe<Array<MvpProps>>;
  getPayblesForVendorCount: Scalars['Int']['output'];
  getVendorPaymentDetails: MvpVendorAmtDetails;
};

export type MaterialVendorPayablesGetInvoicePaymentDeletionDataArgs = {
  invoice_id: Scalars['String']['input'];
};

export type MaterialVendorPayablesGetPayablesForInvoiceArgs = {
  createdAt?: InputMaybe<DateInputProps>;
  grns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  invoice_id: Scalars['String']['input'];
  invoice_numbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  invoices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  org_id: Scalars['String']['input'];
  payment_status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  pos?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vendor_id: Scalars['String']['input'];
};

export type MaterialVendorPayablesGetPayablesForPoArgs = {
  createdAt?: InputMaybe<DateInputProps>;
  grns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  invoice_numbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  invoices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  org_id: Scalars['String']['input'];
  payment_status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  po_id: Scalars['String']['input'];
  pos?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vendor_id: Scalars['String']['input'];
};

export type MaterialVendorPayablesGetPayblesForVendorArgs = {
  createdAt?: InputMaybe<DateInputProps>;
  grns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  invoice_numbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  invoices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: Scalars['limit_Int_NotNull_min_1']['input'];
  org_id: Scalars['String']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
  payment_status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  pos?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vendor_id: Scalars['String']['input'];
};

export type MaterialVendorPayablesGetPayblesForVendorCountArgs = {
  createdAt?: InputMaybe<DateInputProps>;
  grns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  invoice_numbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  invoices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  org_id: Scalars['String']['input'];
  payment_status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  pos?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vendor_id: Scalars['String']['input'];
};

export type MaterialVendorPayablesGetVendorPaymentDetailsArgs = {
  createdAt?: InputMaybe<DateInputProps>;
  grns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  invoice_numbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  invoices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  org_id: Scalars['String']['input'];
  payment_status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  pos?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vendor_id: Scalars['String']['input'];
};

export type OrgPackages = {
  __typename?: 'orgPackages';
  getAllByOrgId: Array<Maybe<OrgPackageProps>>;
};

export type OrgPackagesGetAllByOrgIdArgs = {
  org_id: Scalars['String']['input'];
  org_package_type?: InputMaybe<Scalars['Int']['input']>;
  sort_option?: InputMaybe<Scalars['String']['input']>;
};

/** organisation queries */
export type Organisations = {
  __typename?: 'organisations';
  /** get organisation by id */
  getById: OrganisationProps;
};

/** organisation queries */
export type OrganisationsGetByIdArgs = {
  id: Scalars['String']['input'];
};

export type PettyCashes = {
  __typename?: 'pettyCashes';
  getById?: Maybe<PettyCashProps>;
  getByOrgId?: Maybe<Array<Maybe<PettyCashProps>>>;
  getCountByOrgId: Scalars['Int']['output'];
};

export type PettyCashesGetByIdArgs = {
  id: Scalars['String']['input'];
};

export type PettyCashesGetByOrgIdArgs = {
  dFilter?: InputMaybe<Scalars['DFilterObject']['input']>;
  end_date?: InputMaybe<Scalars['Long']['input']>;
  limit: Scalars['limit_Int_NotNull_min_20_max_100']['input'];
  org_id: Scalars['String']['input'];
  packages?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['page_Int_NotNull_min_0']['input'];
  payers?: InputMaybe<Scalars['String']['input']>;
  payment_modes?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<Scalars['String']['input']>;
  receivers?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['Long']['input']>;
  transaction_types?: InputMaybe<Scalars['String']['input']>;
  vendors?: InputMaybe<Scalars['String']['input']>;
};

export type PettyCashesGetCountByOrgIdArgs = {
  dFilter?: InputMaybe<Scalars['DFilterObject']['input']>;
  end_date?: InputMaybe<Scalars['Long']['input']>;
  org_id: Scalars['String']['input'];
  packages?: InputMaybe<Scalars['String']['input']>;
  payers?: InputMaybe<Scalars['String']['input']>;
  payment_modes?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<Scalars['String']['input']>;
  receivers?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['Long']['input']>;
  transaction_types?: InputMaybe<Scalars['String']['input']>;
  vendors?: InputMaybe<Scalars['String']['input']>;
};

export type PremiumOnboarding = {
  __typename?: 'premiumOnboarding';
  getById?: Maybe<PremiumOnboardingProps>;
};

export type PremiumOnboardingGetByIdArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectPlans = {
  __typename?: 'projectPlans';
  getTaskCountByPlanStatus: Scalars['Int']['output'];
};

export type ProjectPlansGetTaskCountByPlanStatusArgs = {
  filters?: InputMaybe<Filters>;
  plan_status: Scalars['Int']['input'];
  project_id: Scalars['String']['input'];
};

export type Projects = {
  __typename?: 'projects';
  getById: ProjectProps;
  getProjectStages: Array<Maybe<ProjectStageProps>>;
  paginateUserProjects: Array<ProjectProps>;
};

export type ProjectsGetByIdArgs = {
  id: Scalars['String']['input'];
};

export type ProjectsGetProjectStagesArgs = {
  id: Scalars['String']['input'];
};

export type ProjectsPaginateUserProjectsArgs = {
  limit: Scalars['limit_Int_NotNull_min_20_max_100']['input'];
  org_id?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['page_Int_NotNull_min_0']['input'];
  pinned?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOption?: InputMaybe<Array<InputMaybe<SortOptionProps>>>;
  stages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['Number']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SiteTransfers = {
  __typename?: 'siteTransfers';
  getSTByBomsCount: Scalars['Int']['output'];
  getSTbyBoms?: Maybe<Array<Maybe<SiteTranferFlatListProps>>>;
};

export type SiteTransfersGetStByBomsCountArgs = {
  dFilter?: InputMaybe<Scalars['DFilterObject']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  org_id: Scalars['String']['input'];
};

export type SiteTransfersGetSTbyBomsArgs = {
  dFilter?: InputMaybe<Scalars['DFilterObject']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['limit_Int_NotNull_min_20_max_100']['input'];
  org_id: Scalars['String']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
};

export type Tasks = {
  __typename?: 'tasks';
  getByOrgId?: Maybe<Array<Maybe<OrgTaskProps>>>;
  getTaskCountSummaryForOrg?: Maybe<Array<Maybe<TaskCountSummaryProps>>>;
  showLinkedTasksForCustomField?: Maybe<Array<Maybe<CfTaskProps>>>;
};

export type TasksGetByOrgIdArgs = {
  accessType?: InputMaybe<Scalars['Int']['input']>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  dFilter?: InputMaybe<Scalars['DFilterObject']['input']>;
  limit: Scalars['limit_Int_NotNull_min_1']['input'];
  org_id: Scalars['String']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
  plan_status?: InputMaybe<Array<Scalars['Int']['input']>>;
  projects?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOption?: InputMaybe<Array<InputMaybe<SortOptionProps>>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  updated_after_dateMS?: InputMaybe<Scalars['Long']['input']>;
  users?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TasksGetTaskCountSummaryForOrgArgs = {
  accessType?: InputMaybe<Scalars['Int']['input']>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  dFilter?: InputMaybe<Scalars['DFilterObject']['input']>;
  org_id: Scalars['String']['input'];
  projects?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  updated_after_dateMS?: InputMaybe<Scalars['Long']['input']>;
  users?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TasksShowLinkedTasksForCustomFieldArgs = {
  field_id: Scalars['String']['input'];
};

export type Users = {
  __typename?: 'users';
  getUsersByProject: Array<Maybe<UserPropsWithProjectInfos>>;
};

export type UsersGetUsersByProjectArgs = {
  projectId: Scalars['String']['input'];
};

export type GetOrgWcOptionsQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  sortOption?: InputMaybe<Scalars['String']['input']>;
  orgPackageType?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetOrgWcOptionsQuery = {
  __typename?: 'Query';
  orgPackages: {
    __typename?: 'orgPackages';
    getAllByOrgId: Array<{
      __typename?: 'OrgPackageProps';
      name: string;
      id: string;
    } | null>;
  };
};

export type GetPayablesForPoQueryVariables = Exact<{
  poId: Scalars['String']['input'];
  vendorId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  projects?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  invoices?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  grns?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  pos?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  invoiceNumbers?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  paymentStatus?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >;
  createdAt?: InputMaybe<DateInputProps>;
}>;

export type GetPayablesForPoQuery = {
  __typename?: 'Query';
  materialVendorPayables: {
    __typename?: 'materialVendorPayables';
    getPayablesForPO?: Array<{
      __typename?: 'MVPProps';
      _id: string;
      type: number;
      display_id?: string | null;
      delivery_date?: any | null;
      project_id: string;
      project_name?: string | null;
      po_amount?: any | null;
      grn_amount?: any | null;
      grn_adjustment?: any | null;
      grn_total?: any | null;
      invoice_number?: string | null;
      invoice_amount?: any | null;
      invoice_adjustment?: any | null;
      pending_amount?: any | null;
      invoice_total?: any | null;
      total_amount?: any | null;
      paid_amount?: any | null;
      payment_status?: number | null;
      payment_remarks?: Array<string | null> | null;
      hasInvoice?: boolean | null;
      canBePaid?: boolean | null;
      parent_id?: string | null;
      parent_type?: number | null;
      grn_files?: Array<{
        __typename?: 'FileProps';
        name: string;
        url: string;
        mime_type: string;
      } | null> | null;
      invoice_files?: Array<{
        __typename?: 'FileProps';
        name: string;
        url: string;
        mime_type: string;
      } | null> | null;
    }> | null;
  };
};

export type GetPayablesForInvoiceQueryVariables = Exact<{
  invoiceId: Scalars['String']['input'];
  vendorId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  projects?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  invoices?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  grns?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  pos?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  invoiceNumbers?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  paymentStatus?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >;
  createdAt?: InputMaybe<DateInputProps>;
}>;

export type GetPayablesForInvoiceQuery = {
  __typename?: 'Query';
  materialVendorPayables: {
    __typename?: 'materialVendorPayables';
    getPayablesForInvoice?: Array<{
      __typename?: 'MVPProps';
      _id: string;
      delivery_date?: any | null;
      display_id?: string | null;
      grn_adjustment?: any | null;
      grn_amount?: any | null;
      grn_total?: any | null;
      invoice_adjustment?: any | null;
      invoice_amount?: any | null;
      invoice_number?: string | null;
      invoice_total?: any | null;
      paid_amount?: any | null;
      payment_remarks?: Array<string | null> | null;
      payment_status?: number | null;
      po_amount?: any | null;
      project_id: string;
      project_name?: string | null;
      total_amount?: any | null;
      type: number;
      hasInvoice?: boolean | null;
      canBePaid?: boolean | null;
      parent_id?: string | null;
      parent_type?: number | null;
      grn_files?: Array<{
        __typename?: 'FileProps';
        mime_type: string;
        name: string;
        url: string;
      } | null> | null;
      invoice_files?: Array<{
        __typename?: 'FileProps';
        mime_type: string;
        name: string;
        url: string;
      } | null> | null;
    }> | null;
  };
};

export type GetOrgProjectsQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
  limit: Scalars['limit_Int_NotNull_min_20_max_100']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetOrgProjectsQuery = {
  __typename?: 'Query';
  projects: {
    __typename?: 'projects';
    paginateUserProjects: Array<{
      __typename?: 'ProjectProps';
      _id: string;
      name: string;
    }>;
  };
};

export type GetAllUsersOfProjectQueryVariables = Exact<{
  projectId: Scalars['String']['input'];
}>;

export type GetAllUsersOfProjectQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'users';
    getUsersByProject: Array<{
      __typename?: 'UserPropsWithProjectInfos';
      _id: string;
      displayName: string;
      mobile_number: string;
      country_code: string;
      team_name?: string | null;
      isVerified: boolean;
      email?: string | null;
      usersProject: {
        __typename?: 'UsersProjectProps';
        actions?: Array<number | null> | null;
        project_id: string;
        training_status?: number | null;
      };
    } | null>;
  };
};

export type GetWcByOrgIdQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  orgPackageType?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetWcByOrgIdQuery = {
  __typename?: 'Query';
  orgPackages: {
    __typename?: 'orgPackages';
    getAllByOrgId: Array<{
      __typename?: 'OrgPackageProps';
      _id: string;
      hash_value?: string | null;
      name: string;
      org_id: string;
      org_package_type?: number | null;
    } | null>;
  };
};

export type GetLinkedtaskForCustomFieldQueryVariables = Exact<{
  fieldId: Scalars['String']['input'];
}>;

export type GetLinkedtaskForCustomFieldQuery = {
  __typename?: 'Query';
  tasks: {
    __typename?: 'tasks';
    showLinkedTasksForCustomField?: Array<{
      __typename?: 'CFTaskProps';
      name: string;
      index_number?: number | null;
      _id: string;
      ancestors?: Array<{
        __typename?: 'TaskAncestorsProps';
        name: string;
        _id: string;
      } | null> | null;
    } | null> | null;
  };
};

export type GetChecklistForEntityQueryVariables = Exact<{
  entityId: Scalars['String']['input'];
}>;

export type GetChecklistForEntityQuery = {
  __typename?: 'Query';
  checklistitems: {
    __typename?: 'checklistitems';
    getAllForEntity: Array<{
      __typename?: 'ChecklistItemProps';
      _id: string;
      label: string;
      is_active?: boolean | null;
      parent_id?: string | null;
      order: number;
      updatedAt: any;
      createdAt: any;
      updater_id?: string | null;
      state: number;
      updater_name?: string | null;
      creator_id: string;
      creator_name: string;
    } | null>;
  };
};

export type PettyCashByIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type PettyCashByIdQuery = {
  __typename?: 'Query';
  pettyCashes: {
    __typename?: 'pettyCashes';
    getById?: {
      __typename?: 'PettyCashProps';
      _id: string;
      amount: number;
      creator_name: string;
      createdAt: any;
      pc_display_id: string;
      receiver_id?: string | null;
      grn_display_id?: string | null;
      ref_item_id?: string | null;
      payment_mode_id?: string | null;
      payment_date_MS?: any | null;
      transaction_type?: number | null;
      updater_name?: string | null;
      updater_id?: string | null;
      updatedAt: any;
      remarks?: string | null;
      pc_status?: number | null;
      receiver_details?: {
        __typename?: 'UserProps';
        _id: string;
        displayName: string;
      } | null;
      payment_mode?: {
        __typename?: 'PaymentModeProps';
        payment_method?: string | null;
      } | null;
      vendor_details?: {
        __typename?: 'VendorProps';
        _id: string;
        name?: string | null;
      } | null;
      payer_details: {
        __typename?: 'UserProps';
        _id: string;
        displayName: string;
      };
      project: { __typename?: 'ProjectProps'; _id: string; name: string };
      files?: Array<{
        __typename?: 'FileProps';
        mime_type: string;
        url: string;
        name: string;
      } | null> | null;
      approvers?: Array<{
        __typename?: 'ApproverLevelSchemaProps';
        level: number;
        state: number;
        users: Array<{
          __typename?: 'ApproverSchemaProps';
          user_id?: string | null;
          user_name?: string | null;
          user_profile_url?: string | null;
          dateMS?: any | null;
          state?: number | null;
          note?: string | null;
        }>;
      } | null> | null;
    } | null;
  };
};

export type GetPettyCashByOrgIdQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
  limit: Scalars['limit_Int_NotNull_min_20_max_100']['input'];
  dFilter?: InputMaybe<Scalars['DFilterObject']['input']>;
}>;

export type GetPettyCashByOrgIdQuery = {
  __typename?: 'Query';
  pettyCashes: {
    __typename?: 'pettyCashes';
    getByOrgId?: Array<{
      __typename?: 'PettyCashProps';
      _id: string;
      amount: number;
      pc_display_id: string;
      remarks?: string | null;
      org_package_id?: string | null;
      creator_name: string;
      org_package_name?: string | null;
      transaction_type?: number | null;
      receiver_id?: string | null;
      payment_mode_id?: string | null;
      payment_date_MS?: any | null;
      createdAt: any;
      pc_status?: number | null;
      receiver_details?: {
        __typename?: 'UserProps';
        _id: string;
        displayName: string;
      } | null;
      project: { __typename?: 'ProjectProps'; name: string };
      payer_details: {
        __typename?: 'UserProps';
        _id: string;
        displayName: string;
      };
      vendor_details?: {
        __typename?: 'VendorProps';
        _id: string;
        name?: string | null;
      } | null;
      labour_details?: {
        __typename?: 'LabourProps';
        _id: string;
        name: string;
      } | null;
      payment_mode?: {
        __typename?: 'PaymentModeProps';
        payment_method?: string | null;
      } | null;
      files?: Array<{
        __typename?: 'FileProps';
        mime_type: string;
        name: string;
        url: string;
      } | null> | null;
      approvers?: Array<{
        __typename?: 'ApproverLevelSchemaProps';
        level: number;
        state: number;
        users: Array<{
          __typename?: 'ApproverSchemaProps';
          user_id?: string | null;
          user_name?: string | null;
          user_profile_url?: string | null;
          dateMS?: any | null;
          state?: number | null;
          note?: string | null;
        }>;
      } | null> | null;
    } | null> | null;
  };
};

export type GetPettyCashCountByOrgIdQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  projects?: InputMaybe<Scalars['String']['input']>;
  dFilter?: InputMaybe<Scalars['DFilterObject']['input']>;
}>;

export type GetPettyCashCountByOrgIdQuery = {
  __typename?: 'Query';
  pettyCashes: { __typename?: 'pettyCashes'; getCountByOrgId: number };
};

export type ConsumtionListQueryQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
  limit: Scalars['limit_Int_NotNull_min_10']['input'];
  projects?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  materials?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  creators?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  createdAt?: InputMaybe<DateInputProps>;
  types?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >;
  vendors?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  tasks?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  sortOption?: InputMaybe<
    Array<InputMaybe<SortOptionProps>> | InputMaybe<SortOptionProps>
  >;
  checkers?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  members?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  materialTags?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type ConsumtionListQueryQuery = {
  __typename?: 'Query';
  inventoryHistories: {
    __typename?: 'inventoryHistories';
    getConsumptionList?: Array<{
      __typename?: 'ConsumptionProps';
      _id?: string | null;
      material_name?: string | null;
      dateMS: any;
      files?: string | null;
      project_id: string;
      project_name: string;
      remarks?: string | null;
      specs?: string | null;
      user_id: string;
      user_name: string;
      item_type?: number | null;
      item_display_name?: string | null;
      material?: {
        __typename?: 'ConsumptionMaterialProps';
        name: string;
        quantity_str?: string | null;
        specs?: string | null;
      } | null;
      mi?: {
        __typename?: 'ConsumptionMIProps';
        ancestors?: string | null;
        checker_name?: string | null;
        task_name?: string | null;
        vendor_name?: string | null;
      } | null;
      material_tags?: Array<{
        __typename?: 'Tag';
        _id?: string | null;
        tag_name?: string | null;
      } | null> | null;
    } | null> | null;
  };
};

export type ConsumtionListQueryCountQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  projects?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  materials?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  creators?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  createdAt?: InputMaybe<DateInputProps>;
  types?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >;
  vendors?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  tasks?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  checkers?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  members?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  materialTags?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type ConsumtionListQueryCountQuery = {
  __typename?: 'Query';
  inventoryHistories: {
    __typename?: 'inventoryHistories';
    getConsumptionListCount?: number | null;
  };
};

export type GetAllProjectsWiseQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['page_Int_NotNull_min_0']['input'];
  limit: Scalars['limit_Int_NotNull_min_20_max_100']['input'];
}>;

export type GetAllProjectsWiseQuery = {
  __typename?: 'Query';
  projects: {
    __typename?: 'projects';
    paginateUserProjects: Array<{
      __typename?: 'ProjectProps';
      _id: string;
      name: string;
    }>;
  };
};

export type MaterialIssuesQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
  limit: Scalars['limit_Int_NotNull_min_20_max_100']['input'];
  bomIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  taskIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  vendorIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  startDate?: InputMaybe<Scalars['Long']['input']>;
  endDate?: InputMaybe<Scalars['Long']['input']>;
  memberIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  materialTagIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  miDisplayIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  show_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  miStatus?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >;
}>;

export type MaterialIssuesQuery = {
  __typename?: 'Query';
  materialIssues: {
    __typename?: 'materialIssues';
    getByOrgId: Array<{
      __typename?: 'MaterialIssueWebProps';
      _id: string;
      project_id: string;
      vendor_name?: string | null;
      project_name?: string | null;
      creator_id: string;
      creator_name: string;
      createdAt?: any | null;
      mi_display_id?: string | null;
      issuer_name?: string | null;
      mi_status?: number | null;
      is_active: boolean;
      issued_items: {
        __typename?: 'IssuesItems';
        bom_id: string;
        name: string;
        description?: string | null;
        uom: string;
        quantity?: number | null;
        task_list?: {
          __typename?: 'MITask';
          task_id?: string | null;
          task_name?: string | null;
          quantity: number;
        } | null;
      };
      tags?: Array<{
        __typename?: 'Tag';
        _id?: string | null;
        tag_name?: string | null;
      } | null> | null;
      material_tags?: Array<{
        __typename?: 'Tag';
        _id?: string | null;
        tag_name?: string | null;
      } | null> | null;
    } | null>;
  };
};

export type GetMiConsumedCountQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  bomIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  taskIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  vendorIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  startDate?: InputMaybe<Scalars['Long']['input']>;
  endDate?: InputMaybe<Scalars['Long']['input']>;
  memberIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  materialTagIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  miDisplayIds?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  show_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  miStatus?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >;
}>;

export type GetMiConsumedCountQuery = {
  __typename?: 'Query';
  materialIssues: {
    __typename?: 'materialIssues';
    getCount: {
      __typename?: 'MaterialIssuePaginatedCount';
      totalCount: number;
    };
  };
};

export type GetPayablesForVendorQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  vendorId: Scalars['String']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
  limit: Scalars['limit_Int_NotNull_min_1']['input'];
  projects?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  invoices?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  grns?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  pos?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  invoiceNumbers?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  paymentStatus?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >;
  createdAt?: InputMaybe<DateInputProps>;
}>;

export type GetPayablesForVendorQuery = {
  __typename?: 'Query';
  materialVendorPayables: {
    __typename?: 'materialVendorPayables';
    getPayblesForVendor?: Array<{
      __typename?: 'MVPProps';
      _id: string;
      delivery_date?: any | null;
      display_id?: string | null;
      grn_adjustment?: any | null;
      grn_amount?: any | null;
      grn_total?: any | null;
      invoice_adjustment?: any | null;
      invoice_amount?: any | null;
      invoice_number?: string | null;
      invoice_total?: any | null;
      paid_amount?: any | null;
      payment_remarks?: Array<string | null> | null;
      payment_status?: number | null;
      po_amount?: any | null;
      project_id: string;
      project_name?: string | null;
      total_amount?: any | null;
      pending_amount?: any | null;
      type: number;
      hasInvoice?: boolean | null;
      canBePaid?: boolean | null;
      parent_id?: string | null;
      parent_type?: number | null;
      grn_files?: Array<{
        __typename?: 'FileProps';
        mime_type: string;
        name: string;
        url: string;
      } | null> | null;
      invoice_files?: Array<{
        __typename?: 'FileProps';
        mime_type: string;
        name: string;
        url: string;
      } | null> | null;
      po_amounts?: {
        __typename?: 'MvpPoAmountProps';
        grn_amount?: any | null;
        grn_adjustment?: any | null;
        grn_total?: any | null;
        invoice_amount?: any | null;
        invoice_adjustment?: any | null;
        invoice_total?: any | null;
      } | null;
    }> | null;
  };
};

export type GetPayablesForVendorCountQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  vendorId: Scalars['String']['input'];
  projects?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  invoices?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  grns?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  pos?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  invoiceNumbers?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  paymentStatus?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >;
  createdAt?: InputMaybe<DateInputProps>;
}>;

export type GetPayablesForVendorCountQuery = {
  __typename?: 'Query';
  materialVendorPayables: {
    __typename?: 'materialVendorPayables';
    getPayblesForVendorCount: number;
  };
};

export type GetVendorPaymentDetailsQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  vendorId: Scalars['String']['input'];
  projects?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  invoices?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  grns?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  pos?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  invoiceNumbers?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  paymentStatus?: InputMaybe<
    | Array<InputMaybe<Scalars['Int']['input']>>
    | InputMaybe<Scalars['Int']['input']>
  >;
  createdAt?: InputMaybe<DateInputProps>;
}>;

export type GetVendorPaymentDetailsQuery = {
  __typename?: 'Query';
  materialVendorPayables: {
    __typename?: 'materialVendorPayables';
    getVendorPaymentDetails: {
      __typename?: 'MVPVendorAmtDetails';
      amount_to_pay?: any | null;
      grn_amount?: any | null;
      invoice_amount?: any | null;
      amount_paid?: any | null;
      non_invoice_grn_amount?: any | null;
    };
  };
};

export type GetAllProjectsQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
  limit: Scalars['limit_Int_NotNull_min_20_max_100']['input'];
  pinned?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  stages?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  tags?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  sortOption?: InputMaybe<
    Array<InputMaybe<SortOptionProps>> | InputMaybe<SortOptionProps>
  >;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<
    | Array<InputMaybe<Scalars['Number']['input']>>
    | InputMaybe<Scalars['Number']['input']>
  >;
}>;

export type GetAllProjectsQuery = {
  __typename?: 'Query';
  projects: {
    __typename?: 'projects';
    paginateUserProjects: Array<{
      __typename?: 'ProjectProps';
      _id: string;
      name: string;
      description?: string | null;
      org_id: string;
      org_name?: string | null;
      project_address?: string | null;
      project_state?: string | null;
      tc?: string | null;
      promised_cost?: number | null;
      promised_handover_date?: any | null;
      start_date?: any | null;
      end_date?: any | null;
      last_status_update_date?: any | null;
      expected_cost?: number | null;
      expected_handover_date?: any | null;
      photo_url?: string | null;
      is_active?: boolean | null;
      feed_unlocked: boolean;
      project_status?: number | null;
      project_progress?: number | null;
      actual_start_date?: any | null;
      actual_end_date?: any | null;
      planned_start_date?: any | null;
      planned_end_date?: any | null;
      project_plan_type?: any | null;
      project_stages?: Array<{
        __typename?: 'ProjectStageProps';
        _id: string;
        task_status?: number | null;
        task_id?: string | null;
        stage_id: string;
      } | null> | null;
    }>;
  };
};

export type GetStByBomsCountQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  dFilter?: InputMaybe<Scalars['DFilterObject']['input']>;
}>;

export type GetStByBomsCountQuery = {
  __typename?: 'Query';
  siteTransfers: { __typename?: 'siteTransfers'; getSTByBomsCount: number };
};

export type GetSTbyBomsQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  page: Scalars['page_Int_NotNull_min_0']['input'];
  limit: Scalars['limit_Int_NotNull_min_20_max_100']['input'];
  dFilter?: InputMaybe<Scalars['DFilterObject']['input']>;
}>;

export type GetSTbyBomsQuery = {
  __typename?: 'Query';
  siteTransfers: {
    __typename?: 'siteTransfers';
    getSTbyBoms?: Array<{
      __typename?: 'SiteTranferFlatListProps';
      _id: string;
      createdAt: any;
      st_display_id?: string | null;
      source_name?: string | null;
      remarks?: string | null;
      st_status: number;
      is_active: boolean;
      creator_id: string;
      creator_name: string;
      created_at?: any | null;
      updated_at?: any | null;
      boms?: {
        __typename?: 'STBomProps';
        _id: string;
        name: string;
        description?: string | null;
        quantity: number;
        uom?: string | null;
        total_received_quantity?: number | null;
        received_by_name?: string | null;
        remarks?: string | null;
      } | null;
      project: { __typename?: 'ProjectProps'; name: string };
    } | null> | null;
  };
};

export const GetOrgWcOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrgWcOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortOption' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgPackageType' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orgPackages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getAllByOrgId' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'sort_option' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sortOption' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_package_type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgPackageType' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: '_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrgWcOptionsQuery,
  GetOrgWcOptionsQueryVariables
>;
export const GetPayablesForPoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPayablesForPO' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'poId' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vendorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoices' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'grns' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pos' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceNumbers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentStatus' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdAt' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateInputProps' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'materialVendorPayables' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getPayablesForPO' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'po_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'poId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'vendor_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vendorId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'projects' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projects' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'invoices' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invoices' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'grns' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'grns' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pos' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pos' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'invoice_numbers' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invoiceNumbers' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'payment_status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'paymentStatus' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'delivery_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'po_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mime_type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_adjustment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_total' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mime_type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_adjustment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pending_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_total' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'total_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paid_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment_remarks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasInvoice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canBePaid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent_type' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPayablesForPoQuery,
  GetPayablesForPoQueryVariables
>;
export const GetPayablesForInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPayablesForInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vendorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoices' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'grns' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pos' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceNumbers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentStatus' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdAt' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateInputProps' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'materialVendorPayables' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getPayablesForInvoice' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'invoice_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invoiceId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'vendor_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vendorId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'projects' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projects' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'invoices' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invoices' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'grns' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'grns' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pos' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pos' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'invoice_numbers' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invoiceNumbers' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'payment_status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'paymentStatus' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'delivery_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_adjustment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mime_type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_total' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_adjustment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mime_type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_total' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paid_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment_remarks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'po_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'total_amount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasInvoice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canBePaid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent_type' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPayablesForInvoiceQuery,
  GetPayablesForInvoiceQueryVariables
>;
export const GetOrgProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrgProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'page_Int_NotNull_min_0' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'limit_Int_NotNull_min_20_max_100' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginateUserProjects' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'search' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrgProjectsQuery, GetOrgProjectsQueryVariables>;
export const GetAllUsersOfProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllUsersOfProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getUsersByProject' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'projectId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projectId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobile_number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country_code' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isVerified' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usersProject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'actions' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'training_status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllUsersOfProjectQuery,
  GetAllUsersOfProjectQueryVariables
>;
export const GetWcByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWCByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgPackageType' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orgPackages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getAllByOrgId' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_package_type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgPackageType' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hash_value' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_package_type' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWcByOrgIdQuery, GetWcByOrgIdQueryVariables>;
export const GetLinkedtaskForCustomFieldDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLinkedtaskForCustomField' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'showLinkedTasksForCustomField',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'field_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ancestors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'index_number' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLinkedtaskForCustomFieldQuery,
  GetLinkedtaskForCustomFieldQueryVariables
>;
export const GetChecklistForEntityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChecklistForEntity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'entityId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklistitems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getAllForEntity' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'entity_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'entityId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updater_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updater_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator_name' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChecklistForEntityQuery,
  GetChecklistForEntityQueryVariables
>;
export const PettyCashByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PettyCashById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pettyCashes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getById' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pc_display_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'receiver_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'receiver_details' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_display_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ref_item_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment_mode_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment_mode' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'payment_method' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment_date_MS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transaction_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vendor_details' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updater_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updater_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remarks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payer_details' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mime_type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pc_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'users' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'user_profile_url',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dateMS' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'note' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PettyCashByIdQuery, PettyCashByIdQueryVariables>;
export const GetPettyCashByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPettyCashByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'page_Int_NotNull_min_0' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'limit_Int_NotNull_min_20_max_100' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dFilter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DFilterObject' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pettyCashes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getByOrgId' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dFilter' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dFilter' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pc_display_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remarks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_package_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_package_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transaction_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'receiver_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'receiver_details' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payer_details' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vendor_details' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labour_details' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment_mode_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment_mode' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'payment_method' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment_date_MS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mime_type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pc_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'approvers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'users' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'user_profile_url',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dateMS' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'note' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPettyCashByOrgIdQuery,
  GetPettyCashByOrgIdQueryVariables
>;
export const GetPettyCashCountByOrgIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPettyCashCountByOrgId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projects' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dFilter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DFilterObject' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pettyCashes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getCountByOrgId' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'projects' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projects' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dFilter' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dFilter' },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPettyCashCountByOrgIdQuery,
  GetPettyCashCountByOrgIdQueryVariables
>;
export const ConsumtionListQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ConsumtionListQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'page_Int_NotNull_min_0' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'limit_Int_NotNull_min_10' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'materials' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creators' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdAt' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateInputProps' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'types' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vendors' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tasks' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortOption' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SortOptionProps' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'checkers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'members' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'materialTags' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inventoryHistories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getConsumptionList' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'projects' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projects' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'materials' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'materials' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'creators' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'creators' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'types' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'types' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'vendors' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vendors' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'tasks' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tasks' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'sortOption' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sortOption' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'checkers' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'checkers' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'members' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'members' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'material_tags' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'materialTags' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'material' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantity_str' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'specs' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'material_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateMS' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'files' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mi' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ancestors' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'checker_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vendor_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remarks' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'specs' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'item_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'item_display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'material_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConsumtionListQueryQuery,
  ConsumtionListQueryQueryVariables
>;
export const ConsumtionListQueryCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ConsumtionListQueryCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'materials' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creators' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdAt' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateInputProps' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'types' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vendors' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tasks' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'checkers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'members' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'materialTags' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inventoryHistories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getConsumptionListCount' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'projects' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projects' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'materials' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'materials' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'creators' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'creators' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'types' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'types' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'vendors' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vendors' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'tasks' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tasks' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'checkers' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'checkers' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'members' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'members' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'material_tags' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'materialTags' },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConsumtionListQueryCountQuery,
  ConsumtionListQueryCountQueryVariables
>;
export const GetAllProjectsWiseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllProjectsWise' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'page_Int_NotNull_min_0' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'limit_Int_NotNull_min_20_max_100' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginateUserProjects' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'search' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllProjectsWiseQuery,
  GetAllProjectsWiseQueryVariables
>;
export const MaterialIssuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MaterialIssues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'page_Int_NotNull_min_0' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'limit_Int_NotNull_min_20_max_100' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bomIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vendorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'memberIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'materialTagIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'miDisplayIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'show_deleted' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'miStatus' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'materialIssues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getByOrgId' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'bom_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'bomIds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'task_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'taskIds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'vendor_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vendorIds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'start_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'end_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'material_tag_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'materialTagIds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'member_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'memberIds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'mi_display_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'miDisplayIds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'show_deleted' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'show_deleted' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'mi_status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'miStatus' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vendor_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'issued_items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bom_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uom' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_list' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'task_id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'task_name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'quantity' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantity' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'material_tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tag_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mi_display_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'issuer_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mi_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_active' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MaterialIssuesQuery, MaterialIssuesQueryVariables>;
export const GetMiConsumedCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMIConsumedCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bomIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vendorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'memberIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'materialTagIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'miDisplayIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'show_deleted' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'miStatus' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'materialIssues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getCount' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'bom_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'bomIds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'task_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'taskIds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'vendor_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vendorIds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'start_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'end_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'material_tag_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'materialTagIds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'member_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'memberIds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'mi_display_ids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'miDisplayIds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'show_deleted' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'show_deleted' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'mi_status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'miStatus' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMiConsumedCountQuery,
  GetMiConsumedCountQueryVariables
>;
export const GetPayablesForVendorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPayablesForVendor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vendorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'page_Int_NotNull_min_0' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'limit_Int_NotNull_min_1' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoices' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'grns' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pos' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceNumbers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentStatus' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdAt' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateInputProps' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'materialVendorPayables' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getPayblesForVendor' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'vendor_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vendorId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'projects' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projects' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'invoices' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invoices' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'grns' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'grns' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pos' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pos' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'invoice_numbers' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invoiceNumbers' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'payment_status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'paymentStatus' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'delivery_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_adjustment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mime_type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_total' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_adjustment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mime_type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_total' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paid_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment_remarks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'po_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'total_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pending_amount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasInvoice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canBePaid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'po_amounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'grn_amount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'grn_adjustment' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'grn_total' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'invoice_amount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'invoice_adjustment',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'invoice_total' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPayablesForVendorQuery,
  GetPayablesForVendorQueryVariables
>;
export const GetPayablesForVendorCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPayablesForVendorCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vendorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoices' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'grns' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pos' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceNumbers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentStatus' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdAt' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateInputProps' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'materialVendorPayables' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getPayblesForVendorCount' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'vendor_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vendorId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'projects' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projects' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'invoices' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invoices' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'grns' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'grns' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pos' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pos' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'invoice_numbers' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invoiceNumbers' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'payment_status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'paymentStatus' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPayablesForVendorCountQuery,
  GetPayablesForVendorCountQueryVariables
>;
export const GetVendorPaymentDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getVendorPaymentDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vendorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projects' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoices' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'grns' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pos' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceNumbers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentStatus' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createdAt' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateInputProps' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'materialVendorPayables' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getVendorPaymentDetails' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'vendor_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vendorId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'projects' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'projects' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'invoices' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invoices' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'grns' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'grns' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pos' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pos' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'invoice_numbers' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invoiceNumbers' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'payment_status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'paymentStatus' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount_to_pay' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grn_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoice_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount_paid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'non_invoice_grn_amount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVendorPaymentDetailsQuery,
  GetVendorPaymentDetailsQueryVariables
>;
export const GetAllProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'page_Int_NotNull_min_0' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'limit_Int_NotNull_min_20_max_100' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pinned' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'stages' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortOption' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SortOptionProps' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Number' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paginateUserProjects' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pinned' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pinned' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'sortOption' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sortOption' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'search' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'search' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'tags' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tags' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'stages' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'stages' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_state' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'tc' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promised_cost' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promised_handover_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end_date' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'last_status_update_date',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expected_cost' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expected_handover_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photo_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'feed_unlocked' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_progress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actual_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actual_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'planned_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'planned_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_stages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stage_id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_plan_type' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllProjectsQuery, GetAllProjectsQueryVariables>;
export const GetStByBomsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSTByBomsCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dFilter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DFilterObject' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'siteTransfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getSTByBomsCount' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dFilter' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dFilter' },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetStByBomsCountQuery,
  GetStByBomsCountQueryVariables
>;
export const GetSTbyBomsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSTbyBoms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'page_Int_NotNull_min_0' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'limit_Int_NotNull_min_20_max_100' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dFilter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DFilterObject' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'siteTransfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getSTbyBoms' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dFilter' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dFilter' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'st_display_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'source_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remarks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boms' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uom' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'total_received_quantity',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'received_by_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'remarks' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'st_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creator_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'st_status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_active' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSTbyBomsQuery, GetSTbyBomsQueryVariables>;
