export const LOGIN = 'LOGIN';
export const GET_USER = 'GET_USER';
export const GET_USER_PENDING = 'GET_USER_PENDING';
export const LOGIN_CHECK = 'LOGIN_CHECK';
export const LOGOUT = 'LOGOUT';
export const REQ_SUPPORT_CALL_SUCCESS = 'REQ_SUPPORT_CALL_SUCCESS';
export const USER_API_PENDING = 'USER_API_PENDING';
export const USER_API_SUCCESS = 'USER_API_SUCCESS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const AUTH_VIEW_INDEX_CHANGE = 'AUTH_VIEW_INDEX_CHANGE';
export const USER_API_ERROR = 'USER_API_ERROR';
export const SEND_USER_OTP = 'SEND_USER_OTP';
export const SEND_USER_OTP_PENDING = 'SEND_USER_OTP_PENDING';
export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_PENDING = 'VERIFY_OTP_PENDING';
export const EDIT_USER_DISPLAYNAME_PENDING = 'EDIT_USER_DISPLAYNAME_PENDING';
export const GET_USER_ORGS_PENDING = 'GET_USER_ORGS_PENDING';
export const GET_USER_ORGS = 'GET_USER_ORGS';
export const OTP_ERROR = 'OTP_ERROR';
export const VERIFY_USER_EMAIL_PENDING = 'VERIFY_USER_EMAIL_PENDING';
export const SET_TOKEN_EXPIRY = 'SET_TOKEN_EXPIRY';
export const GET_SELF_PERMISSION = 'GET_SELF_PERMISSION';
export const GET_SIGNATURE = 'GET_SIGNATURE';
export const SET_COUNTRIES = 'SET_COUNTRIES';
