import {
  SET_PAYMENT_MODE,
  SET_PETTY_CASH_API_ERROR,
  SET_PETTY_CASH_API_LOADING,
  SET_PETTY_CASH_API_PENDING,
  SET_PETTY_CASH_API_SUCCESS,
} from './PettyCash.type';
import { ReducerProps } from '../../../../interfaces/Base';

const initialState = {
  paymentModes: [],
  pending: false,
  error: false,
  loading: false,
};

export default function pettyCashReducer(
  state = initialState,
  { type, payload }: ReducerProps
) {
  switch (type) {
    case SET_PAYMENT_MODE:
      return { ...state, paymentModes: payload };
    case SET_PETTY_CASH_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case SET_PETTY_CASH_API_PENDING:
      return {
        ...state,
        pending: payload,
      };
    case SET_PETTY_CASH_API_ERROR:
      return {
        ...state,
        pending: false,
        error: payload,
      };
    case SET_PETTY_CASH_API_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
}
