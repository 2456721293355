import {
  CREATE_THREAD_PENDING,
  CREATE_THREAD_WITH_ITEM_PENDING,
  GET_THREAD_PENDING,
  GET_THREAD,
  GET_THREADS_LIST_PENDING,
  GET_THREADS_LIST,
  UPDATE_THREAD_PENDING,
  DELETE_THREAD_PENDING,
  THREAD_API_ERROR,
  GET_THREADS_WITH_ITEM_LIST,
  GET_THREADS_WITH_ITEM_PENDING,
  SET_THREADS_PER_PAGE,
  CLEAR_THREAD_DETAILS,
} from './Thread.type';

import {
  UPLOAD_COMMENT_FILES_PENDING,
  UPLOAD_COMMENT_ATTACHED_FILES,
  FILES_COMMENT_ID,
} from '../../common/comment/Comment.type';
import { ReducerProps } from '../../../../interfaces/Base';

const initialPendingState = {
  createThreadPending: false,
  createThreadWithItemPending: false,
  getThreadPending: false,
  getThreadListPending: false,
  getThreadListWithItemPending: false,
  updateThreadPending: false,
  deleteThreadPending: false,
  uploadCommentFilesPending: false,
  rowsPerPage: 20,
};

const initialState = {
  ...initialPendingState,
  thread: [],
  error: null,
  threadsList: [],
  threadsListWithItem: null,
  attachedFiles: [],
  filesCommentId: null,
};

export default function threadReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
) {
  switch (type) {
    case CREATE_THREAD_PENDING:
      return { ...state, createThreadPending: payload };
    case CREATE_THREAD_WITH_ITEM_PENDING:
      return { ...state, createThreadWithItemPending: payload };
    case GET_THREADS_WITH_ITEM_PENDING:
      return { ...state, getThreadListWithItemPending: payload };
    case GET_THREADS_WITH_ITEM_LIST:
      return {
        ...state,
        threadsListWithItem: payload,
      };
    case UPLOAD_COMMENT_FILES_PENDING:
      return { ...state, uploadCommentFilesPending: payload };
    case GET_THREAD:
      return { ...state, thread: payload, getThreadListPending: false };
    case GET_THREAD_PENDING:
      return { ...state, getThreadPending: payload };
    case FILES_COMMENT_ID:
      return { ...state, filesCommentId: payload };
    case UPLOAD_COMMENT_ATTACHED_FILES:
      return { ...state, attachedFiles: payload };
    case GET_THREADS_LIST:
      return { ...state, threadsList: payload, getThreadListPending: false };
    case GET_THREADS_LIST_PENDING:
      return { ...state, getThreadListPending: payload };
    case UPDATE_THREAD_PENDING:
      return { ...state, updateThreadPending: payload };
    case DELETE_THREAD_PENDING:
      return { ...state, deleteThreadPending: payload };
    case THREAD_API_ERROR:
      return { ...state, error: error };
    case SET_THREADS_PER_PAGE:
      return { ...state, rowsPerPage: payload };
    case CLEAR_THREAD_DETAILS:
      return { ...state, thread: null };
    default:
      return state;
  }
}
