import React, { useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import CancelIcon from '@mui/icons-material/CancelRounded';
import Dialog from '@mui/material/Dialog';

import { UploadFileIcon } from './Icons';
import CustomButton from './customButton/CustomButton';
import { RenderFile } from './FilePreviewer';
import { ACCEPTED_FILE_FORMAT } from '../../constants';
import { FileProps } from '../../interfaces/Base';
import { CustomDialogContent } from './CustomDialog';
import { track } from '../../redux/features/common/Segment.action';
import { useAppDispatch } from '../../redux/Hooks';

const MAX_NUM_IMAGES = 10;
interface MyDropzoneProps {
  files: any[];
  setFiles: React.Dispatch<React.SetStateAction<any[]>>;
  isMultiple?: boolean;
  acceptedFiles?: string[];
  handleUpload: () => void;
  isUploading?: boolean;
  maxNumImages?: number;
  dropZoneClickEvent?: { eventName: string; eventData: any };
}

function MyDropzone({
  files = [],
  setFiles,
  isMultiple = false,
  maxNumImages = MAX_NUM_IMAGES,
  acceptedFiles = ACCEPTED_FILE_FORMAT,
  handleUpload,
  dropZoneClickEvent,
  isUploading = false,
}: MyDropzoneProps) {
  useEffect(() => {
    return () => {
      setFiles([]);
    };
  }, []);
  const dispatch = useAppDispatch();

  const onDrop = useCallback(acceptedFiles => {
    if (dropZoneClickEvent) {
      dispatch(
        track(dropZoneClickEvent.eventName, dropZoneClickEvent.eventData)
      );
    }
    setFiles(
      acceptedFiles.map(file => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      })
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFiles,
    multiple: isMultiple,
    onDrop,
  });

  const handleRemove = (file: FileProps) => () => {
    const index = files.indexOf(file);
    const newFiles = files.slice(0);
    newFiles.splice(index, 1);
    URL.revokeObjectURL(file.preview);
    setFiles(newFiles);
  };

  return (
    <>
      <div>
        <div {...getRootProps()} className="p-3 po-upload">
          <input {...getInputProps()} />
          <UploadFileIcon />
          Click or drag files to upload
        </div>
      </div>
      <div>
        <div className="file-previewer mt-3 mb-3">
          {files.map((file, idx) => (
            <div className="uploadable-text-box" key={idx}>
              <RenderFile file={file} size="small" />
              <CancelIcon
                onClick={handleRemove(file)}
                className="hand-pointer remove-img-sm"
              />
            </div>
          ))}
        </div>
      </div>
      {files.length > 0 ? (
        <>
          <CustomButton
            label="Upload"
            buttonSize="medium"
            spinner={isUploading}
            disabled={isUploading || files.length > maxNumImages}
            className="fw-bold"
            handleSubmit={handleUpload}
          />
          {files.length > maxNumImages ? (
            <div className="text-red fs-s fw-bold">
              {`Only ${maxNumImages} file uploads are allowed`}
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
}

interface UploadFilesModalProps extends MyDropzoneProps {
  show?: boolean;
  onHide: () => void;
}

const UploadFilesModal = ({
  show = false,
  onHide,
  ...restProps
}: UploadFilesModalProps) => {
  return (
    <Dialog open={show} maxWidth="sm" fullWidth onClose={onHide}>
      <CustomDialogContent>
        <MyDropzone {...restProps} />
      </CustomDialogContent>
    </Dialog>
  );
};

export default UploadFilesModal;
