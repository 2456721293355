import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Progress from '@mui/material/LinearProgress';
import CheckCircleRoundedIcon from '@mui/icons-material//CheckCircleRounded';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Fade from '@mui/material/Fade';
import SaveAltIcon from '@mui/icons-material//SaveAlt';
import Alert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle';
import DescriptionIcon from '@mui/icons-material//Description';

import {
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
  SideScreenDialog,
} from '../CustomDialog';
import {
  uploadExcel,
  clearDocState,
  updateExcel,
} from '../../../redux/features/common/document/Document.action';
import {
  UploadMspIcon,
  UploadPlanIcon,
  UploadXmlIcon,
  UploadXlsxIcon,
  UploadCsvIcon,
} from '../Icons/UploadIcon';
import CustomButton from '../customButton/CustomButton';
import { CustomBlueRadio as RadioButton } from '../customControlComponents/CustomControlComponents';
import { getFileExtension } from '../../../utils/MimeType.util';
import { EXPERIMENTS } from '../../../constant/experiment_constants';
import { EXCEL_UPLOAD_ERROR } from '../../../redux/features/common/document/Document.type';
import { eventDispatch } from '../../../redux/base';
import { GANTT_DEFAULT_LEAD_DAY } from '../../../constants';
import useAddOn from '../../../utils/hooks/useAddOn.hook';
import { ADD_ONS } from '../../../constant/module_constants';
import { useAppDispatch, useAppSelector } from '../../../redux/Hooks';
import './customMspUpload.scss';
import { track } from '../../../redux/features/common/Segment.action';
import { ACTION_EVENTS } from '../../../constant/events';

interface Props {
  onHide: any;
  url: string;
  type?: string;
  fileType?: string;
  defaultUploadType?: string;
  show: any;
  sampleFileUrl?: any;
  sampleNestedFileUrl?: any;
  replace: boolean;
  specificFields?: Array<string>;
  addExisting: boolean;
  nestedUpload?: boolean;
  xmlUpload?: boolean;
  mainTaskUpload?: boolean;
  setXmlUpload?: React.Dispatch<React.SetStateAction<boolean>>;
  setNestedUpload?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
  setReplaceableUpload: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadFileType: React.Dispatch<React.SetStateAction<string>>;
  setUrlType: React.Dispatch<React.SetStateAction<string>>;
  setAddExisting: React.Dispatch<React.SetStateAction<boolean>>;
  setShowBaselineDialog?: React.Dispatch<React.SetStateAction<boolean>>;
}

function CustomMspUpload({
  onHide,
  url,
  type,
  fileType,
  show,
  replace = false,
  specificFields,
  sampleFileUrl,
  sampleNestedFileUrl,
  addExisting,
  setUploadFileType,
  setShowUploadModal,
  setAddExisting,
  setUrlType,
  setShowBaselineDialog,
  mainTaskUpload = false,
  defaultUploadType = null,
}: Props) {
  const DEFAULT_SELECTED_UPLOAD_TYPE = defaultUploadType
    ? defaultUploadType
    : 'MPP/XML';
  const [files, setFiles] = useState<any>([]);
  const pending = useAppSelector(state => state.documentreducer.pending);
  const experiments: Map<string, boolean> | null = useAppSelector(
    state => state.organisationReducer.experiments
  );
  const showNestedPlan = experiments?.get(EXPERIMENTS.WEB_NESTED_PLAN_UPLOAD);
  const showDateFormat = experiments?.get(
    EXPERIMENTS.EXP_FORMAT_DATE_TASK_PLAN_UPLOAD
  );

  const projectId = useAppSelector(
    state => state.projectreducer.selectedProj.id
  );

  const orgProfile = useAppSelector(
    state => state.organisationReducer.orgProfile
  );
  const { _id: orgId, org_name } = orgProfile;
  const docUploaded = useAppSelector(
    state => state.documentreducer.docUploaded
  );
  const uploadError = useAppSelector(state => state.documentreducer.excelError);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadType, setUploadType] = useState('');
  const [selectedValue, setSelectedValue] = useState(
    DEFAULT_SELECTED_UPLOAD_TYPE
  );
  const [fileExtension, setFileExtension] = useState();
  const [uploadDateFormat, setUploadDateFormat] = useState('dd-MM-yyyy');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (docUploaded) {
      setUploadSuccess(true);
      setIsUploading(false);
      setFiles([]);
      dispatch(clearDocState());
    }
  }, [docUploaded]);

  useEffect(() => {
    if (!pending) {
      setIsUploading(false);
      setUploadSuccess(false);
    }
  }, [pending]);

  const handleSubmit = () => {
    setIsUploading(true);
    const data = new FormData();
    const reqData = {
      project_id: projectId,
      org_id: orgId,
      org_name,
      replace,
      sampleFileUrl,
      default_lag_day: GANTT_DEFAULT_LEAD_DAY,
    };

    if (showDateFormat) {
      reqData['upload_date_format'] = uploadDateFormat;
    }
    files.forEach((file: any) => {
      data.append('files[]', file);
    });

    let upload_type;
    if (addExisting) {
      upload_type = 'add_existing';
    } else if (replace && specificFields?.length == 0) {
      upload_type = 'replace';
    } else if (specificFields?.length > 0) {
      upload_type = 'replace';
      reqData['fields'] = specificFields;
    } else {
      upload_type = fileType === 'excel' ? 'excel_upload' : 'upload_plan';
    }

    data.append('data', JSON.stringify(reqData));

    setUploadType(upload_type);

    if (upload_type === 'replace' && specificFields?.length > 0) {
      dispatch(updateExcel(url, data, type, upload_type));
    } else {
      dispatch(uploadExcel(url, data, type, null, null, upload_type));
    }
  };

  useEffect(() => {
    if (files?.length) {
      if (!docUploaded) {
        handleSubmit();
      } else {
        setIsUploading(false);
        setUploadSuccess(false);
      }
    }
  }, [files]);

  const onDrop = useCallback(acceptedFiles => {
    const tempFile = acceptedFiles[0]?.name;
    const tmpfileExtension = getFileExtension(acceptedFiles[0]?.name);
    setFiles(
      acceptedFiles.map((file: any) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      })
    );
    setUploadedFile(tempFile);
    setFileExtension(tmpfileExtension);
    dispatch(track(ACTION_EVENTS.TASK_ACTIONS.TASK_VIEW_FILE_UPLOAD_CLICK));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      fileType === 'xml'
        ? ['.xml', '.mpp']
        : [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            '.xlsx',
            '.xls',
          ],
    multiple: false,
    onDrop,
  });

  const handleClose = () => {
    onHide();
    setIsUploading(false);
    setFiles([]);
    setUploadSuccess(false);
    setSelectedValue(DEFAULT_SELECTED_UPLOAD_TYPE);
    dispatch(clearDocState());
    if (uploadSuccess && uploadType == 'add_existing' && setShowBaselineDialog)
      setShowBaselineDialog(true);
  };

  const handleExcelClick = () => {
    setUploadFileType('excel');
    if (!mainTaskUpload) setUrlType('task/upload');
    setShowUploadModal(true);
    setSelectedValue('XLS/XLSX');
    dispatch(eventDispatch(EXCEL_UPLOAD_ERROR, []));
  };

  const handleNestedExcelClick = () => {
    setUploadFileType('excel');
    setUrlType('tasks/nested-excel-upload');
    setShowUploadModal(true);
    setSelectedValue('NESTED/EXCEL');
    dispatch(eventDispatch(EXCEL_UPLOAD_ERROR, []));
  };

  const handleMppClick = () => {
    setAddExisting(true);
    setUploadFileType('xml');
    setUrlType('project-plan/upload-mpp');
    setSelectedValue('MPP/XML');
    dispatch(eventDispatch(EXCEL_UPLOAD_ERROR, []));
  };

  const handleDateFormatChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const dateFormat = event.target.value;
    setUploadDateFormat(dateFormat);
  };

  const getFileIcon = () => {
    switch (fileExtension as any) {
      case 'mpp':
        return <UploadMspIcon />;
      case 'xml':
        return <UploadXmlIcon />;
      case 'xls':
      case 'xlsx':
        return <UploadXlsxIcon />;
      case 'csv':
        return <UploadCsvIcon />;
    }
  };

  const { allowed: showWBS } = useAddOn(ADD_ONS.WBS);

  return (
    <>
      <SideScreenDialog open={show} fullWidth maxWidth="sm">
        <CustomDialogTitle onClose={!isUploading ? handleClose : null}>
          {'Upload Plan'}
        </CustomDialogTitle>
        <CustomDialogContent dividers={false}>
          <>
            {!uploadSuccess ? (
              <>
                {!isUploading ? (
                  <>
                    <div className="mb-4 w-100 d-flex align-items-center">
                      <div className="text-grey-900 fw-light fes-s mt-n2">
                        {'FILE TYPE:'}
                      </div>
                      <RadioGroup
                        row
                        name="position"
                        defaultValue={selectedValue}
                      >
                        {showWBS &&
                        !mainTaskUpload &&
                        specificFields?.length <= 0 ? (
                          <FormControlLabel
                            value="MPP/XML"
                            className={
                              selectedValue === 'MPP/XML'
                                ? 'bg-blue-200 pr-3 text-primary rounded mx-2 fes-s fw-bold mspUploadFileType'
                                : 'text-grey pr-3 mx-2 fes-s fw-bold mspUploadFileType'
                            }
                            control={
                              <Radio
                                size="small"
                                color="primary"
                                onClick={handleMppClick}
                              />
                            }
                            label="MPP/XML"
                          />
                        ) : null}
                        {specificFields?.length <= 0 ? (
                          <FormControlLabel
                            className={
                              selectedValue === 'XLS/XLSX'
                                ? 'bg-blue-200 text-primary pr-3 rounded mx-2 fes-s fw-bold mspUploadFileType'
                                : 'text-grey pr-3 mx-2 fes-s fw-bold mspUploadFileType'
                            }
                            value="XLS/XLSX"
                            control={
                              <Radio
                                size="small"
                                color="primary"
                                onClick={handleExcelClick}
                              />
                            }
                            label="XLS/XLSX"
                          />
                        ) : null}
                        {showNestedPlan && showWBS && !mainTaskUpload ? (
                          <FormControlLabel
                            className={
                              selectedValue === 'NESTED/EXCEL'
                                ? 'bg-blue-200 text-primary pr-3 rounded mx-2 fes-s fw-bold mspUploadFileType'
                                : 'text-grey pr-3 mx-2 fes-s fw-bold mspUploadFileType'
                            }
                            value="NESTED/EXCEL"
                            control={
                              <Radio
                                size="small"
                                color="primary"
                                onClick={handleNestedExcelClick}
                              />
                            }
                            label="Nested Excel"
                          />
                        ) : null}
                      </RadioGroup>
                    </div>

                    <div {...getRootProps()} className="w-100">
                      <input {...getInputProps()} />

                      <div className="msp-dropzone hand-pointer mx-auto text-center bg-white">
                        <UploadPlanIcon
                          style={{ fontSize: '100px' }}
                          className="mx-auto"
                        />
                        <div className="fw-bold fs-l mt-3 mb-2">
                          {' '}
                          Drop or Select file to Upload{' '}
                        </div>
                        <div
                          className=" fs-l mt-2"
                          style={{ color: '#666666' }}
                        ></div>
                      </div>
                    </div>
                    {selectedValue === 'XLS/XLSX' ? (
                      <div className="mt-4">
                        <Fade in={selectedValue === 'XLS/XLSX'}>
                          <a className="pt-4" href={sampleFileUrl} download>
                            <div className="d-flex align-items-center">
                              <SaveAltIcon fontSize="small" />
                              <div className="px-1">
                                Download sample spreadsheet
                              </div>
                              <DescriptionIcon
                                className="text-blue-secondary"
                                fontSize="medium"
                              />
                            </div>
                          </a>
                        </Fade>
                      </div>
                    ) : (
                      ''
                    )}
                    {selectedValue === 'NESTED/EXCEL' ? (
                      <div className="mt-4">
                        <Fade in={selectedValue === 'NESTED/EXCEL'}>
                          <a href={sampleNestedFileUrl} download>
                            <div className="d-flex align-items-center">
                              <SaveAltIcon fontSize="small" />
                              <div className="px-1">
                                Download sample spreadsheet
                              </div>
                              <DescriptionIcon
                                className="text-blue-secondary"
                                fontSize="medium"
                              />
                            </div>
                          </a>
                        </Fade>
                      </div>
                    ) : (
                      ''
                    )}
                    {showDateFormat &&
                    (selectedValue === 'NESTED/EXCEL' ||
                      selectedValue === 'XLS/XLSX') ? (
                      <div className="mt-4">
                        <Fade
                          in={
                            selectedValue === 'NESTED/EXCEL' ||
                            selectedValue === 'XLS/XLSX'
                          }
                        >
                          <div className="mt-4 w-100">
                            <div className="text-grey-secondary fes-xl fw-bold mb-2">
                              Choose your date format for uploading work plan
                            </div>
                            <RadioGroup
                              row
                              name="date-format"
                              defaultValue="dd-MM-yyyy"
                              onChange={handleDateFormatChange}
                            >
                              <FormControlLabel
                                control={
                                  <RadioButton
                                    size="small"
                                    value={'dd-MM-yyyy'}
                                  />
                                }
                                label="DD-MM-YYYY"
                              />
                              <FormControlLabel
                                control={
                                  <RadioButton
                                    size="small"
                                    value={'yyyy-MM-dd'}
                                  />
                                }
                                label="YYYY-MM-DD"
                              />
                              <FormControlLabel
                                control={
                                  <RadioButton size="small" value={'d.M.yy'} />
                                }
                                label="D.M.YY"
                              />
                              <FormControlLabel
                                control={
                                  <RadioButton
                                    size="small"
                                    value={'dd MMMM yyyy'}
                                  />
                                }
                                label="DD MMMM YYYY"
                              />
                              <FormControlLabel
                                control={
                                  <RadioButton
                                    size="small"
                                    value={'d-MMM-yyyy'}
                                  />
                                }
                                label="D-MMM-YYYY"
                              />
                              <FormControlLabel
                                control={
                                  <RadioButton
                                    size="small"
                                    value={'dd/MM/yyyy'}
                                  />
                                }
                                label="DD/MM/YYYY"
                              />
                              <FormControlLabel
                                control={
                                  <RadioButton
                                    size="small"
                                    value={'MM/dd/yyyy'}
                                  />
                                }
                                label="MM/DD/YYYY"
                              />
                            </RadioGroup>
                          </div>
                        </Fade>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <div
                    className="mx-auto my-5 text-center p-5"
                    style={{ width: 250 }}
                  >
                    {getFileIcon()}
                    <div className=" text-center mt-2">{files[0].name}</div>
                    <Progress
                      className="mt-3 rounded"
                      style={{ height: 8 }}
                      classes={{ bar: 'rounded' }}
                    />
                    <div className="mt-2">Processing</div>
                  </div>
                )}
              </>
            ) : (
              <div className="mx-auto my-5 text-center p-5">
                {getFileIcon()}
                <div className="text-center mt-2">{uploadedFile}</div>
                <div className="hand-pointer icons-list mt-3 fes-l text-success">
                  <CheckCircleRoundedIcon fontSize="small" className="mx-2" />
                  Successfully Uploaded
                </div>
              </div>
            )}
            {!uploadSuccess ? (
              <div>
                {uploadError?.length ? (
                  <div className="excel-error mt-4">
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      <ul className="pl-0 ml-n3">
                        {uploadError?.map((err: any, idx: any) => (
                          <li key={idx}>
                            <span className="fw-bold text-black">
                              {err.message}
                            </span>{' '}
                            on Row No: {err.rowNum}
                          </li>
                        ))}
                      </ul>
                    </Alert>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </>
        </CustomDialogContent>

        {uploadSuccess ? (
          <CustomDialogActions leftAlign>
            <CustomButton
              label="Done"
              buttonSize="small"
              onClick={handleClose}
              size="small"
            />
          </CustomDialogActions>
        ) : (
          ''
        )}
      </SideScreenDialog>
    </>
  );
}

export default CustomMspUpload;
