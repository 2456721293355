import { ADD_ONS, RESOURCE_ID_MAP } from '../../constant/module_constants';
import { UserPermissionsMapProps } from '../../interfaces/Team';
import { useAppSelector } from '../../redux/Hooks';
import useAddOn from './useAddOn.hook';

const useModuleAccess = (resource_id: string) => {
  const userPermissions: UserPermissionsMapProps = useAppSelector(
    state => state.userreducer.userPermissionMap
  );
  const proOnlyPO = useAddOn(ADD_ONS.PRO_PO).allowed;

  const resourcePermission = userPermissions.get(resource_id);

  if (!resourcePermission) {
    return {
      permissions: {
        view: false,
        edit: false,
        create: false,
        delete: false,
      },
      access_type: 0,
      resource_access: false,
      view_access: false,
    };
  }

  if (
    proOnlyPO &&
    resource_id === RESOURCE_ID_MAP.PURCHASE_ORDER &&
    !resourcePermission.view_access
  ) {
    return { ...resourcePermission, view_access: true, resource_access: true };
  }

  return resourcePermission;
};

export default useModuleAccess;
