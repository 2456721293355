import { ADD_ONS, SUBSCRIPTION_TYPES } from '../../constant/module_constants';
import { ValueOfType } from '../../interfaces/Base';
import { SubscriptionProps } from '../../interfaces/Team';
import { useAppSelector } from '../../redux/Hooks';

type ReturnType = { allowed: boolean };

const useAddOn = (addOn: ValueOfType<typeof ADD_ONS>): ReturnType => {
  const sub: SubscriptionProps | null = useAppSelector(
    state => state.userreducer.subscription
  );

  if (!sub) {
    return { allowed: true };
  }

  switch (addOn) {
    case ADD_ONS.WBS: {
      if (sub.sub_type !== SUBSCRIPTION_TYPES.PRO) {
        return { allowed: true };
      }

      const wbs = sub.add_ons.find(v => v.type === addOn);

      return { allowed: wbs?.selected || false };
    }

    case ADD_ONS.PRO_PO: {
      if (sub.sub_type !== SUBSCRIPTION_TYPES.PRO) {
        return { allowed: false };
      }

      const proMaterial = sub.add_ons.find(
        it => it.type === ADD_ONS.PRO_WITH_MATERIAL
      )?.selected;
      const proWithPO = sub.add_ons.find(
        it => it.type === ADD_ONS.PRO_WITH_PO
      )?.selected;

      return { allowed: proMaterial && !proWithPO };
    }

    default:
      return { allowed: true };
  }
};

export default useAddOn;
