import { variables } from '../components/common/Theme';

export const PROJECT_STATUS = {
  NOT_STARTED: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  ON_HOLD: 3,
} as const;

export const PROJECT_STATUS_VALUES = {
  [PROJECT_STATUS.IN_PROGRESS]: 'Active',
  [PROJECT_STATUS.COMPLETED]: 'Completed',
  [PROJECT_STATUS.ON_HOLD]: 'On Hold',
} as const;

export const PROJECT_STATUS_COLOR_MAP = {
  [PROJECT_STATUS.IN_PROGRESS]: variables.yellow,
  [PROJECT_STATUS.ON_HOLD]: variables.orange,
  [PROJECT_STATUS.COMPLETED]: variables.green,
} as const;

export const PLAN_CREATION_STATUS = Object.freeze({
  NOT_STARTED: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  FAILED: 3,
});

export const TAX_VALUE = Object.freeze({
  0: 0,
  1: 5,
  2: 12,
  3: 18,
  4: 28,
  5: 3,
  6: 6,
});

export type PROJECT_STATUS_ENUM =
  (typeof PROJECT_STATUS)[keyof typeof PROJECT_STATUS];

export type PLAN_CREATION_STATUS_ENUM =
  (typeof PLAN_CREATION_STATUS)[keyof typeof PLAN_CREATION_STATUS];

export const PROJECT_DUPLICATE_FIELDS = Object.freeze({
  PROJECT: {
    ADDRESS: 0,
    POCS: 1,
    MEMBERS: 2,
    PURCHASE_SETTINGS: 3,
  },
  TASK: {
    TASKS: 0,
    DEPENDENCIES: 1,
    DATES: 2,
    CUSTOM_FIELDS: 3,
    TASK_PROGRESS: 4,
  },
});

export const DATA_EXPORT_STATUS = {
  FAILED: -1,
  NOT_STARTED: 0,
  IN_PROGRESS: 1,
  FINISHED: 2,
};

export const TEAM_ROLE_IDS = Object.freeze({
  ADMIN: 'TEAM100',
  ON_SITE: 'TEAM104',
  ORGANIZATION_MANAGER: 'TEAM107',
  COLLABORATOR_ORGANISATION: 'TEAM106',
  PROJECT_MANAGER: 'TEAM101',
});

export const PROJECT_PLAN_TYPES = {
  DETAILED_PLAN: 1,
  BROAD_PLAN: 0,
};

export const DASHBOARD_FILTER_SOURCES = {
  PROJECT_STATUS: 'project_status',
  TASK_UPDATES: 'task_updates',
  UPCOMING_TASKS: 'upcoming_tasks',
  DEADLINE_TASKS: 'deadline_tasks',
};
