import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import * as yup from 'yup';

import validateForm from '../../../../../utils/Validation.util';
import { updateUserDetails } from '../../../../../redux/features/common/user';
import { compressSingleFile } from '../../../../../utils/CompressFiles.util';
import { singleS3UploadHelper } from '../../../../../utils/Upload.util';
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../../../utils/Toast.util';
import { UPLOAD_FILE_MESSAGE } from '../../../../../constant/toast_message_constants';
import CustomButton from '../../../../common/customButton/CustomButton';
import ProfileImage from '../../../../common/ProfileImage';
import { useAppDispatch, useAppSelector } from '../../../../../redux/Hooks';

const NAME_LENGTH_LIMIT = 30;
const EditProfile = () => {
  const [validationErrorList, setValidationErrorList] = useState<string[]>([]);
  const displayName = useAppSelector(state => state.userreducer.displayName);
  const mobile_number = useAppSelector(
    state => state.userreducer.mobile_number
  );
  const email = useAppSelector(state => state.userreducer.email) || '';
  const docUploaded = useAppSelector(
    state => state.documentreducer.docUploaded
  );
  const userId = useAppSelector(state => state.userreducer._id);
  const photoUrl = useAppSelector(state => state.userreducer.photo_url);

  const dispatch = useAppDispatch();
  const editProfileFormObj: {
    displayName: string;
    email: string;
    mobile_number: string;
    photo_url?: string | null;
  } = {
    displayName: displayName,
    email: email,
    mobile_number: mobile_number,
    photo_url: '',
  };

  const [formObj, setFormObj] = useState(editProfileFormObj);

  const schema = yup.object().shape({
    displayName: yup.string().required(),
    email: yup.string().email().required(),
    mobile_number: yup
      .string()
      .matches(/^[6-9]\d{9}$/)
      .nullable(),
  });

  const handleChange =
    (key: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      if (key === 'displayName' && val.length > NAME_LENGTH_LIMIT) return;

      setFormObj({
        ...formObj,
        [key]: val,
      });
    };

  const handleSubmit = async () => {
    const { isError, valErrors } = await validateForm(schema, formObj);
    if (!isError) {
      setValidationErrorList([]);
      dispatch(updateUserDetails(formObj as any));
    } else {
      setValidationErrorList(valErrors);
    }
  };

  const handleUpload = async (e: any) => {
    const file = await compressSingleFile(e.target.files[0]);
    const uploadedImg = await singleS3UploadHelper(
      file,
      `user/${userId}`,
      'logo'
    );

    if (uploadedImg) {
      const addformObj = {
        ...formObj,
        photo_url: uploadedImg,
      };
      setFormObj({ ...addformObj });
      dispatch(updateUserDetails(addformObj as any));
      toastSuccessMessage(UPLOAD_FILE_MESSAGE.imgSuccess);
    } else {
      toastErrorMessage(UPLOAD_FILE_MESSAGE.imgError);
    }
  };

  useEffect(() => {
    if (photoUrl) {
      setFormObj({ ...formObj, photo_url: photoUrl });
    }
  }, [photoUrl]);

  useEffect(() => {
    if (docUploaded) {
      dispatch(updateUserDetails(formObj as any));
    }
  }, [docUploaded]);

  const handleRemove = () => {
    const removeformObj = {
      ...formObj,
      photo_url: null,
    };
    setFormObj({ ...removeformObj });
    dispatch(updateUserDetails(removeformObj as any));
  };

  return (
    <div>
      <div className="d-flex w-100 mt-n2 flex-column align-items-center">
        <div className="user-profile-name-abbrevation-icon">
          <ProfileImage
            user={{
              user_name: formObj.displayName,
              photo_url: formObj.photo_url,
            }}
            size="profile"
            className="fs-xxl"
          />
        </div>
        <div>
          <div className="d-flex flex-row">
            <input
              className="upload-image-toggle"
              accept="image/*"
              multiple={false}
              onChange={handleUpload}
              type="file"
              id="files"
            />
            <label
              className="upload-image-toggle-label hand-pointer"
              htmlFor="files"
            >
              Upload | &nbsp;
            </label>
            <div
              className="upload-image-toggle-label hand-pointer"
              onClick={handleRemove}
            >
              Remove
            </div>
          </div>
        </div>
        <div className="form-group w-100 px-4">
          <div className="mt-5">
            <div>
              <TextField
                variant="outlined"
                label="Name"
                type="text"
                name="displayName"
                value={formObj.displayName}
                defaultValue={displayName}
                onChange={handleChange('displayName')}
                fullWidth={true}
                error={
                  validationErrorList.indexOf('displayName') > -1 ? true : false
                }
                helperText={
                  validationErrorList.indexOf('displayName') > -1
                    ? 'Name is required'
                    : ''
                }
              />
            </div>
            <div className="text-muted w-100 d-flex justify-content-end">
              {formObj.displayName.length}/{NAME_LENGTH_LIMIT}
            </div>
          </div>
          <div className="mt-4">
            <div>
              <TextField
                variant="outlined"
                label="Email"
                type="text"
                className="mt-2"
                defaultValue={email}
                fullWidth={true}
                name="email"
                onChange={handleChange('email')}
                error={validationErrorList.indexOf('email') > -1 ? true : false}
                helperText={
                  validationErrorList.indexOf('email') > -1
                    ? 'Invalid Email'
                    : ''
                }
              />
            </div>
          </div>
          <div className="mt-4">
            <div>
              <TextField
                variant="outlined"
                label="Mobile"
                type="text"
                name="mobile_number"
                className="mt-2"
                placeholder="10 digit mobile number"
                disabled
                value={mobile_number}
                fullWidth={true}
              />
            </div>
          </div>
          <CustomButton
            label="Save details"
            buttonSize="large"
            className="fw-bold mt-5 my-4 w-100"
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
