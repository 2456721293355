import {
  ADD_PO_ITEM,
  GET_PO_ITEM,
  GET_PO_ITEMS,
  UPDATE_PO_ITEM,
  PO_API_SUCCESS,
  PO_API_ERROR,
  PO_API_PENDING,
  GET_PO_SUMMARY,
  PO_FORM_SHOW,
  PO_ITEM_SELECTED,
  FORM_MODE,
  CLEAR_PO_DATA,
  CLEAR_SELECTED_PO,
  SET_ORG_PURCHASE_ORDERS,
  SET_PURCHASE_ORDERS_BY_ORG_SUMMARY,
  SET_GST_TYPE,
} from './PurchaseOrder.type';
import { SUBMIT_PENDING, SUBMIT_SUCCESS } from '../../common/Generic.type';
import { ReducerProps } from '../../../../interfaces/Base';
import { FORM_MODES, GST_TYPE } from '../../../../constants';
import { PurchaseOrderProps } from '../../../../interfaces/PurchaseOrder';

interface POItemsByOrg extends PurchaseOrderProps {
  total_value?: number;
  delivered_value?: number;
}

const defaultPOSummary = {
  pending_indent_total: 0,
  SENT: 0,
  DELIVERED: 0,
  INVOICED: 0,
};

const initialState = {
  pending: false,
  poItems: [] as PurchaseOrderProps[],
  error: null,
  selectedPOItem: {} as PurchaseOrderProps,
  createSuccess: false,
  showPOForm: false,
  invoiceUploaded: false,
  poSummary: defaultPOSummary,
  formMode: FORM_MODES.create,
  poItemsByOrg: {
    docs: [] as POItemsByOrg[], // will contain result documents
    total: 0, // total count of docs (include of all pages not just current page)
    limit: 10, // limit
    page: 1, // Current Page/ pages start from 1
    pages: 1, // Total Pages or Last Page
  },
  poItemsByOrgSummary: [],
  gstType: GST_TYPE.SGST_CGST,
};

function POReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
): typeof initialState {
  switch (type) {
    case GET_PO_SUMMARY:
      return { ...state, poSummary: payload };
    case ADD_PO_ITEM:
      return {
        ...state,
        poItems: [...state.poItems, payload],
        selectedPOItem: payload,
      };
    case GET_PO_ITEM:
      return { ...state, selectedPOItem: payload };
    case GET_PO_ITEMS:
      return { ...state, poItems: payload };
    case UPDATE_PO_ITEM:
      return state;
    case PO_FORM_SHOW:
      return {
        ...state,
        showPOForm: payload,
      };
    case PO_ITEM_SELECTED:
      return {
        ...state,
        selectedPOItem: payload,
      };
    case FORM_MODE:
      return {
        ...state,
        formMode: payload,
      };
    case PO_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case PO_API_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PO_API_ERROR:
      return {
        ...state,
        pending: false,
        error: error,
      };
    case SUBMIT_PENDING:
      return {
        ...state,
        createSuccess: false,
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        createSuccess: true,
      };
    case CLEAR_PO_DATA:
      return initialState;
    case CLEAR_SELECTED_PO:
      return {
        ...state,
        selectedPOItem: {} as PurchaseOrderProps,
      };
    case SET_ORG_PURCHASE_ORDERS:
      return { ...state, poItemsByOrg: payload };
    case SET_PURCHASE_ORDERS_BY_ORG_SUMMARY:
      return { ...state, poItemsByOrgSummary: payload };
    case SET_GST_TYPE:
      return { ...state, gstType: payload };
    default:
      return state;
  }
}

export default POReducer;
