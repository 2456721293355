import { ADD_ONS, RESOURCE_ID_MAP } from '../../constant/module_constants';
import { UserPermissionsMapProps } from '../../interfaces/Team';
import { useAppSelector } from '../../redux/Hooks';
import useAddOn from './useAddOn.hook';

const useViewAccess = (resource_id?: string | string[]) => {
  const userPermissions: UserPermissionsMapProps = useAppSelector(
    state => state.userreducer.userPermissionMap
  );
  const proOnlyPO = useAddOn(ADD_ONS.PRO_PO).allowed;

  if (!userPermissions.size || resource_id === undefined) {
    return true;
  }

  if (typeof resource_id === 'string') {
    const resourcePermission = userPermissions.get(resource_id);

    if (!resourcePermission) {
      return false;
    }

    const onlyShowProPO =
      !resourcePermission.resource_access &&
      resource_id === RESOURCE_ID_MAP.PURCHASE_ORDER &&
      proOnlyPO;

    if (resourcePermission.view_access || onlyShowProPO) {
      return true;
    }
  } else {
    let permitted = false;

    for (const id of resource_id) {
      const resourcePermission = userPermissions.get(id);
      if (!resourcePermission) {
        continue;
      }

      const onlyShowProPO =
        !resourcePermission.resource_access &&
        id === RESOURCE_ID_MAP.PURCHASE_ORDER &&
        proOnlyPO;

      if (resourcePermission.view_access || onlyShowProPO) {
        permitted = true;
        break;
      }
    }

    return permitted;
  }
};

export default useViewAccess;
