export const GET_USER_PACKAGES_LIST = 'GET_USER_PACKAGES_LIST';
export const GET_USER_PACKAGES_NAV_ITEMS = 'GET_USER_PACKAGES_NAV_ITEMS';
export const KEY_SELECTED = 'KEY_SELECTED';
export const GET_AGENTS = 'GET_AGENTS';
export const ADD_USER_TO_PACKAGE = 'ADD_USER_TO_PACKAGE';
export const AGENTS_LOADED = 'AGENTS_LOADED';
export const ASSIGN_USER = 'ASSIGN_USER';
export const TOTAL_AGENT_COUNT = 'TOTAL_AGENT_COUNT';
export const AGENT_COUNT_PENDING = 'AGENT_COUNT_PENDING';
export const AGENT_COUNT_RESET = 'AGENT_COUNT_RESET';
export const ADD_ORG_TO_PACKAGE = 'ADD_ORG_TO_PACKAGE';
