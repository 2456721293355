import { useEffect, useState } from 'react';
import { Fade, Autocomplete } from '@mui/material';
import { useApolloClient } from '@apollo/client';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import {
  CustomButton,
  CustomDialogActions,
  CustomDialogContent,
  CustomTextField,
} from '../common';
import { OnboardingStepProps } from './types';
import { ONBOARDING_STEPS } from '../../views/Onboarding/OnboardingDialog';
import { gql } from '../../__generated__';
import { track } from '../../redux/features/common/Segment.action';

import { useDebounce } from '../../utils/Debounce.util';
import { getNextPageForList } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../redux/Hooks';

export const GET_ALL_PROJECTS_QUERY = gql(`
query getOrgProjects(
  $orgId: String!
  $page: page_Int_NotNull_min_0!
  $limit: limit_Int_NotNull_min_20_max_100!
  $search: String
) {
  projects {
    paginateUserProjects(org_id: $orgId, page: $page, limit: $limit, search: $search) {
      _id
      name
    }
  }
}`);

const SettingUp = (props: OnboardingStepProps) => {
  const dispatch = useAppDispatch();
  const { setProject, project } = props;

  const orgId = useAppSelector(
    state => state.organisationReducer.orgProfile._id
  );
  const gqlClient = useApolloClient();

  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [newProjectName, setNewProjectName] = useState('');

  const debouncedSearchValue = useDebounce(search, 450);

  const handleClick = () => {
    if (newProjectName) {
      setProject({ id: '', name: newProjectName });
    }
    props.setStep(ONBOARDING_STEPS.SELECTING_MODULE);
    dispatch(track('onboarding_project_created'));
  };

  const fetchProjects = async page => {
    if (orgId) {
      try {
        const { data } = await gqlClient.query({
          query: GET_ALL_PROJECTS_QUERY,
          fetchPolicy: 'no-cache',
          variables: {
            orgId,
            limit: 20,
            page,
            search: debouncedSearchValue ? debouncedSearchValue : '',
          },
        });

        return data.projects.paginateUserProjects || [];
      } catch {
        return [];
      }
    }

    return [];
  };

  const loadMoreResults = newPage => async () => {
    const data = await fetchProjects(newPage);

    if (newPage === 0) {
      setOptions([...data]);
    } else {
      setOptions(prev => [...prev, ...data]);
    }

    setPage(newPage);
  };

  useEffect(() => {
    loadMoreResults(0)();
  }, [debouncedSearchValue]);

  const handleSearchChange = e => {
    const val = e?.target?.value;
    setSearch(val);
  };

  const handleSelect = (_, newVal) => {
    if (newVal) {
      setNewProjectName('');
      setProject({ ...newVal, id: newVal._id });
    } else {
      setNewProjectName('');
      setProject({ id: '', name: '' });
    }
    dispatch(track('onboarding_project_selected'));
  };

  return (
    <>
      <CustomDialogContent>
        <Fade in timeout={500}>
          <div className="mt-4">
            <div className="fw-bold fes-m">{'Create new project'}</div>
            <CustomTextField
              className="mt-1 fw-75"
              value={newProjectName}
              onChange={e => setNewProjectName(e.target.value)}
            />
            <div className="my-3 fs-xxl fw-bold light">{'Or'}</div>
            <div className="fw-bold fes-m">
              {'Select Existing project to start with'}
            </div>
            <Autocomplete
              className="mt-1"
              options={options}
              onOpen={loadMoreResults(0)}
              filterOptions={v => [...v]}
              onInputChange={handleSearchChange}
              getOptionLabel={option => option.name}
              renderInput={(params: any) => (
                <CustomTextField variant="outlined" {...params} size="small" />
              )}
              isOptionEqualToValue={options => options._id === project.id}
              onChange={handleSelect}
              ListboxProps={{
                onScroll: (event: React.SyntheticEvent) => {
                  if (getNextPageForList(event.currentTarget)) {
                    loadMoreResults(page + 1)();
                  }
                },
              }}
            />
          </div>
        </Fade>
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton
          className="fw-bold"
          label="Next"
          disabled={!(project.id || newProjectName)}
          endIcon={<ArrowRightAltIcon />}
          onClick={handleClick}
        />
      </CustomDialogActions>
    </>
  );
};

export default SettingUp;
