import toast from 'react-hot-toast';

export function toastSuccessMessage(msg: string, toastId?: string) {
  return toastId ? toast.success(msg, { id: toastId }) : toast.success(msg);
}

export function toastErrorMessage(msg: string, toastId?: string) {
  return toastId ? toast.error(msg, { id: toastId }) : toast.error(msg);
}

export function toastLoadingMessage(msg: string) {
  return toast.loading(msg);
}

export function dismissToast(toastId: string) {
  return toast.dismiss(toastId);
}
