import {
  GET_VENDOR_ORG_LIST,
  GET_WORK_ORDER_LIST,
  SET_WORK_ORDER_DETAILS,
  GET_WORK_ORDER_SEQUENCE,
  GET_VENDOR_TRANSACTION_LIST,
  GET_VENDOR_PAYABLE_SUMMARY,
  SET_EXISTING_TASK_WORK_ORDER,
  GET_WORK_ORDER_API_PENDING,
  GET_WORK_ORDER_API_SUCCESS,
  WO_API_ERROR,
  WO_API_PENDING,
  WO_API_SUCCESS,
  WO_DELETE_API_PENDING,
  WO_DELETE_API_SUCCESS,
} from './ScPaybale.type';
import { ReducerProps } from '../../../interfaces/Base';

const initialState = {
  pending: false,
  error: null,
  workOrderItemPending: false,
  workOrderDeletePending: false,
  getAllworkOrderPending: false,
  workOrderPaginatedData: {
    docs: [],
    total: 0,
    pages: 0,
  },
  workOrderProfile: [],
  vendorList: {
    docs: [],
    total: 0,
    pages: 0,
  },
  vendorTransaction: {
    docs: [],
    total: 0,
    pages: 0,
  },
  existing_task_map: new Map(),
  vendorPayableSummary: null,
  workOrderSequence: '',
};

function WorkOrderReducer(
  state = initialState,
  { type, payload }: ReducerProps
): any {
  switch (type) {
    case GET_WORK_ORDER_LIST:
      return {
        ...state,
        workOrderPaginatedData: payload,
      };
    case SET_WORK_ORDER_DETAILS:
      return {
        ...state,
        workOrderProfile: payload,
      };
    case GET_VENDOR_ORG_LIST:
      return {
        ...state,
        vendorList: payload,
      };
    case GET_VENDOR_TRANSACTION_LIST:
      return {
        ...state,
        vendorTransaction: payload,
      };
    case GET_VENDOR_PAYABLE_SUMMARY:
      return {
        ...state,
        vendorPayableSummary: payload,
      };
    case GET_WORK_ORDER_SEQUENCE:
      return {
        ...state,
        workOrderSequence: payload,
      };
    case SET_EXISTING_TASK_WORK_ORDER:
      return {
        ...state,
        existing_task_map: payload,
      };
    case WO_DELETE_API_PENDING:
      return {
        ...state,
        workOrderDeletePending: true,
      };

    case WO_DELETE_API_SUCCESS:
      return {
        ...state,
        workOrderDeletePending: false,
      };

    case GET_WORK_ORDER_API_PENDING:
      return {
        ...state,
        getAllworkOrderPending: true,
      };
    case GET_WORK_ORDER_API_SUCCESS:
      return {
        ...state,
        getAllworkOrderPending: false,
      };
    case WO_API_PENDING:
      return {
        ...state,
        pending: true,
      };
    case WO_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case WO_API_ERROR:
      return {
        ...state,
        pending: false,
        error: payload,
      };
    default:
      return state;
  }
}

export default WorkOrderReducer;
