import apiClient from '../../../../utils/ApiClient.util';
import {
  WORK_CATEGORIES_TAB_KEY,
  WORK_TABLE_SHOW,
  PACKAGE_ID_SELECTED,
  UTILS_API_ERROR,
  UTILS_API_PENDING,
  UTILS_API_SUCCESS,
  VERIFY_GST_NUMBER,
  VENDOR_ID_SELECTED,
  BOM_ID_SELECTED,
  INDENT_DETAILS_SHOW,
  INDENT_ID_SELECTED,
  PO_ID_SELECTED,
  CLEAR_UTILS_DATA,
  TOGGLE_SIDEBAR_MENU,
  REGISTER_ZOHO,
  SYNC_PO_STATUS,
  SELECTED_KEY,
  MX_LOOKUP_SUCCESS,
  MX_LOOKUP_FAILURE,
} from './Utils.type';
import {
  toastSuccessMessage,
  toastErrorMessage,
} from '../../../../utils/Toast.util';
import { MISC_APIS } from '../../../../routes.constant';
import { eventDispatch } from '../../../base';
import { customStringify } from '../../../../utils/Url.util';

const getDataSuccess = (data, actionType) => {
  return {
    type: actionType,
    payload: data,
  };
};

const apiPending = () => {
  return {
    type: UTILS_API_PENDING,
  };
};

const apiSuccess = () => {
  return {
    type: UTILS_API_SUCCESS,
  };
};

const apiError = (error?: string | any) => {
  return {
    type: UTILS_API_ERROR,
    error,
  };
};

export const clearUtilsData = () => {
  return {
    type: CLEAR_UTILS_DATA,
  };
};

export const toggleSideBarMenu = toggle => {
  return {
    type: TOGGLE_SIDEBAR_MENU,
    payload: toggle,
  };
};

export const setWCTabKey = key => {
  return dispatch => {
    dispatch(apiPending());
    try {
      dispatch(apiSuccess());
      dispatch(getDataSuccess(key, WORK_CATEGORIES_TAB_KEY));
    } catch (error) {
      dispatch(apiError());
    }
  };
};

export const setShowWorkTable = show => {
  return dispatch => {
    dispatch(apiPending());
    try {
      dispatch(apiSuccess());
      dispatch(getDataSuccess(show, WORK_TABLE_SHOW));
    } catch (error) {
      dispatch(apiError());
    }
  };
};

export const setPOIdSelected = id => {
  return dispatch => {
    dispatch(apiPending());
    try {
      dispatch(apiSuccess());
      dispatch(getDataSuccess(id, PO_ID_SELECTED));
    } catch (error) {
      dispatch(apiError());
    }
  };
};

export const setShowIndentDetails = show => {
  return dispatch => {
    dispatch(apiPending());
    try {
      dispatch(apiSuccess());
      dispatch(getDataSuccess(show, INDENT_DETAILS_SHOW));
    } catch (error) {
      dispatch(apiError());
    }
  };
};

export const setIndentIdSelected = id => {
  return dispatch => {
    dispatch(apiPending());
    try {
      dispatch(apiSuccess());
      dispatch(getDataSuccess(id, INDENT_ID_SELECTED));
    } catch (error) {
      dispatch(apiError());
    }
  };
};

export const setPackageIdSelected = id => {
  return dispatch => {
    dispatch(apiPending());
    try {
      dispatch(apiSuccess());
      dispatch(getDataSuccess(id, PACKAGE_ID_SELECTED));
    } catch (error) {
      dispatch(apiError());
    }
  };
};

export const setVendorIdSelected = id => {
  return dispatch => {
    dispatch(apiPending());
    try {
      dispatch(apiSuccess());
      dispatch(getDataSuccess(id, VENDOR_ID_SELECTED));
    } catch (error) {
      dispatch(apiError());
    }
  };
};

export const setBomIdSelected = id => {
  return dispatch => {
    dispatch(apiPending());
    try {
      dispatch(apiSuccess());
      dispatch(getDataSuccess(id, BOM_ID_SELECTED));
    } catch (error) {
      dispatch(apiError());
    }
  };
};

export const verifyGstNumber = gst_number => {
  const apiUrl = `verify-gst/${gst_number}`;

  return dispatch => {
    dispatch(apiPending());
    return apiClient
      .get(apiUrl)
      .then(response => {
        dispatch(apiSuccess());
        dispatch(getDataSuccess(response.data, VERIFY_GST_NUMBER));
      })
      .catch(err => {
        const rejectObj = { verified: false, state: '', id: gst_number };
        dispatch(getDataSuccess(rejectObj, VERIFY_GST_NUMBER));
        dispatch(apiError(err));
      });
  };
};

export const registerZoho = (url, data) => {
  return dispatch => {
    return apiClient
      .post(url, data)
      .then(() => {
        dispatch(getDataSuccess(true, REGISTER_ZOHO));
      })
      .catch(error => {
        dispatch(apiError(error));
        toastErrorMessage('Error in registering zoho with powerplay os');
      });
  };
};

export const syncPo = (url, params) => {
  const apiUrl = `${url}/?${customStringify(params)}`;

  return dispatch => {
    dispatch(getDataSuccess(true, SYNC_PO_STATUS));
    return apiClient
      .get(apiUrl)
      .then(() => {
        dispatch(getDataSuccess(false, SYNC_PO_STATUS));
        toastSuccessMessage('Successfully synced data with zoho');
      })
      .catch(error => {
        dispatch(apiError(error));
        dispatch(getDataSuccess(false, SYNC_PO_STATUS));
        toastErrorMessage('Error in syncing data with zoho');
      });
  };
};

export const getMxRecord = emails => {
  const apiUrl = MISC_APIS.mxLookup;
  return dispatch => {
    return apiClient
      .post(apiUrl, { emails })
      .then(({ data }) => {
        dispatch(eventDispatch(MX_LOOKUP_SUCCESS, data));
      })
      .catch(error => {
        dispatch(eventDispatch(MX_LOOKUP_FAILURE));
        dispatch(apiError(error));
        toastErrorMessage('Error in getting MX Record of emails');
      });
  };
};

export const setSelectedKey = key => {
  return {
    type: SELECTED_KEY,
    payload: key,
  };
};
