export const API_VERSIONS = {
  V1: 'v1',
  V2: 'v2',
  V3: 'v3',
  V4: 'v4',
  V5: 'v5',
} as const;

export const APP_VERSION_APIS = {
  getLatestVersion: 'app-version/latest?{}',
};

export const ORG_APIS = {
  packagesList: 'organisation/packages/list?{}',
  vendorList: 'organisation/vendors/list?{}',
  changeTeam: 'team/change-team',
  changeBulkTeam: 'team/change-team',
  updateOrg: 'organisation/{}',
  getQuestionnaire: 'questionnaire',
  getOrgProfile: 'organisation/{}',
  createOrgProfile: 'organisation',
  deleteOrgUser: 'organisation/{}/member/{}',
  updateOrgGST: 'organisation/{}/update-gst',
  updateQuestionnaireInfo: 'organisation/{}/update-info',
  getPOSequence: 'organisations/{}/purchase-orders/sequence',
  updatePOSequence: 'organisations/{}/purchase-orders/sequence',
  orgTeamInvites: 'organisations/{}/team-invites',
  sendInterestedPayingOrgDetails: 'organisations/{}/interested-in-paid',
  getConfigAndExperiments: 'config-data?{}',
  updateExpMetadata: 'experiment/{}',
  getInventoriesByOrgId: 'organisations/{}/inventories?{}',
  getGrnsByOrgId: 'organisations/{}/grns?{}',
  getGrnsByOrgIdCount: 'organisations/{}/grns/count?{}',
  updateOrgProjectStages: 'organisations/{}/update-stages',
  updateOrgExpenseSettings: 'organisation/{}/expense-settings',
  allTC: 'organisations/{}/terms-and-condition?{}',
  createTC: 'organisations/{}/terms-and-condition',
  getTcById: 'organisations/{}/terms-and-condition/{}',
  updateTcById: 'organisations/{}/terms-and-condition/{}',
  deleteTcById: 'organisations/{}/terms-and-condition/{}',
  deleteWorkers: 'organisation/workers?{}',
};

export const PO_APIS = {
  approve: 'purchase-order/approve/{}',
  reject: 'purchase-order/reject/{}',
  cancel: 'purchase-order/cancel/{}',
  upload: '/purchase-order/upload',
  addRemark: 'purchase-order',
  emailPO: '/purchase-order/send-email/{}',
  indentsToPO: '/purchase-order/convert-to-po',
  getPOById: '/purchase-order/{}',
  getPOListRequest: '/purchase-orders?{}',
  getPOSummary: '/purchase-orders-summary/{}',
  createPO: '/purchase-order',
  bulkCreatePO: '/purchase-order/bulk-upload-po',
  createIndentToPO: '/purchase-order/indent-to-po',
  updatePO: '/purchase-order/{}',
  deletePO: '/purchase-order/{}',
  getPOByOrg: '/organisations/{}/purchase-orders',
  getPOByOrgSummary: '/organisations/{}/purchase-orders/summary',
  downloadPOReportByOrg: '/organisations/{}/purchase-orders/report',
  createExternalPO: '/purchase-order/external',
  createProPO: '/purchase-order/pro',
  getPOByOrgId: '/organisations/{}/purchase-orders?{}',
  searchPO: '/organisations/{}/purchase-orders/search?{}',
};

export const GRN_APIS = {
  createGRN: 'grn',
  grnItem: 'grn',
  grnList: 'grns',
  getGrnPaymentList: 'grn-payments',
  getGrnPaymentItem: 'grn-payment',
  getGrnPaymentSummary: 'grn-payment-summary',
  esSearch: 'search/grns?{}',
  getById: 'grn/{}',
  getTotalCost: 'grns/total-cost?{}',
  getGrnItemPaymentDetail: '/payment-to-items/items/{}',
  deleteGrnById: '/grns/{}',
  updateInvoiceAdjustments: 'invoices/{}/adjustments',
  createInvoiceForGrns: 'invoices/for-grn',
  searchInvoices: 'invoices/es-search?{}',
  deleteInvoice: 'invoices/{}',
  deletePayment: 'payments/{}',
  deletePettyCashPayment: 'petty-cash/{}',
};

export const BOM_PRICE = {
  bomPricelist: 'bom-prices?{}',
  bomPricingHistory: 'bom-prices/bom?{}',
};

export const VENDOR_APIS = {
  updateVendorCredits: 'vendor/credits/{}',
  getVendorById: 'vendor/{}',
  getVendorListRequest: 'vendors?{}',
  createVendor: 'vendor',
  createVendorWithProjectMapping: 'vendor-projects',
  updateVendor: 'vendor/{}',
  deleteVendor: 'vendor/{}',
  deleteVendorV2: 'vendor/{}?{}',
  vendorSearch: 'vendors/search?{}',
  getVendorsByAmount: '/organisations/{}/vendors/with-amount?{}',
  getVendorsByAmountPaginationData:
    '/organisations/{}/vendors/with-amount-page-data?{}',
  getAllPayableData: '/organisations/{}/vendors/all-payable-data?{}',
  addPaymentForInvoices: '/payments/for-invoices',
  getVendorProjects: '/vendor-projects/{}?{}',
  getVendorProjectsCount: '/vendor-projects/{}/count?{}',
  manageVendorProjects: '/vendor/{}/manage-projects',
  vendorUpload: 'vendor/excel-upload',
  getOrgVendorList: 'vendors-list?{}',
  getOrgVendorSelectionListCount: 'vendors-list/count?{}',
  getVendorPricingHistory: 'vendor/{}/bom-price?{}',
  getVendorPricingHistoryCount: 'vendor/{}/bom-price/count?{}',
  checkItemExists: 'check-item-exists?{}',
};

export const TASK_APIS = {
  taskCount: 'task/count?{}',
  taskList: 'tasks?{}',
  createTaskLog: 'task-log',
  deleteTaskLog: 'task-log/{}',
  updateTaskLog: 'task-log/{}/with-history',
  taskLogs: 'task-logs?{}',
  taskWithLogs: 'task/with-logs?{}',
  taskToBoqCount: 'task-to-boq/count',
  deleteTasks: 'tasks',
  checkTaskUsages: 'tasks/usages',
  deleteAllTasks: 'all-tasks/{}',
  addTasks: 'tasks',
  updateTasks: 'tasks/update',
  assignUser: 'task/assign-user/{}',
  linkBoq: 'task/link-boq/{}',
  assignUsers: 'tasks/assign-user',
  linkBoqs: 'tasks/link-boq',
  sendFollowUp: 'task/follow-up/{}',
  createTask: 'task',
  createBroadPlanMainTask: 'broad-plan',
  updateTask: 'task/{}',
  deleteTask: 'task/{}',
  getTasK: 'task/{}',
  getBroadPlanTask: 'broad-plan?{}',
  getTaskLogsForTask: 'task-log/task-id/{}',
  getTaskDependency: 'gantt-chart?{}',
  createtaskDependency: 'task-dependency',
  deleteTaskDependency: 'task-dependency/{}',
  bulkDependencyUpdate: 'task-dependency/bulk/update',
  bulkDependencyCreate: 'task-dependency/bulk/create',
  fetchDependency: 'tasks/{}/dependencies',
  getBaselines: 'baselines?{}',
  createBaseline: 'baselines',
  updateBaseLine: 'baselines/{}',
  deleteBaseline: 'baselines/{}',
  getTaskTree: 'project-plan/{}/tasks?{}',
  updateTasksProgress: 'tasks/update-progress',
  updateQuantityInfo: 'task/update-quantity/{}',
  getMeasurementUnits: 'measure-units?{}',
  getUoqs: 'uoqs?{}',
  updatePrice: 'tasks/amount',
  getOrgTasks: 'org-tasks?{}',
  copyAndPasteTask: 'tasks/copy-tasks',
  bulkCopyAndPasteTask: 'tasks/bulk-copy-tasks',
  indentTasks: 'tasks/indent',
  outdentTasks: 'tasks/outdent',
  getTaskMaterials: 'task/{}/material-issued',
  getTaskCFs: '/cf-to-items/items/{}',
  getProjectPlan: 'project-plan/{}',
  getTaskSequence: 'projects/{}/task-sequence',
  getTaskChildrenIds: 'tasks/{}/children',
  moveTasks: 'move/tasks/{}',
  fetchLevelWiseTaskForOutline: 'projects/{}/ids-with-level?{}',
  fetchCollapsedTaskData: 'projects/{}/get-task-children-details',
  reconcileParentTasks: 'tasks/reconcile-parent-tasks',
  searchTasks: 'tasks/web-search?{}',
  searchTaskV3: 'tasks/search?{}',
  addTaskAttachments: 'task/{}/attachments',
  nestedTaskUpload: 'tasks/nested-excel-upload',
  createMeasurementUnit: 'measurement-unit',
  fetchPlannedTasks: 'next-day-plan/{}?{}',
  updateTaskPlanStatus: 'tasks/update-plan-status',
  fetchPlannedTasksV2: 'planned-tasks/{}',
  searchOrgTasks: 'tasks/search/{}?{}',
  getTaskUpdates: 'tasks/get-updates',
  setTaskReminder: 'tasks/reminder',
  updateTaskReminder: 'tasks/reminder/{}',
  bulkUploadMainTask: 'broad-plan/main-task/upload?{}',
  getBroadPlanTaskDetails: 'broad-plan/task/{}?{}',
  editBroadPlanTask: 'broad-plan/task/{}',
  updateBroadPlanTask: 'broad-plan/update',
  getBroadPlanUpdate: 'broad-plan/update?{}',
  bulkStatusUpdate: 'broad-plan/bulk-status',
  broadPlanIssue: 'broad-plan/issues?{}',
  broadPlanPhotos: 'broad-plan/photos?{}',
  broadPlanTaskCount: 'broad-plan/count?{}',
  broadPlanDeleteTask: 'broad-plan/task/{}?{}',
  broadPlanBulkDelete: 'broad-plan/bulk-delete?{}',
  searchTasksES: '/tasks/search',
  getTaskResourceUsage: 'resources/resource-utilisation-for-task?{}',
  getTaskList: 'tasks-list?{}',
};

export const CONFIG_APIS = {
  configItem: 'config',
  configList: 'configs',
};

export const TAGS_APIS = {
  tagItem: 'tag',
  tagsList: 'tags',
  getAllTags: 'tags?{}',
  deleteTag: 'tag/{}',
  getProjectTaskTags: '/projects/{}/project-tags',
};

export const BOQ_APIS = {
  boqCount: 'boq/count',
  boqPromises: 'boq-promises',
  boqs: 'boqs',
  deleteBoqs: 'delete-boqs',
  approve: 'boq/approve',
  reject: 'boq/reject',
  bulkApprove: 'boq/bulkApprove',
  bulkReject: 'boq/bulkReject',
};

export const BOM_APIS = {
  bomChildren: 'boms/bom-children?{}',
  getBOMListRequest: 'boms?{}',
  getSearchBOMListRequest: 'boms-search?{}',
  postBomData: 'bom',
  updateBom: 'bom/{}',
  deleteBomItem: 'bom/{}',
  getBomCount: 'boms-count?{}',
  getEsBomCount: 'boms/es/count?{}',
  getRunningBoms: '/running-boms-search?{}',
  getElasticSearchBOMListRequest: 'boms/search/es?{}',
  getEsInventoryBom: 'inventories-with-bom?{}',
  getInventoriesWithBomCount: 'inventories-with-bom/count?{}',
  getInventoriesWithBom: 'inventories-with-bom?{}',
  getMaterials: 'boms-with-inventory?{}',
  getEsBomsWithInventory: 'boms-with-inventory?{}',
  searchBoms: 'boms/search/props?{}',
  addBulkMaterials: 'inventory/create-many',
  advanceSearchMaterial: 'boms/exact-search',
  advanceSearchMaterialCount: 'boms/exact-search/count',
  updateBomWarning: 'bom/{}/linked-material-logs?{}',
};

export const USER_APIS = {
  getUser: 'user',
  getUserOrgs: 'user-to-orgs?{}',
  selfPermission: 'self-permission',
  updateUserDetails: 'user',
  updateGuideInfo: 'guide-info',
  requestSupportCall: 'support-request',
  sendEmailVerification: 'user/{}/send-verification-email',
  verifyEmail: 'user/verify-email?{}',
  sendUserOtp: 'send-otp',
  verifyUserOtp: 'verify-otp',
  user: 'user',
  userSelf: 'user-self',
  updateAppSettings: 'user/app-settings',
  premiumverifyOtp: 'premium-onboarding/{}/verify',
  getSignature: 'user/signature',
};

export const USER_PROJECT_APIS = {
  agentCount: 'users-projects/agents/count',
  changePassword: 'user/change-password',
  updateProjectUsers: 'users-project/users',
  deleteProjectUser: 'users-project?{}',
  userProjectUpdate: '/users-project/update',
};

export const AUTO_DPR_APIS = {
  getAutoDprData: 'autoreport/{}',
  getAutoDprMembers: 'autoreport/all-member/{}',
  updateAutoDpr: '/autoreport/{}',
};

export const PROJECT_APIS = {
  updateFinanceSettings: 'project/finance-settings',
  updatePurchaseSettings: 'purchase-settings/{}',
  updateDriveSettings: 'drive-settings/{}',
  getProjectList: 'projects/user?{}',
  getProjectsForOrg: 'projects?{}',
  createProject: 'project-with-channel',
  getProjectProfile: 'project-profile/{}',
  createProjectProfile: 'project-profile',
  updateProjectProfile: 'project-profile/{}',
  getProject: 'project/{}',
  deleteProject: 'project/{}',
  getProjUsers: 'project-members?{}',
  updateProject: 'project/{}',
  duplicateProject: 'projects/{}/duplicate-with-channel',
  getAllCustomFields: 'all-custom-fields/projects/{}',
  creatCfToItemIds: 'cf-to-items/create-many',
  getCFTemplates: 'custom-fields/templates?{}',
  deleteManyCfsToItem: '/custom-fields/{}/items/delete-many',
  getCustomTaskLogFields: 'custom-fields-sorted/projects/{}?resource_types={}',
  duplicateProjectWithDependencies: 'projects/{}/duplicate-project',
  updateCustomTaskFields: 'custom-fields/{}',
  updateOptionsByField: 'custom-fields',
  getProjectTaskFilters: 'projects/{}/tasks/filters',
  getAdditionalTaskFilters: 'additional-filters?project_id={}',
  esSearchProjects: 'projects-search/user?{}',
  getProjectStages: 'projects/{}/stages',
  assignTaskToProjectStage: 'projects/{}/stages/assign-task',
  projectTeamInvites: 'projects/{}/team-invites',
  updateTaskSettingGalleryEnable: 'project-config/{}',
  getProjectConfig: 'project/{}?{}',
  getResourceTypes: 'project/{}/project-resource-types',
  createResourceTypes: 'project/{}/project-resource-types',
  getResources: 'resources?{}',
  createResources: 'resources',
  deleteResources: 'resources?{}',
  getResourceUtilisation: 'resources/resource-utilisation?{}',
  bulkUploadResource: 'resources/bulk-upload',
  getProjectHolidays: 'projects/{}/holidays?{}',
  bulkUpdateProjectHolidays: 'projects/{}/holidays',
  getUnviewedReportCount: 'async-reports/unviewed/count?{}',
};

export const PACKAGE_APIS = {
  packageItem: 'package',
  packageList: 'packages',
  userToPackages: 'user-to-packages',
  getPackages: 'work-categories/default?{}',
  createPackage: 'package',
  getOrgPackages: 'organisations/{}/org-packages?{}',
  getAllForOrgId: 'organisations/{}/org-packages',
  deleteOrgPackage: 'org-package/{}',
  updateOrgPackage: 'org-package/{}',
  creatOrgPackage: 'org-package',
};

export const ONBOARDING_APIS = {
  createOrg: 'onboarding-org',
  createProject: 'project',
  getNewOnboardingQuestions: 'onboarding-org/questions?{}',
  postAnswerForNewOnboarding: 'onboarding-org/questions',
  whatsappInviteAdmin: 'onboarding-org/whatsapp-invite',
  inviteOnboardingMember: 'onboarding-org/members-invite?{}',
};

export const PROJECT_PLAN = {
  sendDPR: 'send-dpr',
  getGanttChartData: 'gantt-chart',
  getImageList: 'task-log/img-with-ref',
  createPlanUsingTemplate: 'projects/{}/tasks-template',
};

export const TEMPLATE_TASKS = {
  apartmentPlanning: 'task-template/apartment',
};

export const THREAD_APIS = {
  createThread: 'thread',
  updateThread: 'thread/{}',
  getThread: 'thread/{}',
  closeThread: 'thread/close/{}?{}',
  deleteThread: 'thread/{}',
  getThreadsList: 'threads?{}',
  createThreadWithItem: 'thread-with-item?{}',
  getThreadsWithItem: 'threads-with-item?{}',
};

export const COMMENT_APIS = {
  getComments: 'comments?{}',
  addComment: 'comment',
  updateComment: 'comment/{}',
  deleteComment: 'comment/{}',
  uploadFiles: 'comment/upload_files',
};

export const ATTENDANCE_APIS = {
  getAllAttendanceRecords: 'attendances?{}',
  exportAllAttendanceRecords: 'attendance/export?{}',
  createAttendance: 'attendance',
  getVendorAttendance: '/org/{}/vendor-attendance?{}',
  getVendorAttendanceCount: '/org/{}/vendor-attendance-count?{}',
  bulkUploadLabour: 'labour/excel-upload?{}',
  bulkUploadVendor: 'vendor/excel-upload?{}',
  bulkUploadVendorLabours: 'vendor-labours/excel-upload?{}',
  createVendor: 'vendor',
  updateVendor: 'vendor/{}',
  createLabour: 'labour',
  updateLabour: 'labour/{}',
  getWorkerPaybles: 'worker-payables?{}',
  getAttendanceSummary: 'attendance-summary?{}',
  getWorkerPayableSummary: 'worker-payable-summary?{}',
  createWages: 'worker-wage',
  updateWages: 'update/worker-wage',
  createWorkerPayable: 'worker-payable',
  labourAttendanceSummary: 'labour-attendance-summary?{}',
  vendorAttendanceSummary: 'vendor-attendance-summary?{}',
  getAttendanceLog: 'attendance-log?{}',
  getWorkerAttendance: 'attendance-work-updates?{}',
  addExistingWorker: 'existing-worker',
  getVendorAttendanceSummaryCount: 'vendor-attendance-summary-count?{}',
  getLabourAttendanceSummaryCount: 'labour-attendance-summary-count?{}',
  getLabourList: 'attendance-worker-payable?{}',
  getVendorTransactionList: 'worker-payables?{}',
  getWorkerAttendanceProjectList: 'attendance-worker-projects?{}',
  getWorkerList: 'attendance-workers?{}',
  getAttendanceLabourList: 'attendance-worker-payable?{}',
  getOrgLevelAttendanceDetails: 'worker-payable?{}',
  getVendorProjectList: 'attendance-worker-projects?{}',
  orgLevellabourAttendanceSummary: 'labour-attendance-summary?{}',
  createOrgAddPaidAmount: 'unmapped-worker-payment?{}',
  getOrgLevelTotalPaid: 'total-paid-amount?{}',
  getOrgLabourSummary: 'labour-attendance-summary-org?{}',
  mappedPaymentWithProject: 'map-worker-payment/{}',
  getAttendancePaymentDetail: 'worker-payment/{}',
  getVendorTotalToPay: 'worker-payable?{}',
  getVendor: 'vendor/{}',
  getLabour: 'labour/{}',
  deleteAttendancePayment: 'worker-payable/{}',
  updateVendorProfile: 'vendor/{}/map-projects',
  updateLabourProfile: 'labour/{}/map-projects',
  workerSearch: 'worker/search?{}',
  getLabourType: 'labour-type?{}',
  getMergeWorkersList: 'worker-list?{}',
  getSerachMergeList: 'workers-search?{}',
  postMergeWorkers: 'worker-list',
  postMergeLabours: 'merge-labours-vendors',
  deleteAndRecreatePayable: 'worker-payable/recreation',
  getAllAsyncReports: 'async-reports/all?{}',
  getWorkerWageDetails: 'worker-wage/attendance-details',
  checkWorkerExist: 'check-item-exists?{}',
};

export const NOTIFICATION_APIS = {
  getUnseenCount: 'notification/unseen-count?{}',
  updateLastSeen: 'notification/update-last-seen?{}',
  getAllNotifications: 'notifications?{}',
  markClicked: 'notification/mark-clicked/{}',
  markAllRead: 'notification/mark-all-read',
};

export const DASHBOARD_APIS = {
  uncompletedTasks: 'dashboard/uncompleted-task',
  taskCountOnTime: 'dashboard/ontime-task-count',
  packageProgress: 'package/progress',
  itemsCount: 'items-count',
  getLast30DaysActivities: 'dashboard/{}/30-day-activities',
  getInProgressTasks: 'dashboard/projects/{}/work-updates?{}',
  getTasksSummary: 'task/summary?{}',
  getOrgInProgressTasks: 'dashboard/work-updates?{}',
  getPackageWiseTaskSummary: 'dashboard/projects/{}/package-task-summary',
  getTagWiseTaskSummary: 'dashboard/projects/{}/tag-task-summary',
  getUserWiseTaskSummary: 'dashboard/projects/{}/user-task-summary',
  getPlanStatusCountWithFilter:
    '/dashboard/projects/{}/plan-status-count-with-filters?{}',
  broadPlanDashboard: 'broad-plan/dashboard?{}',
  broadPlanTopActiveUsers: 'broad-plan/top-active-users?{}',
};

export const ACTIVITIES_APIS = {
  dailyActivity: 'daily-activity?{}',
  userActivity: 'user-activity?{}',
};

export const USER_TO_PKG_APIS = {
  userToPkg: 'user-to-package',
  pkgToCollaborator: 'collab-to-package',
};

export const ZOHO_APIS = {
  registerZoho: 'register-zoho',
  syncPo: 'sync-po',
};

export const INDENT_APIS = {
  indent: 'indent',
  approve: 'approve',
  reject: 'reject',
  indentList: 'indents',
  getIndentList: 'indents?{}',
  getIndentById: 'indent/{}',
  createIndent: 'indent',
  updateIndent: 'indent/{}',
  deleteIndent: 'indent/{}',
  approveIndent: 'indent/approve/{}',
  rejectIndent: 'indent/reject/{}',
  bulkApproveIndents: 'indents/approve',
  getIndentsByOrgId: 'organisations/{}/indents?{}',
  getIndentIDsByOrgId: 'organisations/{}/indents-display-ids?{}',
  getSignature: 'user/signature',
  addSignature: 'user/add-signature',
} as const;

export const INVENTORY_APIS = {
  deleteInventory: 'inventory/{}',
  addToInventory: 'inventory',
  updateInventory: 'inventory/{}',
  createInventories: 'inventories/onboarding-create',
  getInventoryHistory: 'inventory/{}?{}',
  getInventoryIndentHistory: 'indents/bom/{}?{}',
  getGroupedMaterials: 'group-materials?{}',
  getGroupMaterialsCount: 'group-materials/count?{}',
  getEditMterialInfo: 'group-material/draft/{}?{}',
  getGroupedMaterialInfo: 'group-material/{}?{}',
  getGroupedMaterialBomInfo: 'group-material/bom/{}?{}',
  postGroupMaterialForm: 'group-materials',
  postDraftedGroupMaterialForm: 'group-material/{}',
  deleteGroupedMaterial: 'group-material/{}',
  syncInventoryHistory: 'sync-inventory-history/{}',
} as const;

export const MILESTONE_APIS = {
  getAllMileStones: 'milestones',
  milestone: 'milestone',
  raiseBill: 'milestone/raise-bill',
  bulkLinkBoq: 'milestone/link-budgets-milestones',
  bulkLinkTask: 'milestone/link-tasks-milestones',
  milestoneSummary: 'milestone/summary',
};

export const EXPENSE_APIS = {
  expenseList: 'expenses',
  expenseItem: 'expense',
  delete: 'petty-cash/{}',
  approvePC: 'petty-cash/approve/{}',
  rejectPC: 'petty-cash/reject/{}',
  getExpenseIDsByOrgId: 'organisations/{}/petty-cash-ids?{}',
  createPC: 'petty-cash',
};

export const UTIL_APIS = {
  dpr: 'generate-dpr',
  wpr: 'generate-wpr',
};

export const DOCUMENT_APIS = {
  signedUrls: '/docs/get-signed-urls',
  singleSignedUrl: '/docs/get-signed-url?{}',
  privateSingleSignedUrl: '/docs/get-private-signed-url?{}',
};

export const TEAM_APIS = {
  createTeam: 'team',
  getTemplates: 'team/templates',
  getMembers: `team/members?{}`,
  getMembersWithPagination: `team/members-with-pagination?{}`,
  getTeams: 'teams?{}',
  updateTeam: 'team/{}',
  getTeamById: 'team/{}',
  inviteTeam: 'team/invite',
  updateTeamUser: '/users-project/projects',
  resendInvite: 'team/resend-invite',
  deleteTeamUser: '/team/{}/member/{}',
  getOrgAdmins: 'organisations/{}/admins',
  sendAccessRequestEmail: 'send-access-request-mail',
  deleteTeam: 'team/{}',
};

export const REPORT_APIS = {
  generateReport: 'generate-project-report?{}',
  planExtract: 'reports/projects/{}/task-plan-extract',
  generateExcelReport: 'reports/projects/{}/techno-clean',
  getReportImageSelectionList: 'reports/projects/{}/images?{}',
  generateClientReport: 'report/client-report',
  excelReport: 'reports/excel',
  miConsumptionReport: 'excel/material-issue',
  getReportHtml: 'reports/html',
  htmlToPdf: 'reports/html-to-pdf',
};

export const DRIVE_APIS = {
  fileRoute: 'drive/file/{}',
  sampleUploadDocument: 'drive/files',
  getFiles: 'drive/files?{}',
  getFolders: 'drive/folders?{}',
  folderInfo: 'drive/folder/{}?{}',
  fileVersion: 'drive/file/{}/version',
  updateFileInfo: 'drive/file/{}/info',
  fileSharableLink: 'drive/file/{}/share?version_id={}',
  fileChangeVersion: 'drive/file/{}/changeVersion',
  updateFileVersionState: '/drive/files/{}/approval-status',
  fileInfo: 'drive/file/{}',
  createFolder: 'drive/folder',
  folderRoute: 'drive/folder/{}',
};

export const MISC_APIS = {
  createDynamicLink: 'create-dl',
  mxLookup: 'misc/mx-lookup',
  fetchPremiumOnboarding: 'premium-onboarding/{}',
  fetchSupportedCountries: 'supported-countries',
};

export const EXPORT_API = {
  exportData: '/export-data',
};

export const S_CURVE_API = {
  sCurve: 's-curve/{}?{}',
};

export const CHECKLIST_APIS = {
  getAllWithPagination: 'entities/{}/checklist-items?{}',
  newChecklistItem: 'checklist-item',
  deleteItem: 'checklist-items/{}',
  updateChecklistItem: 'checklist-item/{}/update-label',
  search: 'checklist-items/es-search?{}',
};

export const PAYMENT_MODES = {
  getAll: 'payment-mode?{}',
  createPaymentMode: 'payment-mode',
};

export const TASK_LOG_FIELD_TYPES = {
  allTaskLogTypes: '1,2,3,4',
};

export const MI_APIS = {
  updateMI: 'material-issue/{}',
  getMIItem: 'material-issue/{}',
  deleteMIItem: 'material-issue/{}?{}',
  approveMIItem: 'material-issue/approve/{}',
  rejectMIItem: 'material-issue/reject/{}',
  createMI: 'material-issue',
  getMIDisplayIds: '/organisation/material-issues?{}',
};

export const ST_APIS = {
  getSTDisplayIds: 'organisations/{}/st-display-ids?{}',
};

export const ANALYTICS_DASHBOARD_APIS = {
  getEmbedURL: 'workspace?{}',
  getViews: 'workspaces?{}',
};

export const SC_PAYABALS_APIS = {
  createWorkOrder: 'work-order',
  getWorkOrderList: 'work-orders?{}',
  getWorkOrderProfile: 'work-order/{}',
  updateWorkOrder: 'work-order/{}',
  deleteWorkOrder: 'work-order/{}',
  getVendorList: 'vendors?{}',
  getWorkOrderSequence: 'projects/{}/wo-sequence',
  deleteWorkOrderItem: 'work-order-item/{}',
  updateWorkOrderItem: 'work-order-item/{}',
  getVendorTransaction: 'transactions/receiver-detail?{}',
  getVendorPayableSummary: 'vendor-summary/{}?{}',
  createVendorTransaction: 'work-order-payment',
  sendWOEmail: '/work-order/send-email/{}',
  editWorkOrder: 'work-order/details/{}',
  sendUpgradeRequest: 'send-upgrade-request-mail',
};

export const PO_SEQUENCING_APIS = {
  archiveSequence: 'sequence/{}',
  updateSeq: 'sequence/{}',
  restoreSeq: 'sequence/restore/{}',
  seqPrefix: 'sequence/{}/{}?seqName=po',
  seqCreate: 'sequence/create',
  getAllSeqActive: 'sequences/settings/{}?seqName=po&is_current=true',
  getAllSeqArchived: 'sequences/settings/{}?seqName=po&is_current=false',
  getAllSeq: 'sequences/settings/{}?seqName=po',
  getAllSeqProject: 'sequences/project-level/{}?seqName=po',
  getSeqPo: 'sequence/{}/{}?seqName=po',
};

export const INDENT_SEQUENCING_APIS = {
  archiveSequence: 'sequence/{}',
  updateSeq: 'sequence/{}',
  restoreSeq: 'sequence/restore/{}',
  seqPrefix: 'sequence/{}/{}?seqName=mt',
  seqCreate: 'sequence/create',
  getAllSeqActive: 'sequences/settings/{}?seqName=mt&is_current=true',
  getAllSeqArchived: 'sequences/settings/{}?seqName=mt&is_current=false',
  getAllSeq: 'sequences/settings/{}?seqName=mt',
  getAllSeqProject: 'sequences/project-level/{}?seqName=mt',
  getSeqPo: 'sequence/{}/{}?seqName=mt',
};

export const SITE_TRANSFER_APIS = {
  getSTItem: 'site-transfers/{}',
  deleteSTItem: 'material-issue/{}?{}',
  approveSTItem: 'material-issue/approve/{}',
  rejectSTItem: 'material-issue/reject/{}',
};

export const SEQUENCING_APIS = {
  archiveSequence: 'sequence/{}',
  updateSeq: 'sequence/{}',
  restoreSeq: 'sequence/restore/{}',
  seqPrefix: 'sequence/{}/{}?{}',
  seqCreate: 'sequence/create',
  getAllSeqActive: 'sequences/settings/{}?{}',
  getAllSeqArchived: 'sequences/settings/{}?{}',
  getAllSeq: 'sequences/settings/{}?{}',
  getAllSeqProject: 'sequences/project-level/{}?{}',
  getSeqPo: 'sequence/{}/{}?{}',
};

export const TRANSACTION_APIS = {
  syncTransaction: 'transactions-sync',
};

export const SUPERSET_APIS = {
  getSupersetOrgViews: 'superset/org-views?{}',
  getSupersetViewsGuestToken: 'superset/org-views/token',
};
