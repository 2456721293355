import CircularProgress from '@mui/material/CircularProgress';

function CustomSpinner() {
  return (
    <div className={`custom-spinner`}>
      <CircularProgress color="primary" />
    </div>
  );
}

export default CustomSpinner;
