import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import './customButton.scss';
import { CircularProgress } from '@mui/material';

const colorsEnum = {
  INHERIT: 'inherit',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
  DEFAULT: 'default',
} as const;

type DefaultProps = Omit<ButtonProps, 'color'>;

interface CustomButtonProps {
  /**label of the button*/
  label?: any;
  /**callback fired when the button is clicked*/
  handleSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  /**If `true`, the button will be disabled*/
  isDisabled?: boolean;
  className?: string;
  /**If `true`, the button will be is loading state*/
  spinner?: boolean;
  /**If `true`, the label of button will be in uppercase*/
  uppercase?: boolean;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'default';
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'small' | 'medium' | 'large';
  /**size of the button */
  buttonSize?: 'small' | 'medium' | 'large';
  [props: string]: any;
}

const colors = Object.values(colorsEnum);

const CustomButton = ({
  label = 'Save',
  variant = 'contained',
  color = 'primary',
  isDisabled,
  handleSubmit,
  buttonSize = 'medium',
  className,
  spinner,
  uppercase,
  style,
  startIcon,
  ...props
}: CustomButtonProps & DefaultProps) => {
  const propClasses = clsx(className);
  const customClass = variant + color.charAt(0).toUpperCase() + color.slice(1);
  const buttonLabel = uppercase ? label?.toUpperCase() : label;
  const lgBreakpoint = useMediaQuery((theme: any) =>
    theme.breakpoints.up('md')
  );
  buttonSize = lgBreakpoint ? buttonSize : 'medium';

  if (!colors.includes(color)) {
    color = 'default';
  }

  const renderSpinner = <CircularProgress size={20} color="inherit" />;

  return (
    <Button
      color={color as any}
      variant={variant}
      type="submit"
      disabled={isDisabled || spinner}
      onClick={handleSubmit}
      disableElevation
      className={`${customClass} customButtonRoot custombuttonLabel ${propClasses} custom-button-${buttonSize}`}
      style={{
        textTransform: 'none',
        ...style,
      }}
      startIcon={!spinner ? startIcon : renderSpinner}
      {...props}
    >
      {buttonLabel}
    </Button>
  );
};

export default CustomButton;
