import {
  NOTIFICATION_API_SUCCESS,
  NOTIFICATION_API_ERROR,
  NOTIFICATION_API_PENDING,
  GET_UNSEEN_COUNT,
  UPDATE_LAST_SEEN,
  GET_NOTIFICATIONS,
} from './Notification.type';
import { ReducerProps } from '../../../../interfaces/Base';

const initialState = {
  pending: false,
  error: null,
  notifications: [],
  unseenCount: 0,
};

export default function notificationReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
) {
  switch (type) {
    case NOTIFICATION_API_SUCCESS:
      return { ...state, pending: false };
    case NOTIFICATION_API_ERROR:
      return { ...state, pending: false, error: error };
    case NOTIFICATION_API_PENDING:
      return { ...state, pending: true };
    case GET_UNSEEN_COUNT:
      return { ...state, unseenCount: payload.count };
    case GET_NOTIFICATIONS:
      return { ...state, notifications: payload };
    case UPDATE_LAST_SEEN:
      return state;
    default:
      return state;
  }
}
