import { Dispatch } from 'react';

import {
  API_VERSIONS,
  ANALYTICS_DASHBOARD_APIS,
} from '../../../../routes.constant';
import { parseString, toastErrorMessage } from '../../../../utils';
import { eventDispatch } from '../../../base';
import { getRequest } from '../../../../apiClient';
import {
  SET_EMBED_URL,
  SET_ANALYTICS_DASHBOARD_API_PENDING,
  SET_ANALYTICS_DASHBOARD_LOADING,
  SET_VIEWS,
} from './Zoho.type';
import { stringify } from 'querystring';
import { track } from '../../common/Segment.action';
import { ZOHO_SEGMENT_CONSTANT } from '../../../../constant/segment_constant';
import { RootState } from '../../../Reducers';

export function getEmbedURL(params) {
  const apiUrl = parseString(
    ANALYTICS_DASHBOARD_APIS.getEmbedURL,
    stringify({ org_id: params.org_id, view_id: params.view_id })
  );
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(eventDispatch(SET_ANALYTICS_DASHBOARD_API_PENDING, true));
    dispatch(eventDispatch(SET_ANALYTICS_DASHBOARD_LOADING, true));
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(SET_ANALYTICS_DASHBOARD_LOADING, false));
      dispatch(eventDispatch(SET_EMBED_URL, data));
      dispatch(eventDispatch(SET_ANALYTICS_DASHBOARD_API_PENDING, false));
      dispatch(
        track(ZOHO_SEGMENT_CONSTANT.ANALYTICS_DASHBOARD_TAB_LOAD, {
          selected_tab: params.view_name,
          team_name: getState().userreducer.team_name,
        })
      );
    } catch (error) {
      dispatch(eventDispatch(SET_ANALYTICS_DASHBOARD_LOADING, false));
      dispatch(eventDispatch(SET_EMBED_URL, true));
      dispatch(eventDispatch(SET_ANALYTICS_DASHBOARD_API_PENDING, false));
      toastErrorMessage('Something went wrong');
      dispatch(
        track(ZOHO_SEGMENT_CONSTANT.ANALYTICS_DASHBOARD_ERROR_SCREEN, {
          error: error.message,
          team_name: getState().userreducer.team_name,
        })
      );
    }
  };
}

export function getOrgViews(params) {
  const apiUrl = parseString(
    ANALYTICS_DASHBOARD_APIS.getViews,
    stringify(params)
  );
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(eventDispatch(SET_ANALYTICS_DASHBOARD_LOADING, true));
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(SET_VIEWS, data.views));
      dispatch(eventDispatch(SET_ANALYTICS_DASHBOARD_LOADING, false));
      dispatch(
        track(ZOHO_SEGMENT_CONSTANT.ANALYTICS_DASHBOARD_SCREEN_LOAD, {
          team_name: getState().userreducer.team_name,
        })
      );
    } catch (error) {
      dispatch(
        track(ZOHO_SEGMENT_CONSTANT.ANALYTICS_DASHBOARD_ERROR_SCREEN, {
          error: error.message,
          team_name: getState().userreducer.team_name,
        })
      );
      toastErrorMessage('Some error while getting data');
      dispatch(eventDispatch(SET_ANALYTICS_DASHBOARD_LOADING, false));
    }
  };
}
