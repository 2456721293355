/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\nquery GetOrgWcOptions($orgId: String!, $sortOption: String, $orgPackageType: Int) {\n  orgPackages {\n    getAllByOrgId(org_id: $orgId, sort_option: $sortOption, org_package_type: $orgPackageType) {\n      id: _id\n      name\n    }\n  }\n}':
    types.GetOrgWcOptionsDocument,
  '\nquery GetPayablesForPO($poId: String!, $vendorId: String!, $orgId: String!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getPayablesForPO(po_id: $poId, vendor_id: $vendorId, org_id: $orgId, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt) {\n      _id\n      type\n      display_id\n      delivery_date\n      project_id\n      project_name\n      po_amount\n      grn_files {\n        name\n        url\n        mime_type\n      }\n      grn_amount\n      grn_adjustment\n      grn_total\n      invoice_files {\n        name\n        url\n        mime_type\n      }\n      invoice_number\n      invoice_amount\n      invoice_adjustment\n      pending_amount\n      invoice_total\n      total_amount\n      paid_amount\n      payment_status\n      payment_remarks\n      hasInvoice\n      canBePaid\n      parent_id\n      parent_type\n    }\n  }\n}':
    types.GetPayablesForPoDocument,
  '\nquery GetPayablesForInvoice($invoiceId: String!, $vendorId: String!, $orgId: String!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getPayablesForInvoice(invoice_id: $invoiceId, vendor_id: $vendorId, org_id: $orgId, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt) {\n      _id\n      delivery_date\n      display_id\n      grn_adjustment\n      grn_amount\n      grn_files {\n        mime_type \n        name\n        url\n      }\n      grn_total\n      invoice_adjustment\n      invoice_amount\n      invoice_files {\n        mime_type\n        name\n        url\n      }\n      invoice_number\n      invoice_total\n      paid_amount\n      payment_remarks\n      payment_status\n      po_amount\n      project_id\n      project_name\n      total_amount\n      type\n      hasInvoice\n      canBePaid\n      parent_id\n      parent_type\n    }\n  }\n}':
    types.GetPayablesForInvoiceDocument,
  '\nquery getOrgProjects(\n  $orgId: String!\n  $page: page_Int_NotNull_min_0!\n  $limit: limit_Int_NotNull_min_20_max_100!\n  $search: String\n) {\n  projects {\n    paginateUserProjects(org_id: $orgId, page: $page, limit: $limit, search: $search) {\n      _id\n      name\n    }\n  }\n}':
    types.GetOrgProjectsDocument,
  '\nquery GetAllUsersOfProject($projectId: String!) {\n  users {\n    getUsersByProject(projectId: $projectId) {\n      _id\n      displayName\n      mobile_number\n      country_code\n      team_name\n      isVerified\n      email\n      usersProject {\n        actions\n        project_id\n        training_status\n      }\n    }\n  }\n}':
    types.GetAllUsersOfProjectDocument,
  '\nquery GetWCByOrgId($orgId: String!, $orgPackageType: Int) {\n  orgPackages {\n    getAllByOrgId(org_id: $orgId, org_package_type: $orgPackageType) {\n      _id\n      hash_value\n      name\n      org_id\n      org_package_type\n    }\n  }\n}':
    types.GetWcByOrgIdDocument,
  '\nquery GetLinkedtaskForCustomField($fieldId: String!) {\n  tasks {\n    showLinkedTasksForCustomField(field_id: $fieldId) {\n      name\n      ancestors {\n        name\n        _id\n      }\n      index_number\n      _id\n    }\n  }\n}   \n':
    types.GetLinkedtaskForCustomFieldDocument,
  '\nquery GetChecklistForEntity($entityId: String!) {\n  checklistitems {\n    getAllForEntity(entity_id: $entityId) {\n      _id\n      label\n      is_active\n      parent_id\n      order\n      updatedAt\n      createdAt\n      updater_id\n      state\n      updater_name\n      creator_id\n      creator_name\n    }\n  }\n}':
    types.GetChecklistForEntityDocument,
  '\nquery PettyCashById($id: String!) {\n  pettyCashes {\n    getById(id: $id) {\n      _id\n      amount\n      creator_name\n      createdAt\n      pc_display_id\n      receiver_id\n      receiver_details {\n        _id\n        displayName\n      }\n      grn_display_id\n      ref_item_id\n      payment_mode_id\n      payment_mode {\n        payment_method\n      }\n      payment_date_MS\n      transaction_type\n      vendor_details {\n        _id\n        name\n      }\n      updater_name\n      updater_id\n      updatedAt\n      remarks\n      amount\n      payer_details {\n        _id\n        displayName\n      }\n      project {\n        _id\n        name\n      }\n      files {\n        mime_type\n        url\n        name\n      }\n      pc_status\n      approvers {\n        level\n        state\n        users {\n          user_id\n          user_name\n          user_profile_url\n          dateMS\n          state\n          note\n        }\n      }\n    }\n  }\n}':
    types.PettyCashByIdDocument,
  '\nquery GetPettyCashByOrgId($orgId: String!, $page: page_Int_NotNull_min_0!, $limit: limit_Int_NotNull_min_20_max_100!, $dFilter: DFilterObject) {\n  pettyCashes {\n    getByOrgId(org_id: $orgId, page: $page, limit: $limit, dFilter: $dFilter) {\n      _id\n      amount\n      pc_display_id\n      remarks\n      org_package_id\n      creator_name\n      org_package_name\n      transaction_type\n      receiver_id\n      receiver_details {\n        _id\n        displayName\n      }\n      project {\n        name\n      }\n      payer_details {\n        _id\n        displayName\n      }\n      vendor_details {\n        _id\n        name\n      }\n      labour_details {\n        _id,\n        name\n      }\n      payment_mode_id\n      payment_mode {\n        payment_method\n      }\n      payment_date_MS\n      createdAt\n      files {\n        mime_type\n        name\n        url\n      }\n      pc_status\n      approvers {\n        level\n        state\n        users {\n          user_id\n          user_name\n          user_profile_url\n          dateMS\n          state\n          note\n        }\n      }\n    }\n  }\n}':
    types.GetPettyCashByOrgIdDocument,
  '\nquery GetPettyCashCountByOrgId($orgId: String!, $projects: String, $dFilter: DFilterObject) {\n  pettyCashes {\n    getCountByOrgId(org_id: $orgId, projects: $projects, dFilter: $dFilter)\n  }\n}':
    types.GetPettyCashCountByOrgIdDocument,
  '\nquery ConsumtionListQuery($orgId: String!, $page: page_Int_NotNull_min_0!, $limit: limit_Int_NotNull_min_10!, $projects: [String], $materials: [String], $creators: [String], $createdAt: DateInputProps, $types: [Int], $vendors: [String], $tasks: [String], $sortOption: [SortOptionProps], $checkers: [String], $members: [String], $materialTags: [String] ) {\n  inventoryHistories {\n    getConsumptionList(org_id: $orgId, page: $page, limit: $limit, projects: $projects, materials: $materials, creators: $creators, createdAt: $createdAt, types: $types, vendors: $vendors, tasks: $tasks, sortOption: $sortOption, checkers: $checkers, members: $members, material_tags:$materialTags) {\n      _id\n      material {\n        name\n        quantity_str\n        specs\n      }\n      material_name\n      dateMS\n      files\n      mi {\n        ancestors\n        checker_name\n        task_name\n        vendor_name\n      }\n      project_id\n      project_name\n      remarks\n      specs\n      user_id\n      user_name\n      item_type\n      item_display_name\n      material_tags {\n      _id\n      tag_name\n      }\n    }\n  }\n}':
    types.ConsumtionListQueryDocument,
  '\nquery ConsumtionListQueryCount($orgId: String!, $projects: [String], $materials: [String], $creators: [String], $createdAt: DateInputProps, $types: [Int], $vendors: [String], $tasks: [String], $checkers: [String], $members: [String], $materialTags: [String]) {\n  inventoryHistories {\n    getConsumptionListCount(org_id: $orgId, projects: $projects, materials: $materials, creators: $creators, createdAt: $createdAt, types: $types, vendors: $vendors, tasks: $tasks, checkers: $checkers, members: $members, material_tags:$materialTags)\n  }\n}':
    types.ConsumtionListQueryCountDocument,
  '\nquery GetAllProjectsWise(\n  $orgId: String!\n  $search: String\n  $page: page_Int_NotNull_min_0!\n  $limit: limit_Int_NotNull_min_20_max_100!\n) {\n  projects {\n    paginateUserProjects(org_id: $orgId, search: $search, page: $page, limit: $limit,) {\n      _id\n      name\n    }\n  }\n}':
    types.GetAllProjectsWiseDocument,
  '\nquery MaterialIssues($orgId: String!, $page: page_Int_NotNull_min_0!, $limit: limit_Int_NotNull_min_20_max_100!, $bomIds: [String], $taskIds: [String], $vendorIds: [String], $startDate: Long, $endDate: Long, $memberIds: [String], $materialTagIds: [String], $miDisplayIds: [String], $show_deleted: Boolean,  $miStatus: [Int]) {\n  materialIssues {\n    getByOrgId(org_id: $orgId, page: $page, limit: $limit, bom_ids: $bomIds, task_ids: $taskIds, vendor_ids: $vendorIds, start_date: $startDate, end_date: $endDate, material_tag_ids: $materialTagIds, member_ids: $memberIds, mi_display_ids: $miDisplayIds, show_deleted: $show_deleted, mi_status: $miStatus) {\n      _id\n      project_id\n      vendor_name\n      project_name\n      issued_items {\n        bom_id\n        name\n        description\n        uom\n        task_list {\n          task_id\n          task_name\n          quantity\n        }\n        quantity\n      }\n      tags {\n        _id\n        tag_name\n      }\n      creator_id\n      creator_name\n      createdAt\n      material_tags{\n        _id\n        tag_name\n      }\n      mi_display_id\n      issuer_name\n      mi_status\n      is_active\n    }\n  }\n}':
    types.MaterialIssuesDocument,
  '\nquery GetMIConsumedCount($orgId: String!, $bomIds: [String], $taskIds: [String], $vendorIds: [String], $startDate: Long, $endDate: Long, $memberIds: [String], $materialTagIds: [String], $miDisplayIds: [String], $show_deleted: Boolean, $miStatus: [Int]) {\n  materialIssues {\n    getCount(org_id: $orgId, bom_ids: $bomIds, task_ids: $taskIds, vendor_ids: $vendorIds, start_date: $startDate, end_date: $endDate, material_tag_ids: $materialTagIds, member_ids: $memberIds, mi_display_ids: $miDisplayIds, show_deleted: $show_deleted, , mi_status: $miStatus) {\n      totalCount\n    }\n  }\n}':
    types.GetMiConsumedCountDocument,
  '\nquery GetPayablesForVendor($orgId: String!, $vendorId: String!, $page: page_Int_NotNull_min_0!, $limit: limit_Int_NotNull_min_1!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getPayblesForVendor(org_id: $orgId, vendor_id: $vendorId, page: $page, limit: $limit, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt) {\n      _id\n      delivery_date\n      display_id\n      grn_adjustment\n      grn_amount\n      grn_files {\n        mime_type \n        name\n        url\n      }\n      grn_total\n      invoice_adjustment\n      invoice_amount\n      invoice_files {\n        mime_type\n        name\n        url\n      }\n      invoice_number\n      invoice_total\n      paid_amount\n      payment_remarks\n      payment_status\n      po_amount\n      project_id\n      project_name\n      total_amount\n      pending_amount\n      type\n      hasInvoice\n      canBePaid\n      parent_id\n      parent_type\n      po_amounts {\n        grn_amount\n        grn_adjustment\n        grn_total\n        invoice_amount\n        invoice_adjustment\n        invoice_total\n      }\n    }\n  }\n}':
    types.GetPayablesForVendorDocument,
  '\nquery GetPayablesForVendorCount($orgId: String!, $vendorId: String!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getPayblesForVendorCount(org_id: $orgId, vendor_id: $vendorId, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt)\n  }\n}':
    types.GetPayablesForVendorCountDocument,
  '\nquery getVendorPaymentDetails($orgId: String!, $vendorId: String!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getVendorPaymentDetails(org_id: $orgId, vendor_id: $vendorId, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt) {\n      amount_to_pay\n      grn_amount\n      invoice_amount\n      amount_paid\n      non_invoice_grn_amount\n    }\n  }\n}':
    types.GetVendorPaymentDetailsDocument,
  '\nquery GetAllProjects(\n  $orgId: String!\n  $page: page_Int_NotNull_min_0!\n  $limit: limit_Int_NotNull_min_20_max_100!\n  $pinned: [String]\n  $stages: [String]\n  $tags: [String]\n  $sortOption: [SortOptionProps]\n  $search: String\n  $status: [Number]\n) {\n  projects {\n    paginateUserProjects(org_id: $orgId, page: $page, limit: $limit, pinned: $pinned, sortOption: $sortOption, search: $search, tags: $tags, stages: $stages, status: $status) {\n      _id\n      name\n      description\n      org_id\n      org_name\n      project_address\n      project_state\n      tc\n      promised_cost\n      promised_handover_date\n      start_date\n      end_date\n      last_status_update_date\n      expected_cost\n      expected_handover_date\n      photo_url\n      is_active\n      feed_unlocked\n      project_status\n      project_progress\n      actual_start_date\n      actual_end_date\n      planned_start_date\n      planned_end_date\n      project_stages {\n        _id\n        task_status\n        task_id\n        stage_id\n      }\n      project_plan_type\n    }\n  }\n}':
    types.GetAllProjectsDocument,
  '\nquery GetSTByBomsCount($orgId: String!, $dFilter: DFilterObject) {\n  siteTransfers {\n    getSTByBomsCount(org_id: $orgId, dFilter: $dFilter) \n  }\n}':
    types.GetStByBomsCountDocument,
  '\n  query GetSTbyBoms(\n    $orgId: String!\n    $page: page_Int_NotNull_min_0!\n    $limit: limit_Int_NotNull_min_20_max_100!\n    $dFilter: DFilterObject\n  ) {\n    siteTransfers {\n      getSTbyBoms(\n        org_id: $orgId\n        page: $page\n        limit: $limit\n        dFilter: $dFilter\n      ) {\n        _id\n        createdAt\n        st_display_id\n        source_name\n        remarks\n        boms {\n          _id\n          name\n          description\n          quantity\n          uom\n          total_received_quantity\n          received_by_name\n          remarks\n        }\n        project {\n          name\n        }\n        st_status\n        is_active\n        creator_id\n        creator_name\n        created_at\n        updated_at\n        st_status\n        is_active\n        }\n    }\n  }':
    types.GetSTbyBomsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetOrgWcOptions($orgId: String!, $sortOption: String, $orgPackageType: Int) {\n  orgPackages {\n    getAllByOrgId(org_id: $orgId, sort_option: $sortOption, org_package_type: $orgPackageType) {\n      id: _id\n      name\n    }\n  }\n}'
): (typeof documents)['\nquery GetOrgWcOptions($orgId: String!, $sortOption: String, $orgPackageType: Int) {\n  orgPackages {\n    getAllByOrgId(org_id: $orgId, sort_option: $sortOption, org_package_type: $orgPackageType) {\n      id: _id\n      name\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetPayablesForPO($poId: String!, $vendorId: String!, $orgId: String!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getPayablesForPO(po_id: $poId, vendor_id: $vendorId, org_id: $orgId, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt) {\n      _id\n      type\n      display_id\n      delivery_date\n      project_id\n      project_name\n      po_amount\n      grn_files {\n        name\n        url\n        mime_type\n      }\n      grn_amount\n      grn_adjustment\n      grn_total\n      invoice_files {\n        name\n        url\n        mime_type\n      }\n      invoice_number\n      invoice_amount\n      invoice_adjustment\n      pending_amount\n      invoice_total\n      total_amount\n      paid_amount\n      payment_status\n      payment_remarks\n      hasInvoice\n      canBePaid\n      parent_id\n      parent_type\n    }\n  }\n}'
): (typeof documents)['\nquery GetPayablesForPO($poId: String!, $vendorId: String!, $orgId: String!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getPayablesForPO(po_id: $poId, vendor_id: $vendorId, org_id: $orgId, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt) {\n      _id\n      type\n      display_id\n      delivery_date\n      project_id\n      project_name\n      po_amount\n      grn_files {\n        name\n        url\n        mime_type\n      }\n      grn_amount\n      grn_adjustment\n      grn_total\n      invoice_files {\n        name\n        url\n        mime_type\n      }\n      invoice_number\n      invoice_amount\n      invoice_adjustment\n      pending_amount\n      invoice_total\n      total_amount\n      paid_amount\n      payment_status\n      payment_remarks\n      hasInvoice\n      canBePaid\n      parent_id\n      parent_type\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetPayablesForInvoice($invoiceId: String!, $vendorId: String!, $orgId: String!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getPayablesForInvoice(invoice_id: $invoiceId, vendor_id: $vendorId, org_id: $orgId, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt) {\n      _id\n      delivery_date\n      display_id\n      grn_adjustment\n      grn_amount\n      grn_files {\n        mime_type \n        name\n        url\n      }\n      grn_total\n      invoice_adjustment\n      invoice_amount\n      invoice_files {\n        mime_type\n        name\n        url\n      }\n      invoice_number\n      invoice_total\n      paid_amount\n      payment_remarks\n      payment_status\n      po_amount\n      project_id\n      project_name\n      total_amount\n      type\n      hasInvoice\n      canBePaid\n      parent_id\n      parent_type\n    }\n  }\n}'
): (typeof documents)['\nquery GetPayablesForInvoice($invoiceId: String!, $vendorId: String!, $orgId: String!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getPayablesForInvoice(invoice_id: $invoiceId, vendor_id: $vendorId, org_id: $orgId, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt) {\n      _id\n      delivery_date\n      display_id\n      grn_adjustment\n      grn_amount\n      grn_files {\n        mime_type \n        name\n        url\n      }\n      grn_total\n      invoice_adjustment\n      invoice_amount\n      invoice_files {\n        mime_type\n        name\n        url\n      }\n      invoice_number\n      invoice_total\n      paid_amount\n      payment_remarks\n      payment_status\n      po_amount\n      project_id\n      project_name\n      total_amount\n      type\n      hasInvoice\n      canBePaid\n      parent_id\n      parent_type\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery getOrgProjects(\n  $orgId: String!\n  $page: page_Int_NotNull_min_0!\n  $limit: limit_Int_NotNull_min_20_max_100!\n  $search: String\n) {\n  projects {\n    paginateUserProjects(org_id: $orgId, page: $page, limit: $limit, search: $search) {\n      _id\n      name\n    }\n  }\n}'
): (typeof documents)['\nquery getOrgProjects(\n  $orgId: String!\n  $page: page_Int_NotNull_min_0!\n  $limit: limit_Int_NotNull_min_20_max_100!\n  $search: String\n) {\n  projects {\n    paginateUserProjects(org_id: $orgId, page: $page, limit: $limit, search: $search) {\n      _id\n      name\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetAllUsersOfProject($projectId: String!) {\n  users {\n    getUsersByProject(projectId: $projectId) {\n      _id\n      displayName\n      mobile_number\n      country_code\n      team_name\n      isVerified\n      email\n      usersProject {\n        actions\n        project_id\n        training_status\n      }\n    }\n  }\n}'
): (typeof documents)['\nquery GetAllUsersOfProject($projectId: String!) {\n  users {\n    getUsersByProject(projectId: $projectId) {\n      _id\n      displayName\n      mobile_number\n      country_code\n      team_name\n      isVerified\n      email\n      usersProject {\n        actions\n        project_id\n        training_status\n      }\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetWCByOrgId($orgId: String!, $orgPackageType: Int) {\n  orgPackages {\n    getAllByOrgId(org_id: $orgId, org_package_type: $orgPackageType) {\n      _id\n      hash_value\n      name\n      org_id\n      org_package_type\n    }\n  }\n}'
): (typeof documents)['\nquery GetWCByOrgId($orgId: String!, $orgPackageType: Int) {\n  orgPackages {\n    getAllByOrgId(org_id: $orgId, org_package_type: $orgPackageType) {\n      _id\n      hash_value\n      name\n      org_id\n      org_package_type\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetLinkedtaskForCustomField($fieldId: String!) {\n  tasks {\n    showLinkedTasksForCustomField(field_id: $fieldId) {\n      name\n      ancestors {\n        name\n        _id\n      }\n      index_number\n      _id\n    }\n  }\n}   \n'
): (typeof documents)['\nquery GetLinkedtaskForCustomField($fieldId: String!) {\n  tasks {\n    showLinkedTasksForCustomField(field_id: $fieldId) {\n      name\n      ancestors {\n        name\n        _id\n      }\n      index_number\n      _id\n    }\n  }\n}   \n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetChecklistForEntity($entityId: String!) {\n  checklistitems {\n    getAllForEntity(entity_id: $entityId) {\n      _id\n      label\n      is_active\n      parent_id\n      order\n      updatedAt\n      createdAt\n      updater_id\n      state\n      updater_name\n      creator_id\n      creator_name\n    }\n  }\n}'
): (typeof documents)['\nquery GetChecklistForEntity($entityId: String!) {\n  checklistitems {\n    getAllForEntity(entity_id: $entityId) {\n      _id\n      label\n      is_active\n      parent_id\n      order\n      updatedAt\n      createdAt\n      updater_id\n      state\n      updater_name\n      creator_id\n      creator_name\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery PettyCashById($id: String!) {\n  pettyCashes {\n    getById(id: $id) {\n      _id\n      amount\n      creator_name\n      createdAt\n      pc_display_id\n      receiver_id\n      receiver_details {\n        _id\n        displayName\n      }\n      grn_display_id\n      ref_item_id\n      payment_mode_id\n      payment_mode {\n        payment_method\n      }\n      payment_date_MS\n      transaction_type\n      vendor_details {\n        _id\n        name\n      }\n      updater_name\n      updater_id\n      updatedAt\n      remarks\n      amount\n      payer_details {\n        _id\n        displayName\n      }\n      project {\n        _id\n        name\n      }\n      files {\n        mime_type\n        url\n        name\n      }\n      pc_status\n      approvers {\n        level\n        state\n        users {\n          user_id\n          user_name\n          user_profile_url\n          dateMS\n          state\n          note\n        }\n      }\n    }\n  }\n}'
): (typeof documents)['\nquery PettyCashById($id: String!) {\n  pettyCashes {\n    getById(id: $id) {\n      _id\n      amount\n      creator_name\n      createdAt\n      pc_display_id\n      receiver_id\n      receiver_details {\n        _id\n        displayName\n      }\n      grn_display_id\n      ref_item_id\n      payment_mode_id\n      payment_mode {\n        payment_method\n      }\n      payment_date_MS\n      transaction_type\n      vendor_details {\n        _id\n        name\n      }\n      updater_name\n      updater_id\n      updatedAt\n      remarks\n      amount\n      payer_details {\n        _id\n        displayName\n      }\n      project {\n        _id\n        name\n      }\n      files {\n        mime_type\n        url\n        name\n      }\n      pc_status\n      approvers {\n        level\n        state\n        users {\n          user_id\n          user_name\n          user_profile_url\n          dateMS\n          state\n          note\n        }\n      }\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetPettyCashByOrgId($orgId: String!, $page: page_Int_NotNull_min_0!, $limit: limit_Int_NotNull_min_20_max_100!, $dFilter: DFilterObject) {\n  pettyCashes {\n    getByOrgId(org_id: $orgId, page: $page, limit: $limit, dFilter: $dFilter) {\n      _id\n      amount\n      pc_display_id\n      remarks\n      org_package_id\n      creator_name\n      org_package_name\n      transaction_type\n      receiver_id\n      receiver_details {\n        _id\n        displayName\n      }\n      project {\n        name\n      }\n      payer_details {\n        _id\n        displayName\n      }\n      vendor_details {\n        _id\n        name\n      }\n      labour_details {\n        _id,\n        name\n      }\n      payment_mode_id\n      payment_mode {\n        payment_method\n      }\n      payment_date_MS\n      createdAt\n      files {\n        mime_type\n        name\n        url\n      }\n      pc_status\n      approvers {\n        level\n        state\n        users {\n          user_id\n          user_name\n          user_profile_url\n          dateMS\n          state\n          note\n        }\n      }\n    }\n  }\n}'
): (typeof documents)['\nquery GetPettyCashByOrgId($orgId: String!, $page: page_Int_NotNull_min_0!, $limit: limit_Int_NotNull_min_20_max_100!, $dFilter: DFilterObject) {\n  pettyCashes {\n    getByOrgId(org_id: $orgId, page: $page, limit: $limit, dFilter: $dFilter) {\n      _id\n      amount\n      pc_display_id\n      remarks\n      org_package_id\n      creator_name\n      org_package_name\n      transaction_type\n      receiver_id\n      receiver_details {\n        _id\n        displayName\n      }\n      project {\n        name\n      }\n      payer_details {\n        _id\n        displayName\n      }\n      vendor_details {\n        _id\n        name\n      }\n      labour_details {\n        _id,\n        name\n      }\n      payment_mode_id\n      payment_mode {\n        payment_method\n      }\n      payment_date_MS\n      createdAt\n      files {\n        mime_type\n        name\n        url\n      }\n      pc_status\n      approvers {\n        level\n        state\n        users {\n          user_id\n          user_name\n          user_profile_url\n          dateMS\n          state\n          note\n        }\n      }\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetPettyCashCountByOrgId($orgId: String!, $projects: String, $dFilter: DFilterObject) {\n  pettyCashes {\n    getCountByOrgId(org_id: $orgId, projects: $projects, dFilter: $dFilter)\n  }\n}'
): (typeof documents)['\nquery GetPettyCashCountByOrgId($orgId: String!, $projects: String, $dFilter: DFilterObject) {\n  pettyCashes {\n    getCountByOrgId(org_id: $orgId, projects: $projects, dFilter: $dFilter)\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery ConsumtionListQuery($orgId: String!, $page: page_Int_NotNull_min_0!, $limit: limit_Int_NotNull_min_10!, $projects: [String], $materials: [String], $creators: [String], $createdAt: DateInputProps, $types: [Int], $vendors: [String], $tasks: [String], $sortOption: [SortOptionProps], $checkers: [String], $members: [String], $materialTags: [String] ) {\n  inventoryHistories {\n    getConsumptionList(org_id: $orgId, page: $page, limit: $limit, projects: $projects, materials: $materials, creators: $creators, createdAt: $createdAt, types: $types, vendors: $vendors, tasks: $tasks, sortOption: $sortOption, checkers: $checkers, members: $members, material_tags:$materialTags) {\n      _id\n      material {\n        name\n        quantity_str\n        specs\n      }\n      material_name\n      dateMS\n      files\n      mi {\n        ancestors\n        checker_name\n        task_name\n        vendor_name\n      }\n      project_id\n      project_name\n      remarks\n      specs\n      user_id\n      user_name\n      item_type\n      item_display_name\n      material_tags {\n      _id\n      tag_name\n      }\n    }\n  }\n}'
): (typeof documents)['\nquery ConsumtionListQuery($orgId: String!, $page: page_Int_NotNull_min_0!, $limit: limit_Int_NotNull_min_10!, $projects: [String], $materials: [String], $creators: [String], $createdAt: DateInputProps, $types: [Int], $vendors: [String], $tasks: [String], $sortOption: [SortOptionProps], $checkers: [String], $members: [String], $materialTags: [String] ) {\n  inventoryHistories {\n    getConsumptionList(org_id: $orgId, page: $page, limit: $limit, projects: $projects, materials: $materials, creators: $creators, createdAt: $createdAt, types: $types, vendors: $vendors, tasks: $tasks, sortOption: $sortOption, checkers: $checkers, members: $members, material_tags:$materialTags) {\n      _id\n      material {\n        name\n        quantity_str\n        specs\n      }\n      material_name\n      dateMS\n      files\n      mi {\n        ancestors\n        checker_name\n        task_name\n        vendor_name\n      }\n      project_id\n      project_name\n      remarks\n      specs\n      user_id\n      user_name\n      item_type\n      item_display_name\n      material_tags {\n      _id\n      tag_name\n      }\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery ConsumtionListQueryCount($orgId: String!, $projects: [String], $materials: [String], $creators: [String], $createdAt: DateInputProps, $types: [Int], $vendors: [String], $tasks: [String], $checkers: [String], $members: [String], $materialTags: [String]) {\n  inventoryHistories {\n    getConsumptionListCount(org_id: $orgId, projects: $projects, materials: $materials, creators: $creators, createdAt: $createdAt, types: $types, vendors: $vendors, tasks: $tasks, checkers: $checkers, members: $members, material_tags:$materialTags)\n  }\n}'
): (typeof documents)['\nquery ConsumtionListQueryCount($orgId: String!, $projects: [String], $materials: [String], $creators: [String], $createdAt: DateInputProps, $types: [Int], $vendors: [String], $tasks: [String], $checkers: [String], $members: [String], $materialTags: [String]) {\n  inventoryHistories {\n    getConsumptionListCount(org_id: $orgId, projects: $projects, materials: $materials, creators: $creators, createdAt: $createdAt, types: $types, vendors: $vendors, tasks: $tasks, checkers: $checkers, members: $members, material_tags:$materialTags)\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetAllProjectsWise(\n  $orgId: String!\n  $search: String\n  $page: page_Int_NotNull_min_0!\n  $limit: limit_Int_NotNull_min_20_max_100!\n) {\n  projects {\n    paginateUserProjects(org_id: $orgId, search: $search, page: $page, limit: $limit,) {\n      _id\n      name\n    }\n  }\n}'
): (typeof documents)['\nquery GetAllProjectsWise(\n  $orgId: String!\n  $search: String\n  $page: page_Int_NotNull_min_0!\n  $limit: limit_Int_NotNull_min_20_max_100!\n) {\n  projects {\n    paginateUserProjects(org_id: $orgId, search: $search, page: $page, limit: $limit,) {\n      _id\n      name\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery MaterialIssues($orgId: String!, $page: page_Int_NotNull_min_0!, $limit: limit_Int_NotNull_min_20_max_100!, $bomIds: [String], $taskIds: [String], $vendorIds: [String], $startDate: Long, $endDate: Long, $memberIds: [String], $materialTagIds: [String], $miDisplayIds: [String], $show_deleted: Boolean,  $miStatus: [Int]) {\n  materialIssues {\n    getByOrgId(org_id: $orgId, page: $page, limit: $limit, bom_ids: $bomIds, task_ids: $taskIds, vendor_ids: $vendorIds, start_date: $startDate, end_date: $endDate, material_tag_ids: $materialTagIds, member_ids: $memberIds, mi_display_ids: $miDisplayIds, show_deleted: $show_deleted, mi_status: $miStatus) {\n      _id\n      project_id\n      vendor_name\n      project_name\n      issued_items {\n        bom_id\n        name\n        description\n        uom\n        task_list {\n          task_id\n          task_name\n          quantity\n        }\n        quantity\n      }\n      tags {\n        _id\n        tag_name\n      }\n      creator_id\n      creator_name\n      createdAt\n      material_tags{\n        _id\n        tag_name\n      }\n      mi_display_id\n      issuer_name\n      mi_status\n      is_active\n    }\n  }\n}'
): (typeof documents)['\nquery MaterialIssues($orgId: String!, $page: page_Int_NotNull_min_0!, $limit: limit_Int_NotNull_min_20_max_100!, $bomIds: [String], $taskIds: [String], $vendorIds: [String], $startDate: Long, $endDate: Long, $memberIds: [String], $materialTagIds: [String], $miDisplayIds: [String], $show_deleted: Boolean,  $miStatus: [Int]) {\n  materialIssues {\n    getByOrgId(org_id: $orgId, page: $page, limit: $limit, bom_ids: $bomIds, task_ids: $taskIds, vendor_ids: $vendorIds, start_date: $startDate, end_date: $endDate, material_tag_ids: $materialTagIds, member_ids: $memberIds, mi_display_ids: $miDisplayIds, show_deleted: $show_deleted, mi_status: $miStatus) {\n      _id\n      project_id\n      vendor_name\n      project_name\n      issued_items {\n        bom_id\n        name\n        description\n        uom\n        task_list {\n          task_id\n          task_name\n          quantity\n        }\n        quantity\n      }\n      tags {\n        _id\n        tag_name\n      }\n      creator_id\n      creator_name\n      createdAt\n      material_tags{\n        _id\n        tag_name\n      }\n      mi_display_id\n      issuer_name\n      mi_status\n      is_active\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetMIConsumedCount($orgId: String!, $bomIds: [String], $taskIds: [String], $vendorIds: [String], $startDate: Long, $endDate: Long, $memberIds: [String], $materialTagIds: [String], $miDisplayIds: [String], $show_deleted: Boolean, $miStatus: [Int]) {\n  materialIssues {\n    getCount(org_id: $orgId, bom_ids: $bomIds, task_ids: $taskIds, vendor_ids: $vendorIds, start_date: $startDate, end_date: $endDate, material_tag_ids: $materialTagIds, member_ids: $memberIds, mi_display_ids: $miDisplayIds, show_deleted: $show_deleted, , mi_status: $miStatus) {\n      totalCount\n    }\n  }\n}'
): (typeof documents)['\nquery GetMIConsumedCount($orgId: String!, $bomIds: [String], $taskIds: [String], $vendorIds: [String], $startDate: Long, $endDate: Long, $memberIds: [String], $materialTagIds: [String], $miDisplayIds: [String], $show_deleted: Boolean, $miStatus: [Int]) {\n  materialIssues {\n    getCount(org_id: $orgId, bom_ids: $bomIds, task_ids: $taskIds, vendor_ids: $vendorIds, start_date: $startDate, end_date: $endDate, material_tag_ids: $materialTagIds, member_ids: $memberIds, mi_display_ids: $miDisplayIds, show_deleted: $show_deleted, , mi_status: $miStatus) {\n      totalCount\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetPayablesForVendor($orgId: String!, $vendorId: String!, $page: page_Int_NotNull_min_0!, $limit: limit_Int_NotNull_min_1!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getPayblesForVendor(org_id: $orgId, vendor_id: $vendorId, page: $page, limit: $limit, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt) {\n      _id\n      delivery_date\n      display_id\n      grn_adjustment\n      grn_amount\n      grn_files {\n        mime_type \n        name\n        url\n      }\n      grn_total\n      invoice_adjustment\n      invoice_amount\n      invoice_files {\n        mime_type\n        name\n        url\n      }\n      invoice_number\n      invoice_total\n      paid_amount\n      payment_remarks\n      payment_status\n      po_amount\n      project_id\n      project_name\n      total_amount\n      pending_amount\n      type\n      hasInvoice\n      canBePaid\n      parent_id\n      parent_type\n      po_amounts {\n        grn_amount\n        grn_adjustment\n        grn_total\n        invoice_amount\n        invoice_adjustment\n        invoice_total\n      }\n    }\n  }\n}'
): (typeof documents)['\nquery GetPayablesForVendor($orgId: String!, $vendorId: String!, $page: page_Int_NotNull_min_0!, $limit: limit_Int_NotNull_min_1!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getPayblesForVendor(org_id: $orgId, vendor_id: $vendorId, page: $page, limit: $limit, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt) {\n      _id\n      delivery_date\n      display_id\n      grn_adjustment\n      grn_amount\n      grn_files {\n        mime_type \n        name\n        url\n      }\n      grn_total\n      invoice_adjustment\n      invoice_amount\n      invoice_files {\n        mime_type\n        name\n        url\n      }\n      invoice_number\n      invoice_total\n      paid_amount\n      payment_remarks\n      payment_status\n      po_amount\n      project_id\n      project_name\n      total_amount\n      pending_amount\n      type\n      hasInvoice\n      canBePaid\n      parent_id\n      parent_type\n      po_amounts {\n        grn_amount\n        grn_adjustment\n        grn_total\n        invoice_amount\n        invoice_adjustment\n        invoice_total\n      }\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetPayablesForVendorCount($orgId: String!, $vendorId: String!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getPayblesForVendorCount(org_id: $orgId, vendor_id: $vendorId, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt)\n  }\n}'
): (typeof documents)['\nquery GetPayablesForVendorCount($orgId: String!, $vendorId: String!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getPayblesForVendorCount(org_id: $orgId, vendor_id: $vendorId, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt)\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery getVendorPaymentDetails($orgId: String!, $vendorId: String!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getVendorPaymentDetails(org_id: $orgId, vendor_id: $vendorId, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt) {\n      amount_to_pay\n      grn_amount\n      invoice_amount\n      amount_paid\n      non_invoice_grn_amount\n    }\n  }\n}'
): (typeof documents)['\nquery getVendorPaymentDetails($orgId: String!, $vendorId: String!, $projects: [String], $invoices: [String], $grns: [String], $pos: [String], $invoiceNumbers: [String], $paymentStatus: [Int], $createdAt: DateInputProps) {\n  materialVendorPayables {\n    getVendorPaymentDetails(org_id: $orgId, vendor_id: $vendorId, projects: $projects, invoices: $invoices, grns: $grns, pos: $pos, invoice_numbers: $invoiceNumbers, payment_status: $paymentStatus, createdAt: $createdAt) {\n      amount_to_pay\n      grn_amount\n      invoice_amount\n      amount_paid\n      non_invoice_grn_amount\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetAllProjects(\n  $orgId: String!\n  $page: page_Int_NotNull_min_0!\n  $limit: limit_Int_NotNull_min_20_max_100!\n  $pinned: [String]\n  $stages: [String]\n  $tags: [String]\n  $sortOption: [SortOptionProps]\n  $search: String\n  $status: [Number]\n) {\n  projects {\n    paginateUserProjects(org_id: $orgId, page: $page, limit: $limit, pinned: $pinned, sortOption: $sortOption, search: $search, tags: $tags, stages: $stages, status: $status) {\n      _id\n      name\n      description\n      org_id\n      org_name\n      project_address\n      project_state\n      tc\n      promised_cost\n      promised_handover_date\n      start_date\n      end_date\n      last_status_update_date\n      expected_cost\n      expected_handover_date\n      photo_url\n      is_active\n      feed_unlocked\n      project_status\n      project_progress\n      actual_start_date\n      actual_end_date\n      planned_start_date\n      planned_end_date\n      project_stages {\n        _id\n        task_status\n        task_id\n        stage_id\n      }\n      project_plan_type\n    }\n  }\n}'
): (typeof documents)['\nquery GetAllProjects(\n  $orgId: String!\n  $page: page_Int_NotNull_min_0!\n  $limit: limit_Int_NotNull_min_20_max_100!\n  $pinned: [String]\n  $stages: [String]\n  $tags: [String]\n  $sortOption: [SortOptionProps]\n  $search: String\n  $status: [Number]\n) {\n  projects {\n    paginateUserProjects(org_id: $orgId, page: $page, limit: $limit, pinned: $pinned, sortOption: $sortOption, search: $search, tags: $tags, stages: $stages, status: $status) {\n      _id\n      name\n      description\n      org_id\n      org_name\n      project_address\n      project_state\n      tc\n      promised_cost\n      promised_handover_date\n      start_date\n      end_date\n      last_status_update_date\n      expected_cost\n      expected_handover_date\n      photo_url\n      is_active\n      feed_unlocked\n      project_status\n      project_progress\n      actual_start_date\n      actual_end_date\n      planned_start_date\n      planned_end_date\n      project_stages {\n        _id\n        task_status\n        task_id\n        stage_id\n      }\n      project_plan_type\n    }\n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\nquery GetSTByBomsCount($orgId: String!, $dFilter: DFilterObject) {\n  siteTransfers {\n    getSTByBomsCount(org_id: $orgId, dFilter: $dFilter) \n  }\n}'
): (typeof documents)['\nquery GetSTByBomsCount($orgId: String!, $dFilter: DFilterObject) {\n  siteTransfers {\n    getSTByBomsCount(org_id: $orgId, dFilter: $dFilter) \n  }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSTbyBoms(\n    $orgId: String!\n    $page: page_Int_NotNull_min_0!\n    $limit: limit_Int_NotNull_min_20_max_100!\n    $dFilter: DFilterObject\n  ) {\n    siteTransfers {\n      getSTbyBoms(\n        org_id: $orgId\n        page: $page\n        limit: $limit\n        dFilter: $dFilter\n      ) {\n        _id\n        createdAt\n        st_display_id\n        source_name\n        remarks\n        boms {\n          _id\n          name\n          description\n          quantity\n          uom\n          total_received_quantity\n          received_by_name\n          remarks\n        }\n        project {\n          name\n        }\n        st_status\n        is_active\n        creator_id\n        creator_name\n        created_at\n        updated_at\n        st_status\n        is_active\n        }\n    }\n  }'
): (typeof documents)['\n  query GetSTbyBoms(\n    $orgId: String!\n    $page: page_Int_NotNull_min_0!\n    $limit: limit_Int_NotNull_min_20_max_100!\n    $dFilter: DFilterObject\n  ) {\n    siteTransfers {\n      getSTbyBoms(\n        org_id: $orgId\n        page: $page\n        limit: $limit\n        dFilter: $dFilter\n      ) {\n        _id\n        createdAt\n        st_display_id\n        source_name\n        remarks\n        boms {\n          _id\n          name\n          description\n          quantity\n          uom\n          total_received_quantity\n          received_by_name\n          remarks\n        }\n        project {\n          name\n        }\n        st_status\n        is_active\n        creator_id\n        creator_name\n        created_at\n        updated_at\n        st_status\n        is_active\n        }\n    }\n  }'];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
