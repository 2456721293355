import { Switch } from '@mui/material';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import './customControlComponents.scss';

export const CustomBlueRadio = props => (
  <Radio color="default" className="customBlueRadioChecked" {...props} />
);

export const CustomBlueCheckbox = props => {
  return (
    <Checkbox
      color="default"
      className="customBlueCheckboxChecked"
      {...props}
    />
  );
};

// eslint-disable-next-line react/prop-types
export const MiniSwitch = ({ checked, onChange }) => {
  return (
    <Switch checked={checked} className={`miniSwitch`} onChange={onChange} />
  );
};

export const CustomBlueSwitch = ({ ...props }) => {
  return (
    <Switch
      focusVisibleClassName=""
      className={`customBlueSwitchRoot customBlueSwitchThumb customBlueSwitchTrack ${
        // eslint-disable-next-line react/prop-types
        props.disabled
          ? 'customBlueSwitchDisabledSwitchBase'
          : 'customBlueSwitchSwitchBase'
      }`}
      disableRipple
      {...props}
    ></Switch>
  );
};

// eslint-disable-next-line react/prop-types
export const SmallSwitch = ({ className, checked, onChange }) => {
  return (
    <Switch
      checked={checked}
      className={`${className} smallSwitch smallSwitchThumb smallSwitchTrack`}
      onChange={onChange}
    />
  );
};
