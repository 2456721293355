import {
  GET_ALL_DOCS,
  DOC_UPLOAD,
  DOC_API_SUCCESS,
  DOC_API_PENDING,
  DOC_API_ERROR,
  DOC_UPLOAD_SUCCESS,
  EXCEL_UPLOAD_ERROR,
  DOC_CLEAR_STATE,
  EXCEL_UPLOAD_WARNINGS,
  EXCEL_UPLOAD_MESSAGE,
  RESET_EXCEL_ERROR_LIST,
} from './Document.type';

const initialState = {
  documents: [],
  error: null,
  recentUploads: [],
  pending: false,
  docUploaded: false,
  excelError: [],
  excelWarnings: [],
  excelUploadMessage: '',
};

const documentreducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case GET_ALL_DOCS:
      return { ...state, documents: payload };
    case DOC_UPLOAD:
      return { ...state, recentUploads: payload };
    case DOC_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DOC_API_PENDING:
      return {
        ...state,
        pending: true,
        docUploaded: false,
      };
    case DOC_UPLOAD_SUCCESS:
      return {
        ...state,
        docUploaded: true,
        pending: false,
      };
    case DOC_API_ERROR:
      return {
        ...state,
        pending: false,
        error: error,
      };
    case EXCEL_UPLOAD_ERROR:
      return {
        ...state,
        pending: false,
        excelError: payload,
      };
    case EXCEL_UPLOAD_WARNINGS:
      return {
        ...state,
        excelWarnings: payload,
      };
    case EXCEL_UPLOAD_MESSAGE:
      return {
        ...state,
        excelUploadMessage: payload,
      };
    case RESET_EXCEL_ERROR_LIST:
      return {
        ...state,
        excelError: [],
      };
    case DOC_CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default documentreducer;
