export const SET_SHOW_MILESTONE_DETAILS = 'SET_SHOW_MILESTONE_DETAILS';
export const GET_ALL_MILESTONES = 'GET_ALL_MILESTONES';
export const GET_ALL_MILESTONES_PENDING = 'GET_ALL_MILESTONES_PENDING';
export const CREATE_MILESTONE_PENDING = 'CREATE_MILESTONE_PENDING';
export const CREATE_MILESTONE = 'CREATE_MILESTONE';
export const DELETE_MILESTONE = 'DELETE_MILESTONE';
export const DELETE_MILESTONE_PENDING = 'DELETE_MILESTONE_PENDING';
export const RAISE_BILL = 'RAISE_BILL';
export const GET_MILESTONE = 'GET_MILESTONE';
export const GET_MILESTONE_PENDING = 'GET_MILESTONE_PENDING';
export const MILESTONE_ID_SELECTED = 'MILESTONE_ID_SELECTED';
export const UPDATE_MILESTONE_PENDING = 'UPDATE_MILESTONE_PENDING';
export const LINK_TASK_TO_MILESTONE_PENDING = 'LINK_TASK_TO_MILESTONE_PENDING';
export const LINK_BUDGET_TO_MILESTONE_PENDING =
  'LINK_BUDGET_TO_MILESTONE_PENDING';
export const GET_MILESTONE_SUMMARY = 'GET_MILESTONE_SUMMARY';
export const GET_MILESTONE_SUMMARY_PENDING = 'GET_MILESTONE_SUMMARY_PENDING';
