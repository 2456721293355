import {
  GET_SUPERSET_ORG_VIEWS_PENDING,
  GET_SUPERSET_ORG_VIEWS_SUCCESS,
  GET_SUPERSET_ORG_VIEWS_ERROR,
  GET_SUPERSET_VIEW_TOKEN_PENDING,
  GET_SUPERSET_VIEW_TOKEN_SUCCESS,
  GET_SUPERSET_VIEW_TOKEN_ERROR,
} from './Superset.types';
import { ReducerProps } from '../../../../interfaces/Base';
import {
  type ISupersetOrgView,
  type ISupersetOrgViewsGuestToken,
} from '../../../../modules/superset/types';

interface IReducerInitialState {
  org_id: string;
  superset_org_views: ISupersetOrgView[];
  is_fetching_superset_org_views: boolean;
  error_fetching_superset_org_views: string;

  superset_org_views_guest_token: ISupersetOrgViewsGuestToken;
  is_fetching_superset_view_token: boolean;
  error_fetching_superset_view_token: string;
}

const reducerInitialState: IReducerInitialState = {
  org_id: '',

  superset_org_views: [],
  is_fetching_superset_org_views: false,
  error_fetching_superset_org_views: '',

  superset_org_views_guest_token: {
    token: '',
    last_fetched_in_ms: 0,
  },
  is_fetching_superset_view_token: false,
  error_fetching_superset_view_token: '',
};

const supersetReducer = (
  state = reducerInitialState,
  { type, payload, error }: ReducerProps = { type: '', payload: null }
) => {
  switch (type) {
    // GET_SUPERSET_ORG_VIEWS states
    case GET_SUPERSET_ORG_VIEWS_PENDING:
      return { ...state, is_fetching_superset_org_views: payload };
    case GET_SUPERSET_ORG_VIEWS_SUCCESS:
      return {
        ...state,
        org_id: payload?.org_id,
        superset_org_views: (payload?.views as ISupersetOrgView[]) || [],
        superset_org_views_guest_token: {
          token: payload?.token,
          last_fetched_in_ms: Date.now(),
        } as ISupersetOrgViewsGuestToken,
      };
    case GET_SUPERSET_ORG_VIEWS_ERROR:
      return { ...state, error_fetching_superset_org_views: error };

    // GET_SUPERSET_VIEW_GUEST_TOKEN states
    case GET_SUPERSET_VIEW_TOKEN_PENDING:
      return { ...state, is_fetching_superset_org_views: payload };
    case GET_SUPERSET_VIEW_TOKEN_SUCCESS:
      return {
        ...state,
        superset_org_views_guest_token: {
          token: payload?.token,
          last_fetched_in_ms: Date.now(),
        } as ISupersetOrgViewsGuestToken,
      };
    case GET_SUPERSET_VIEW_TOKEN_ERROR:
      return { ...state, error_fetching_superset_view_token: error };

    default:
      return state;
  }
};

export default supersetReducer;
