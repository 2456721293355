export const ADD_PO_ITEM = 'ADD_PO_ITEM';
export const GET_PO_ITEM = 'GET_PO_ITEM';
export const GET_PO_ITEMS = 'GET_PO_ITEMS';
export const UPDATE_PO_ITEM = 'UPDATE_PO_ITEM';
export const DELETE_PO_ITEM = 'DELETE_PO_ITEM';
export const PO_API_SUCCESS = 'PO_API_SUCCES';
export const PO_API_ERROR = 'PO_API_ERROR';
export const PO_API_PENDING = 'PO_API_PENDING';
export const PO_ITEM_SELECTED = 'PO_ITEM_SELECTED';
export const PO_FORM_SHOW = 'PO_FORM_SHOW';
export const FORM_MODE = 'FORM_MODE';
export const GET_PO_SUMMARY = 'GET_PO_SUMMARY';
export const CLEAR_PO_DATA = 'CLEAR_PO_DATA';
export const CLEAR_SELECTED_PO = 'CLEAR_SELECTED_PO';
export const SET_ORG_PURCHASE_ORDERS = 'SET_ORG_PURCHASE_ORDERS';
export const SET_PURCHASE_ORDERS_BY_ORG_SUMMARY =
  'SET_PURCHASE_ORDERS_BY_ORG_SUMMARY';
export const SET_GST_TYPE = 'SET_GST_TYPE';
