import useModuleAccess from './useModuleAccess.hook';

const useActionAllowed = (
  resource_id: string,
  action: 'edit' | 'view' | 'create' | 'delete'
) => {
  const resourcePermissions = useModuleAccess(resource_id);
  const permitted =
    resourcePermissions.view_access && resourcePermissions.permissions[action];

  return permitted;
};

export default useActionAllowed;
