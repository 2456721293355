import { Dispatch } from 'redux';
import { deleteRequest, getRequest } from '../../../../apiClient';
import { COMMENT_TYPES } from '../../../../constant/comment_constants';

import {
  TASK_SEGMENT,
  THREAD_SEGMENT,
} from '../../../../constant/segment_constant';
import {
  toastErrorMessage,
  toastLoadingMessage,
  toastSuccessMessage,
} from '../../../../utils';
import apiV1 from '../../../../utils/ApiClient.util';
import { parseString } from '../../../../utils/Common.util';
import { CommentProps } from '../../../../interfaces/Base';
import { COMMENT_APIS } from '../../../../routes.constant';
import { eventDispatch } from '../../../base';
import { SYNC_TASK_PROFILE } from '../refresh/Refresh.type';
import { track } from '../Segment.action';
import { getTaskComments } from '../../task/task';
import { getThread } from '../../task/thread';
import { SET_FILE_COMMENTS } from '../../drive/Drive.type';
import { customStringify } from '../../../../utils/Url.util';

export function getComments(itemId) {
  const apiUrl = parseString(
    COMMENT_APIS.getComments,
    customStringify({ item_id: itemId })
  );
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(SET_FILE_COMMENTS, data));
    } catch (err) {
      toastErrorMessage('Error in getting task comments');
    }
  };
}

export function addComment(reqBody: CommentProps, pageSource: string) {
  const apiUrl = COMMENT_APIS.addComment;
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage('Adding Comment...');
    try {
      await apiV1.post(apiUrl, reqBody);
      toastSuccessMessage('Comment added successfully', toastId);
      switch (reqBody.type) {
        case COMMENT_TYPES.ISSUE:
          dispatch(
            track(THREAD_SEGMENT.COMMENT_CREATE_SUCCESS, {
              page_source: pageSource,
            })
          );
          dispatch(getThread(reqBody.item_id, true));
          break;
        case COMMENT_TYPES.TASK:
          dispatch(
            track(TASK_SEGMENT.TASK_COMMENT_CREATE_SUCCESS, {
              page_source: pageSource,
            })
          );
          dispatch(getTaskComments(reqBody.item_id));
          break;
        case COMMENT_TYPES.DRIVE:
          dispatch(getComments(reqBody.item_id));
          break;
      }
    } catch (error) {
      switch (reqBody.type) {
        case COMMENT_TYPES.ISSUE:
          dispatch(
            track(THREAD_SEGMENT.COMMENT_CREATE_ERROR, {
              page_source: pageSource,
            })
          );
          break;
        case COMMENT_TYPES.TASK:
          dispatch(
            track(TASK_SEGMENT.TASK_COMMENT_CREATE_ERROR, {
              page_source: pageSource,
            })
          );
          break;
      }

      toastErrorMessage('Error in adding Comment', toastId);
    }
  };
}

export function updateComment(reqBody: CommentProps) {
  const apiUrl = parseString(COMMENT_APIS.updateComment, reqBody._id);
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage('Updating Comment...');
    try {
      await apiV1.put(apiUrl, reqBody);
      toastSuccessMessage('Successfully updated comment', toastId);
      switch (reqBody.type) {
        case COMMENT_TYPES.ISSUE:
          dispatch(track(THREAD_SEGMENT.COMMENT_EDIT_SUCCESS));
          dispatch(getThread(reqBody.item_id, true));
          break;
        case COMMENT_TYPES.TASK:
          dispatch(getTaskComments(reqBody.item_id));
          break;
        case COMMENT_TYPES.DRIVE:
          dispatch(getComments(reqBody.item_id));
          break;
      }
    } catch (error) {
      if (reqBody.type === COMMENT_TYPES.ISSUE) {
        dispatch(track(THREAD_SEGMENT.COMMENT_EDIT_ERROR));
      }
      toastErrorMessage('Error in updating comment', toastId);
    }
  };
}

export function deleteComment(
  commentId: string,
  type: (typeof COMMENT_TYPES)[keyof typeof COMMENT_TYPES],
  itemId?: string
) {
  const apiUrl = parseString(COMMENT_APIS.deleteComment, commentId);
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage('Deleting Comment...');
    try {
      await deleteRequest(apiUrl);
      toastSuccessMessage('Successfully updated comment', toastId);
      switch (type) {
        case COMMENT_TYPES.ISSUE:
          dispatch(track(THREAD_SEGMENT.COMMENT_EDIT_SUCCESS));
          if (itemId) dispatch(getThread(itemId, true));
          break;
        case COMMENT_TYPES.TASK:
          dispatch(eventDispatch(SYNC_TASK_PROFILE, true));
          break;
        case COMMENT_TYPES.DRIVE:
          dispatch(getComments(itemId));
          break;
      }
    } catch (error) {
      if (type === COMMENT_TYPES.ISSUE) {
        dispatch(track(THREAD_SEGMENT.COMMENT_EDIT_ERROR));
      }

      toastErrorMessage('Error in updating comment', toastId);
    }
  };
}
