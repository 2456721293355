export const GET_ALL_TC = 'GET_ALL_TC';
export const GET_TC = 'GET_TC';
export const CREATE_TC = 'CREATE_TC';
export const UPDATE_TC = 'UPDATE_TC';
export const DELETE_TC = 'DELETE_TC';
export const TC_API_ERROR = 'TC_API_ERROR';
export const GET_TC_PENDING = 'GET_TC_PENDING';
export const GET_TCS_PENDING = 'GET_TCS_PENDING';
export const CREATE_TC_PENDING = 'CREATE_TC_PENDING';
export const UPDATE_TC_PENDING = 'UPDATE_TC_PENDING';
export const DELETE_TC_PENDING = 'DELETE_TC_PENDING';
