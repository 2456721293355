/* eslint-disable no-undef */
export const USE_SEGMENT = process.env.REACT_APP_USE_SEGMENT === 'true';
export const SEGMENT_TOKEN =
  process.env.REACT_APP_SEGMENT_TOKEN || 'IGot5YtqiD7DATDtN6dgrlFPiRQDVW65';
export const ONBOARDING_SEGMENT = {
  ONBOARDING_SIGNUP_SUCCESS: 'ONBOARDING_SIGNUP_SUCCESS',
  ONBOARDING_LOGIN_SUCCESS: 'ONBOARDING_LOGIN_SUCCESS',
  ONBOARDING_PROJECT_SELECTED: 'onboarding_project_selected',
  ONBOARDING_PROJECT_CREATED: 'onboarding_project_created',
  ONBOARDING_UPLOAD_PLAN_CLICK: 'onboarding_upload_plan_click',
  ONBOARDING_MATERIAL_LIST_ADDED: 'onboarding_material_list_added',
  ONBOARDING_COMPLETE: 'onboarding_complete',
  ONBOARDING_RESEND_INVITE_CLICK: 'onboarding_resend_invite_click',
  ONBOARDING_ADD_ANOTHER_MEMBER_CLICK: 'onboarding_add_another_member_click',
  ONBOARDING_INVITE_MEMBERS_CLICK: 'onboarding_invite_members_click',
  ONBOARDING_GET_WHATSAPP_INVITE_CLICK: 'onboarding_get_whatsapp_invite_link',
  ONBOARDING_PLAN_CREATED: 'onboarding_plan_created',
  ONBOARDING_PLAN_UPLOAD_SUCCESS: 'onboarding_plan_upload_success',
  ONBOARDING_MODULE_SELECTED: 'onboarding_module_selected',
  ONBOARDING_ICP_SUCCESS: 'onboarding_icp_success',
  ONBOARDING_TRAINING_DONE_SUCCESS: 'onboarding_training_done_success',
  ONBOARDING_VIEW_TUTORIAL_CLICK: 'onboarding_view_tutorial_click',
  ONBOARDING_ADD_ACTIONS_CLICK: 'onboarding_add_actions_click',
  ONBOARDING_VALUES_SELECTED: 'onboarding_values_selected',
  ONBOARDING_INFO_RECEIVED: 'onboarding_info_received',
  ONBOARDING_INVITATION_INFO_RECEIVED: 'onbaording_invitation_info_received',
  ONBOARDING_CURRENT_PROCESS_ANSWERED: 'onboarding_current_process_answered',
  ONBOARDING_CURRENT_PROCESS_MEMBER_ASSIGNED:
    'onboarding_current_process_member_assigned',
  ONBOARDING_CREATE_MATERIAL_CLICKED: 'onboarding_create_material_clicked',
  ONBOARDING_DATE_TIME_SELECTED: 'onboarding_date_time_selected',
} as const;
export const USER_SEGMENT = {
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
} as const;
export const NAVIGATION_SEGMENT = {
  MODULE_ACCESSED: 'MODULE_ACCESSED',
} as const;
export const PROJECT_SEGMENT = {
  PROJECT_CREATE_SUCCESS: 'PROJECT_CREATE_SUCCESS',
  PROJECT_CREATE_ERROR: 'PROJECT_CREATE_ERROR',
  PROJECT_CLICKED: 'PROJECT_CLICKED',
  PROJECT_DELETE_SUCCESS: 'PROJECT_DELETE_SUCCESS',
  PROJECT_DELETE_ERROR: 'PROJECT_DELETE_ERROR',
  PROJECT_INVITE_SUCCESS: 'PROJECT_INVITE_SUCCESS',
  PROJECT_INVITE_ERROR: 'PROJECT_INVITE_ERROR',
} as const;
export const CATEGORY_SEGMENT = {
  CATEGORY_CREATE_SUCCESS: 'CATEGORY_CREATE_SUCCESS',
  CATEGORY_CREATE_ERROR: 'CATEGORY_CREATE_ERROR',
} as const;
export const TASK_SEGMENT = {
  TASK_CREATE_SUCCESS: 'task_create_success',
  TASK_CREATE_ERROR: 'task_create_failure',
  TASK_EDIT_SUCCESS: 'edit_task_popup_edit_details_success',
  TASK_EDIT_ERROR: 'edit_task_popup_edit_details_failure',
  TASK_DELETE_SUCCESS: 'TASK_DELETE_SUCCESS',
  TASK_DELETE_ERROR: 'TASK_DELETE_ERROR',
  TASK_COMMENT_CREATE_SUCCESS: 'comment_post_success',
  TASK_COMMENT_CREATE_ERROR: 'comment_post_failure',
  TYPE: 'TASK',
  TASK_GET_UPDATE_CLICK: 'task_get_update_click',
  TASK_GET_UPDATE_POPUP_LOAD: 'task_get_update_popup_load',
  TASK_GET_UPDATE_POPUP_BUTTON_CLICK: 'task_get_update_popup_button_click',
  TASK_NOTIFY_ME_CLICK: 'task_notify_me_click',
  TASK_NOTIFY_ME_POPUP_LOAD: 'task_notify_me_popup_load',
  TASK_NOTIFY_ME_POPUP_BUTTON_CLICK: 'task_notify_me_popup_button_click',
} as const;
export const TASK_LOG_SEGMENT = {
  TASK_LOG_CREATE_SUCCESS: 'add_task_logs_save_success',
  TASK_LOG_CREATE_ERROR: 'add_task_logs_save_failure',
  TASK_LOG_EDIT_SUCCESS: 'TASK_LOG_EDIT_SUCCESS',
  TASK_LOG_EDIT_ERROR: 'TASK_LOG_EDIT_ERROR',
  TASK_LOG_DELETE_SUCCESS: 'delete_task_logs_popup_delete_permanently_success',
  TASK_LOG_DELETE_ERROR: 'delete_task_logs_popup_delete_permanently_failure',
  TYPE: 'TASK_LOG',
} as const;
export const BOQ_SEGMENT = {
  BOQ_CREATE_SUCCESS: 'BOQ_CREATE_SUCCESS',
  BOQ_CREATE_ERROR: 'BOQ_CREATE_ERROR',
  BOQ_EDIT_SUCCESS: 'BOQ_EDIT_SUCCESS',
  BOQ_EDIT_ERROR: 'BOQ_EDIT_ERROR',
  BOQ_DELETE_SUCCESS: 'BOQ_DELETE_SUCCESS',
  BOQ_DELETE_ERROR: 'BOQ_DELETE_ERROR',
  BULK_BOQ_DELETE_ERROR: 'BULK_BOQ_DELETE_ERROR',
  BULK_BOQ_DELETE_SUCCESS: 'BULK_ BOQ_DELETE_SUCCESS',
} as const;
export const INDENT_SEGMENT = {
  INDENT_CREATE_SUCCESS: 'INDENT_CREATE_SUCCESS',
  INDENT_CREATE_ERROR: 'INDENT_CREATE_ERROR',
  IDENT_SUCCESS: 'IDENT_SUCCESS',
  IDENT_ERROR: 'IDENT_ERROR',
  INDENT_MODULE_ACCESSED: 'INDENT_MODULE_ACCESSED',
  INDENT_SEARCH: 'INDENT_SEARCH',
} as const;
export const ORG_SEGMENT = {
  ORG_INVITE_SUCCESS: 'ORG_INVITE_SUCCESS',
  ORG_INVITE_ERROR: 'ORG_INVITE_ERROR',
} as const;
export const THREAD_SEGMENT = {
  THREAD_CREATE_SUCCESS: 'new_issue_create_success',
  THREAD_CREATE_ERROR: 'new_issue_create_failure',
  THREAD_EDIT_SUCCESS: 'THREAD_EDIT_SUCCESS',
  THREAD_EDIT_ERROR: 'THREAD_EDIT_ERROR',
  THREAD_DELETE_SUCCESS: 'THREAD_DELETE_SUCCESS',
  THREAD_DELETE_ERROR: 'THREAD_DELETE_ERROR',
  THREAD_CLOSE_SUCCESS: 'THREAD_CLOSE_SUCCESS',
  THREAD_CLOSE_ERROR: 'THREAD_CLOSE_ERROR',
  COMMENT_CREATE_SUCCESS: 'COMMENT_CREATE_SUCCESS',
  COMMENT_CREATE_ERROR: 'COMMENT_CREATE_ERROR',
  COMMENT_EDIT_SUCCESS: 'COMMENT_EDIT_SUCCESS',
  COMMENT_EDIT_ERROR: 'COMMENT_EDIT_ERROR',
  COMMENT_DELETE_SUCCESS: 'COMMENT_DELETE_SUCCESS',
  COMMENT_DELETE_ERROR: 'COMMENT_DELETE_ERROR',
  UPLOAD_FILES_SUCCESS: 'UPLOAD_FILES_SUCCESS',
  UPLOAD_FILES_ERROR: 'UPLOAD_FILES_ERROR',
} as const;
export const MANAGE_TASK_SEGMENT = {
  MANAGE_TASK_ADD_SUCCESS: 'MANAGE_TASK_ADD_SUCCESS',
  MANAGE_TASK_ADD_ERROR: 'MANAGE_TASK_ADD_ERROR',
  MANAGE_TASK_ASSIGN_USER_SUCCESS: 'MANAGE_TASK_ASSIGN_USER_SUCCESS',
  MANAGE_TASK_ASSIGN_USER_ERROR: 'MANAGE_TASK_ASSIGN_USER_ERROR',
  MANAGE_TASK_LINK_BOQ_SUCCESS: 'MANAGE_TASK_LINK_BOQ_SUCCESS',
  MANAGE_TASK_LINK_BOQ_ERROR: 'MANAGE_TASK_LINK_BOQ_ERROR',
  MANAGE_TASK_UPDATE_SUCCESS: 'edit_inline_successful',
  MANAGE_TASK_UPDATE_ERROR: 'eidt_inline_failure',
  BULK_UPDATE: 'web_bulk_update_tasks',
  MANAGE_TASK_BULK_DELETE_SUCCESS: 'bulk_delete_success',
  MANAGE_TASK_BULK_DELETE_ERROR: 'bulk_delete_failure',
  HOVER_TASK_DELETE_SUCCESS: 'hover_delete_task_success',
  HOVER_TASK_DELETE_ERROR: 'hover_delete_task_failure',
  MANAGE_TASK_BULK_ASSIGN_USER_SUCCESS: 'MANAGE_TASK_BULK_ASSIGN_USER_SUCCESS',
  MANAGE_TASK_BULK_ASSIGN_USER_ERROR: 'MANAGE_TASK_BULK_ASSIGN_USER_ERROR',
  MANAGE_TASK_BULK_LINK_BOQ_SUCCESS: 'MANAGE_TASK_BULK_LINK_BOQ_SUCCESS',
  MANAGE_TASK_BULK_LINK_BOQ_ERROR: 'MANAGE_TASK_BULK_LINK_BOQ_ERROR',
  BULK_MARK_COMPLETE_SUCCESS: 'bulk_mark_complete_success',
  BULK_MARK_COMPLETE_FAILURE: 'bulk_mark_complete_failure',
  SITE_PLAN_COPY_TASK_CLICK: 'site_plan_copy_task_click',
  SITE_PLAN_PASTE_AS_SUBTASK_CLICK: 'site_plan_paste_as_subtask_click',
  SITE_PLAN_PASTE_AS_SUBTASK_SUCCESS: 'site_plan_paste_as_subtask_success',
} as const;
export const UPLOAD_DOC_SEGMENT = {
  UPLOAD_EXCEL_SUCCESS: 'UPLOAD_EXCEL_SUCCESS',
  UPLOAD_EXCEL_ERROR: 'UPLOAD_EXCEL_ERROR',
  UPLOAD_EXISTING_EXCEL_SUCCESS: 'add_existing_plan_success',
  UPLOAD_EXISTING_EXCEL_ERROR: 'add_existing_plan_failure',
  REPLACE_PLAN_SUCCESS: 'replace_plan_success',
  REPLACE_PLAN_ERROR: 'replace_plan_failure',
} as const;
export const REPORTS_SEGMENT = {
  GENERATE_REPORT_SUCCESS: 'GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_ERROR: 'GENERATE_REPORT_ERROR',
  SEND_DPR_SUCCESS: 'SEND_DPR_SUCCESS',
  SEND_DPR_ERROR: 'SEND_DPR_ERROR',
  DPR_DOWNLOAD_SUCCESS: 'download_dpr_popout_submit_success',
  DPR_DOWNLOAD_FAILURE: 'download_dpr_popout_submit_failure',
  WPR_DOWNLOAD_SUCCESS: 'download_wpr_popout_submit_download_success',
  WPR_DOWNLOAD_FAILURE: 'download_wpr_popout_submit_download_failure',
} as const;
export const BUTTON_SEGMENT = {
  CREATE: 'CREATE_CLICKED',
  DETAIL: 'PROFILE_VIEW',
  BACK: 'BACK_CLICKED',
  SELECTED: 'SELECTED',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  CANCEL: 'CANCEL',
  DOWNLOAD: 'DOWNLOAD',
} as const;

export const PURCHASE_ORDER_SEGMENT = {
  PO_CREATE_CONFIGURE_CLICK: 'create_po_configure_click',
  PO_CREATE_CONFIGURE_SUCCESS: 'create_po_configure_success',
  PO_CREATE_ADD_PHOTO_SELECT: 'create_po_add_photo_select',
  PO_CREATE_ADD_PHOTO_CLICK: 'create_po_add_photo_click',
  PO_CREATE_ADD_PHOTO_SUCCESS: 'create_po_add_photo_success',
  PO_APPROVE_SUCCESS: 'po_approve_success',
  PO_REJECT_SUCCESS: 'po_reject_success',
  PO_CREATE_SELECT_GST_CLICK: 'create_po_select_gst_click',
  PO_CREATE_SELECT_GST_SUCCESS: 'create_po_select_gst_success',
  PO_CREATE_ADD_BILLING_ADDRESS_CLICK: 'create_add_billing_address_click',
  PO_CREATE_DEFAULT_BILLING_ADDRESS_SAVE:
    'create_po_default_billing_address_save',
  PO_CREATE_SIGNATURE_TYPE_SELECT: 'create_po_signature_type_select',
  PO_CREATE_EDIT_SIGNATURE_CLICK: 'create_po_edit_signature_click',
  PO_CREATE_ADD_SIGNATURE_UPLOAD_CLICK: 'create_po_add_signature_upload_click',
  PO_CREATE_TNC_ADD: 'create_po_tnc_add',
  PURCHASE_ORDER_CREATE_SUCCESS: 'PURCHASE_ORDER_CREATE_SUCCESS',
  PURCHASE_ORDER_CREATE_ERROR: 'PURCHASE_ORDER_CREATE_ERROR',
  PURCHASE_ORDER_SUCCESS: 'PURCHASE_ORDER_SUCCESS',
  PURCHASE_ORDER_ERROR: 'PURCHASE_ORDER_ERROR',
  PURCHASE_ORDER_MODULE_ACCESSED: 'PURCHASE_ORDER_MODULE_ACCESSED',
  PURCHASE_ORDER_SEARCH: 'PURCHASE_ORDER_SEARCH',
  PURCHASE_ORDER_VENDOR_SELECT: 'PURCHASE_ORDER_VENDOR_SELECT',
  PURCHASE_ORDER_QUICK_ADD_VENDOR: 'PURCHASE_ORDER_QUICK_ADD_VENDOR',
  PURCHASE_ORDER_ADD_NEW_BOM: 'PURCHASE_ORDER_ADD_NEW_BOM',
  PURCHASE_ORDER_BOM_SELECTED: 'PURCHASE_ORDER_BOM_SELECTED',
  PURCHASE_ORDER_ADDITIONAL_DISCOUNT: 'PURCHASE_ORDER_ADDITIONAL_DISCOUNT',
  PURCHASE_ORDER_BOQ_LINK_CLICKED: 'PURCHASE_ORDER_BOQ_LINK_CLICKED',
  PURCHASE_ORDER_TNC_CLICKED: 'PURCHASE_ORDER_TNC_CLICKED',
  PURCHASE_ORDER_CUSTOMER_NOTES: 'PURCHASE_ORDER_CUSTOMER_NOTES',
} as const;

export const PROJECT_STAGES_SEGMENT = {
  TASK_STAGE_SELECTED: 'TASK_STAGE_SELECTED',
  ADD_NEW_STAGE_CLICKED: 'ADD_NEW_STAGE_CLICKED',
  EDIT_STAGE_SUCCESS: 'EDIT_STAGE_SUCCESS',
  STAGE_DELETED: 'STAGE_DELETED',
  TAG_ADDED: 'TAG_ADDED',
  STAGE_FILTER_CLICKED: 'STAGE_FILTER_CLICKED',
  STAGE_FILTER_APPLIED: 'STAGE_FILTER_APPLIED',
  STAGE_FILTER_CLEARED: 'STAGE_FILTER_CLEARED',
} as const;

export const SEGMENT_TEAM_ACTIONS = {
  CHANGE_USER_TEAM: 'CHANGE_USER_TEAM',
  INVITE_TEAM: 'INVITE_USER_TO_TEAM',
};

export const NEW_ATTENDANCE_SEGMENT = {
  PROJECT_ATTENDANCE_SCREEN_LOAD: 'PROJECT_ATTENDANCE_SCREEN_LOAD',
  ATTENDANCE_ATTENDANCE_BUTTON_CLICK: 'ATTENDANCE_ATTENDANCE_BUTTON_CLICK',
  LABOUR_PROFILE_NAME_ARROW_CLICK: 'LABOUR_PROFILE_NAME_ARROW_CLICK',
  VENDOR_PROFILE_NAME_ARROW_CLICK: 'VENDOR_PROFILE_NAME_ARROW_CLICK',
  VENDOR_PROFILE_CREATE_SUCCESS: 'VENDOR_PROFILE_CREATE_SUCCESS',
  VENDOR_PROFILE_DONE_BUTTON_CILCK: 'VENDOR_PROFILE_DONE_BUTTON_CILCK',
  LABOUR_PROFILE_CREATE_SUCCESS: 'VENDOR_PROFILE_CREATE_SUCCESS',
  LABOUR_PROFILE_DONE_BUTTON_CILCK: 'LABOUR_PROFILE_DONE_BUTTON_CILCK',
  VENDOR_PROFILE_ADD_NEW_TYPE_CILCK: 'VENDOR_PROFILE_ADD_NEW_TYPE_CILCK',
  VENDOR_PROFILE_MARK_ATTENDANCE_METHOD_SELECTED:
    'VENDOR_PROFILE_MARK_ATTENDANCE_METHOD_SELECTED',
  EDIT_LABOUR_PROFILE_SUBMIT_BUTTON_CLICK:
    'EDIT_LABOUR_PROFILE_SUBMIT_BUTTON_CLICK',
  EDIT_VENDOR_PROFILE_SUBMIT_BUTTON_CLICK:
    'EDIT_VENDOR_PROFILE_SUBMIT_BUTTON_CLICK:',
  LABOUR_PROFILE_EDIT_SUCCESS: 'LABOUR_PROFILE_EDIT_SUCCESS',
  VENDOR_PROFILE_EDIT_SUCCESS: 'VENDOR_PROFILE_EDIT_SUCCESS',
  LABOUR_PROFILE_EDIT_LABOUR_PROFILE_CLICK:
    'LABOUR_PROFILE_EDIT_LABOUR_PROFILE_CLICK',
  UPLOAD_WORKER_LIST_CLICK: 'UPLOAD_WORKER_LIST_CLICK',
  UPLOAD_WORKER_LIST_EXCEL_CLICK: 'UPLOAD_WORKER_LIST_EXCEL_CLICK ',
  LABOUR_HISTORY_OPTION_SELECT: 'LABOUR_HISTORY_OPTION_SELECT',
  LABOUR_HISTORY_ADD_PAID_AMOUNT_ADDED_SUCCESS:
    'LABOUR_HISTORY_ADD_PAID_AMOUNT_ADDED_SUCCESS',
  LABOUR_HISTORY_ADD_PAID_AMOUNT_CLICK: 'LABOUR_HISTORY_ADD_PAID_AMOUNT_CLICK',
  LABOUR_HISTORY_ADD_PAID_AMOUNT_ADDED: 'LABOUR_HISTORY_ADD_PAID_AMOUNT_ADDED',
  CALCULATE_PAYABLES_ADD_WAGES_CLICK: 'CALCULATE_PAYABLES_ADD_WAGES_CLICK',
  ATTD_ADD_PAYMENT_CLICK: 'attd_add_payment_click',
  ATTD_PAYMENT_SCREEN_CLOSE: 'attd_payment_screen_close',
  ATTD_PAYMENT_SCREEN_DONE_CLICK: 'attd_payment_screen_done_click',
  ATTD_PAYMENT_PAID_SYS: 'attd_payment_paid_sys',
  NOTIF_ATTD_PAYMENT_PAID_PN: 'notif_attd_payment_paid_pn',
  NOTIF_ATTD_PAYMENT_APPROVAL_REQUEST_PN:
    'notif_attd_payment_approval_request_pn',
  ATTD_PAYMENT_APPROVED_SYS: 'attd_payment_approved_sys',
  NOTIF_ATTD_PAYMENT_APPROVED_PN: 'notif_attd_payment_approved_pn',
  ATTD_PAYMENT_REJECTED_SYS: 'attd_payment_rejected_sys',
  NOTIF_ATTD_PAYMENT_REJECTED_PN: 'notif_attd_payment_rejected_pn',
  ATTD_PAYMENT_HISTORY_ITEM_CLICK: 'attd_payment_history_item_click',
  ATTD_PAYMENT_HISTORY_ITEM_LOAD: 'attd_payment_history_item_load',
  ATTD_PAYMENT_ATTACHMENT_DELETE_CLICK: 'attd_payment_attachment_delete_click',
  ATTD_PAYMENT_TRANSACTION_DELETE_CLICK:
    'attd_payment_transaction_delete_click',
  ATTD_PAYMENT_TRANSACTION_DELETE_POPUP_LOAD:
    'attd_payment_transaction_delete_popup_load',
  ATTD_PAYMENT_TRANSACTION_DELETE_OPTION_SELECT:
    'attd_payment_transaction_delete_option_select',
  ATTD_PAYMENT_TRANSACTION_DELETE_SYS: 'attd_payment_transaction_delete_sys',
  NOTIF_ATTD_PAYMENT_TRANSACTION_DELETE_PN:
    'notif_attd_payment_transaction_delete_pn',
  NEW_ATTD_PAYMENT_ATTACHMENT_LOAD: 'new_attd_payment_attachment_load',
  NEW_ATTD_PAYMENT_ATTACHMENT_SAVE_CLICK:
    'new_attd_payment_attachment_save_click',
  NEW_ATTD_PAYMENT_ATTACHMENT_SAVE_POPUP_LOAD:
    'new_attd_payment_attachment_save_popup_load',
  NEW_ATTD_PAYMENT_ATTACHMENT_SAVE_POPUP_OPTION_CLICK:
    'new_attd_payment_attachment_save_popup_option_click',
};

export const PROJECT_PROFILE_SETTINGS = {
  PROJECT_PROFILE_TASK_SETTING_CLICK: 'PROJECT_PROFILE_TASK_SETTING_CLICK',
  TASK_SETTING_LOAD: 'TASK_SETTING_LOAD',
  TASK_SETTING_PHONE_GALLERY_PERMISSION_TOOGLE:
    'TASK_SETTING_PHONE_GALLERY_PERMISSION_TOOGLE',
};

export const SEGMENTS_USER_PERMISSION = {
  USER_PERMISSION_NEW_ROLE_CLICK: 'USER_PERMISSION_NEW_ROLE_CLICK',
  USER_PERMISSION_NEW_ROLE_ADD_ROLE_CLICK:
    'USER_PERMISSION_NEW_ROLE_ADD_ROLE_CLICK',
  USER_PERMISSION_NEW_ROLE_SUCCESS: 'USER_PERMISSION_NEW_ROLE_SUCCESS',
  USER_PERMISSION_ROLE_LOAD_SUCCESS: 'USER_PERMISSION_ROLE_LOAD_SUCCESS',
  USER_PERMISSION_ROLE_CLICK: 'USER_PERMISSION_ROLE_CLICK',
  USER_PERMISSION_ROLE_PERMISSION_LOAD: 'USER_PERMISSION_ROLE_PERMISSION_LOAD',
  USER_PERMISSION_ROLE_EDIT_CLICK: 'USER_PERMISSION_ROLE_EDIT_CLICK',
  USER_PERMISSION_ROLE_EDIT_SAVE_CLICK: 'USER_PERMISSION_ROLE_EDIT_SAVE_CLICK',
  USER_PERMISSION_ROLE_BACK_ARROW_CLICK:
    'USER_PERMISSION_ROLE_BACK_ARROW_CLICK',
  USER_PERMISSION_ROLE_PERMISSION_ASSIGN_MEMBER_CLICK:
    'USER_PERMISSION_ROLE_PERMISSION_ASSIGN_MEMBER_CLICK',
  USER_PERMISSION_ROLE_PERMISSION_ASSIGN_MEMBER_SUCCESS:
    'USER_PERMISSION_ROLE_PERMISSION_ASSIGN_MEMBER_SUCCESS',
  USER_PERMISSION_ROLE_PERMISSION_CLICK:
    'USER_PERMISSION_ROLE_PERMISSION_CLICK',
  USER_PERMISIION_ROLE_PERMISSION_SCOPE_CLICK:
    'USER_PERMISIION_ROLE_PERMISSION_SCOPE_CLICK',
  USER_PERMISSION_ROLE_PERMISSION_PERMISSION_CLICK:
    'USER_PERMISSION_ROLE_PERMISSION_PERMISSION_CLICK',
  USER_PERMISSION_ROLE_PERMISSION_TOGGLE_CLICK:
    'USER_PERMISSION_ROLE_PERMISSION_TOGGLE_CLICK',
  USER_PERMISSION_ROLE_PERMISSION_TAB_CLICK:
    'USER_PERMISSION_ROLE_PERMISSION_TAB_CLICK',
  USER_PERMISSION_ASSIGNED_REMOVE_CLICK:
    'USER_PERMISSION_ASSIGNED_REMOVE_CLICK',
  USER_PERMISSION_ASSIGNED_USER_REMOVE_POPUP:
    'USER_PERMISSION_ASSIGNED_USER_REMOVE_POPUP',
  USER_PERMISSION_ASSIGNED_USER_REMOVE_SUCCESS:
    'USER_PERMISSION_ASSIGNED_USER_REMOVE_SUCCESS',
  USER_PERMISSION_ASSIGNED_USER_LOAD_SUCCESS:
    'USER_PERMISSION_ASSIGNED_USER_LOAD_SUCCESS',
  ACCESS_DENIED_POPUP: 'ACCESS_DENIED_POPUP',
  ACCESS_DENIED_SCREEN: 'ACCESS_DENIED_SCREEN',
  ACCESS_DENIED_RAISE_REQUEST_CLICK: 'ACCESS_DENIED_RAISE_REQUEST_CLICK',
};

export const SEGMENTS_AUTO_DPR = {
  AUTO_DPR_EXPERIMENT_NAME: 'AUTO DPR V2',
  AUTO_DPR_PROJECT_SETTING_CLICK: 'AUTO_DPR_PROJECT_SETTING_CLICK',
  AUTO_DPR_LOAD_SUCCESS: 'AUTO_DPR_LOAD_SUCCESS',
  AUTO_DPR_START_NOW_CLICK: 'AUTO_DPR_START_NOW_CLICK',
  AUTO_DPR_TOGGLE_CLICK: 'AUTO_DPR_TOGGLE_CLICK',
  AUTO_DPR_BACK_ARROW_CLICK: 'AUTO_DPR_BACK_ARROW_CLICK',
  AUTO_DPR_CTA_CLICK: 'AUTO_DPR_CTA_CLICK',
  AUTO_DPR_PROJECT_CHANGE_CLICK: 'AUTO_DPR_PROJECT_CHANGE_CLICK',
  AUTO_DPR_PROJECT_SAVE_CLICK: 'AUTO_DPR_PROJECT_SAVE_CLICK',
  AUTO_DPR_PROJECTS_REMOVE_ALL_CROSS_CLICK:
    'AUTO_DPR_PROJECTS_REMOVE_ALL_CROSS_CLICK',
  AUTO_DPR_ON_HOLD_PROJECT_CHANGE_STATUS_CLICK:
    'AUTO_DPR_ON_HOLD_PROJECT_CHANGE_STATUS_CLICK',
  AUTO_DPR_PROJECT_STATUS_CHANGE: 'AUTO_DPR_PROJECT_STATUS_CHANGE',
  AUTO_DPR_PROJECT_NEXT_CLICK: 'AUTO_DPR_PROJECT_NEXT_CLICK',
  AUTO_DPR_MEMBER_LOAD_SUCCESS: 'AUTO_DPR_MEMBER_LOAD_SUCCESS',
  AUTO_DPR_OWNER_CHANGE_CLICK: 'AUTO_DPR_OWNER_CHANGE_CLICK',
  AUTO_DPR_OWNER_SAVE_CLICK: 'AUTO_DPR_OWNER_SAVE_CLICK',
  AUTO_DPR_OWNER_CHANGE_SUCCESS: 'AUTO_DPR_OWNER_CHANGE_CLICK',
  AUTO_DPR_STM_CHANGE_CLICK: 'AUTO_DPR_STM_CHANGE_CLICK',
  AUTO_DPR_STM_SAVE_CLICK: 'AUTO_DPR_STM_SAVE_CLICK',
  AUTO_DPR_STM_REMOVE_ALL_CROSS_CLICK: 'AUTO_DPR_STM_REMOVE_ALL_CROSS_CLICK',
  AUTO_DPR_OTM_CHANGE_CLICK: 'AUTO_DPR_OTM_CHANGE_CLICK',
  AUTO_DPR_OTM_SAVE_CLICK: 'AUTO_DPR_OTM_SAVE_CLICK',
  AUTO_DPR_OTM_REMOVE_ALL_CROSS_CLICK: 'AUTO_DPR_OTM_REMOVE_ALL_CROSS_CLICK',
  AUTO_DPR_STM_TOGGLE_CLICK: 'AUTO_DPR_STM_TOGGLE_CLICK',
  AUTO_DPR_MEMBER_NEXT_CLICK: 'AUTO_DPR_MEMBER_NEXT_CLICK',
  AUTO_DPR_TIME_LOAD_SUCCESS: 'AUTO_DPR_TIME_LOAD_SUCCESS',
  AUTO_DPR_DAYS_CHANGE_CLICK: 'AUTO_DPR_DAYS_CHANGE_CLICK',
  AUTO_DPR_DAYS_SAVE_CLICK: 'AUTO_DPR_DAYS_SAVE_CLICK',
  AUTO_DPR_DAYS_REMOVE_ALL_CLICK: 'AUTO_DPR_DAYS_REMOVE_ALL_CLICK',
  AUTO_DPR_STR_CHANGE_CLICK: 'AUTO_DPR_STR_CHANGE_CLICK',
  AUTO_DPR_STR_SAVE_CLICK: 'AUTO_DPR_STR_SAVE_CLICK',
  AUTO_DPR_OTR_CHANGE_CLICK: 'AUTO_DPR_OTR_CHANGE_CLICK',
  AUTO_DPR_OTR_SAVE_CLICK: 'AUTO_DPR_OTR_SAVE_CLICK',
  AUTO_DPR_TIME_DONE_CLICK: 'AUTO_DPR_DONE_CLICK',
  AUTO_DPR_SAVE_SUCCESS: 'AUTO_DPR_SAVE_SUCCESS',
};

export const BROAD_PLAN_SEGMENT = {
  CREATE_PROJECT_PLAN_STRUCTURE_SELECT: 'create_project_plan_structure_select',
  CREATE_PROJECT_CREATE_BUTTON_CLICK: 'create_project_create_button_click',
  ADD_PROJECT_DATE_CLICK: 'add_project_date_click',
  TASK_PLAN_TAB_SELECT: 'task_plan_tab_select',
  ADD_NEW_TASK_PLAN_CLICK: 'add_new_task_plan_click',
  TASK_PLAN_CREATION_SCREEN_LOAD: 'task_plan_creation_screen_load',
  MAIN_TASK_CREATE_TASK_CLICK: 'main_task_create_task_click',
  TASK_PLAN_TASK_CARD_CLICK: 'task_plan_task_card_click',
  TASK_PLAN_OPTIONS_CLICK: 'task_plan_options_click',
  TASK_PLAN_STATUS_ARROW_CLICK: 'task_plan_status_arrow_click',
  TASK_PLAN_ADD_PLANNED_END_CLICK: 'task_plan_add_planned_end_click',
  TASK_PLAN_EXPECTED_DATE_SELECT: 'task_plan_expected_date_select',
  TASK_PLAN_ADD_PLANNED_START_DATE_CLICK:
    'task_plan_add_planned_start_date_click',
  TASK_PLAN_ADD_ACTUAL_END_DATE_CLICK: 'task_plan_add_actual_end_date_click',
  TASK_PLAN_ADD_TASK_PROGRESS_CLICK: 'task_plan_add_task_progress_click',
  TASK_PROFILE_ADD_ASSIGNEE_CLICK: 'task_profile_add_assignee_click',
  TASK_PLAN_MAPPED_WEEKLY_TASK_DONE: 'task_plan_mapped_weekly_task_done',
  TASK_PLAN_SEE_ALL_CLICK: 'task_plan_see_all_click',
  TASK_PLAN_EDIT_TASK_CLICK: 'task_plan_edit_task_click',
  TASK_PLAN_TASK_ISSUE_ARROW_CLICK: 'task_plan_task_issue_arrow_click',
  SITE_UPDATES_BUTTON_CLICK: 'site_updates_button_click',
  SITE_UPDATE_FILTER_CLICK: 'site_update_filter_click',
  SITE_UPDATE_FILTER_OPTIONS_CHOOSE: 'site_update_filter_options_choose',
  TASK_PLAN_DELETE_TASK_CLICK: 'task_plan_delete_task_click',
  TASK_PLAN_DELETE_TASK_POPUP_CLICK: 'task_plan_delete_task_popup_click',
  WEEKLY_TASK_CREATE_PLAN_CLICK: 'weekly_task_create_plan_click',
  WEEKLY_PLAN_DATE_SELECT: 'weekly_plan_date_select',
  WEEKLY_PLAN_SELECT_WEEK_BEGIN_CLICK: 'weekly_plan_select_week_begin_click',
  TASK_PLAN_EDIT_TASK_EDIT_CLICK: 'task_plan_edit_task_edit_click',
  WEEKLY_TASK_GO_TO_CURRENT_WEEK_CLICK: 'weekly_task_go_to_current_week_click',
  ISSUE_CLOSE_ISSUE_CLOSE_CLICK: 'issue_close_issue_close_click',
  TASK_PLAN_ISSUE_CLOSE_CLICK: 'task_plan_issue_close_click',
  TASK_PLAN_ISSUE_DELETE_ICON_CLICK: 'task_plan_issue_delete_icon_click',
  TASK_PLAN_ISSUE_DELETE_ACTION_CLICK: 'task_plan_issue_delete_action_click',
  ISSUE_EDIT_DETAILS_EDIT_CLICK: 'issue_edit_details_edit_click',
  FILTER_ARROW_CLICK: 'filter_arrow_click',
  FILTER_ACTION_TASK_PLAN_APPLY: 'filter_action_task_plan_apply',
  WEEKLY_TASK_SELECT_TASK_BUTTON_CLICK: 'weekly_task_select_task_button_click',
  TASK_PLAN_MARK_AS_COMPLETE_CLICK: 'task_plan_mark_as_complete_click',
  TASK_PLAN_MARK_AS_COMPLETE_ACTION_CLICK:
    'task_plan_mark_as_complete_action_click',
  MAIN_TASK_UPLOAD_PLAN_CLICK: 'main_task_upload_plan_click',
  WEEKLY_TASK_DATE_ARROW_CLICK: 'weekly_task_date_arrow_click',
  TASK_PLAN_DOWNLOAD_REPORT_CLICK: 'task_plan_download_report_click',
  GENERATE_REPORT_SUCCESS: 'generate_report_success',
  TASK_PLAN_SCREEN_LOAD: 'task_plan_screen_load',
  TASK_PLAN_DOWNLOAD_REPORT_ICON_CLICK: 'task_plan_download_report_icon_click',
};

export const SC_PAYABALS_SEGMENT = {
  SC_PAYABLE_ICON_CLICK: 'sc_payable_icon_click',
  VENDOR_SCREEN_LOAD: 'vendor_screen_load',
  VENDOR_CREATE_CLICK: 'vendor_create_click',
  CREATE_VENDOR_SCREEN_LOAD: 'create_vendor_screen_load',
  VENDOR_NAME_TYPE: 'vendor_name_type',
  VENDOR_MOBILE_NUMBER_TYPE: 'vendor_mobile_number_type',
  VENDOR_ADD_ANOTHER_GST_CLICK: 'vendor_add_another_gst_click',
  VENDOR_CREATE_VENDOR_CLICK: 'vendor_create_vendor_click',
  VENDOR_ARROW_BUTTON_CLICK: 'vendor_arrow_button_click',
  VENDOR_PROFILE_LOAD: 'vendor_profile_load',
  VENDOR_PROFILE_OPTION_SELECT: 'vendor_profile_option_select',
  EDIT_VENDOR_ORG_LEVEL_DATAILS_CLICK: 'edit_vendor_org_level_datails_click',
  EDIT_VENDOR_DETAILS_LOAD: 'edit_vendor_details_load',
  EDIT_VENDOR_UPATE_VENDOR_CLICK: 'edit_vendor_upate_vendor_click',
  VENDOR_PROJECT_ARROW_CLICK: 'vendor_project_arrow_click',
  VENDOR_ADD_WORK_ORDER_CLICK: 'vendor_add_work_order_click',
  WORK_DONE_VENDOR_PROJECT_POPUP: 'work_done_vendor_project_popup',
  WORK_DONE_PROJECT_SELECT: 'work_done_project_select',
  WORK_ORDER_SELECT_EXISTING_PLAN_CLICK:
    'work_order_select_existing_plan_click',
  WORK_ORDER_TASK_SELECT: 'work_order_task_select',
  WORK_ORDER_TASK_UNSELECT: 'work_order_task_unselect',
  WORK_ORDER_NEXT_BUTTON_CLICK: 'work_order_next_button_click',
  CREATE_WORK_ORDER_CLICK: 'create_work_order_click',
  WORK_ORDER_TASK_CREATE: 'work_order_task_create',
  VENDOR_WORK_ORDER_CREATE: 'vendor_work_order_create',
  VENDOR_WORK_ORDER_EDIT: 'vendor_work_order_edit',
  VENDOR_WORK_ORDER_CANCEL_CLICK: 'vendor_work_order_cancel_click',
  VENDOR_WORK_ORDER_OPTION_SELECT: 'vendor_work_order_option_select',
  VENDOR_WORK_ORDER_CARD_CLICK: 'vendor_work_order_card_click',
  VENDOR_WORK_ORDER_CARD_LOAD: 'vendor_work_order_card_load',
  VENDOR_WORK_ORDER_SCREEN_SCROLL: 'vendor_work_order_screen_scroll',
  VENDOR_WORK_ORDER_TASK_EDIT: 'vendor_work_order_task_edit',
  VENDOR_WORK_ORDER_TASK_EDIT_SAVE: 'vendor_work_order_task_edit_save',
  VENDOR_WORK_ORDER_ADD_NEW_FIELD_CLICK:
    'vendor_work_order_add_new_field_click',
  MODE_ADD_NEW_FIELD_CLICK: 'mode_add_new_field_click',
  WORK_ORDER_MODE_EDIT: 'work_order_mode_edit',
  WORK_ORDER_REMOVE_ICON_CLICK: 'work_order_remove_icon_click',
  WORK_ORDER_REMOVE_OPTION_SELECT: 'work_order_remove_option_select',
  VENDOR_PROFILE_PROJECT_CARD_CLICK: 'vendor_profile_project_card_click',
  CREATE_NEW_VENDOR_CLICK: 'create_new_vendor_click',
  UPDATE_VENDOR_CLICK: 'update_vendor_click',
  ADD_NEW_PAYMENT_BTN_CLICK: 'add_new_payment_btn_click',
  ADD_NEW_PAYMENT_SAVE_BTN_CLICK: 'add_new_payment_save_btn_click',
  SC_ADD_TC_CLICK: 'sc_add_tc_click',
  SC_TC_SIDE_SCREEN_LOAD: 'sc_tc_side_screen_load',
  SC_TC_SIDE_SCREEN_OPTION_CLICK: 'sc_tc_side_screen_option_click',
  SC_TC_SIDE_SCREEN_DELETE_POPUP: 'sc_tc_side_screen_delete_popup',
  SC_TC_SIDE_SCREEN_DELETE_OPTION_CLICK:
    'sc_tc_side_screen_delete_option_click',
  SC_TC_DONE_CLICK: 'sc_tc_done_click',
  SC_ADD_REMARKS_CLICK: 'sc_add_remarks_click',
  SC_ADD_REMARKS_DONE_CLICK: 'sc_add_remarks_done_click',
  SC_WO_DOWNLOAD_PDF_CLICK: 'sc_wo_download_pdf_click',
  SC_WO_DOWNLOAD_PDF_SYS: 'sc_wo_download_pdf_sys',
  SC_WO_SHARE_CLICK: 'sc_wo_share_click',
  SC_WO_SHARE_POPUP: 'sc_wo_share_popup',
  SC_WO_SHARE_SYS: 'sc_wo_share_sys',
  SC_UPGRADE_POPUP_LOAD: 'sc_upgrade_popup_load',
  SC_UPGRADE_REQUEST_CLICK: 'sc_upgrade_request_click',
  ADD_TASK_LOGS_SAVE_SUCCESS: 'add_task_logs_save_success',
  TASK_LOG_SAVE_UPDATE_CLICK: 'task_log_save_update_click',
};

export const ZOHO_SEGMENT_CONSTANT = {
  ANALYTICS_DASHBOARD_ERROR_SCREEN: 'analytics_dashboard_error_screen',
  ANALYTICS_DASHBOARD_SCREEN_LOAD: 'analytics_dashboard_screen_load',
  ANALYTICS_DASHBOARD_TAB_CLICK: 'analytics_dashboard_tab_click',
  ANALYTICS_DASHBOARD_TAB_LOAD: 'analytics_dashboard_tab_load',
};

export const MVP_SEGMENT_CONST = {
  MVP_INVOICE_DETAILS_POPUP_SUBMIT_CLICK:
    'mvp_invoice_details_popup_submit_click',
  MVP_ADD_INVOICE_DETAILS_CLICK: 'mvp_add_invoice_details_click',
  MVP_INVOICE_DETAILS_POPUP_FILE_UPLOAD_SUCCESS:
    'mvp_invoice_details_popup_file_upload_success',
  MVP_INVOICE_DELETE: 'mvp_invoice_delete',
  MVP_VENDOR_GRN_CLICK: 'mvp_vendor_grn_click',
  MVP_PAYMENT_DETAILS_UPLOAD_SUCCESS: 'mvp_payment_details_upload_success',
  MVP_PAYMENT_DETAILS_SUBMIT_SUCCESS: 'mvp_payment_details_submit_success',
  MVP_VENDOR_CLICK: 'mvp_vendor_click',
  MVP_ADD_PAYMENT_DETAILS_CLICK: 'mvp_add_payment_details_click',
};

export const INVITE_CONSTANT = {
  ADD_MEMBER_BTN_CLICK: 'add_member_btn_click',
  INVITE_MEMBER_BTN_CLICK: 'invite_member_btn_click',
  ORG_INVITE_MEMBER_SUCCESS: 'org_invite_member_success',
  PROJECT_INVITE_MEMBER_SUCCESS: 'project_invite_member_success',
  ORG_REMOVE_MEMBER_BTN_CLICK: 'org_remove_member_btn_click',
};

export const MATERIAL_SEGMENT_CONST = {
  COMMERCIAL_MATERIAL_LIST_CLICK: 'commercial_material_list_click',
  MATERIAL_LIST_PROJECTWISE_CLICK: 'material_list_project_vise_inventory_click',
  MATERIAL_LIST_ALL_CLICK: 'material_list_all_material_click',
  MATERIAL_LIST_GROUPED_CLICK: 'material_list_grouped_materials_click',
  NEW_MATERIAL_REQUEST_CLICK: 'new_material_request_click',
  MATERIAL_LIST_OPEN: 'material_list_open',
  MATERIAL_SEARCH_SELECT_ADD_QUANTITY: 'material_search_select_add_quantity',
  NEW_MATERIAL_REQUEST_NEXT_BUTTON_CLICK:
    'new_materaial_request_next_button_click',
  NEW_MATERIAL_REQUEST_CANCEL: 'new_material_request_cancel',
  NEW_MATERIAL_MATERIAL_CLICK: 'new_material_material_click',
  NEW_MATERIAL_NEW_CATEGORY_CLICK: 'add_new_material_new_category_click',
  NEW_MATERIAL_NEW_CATEGORY_CANCEL: 'add_new_material_new_category_cancel',
  NEW_MATERIAL_NEW_CATEGORY_SUCCESS: 'add_new_material_new_category_success',
  MATERIAL_PROJECTWISE_ARCHIVED_CLICK: 'material_list_show_archived_click',
  MATERIAL_PROJECTWISE_INSTOCK_ADD_TABLE: 'materila_list_in_stock_add_click',
  MATERIAL_PROFILE_THREE_DOT_CLICK: 'material_profile_three_dot_click',
  MATERIAL_PROJECTWISE_DOC_DROPDOWN: 'material_list_excel_drop_down_click',
  MATERIAL_PROFILE_THREE_DOT_DUPLICATE_CLICK:
    'material_profile_three_dot_duplicate_click',
  MATERIAL_PROFILE_EDIT_CLICK: 'material_list_edit_click',
  MATERIAL_NEW_MATERIAL_SPECS: 'add_new_material_specifications_type',
  MATERIAL_NEW_MATERIAL_CATEGORY: 'add_new_material_category_select',
  MATERIAL_NEW_MATERIAL_ITEM_CODE: 'add_new_material_item_code_type',
  MATERIAL_NEW_MATERIAL_ADD_STOCK: 'add_material_add_stock_details_click',
  MATERIAL_NEW_MATERIAL_SAVE_AND_CREATE_NEW_CLICK:
    'add_material_save_and_create_new_success',
  MATERIAL_ADD_MATERIAL_CATEGORY_CLICK: 'add_material_category_dropdown_click',
  MATERIAL_ADD_MATERIAL_ADV_SEARCH_CLICK: 'add_material_advance_search_click',
  MATERIAL_ADD_MATERIAL_CONTINUE_CLICK: 'add_material_continue_click',
  MATERIAL_PROJECTWISE_MATERIAL_FILTER_APPLY:
    'project_vise_inventory_material_name_check_box',

  EDIT_MATERIAL_UPDATE_CLICK: 'edit_material_update_click',
  DELETE_MATERIAL_BTN_CLICK: 'delete_material_btn_click',
  PROJECT_MATERIAL_BTN_CLICK: 'project_material_btn_click',
  ALL_MATERIAL_BTN_CLICK: 'all_material_btn_click',
};

export const PO_SEQUENCING_SEGMENT_CONST = {
  PO_DETAILS_CLICK: 'po_details_click',
  PO_EDIT: 'po_setting_edit_number_click',
  PO_PROJECTWISE_ADD_PREFIX: 'po_setting_add_projectwise_prefix_click',
  PO_SETTING_SELECT_PROJECT_CLICK: 'po_setting_select_project_click',
  POS_SETTING_ADD_OTHER_PREFIX_CLICK: 'po_setting_add_other_prefix_click',
  PO_SETTING_REMOVE_SUBPREFIX_CLICK: 'po_setting_remove_subprefix_click',
  PO_SETTING_REMOVE_SUBPREFIX_SUCCESS: 'po_setting_remove_subprefix_success',
  PO_SETTING_ADD_PREFIX_SUCCESS: 'po_setting_add_prefix_success',
};

export const GROUP_MATERIALS_SEGMENT_CONST = {
  MATERIAL_LIST_GROUPED_MATERIALS_CLICK:
    'material_list_grouped_materials_click',
  MATERIAL_MATERIAL_SEARCH_CLICK: 'grouped_material_search_click',
  GROUPED_MATERIAL_SHOW_ARCHIVED: 'grouped_material_show _archived_click',
  GROUPED_MATERIAL_EXCEL_CLICK: 'grouped_material_excel_click',
  GROUPED_MATERIALS_GROUP_NAME_CLICK: 'grouped_materials_group_name_click',
  GROUPED_MATERIALS_MATERIAL_NAME_CLICK:
    'grouped_materials_Material_name_click',
  GROUPED_MATERIALS_PROJECT_NAME_CLICK: 'grouped_materials_project_name_click',
  MATERIAL_LIST_GROUP_TYPE_CLICK: 'material_list_group_type_click',
  MATERIAL_LIST_ADD_GROUP_CLICK: 'material_list_add_group_click',
  MATERIAL_LIST_ADD_GROUP_SUCCESS: 'material_list_add_group_success',
};

export const GRN_SEGMENT_CONST = {
  GRN_WITH_INVOICE_CREATE_SUCCESS: 'grn_with_invoice_create_success',
};

export const REPORT_ASYNC = {
  PROGRESS_REPORT_SUCCESS_SIDE_PAN_OPTION_SELECT:
    'progress_report_success_side_pan_option_select',
  REPORT_SECTION_DOWNLOAD_CLICK: 'report_section_download_click',
  REPORT_SECTION_CREATE_REPORT_CLICK: 'report_section_create_report_click',
  REPORT_POPUP_CHECK_STATUS_CLICK: 'report_popup_check_status_click',
  REPORT_SECTION_VIEW_CLICK: 'report_section_view_click',
};

export const MATERIAL_ISSUE_SEGMENT = {
  MATERIAL_ISSUE_CREATE_CLICK: 'material_issue_create_click',
  MATERIAL_ISSUE_CREATE_SUCCESS: 'material_issue_create_success',
  EDIT_OPTION_CLICK: 'edit_option_click',
};

export const SITE_TRANSFER_SEGMENT = {
  SITE_TRANSFER_CREATE_CLICK: 'site_transfer_create_click',
  SITE_TRANSFER_CREATE_SUCCESS: 'site_transfer_create_success',
};

export const ORG_VENDOR_SEGMENT = {
  UPLOAD_VENDOR_LIST_BTN_CLICK: 'upload_vendor_list_btn_click',
  DOWNLOAD_SAMPLE_CLICK: 'download_sample_click',
  ERROR_SHEET_POP_UP: 'error_sheet_pop_up',
  UPLOAD_UPDTD_SHEET_BTN_CLICK: 'upload_updtd_sheet_btn_click',
  UPLOAD_VENDOR_LIST_SUCCESS: 'upload_vendor_list_success',
  CREATE_NEW_VENDOR_BTN_CLICK: 'create_new_vendor_btn_click',
  CREATE_VENDOR_BTN_CLICK: 'create_vendor_btn_click',
  CREATE_SINGLE_VENDOR_SUCCESS: 'create_single_vendor_success',
  ADD_ADDITIONAL_INFO_BTN_CLICK: 'add_additional_info_btn_click',
  SKIP_FOR_NOW_BTN_CLICK: 'skip_for_now_btn_click',
  SAVE_ADDITIONAL_DETAILS_BTN_CLICK: 'save_additional_details_btn_clik',
  SAVE_ADDITIONAL_DETAILS_SUCCESS: 'save_additional_details_success',
  CHECK_GSTIN_BTN_CLICK: 'check_gstin_btn_click',
  UPLOAD_VENDOR_DOC_BTN_CLICK: 'upload_vendor_doc_btn_click',
  REMOVE_VENDOR_DOC_BTN_CLICK: 'remove_vendor_doc_btn_click',
  VENDOR_DATE_PILL_CLICK: 'vendor_date_pill_click',
  PROJECT_PILL_BTN_CLICK: 'project_pill_btn_click',
  PROJECT_FILTER_APPLY_CLICK: 'project_filter_apply_click',
  SORT_BY_PILL_CLICK: 'sort_by_pill_click',
  CATEGORY_PILL_CLICK: 'category_pill_click',
  VENDOR_SEARCH_BAR_CLICK: 'vendor_search_bar_click',
  VENDOR_SEARCH_BAR_TYPE: 'vendor_search_bar_type',
  VENDOR_CARD_CLICK: 'vendor_card_click',
  VIEW_VENDOR_INFO_BTN_CLICK: 'view_vendor_info_btn_click',
  PAYMENT_HISTORY_BTN_CLICK: 'payemnt_history_btn_click',
  PRICING_HISTORY_BTN_CLICK: 'pricing_history_btn_click',
  EDIT_VENDOR_BTN_CLICK: 'edit_vendor_btn_click',
  DELETE_VENDOR_BTN_CLICK: 'delete_vendor_btn_click',
  DELETE_VENDOR_FINAL_CLICK: 'delete_vendor_final_cilck',
  DELETE_VENDOR_SUCCESS: 'delete_vendor_success',
  ADD_TO_PROJECTS_BTN_CLICK: 'add_to_projects_btn_click',
  PROJECTS_ADD_BTN_FINAL_CLICK: 'projects_add_btn_final_click',
  PROJECTS_ADDED_SUCCESS: 'projects_added_success',
  CANCEL_ADD_DETAILS_POP_UP: 'cancel_add_details_pop_up',
  CANCEL_ADD_DETAILS_BTN_CLICK: 'cancel_add_details_btn_click',
  VIEW_ALL_VENDOR_BTN_CLICK: 'view_all_vendor_btn_click',
  SEARCH_ALL_VENDOR_BTN_CLICK: 'search_all_vendor_btn_click',
  CONTINUE_VENDOR_ADD_BTN_CLICK: 'continue_vendor_add_btn_click',
};

export const APPROVER_SETTINGS = {
  APPROVAL_SETTINGS_ADD_APPROVER_BUTTON_CLICK:
    'approval_settings_add_approver_btn_click',
  APPROVAL_SETTINGS_ADD_APPROVER_POP_UP:
    'approval_settings_add_approver_pop_up',
  APPROVAL_SETTINGS_REMOVE_APPROVER_BTN_CLICK:
    'approval_settings_remove_approver_btn_click',
  APPROVAL_SETTINGS_SAVE_APPROVER_BTN_CLICK:
    'approval_settings_save_approver_btn_click',
  APPROVAL_SETTINGS_CANCEL_BTN: 'approval_settings_cancel_btn',
  EXPENSE_SETTINGS_ADD_APPROVER_BTN_CLICK:
    'expense_settings_add_approver_btn_click',
  EXPENSE_SETTINGS_ADD_APPROVER_POP_UP: 'expense_settings_add_approver_pop_up',
  EXPENSE_SETTINGS_REMOVE_APPROVER_BTN_CLICK:
    'expense_settings_remove_approver_btn_click',
  EXPENSE_SETTINGS_SAVE_APPROVER_BTN_CLICK:
    'expense_settings_save_approver_btn_click',
  EXPENSE_SETTINGS_CANCEL_APPROVER_BTN_CLICK:
    'expense_settings_cancel_approver_btn_click',
  EXPENSE_SETTINGS_TOGGLE_BTN: 'expense_settings_toggle_btn',
};
