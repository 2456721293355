import { TASK_STATUS } from '../constant';
import { divide, mod, toNumber } from './Math.util';
import _ from 'lodash';

export const deepCloneArray = (arr: Array<any>) =>
  Array.from(arr, x => Object.assign({}, x));

export function isEmail(email: string) {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return re.test(String(email).toLowerCase());
}

export function encodeString(str: string) {
  return str.replace(/./g, c => {
    return mod(`0${c.charCodeAt(0)}`.slice(-3), 7);
  });
}

export function capitalizeFirstChar(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

export function parseString(str: string, ...args: Array<any>): string {
  let i = 0;

  return str.replace(/{}/g, () => String(args[i++] || ''));
}

export const capitalizeString = (text: string) =>
  text.replace(/(^\w|\s\w)/g, m => m.toUpperCase());

interface CombinedProjectStageProps {
  _id: string;
  name: string;
  stage_id: string;
  task_id?: string;
  task_status: number;
  project_id: string;
  order: number;
}

const compareByOrder = (a: any, b: any) => a.order - b.order;

export const combineProjectStages = (orgStages = [], projectStages = []) => {
  const values = Object.values(
    _.mergeWith(
      _.keyBy(_.merge({}, orgStages), 'stage_id'),
      _.keyBy(_.merge({}, projectStages), 'stage_id')
    )
  );

  return [...values].sort(compareByOrder) as CombinedProjectStageProps[];
};

export const getLatestStageName = (orgStages, projectStages) => {
  if (!orgStages?.length) {
    return undefined;
  }
  const arr = combineProjectStages(orgStages, projectStages);

  let highestOrderIndex = -1,
    highestOrder = -Infinity,
    allCompleted = true;

  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    allCompleted = allCompleted && obj.task_status === TASK_STATUS.COMPLETED;

    if (
      obj.task_status === TASK_STATUS.IN_PROGRESS ||
      obj.task_status === TASK_STATUS.COMPLETED
    ) {
      if (obj.order > highestOrder) {
        highestOrder = obj.order;
        highestOrderIndex = i;
      }
    }
  }

  return allCompleted
    ? 'All Stages Complete'
    : arr[Math.max(highestOrderIndex, 0)].name;
};

export const getNextPageForList = listBoxNode => {
  const [scrolledHeight, scrollableHeight] = [
    listBoxNode?.scrollTop + listBoxNode.clientHeight,
    listBoxNode.scrollHeight,
  ];

  return (scrolledHeight / scrollableHeight) * 100 > 80; // fetch next page only if 80% of the current page is scrolled
};

export const convertOvertimeMinutesToHours = (overtimeMinutes: number) => {
  const remainderMinutes = toNumber(mod(overtimeMinutes, 60));
  const hours = toNumber(divide(overtimeMinutes - remainderMinutes, 60));

  return {
    hours,
    minutes: remainderMinutes,
  };
};

export const getSortOption = (params: any) => {
  const sortModel = params?.columnApi
    ?.getColumnState?.()
    .find?.(s => s.sort != null);

  let sortOption;
  if (sortModel) {
    sortOption = { [sortModel.colId]: sortModel.sort === 'asc' ? 1 : -1 };
  }

  return sortOption;
};

export const getDFilterSortOption = (params: any) => {
  const sortModel = params?.columnApi
    ?.getColumnState?.()
    .find?.(s => s.sort != null);

  const sort = [];
  if (sortModel) {
    sort.push(`${(sortModel.sort === 'asc' ? '' : '-') + sortModel.colId}`);
  }

  return sort;
};

export const getMultiArrayItemStr = arr => {
  if (arr.length <= 1) {
    return arr[0] || '';
  }

  return `${arr[0]} +${arr.length - 1}`;
};

export const isSubString = (str: string, sub: string) => {
  return str.toUpperCase().includes(sub.toUpperCase());
};

export const removeNullKeys = reqBody => {
  Object.keys(reqBody).forEach(key => {
    if (reqBody[key] === undefined || reqBody[key] === null) {
      delete reqBody[key];
    }
  });
};
