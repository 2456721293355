export const INVENTORY_API_PENDING = 'INDENT_API_PENDING';
export const INVENTORY_API_ERROR = 'INDENT_API_ERROR';
export const INVENTORY_API_SUCCESS = 'INDENT_API_SUCCESS';

export const GET_INVENTORY_DETAILS = 'GET_INVENTORY_DETAILS';
export const GET_GROUPED_MATERIALS = 'GET_GROUPED_MATERIALS';
export const GET_GROUPED_MATERIAL = 'GET_GROUPED_MATERIAL';
export const GET_EDIT_MATERIAL_INFO = 'GET_EDIT_MATERIAL_INFO';
export const SET_GROUP_MATERIAL_FORM = 'SET_GROUP_MATERIAL_FORM';
export const SET_GROUP_MATERIAL_FORM_ITEMS = 'SET_GROUP_MATERIAL_FORM_ITEMS';
export const SET_GROUP_MATERIAL_FORM_ERROR = 'SET_GROUP_MATERIAL_FORM_ERROR';
export const SET_GROUP_MATERIAL_DESCRIPTION_ERROR =
  'SET_GROUP_MATERIAL_DESCRIPTION_ERROR';
export const SET_GROUP_MATERIAL_FORM_DEFAULT =
  'SET_GROUP_MATERIAL_FORM_DEFAULT';
export const SET_PAGINATED_DATA_TOTAL_COUNT = 'SET_PAGINATED_DATA_TOTAL_COUNT';
export const SET_SELECTED_INVENTORY_TAB_VIEW_ID =
  'SET_SELECTED_INVENTORY_TAB_VIEW_ID';
export const GET_INVENTORY_INDENT_DETAILS = 'GET_INVENTORY_INDENT_DETAILS';
export const ADD_MATERIAL_INVENTORY = 'ADD_MATERIAL_INVENTORY';
