import {
  DRIVE_FILES,
  DRIVE_PARENT_ID,
  DRIVE_FOLDERS,
  DRIVE_FOLDER_API_ERROR,
  DRIVE_OPEN_FOLDER_DETAIL,
  DRIVE_FOLDER_API_PENDING,
  DRIVE_FOLDER_API_SUCCESS,
  CREATE_FILE_API_SUCCESS,
  CREATE_FILE_API_PENDING,
  CREATE_FILE_API_ERROR,
  DRIVE_UPLOAD_FILE_RESET,
  DRIVE_FILE_SHARE_ERROR,
  DRIVE_FILE_SHARABLE_LINK,
  DRIVE_FILE_SHARE_PENDING,
  DRIVE_FILE_SHARE_RESET,
  DRIVE_FOLDER_INFO_SUCCESS,
  DRIVE_FOLDER_INFO_ERROR,
  DRIVE_FOLDER_INFO_PENDING,
  DRIVE_FILE_INFO_SUCCESS,
  DRIVE_FILE_INFO_ERROR,
  DRIVE_FILE_INFO_PENDING,
  SELECTED_FILE_INFO,
  SELECTED_FILE_VERSION,
  DRIVE_FOLDER_INFO_DETAIL,
  DRIVE_SHARE_INFO_RESET,
  SET_FILE_COMMENTS,
} from './Drive.type';
import { FOLDER_TYPE } from '../../../constant/drive_constants';

import { ReducerProps } from '../../../interfaces/Base';
import {
  DriveFolderInfoProps,
  DriveInitialsState,
} from '../../../interfaces/Drive';

const initialState: DriveInitialsState = {
  folders: [],
  files: [],
  parentID: '',
  pending: false,
  folder_type: FOLDER_TYPE.REGULAR,
  error: '',
  folder_detail: {} as DriveFolderInfoProps,
  upload_file_pending: false,
  upload_file_error: '',
  upload_file_success: false,
  share_file_pending: false,
  share_file_error: '',
  share_file_info: null,
  folder_info_pending: false,
  selected_folder_info: {} as DriveFolderInfoProps,
  folder_info_error: '',
  file_info_pending: false,
  file_info: null,
  file_info_error: '',
  file_version: [],
  comments: [],
};

export type driveReducerProps = typeof initialState;

export default function driveReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
): driveReducerProps {
  switch (type) {
    case DRIVE_PARENT_ID:
      return {
        ...state,
        folders: payload,
      };
    case DRIVE_FILES:
      return {
        ...state,
        files: payload,
      };
    case DRIVE_FOLDERS:
      return { ...state, folders: payload };
    case DRIVE_OPEN_FOLDER_DETAIL:
      return {
        ...state,
        folder_detail: payload,
        folder_type: payload?.folder_type ?? FOLDER_TYPE.REGULAR,
      };
    case DRIVE_FOLDER_API_ERROR:
      return { ...state, error: error, pending: false };
    case DRIVE_FOLDER_API_PENDING:
      return { ...state, pending: true };
    case DRIVE_FOLDER_API_SUCCESS:
      return { ...state, pending: false, error: '' };
    case CREATE_FILE_API_SUCCESS:
      return {
        ...state,
        upload_file_pending: false,
        upload_file_success: true,
        upload_file_error: '',
      };
    case CREATE_FILE_API_PENDING:
      return {
        ...state,
        upload_file_pending: true,
        upload_file_success: false,
        upload_file_error: '',
      };
    case CREATE_FILE_API_ERROR:
      return {
        ...state,
        upload_file_pending: false,
        upload_file_success: false,
        upload_file_error: payload,
      };

    case DRIVE_UPLOAD_FILE_RESET:
      return {
        ...state,
        upload_file_pending: false,
        upload_file_success: false,
        upload_file_error: '',
      };
    case DRIVE_FILE_SHARE_ERROR:
      return {
        ...state,
        share_file_info: null,
        share_file_pending: false,
        share_file_error: payload,
      };

    case DRIVE_FILE_SHARABLE_LINK:
      return {
        ...state,
        share_file_info: payload,
        share_file_pending: false,
        share_file_error: '',
      };

    case DRIVE_FILE_SHARE_PENDING:
      return {
        ...state,
        share_file_info: null,
        share_file_pending: true,
        share_file_error: '',
      };

    case DRIVE_FILE_SHARE_RESET:
      return {
        ...state,
        share_file_info: null,
        share_file_pending: false,
        share_file_error: '',
      };

    case DRIVE_FOLDER_INFO_SUCCESS:
      return {
        ...state,
        folder_info_pending: false,
        folder_info_success: true,
        folder_info_error: '',
      };
    case SELECTED_FILE_INFO:
      return {
        ...state,
        file_info: payload,
      };
    case SELECTED_FILE_VERSION:
      return {
        ...state,
        file_version: payload,
      };
    case DRIVE_FOLDER_INFO_ERROR:
      return {
        ...state,
        folder_info_pending: false,
        folder_info_error: payload,
      };
    case DRIVE_FOLDER_INFO_DETAIL:
      return { ...state, selected_folder_info: payload };
    case DRIVE_FOLDER_INFO_PENDING:
      return {
        ...state,
        folder_info_pending: true,
        selected_folder_info: {} as DriveFolderInfoProps,
        folder_info_error: '',
      };
    case DRIVE_FILE_INFO_SUCCESS:
      return {
        ...state,
        file_info_pending: false,
        file_info: payload,
        file_info_error: '',
      };

    case DRIVE_FILE_INFO_ERROR:
      return {
        ...state,
        file_info_pending: false,
        file_info_error: payload,
      };

    case DRIVE_FILE_INFO_PENDING:
      return {
        ...state,
        file_info_pending: true,
        file_info_error: '',
      };
    case DRIVE_SHARE_INFO_RESET:
      return {
        ...state,
        share_file_info: null,
      };
    case SET_FILE_COMMENTS:
      return {
        ...state,
        comments: payload,
      };

    default:
      return state;
  }
}
