import {
  ARCHIVE_MI_PENDING,
  ARCHIVE_MI_SEQ,
  MI_SEQ_PENDING,
  CREATE_MI_SEQ,
  GET_ACTIVE_MI_SEQ,
  GET_ALL_MI_SEQ,
  GET_ARCHIVED_MI_SEQ,
  GET_MI_SEQ_PROJECT,
  RESTORE_MI_SEQ,
  UPDATE_ACTIVE_MI_SEQ,
  UPDATE_ALL_MI_SEQ,
  UPDATE_ARCHIVED_MI_SEQ,
  UPDATE_MI_SEQ,
  GET_MI_SEQ,
  MI_SEQ_API_ERROR,
} from './MaterialIssueSequence.type';

const pendingInitialStae = {
  miSeqPending: false,
  archiveMISeqPending: false,
};

const initialState = {
  ...pendingInitialStae,
  allMISeq: [],
  archivedMISeq: [],
  activeMISeq: [],
  projectMISeq: [],
  createMISeq: null,
  selectedMISeq: null,
  addedMISeq: null,
  MISeq: null,
  archiveMISeqResp: null,
  restoreMISeq: null,
  updateMISeq: null,
  updateMIAllSeq: null,
  updateMIArchivedSeq: null,
  updateMIActiveSeq: null,
  error: null,
};

export default function miSequenceReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case MI_SEQ_PENDING:
      return { ...state, miSeqPending: payload };
    case CREATE_MI_SEQ:
      return { ...state, createMISeq: payload };
    case GET_ALL_MI_SEQ:
      return { ...state, allMISeq: payload };
    case GET_ACTIVE_MI_SEQ:
      return { ...state, activeMISeq: payload };
    case GET_ARCHIVED_MI_SEQ:
      return { ...state, archivedMISeq: payload };
    case GET_MI_SEQ_PROJECT:
      return { ...state, projectMISeq: payload };
    case GET_MI_SEQ:
      return { ...state, MISeq: payload };

    case UPDATE_MI_SEQ:
      return { ...state, updateMISeq: payload };
    case UPDATE_ALL_MI_SEQ:
      return { ...state, updateAllMISeq: payload };
    case UPDATE_ARCHIVED_MI_SEQ:
      return { ...state, updateArchivedMISeq: payload };
    case UPDATE_ACTIVE_MI_SEQ:
      return { ...state, updateActiveMISeq: payload };
    case ARCHIVE_MI_PENDING:
      return { ...state, archiveMISeqPending: payload };
    case ARCHIVE_MI_SEQ:
      return { ...state, archiveMISeqResp: payload };
    case RESTORE_MI_SEQ:
      return { ...state, restoreMISeq: payload };

    case MI_SEQ_API_ERROR:
      return { ...state, error: error };
    default:
      return state;
  }
}
