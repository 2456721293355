import {
  getSignedUrl,
  singleUploadToS3,
  uploadMultipleToS3,
  getSignedUrlArr,
  getPrivateSignedUrl,
} from '../redux/features/common/document/Document.action';
import { getMimeTypeFromFilename, getFileExtension } from './MimeType.util';
import { formatDate } from './Date.util';
import { toastErrorMessage, toastSuccessMessage } from './Toast.util';
import { UPLOAD_FILE_MESSAGE } from '../constant/toast_message_constants';

export function getFileKeyName(contextId, context, fileName) {
  let keyUrl = contextId ? contextId : '';
  keyUrl = context ? `${keyUrl}/${context}` : keyUrl;
  const extension = getFileExtension(fileName);
  const mainName = fileName.substring(0, fileName.lastIndexOf('.'));
  const currDateTime = formatDate(Date.now(), 'DD_MMM_YYYY_hh_mm_a');
  return `${keyUrl}/${mainName}_${currDateTime}.${extension}`;
}

export async function s3UploadHelper(files, contextId, context) {
  const metaInfos = [];
  const keys = [];
  const temp = [];
  const keyMap = new Map();
  for (const file of files) {
    const keyName = getFileKeyName(contextId, context, file.name);
    keys.push(keyName);
    keyMap.set(keyName, file.name);
  }
  const signedUrls = await getSignedUrlArr(keys);
  if (signedUrls.length < 0) {
    return [];
  }
  signedUrls.forEach(item => {
    const fileName = keyMap.get(item.keyName) || '';
    const file = files.find(elem => elem.name === fileName);
    const mimeType = getMimeTypeFromFilename(fileName);
    metaInfos.push({
      name: fileName,
      url: item.url.substr(0, item.url.indexOf('?')),
      mime_type: mimeType,
    });
    temp.push({
      url: item.url,
      file,
      type: mimeType,
    });
  });
  const success = await uploadMultipleToS3(temp);
  if (!success) {
    return [];
  }
  return metaInfos;
}

export async function singleS3UploadHelper(
  file,
  contextId,
  context,
  setIsUploading?: Function,
  bucketName = '',
  isPrivateBucket = false,
  key = ''
) {
  const fileName = file.name;
  if (!key) {
    key = getFileKeyName(contextId, context, file.name);
  }
  let signedUrl;
  if (isPrivateBucket) {
    signedUrl = await getPrivateSignedUrl(key, bucketName);
  } else {
    signedUrl = await getSignedUrl(key);
  }
  if (!signedUrl) {
    return '';
  }
  const fileToUpload = {
    url: signedUrl,
    file,
    type: getMimeTypeFromFilename(fileName),
  };
  if (setIsUploading && typeof setIsUploading === 'function') {
    setIsUploading(true);
  }
  const uploadSuccess = await singleUploadToS3(fileToUpload);
  if (!uploadSuccess) {
    return '';
  }
  if (setIsUploading && typeof setIsUploading === 'function') {
    setIsUploading(false);
  }
  return signedUrl.substr(0, signedUrl.indexOf('?'));
}

export async function handleS3Upload(
  files,
  setAttachedFiles,
  setPending,
  contextId,
  context,
  successMessage?: string,
  errorMessage?: string
) {
  try {
    setPending?.(true);
    const urls = await s3UploadHelper(files, contextId, context);
    if (urls?.length) {
      if (successMessage !== '') {
        toastSuccessMessage(successMessage || UPLOAD_FILE_MESSAGE.success);
      }
      setAttachedFiles?.(urls);
      return urls;
    } else throw new Error(errorMessage || UPLOAD_FILE_MESSAGE.error);
  } catch (error) {
    toastErrorMessage(error?.message);
    return [];
  } finally {
    setPending?.(false);
  }
}
