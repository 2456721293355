/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { CrashErrorIcon } from '../../components/common/Icons';

function ErrorBoundary() {
  return (
    <div className="error-container d-flex flex-column align-items-center">
      <CrashErrorIcon className="error-boundary-icon" />
      <div className="title">Something just went wrong!</div>
      <div>The page you are looking for didn’t load. We will fix it soon!</div>
      <div
        className="fw-bold text-primary hand-pointer"
        onClick={() => {
          window.location.reload();
        }}
      >
        Try Reloading the Page
      </div>
    </div>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
