import { useEffect } from 'react';
import MuiPhoneNumber from 'mui-phone-number';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/Hooks';
import { fetchSupportedCountries } from '../../redux/features/common/user';

function CustomMobileInput({
  defaultCountry = 'in',
  InputProps,
  onChange,
  ...props
}: any) {
  const dispatch = useAppDispatch();
  const countries = useAppSelector(
    state => state.userreducer.supported_countries
  );
  const allowedCountryArr =
    countries?.map(country => country._id?.toLowerCase()) || [];

  const handleOnChange = (e: any, val: any) => {
    const countryCode = `+${val.dialCode}`;
    let mobileNumber = e.slice(val.dialCode.length + 1 || '');
    if (mobileNumber) {
      mobileNumber = mobileNumber.replace(/[^\w]/gi, '');
    }
    onChange?.(countryCode, mobileNumber);
  };

  const customInputProps = {
    ...InputProps,
    classes: {
      ...InputProps?.classes,
      root: clsx(InputProps?.classes?.root, 'typography-base'),
      input: clsx(InputProps?.classes?.input),
    },
  };

  useEffect(() => {
    if (!countries?.length) {
      dispatch(fetchSupportedCountries());
    }
  }, []);

  const CustomMuiPhoneNumber = MuiPhoneNumber as any;

  return allowedCountryArr?.length ? (
    <CustomMuiPhoneNumber
      defaultCountry={defaultCountry}
      onChange={handleOnChange as any}
      InputProps={customInputProps}
      disableAreaCodes={true}
      onlyCountries={allowedCountryArr}
      {...props}
    />
  ) : null;
}

export default CustomMobileInput;
