export const GET_WORK_ORDER_LIST = 'GET_WORK_ORDER_LIST';
export const SET_WORK_ORDER_DETAILS = 'SET_WORK_ORDER_DETAILS';
export const GET_VENDOR_ORG_LIST = 'GET_VENDOR_ORG_LIST';
export const GET_WORK_ORDER_SEQUENCE = 'GET_WORK_ORDER_SEQUENCE';
export const GET_VENDOR_TRANSACTION_LIST = 'GET_VENDOR_TRANSACTION_LIST';
export const GET_VENDOR_PAYABLE_SUMMARY = 'GET_VENDOR_PAYABLE_SUMMARY';
export const SET_EXISTING_TASK_WORK_ORDER = 'SET_EXISTING_TASK_WORK_ORDER';
export const GET_WORK_ORDER_API_PENDING = 'GET_WORK_ORDER_API_PENDING';
export const GET_WORK_ORDER_API_SUCCESS = 'GET_WORK_ORDER_API_SUCCESS';
export const WO_API_PENDING = 'WO_API_PENDING';
export const WO_API_SUCCESS = 'WO_API_SUCCESS';
export const WO_API_ERROR = 'WO_API_ERROR';
export const WO_DELETE_API_PENDING = 'WO_DELETE_API_PENDING';
export const WO_DELETE_API_SUCCESS = 'WO_DELETE_API_SUCCESS';
