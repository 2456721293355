export * as AUTH_EVENTS from './auth';
export * as REPORT_EVENTS from './report';
export const SHARE_IMAGE_EVENTS = {
  IMAGE_SHARE_SUCCESS: 'IMAGE_SHARE_SUCCESS',
} as const;
export * as NAVBAR_EVENTS from './navbar';
export * as BUTTON_EVENTS from './button';
export * as ACTION_EVENTS from './actions';
export * as SCROLL_EVENTS from './scroll';
export * as UI_EVENTS from './UI';

export const INDEX_DB_ERROR = 'INDEX_DB_ERROR';
