import { useState } from 'react';

import { GetFileIcon } from './FileIcons';
import CustomTooltip from './customTooltip/CustomTooltip';
import {
  getFileExtension,
  getMimeTypeFromExt,
} from '../../utils/MimeType.util';
import { openInNewTab } from '../../utils';
import MultipleImagesModal from './images/MultipleImagesModal';

interface FileProps {
  name?: string;
  path?: string;
  filename?: string;
  preview?: string;
  url?: string;
}

interface GridFileRendererProps {
  file: FileProps;
  className?: string;
  [props: string]: any;
}

export const GridFileRenderer = ({
  file,
  className = '',
  ...props
}: GridFileRendererProps) => {
  let fileName: string;

  if (file?.name) {
    fileName = file.name;
  } else if (file?.path) {
    fileName = file.path;
  } else {
    fileName = file.filename;
  }

  if (!fileName) return null;
  const ext = getFileExtension(fileName);

  return getMimeTypeFromExt(ext).split('/')[0] === 'image' ? (
    <img src={file.url} {...props} />
  ) : (
    <GetFileIcon
      className={className}
      type={ext}
      {...props}
      onClick={() => openInNewTab(file.url)}
    />
  );
};
interface RenderFileProps {
  size?: 'small' | 'medium' | 'large' | 'x-large';
  file: FileProps | string;
  className?: string;
}

export const RenderFile = ({
  size = 'medium',
  file,
  className = '',
}: RenderFileProps) => {
  const [showImagePreview, setShowImagePreview] = useState(false);

  if (typeof file === 'string') {
    return (
      <>
        <img
          src={file}
          className={`size-${size} rounded hand-pointer`}
          onClick={() => setShowImagePreview(true)}
        />
        <MultipleImagesModal
          show={showImagePreview}
          onHide={() => setShowImagePreview(false)}
          images={[file]}
          handleSetIndex={() => {}}
          index={0}
        />
      </>
    );
  }

  let fileName: string;

  if (file?.name) {
    fileName = file.name;
  } else if (file?.path) {
    fileName = file.path;
  } else {
    fileName = file.filename;
  }

  if (!fileName) return null;
  const ext = getFileExtension(fileName);

  const renderImage = (
    <>
      <CustomTooltip title={fileName}>
        <img
          src={file.url ? file.url : file.preview}
          alt={fileName}
          className={`size-${size} rounded hand-pointer ${className}`}
          onClick={() => setShowImagePreview(true)}
        />
      </CustomTooltip>
      <MultipleImagesModal
        show={showImagePreview}
        onHide={() => setShowImagePreview(false)}
        images={file.url ? [file.url] : [file.preview]}
        handleSetIndex={() => {}}
        index={0}
      />
    </>
  );

  const renderFiles = (
    <CustomTooltip title={fileName}>
      <span>
        <GetFileIcon
          type={ext}
          onClick={() => openInNewTab(file.url)}
          className={`size-${size} hand-pointer ${className}`}
        />
      </span>
    </CustomTooltip>
  );

  return getMimeTypeFromExt(ext).split('/')[0] === 'image'
    ? renderImage
    : renderFiles;
};

interface FilePreviewerProps {
  size?: 'small' | 'medium' | 'large' | 'x-large';
  files: Array<FileProps> | string[];
}

export const FilePreviewer = ({
  size = 'medium',
  files = [],
}: FilePreviewerProps) => {
  return (
    <div className="file-previewer">
      {files.map((file, idx) => (
        <RenderFile key={idx} file={file} size={size} />
      ))}
    </div>
  );
};
