/* eslint-disable */

interface IUseLocalStorage {
  isLocalStorageAvailable: () => boolean;
  getLocalStorageValue: (key?: string, shouldParse?: boolean) => any;
  setLocalStorageValue: (
    value: any,
    key?: string,
    shouldStringify?: boolean
  ) => void;
  removeLocalStorageValue: (key?: string) => void;
  clearLocalStorageValues: () => void;
  checkLocalStorageValue: (key?: string) => boolean;
}

const useLocalStorage = (initialkey?: string): IUseLocalStorage => {
  const isLocalStorageAvailable = (): boolean => {
    try {
      const testKey = '__storage_test__';
      window.localStorage.setItem(testKey, 'test');
      window.localStorage.removeItem(testKey);
      return true;
    } catch (error) {
      console.warn('LocalStorage is not available:', error);
      return false;
    }
  };

  const getLocalStorageValue = (
    key: string = initialkey,
    shouldParse: boolean = true
  ) => {
    if (!key || !isLocalStorageAvailable()) return null;

    try {
      const item = window.localStorage.getItem(key);
      return shouldParse && item ? JSON.parse(item) : item || null;
    } catch (error) {
      console.error('Error reading localStorage key:', key, error);
      return null;
    }
  };

  const setLocalStorageValue = (
    value: any,
    key: string = initialkey,
    shouldStringify: boolean = true
  ) => {
    if (!key || !isLocalStorageAvailable()) return;

    try {
      window.localStorage.setItem(
        key,
        shouldStringify ? JSON.stringify(value) : value
      );
    } catch (error) {
      console.error('Error setting localStorage key:', key, error);
    }
  };

  const removeLocalStorageValue = (key: string = initialkey) => {
    if (!key || !isLocalStorageAvailable()) return;

    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing localStorage key:', key, error);
    }
  };

  const clearLocalStorageValues = () => {
    if (!isLocalStorageAvailable()) return;

    try {
      window.localStorage.clear();
    } catch (error) {
      console.error('Error removing all localStorage key pair values:', error);
    }
  };

  const checkLocalStorageValue = (key: string = initialkey): boolean => {
    if (!key || !isLocalStorageAvailable()) return false;

    try {
      return window.localStorage.getItem(key) !== null;
    } catch (error) {
      console.error('Error checking localStorage key:', key, error);
      return false;
    }
  };

  return {
    isLocalStorageAvailable,
    getLocalStorageValue,
    setLocalStorageValue,
    removeLocalStorageValue,
    clearLocalStorageValues,
    checkLocalStorageValue,
  };
};

export default useLocalStorage;
