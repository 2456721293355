import { getMimeTypeFromFilename } from './MimeType.util';
import { compressImage } from './Generic.util';

export async function compressSingleFile(file) {
  if (getMimeTypeFromFilename(file?.name || '')?.split('/')[0] === 'image') {
    const compressedImg = await compressImage(file);
    return compressedImg;
  }

  return file;
}

export async function compressFiles(files) {
  const compressedFiles = [...(files || [])];
  compressedFiles?.forEach(async file => await compressSingleFile(file));

  return compressedFiles;
}
