import {
  ARCHIVE_INDENT_PENDING,
  ARCHIVE_INDENT_SEQ,
  INDENT_SEQ_PENDING,
  CREATE_INDENT_SEQ,
  GET_ACTIVE_INDENT_SEQ,
  GET_ALL_INDENT_SEQ,
  GET_ARCHIVED_INDENT_SEQ,
  GET_INDENT_SEQ_PROJECT,
  RESTORE_INDENT_SEQ,
  UPDATE_ACTIVE_INDENT_SEQ,
  UPDATE_ALL_INDENT_SEQ,
  UPDATE_ARCHIVED_INDENT_SEQ,
  UPDATE_INDENT_SEQ,
  GET_INDENT_SEQ,
  INDENT_SEQ_API_ERROR,
} from './IndentSquence.types';

const pendingInitialStae = {
  indentSeqPending: false,
  archiveIndentSeqPending: false,
};

const initialState = {
  ...pendingInitialStae,
  allIndentSeq: [],
  archivedIndentSeq: [],
  activeIndentSeq: [],
  projectIndentSeq: [],
  createIndentSeq: null,
  selectedIndentSeq: null,
  addedIndentSeq: null,
  indentSeq: null,
  archiveIndentSeqResp: null,
  restoreIndentSeq: null,
  updateIndentSeq: null,
  updateIndentAllSeq: null,
  updateIndentArchivedSeq: null,
  updateIndentActiveSeq: null,
  error: null,
};

export default function indentSequenceReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case INDENT_SEQ_PENDING:
      return { ...state, indentSeqPending: payload };
    case CREATE_INDENT_SEQ:
      return { ...state, createIndentSeq: payload };
    case GET_ALL_INDENT_SEQ:
      return { ...state, allIndentSeq: payload };
    case GET_ACTIVE_INDENT_SEQ:
      return { ...state, activeIndentSeq: payload };
    case GET_ARCHIVED_INDENT_SEQ:
      return { ...state, archivedIndentSeq: payload };
    case GET_INDENT_SEQ_PROJECT:
      return { ...state, projectIndentSeq: payload };
    case GET_INDENT_SEQ:
      return { ...state, indentSeq: payload };

    case UPDATE_INDENT_SEQ:
      return { ...state, updateIndentSeq: payload };
    case UPDATE_ALL_INDENT_SEQ:
      return { ...state, updateAllIndentSeq: payload };
    case UPDATE_ARCHIVED_INDENT_SEQ:
      return { ...state, updateArchivedIndentSeq: payload };
    case UPDATE_ACTIVE_INDENT_SEQ:
      return { ...state, updateActiveIndentSeq: payload };
    case ARCHIVE_INDENT_PENDING:
      return { ...state, archiveIndentSeqPending: payload };
    case ARCHIVE_INDENT_SEQ:
      return { ...state, archiveIndentSeqResp: payload };
    case RESTORE_INDENT_SEQ:
      return { ...state, restoreIndentSeq: payload };

    case INDENT_SEQ_API_ERROR:
      return { ...state, error: error };
    default:
      return state;
  }
}
