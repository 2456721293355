import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

export function CircularProgressWithLabel(
  props: CircularProgressProps & {
    value: number;
    barColor?: string;
    size?: string;
    labelTop?: number;
    labelBottom?: number;
    labelLeft?: number;
    labelRight?: number;
    fontSize?: 'fes-s' | 'fes-m' | 'fes-xs';
  }
) {
  const {
    labelTop = -3,
    labelBottom = 0,
    labelRight = 0,
    labelLeft = 2,
    size = '3.5em',
    fontSize = 'fes-m',
  } = props;
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <CircularProgress
        variant="determinate"
        style={{
          color: `${props.barColor}40`,
        }}
        size={size}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        style={{
          color: props.barColor,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          strokeLinecap: 'round',
        }}
        {...props}
        value={Math.round(props.value)}
        size={size}
        thickness={4}
      />
      <Box
        top={labelTop}
        left={labelLeft}
        bottom={labelBottom}
        right={labelRight}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <div className={`${fontSize} fw-bold`}>{`${Math.round(
          props.value
        )}%`}</div>
      </Box>
    </div>
  );
}
