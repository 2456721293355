import { spawn, all, call } from 'redux-saga/effects';
import { toastErrorMessage } from '../utils/Toast.util';
import { dashboardSaga } from './dashboardSaga';

/**
 * Define the rootSaga to combine sagas
 */
export default function* rootSaga() {
  const sagas = [dashboardSaga];

  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            toastErrorMessage(e);
          }
        }
      })
    )
  );
}
