/* eslint-disable */

interface ILocalStorageUtil {
  isLocalStorageAvailable: () => boolean;
  getLocalStorageValue: (key: string, shouldParse?: boolean) => any;
  setLocalStorageValue: (
    key: string,
    value: any,
    shouldStringify?: boolean
  ) => void;
  removeLocalStorageValue: (key: string) => void;
  clearLocalStorageValues: () => void;
  checkLocalStorageValue: (key: string) => boolean;
}

const LocalStorageUtil: ILocalStorageUtil = {
  isLocalStorageAvailable: (): boolean => {
    try {
      const testKey = '__storage_test__';
      window.localStorage.setItem(testKey, 'test');
      window.localStorage.removeItem(testKey);
      return true;
    } catch (error) {
      console.warn('LocalStorage is not available:', error);
      return false;
    }
  },

  getLocalStorageValue: (key: string, shouldParse: boolean = true) => {
    if (!key || !LocalStorageUtil.isLocalStorageAvailable()) return null;

    try {
      const item = window.localStorage.getItem(key);
      return shouldParse && item ? JSON.parse(item) : item || null;
    } catch (error) {
      console.error('Error reading localStorage key:', key, error);
      return null;
    }
  },

  setLocalStorageValue: (
    key: string,
    value: any,
    shouldStringify: boolean = true
  ) => {
    if (!key || !LocalStorageUtil.isLocalStorageAvailable()) return;

    try {
      window.localStorage.setItem(
        key,
        shouldStringify ? JSON.stringify(value) : value
      );
    } catch (error) {
      console.error('Error setting localStorage key:', key, error);
    }
  },

  removeLocalStorageValue: (key: string) => {
    if (!key || !LocalStorageUtil.isLocalStorageAvailable()) return;

    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing localStorage key:', key, error);
    }
  },

  clearLocalStorageValues: () => {
    if (!LocalStorageUtil.isLocalStorageAvailable()) return;

    try {
      window.localStorage.clear();
    } catch (error) {
      console.error('Error removing all localStorage key pair values:', error);
    }
  },

  checkLocalStorageValue: (key: string): boolean => {
    if (!key || !LocalStorageUtil.isLocalStorageAvailable()) return false;

    try {
      return window.localStorage.getItem(key) !== null;
    } catch (error) {
      console.error('Error checking localStorage key:', key, error);
      return false;
    }
  },
};

export default LocalStorageUtil;
