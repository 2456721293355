import {
  SET_AUTO_DPR_API_ERROR,
  SET_AUTO_DPR_API_PENDING,
  SET_AUTO_DPR_API_SUCCESS,
  SET_AUTO_DPR_MEMBERS,
  SET_AUTO_DPR_LOADING,
  SET_AUTO_DPR_DATA,
} from './AutoDpr.type';
import { DEFUALT_AUTO_DPR_TIME } from '../../../../constants';
import { ReducerProps } from '../../../../interfaces/Base';

const initialState = {
  isAutoDprEnable: false,
  autoDprProjects: [],
  autoDprMembers: [],
  autoDprOwner: {},
  autoDprSiteTeamMembers: [],
  autoDprOfficeTeamMembers: [],
  autoDprDays: [],
  autoDprOfficeTeamTime: DEFUALT_AUTO_DPR_TIME.OFFICE_TEAM_TIME,
  autoDprSiteTeamTime: DEFUALT_AUTO_DPR_TIME.SITE_TEAM_TIME,
  pending: false,
  error: false,
  loading: true,
};

export default function autoDprReducer(
  state = initialState,
  { type, payload }: ReducerProps
) {
  switch (type) {
    case SET_AUTO_DPR_DATA:
      return {
        ...state,
        isAutoDprEnable: !payload?.is_disabled,
        autoDprOwner: payload?.owner,
        autoDprProjects: payload?.projects
          ?.filter(d => d.project_status === 1)
          ?.map(d => ({
            project_id: d?.project_id,
            project_status: d?.project_status,
            project_name: d?.project_name,
          })),
        autoDprOfficeTeamMembers: payload?.office_team_user?.map(d => ({
          user_id: d?.user_id,
          user_name: d?.user_name,
          team_id: d?.team_id,
          team_name: d?.team_name,
        })),
        autoDprSiteTeamMembers: payload?.site_team_user?.map(d => ({
          user_id: d?.user_id,
          user_name: d?.user_name,
          team_id: d?.team_id,
          team_name: d?.team_name,
        })),
        autoDprOfficeTeamTime: payload?.office_team_timeMS,
        autoDprSiteTeamTime: payload?.site_team_timeMS,
        autoDprDays: payload?.days,
      };
    case SET_AUTO_DPR_MEMBERS:
      return { ...state, autoDprMembers: payload };
    case SET_AUTO_DPR_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case SET_AUTO_DPR_API_PENDING:
      return {
        ...state,
        pending: payload,
      };
    case SET_AUTO_DPR_API_ERROR:
      return {
        ...state,
        pending: false,
        error: payload,
      };
    case SET_AUTO_DPR_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
}
