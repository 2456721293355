import {
  GET_INDENT_ITEMS,
  INDENT_API_PENDING,
  INDENT_API_ERROR,
  INDENT_API_SUCCESS,
  ADD_INDENT_ITEM,
  GET_INDENT_DETAILS,
  FORM_MODE,
  INDENT_FORM_SHOW,
  INDENT_ITEM_SELECTED,
  DELETE_INDENT,
  GET_INDENT_BY_ORG_ID,
  GET_SIGNATURE,
} from './Indent.type';
import { INDENT_TYPES } from '../../../../constant/po_constants';
import { IndentProps } from '../../../../interfaces/Indent';

const initial_indent_items = [];

const initialPendingState = {
  getIndentPending: false,
};

const initialState = {
  ...initialPendingState,
  pending: false,
  indentItems: initial_indent_items,
  error: null,
  indentItemAdded: false,
  selectedIndentItem: { indent_type: INDENT_TYPES.BOM_LIST } as IndentProps,
  formMode: '',
  indentItemsByOrg: {
    docs: [] as IndentProps[], // will contain result documents
    total: 0, // total count of docs (include of all pages not just current page)
    limit: 10, // limit
    page: 1, // Current Page/ pages start from 1
    pages: 1, // Total Pages or Last Page
  },
  showIndentForm: false,
  userSignature: null,
};

export default function baseReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case ADD_INDENT_ITEM:
      return {
        ...state,
        indentItems: [...state.indentItems, payload],
        indentItemAdded: true,
      };
    case GET_INDENT_ITEMS:
      return { ...state, indentItems: payload };
    case GET_INDENT_DETAILS:
    case INDENT_ITEM_SELECTED:
      return {
        ...state,
        selectedIndentItem: payload,
      };
    case INDENT_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case INDENT_API_PENDING:
      return {
        ...state,
        pending: true,
      };
    case INDENT_API_ERROR:
      return {
        ...state,
        pending: false,
        error: error?.response?.data?.message,
      };
    case FORM_MODE:
      return {
        ...state,
        formMode: payload,
      };
    case DELETE_INDENT:
      return {
        ...state,
        selectedIndentItem: {},
      };
    case INDENT_FORM_SHOW:
      return {
        ...state,
        showIndentForm: payload,
      };
    case GET_INDENT_BY_ORG_ID:
      return {
        ...state,
        indentItemsByOrg: payload,
      };
    case GET_SIGNATURE:
      return {
        ...state,
        userSignature: payload,
      };
    default:
      return state;
  }
}
