import { LocalStorageTokenKeyName } from '../apiClient';
import LocalStorageUtil from './LocalStorage.util';

export default function authHeader() {
  // return authorization header with jwt token
  const token = LocalStorageUtil.getLocalStorageValue(
    LocalStorageTokenKeyName,
    false
  );

  if (token) {
    return {
      token,
      isAuth: true,
    };
  } else {
    return { isAuth: false };
  }
}
