import { Suspense, useEffect } from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';

import MainNavbar from '../components/layout/MainNavbar/MainNavbar';
import getSidebarNavItems from '../data/sidebar-nav-items';
import { setNavbarTitle } from '../redux/features/common/layout/Layout.action';
import { setInitialProjectState } from '../redux/features/common/project';
import { clearUtilsData } from '../redux/features/common/utils/Utils.action';
import { clearPOData } from '../redux/features/material/purchase-order';
import { Buildings } from '../components/common/Icons';
import authHeader from '../utils/AuthHeader.util';
import { loginCheck } from '../redux/features/common/user';
import { ModuleProps } from '../interfaces/Layout';
import { eventDispatch } from '../redux/base';
import { GET_NOTIFICATIONS } from '../redux/features/miscellaneous/notification';
import OrgProfileProps from '../interfaces/Organisation';
import { getOrgProfile } from '../redux/features/common/organisation';
import MenuDropdown from './MenuDropdown';
import { POWERPLAY_WEB_VERSION } from '../constants';
import { useAppDispatch, useAppSelector } from '../redux/Hooks';
import { clearDashboardData } from '../redux/features/dashboard';
import './layoutStyles.scss';
import { theme } from '../components/common/Theme';
import { CustomSpinner } from '../components/common';
import { LOCAL_STORAGE_SEARCH_STRING, TASK_FILTER } from '../constant';
import useLocalStorage from '../utils/hooks/useLocalStorage.hook';

function ProjectLayout() {
  const dispatch = useAppDispatch();
  const history = useNavigate();

  const isAuthenticated = useAppSelector(state => state.userreducer.isAuth);
  const userPermissions = useAppSelector(
    state => state.userreducer.userPermissionMap
  );

  const orgProfile: OrgProfileProps = useAppSelector(
    state => state.organisationReducer.orgProfile
  );
  const projectOrgId = useAppSelector(
    state => state.projectreducer.projectProfile.org_id
  );
  const experiments = useAppSelector(
    state => state.organisationReducer.experiments
  );

  const { removeLocalStorageValue } = useLocalStorage();

  useEffect(() => {
    if (orgProfile?._id && projectOrgId) {
      if (orgProfile?._id !== projectOrgId) {
        dispatch(getOrgProfile(projectOrgId, true));
      }
    }
  }, [orgProfile, projectOrgId]);

  const { org_name: orgName, logo_url: orgLogo } = orgProfile;

  const initialNavItems = getSidebarNavItems(userPermissions, experiments);

  useEffect(() => {
    if (!isAuthenticated) {
      const payload = authHeader();
      dispatch(loginCheck(payload, history));
    }
  }, []);

  const backToProjects = () => {
    dispatch(setInitialProjectState());
    dispatch(clearUtilsData());
    dispatch(clearDashboardData());
    dispatch(clearPOData());
    dispatch(setNavbarTitle(''));
    dispatch(eventDispatch(GET_NOTIFICATIONS, []));
    removeLocalStorageValue(LOCAL_STORAGE_SEARCH_STRING);
    removeLocalStorageValue(TASK_FILTER);
  };

  return (
    <Suspense fallback={<CustomSpinner />}>
      <div className="layoutRoot">
        <CssBaseline />
        <Drawer
          variant="permanent"
          className="layoutDrawer"
          PaperProps={{
            style: {
              transition: 'width 450ms cubic-bezier(.4,0,0,1) 0ms',
              overflowX: 'hidden',
              width: theme.spacing(8),
            },
          }}
        >
          <div className="layoutToolbar">
            <Button
              component={Link}
              disableTouchRipple
              to="/projects"
              onClick={backToProjects}
            >
              {orgLogo ? (
                <img src={orgLogo} alt={orgName} className="layoutOrgLogo" />
              ) : (
                <Buildings className="layoutHomeIcon" />
              )}
            </Button>
          </div>

          <List className="layoutList">
            {initialNavItems.map((item: ModuleProps, index: number) => (
              <MenuDropdown
                key={`${item.to}-${item.title}-${index}`}
                item={item}
                isProjectRoute={true}
                index={index}
              />
            ))}
          </List>
          <small className="text-light text-center mt-auto">
            Version:
            <br /> {POWERPLAY_WEB_VERSION}
          </small>
        </Drawer>
        <main className="custom-scroll layoutContent">
          <MainNavbar noOrg={true} />
          <Outlet />
        </main>
      </div>
    </Suspense>
  );
}

export default ProjectLayout;
