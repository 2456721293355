import {
  GET_USER_PACKAGES_LIST,
  GET_USER_PACKAGES_NAV_ITEMS,
  KEY_SELECTED,
  GET_AGENTS,
  ADD_USER_TO_PACKAGE,
  AGENTS_LOADED,
  ASSIGN_USER,
  TOTAL_AGENT_COUNT,
  AGENT_COUNT_PENDING,
  AGENT_COUNT_RESET,
  ADD_ORG_TO_PACKAGE,
} from './UserPackage.type';
import { API_SUCCESS, API_ERROR, API_PENDING } from '../Generic.type';

const boqNavItems = [
  { key: 'AC Works' },
  { key: 'Branding' },
  { key: 'Civil Works' },
  { key: 'Electrical Works' },
  { key: 'Furniture' },
  { key: 'Glass Works' },
  { key: 'Gypsum Works' },
  { key: 'Loose Furniture/Bought Out Items' },
  { key: 'Networking & CCTV' },
  { key: 'Painting' },
  { key: 'Plumbing' },
  { key: 'Services' },
  { key: 'Soft Furnishing Works' },
];

const initialState = {
  pending: false,
  packageList: [],
  error: null,
  navItems: boqNavItems,
  initialKey: '',
  agents: [],
  isLoaded: false,
  isAssign: false,
  count: 0,
  countPending: false,
};

export default function userPackageReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case GET_USER_PACKAGES_LIST:
      return { ...state, packageList: payload.data };
    case API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case API_PENDING:
      return {
        ...state,
        pending: true,
      };
    case API_ERROR:
      return {
        ...state,
        pending: false,
        error: error,
      };
    case GET_USER_PACKAGES_NAV_ITEMS:
      return {
        ...state,
        navItems: payload,
      };
    case KEY_SELECTED:
      return {
        ...state,
        initialKey: payload,
      };
    case GET_AGENTS:
      return {
        ...state,
        agents: payload.data,
      };
    case ADD_USER_TO_PACKAGE:
      return state;
    case ADD_ORG_TO_PACKAGE:
      return state;
    case AGENTS_LOADED:
      return {
        ...state,
        isLoaded: true,
      };
    case ASSIGN_USER:
      return { ...state, isAssign: payload };
    case TOTAL_AGENT_COUNT:
      return { ...state, count: payload, countPending: false };
    case AGENT_COUNT_PENDING:
      return { ...state, countPending: true };
    case AGENT_COUNT_RESET:
      return { ...state, count: 0 };
    default:
      return state;
  }
}
