import {
  ADD_BOM_ITEM,
  GET_BOM_ITEMS,
  UPDATE_BOM_ITEM,
  DELETE_BOM_ITEM,
  BOM_API_SUCCESS,
  BOM_API_ERROR,
  BOM_API_PENDING,
  BOM_ITEM_ADDED,
  BOM_COUNT_FETCHED,
  GET_BOM_CHILD_PENDING,
  GET_BOM_CHILD,
  RESET_BOM_ITEM_ADDED,
  GET_BOM_SEARCH_ITEM,
  BOM_SEARCH_CHECK,
  GET_INDENT_BOM_SEARCH_ITEM,
  GET_BOMS_WITH_INVENTORY,
  GET_BOMS_WITH_INVENTORY_COUNT,
  SHOULD_UPDATE_BOM_ITEM,
  GET_MATERIALS,
  GET_ORG_MATERIALS_SEARCH,
  ADD_BULK_MATERIALS,
  ADVANCE_SEARCH,
  ADVANCE_SEARCH_COUNT,
  GET_BOM_INFO,
  SET_BOM_INFO_DEFAULT,
  ADVANCE_SEARCH_INVENTORY,
  GET_BOM_SEARCH_ITEM_ORG,
} from './Bom.type';
import { ReducerProps } from '../../../../interfaces/Base';
import { BomProps } from '../../../../interfaces/Bom';
import { BOMProps } from '../../../../interfaces/PurchaseOrder';

const initialPendingState = {
  getBomChildrenPending: false,
};
const initialState: {
  pending: boolean;
  bomItems: BomProps[];
  error: any;
  uploadError: any;
  bomCount: number;
  bomItemAdded: boolean;
  addedItem: any;
  bomChild: any;
  updatedBom: any;
  getBomChildrenPending: any;
  bomOption: any;
  bomOptionOrg: BOMProps[];
  bomCheck: boolean;
  indentBomItems: any;
  bomsWithInventory: any;
  materials: any;
  addMaterials: any;
  selectedBomInfo: any;
  searchCount: number;
  inventoriesBomCount: number;
  materialSearchOrg: BOMProps[];
  linkedLogExists: boolean;
} = {
  ...initialPendingState,
  pending: false,
  bomItems: [],
  error: null,
  uploadError: [],
  bomItemAdded: false,
  bomCount: 0,
  addedItem: {},
  bomChild: {},
  updatedBom: {},
  bomOption: [],
  bomOptionOrg: [],
  bomCheck: false,
  indentBomItems: [],
  bomsWithInventory: [],
  materials: [],
  addMaterials: [],
  selectedBomInfo: null,
  searchCount: null,
  inventoriesBomCount: 0,
  materialSearchOrg: [],
  linkedLogExists: false,
};

export default function baseReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
) {
  switch (type) {
    case ADD_BOM_ITEM:
      return {
        ...state,
        bomItems: [...state.bomItems, payload],
        addedItem: payload,
      };
    case GET_BOM_ITEMS:
      return { ...state, bomItems: payload };
    case GET_BOM_INFO:
      return {
        ...state,
        selectedBomInfo: payload,
      };
    case SET_BOM_INFO_DEFAULT: {
      return {
        ...state,
        selectedBomInfo: null,
      };
    }
    case UPDATE_BOM_ITEM:
      return { ...state, updatedBom: payload };
    case DELETE_BOM_ITEM:
      return {
        ...state,
        bomItems: state.bomItems.filter((elem: any) => elem._id !== payload.id),
      };
    case BOM_SEARCH_CHECK:
      return {
        ...state,
        bomCheck: payload,
      };

    case BOM_API_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case GET_BOM_SEARCH_ITEM:
      return { ...state, bomOption: payload };
    case GET_BOM_SEARCH_ITEM_ORG:
      return { ...state, bomOptionOrg: payload };
    case BOM_API_PENDING:
      return {
        ...state,
        pending: true,
        bomItemAdded: false,
      };
    case BOM_API_ERROR:
      return {
        ...state,
        pending: false,
        error: error?.response?.data?.message,
      };
    case BOM_ITEM_ADDED:
      return {
        ...state,
        bomItemAdded: payload,
      };
    case BOM_COUNT_FETCHED:
      return {
        ...state,
        bomCount: payload,
      };
    case GET_BOM_CHILD:
      return {
        ...state,
        bomChild: payload,
      };
    case GET_BOM_CHILD_PENDING:
      return {
        ...state,
        getBomChildrenPending: payload,
      };
    case RESET_BOM_ITEM_ADDED:
      return {
        ...state,
        addedItem: {},
      };
    case GET_INDENT_BOM_SEARCH_ITEM:
      return {
        ...state,
        indentBomItems: payload,
      };

    case GET_BOMS_WITH_INVENTORY: {
      return {
        ...state,
        bomsWithInventory: payload,
      };
    }
    case GET_BOMS_WITH_INVENTORY_COUNT: {
      return {
        ...state,
        inventoriesBomCount: payload?.count || 0,
      };
    }
    case GET_MATERIALS:
    case ADVANCE_SEARCH: {
      return {
        ...state,
        materials: payload,
      };
    }
    case GET_ORG_MATERIALS_SEARCH: {
      return {
        ...state,
        materialSearchOrg: payload?.docs || [],
      };
    }
    case ADD_BULK_MATERIALS: {
      return {
        ...state,
        addMaterials: payload,
      };
    }
    case ADVANCE_SEARCH_COUNT: {
      return {
        ...state,
        searchCount: payload,
      };
    }
    case ADVANCE_SEARCH_INVENTORY: {
      return {
        ...state,
        bomsWithInventory: payload?.docs || {},
      };
    }
    case SHOULD_UPDATE_BOM_ITEM: {
      return {
        ...state,
        linkedLogExists: payload ?? false,
      };
    }
    default:
      return state;
  }
}
