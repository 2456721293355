import { Dispatch } from 'redux';

import apiClient from '../../../../utils/ApiClient.util';
import {
  ADD_BOM_ITEM,
  GET_BOM_ITEMS,
  UPDATE_BOM_ITEM,
  DELETE_BOM_ITEM,
  BOM_API_SUCCESS,
  BOM_API_ERROR,
  BOM_API_PENDING,
  BOM_ITEM_ADDED,
  BOM_COUNT_FETCHED,
  GET_BOM_CHILD,
  GET_BOM_CHILD_PENDING,
  RESET_BOM_ITEM_ADDED,
  BOM_SEARCH_CHECK,
  GET_BOM_SEARCH_ITEM,
  GET_INDENT_BOM_SEARCH_ITEM,
  GET_BOMS_WITH_INVENTORY,
  GET_MATERIALS,
  ADD_BULK_MATERIALS,
  ADVANCE_SEARCH,
  ADVANCE_SEARCH_COUNT,
  GET_BOMS_WITH_INVENTORY_COUNT,
  ADVANCE_SEARCH_INVENTORY,
  GET_BOM_SEARCH_ITEM_ORG,
  GET_ORG_MATERIALS_SEARCH,
  SHOULD_UPDATE_BOM_ITEM,
} from './Bom.type';
import { API_VERSIONS, BOM_APIS } from '../../../../routes.constant';
import {
  toastSuccessMessage,
  toastErrorMessage,
} from '../../../../utils/Toast.util';
import {
  BomProps,
  ElasticSearchBOMListRequestParams,
  getBomListProps,
} from '../../../../interfaces/Bom';
import { parseString } from '../../../../utils/Common.util';
import { eventDispatch } from '../../../base';
import { getRequest, postRequest } from '../../../../apiClient';
import { customStringify } from '../../../../utils/Url.util';

import {
  AddBulkMaterialPayload,
  AdvanceFilterProps,
  MaterialFilterProps,
} from '../../../../interfaces/Materials';
import { SYNC_INVENTORY_VIEW } from '../../common/refresh/Refresh.type';

export function getBOMListRequest(params: getBomListProps) {
  const apiUrl = parseString(
    BOM_APIS.getBOMListRequest,
    customStringify(params)
  );

  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(BOM_API_PENDING));
    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(BOM_API_SUCCESS));
      dispatch(eventDispatch(GET_BOM_ITEMS, data));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
}

export const getElasticSearchBOMListRequest =
  (params: ElasticSearchBOMListRequestParams) => async (dispatch: Dispatch) => {
    try {
      const apiUrl = parseString(
        BOM_APIS.getInventoriesWithBom,
        customStringify(params)
      );
      dispatch(eventDispatch(BOM_API_PENDING));
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(BOM_API_SUCCESS));
      dispatch(eventDispatch(GET_BOM_SEARCH_ITEM, data));
      dispatch(eventDispatch(BOM_SEARCH_CHECK, true));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };

export const getEsInventoryBom = params => async (dispatch: Dispatch) => {
  try {
    const apiUrl = parseString(
      BOM_APIS.getEsInventoryBom,
      customStringify(params)
    );
    dispatch(eventDispatch(BOM_API_PENDING));
    const { data } = await apiClient.get(apiUrl);
    dispatch(eventDispatch(BOM_API_SUCCESS));
    dispatch(eventDispatch(GET_BOM_SEARCH_ITEM, data));
    dispatch(eventDispatch(BOM_SEARCH_CHECK, true));
  } catch (error) {
    dispatch(eventDispatch(BOM_API_ERROR, error));
  }
};

export const getEsBomInventory =
  (projectId: string, text: string) => async (dispatch: Dispatch) => {
    try {
      const apiUrl = parseString(
        BOM_APIS.getEsBomsWithInventory,
        customStringify({
          project_id: projectId,
          text,
        })
      );
      dispatch(eventDispatch(BOM_API_PENDING));
      const { data } = await getRequest(apiUrl, API_VERSIONS.V2);
      dispatch(eventDispatch(BOM_API_SUCCESS));
      dispatch(eventDispatch(GET_BOM_SEARCH_ITEM_ORG, data?.docs));
      dispatch(eventDispatch(BOM_SEARCH_CHECK, true));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };

export function getSearchBOMListRequest(params: getBomListProps) {
  const apiUrl = parseString(
    BOM_APIS.getSearchBOMListRequest,
    customStringify(params)
  );
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(BOM_API_PENDING));
    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(BOM_API_SUCCESS));
      dispatch(eventDispatch(GET_BOM_ITEMS, data));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
}

export function getRunningBomListRequest(params: {
  project_id: string;
  text: string;
}) {
  const apiUrl = parseString(BOM_APIS.getRunningBoms, customStringify(params));
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(BOM_API_PENDING));
    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(BOM_API_SUCCESS));
      dispatch(eventDispatch(GET_BOM_ITEMS, data));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
}

export function bomFormAddedItemState(state: boolean) {
  return (dispatch: Dispatch) => {
    dispatch(eventDispatch(BOM_API_PENDING));
    try {
      dispatch(eventDispatch(BOM_API_SUCCESS));
      dispatch(eventDispatch(BOM_ITEM_ADDED, state));
    } catch {
      dispatch(eventDispatch(BOM_API_ERROR));
    }
  };
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function postBomData(reqBody: BomProps, callback = (val: any) => {}) {
  return async (dispatch: Dispatch<any>, getState: any) => {
    const orgProfile = getState().organisationReducer.orgProfile;
    const { _id: orgId } = orgProfile;
    dispatch(eventDispatch(BOM_API_PENDING));
    try {
      const { data } = await apiClient.post(BOM_APIS.postBomData, reqBody);
      dispatch(eventDispatch(BOM_API_SUCCESS));
      toastSuccessMessage('Successfully added BoM Item');
      dispatch(eventDispatch(ADD_BOM_ITEM, data));
      dispatch(bomFormAddedItemState(true));
      dispatch(
        getBOMListRequest({
          org_id: orgId,
          sort_option: '-createdAt',
          page: 0,
        })
      );
      callback(data);
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
      let errorMessage = 'Something went wrong';
      if (error.isAxiosError) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
      }
      toastErrorMessage(errorMessage);
    }
  };
}

export function updateBom(bomId: string, reqBody: BomProps) {
  delete reqBody._id;
  const apiUrl = parseString(BOM_APIS.updateBom, bomId);
  return async (dispatch: Dispatch<any>, getState: any) => {
    const orgId = getState().organisationReducer.orgProfile._id;
    dispatch(eventDispatch(BOM_API_PENDING));
    try {
      const { data } = await apiClient.put(apiUrl, reqBody);
      dispatch(eventDispatch(BOM_API_SUCCESS));
      toastSuccessMessage('Successfully updated BoM Item');
      dispatch(eventDispatch(UPDATE_BOM_ITEM, data));
      dispatch(bomFormAddedItemState(true));
      dispatch(
        getBOMListRequest({
          org_id: orgId,
          sort_option: '-createdAt',
        })
      );
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));

      let errorMessage = 'Something went wrong';
      if (error.isAxiosError) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
      }

      toastErrorMessage(errorMessage);
    }
  };
}

export function resetAddedItem() {
  return (dispatch: Dispatch) => dispatch({ type: RESET_BOM_ITEM_ADDED });
}

export function deleteBomItem(
  bomId: string,
  query?: {
    org_id: string;
  }
) {
  const apiUrl = parseString(
    BOM_APIS.deleteBomItem,
    bomId,
    customStringify(query)
  );
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apiClient.delete(apiUrl);
      if (data.status && data.status === 'error') {
        toastErrorMessage(data.message);
        dispatch(eventDispatch(BOM_API_ERROR, data.message));
      } else {
        dispatch(eventDispatch(BOM_API_SUCCESS));
        dispatch(eventDispatch(DELETE_BOM_ITEM, data));
      }
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
}

export function getBOMCount(params: { org_id: string; [key: string]: any }) {
  const {
    org_id: orgId,
    text,
    show_archived: showArchived,
    ...remainingFilters
  } = params;
  const apiUrl = parseString(
    BOM_APIS.getBomCount,
    customStringify({
      org_id: orgId,
      text,
      show_archived: showArchived,
      dFilter: JSON.stringify(remainingFilters),
    })
  );
  return async (dispatch: Dispatch) => {
    try {
      const {
        data: { count },
      } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(BOM_API_SUCCESS));
      dispatch(eventDispatch(BOM_COUNT_FETCHED, count));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
}

export function getBOMSearchCount(params: {
  org_id: string;
  [key: string]: any;
}) {
  const apiUrl = parseString(BOM_APIS.getEsBomCount, customStringify(params));
  return async (dispatch: Dispatch) => {
    try {
      const {
        data: { count },
      } = await getRequest(apiUrl);
      dispatch(eventDispatch(BOM_API_SUCCESS));
      dispatch(eventDispatch(BOM_COUNT_FETCHED, count));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
}

export function getBomChild(params: any) {
  const apiUrl = parseString(BOM_APIS.bomChildren, customStringify(params));
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_BOM_CHILD_PENDING, true));
    try {
      const { data } = await apiClient.get(apiUrl);
      toastSuccessMessage('Successfully fetched bom items');
      dispatch(eventDispatch(GET_BOM_CHILD_PENDING, false));
      dispatch(eventDispatch(GET_BOM_CHILD, data));
    } catch (error) {
      dispatch(eventDispatch(GET_BOM_CHILD_PENDING, false));
      toastErrorMessage('Error while fetching bom items');
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
}

export function resetBomChild() {
  return (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_BOM_CHILD, {}));
  };
}

export function indentRequestBomSearch(apiUrl: string, cb?: Function) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(GET_INDENT_BOM_SEARCH_ITEM, data));

      cb?.(data);
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
}
interface InterfaceWithBOMProps {
  page?: number;
  limit?: number;
  org_id?: string;
  project_id?: string;
  text?: string;
  filters: MaterialFilterProps;
}

export const getInventoriesWithBom = ({
  page,
  limit,
  org_id,
  project_id,
  text,
  filters,
}: InterfaceWithBOMProps) => {
  const apiUrl = parseString(
    BOM_APIS.getInventoriesWithBom,
    customStringify({
      project_id,
      org_id,
      page,
      limit,
      text,
      dFilter: JSON.stringify({ ...filters }),
    })
  );

  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_BOMS_WITH_INVENTORY, []));
    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(GET_BOMS_WITH_INVENTORY, data));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
};

export const getInventoriesWithBomCount = ({
  org_id,
  project_id,
  text,
  filters,
}: InterfaceWithBOMProps) => {
  const apiUrl = parseString(
    BOM_APIS.getInventoriesWithBomCount,
    customStringify({
      project_id,
      org_id,
      text,
      dFilter: JSON.stringify({ ...filters }),
    })
  );

  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(GET_BOMS_WITH_INVENTORY_COUNT, data));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
};

export const getMaterials =
  (projectId: string, page: number, limit: number, filters = {}) =>
  async (dispatch: Dispatch) => {
    try {
      const apiUrl = parseString(
        BOM_APIS.getMaterials,
        customStringify({
          project_id: projectId,
          page,
          limit,
          dFilter: JSON.stringify(filters),
        })
      );
      const { data } = await getRequest(apiUrl, API_VERSIONS.V2);
      dispatch(eventDispatch(GET_MATERIALS, data));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };

export const advanceSearchInventory = params => {
  return async (dispatch: Dispatch) => {
    try {
      const apiUrl = BOM_APIS.advanceSearchMaterial;
      const { data } = await postRequest(apiUrl, params);
      dispatch(eventDispatch(BOM_API_SUCCESS));
      dispatch(eventDispatch(ADVANCE_SEARCH_INVENTORY, { docs: data }));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
};

export const advanceSearchInventoryCount = (params: AdvanceFilterProps) => {
  return async (dispatch: Dispatch) => {
    try {
      const apiUrl = BOM_APIS.advanceSearchMaterialCount;
      const { data } = await postRequest(apiUrl, params);
      dispatch(eventDispatch(GET_BOMS_WITH_INVENTORY_COUNT, data?.count));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
};

export const addBulkMaterials = (
  orgId: string,
  params: AddBulkMaterialPayload
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const apiUrl = BOM_APIS.addBulkMaterials;
      const { data } = await postRequest(apiUrl, params, API_VERSIONS.V2);
      dispatch(eventDispatch(BOM_API_SUCCESS));
      dispatch(eventDispatch(ADD_BULK_MATERIALS, data));
      dispatch(eventDispatch(SYNC_INVENTORY_VIEW, true));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
};

export const advanceSearchMaterial = params => {
  return async (dispatch: Dispatch) => {
    try {
      const apiUrl = BOM_APIS.advanceSearchMaterial;
      const { data } = await postRequest(apiUrl, params);
      dispatch(eventDispatch(BOM_API_SUCCESS));
      dispatch(eventDispatch(ADVANCE_SEARCH, { docs: data }));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
};

export const advanceSearchMaterialCount = (params: AdvanceFilterProps) => {
  return async (dispatch: Dispatch) => {
    try {
      const apiUrl = BOM_APIS.advanceSearchMaterialCount;
      const { data } = await postRequest(apiUrl, params);
      dispatch(eventDispatch(ADVANCE_SEARCH_COUNT, data?.count));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
};

export const getOrgMaterialsWithSearch =
  (
    projectId: string,
    page: number,
    limit: number,
    text: string,
    filters = {}
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(eventDispatch(BOM_API_PENDING));
    try {
      const apiUrl = parseString(
        BOM_APIS.getMaterials,
        customStringify({
          project_id: projectId,
          text,
          page,
          limit,
          dFilter: JSON.stringify(filters),
        })
      );
      const { data } = await getRequest(apiUrl, API_VERSIONS.V2);
      dispatch(eventDispatch(BOM_API_SUCCESS));
      dispatch(eventDispatch(GET_ORG_MATERIALS_SEARCH, data));
    } catch (error) {
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
interface UpdateBomWarningProps {
  org_id: string;
  name: string;
  description?: string;
}
export const updateBomWarning = (
  bomId: string,
  params: UpdateBomWarningProps,
  cb?: Function
) => {
  return async (dispatch: Dispatch) => {
    try {
      const apiUrl = parseString(
        BOM_APIS.updateBomWarning,
        bomId,
        customStringify(params)
      );
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(SHOULD_UPDATE_BOM_ITEM, data?.linked_logs_exists));
      cb?.(data?.linked_logs_exists);
    } catch (error) {
      toastErrorMessage(error?.errorMsg || 'Error in verifying the material');
      dispatch(eventDispatch(BOM_API_ERROR, error));
    }
  };
};
