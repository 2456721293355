interface CookieOptions {
  expires?: string;
  path?: string;
  secure?: boolean;
  maxAge?: number;
}

export function setCookie(
  name: string,
  value: string,
  options: CookieOptions = {}
): void {
  let cookieString = `${name}=${value};`;
  if (options.maxAge) {
    options.expires = new Date(Date.now() + options.maxAge).toUTCString();
  }

  if (options.expires) {
    cookieString += `expires=${options.expires};`;
  }

  if (options.path) {
    cookieString += `path=${options.path};`;
  }

  if (options.secure) {
    cookieString += 'secure;';
  }

  document.cookie = cookieString;
}

export function getCookie(name: string): string {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return '';
}

export function removeCookie(name: string): void {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function removeAllCookies(): void {
  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {
    const [cookieName] = cookie.split('=');
    removeCookie(cookieName);
  }
}
