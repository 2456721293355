import { useEffect, useState } from 'react';
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Autocomplete,
} from '@mui/material';
import { HighlightOffOutlined } from '@mui/icons-material';
import * as yup from 'yup';
import AddIcon from '@mui/icons-material/Add';

import { OnboardingStepProps } from './types';
import {
  CustomButton,
  CustomDialogActions,
  CustomDialogContent,
  CustomMobileInput,
  CustomTextField,
} from '../common';
import { TEAM_ROLE_IDS } from '../../constant';
import { getTeams } from '../../redux/features/common/organisation';
import { validateForm } from '../../utils';
import { phoneRegExp } from '../../constants';
import { InviteOrgTeamParams } from '../../interfaces/Team';
import {
  createNewProjectAndSendInvites,
  sendProjectTeamInvites,
} from '../../redux/features/common/project';
import ProjectProps from '../../interfaces/Project';
import { track } from '../../redux/features/common/Segment.action';
import { useAppDispatch, useAppSelector } from '../../redux/Hooks';
import { ONBOARDING_SEGMENT } from '../../constant/segment_constant';

type formObj = {
  key: number;
  text: string;
  members?: {
    country_code: string;
    displayName: string;
    mobile_number: string;
    team_id: string;
  }[];
}[];

const schema = yup.array().of(
  yup.object({
    key: yup.number(),
    members: yup.array().of(
      yup.object({
        country_code: yup.string().required(),
        displayName: yup.string().required(),
        mobile_number: yup.string().matches(phoneRegExp),
        team_id: yup.string().required(),
      })
    ),
  })
);

const uniqueMember = new Set();

const InviteMembers = (props: OnboardingStepProps) => {
  const dispatch = useAppDispatch();
  const {
    onboardingQuestions,
    project,
    setOpen,
    setStep,
    startingStep = 0,
  } = props;

  const orgTeams = useAppSelector(state => state.organisationReducer.org_teams);
  const orgId = useAppSelector(
    state => state.organisationReducer.orgProfile._id
  );
  const orgName = useAppSelector(
    state => state.organisationReducer.orgProfile.org_name
  );
  const orgMembers = useAppSelector(state => state.organisationReducer.members);

  const [formObj, setFormObj] = useState<formObj>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [memberOption, setMemberOption] = useState([]);

  useEffect(() => {
    const data = orgMembers.map(item => {
      const id = `${item.displayName}-${item.team_id}-${item.mobile_number}`;
      uniqueMember.add(id);
      return {
        displayName: item.displayName,
        team_id: item.team_id,
        team_name: item.team_name,
        mobile_number: item.mobile_number,
      };
    });

    setMemberOption(data);
  }, []);

  const handleChange =
    (
      field: 'mobile_number' | 'displayName' | 'team_id',
      qIdx,
      mIdx,
      value = '',
      countryCode = '',
      mobileNumber = '',
      team = ''
    ) =>
    (...args) => {
      const tmp = [...formObj];
      const obj = tmp[qIdx];
      if (!obj) return;

      const rec = obj?.members[mIdx];
      if (!rec) return;

      if (field === 'mobile_number') {
        rec.country_code = args[0];
        rec.mobile_number = mobileNumber || args[1];
        if (
          rec.mobile_number.length >= 10 &&
          rec.team_id !== '' &&
          rec.displayName !== ''
        ) {
          const id = `${rec.displayName}-${rec.team_id}-${rec.mobile_number}`;

          if (!uniqueMember.has(id)) {
            uniqueMember.add(id);
            const data = {
              displayName: rec.displayName,
              team_id: rec.team_id,
              team_name: rec.team_id,
              mobile_number: rec.mobile_number,
            };

            const val = [...memberOption, data];
            setMemberOption(val);
          }
        }
        dispatch(track('onboarding_phone_entered', { phone: rec }));
      } else {
        const eventName =
          field === 'displayName'
            ? 'onboarding_name_entered'
            : 'onboarding_designation_entered';

        rec['mobile_number'] = mobileNumber || '';
        rec['country_code'] = countryCode || '+91';

        if (field === 'team_id' && args.length === 2) {
          team = args[0].target.value;
        }
        rec['team_id'] = team || '';
        if (field === 'displayName') rec[field] = value;

        const data = field === 'displayName' ? value : team;
        dispatch(track(eventName, { data }));
      }

      setErrors([]);
      setFormObj(tmp);
    };

  const handleNameChange =
    (
      field: 'mobile_number' | 'displayName' | 'team_id',
      qIdx: any,
      mIdx: any
    ) =>
    (...args) => {
      const value = args[1] || '';
      let selectedOrgMember = orgMembers.find(
        member => member.displayName === value
      );

      if (!selectedOrgMember) {
        selectedOrgMember = memberOption.find(
          member => member.displayName === value
        );
      }

      const countryCode = selectedOrgMember?.country_code || '+91';
      const mobileNumber = selectedOrgMember?.mobile_number || '';
      const teamId = selectedOrgMember?.team_id || '';
      handleChange(
        field,
        qIdx,
        mIdx,
        value,
        countryCode,
        mobileNumber,
        teamId
      )(args);
    };

  useEffect(() => {
    setFormObj(
      onboardingQuestions
        .map(q =>
          q.actions.map(obj => ({
            ...obj,
            members: [
              {
                country_code: '+91',
                mobile_number: '',
                displayName: '',
                team_id: '',
              },
            ],
          }))
        )
        .flat()
    );
  }, [onboardingQuestions]);

  const allQuestions = onboardingQuestions.map(q => q.actions).flat();

  const handleAddMore = idx => () => {
    const tmp = [...formObj];

    const obj = tmp[idx];
    if (!obj) return;
    obj.members = obj.members || [];

    obj?.members.push({
      country_code: '+91',
      mobile_number: '',
      displayName: '',
      team_id: '',
    });

    setFormObj(tmp);
    dispatch(track('onboarding_add_member_click'));
  };

  const handleRemove = (i, y) => () => {
    const tmp = [...formObj];

    const obj = tmp[i];

    obj?.members?.splice(y, 1);
    setFormObj(tmp);
  };

  function getOrgTeams() {
    if (orgId) {
      dispatch(
        getTeams({
          org_id: orgId,
        })
      );
    }
  }

  const handleSubmit = async () => {
    const { valErrors, isError } = await validateForm(schema, formObj);

    if (isError) {
      setErrors(valErrors);
      return;
    }

    const mobInviteMap = formObj.reduce((map, obj) => {
      for (const mem of obj.members) {
        if (map.has(mem.mobile_number)) {
          const actions = map.get(mem.mobile_number).actions;

          actions.push(obj.key);
          map.get(mem.mobile_number).actions = Array.from(new Set(actions));
        } else {
          map.set(mem.mobile_number, { ...mem, actions: [obj.key] });
        }
      }

      return map;
    }, new Map());

    const reqBody: InviteOrgTeamParams = {
      users: Array.from(mobInviteMap.values()),
    };

    if (project.id) {
      dispatch(sendProjectTeamInvites(reqBody, project.id));
    } else if (project.name) {
      const newProject: ProjectProps = {
        name: project.name,
        org_id: orgId,
        org_name: orgName,
      };

      dispatch(createNewProjectAndSendInvites(newProject, reqBody));
    }

    dispatch(
      track(ONBOARDING_SEGMENT.ONBOARDING_INVITE_MEMBERS_CLICK, {
        val: formObj,
      })
    );
    setOpen(false);
    setStep(startingStep);
  };

  return (
    <>
      <CustomDialogContent>
        {formObj.map((obj, qidx) => (
          <>
            <div className="py-3">
              <div className="fw-bold divide">{`${qidx + 1}. ${obj.text}`}</div>
              <Grid container spacing={2} className="mt-2">
                {obj?.members?.map((v, mIdx) => {
                  const [nameErr, teamErr, numErr] = [
                    errors.includes(`[${qidx}].members[${mIdx}].displayName`),
                    errors.includes(`[${qidx}].members[${mIdx}].team_id`),
                    errors.includes(`[${qidx}].members[${mIdx}].mobile_number`),
                  ];

                  return (
                    <>
                      <Grid item xs={4}>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          options={memberOption.map(
                            option => option.displayName
                          )}
                          onInputChange={handleNameChange(
                            'displayName',
                            qidx,
                            mIdx
                          )}
                          renderInput={(params: any) => (
                            <CustomTextField
                              {...params}
                              placeholder="Enter Name"
                              error={nameErr}
                              helperText={nameErr ? 'required' : null}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth error={teamErr}>
                          <Select
                            fullWidth
                            onOpen={getOrgTeams}
                            variant="outlined"
                            placeholder="Role"
                            classes={{ root: 'typography-base' }}
                            name="team_id"
                            onChange={handleChange('team_id', qidx, mIdx)}
                            value={v.team_id || 'placeholder'}
                          >
                            <MenuItem value="placeholder" disabled>
                              <span className="light">Select Role</span>
                            </MenuItem>
                            {orgTeams.map(team => (
                              <MenuItem value={team._id} key={team._id}>
                                <span className="typography-base">
                                  {team.template_id === TEAM_ROLE_IDS.ADMIN
                                    ? 'Admin'
                                    : team.team_name}
                                </span>
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {teamErr ? 'required' : null}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <CustomMobileInput
                          variant="outlined"
                          size="small"
                          fullWidth
                          defaultCountry={'in'}
                          inputProps={{ style: { padding: 8 } }}
                          placeholder="Mobile Number"
                          error={numErr}
                          value={
                            `${v.country_code} ${v.mobile_number}` ||
                            'placeholder'
                          }
                          helperText={
                            numErr ? 'Not a valid mobile number' : null
                          }
                          onChange={handleChange('mobile_number', qidx, mIdx)}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          size="small"
                          className="mt-1"
                          onClick={handleRemove(qidx, mIdx)}
                        >
                          <HighlightOffOutlined fontSize="small" />
                        </IconButton>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
              <CustomButton
                label={'Add another member'}
                className="mt-3 fes-m fw-bold"
                startIcon={<AddIcon />}
                buttonSize="small"
                classes={{ root: 'px-0' }}
                variant="text"
                onClick={handleAddMore(qidx)}
              />
            </div>
            {qidx < allQuestions.length - 1 && <Divider />}
          </>
        ))}
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton label={'Invite Members'} onClick={handleSubmit} />
      </CustomDialogActions>
    </>
  );
};

export default InviteMembers;
