import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TreeStateIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...(props as any)}
  >
    <path d="M5.83423 8.33301L10.0009 12.4997L14.1676 8.33301H5.83423Z" />
  </SvgIcon>
);

export const PlaylistAddAboveIcon = (props: SvgIconProps) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M8 6H14.5V8H8V6Z" />
    <path d="M5.5 10.25H14.5V11.5H5.5V10.25Z" />
    <path d="M6 7H4V5H3V7H1V8H3V10H4V8H6V7Z" />
  </SvgIcon>
);
