import useSessionStorage from '../../utils/hooks/useSessionStorage.hook';

const lazyRetry = <T extends React.ComponentType<any>>(
  componentImport: () => Promise<{ default: T }>,
  name: string
): Promise<{ default: T }> => {
  const keyName: string = `retry-${name}-refreshed`;

  const { getSessionStorageValue, setSessionStorageValue } =
    useSessionStorage();

  return new Promise((resolve, reject) => {
    const hasRefreshed = getSessionStorageValue(keyName, true) || false;

    componentImport()
      .then(component => {
        setSessionStorageValue(keyName, 'false');
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          setSessionStorageValue(keyName, 'true');
          return window?.location?.reload();
        }
        reject(error);
      });
  });
};

export default lazyRetry;
