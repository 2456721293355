export const ONBOARDING_CREATE_ORG_AND_PROJECT_PENDING =
  'ONBOARDING_CREATE_PROJECT_PENDING';
export const ONBOARDING_API_ERROR = 'ONBOARDING_API_ERROR';
export const MOBILE_NUMBER_ERROR = 'MOBILE_NUMBER_ERROR';
export const SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP';
export const SET_NEW_ONBOARDING_STEP = 'SET_NEW_ONBOARDING_STEP';
export const SET_INTERNAL_STEP = 'SET_INTERNAL_STEP';
export const SET_INTERNAL_SUBSTEP = 'SET_INTERNAL_SUBSTEP';
export const SET_NEW_ONBOARDING_QUESTIONS = 'SET_NEW_ONBOARDING_QUESTIONS';
export const SET_ANSWER = 'SET_ANSWER';
export const SET_MODULE = 'SET_MODULE';
export const SET_PROJECT = 'SET_PROJECT';
export const SET_QUESTION_LOADING = 'SET_QUESTION_LOADING';
export const SET_IS_PROJECT_SELECTION = 'SET_IS_PROJECT_SELECTION';
export const SET_NO_QUESTION_LEVEL = 'SET_NO_QUESTION_LEVEL';
export const SET_STEP_AND_SUBSTEP = 'SET_STEP_AND_SUBSTEP';
