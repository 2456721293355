import { SHEET_EXPORT_API_PENDING } from './Export.type';

import { ReducerProps } from '../../../../interfaces/Base';

type exportProps = {
  loading: boolean;
};

const initialState: exportProps = {
  loading: false,
};

export type exportReducerProps = typeof initialState;

export default function exportReducer(
  state = initialState,
  { type, payload }: ReducerProps
): exportReducerProps {
  switch (type) {
    case SHEET_EXPORT_API_PENDING:
      return {
        ...state,
        loading: payload,
      };

    default:
      return state;
  }
}
