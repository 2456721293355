export const GET_PROJECT_PLAN_ITEMS = 'GET_PROJECT_PLAN_ITEMS';
export const GET_PROJECT_PLAN_SUMMARY = 'GET_PROJECT_PLAN_SUMMARY';
export const PROJECT_PLAN_API_SUCCESS = 'PROJECT_PLAN_API_SUCCESS';
export const PROJECT_PLAN_API_ERROR = 'PROJECT_PLAN_API_ERROR';
export const PROJECT_PLAN_API_PENDING = 'PROJECT_PLAN_API_PENDING';
export const PROJECT_PLAN_UPLOAD_ERROR = 'PROJECT_PLAN_UPLOAD_ERROR';
export const GET_GANTT_CHART_DATA = 'GET_GANTT_CHART_DATA';
export const GET_GANTT_CHART_DATA_PENDING = 'GET_GANTT_CHART_DATA_PENDING';
export const GET_IMAGE_LIST = 'GET_IMAGE_LIST';
export const GET_IMAGE_LIST_PENDING = 'GET_IMAGE_LIST_PENDING';
export const SET_SHOW_WRP_REF_DIALOG = 'SET_SHOW_WRP_REF_DIALOG';
export const SET_APARTMENT_TEMPLATE = 'SET_APARTMENT_TEMPLATE';
export const SET_APARTMENT_TEMPLATE_PENDING = 'SET_APARTMENT_TEMPLATE_PENDING';
