import { useState, useEffect } from 'react';

// debounce hook
export function useDebounce(value: string, wait = 0): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const tid = setTimeout(() => setDebouncedValue(value), wait);

    return () => clearTimeout(tid);
  }, [value]);

  return debouncedValue;
}

export function useDebounceFunc(callback: any, delay: number) {
  let timeout: any;

  return function () {
    const context: any = this,
      args = arguments;
    const later = function () {
      timeout = null;
      callback.apply(context, args);
    };
    const callNow = !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, delay);

    if (callNow) {
      callback.apply(context, args);
    }
  };
}
