import { useEffect, useState } from 'react';
import { Box, Dialog, Grid } from '@mui/material';

import useActionAllowed from '../../utils/hooks/useActionAllowed.hook';
import { CustomDialogContent } from './CustomDialog';
import {
  getOrgAdmins,
  sendAccessRequestEmail,
} from '../../redux/features/common/organisation';
import UserProps from '../../interfaces/User';
import CustomButton from './customButton/CustomButton';
import { useAppDispatch, useAppSelector } from '../../redux/Hooks';
import { track } from '../../redux/features/common/Segment.action';
import { SEGMENTS_USER_PERMISSION } from '../../constant/segment_constant';
import { MODULE_NAME_MAP } from '../../constant/module_constants';
import {
  CreateBlockedIcon,
  DeleteBlockedIcon,
  EditBlockedIcon,
} from './Icons/Icons';

interface ActionProps {
  resource_id: string;
  action: 'view' | 'edit' | 'create' | 'delete';
  children?: any;
  renderNoAccess?: null | JSX.Element | React.ReactNode;
}
interface NoAcessProps {
  action: 'view' | 'edit' | 'create' | 'delete';
  children: JSX.Element | null | React.ReactNode;
  resource_id: string;
}

const RenderNoAccess = ({ children, action, resource_id }: NoAcessProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const handleOpen = val => () => {
    if (open === false) {
      const eventProps = {
        module_name: MODULE_NAME_MAP[resource_id],
        denied_access_type: action,
        module_id: resource_id,
      };
      dispatch(track(SEGMENTS_USER_PERMISSION.ACCESS_DENIED_POPUP, eventProps));
    }
    setOpen(val);
  };

  const orgAdmins: UserProps[] = useAppSelector(
    state => state.organisationReducer.orgAdmins
  );
  const orgId = useAppSelector(
    state => state.organisationReducer.orgProfile._id
  );

  const teamName = useAppSelector(state => state.userreducer.user.team_name);

  useEffect(() => {
    if (!orgAdmins?.length && orgId) {
      dispatch(getOrgAdmins(orgId));
    }
  }, [orgAdmins, orgId]);

  const renderIcon = () => {
    const props = { width: 250 };
    switch (action) {
      case 'create':
        return <CreateBlockedIcon {...props} />;
      case 'delete':
        return <DeleteBlockedIcon {...props} />;
      case 'edit':
        return <EditBlockedIcon {...props} />;
    }
  };

  const handleClick = receiverId => () => {
    dispatch(sendAccessRequestEmail(receiverId, action, resource_id));
    const eventProps = {
      admin_user_id: receiverId,
      denied_access_type: action,
      module_id: resource_id,
      team_name: teamName,
    };

    dispatch(
      track(
        SEGMENTS_USER_PERMISSION.ACCESS_DENIED_RAISE_REQUEST_CLICK,
        eventProps
      )
    );
  };

  return (
    <>
      <Box
        display={'inline-block'}
        onClick={handleOpen(true)}
        className="disabled-wrapper"
      >
        <span className="badge">{children}</span>
      </Box>

      <Dialog open={open} maxWidth={'sm'} fullWidth onClose={handleOpen(false)}>
        <CustomDialogContent className="text-center">
          {renderIcon()}
          <div className="text-capitalize fw-bolder fs-xxxxl">{`${action} Access Denied`}</div>
          <div className="text-default fw-lighter fes-l">
            {`Since you don't have permission, you can't ${action} details`}
          </div>

          <div className="mt-4 fw-bold fs-xl">
            {'Request access from site admin'}
          </div>

          <Box
            maxHeight={250}
            overflow={'scroll'}
            className="hide-scrollbar mt-2"
          >
            <Grid container justifyContent="space-evenly">
              {orgAdmins
                .filter(usr => Boolean(usr.email))
                .map(user => (
                  <Grid
                    item
                    xs={5}
                    className="text-left d-flex align-items-center justify-content-between my-2 p-2 py-3 rounded border-grey-300 cbg-blue-grey-100"
                    key={user._id}
                  >
                    <div>
                      <div className="fw-bold fes-l">{user.displayName}</div>
                      <div>{`${user.country_code} ${user.mobile_number}`}</div>
                    </div>
                    <CustomButton
                      label={'Raise Request'}
                      variant="text"
                      className="fw-bold"
                      buttonSize="small"
                      size="small"
                      onClick={handleClick(user._id)}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </CustomDialogContent>
      </Dialog>
    </>
  );
};

const Action = ({
  resource_id,
  action,
  children,
  renderNoAccess: customNoAccess = null,
}: ActionProps) => {
  const permitted = useActionAllowed(resource_id, action);

  if (permitted) {
    return children;
  }

  if (customNoAccess) {
    return customNoAccess;
  }

  return (
    <RenderNoAccess action={action} resource_id={resource_id}>
      {children}
    </RenderNoAccess>
  );
};

export default Action;
