import { Dispatch } from 'redux';

import apiClient from '../../../../utils/ApiClient.util';
import {
  CREATE_THREAD_PENDING,
  GET_THREAD_PENDING,
  GET_THREAD,
  GET_THREADS_LIST_PENDING,
  GET_THREADS_LIST,
  UPDATE_THREAD_PENDING,
  DELETE_THREAD_PENDING,
  THREAD_API_ERROR,
  CLOSE_THREAD_PENDING,
  CREATE_THREAD_WITH_ITEM_PENDING,
  GET_THREADS_WITH_ITEM_PENDING,
  GET_THREADS_WITH_ITEM_LIST,
  CLEAR_THREAD_DETAILS,
} from './Thread.type';
import {
  DELETE_COMMENT_PENDING,
  UPLOAD_COMMENT_ATTACHED_FILES,
} from '../../common/comment/Comment.type';
import {
  toastSuccessMessage,
  toastErrorMessage,
} from '../../../../utils/Toast.util';
import { THREAD_APIS, COMMENT_APIS } from '../../../../routes.constant';
import { THREAD_SEGMENT } from '../../../../constant/segment_constant';
import { track } from '../../common/Segment.action';
import IssuesProps, {
  getThreadsListParamProps,
  GetThreadsWithItemParamProps,
} from '../../../../interfaces/Issues';
import { parseString } from '../../../../utils/Common.util';
import { CommentProps } from '../../../../interfaces/Base';
import { eventDispatch } from '../../../base';
import {
  SYNC_ISSUE,
  SYNC_TASK_PROFILE,
} from '../../common/refresh/Refresh.type';
import { customStringify } from '../../../../utils/Url.util';

export function getThreadsList(params: getThreadsListParamProps) {
  const apiUrl = parseString(
    THREAD_APIS.getThreadsList,
    customStringify(params)
  );

  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_THREADS_LIST_PENDING, true));

    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(GET_THREADS_LIST_PENDING, false));
      dispatch(eventDispatch(GET_THREADS_LIST, data));
    } catch (error) {
      dispatch(eventDispatch(GET_THREADS_LIST_PENDING, false));
      dispatch(eventDispatch(THREAD_API_ERROR, error));
    }
  };
}

export function createThread(reqBody: IssuesProps, pageSource: string) {
  const apiUrl = THREAD_APIS.createThread;

  return async (dispatch: Dispatch<any>, getState: any) => {
    dispatch(eventDispatch(CREATE_THREAD_PENDING, true));

    const projectId = getState().projectreducer.selectedProj.id;
    const orgId = getState().organisationReducer.orgProfile._id;

    try {
      await apiClient.post(apiUrl, reqBody);
      dispatch(
        track(THREAD_SEGMENT.THREAD_CREATE_SUCCESS, {
          page_source: pageSource,
        })
      );
      dispatch(eventDispatch(CREATE_THREAD_PENDING, false));
      dispatch(getThreadsList({ project_id: projectId, org_id: orgId }));
      toastSuccessMessage('Issue created successfully');
    } catch (error) {
      dispatch(
        track(THREAD_SEGMENT.THREAD_CREATE_ERROR, {
          page_source: pageSource,
        })
      );
      dispatch(eventDispatch(CREATE_THREAD_PENDING, false));
      dispatch(eventDispatch(THREAD_API_ERROR, error));
      toastErrorMessage('Error in Creating Issue');
    }
  };
}

export function getThreadsWithItem(params: GetThreadsWithItemParamProps) {
  const apiUrl = parseString(
    THREAD_APIS.getThreadsWithItem,
    customStringify(params)
  );
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_THREADS_WITH_ITEM_PENDING, true));
    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(GET_THREADS_WITH_ITEM_PENDING, false));
      dispatch(eventDispatch(GET_THREADS_WITH_ITEM_LIST, data));
    } catch (error) {
      dispatch(eventDispatch(GET_THREADS_WITH_ITEM_PENDING, false));
      dispatch(eventDispatch(THREAD_API_ERROR, error));
    }
  };
}

export function createThreadWithItem(reqBody: IssuesProps, pageSource: string) {
  const apiUrl = parseString(
    THREAD_APIS.createThreadWithItem,
    customStringify({ item_id: reqBody.item_id })
  );

  return async (dispatch: Dispatch<any>, getState: any) => {
    dispatch(eventDispatch(CREATE_THREAD_WITH_ITEM_PENDING, true));
    const taskId = getState().taskreducer.selectedTask._id;
    const org_id = getState().organisationReducer.orgProfile._id;
    const project_id = getState().projectreducer.projectProfile._id;

    reqBody = { ...reqBody, org_id, project_id };

    try {
      await apiClient.post(apiUrl, reqBody);
      dispatch(
        track(THREAD_SEGMENT.THREAD_CREATE_SUCCESS, {
          taskId,
          page_source: pageSource,
        })
      );
      dispatch(eventDispatch(CREATE_THREAD_WITH_ITEM_PENDING, false));
      dispatch(getThreadsWithItem({ item_id: taskId, org_id }));
      dispatch(eventDispatch(SYNC_TASK_PROFILE, true));
      toastSuccessMessage('Issue with item created successfully');
    } catch (error) {
      dispatch(
        track(THREAD_SEGMENT.THREAD_CREATE_ERROR, {
          taskId,
          page_source: pageSource,
        })
      );
      dispatch(eventDispatch(CREATE_THREAD_WITH_ITEM_PENDING, false));
      dispatch(eventDispatch(THREAD_API_ERROR, error));
      toastErrorMessage('Error in Creating Issue with item');
    }
  };
}
export function getThread(id: string, addedComment: boolean = false) {
  const apiUrl = parseString(THREAD_APIS.getThread, id);
  return async (dispatch: Dispatch) => {
    if (!addedComment) {
      dispatch(eventDispatch(GET_THREAD_PENDING, true));
    }

    dispatch(eventDispatch(CLEAR_THREAD_DETAILS, null));

    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(GET_THREAD, data));
      dispatch(eventDispatch(GET_THREAD_PENDING, false));
      dispatch(eventDispatch(SYNC_ISSUE, true));
    } catch (error) {
      dispatch(eventDispatch(GET_THREAD_PENDING, false));
      dispatch(eventDispatch(THREAD_API_ERROR, error));
    }
  };
}

export function updateThread(reqBody: IssuesProps, cb?: Function) {
  const apiUrl = parseString(THREAD_APIS.updateThread, reqBody._id);
  return async (dispatch: Dispatch<any>, getState: any) => {
    const projectId = getState().projectreducer.projectProfile._id;

    dispatch(eventDispatch(UPDATE_THREAD_PENDING, true));
    try {
      await apiClient.put(apiUrl, reqBody);
      dispatch(eventDispatch(UPDATE_THREAD_PENDING, false));
      dispatch(track(THREAD_SEGMENT.THREAD_EDIT_SUCCESS));
      toastSuccessMessage('Successfully updated Issue');
      dispatch(getThread(reqBody._id));
      dispatch(getThreadsList({ project_id: projectId }));
      cb?.();
    } catch (error) {
      dispatch(eventDispatch(THREAD_API_ERROR, error));
      dispatch(track(THREAD_SEGMENT.THREAD_EDIT_ERROR));
      toastErrorMessage('Error in updating Issue');
    }
  };
}

export function deleteThread(id: string) {
  const apiUrl = parseString(THREAD_APIS.deleteThread, id);

  return async (dispatch: Dispatch<any>, getState: any) => {
    dispatch(eventDispatch(DELETE_THREAD_PENDING, true));
    const projectId = getState().projectreducer.selectedProj.id;
    const orgId = getState().organisationReducer.orgProfile._id;
    try {
      await apiClient.delete(apiUrl);
      dispatch(track(THREAD_SEGMENT.THREAD_DELETE_SUCCESS));
      dispatch(eventDispatch(DELETE_THREAD_PENDING, false));
      toastSuccessMessage('Successfully deleted Issue');
      dispatch(getThreadsList({ project_id: projectId, org_id: orgId }));
      dispatch(eventDispatch(SYNC_TASK_PROFILE, true));
      dispatch(eventDispatch(SYNC_ISSUE, true));
    } catch (error) {
      dispatch(track(THREAD_SEGMENT.THREAD_DELETE_ERROR));
      dispatch(eventDispatch(DELETE_THREAD_PENDING, false));
      dispatch(eventDispatch(THREAD_API_ERROR, error));
      toastErrorMessage('Error in deleting Issue');
    }
  };
}

export function closeThread(id: string, reqBody: any) {
  const apiUrl = parseString(THREAD_APIS.closeThread, id);

  return async (dispatch: Dispatch<any>, getState: any) => {
    dispatch(eventDispatch(CLOSE_THREAD_PENDING, true));
    const projectId = getState().projectreducer.selectedProj.id;
    const orgId = getState().organisationReducer.orgProfile._id;

    try {
      await apiClient.put(apiUrl, reqBody);
      dispatch(track(THREAD_SEGMENT.THREAD_CLOSE_SUCCESS));
      dispatch(eventDispatch(CLOSE_THREAD_PENDING, false));
      toastSuccessMessage('Successfully closed Issue');
      dispatch(getThreadsList({ project_id: projectId, org_id: orgId }));
      dispatch(eventDispatch(SYNC_TASK_PROFILE, true));
    } catch (error) {
      dispatch(track(THREAD_SEGMENT.THREAD_CLOSE_ERROR));
      dispatch(eventDispatch(CLOSE_THREAD_PENDING, false));
      dispatch(eventDispatch(THREAD_API_ERROR, error));
      toastErrorMessage('Error in closing Issue');
    }
  };
}

export function addComment(reqBody: CommentProps) {
  const apiUrl = COMMENT_APIS.addComment;
  return async (dispatch: Dispatch<any>) => {
    try {
      await apiClient.post(apiUrl, reqBody);
      dispatch(track(THREAD_SEGMENT.COMMENT_CREATE_SUCCESS));
      toastSuccessMessage('Comment added successfully');
      dispatch(getThread(reqBody.item_id, true));
      dispatch(eventDispatch(UPLOAD_COMMENT_ATTACHED_FILES, []));
    } catch (error) {
      dispatch(track(THREAD_SEGMENT.COMMENT_CREATE_ERROR));
      dispatch(eventDispatch(THREAD_API_ERROR, error));
      toastErrorMessage('Error in adding Comment');
    }
  };
}

export function updateComment(reqBody: CommentProps) {
  const apiUrl = parseString(COMMENT_APIS.updateComment, reqBody._id);
  return async (dispatch: Dispatch<any>) => {
    try {
      await apiClient.put(apiUrl, reqBody);
      dispatch(track(THREAD_SEGMENT.COMMENT_EDIT_SUCCESS));
      toastSuccessMessage('Successfully updated comment');
      dispatch(getThread(reqBody.item_id, true));
      dispatch(eventDispatch(UPLOAD_COMMENT_ATTACHED_FILES, []));
    } catch (error) {
      dispatch(eventDispatch(THREAD_API_ERROR, error));
      dispatch(track(THREAD_SEGMENT.COMMENT_EDIT_ERROR));
      toastErrorMessage('Error in updating comment');
    }
  };
}

export function deleteComment(reqBody: CommentProps) {
  const apiUrl = parseString(COMMENT_APIS.deleteComment, reqBody._id);
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(DELETE_COMMENT_PENDING, true));
    try {
      await apiClient.delete(apiUrl);
      dispatch(track(THREAD_SEGMENT.COMMENT_DELETE_SUCCESS));
      dispatch(eventDispatch(DELETE_COMMENT_PENDING, false));
      toastSuccessMessage('Successfully deleted Comment');
      dispatch(getThread(reqBody.item_id));
    } catch (error) {
      dispatch(track(THREAD_SEGMENT.COMMENT_DELETE_ERROR));
      dispatch(eventDispatch(DELETE_COMMENT_PENDING, false));
      dispatch(eventDispatch(THREAD_API_ERROR, error));
      toastErrorMessage('Error in deleting Comment');
    }
  };
}
