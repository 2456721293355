import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ONE_DAY, ONE_HOUR, ONE_MIN } from '../../constants';
import { AutoRenewIcon } from '../common/Icons';

const RefreshButton = ({
  noLabel = false,
  className = '',
  reloadTime,
  labelText = '',
  onClick,
}) => {
  const [timeLeft, setTimeLeft] = useState({});
  const [updateTime, setUpdateTime] = useState('');

  const calculateTimeLeft = () => {
    const difference = Date.now() - reloadTime;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / ONE_DAY),
        hours: Math.floor((difference / ONE_HOUR) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
      };
    }
    return timeLeft;
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, ONE_MIN);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft.days) {
      setUpdateTime(`- Last synced ${timeLeft.days} days ago`);
    } else if (timeLeft.minutes) {
      let minMeesage = 'minute';
      if (timeLeft.minutes > 1) {
        minMeesage = 'minutes';
      }
      setUpdateTime(`- Last synced  ${timeLeft.minutes} ${minMeesage} ago`);
    } else {
      setUpdateTime('- Last synced few seconds ago');
    }
  }, [timeLeft]);

  const handleClick = e => {
    e.preventDefault();

    onClick();
    setTimeLeft({});
  };

  return (
    <div className={`${className} sync-time-container`}>
      <AutoRenewIcon onClick={handleClick} className={'auto-renew-icon'} />
      {noLabel
        ? ''
        : labelText || (
            <div className="text-primary fw-bold ml-1">Sync with app</div>
          )}
      <span className="time-before-container ml-1">{updateTime}</span>
    </div>
  );
};

RefreshButton.propTypes = {
  reloadTime: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string,
  labelText: PropTypes.any,
  noLabel: PropTypes.bool,
};
export default RefreshButton;
