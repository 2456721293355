import { ADD_ONS, RESOURCE_ID_MAP } from '../../constant/module_constants';
import { UserPermissionsMapProps } from '../../interfaces/Team';
import { useAppSelector } from '../../redux/Hooks';
import useAddOn from './useAddOn.hook';

export const useResourceMapper = () => {
  const userPermissions: UserPermissionsMapProps = useAppSelector(
    state => state.userreducer.userPermissionMap
  );
  const proOnlyPO = useAddOn(ADD_ONS.PRO_PO).allowed;

  const getResource = resourceId => {
    if (!userPermissions.size || resourceId === undefined) {
      return true;
    }

    if (typeof resourceId === 'string') {
      const resourcePermission = userPermissions.get(resourceId);
      if (!resourcePermission) {
        return false;
      }

      const onlyShowProPO =
        !resourcePermission.resource_access &&
        resourceId === RESOURCE_ID_MAP.PURCHASE_ORDER &&
        proOnlyPO;

      if (resourcePermission.resource_access || onlyShowProPO) {
        return true;
      }
    } else {
      let permitted = false;

      for (const id of resourceId) {
        const resourcePermission = userPermissions.get(id);
        if (!resourcePermission) {
          continue;
        }

        const onlyShowProPO =
          !resourcePermission.resource_access &&
          id === RESOURCE_ID_MAP.PURCHASE_ORDER &&
          proOnlyPO;

        if (resourcePermission.resource_access || onlyShowProPO) {
          permitted = true;
          break;
        }
      }

      return permitted;
    }
  };

  return getResource;
};

const useResourceAccess = (resource_id?: string | string[]) => {
  const resourceMapper = useResourceMapper();

  return resourceMapper(resource_id);
};

export default useResourceAccess;
