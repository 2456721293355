/* eslint-disable react/prop-types */
import UpdateIcon from '@mui/icons-material/Update';
import Dialog from '@mui/material/Dialog';

import {
  CustomDialogContent,
  CustomDialogActions,
} from '../common/CustomDialog';
import CustomButton from './customButton/CustomButton';
import { SHOW_UPDATE_ALERT } from '../../redux/features/miscellaneous/service-worker/ServiceWorker.type';
import { useAppDispatch, useAppSelector } from '../../redux/Hooks';
import { useEffect } from 'react';
import { getLatestVersion } from '../../redux/features/miscellaneous/service-worker/ServiceWorker.action';
import { POWERPLAY_WEB_VERSION } from '../../constants';

const UpdateDialog = () => {
  const dispatch = useAppDispatch();

  const showBanner = useAppSelector(
    state => state.serviceWorkerReducer.showUpdateAlerts
  );
  const latestVersion = useAppSelector(
    state => state.serviceWorkerReducer.webAppVersion
  );

  const handleClose = () => {
    dispatch({ type: SHOW_UPDATE_ALERT, payload: false });
  };

  const handleUpdateNow = () => {
    dispatch({ type: SHOW_UPDATE_ALERT, payload: false });
    window.location.reload();
  };

  useEffect(() => {
    dispatch(getLatestVersion());
  }, []);

  let open = false;
  if (
    showBanner &&
    latestVersion !== 0 &&
    latestVersion > Number(POWERPLAY_WEB_VERSION)
  ) {
    open = true;
  }

  return (
    <Dialog open={open} maxWidth={450}>
      <CustomDialogContent dividers={false}>
        <div className="fs-m text-center">
          <img src="/images/updateAppIcon.png" height="80" width="80" />
          <div className="text-center fw-bold my-3 fs-l">
            We have a new update!
          </div>
          <div
            style={{ overflowX: 'auto', maxWidth: 300 }}
            className="text-center fs-m fw-lighter"
          >
            Click below or press <span className="fw-bold">“CTRL+Shift+R”</span>{' '}
            to update to the latest version.
          </div>
        </div>
      </CustomDialogContent>
      <CustomDialogActions leftAlign>
        <CustomButton
          type="contained"
          label="Update Later"
          variant="text"
          handleSubmit={handleClose}
          uppercase
          size="small"
          className={'text-muted fw-bold custom-button-small fs-m mx-3'}
        />
        <CustomButton
          variant="contained"
          label="Update Now"
          size="small"
          startIcon={<UpdateIcon />}
          uppercase
          handleSubmit={handleUpdateNow}
          className={'text-white mx-3'}
        />
      </CustomDialogActions>
    </Dialog>
  );
};

export default UpdateDialog;
