export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_TODAYS_MILESTONE = 'GET_TODAYS_MILESTONE';
export const DASHBOARD_API_PENDING = 'DASHBOARD_API_PENDING';
export const DASHBOARD_API_ERROR = 'DASHBOARD_API_ERROR';
export const DASHBOARD_API_SUCCESS = 'DASHBOARD_API_SUCCESS';
export const GET_PACKAGES_PROGRESS = 'GET_PACKAGES_PROGRESS';
export const CLEAR_DASHBOARD_DATA = 'CLEAR_DASHBOARD_DATA';
export const GET_TASK_COUNT_NEXT_SEVEN_DAYS = 'GET_TASK_COUNT_NEXT_SEVEN_DAYS';
export const GET_ONTIME_TASK_COUNT = 'GET_ONTIME_TASK_COUNT';
export const MILESTONE_PENDING = 'MILESTONE_PENDING';
export const ON_TIME_TASK_PENDING = 'ON_TIME_TASK_PENDING';
export const UPCOMING_TASK_PENDING = 'UPCOMING_TASK_PENDING';
export const ITEMS_COUNT_PENDING = 'ITEMS_COUNT_PENDING';
export const ITEMS_COUNT = 'ITEMS_COUNT';

export const GET_30_DAYS_ACTIVITIES = 'GET_30_DAYS_ACTIVITIES';

export const GET_UPCOMING_TASKS = 'GET_UPCOMING_TASKS';
export const GET_UPCOMING_TASKS_PENDING = 'GET_UPCOMING_TASKS_PENDING';

export const GET_RECENTLY_UPDATED_TASKS = 'GET_RECENTLY_UPDATED_TASKS';
export const GET_RECENTLY_UPDATED_TASKS_PENDING =
  'GET_RECENTLY_UPDATED_TASKS_PENDING';

export const GET_DEADLINE_TASKS = 'GET_DEADLINE_TASKS';
export const GET_DEADLINE_TASKS_PENDING = 'GET_DEADLINE_TASKS_PENDING';

export const GET_DASHBOARD_TASK_SUMMARY = 'GET_DASHBOARD_TASK_SUMMARY';

export const GET_ORG_UPCOMING_TASKS = 'GET_ORG_UPCOMING_TASKS';
export const GET_ORG_UPCOMING_TASKS_PENDING = 'GET_ORG_UPCOMING_TASKS_PENDING';

export const GET_ORG_DEADLINE_TASKS = 'GET_ORG_DEADLINE_TASKS';
export const GET_ORG_DEADLINE_TASKS_PENDING = 'GET_ORG_DEADLINE_TASKS_PENDING';

export const GET_ORG_UPDATED_TASKS = 'GET_ORG_UPDATED_TASKS';
export const GET_ORG_UPDATED_TASKS_PENDING = 'GET_ORG_UPDATED_TASKS_PENDING';

export const GET_PACKAGE_WISE_TASK_SUMMARY = 'GET_PACKAGE_WISE_TASK_SUMMARY';
export const GET_TAG_WISE_TASK_SUMMARY = 'GET_TAG_WISE_TASK_SUMMARY';
export const GET_USER_WISE_TASK_SUMMARY = 'GET_USER_WISE_TASK_SUMMARY';

export const GET_S_CURVE_DATA_SUCCESS = 'GET_S_CURVE_DATA_SUCCESS';
export const GET_TASK_COUNT_DETAILS = 'GET_TASK_COUNT_DETAILS';
export const GET_FILTERED_TASK_PROGRESS = 'GET_FILTERED_TASK_PROGRESS';

export const GET_BROAD_PLAN_PROJECT_DASHBOARD_STATUS =
  'GET_BROAD_PLAN_PROJECT_DASHBOARD_STATUS';
export const GET_BROAD_PLAN_TOP_ACTIVE_USERS =
  'GET_BROAD_PLAN_TOP_ACTIVE_USERS';
