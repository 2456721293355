import { removeAllCookies } from '../../utils/Cookies.util';
import LocalStorageUtil from '../../utils/LocalStorage.util';

const checkTokenExpirationMiddleware = () => (next: any) => (action: any) => {
  const exp = Number(LocalStorageUtil.getLocalStorageValue('exp', false));
  if (exp && exp < Date.now() / 1000) {
    LocalStorageUtil.clearLocalStorageValues();
    removeAllCookies();
    window.location.reload();
  }
  next(action);
};

export default checkTokenExpirationMiddleware;
