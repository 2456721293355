/* eslint-disable no-case-declarations */
import {
  GET_PACKAGE_ITEMS,
  GET_ALL_PACKAGES,
  PACKAGE_API_PENDING,
  PACKAGE_API_ERROR,
  PACKAGE_API_SUCCESS,
  SELECTED_PACKAGE_DETAILS,
  GET_FETCHED_PACKAGE_DETIALS,
  WC_PACKAGE_API_SUCCESS,
  WC_PACKAGE_API_PENDING,
  PACKAGE_BIRD_EYE_VIEW,
  GET_PACKAGES_LIST,
  SET_SELECTED_PACKAGE_ROWS,
  GET_PACKAGES,
  GET_ALL_PACKAGES_WITH_QUERY,
} from './Package.type';

const initialState = {
  pending: 0,
  wcPending: false,
  allPackagesWithQuery: [],
  allPackages: [],
  packageItems: [],
  packagesList: [], // contains id and name of the packages
  error: null,
  selectedPackageDetails: {},
  fetchedPackageDetails: [],
  packageBirdEyeView: {},
  selectedPackageRows: [],
  packages: [],
};

export default function baseReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case GET_PACKAGE_ITEMS:
      return { ...state, packageItems: payload.data };
    case GET_PACKAGES_LIST:
      return { ...state, packagesList: payload.data };
    case GET_ALL_PACKAGES: {
      return { ...state, allPackages: payload };
    }
    case PACKAGE_API_SUCCESS:
      return {
        ...state,
        pending: state.pending - 1,
        error: null,
      };

    case PACKAGE_API_PENDING:
      return {
        ...state,
        pending: state.pending + 1,
      };

    case WC_PACKAGE_API_PENDING:
      return {
        ...state,
        wcPending: true,
      };

    case WC_PACKAGE_API_SUCCESS:
      return {
        ...state,
        wcPending: false,
      };

    case PACKAGE_API_ERROR:
      return {
        ...state,
        pending: false,
        error: error,
      };

    case SELECTED_PACKAGE_DETAILS:
      return {
        ...state,
        selectedPackageDetails: payload.data,
      };
    case GET_FETCHED_PACKAGE_DETIALS:
      let packageItem = {};
      if (payload.data && Object.keys(payload.data).length !== 0) {
        const packageId = payload.data._id;
        const packageDetails = payload.data;
        packageItem = { packageId, packageDetails };
        state.fetchedPackageDetails.push(packageItem);
      }
      return state;
    case PACKAGE_BIRD_EYE_VIEW:
      return {
        ...state,
        packageBirdEyeView: payload.data,
      };
    case SET_SELECTED_PACKAGE_ROWS:
      return {
        ...state,
        selectedPackageRows: payload,
      };
    case GET_PACKAGES:
      return {
        ...state,
        packages: [
          ...(payload.defaultWCArr || []),
          ...(payload.orgWCArr || []),
        ],
      };
    case GET_ALL_PACKAGES_WITH_QUERY:
      return {
        ...state,
        allPackagesWithQuery: payload,
      };
    default:
      return state;
  }
}
