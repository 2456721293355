export const FETCH_TASK_DATA_SUCCESS = 'task_load_success';
export const FETCH_TASK_DATA_FAILURE = 'task_load_failure';
export const UPLOAD_MSP_SUCCESS = 'upload_msp_success';
export const UPLOAD_MSP_FAILURE = 'upload_msp_failure';
export const UPLOAD_EXISTING_PLAN_SUCCESS = 'add_existing_plan_success';
export const UPLOAD_EXISTING_PLAN_FAILURE = 'add_existing_plan_failure';
export const REPLACE_PLAN_FAILURE = 'replace_plan_failure';
export const REPLACE_PLAN_SUCCESS = 'replace_plan_success';
export const CREATE_BASELINE_SUCCESS = 'baseline_create_new_success';
export const CREATE_BASELINE_FAILURE = 'baseline_create_new_failure';
export const ON_DEPENDENC_CREATE = 'gantt_view_dep_drag';
export const ON_TASK_LINK_CREATE_SUCCESS = 'gantt_view_dep_drag_success';
export const ON_TASK_LINK_CREATE_FAILURE = 'gantt_view_dep_drag_failure';
export const LINK_DELETE_SUCCESS = 'dependency_remove_successful';
export const LINK_DELETE_FAILURE = 'dependency_remove_failure';
export const DOWNLOAD_DPR_SUCCESS = 'download_dpr_popout_submit_success';
export const DOWNLOAD_DPR_FAILURE = 'download_dpr_popout_submit_failure';
export const ZOOMOUT_SUCCESS = 'zoomout_success';
export const ZOOM_SCROLLED = 'zoom_scrolled';
export const ZOOMOUT_FAILURE = 'zoomout_failure';
export const RESIZE_GANTT_VIEW = 'resize_gantt_view';
export const DELETE_BASELINE_FAILURE = 'baseline_popup_baseline_delete_failure';
export const DELETE_BASELINE_SUCCESS = 'baseline_popup_baseline_delete_success';
export const UPDATE_BASELINE_FAILURE = 'baseline_popup_edit_failure';
export const UPDATE_BASELINE_SUCCESS = 'baseline_popup_edit_success';
export const BULK_NO_PROGRESS_SUCCESS = 'bulk_no_progress_success';
export const BULK_NO_PROGRESS_FAILURE = 'bulk_no_progress_failure';
export const EDIT_INLINE_SUCCESSFUL = 'edit_inline_successful';
export const EDIT_INLINE_ERROR = 'edit_inline_failure';

export const LEADLAG_TYPE_CHANGE_SUCCESS = 'lead_lag_type_change_success';
export const LEADLAG_CHANGE_FAILURE = 'lead_lag_type_change_success_failure';

export const LEAD_ADD_SUCCESSFUL = 'lead_add_successful';
export const LEAD_ADD_FAILURE = 'lead_add_failure';

export const LAG_ADD_SUCCESSFUL = 'lag_add_successful';
export const LAG_ADD_FAILURE = 'lag_add_failure';

export const TASK_VIEW_TASKS_FILTERED = 'task_view_tasks_filtered';
export const TASK_VIEW_FILTER_RESET = 'task_view_filter_reset';

export const SITE_PLAN_INDENT_CLICK = 'site_plan_indent_click';
export const SITE_PLAN_OUTDENT_CLICK = 'site_plan_outdent_click';

// apartment template plan events

export const TEMP_PLAN_LOAD_SUCCESS = 'template_plan_load_success';
export const TEMP_PLAN_LOAD_FAILURE = 'template_plan_load_failure';
export const TEMP_PLAN_CREATE_SUCCESS = 'template_plan_create_success';
export const TEMP_PLAN_CREATE_FAILURE = 'template_plan_create_failure';

export const TASK_UPDATE_DATE_CLICK = 'task_update_date_click';
export const TASK_UPDATE_DATE_SELECT = 'task_update_date_select';
export const TASK_UPDATE_SAVE_UPDATE_POPUP = 'task_update_save_update_popup';
export const TASK_LOG_SAVE_UPDATE_CLICK = 'task_log_save_update_click';

export const TASK_VIEW_REPLACE_BUTTON_CLICK = 'Task_view_replace_button_click';
export const TASK_VIEW_FILE_UPLOAD_CLICK = 'Task_view_file_upload_click';
export const TASK_VIEW_UPLOAD_PLAN_TYPE_CLICK =
  'task_view_upload_plan_type_click';
export const UPLOAD_PLAN_EXCEL_TABS_CLICK = 'upload_plan_excel_tabs_click';
export const UPLOAD_PLAN_EXCEL_DOWNLOAD_CLICK =
  'upload_plan_excel_download_click';
export const TASK_SEARCH_BAR_CLICK = 'task_search_bar_click';
export const TASK_SEARCH_BAR_TYPE_SUCCESS = 'task_search_bar_type_success';

// Task log edit
export const TASK_PROFILE_TIMELINE_MENU_CLICK =
  'task_profile_timeline_menu_click';
export const TASK_PROFILE_EDIT_CLICK = 'task_profile_edit_click';
export const TASK_PROFILE_DELETE_CLICK = 'task_profile_delete_click';
export const TASK_PROFILE_EDIT_HISTORY_CLICK =
  'task_profile_edit_history_click';

// Task View
export const TASK_VIEW_PAGE_SCROLL = 'task_view_page_scroll';
export const TASK_VIEW_PROJECT_DROPDOWN_CLICK =
  'task_view_project_dropdown_click';
export const TASK_DETAIL_INFORMATION_ICON_CLICK =
  'task_detail_information_icon_click';
export const TASK_OUTLINE_DROPDOWN_CLICK = 'task_outline_dropdown_click';
export const TASK_OUTLINE_OPTION_CLICK = 'task_outline_option_click';
export const TASK_SITE_UPDATE_OPTION_CLICK = 'task_site_update_option_click';
export const TASK_SITE_UPDATE_FILTER_OPTION = 'task_site_update_filter_option';
export const TASK_SITE_UPDATE_FILTER_SUCCESS =
  'task_site_update_filter_success';
