import ChatBubble from '@mui/icons-material/ChatBubble';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { ProjectPlan } from '../../../../common/Icons';
import { fromNow } from '../../../../../utils/Date.util';
import { markClicked } from '../../../../../redux/features/miscellaneous/notification';
import { NOTIF_TYPE } from '../../../../../constant/notification_constant';
import { setNavbarTitle } from '../../../../../redux/features/common/layout/Layout.action';
import { NotifTitle } from '../../../../../constants';
import { setSelectedKey } from '../../../../../redux/features/common/utils/Utils.action';
import { NotificationProps } from '../../../../../interfaces/Notifications';
import CommercialNotif from './CommercialNotif';
import { useAppDispatch, useAppSelector } from '../../../../../redux/Hooks';

interface Props {
  className: string;
  notifObj: NotificationProps;
}

function Notification({ className, notifObj }: Props) {
  const { data, notification, is_clicked, created_date } = notifObj;
  const projectId = useAppSelector(
    state => state.projectreducer.selectedProj.id
  );
  const dispatch = useAppDispatch();

  const classes = !is_clicked
    ? clsx(className, 'bg-light-blue')
    : clsx(className);

  const icon_classes = !is_clicked
    ? clsx(className, 'svg-blue')
    : clsx(className, 'svg-grey');

  const handleClick = (notification: NotificationProps) => () => {
    if (NotifTitle[notification?.data?.type as keyof typeof NotifTitle]) {
      dispatch(
        setNavbarTitle(
          NotifTitle[notification.data.type as keyof typeof NotifTitle]?.title
        )
      );
      if (NotifTitle[notification.data.type as keyof typeof NotifTitle].key) {
        dispatch(
          setSelectedKey(
            NotifTitle[notification.data.type as keyof typeof NotifTitle].key
          )
        );
      }
    } else {
      dispatch(setNavbarTitle(NotifTitle['task'].title));
      dispatch(setSelectedKey(NotifTitle['task'].key));
    }

    dispatch(markClicked(notification._id));
  };

  if (!data) {
    return (
      <div
        className={`d-flex justify-content-between align-items-center border-bottom py-3 ${classes}`}
      >
        <div className="px-3">
          <ChatBubble className={`${icon_classes}`} />
        </div>
        <div className="d-flex flex-column flex-grow-1">
          <div className="fs-m d-flex flex-column">
            <span className="d-inline-block text-truncate notif-width">
              {notification.title}
            </span>
            <span className="fs-s d-inline-block text-truncate notif-width text-dark-grey mt-xl-1">
              {notification.body}
            </span>
          </div>
        </div>
        <div className="flex-shrink-1 px-2 fs-s light">
          {fromNow(created_date, false)}
        </div>
      </div>
    );
  } else if (
    data.type === NOTIF_TYPE.THREAD ||
    (data.type === 'url_notif' && data._id.slice(0, 3) === 'THD')
  ) {
    return (
      <div
        className={`d-flex notif-item justify-content-between align-items-center border-bottom py-3 ${classes}`}
      >
        <div className="px-3">
          <ChatBubble className={`${icon_classes}`} />
        </div>
        <div
          className="fs-s d-flex flex-column flex-grow-1"
          onClick={handleClick(notifObj)}
        >
          <Link
            to={`/project/${projectId}/threads/${data._id}`}
            className="fs-s d-flex flex-column no-text-dec"
          >
            <span className="fs-m d-inline-block text-truncate notif-width text-grey-900">
              {notification.title}
            </span>
            <span className="d-inline-block text-truncate notif-width text-dark-grey mt-xl-1">
              {notification.body}
            </span>
          </Link>
        </div>
        <div className="flex-shrink-1 px-2 fs-s light">
          {fromNow(created_date, false)}
        </div>
      </div>
    );
  } else if (
    data.type === NOTIF_TYPE.INDENT ||
    data.type === NOTIF_TYPE.PURCHASE_ORDER
  ) {
    return (
      <CommercialNotif
        className={className}
        notifObj={notifObj}
        handleClick={handleClick}
      />
    );
  } else {
    return (
      <div
        className={`d-flex notif-item align-items-center border-bottom py-3 ${classes}`}
      >
        <div className="px-3">
          <ProjectPlan className={`${icon_classes}`} fillOpacity={1} />
        </div>
        <div
          className="fs-s d-flex flex-column flex-grow-1"
          onClick={handleClick(notifObj)}
        >
          <Link
            to={`/project/${projectId}/project-plan`}
            className="fs-s d-flex flex-column no-text-dec"
          >
            <span className="fs-m d-inline-block text-truncate notif-width text-grey-900">
              {notification.title}
            </span>
            <span className="d-inline-block text-truncate notif-width text-dark-grey mt-xl-1">
              {notification.body}
            </span>
          </Link>
        </div>
        <div className="flex-shrink-1 px-2 fs-s light">
          {fromNow(created_date, false)}
        </div>
      </div>
    );
  }
}

export default Notification;
