export const SYNC_DASHBOARD_VIEW = 'SYNC_DASHBOARD_VIEW';
export const SYNC_COMMERCIAL_VIEW = 'SYNC_COMMERCIAL_VIEW';
export const SYNC_WORK_CATEGORIES_VIEW = 'SYNC_WORK_CATEGORIES_VIEW';
export const SYNC_PROJECT_PLAN_VIEW = 'SYNC_PROJECT_PLAN_VIEW';
export const SYNC_BOM_VIEW = 'SYNC_BOM_VIEW';
export const SYNC_VENDOR_VIEW = 'SYNC_VENDOR_VIEW';
export const SYNC_ORGANIZATION_PROFILE_VIEW = 'SYNC_ORGANIZATION_PROFILE_VIEW';
export const SYNC_TEAM_VIEW = 'SYNC_TEAM_VIEW';
export const SYNC_MASTER_VENDOR_VIEW = 'SYNC_MASTER_VENDOR_VIEW';
export const SYNC_MASTER_BOM_VIEW = 'SYNC_MASTER_BOM_VIEW';
export const SYNC_INVITE_VIEW = 'SYNC_INVITE_VIEW';
export const SYNC_PACKAGES_VIEW = 'SYNC_PACKAGES_VIEW';
export const SYNC_DOCUMENTS_VIEW = 'SYNC_DOCUMENTS_VIEW';
export const SYNC_INVOICES_VIEW = 'SYNC_INVOICES_VIEW';
export const SYNC_REPORTS_VIEW = 'SYNC_REPORTS_VIEW';
export const SYNC_PROJECT_PROFILE_VIEW = 'SYNC_PROJECT_PROFILE_VIEW';
export const SYNC_THREADS_VIEW = 'SYNC_THREADS_VIEW';
export const SYNC_TASK_PROFILE = 'SYNC_TASK_PROFILE';
export const SYNC_BROAD_PLAN_TASK_VIEW = 'SYNC_BROAD_PLAN_TASK_VIEW';
export const SYNC_BROAD_PLAN_WEEK_VIEW = 'SYNC_BROAD_PLLAN_WEEK_VIEW';
export const SYNC_BROAD_PLAN_TASK_PROFILE = 'SYNC_BROAD_PLAN_TASK_PROFILE';
export const SYNC_BROAD_PLAN_WEEK_TASK_PROFILE =
  'SYNC_BROAD_PLAN_WEEK_TASK_PROFILE';
export const SYNC_EXPENSE_PROFILE = 'SYNC_EXPENSE_PROFILE';
export const SYNC_ACTIVITY_VIEW = 'SYNC_ACTIVITY_VIEW';
export const SYNC_BUDGET_VIEW = 'SYNC_BUDGET_VIEW';
export const SYNC_GRN_VIEW = 'SYNC_GRN_VIEW';
export const SYNC_ATTENDANCE_VIEW = 'SYNC_ATTENDANCE_VIEW';
export const SYNC_GRN_LIST_VIEW = 'SYNC_GRN_LIST_VIEW';
export const SYNC_SITE_PLAN_VIEW = 'SYNC_SITE_PLAN_VIEW';
export const SYNC_PROJECT_LIST_VIEW = 'SYNC_PROJECT_LIST_VIEW';
export const SYNC_MEMBERS_VIEW = 'SYNC_MEMBERS_VIEW';
export const SYNC_INVENTORY_VIEW = 'SYNC_INVENTORY_VIEW';
export const SYNC_VIEW = 'SYNC_VIEW';
export const SYNC_ATTENDANCE_SUMMARY_VIEW = 'SYNC_ATTENDANCE_SUMMARY_VIEW';
export const SYNC_ORG_ATTENDANCE_SUMMARY_VIEW =
  'SYNC_ORG_ATTENDANCE_SUMMARY_VIEW';
export const SYNC_ATTENDANCE_PAYABLES_VIEW = 'SYNC_ATTENDANCE_PAYABLES_VIEW';
export const SYNC_ATTENDANCE_PROFILE_VIEW = 'SYNC_ATTENDANCE_PROFILE_VIEW';
export const SYNC_ISSUE = 'SYNC_ISSUE';
export const SYNC_MVP_CHILDREN = 'SYNC_MVP_CHILDREN';
export const SYNC_MVP_VIEW = 'SYNC_MVP_VIEW';
export const REFRESH_MVP_CHILDRENS_FOR_ID = 'REFRESH_MVP_CHILDRENS_FOR_ID';
export const SYNC_ATTENDANCE_ORG_PAYBALS_VIEW =
  'SYNC_ATTENDANCE_ORG_PAYBALS_VIEW';
export const SYNC_ATTENDANCE_PROJECT_PROFILE_VIEW =
  'SYNC_ATTENDANCE_PROJECT_PROFILE_VIEW';
export const SYNC_VENDOR_ORG_LIST_VIEW = 'SYNC_VENDOR_ORG_LIST_VIEW';
export const SYNC_WORK_ORDER_VIEW = 'SYNC_WORK_ORDER_VIEW';
export const SYNC_WORK_ORDER_PROFILE_VIEW = 'SYNC_WORK_ORDER_PROFILE_VIEW';
export const SYNC_WORK_ORDER_TRANSACTION_VIEW =
  'SYNC_WORK_ORDER_TRANSACTION_VIEW';
export const SYNC_VENDOR_PROFILE_VIEW = 'SYNC_VENDOR_PROFILE_VIEW';
export const SYNC_VENDOR_TO_PAY_VIEW = 'SYNC_VENDOR_TO_PAY_VIEW';
export const SYNC_LABOUR_TO_PAY_VIEW = 'SYNC_LABOUR_TO_PAY_VIEW';
export const SYNC_GROUP_MATERIAL_VIEW = 'SYNC_GROUP_MATERIAL_VIEW';
export const SYNC_INVENTORY_HISTORY = 'SYNC_INVENTORY_HISTORY';
export const SYNC_BOM_PROFILE_VIEW = 'SYNC_BOM_PROFILE_VIEW';
export const SYNC_PRICING_HISTORY = 'SYNC_PRICING_HISTORY';
export const SYNC_ASYNC_REPORTS = 'SYNC_ASYNC_REPORTS';
export const INDENT_SEQ_SYNC_VIEW = 'INDENT_SEQ_SYNC_VIEW';
export const SYNC_DIALOG_VIEW = 'SYNC_DIALOG_VIEW';
export const SYNC_ORG_MI_VIEW = 'SYNC_ORG_MI_VIEW';
export const SYNC_ORG_ST_VIEW = 'SYNC_ORG_ST_VIEW';

export const SYNC_VENDOR_PROJECTS_VIEW = 'SYNC_VENDOR_PROJECTS_VIEW';
export const SYNC_ORG_VENDOR_SELECTION_VIEW = 'SYNC_ORG_VENDOR_SELECTION_VIEW';
