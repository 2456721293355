import { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { CustomDialogTitle, SideScreenDialog } from '../../components/common';
import SettingUp from '../../components/onboarding/SettingUp';
import { ValueOfType } from '../../interfaces/Base';
import SelectModules from '../../components/onboarding/SelectModules';
import {
  OnboardingQuestions,
  OnboardingStepProps,
} from '../../components/onboarding/types';
import InviteMembers from '../../components/onboarding/InviteMembers';

export const ONBOARDING_STEPS = {
  SETTING_UP: 0,
  SELECTING_MODULE: 1,
  INVITING_MEMBERS: 2,
} as const;

export type onboardingStepType = ValueOfType<typeof ONBOARDING_STEPS>;

interface Props {
  startingStep?: onboardingStepType;
  existingProject?: { id: string; name: string };
  open: boolean;
  setOpen: (open: boolean) => void;
  width?: number;
}

const OnboardingDialog = (props: Props) => {
  const { startingStep = 0, open, setOpen, existingProject, width } = props;
  const [step, setStep] = useState<onboardingStepType>(startingStep);
  const [selectedModule, setSelectedModule] = useState<
    'task' | 'material' | 'attendance'
  >('task');
  const [project, setProject] = useState({
    id: existingProject?.id,
    name: '',
  });

  useEffect(() => {
    setStep(startingStep);
  }, [startingStep]);

  useEffect(() => {
    if (existingProject) {
      setProject(existingProject);
    }
  }, [existingProject]);

  const [onboardingQuestions, setOnboardingQuestions] =
    useState<OnboardingQuestions>([]);

  const getStep = () => {
    const props: OnboardingStepProps = {
      setStep,
      selectedModule,
      setSelectedModule,
      onboardingQuestions,
      setOnboardingQuestions,
      project,
      setProject,
      open,
      setOpen,
      startingStep,
    };

    switch (step) {
      case ONBOARDING_STEPS.SETTING_UP:
        return <SettingUp {...props} />;
      case ONBOARDING_STEPS.SELECTING_MODULE:
        return <SelectModules {...props} />;
      case ONBOARDING_STEPS.INVITING_MEMBERS:
        return <InviteMembers {...props} />;
    }
  };

  const getHeading = () => {
    switch (step) {
      case ONBOARDING_STEPS.SETTING_UP:
        return 'Setting Up';
      case ONBOARDING_STEPS.SELECTING_MODULE:
        return `Select Module to get started for Project - ${project.name}`;
      case ONBOARDING_STEPS.INVITING_MEMBERS:
        return `Add Suggested Members in ${project.name}`;
    }
  };

  return (
    <SideScreenDialog width={width} fullScreen open={open}>
      <CustomDialogTitle
        onClose={() => {
          setOpen(false);
          setStep(startingStep || 0);
        }}
      >
        <div>{'Start Onboarding'}</div>
        <div className="text-default fs-s mt-1 fw-bold ico-text">
          {Boolean(step) && (
            <ArrowBackIcon
              className="text-default hand-pointer mr-1"
              fontSize="small"
              onClick={() => {
                setStep(prev => (prev - 1) as any);
              }}
            />
          )}
          <span>{`Step ${step + 1}/${
            Object.keys(ONBOARDING_STEPS).length
          } - ${getHeading()}`}</span>
        </div>
      </CustomDialogTitle>
      {getStep()}
    </SideScreenDialog>
  );
};

export default OnboardingDialog;
