import { useState } from 'react';

interface UserProps {
  user_profile_url?: string;
  photo_url?: string | null;
  user_name?: string;
  displayName?: string;
}
interface Props {
  user?: UserProps;
  size?: 'small' | 'medium' | 'large' | 'x-large' | 'profile';
  className?: string;
}

const AVATAR_COLOR_MAP: Array<string> = [
  '#0097A7',
  '#0091EA',
  '#558B2F',
  '#D32F2F',
  '#E91E63',
  '#EF6C00',
  '#607D8B',
  '#3D5AFE',
  '#FF1744',
  '#26A69A',
];

export function getAvatarBackgroundColor(name: string): string {
  const index = name?.length % 10;
  return AVATAR_COLOR_MAP[index || 0];
}

export function getInitials(name: string): string {
  name = name ? name : 'P P';
  const nameArray = name?.split(' ');
  let initals = '';
  nameArray?.forEach(element => {
    if (initals.length >= 2) return;
    initals = initals + element[0];
  });
  return initals.toUpperCase() ?? '';
}

const ProfileImage = ({
  user = { user_name: 'pp' },
  size = 'medium',
  className = '',
}: Props) => {
  const url = user.user_profile_url || user.photo_url || '';
  const [noImage, setNoImage] = useState(false);
  const username = user.displayName || user.user_name || 'P P';

  return (
    <>
      {!noImage ? (
        <img
          className={`user__icon rounded-circle  ${className} square-${size}`}
          src={url}
          alt={username}
          onError={() => setNoImage(true)}
        />
      ) : (
        <>
          <div
            className={`user__icon rounded-circle  ${className} square-${size}`}
            style={{ backgroundColor: getAvatarBackgroundColor(username) }}
          >
            <span className={`text-white`}>{getInitials(username)}</span>
          </div>
        </>
      )}
    </>
  );
};

export default ProfileImage;
