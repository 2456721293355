export const GET_PACKAGE_ITEMS = 'GET_PACKAGE_ITEMS';
export const GET_PACKAGES_LIST = 'GET_PACKAGES_LIST';
export const GET_ALL_PACKAGES = 'GET_ALL_PACKAGES';
export const GET_ALL_PACKAGES_WITH_QUERY = 'GET_ALL_PACKAGES_WITH_QUERY';
export const PACKAGE_API_PENDING = 'PACKAGE_API_PENDING';
export const PACKAGE_API_ERROR = 'PACKAGE_API_ERROR';
export const PACKAGE_API_SUCCESS = 'PACKAGE_API_SUCCESS';
export const SELECTED_PACKAGE_DETAILS = 'SELECTED_PACKAGE_DETAILS';
export const GET_FETCHED_PACKAGE_DETIALS = 'GET_FETCHED_PACKAGE_DETIALS';
export const WC_PACKAGE_API_PENDING = 'WC_PACKAGE_API_PENDING';
export const WC_PACKAGE_API_SUCCESS = 'WC_PACKAGE_API_SUCCESS';
export const PACKAGE_BIRD_EYE_VIEW = 'PACKAGE_BIRD_EYE_VIEW';
export const SET_SELECTED_PACKAGE_ROWS = 'SET_SELECTED_PACKAGE_ROWS';
export const GET_PACKAGES = 'GET_PACKAGES';
