export const SET_EMBED_URL = 'SET_EMBED_URL';

export const SET_VIEWS = 'SET_VIEWS';

export const SET_ANALYTICS_DASHBOARD_API_SUCCESS =
  'SET_ANALYTICS_DASHBOARD_API_SUCCESS';

export const SET_ANALYTICS_DASHBOARD_API_PENDING =
  'SET_ANALYTICS_DASHBOARD_API_PENDING';

export const SET_ANALYTICS_DASHBOARD_API_ERROR =
  'SET_ANALYTICS_DASHBOARD_API_ERROR';

export const SET_ANALYTICS_DASHBOARD_LOADING =
  'SET_ANALYTICS_DASHBOARD_LOADING';
