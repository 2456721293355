import { SET_PROJECT_FILTER, SET_PROJECT_STAGE } from './Portfolio.type';

const initialState = {
  projectStatus: '',
  projectStage: '',
};

export default function portfolioReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_PROJECT_FILTER:
      return { ...state, projectStatus: payload };
    case SET_PROJECT_STAGE:
      return { ...state, projectStage: payload };
    default:
      return state;
  }
}
