import { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  CustomButton,
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogActions,
} from '.';
import { AlertIcon, WarningIcon } from './Icons';
import { variables } from './Theme';
interface Props {
  onHide: () => void;
  onSubmit: Function;
  show?: boolean;
  headerIconType?: 'warning' | 'alert' | 'none' | 'accept';
  headerLabel?: string;
  content?: string | ReactNode;
  confirmStartIcon?: ReactNode;
  cancelStartIcon?: ReactNode;
  confirmButtonLabel?: string;
  confirmButtonType?: 'contained' | 'text' | 'outlined';
  confirmButtonColor?: 'default' | 'primary';
  confirmButtonClassName?: string;
  cancelButtonLabel?: string;
  cancelButtonType?: 'contained' | 'text' | 'outlined';
  cancelButtonColor?: 'default' | 'primary' | 'inherit' | 'secondary';
  cancelButtonClassName?: string;
  buttonPosition?: 'straight' | 'reverse';
  closeable?: boolean;
  children?: ReactNode;
  footerContent?: string | ReactNode;
  closeOnSubmit?: boolean;
  styles?: string;
  onCancel?: Function | null;
  customOnHide?: Function | null;
  confirmButtonDisable?: boolean;
  setError?: Function;
  headerStyles?: string;
  lowercase?: boolean;
}
function ConfirmDialog(props: Props) {
  const {
    onHide,
    show = false,
    onSubmit,
    content = 'Are you sure you want to do this operation?',
    confirmButtonLabel = 'Confirm',
    headerLabel = 'Confirm the action',
    confirmStartIcon,
    cancelStartIcon,
    confirmButtonType = 'contained',
    confirmButtonClassName = '',
    confirmButtonColor,
    cancelButtonLabel = 'Cancel',
    cancelButtonType = 'text',
    cancelButtonColor = 'default',
    headerIconType = 'none',
    cancelButtonClassName = '',
    buttonPosition = 'straight',
    closeable = true,
    children,
    footerContent,
    closeOnSubmit = true,
    styles,
    onCancel = () => {},
    customOnHide = () => {},
    confirmButtonDisable = false,
    setError,
    headerStyles,
    lowercase,
  } = props;

  const handleClose = () => {
    onCancel?.();
    onHide();
  };

  const handleHide = () => {
    customOnHide?.();
    onHide();
  };

  const handleSubmit = () => {
    if (confirmButtonDisable) {
      setError(true);
      return;
    }
    onSubmit();
    if (closeOnSubmit) {
      onHide();
    }
  };
  const getHeaderIcon = () => {
    switch (headerIconType) {
      case 'warning':
        return <WarningIcon width={24} height={24} className="mr-2" />;
      case 'alert':
        return <AlertIcon width={24} height={24} className="mr-2" />;
      case 'accept':
        return (
          <AlertIcon
            width={24}
            height={24}
            color={variables.blue500}
            className="mr-2"
          />
        );
      case 'none':
        return '';
    }
  };
  const confirmButton = (
    <CustomButton
      label={confirmButtonLabel}
      onClick={handleSubmit}
      uppercase={lowercase ? false : true}
      buttonSize="small"
      color={confirmButtonColor}
      startIcon={confirmStartIcon}
      variant={confirmButtonType}
      className={confirmButtonClassName}
      disable={confirmButtonDisable}
    />
  );
  const cancelButton = (
    <CustomButton
      label={cancelButtonLabel}
      onClick={handleClose}
      uppercase={lowercase ? false : true}
      buttonSize="small"
      startIcon={cancelStartIcon}
      color={cancelButtonColor}
      variant={cancelButtonType}
      className={cancelButtonClassName}
    />
  );
  return (
    <Dialog
      open={show}
      maxWidth="xs"
      fullWidth
      onClose={closeable ? onHide : undefined}
    >
      <CustomDialogTitle
        onClose={closeable ? handleHide : null}
        classes={undefined}
        iconClasses={''}
      >
        <div className={`d-flex align-items-center ${styles}`}>
          {getHeaderIcon()}
          <div className={headerStyles}>{headerLabel}</div>
        </div>
      </CustomDialogTitle>
      <CustomDialogContent dividers={false}>
        {content}
        {children}
        {footerContent}
      </CustomDialogContent>
      <CustomDialogActions classes={undefined}>
        <div
          className={
            buttonPosition === 'reverse' ? 'd-flex flex-row-reverse' : ''
          }
        >
          {confirmButton} <span className="mx-1" /> {cancelButton}
        </div>
      </CustomDialogActions>
    </Dialog>
  );
}
export default ConfirmDialog;
